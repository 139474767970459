import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { feeScheduleBreadcrumbsHistory } from './functions';

const FeeScheduleBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="fee-schedule-breadcrumbs"
      history={feeScheduleBreadcrumbsHistory(t, location)}
    />
  );
};

export default FeeScheduleBreadcrumbs;
