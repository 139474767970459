import React from 'react';
import classnames from 'classnames';
import ListCard from './list-card';

const CaseListCard = (props) => {
  const { className, ...cardProps } = props;
  return (
    <ListCard
      className={classnames('case-list-card', className)}
      {...cardProps}
    />
  );
};

CaseListCard.propTypes = {
  ...ListCard.propTypes,
};

export default CaseListCard;
