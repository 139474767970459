import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
  OffsetLimitPagination,
} from 'js/components/design-system';
import {
  onMountEffect,
  getLinkedLawyersEffect,
  onSelectLawyerEffect,
  previousLinkedLawyerResultsEffect,
  nextLinkedLawyerResultsEffect,
  linkedLawyerResultsPageNumberEffect,
} from './effects';
import { getLinkedLawyerColumns, queryForSearchString } from './functions';
import { useLocation, useHistory } from 'react-router-dom';
import EmptyState from 'js/components/controls/empty-state';

const LinkedLawyerList = (props) => {
  const { firmId, isNew } = props;
  const abortControllerRef = useRef(null);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [linkedLawyers, setLinkedLawyers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const history = useHistory();
  const { search = '' } = useLocation();
  const query = queryForSearchString(search);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    getLinkedLawyersEffect({
      t,
      api,
      firmId,
      isNew,
      setActive,
      setLinkedLawyers,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );
  return (
    <Fragment>
      <h1>{t('components.LawFirmDetailsPage.LinkedLawyerList.title')}</h1>
      {active && <ActivityIndicatorCard />}
      {!active && linkedLawyers.length === 0 && (
        <EmptyState
          title={t(
            'components.LawFirmDetailsPage.LinkedLawyerList.emptyStateMessage'
          )}
        />
      )}
      {!active && linkedLawyers.length > 0 && (
        <Fragment>
          <SortableTable
            className="linked-lawyers-table"
            columns={getLinkedLawyerColumns(t)}
            data={linkedLawyers}
            initialSortColumnKey={'firstName'}
            initialSortDirection={tableSortDirection.desc}
            onClickRow={onSelectLawyerEffect({ history })}
          />
          <div className="linked-lawyer-pagination-container">
            <OffsetLimitPagination
              className="linked-lawyer-pagination"
              offset={query.params.Offset}
              limit={query.params.Limit}
              totalResults={totalResults}
              onClickPrevious={previousLinkedLawyerResultsEffect({
                history,
                query,
              })}
              onClickNext={nextLinkedLawyerResultsEffect({ history, query })}
              onClickPageNumber={linkedLawyerResultsPageNumberEffect({
                history,
                query,
              })}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

LinkedLawyerList.propTypes = {
  firmId: PropTypes.string,
  isNew: PropTypes.bool,
};

export default LinkedLawyerList;
