import React, { useContext, useState, useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { CurrentUserContext } from 'js/components/current-user';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  GroupPermissionsContext,
  NamedPermissionsContext,
  NamedPermissionsProvider,
  userGroups,
} from 'js/components/group-permissions';
import {
  caseBillingValidationRules,
  caseBillingRequiredKeysForLawyerType,
  returnCanEditCase,
} from 'js/utilities/cases';
import CasePage from '../case-page';
import { CaseProvider, CaseContext } from '../case-context';
import { caseBillingNamedPermissions } from '../case-named-permissions';
import { CaseBillingForm } from '../case-forms';
import {
  onMountEffect,
  updateCaseBillingEffect,
  patchCaseBillingEffect,
  saveCaseBillingEffect,
} from './effects';

const CaseBilling = () => {
  const { t } = useTranslation();

  const { caseInfo, setCaseInfo, caseOptions } = useContext(CaseContext);
  const { currentUser = {} } = useContext(CurrentUserContext);
  const { caseInformation = {}, caseLawyer = {}, caseBilling = {} } = caseInfo;
  const { lawyerType = '' } = caseLawyer;
  const { payee = [] } = caseOptions;
  const { officeOpened = '', caseStatus = '', cif = '' } = caseInformation;

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const patchCaseBillingAbortControllerRef = useRef(null);
  const { matchAnyGroup } = useContext(GroupPermissionsContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);

  const [active, setActive] = useState(false);

  const { params = {} } = useRouteMatch();
  const caseNumber = decodeURIComponent(params.caseNumber);
  const isAdmin = matchAnyGroup([
    userGroups.administrator,
    userGroups.dataEntry,
  ]);
  const hasEditPermission = hasNamedPermission('editCaseBilling');

  const canEditCase = returnCanEditCase({
    officeOpened,
    currentUser,
    hasEditPermission,
    caseStatus,
    adminOnly: true,
    isNew: false,
    cif: cif,
  });

  const requiredFields = caseBillingRequiredKeysForLawyerType(lawyerType);
  const { isValid } = validate(caseBilling, requiredFields);
  const touchAll = () => touch(requiredFields);

  useEffect(
    onMountEffect({
      abortControllerRefs: [patchCaseBillingAbortControllerRef],
      setActive,
    }),
    []
  );

  const patchEffect = patchCaseBillingEffect({
    t,
    api,
    cache,
    isValid,
    caseNumber,
    caseInfo,
    setActive,
    touchAll,
    resetInteractionCount,
    patchCaseBillingAbortControllerRef,
  });

  const saveCaseBilling = saveCaseBillingEffect({
    t,
    saveEffect: patchEffect,
    presentStyledBanner,
  });

  return (
    <CasePage
      className="case-billing"
      title={t('components.CaseBilling.title')}
      actions={
        <button
          className="button button-highlight page-action-button"
          disabled={!canEditCase}
          onClick={saveCaseBilling}
        >
          {active ? t('common.saving') : t('common.save')}
        </button>
      }
    >
      <NavigationSaveModal
        proceedAfter={async () => await saveCaseBilling()}
        shouldBlockNavigation={() => canEditCase && interactionCount > 0}
      />
      <form
        onSubmit={(e) => e.preventDefault()}
        onClick={incrementInteractionCount}
      >
        <CaseBillingForm
          formData={caseBilling}
          caseInfo={caseInfo}
          formOptions={{ payee }}
          lawyerType={lawyerType}
          onChange={updateCaseBillingEffect({
            setCaseInfo,
          })}
          isAdmin={isAdmin}
          caseStatus={caseInformation.caseStatus}
        />
      </form>
    </CasePage>
  );
};

const CaseBillingWithContext = (props) => (
  <CaseProvider>
    <CaseBilling {...props} />
  </CaseProvider>
);

const CaseBillingWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={caseBillingNamedPermissions}>
    <CaseBillingWithContext {...props} />
  </NamedPermissionsProvider>
);

const ValidatedCaseBilling = (props) => (
  <ValidationProvider rules={caseBillingValidationRules}>
    <CaseBillingWithNamedPermissions {...props} />
  </ValidationProvider>
);

const InteractionTrackingCaseBilling = (props) => (
  <InteractionTrackingProvider>
    <ValidatedCaseBilling {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingCaseBilling;
