import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { breadcrumbTypes } from 'js/components/design-system';
import { userGroups } from 'js/components/group-permissions';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryFilteringSearchParamsForCaseTabs = (search = '') => {
  const permittedKeys = ['from'];
  const params = paramsFromSearchString(search);
  const filteredParams = permittedKeys.reduce((nextParams, key) => {
    if (params[key]) {
      return { ...nextParams, [key]: params[key] };
    } else {
      return nextParams;
    }
  }, {});
  return queryForParams(filteredParams);
};

export const linkPropsForRoutePath = (path, params = {}, search = '') => {
  const casePath = routePathReplacingParams(path, params);
  return {
    linkTo: `${casePath}${search}`,
    isActive: (currentPath) => currentPath === path,
  };
};

export const caseTabItems = (options = {}) => {
  const {
    t,
    params = {},
    search = '',
    hasBillingAccess,
    showCaseNotesCount,
    caseNotesCount,
    isAdmin,
  } = options;

  const infoTab = {
    ...linkPropsForRoutePath(routePaths.caseInformation, params, search),
    content: t('components.CaseInformation.title'),
  };

  const lawyerTab = {
    ...linkPropsForRoutePath(routePaths.caseLawyer, params, search),
    content: t('components.CaseLawyer.title'),
  };

  const billingTab = {
    ...linkPropsForRoutePath(routePaths.caseBilling, params, search),
    content: t('components.CaseBilling.title'),
  };

  const caseLogTab = {
    ...linkPropsForRoutePath(routePaths.caseLog, params, search),
    content: t('components.CaseLog.title'),
  };

  let caseNotesTabContent = t('components.CaseNotes.title');
  if (showCaseNotesCount) {
    caseNotesTabContent = `${caseNotesTabContent} (${caseNotesCount})`;
  }

  const caseNotesTab = {
    ...linkPropsForRoutePath(routePaths.caseNotes, params, search),
    content: caseNotesTabContent,
  };
  const tabs = [infoTab, lawyerTab, caseNotesTab];

  if (hasBillingAccess) {
    tabs.splice(2, 0, billingTab);
  }
  if (isAdmin) {
    tabs.push(caseLogTab);
  }
  return tabs;
};

export const caseBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;

  return [
    breadcrumbTypes.home,
    {
      content: t('components.CasePage.breadcrumbs.case'),
      path: currentPath,
    },
  ];
};

export const caseActionsPermittedGroups = [
  userGroups.administrator,
  userGroups.dataEntry,
  userGroups.intakeSpecialist,
  userGroups.feedbackSpecialist,
  userGroups.support,
];
