import {
  endOfQuarter,
  startOfYear,
  endOfYear,
  getYear,
  startOfMonth,
  endOfMonth,
  sub,
} from 'date-fns';
import { formatDateFromString } from 'js/utilities/dates';
import { DATE_RANGE_FILTER } from './functions';

const currentYear = getYear(new Date());
const previousYear = sub(new Date(), { years: 1 });
const previousMonth = sub(new Date(), { months: 1 });

// Monthly should set the date to the previous Month
// Yearly should set the date to the previous Year
// 1st Quarter should be from January 1 to March 31 of the current year.
// Half Year should be from January 1 to June 30 of the current year.
// 3rd Quarter - the date range should be to from January 1 to September 30 of the current year.
export const getDateRangeValues = () => ({
  generateFirstQuarterly: {
    startDate: startOfYear(new Date(currentYear, 1, 1)),
    endDate: endOfQuarter(new Date(currentYear, 2, 31)),
  },
  generateHalfYearly: {
    startDate: startOfYear(new Date(currentYear, 1, 1)),
    endDate: endOfQuarter(new Date(currentYear, 5, 30)),
  },
  generateThirdQuarterly: {
    startDate: startOfYear(new Date(currentYear, 1, 1)),
    endDate: endOfQuarter(new Date(currentYear, 8, 30)),
  },
  generateMonthly: {
    startDate: startOfMonth(previousMonth),
    endDate: endOfMonth(previousMonth),
  },
  generateYearly: {
    startDate: startOfYear(previousYear),
    endDate: endOfYear(previousYear),
  },
  custom: {
    startDate: '',
    endDate: '',
  },
});

export const resolveDatesForDateRange = (storage, dateRange) => {
  const nextRange = getDateRangeValues()[dateRange];
  if (dateRange === 'custom') {
    const {
      startDate: startDateString,
      endDate: endDateString,
    } = restoreDateRangeParams(storage);
    if (startDateString && endDateString) {
      const startDate = formatDateFromString(startDateString);
      const endDate = formatDateFromString(endDateString);
      if (startDate && endDate) {
        nextRange.startDate = startDate;
        nextRange.endDate = endDate;
      }
    }
  }
  return nextRange;
};

export const firstSelectedDateRangeForReport = (report = {}) => {
  const orderedDateRanges = [
    'generateMonthly',
    'generateFirstQuarterly',
    'generateHalfYearly',
    'generateThirdQuarterly',
    'generateYearly',
  ];

  const dateRange = orderedDateRanges.find((key) => report[key] === true);

  return dateRange || '';
};

export const resolveSelectedDateRangeParamsForReport = (
  storage,
  report = {},
  reportsFilterIndex
) => {
  const dateRange =
    reportsFilterIndex !== 0
      ? DATE_RANGE_FILTER[reportsFilterIndex]
      : firstSelectedDateRangeForReport(report);
  let params = { dateRange };
  if (dateRange) {
    const { startDate, endDate } = resolveDatesForDateRange(storage, dateRange);
    params = { ...params, startDate, endDate };
  }
  return params;
};

export const storageKeyForDateRangeParam = (name) => {
  return `com.redbitdev.uniforlsp.reports.dateRange.${name}`;
};

export const saveDateRangeParam = (storage, name, value) => {
  const key = storageKeyForDateRangeParam(name);
  storage.setItem(key, value);
};

export const restoreDateRangeParam = (storage, name) => {
  const key = storageKeyForDateRangeParam(name);
  return storage.getItem(key);
};

export const restoreDateRangeParams = (storage) => {
  const startDate = restoreDateRangeParam(storage, 'startDate');
  const endDate = restoreDateRangeParam(storage, 'endDate');
  return { startDate, endDate };
};
