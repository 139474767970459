import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InputLabel,
  InputHelp,
  StyledSelect,
} from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import {
  onInputFilterChangeEffect,
  onInputBlurEffect,
  insertFilterEffect,
} from './effects';
import {
  getOperatorOptions,
  filterValidationRules,
  filterRequiredFields,
} from './functions';

const ReportFilterForm = (props) => {
  const { t } = useTranslation();
  const { validate, touch } = useContext(ValidationContext);
  const {
    tableFields = [],
    isLoadingTableFields,
    filter,
    onUpdateFilter,
    canUpdateFilter,
  } = props;
  const [valueError, setValueError] = useState('');
  const [filterValues, setFilterValues] = useState({
    fieldName: '',
    operator: '',
    value: '',
  });

  const { isValid } = validate(filterValues, filterRequiredFields);

  const inputEventHandlers = {
    onChange: onInputFilterChangeEffect({ filterValues, setFilterValues }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const updateFilter = insertFilterEffect({
    t,
    filter,
    filterValues,
    onUpdateFilter,
    setValueError,
  });

  return (
    <div className="form-row report-filter-form-row">
      <InputLabel content={t('components.RunEditReportModal.fieldName')}>
        <StyledSelect
          name="fieldName"
          value={filterValues.fieldName}
          disabled={isLoadingTableFields}
          {...inputEventHandlers}
        >
          <option key={''} value={''} disabled>
            {t('common.defaultSelectPlaceholder')}
          </option>
          {tableFields.map((option, k) => (
            <option key={k} value={option}>
              {option}
            </option>
          ))}
        </StyledSelect>
      </InputLabel>

      <InputLabel content={t('components.RunEditReportModal.operator')}>
        <StyledSelect
          name="operator"
          value={filterValues.operator}
          {...inputEventHandlers}
        >
          <option key={''} value={''} disabled>
            {t('common.defaultSelectPlaceholder')}
          </option>
          {getOperatorOptions(t).map((option, k) => (
            <option key={k} value={option.value}>
              {option.title}
            </option>
          ))}
        </StyledSelect>
      </InputLabel>

      <InputHelp validationContent={valueError} invalid={!isEmpty(valueError)}>
        <InputLabel content={t('components.RunEditReportModal.value')}>
          <input
            type="text"
            name="value"
            value={filterValues.value}
            {...inputEventHandlers}
          />
        </InputLabel>
      </InputHelp>

      <button
        className="button button-highlight"
        onClick={updateFilter}
        disabled={!isValid || !canUpdateFilter}
      >
        {t('components.RunEditReportModal.insert')}
      </button>
    </div>
  );
};

ReportFilterForm.defaultProps = {
  isLoadingTableFields: false,
  canUpdateFilter: false,
};

ReportFilterForm.propTypes = {
  tableFields: PropTypes.array.isRequired,
  isLoadingTableFields: PropTypes.bool,
  filter: PropTypes.string.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  canUpdateFilter: PropTypes.bool,
};

const ValidatedReportFilterForm = (props) => (
  <ValidationProvider rules={filterValidationRules}>
    <ReportFilterForm {...props} />
  </ValidationProvider>
);

export default ValidatedReportFilterForm;
