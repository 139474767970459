import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ListCard } from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

const ProvincesListCard = (props) => {
  const { province = {} } = props;
  const { id, name, isActive, cities = [] } = province;

  const headingContent = (
    <Fragment>
      <span>{id}</span>
      <span>{name}</span>
      <InactiveTag inactive={!isActive} />
    </Fragment>
  );

  return (
    <Fragment>
      <div data-screen-only>
        <ListCard
          className="provinces-list-card"
          heading={headingContent}
          linkTo={routePathReplacingParams(routePaths.province, {
            provinceId: id,
          })}
        />
      </div>
      <div className="provinces-list-card-print" data-print-only>
        <h3>{headingContent}</h3>
        <ul className="unstyled-list">
          {cities.map((city) => (
            <li key={city.cityCode}>
              <span>{city.cityCode}</span>
              <span>{city.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

ProvincesListCard.propTypes = {
  province: PropTypes.object.isRequired,
};

export default ProvincesListCard;
