import React, { createContext, useState } from 'react';

const LawyerContext = createContext({
  lawyer: {},
  setLawyer: () => {},
  isLoadingLawyer: false,
  setLoadingLawyer: () => {},
  lawyerFeedback: [],
  setLawyerFeedback: () => {},
  isLoadingLawyerFeedback: false,
  setLoadingLawyerFeedback: () => {},
});

const LawyerProvider = (props) => {
  const [lawyer, setLawyer] = useState({});
  const [isLoadingLawyer, setLoadingLawyer] = useState(false);
  const [lawyerFeedback, setLawyerFeedback] = useState([]);
  const [isLoadingLawyerFeedback, setLoadingLawyerFeedback] = useState(false);

  return (
    <LawyerContext.Provider
      value={{
        lawyer,
        setLawyer,
        isLoadingLawyer,
        setLoadingLawyer,
        lawyerFeedback,
        setLawyerFeedback,
        isLoadingLawyerFeedback,
        setLoadingLawyerFeedback,
      }}
    >
      {props.children}
    </LawyerContext.Provider>
  );
};

export { LawyerContext, LawyerProvider };
