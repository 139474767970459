import React, { useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Page from 'js/components/page';
import SearchHeader from 'js/components/headers/search-header';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  FAB,
  FABPosition,
  FontAwesomeIcon,
  FABOffset,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  GroupPermissionsGate,
  NamedPermissionsContext,
} from 'js/components/group-permissions';
import { isNewClientPath, isNewDependentPath } from 'js/utilities/clients';
import { getClientNotesEffect } from '../client-notes/effects';
import { queryForSearchString as clientNotesQueryForSearchString } from '../client-notes/functions';
import { ClientContext } from '../client-context';
import ClientBreadcrumbs from './breadcrumbs';
import ClientTabs from './tabs';
import {
  onMountEffect,
  outstandingBalanceBannerEffect,
  getClientEffect,
  prepNewClientEffect,
  postClientActiveEffect,
  getClientNotesConditionalEffect,
} from './effects';
import {
  titleForNewClientOrDependent,
  clientActionsPermittedGroups,
} from './functions';

const ClientPage = (props) => {
  const { className, title, actions, children, ...pageProps } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const { path, params = {} } = useRouteMatch();
  const { id, clientCode = '00' } = params;

  let clientNotesQuery;
  if (path === routePaths.caseNotes) {
    const { search } = useLocation();
    clientNotesQuery = clientNotesQueryForSearchString(search);
  }

  const getClientAbortControllerRef = useRef(null);
  const getClientNotesAbortControllerRef = useRef(null);
  const prepClientAbortControllerRef = useRef(null);
  const postClientActiveAbortControllerRef = useRef(null);
  const unsetCompanyChangedAbortControllerRef = useRef(null);

  const {
    client,
    setClient,
    isLoadingClient,
    setLoadingClient,
    clientNotes,
    setClientNotes,
    isLoadingClientNotes,
    setLoadingClientNotes,
    hasOutstandingBalance,
    setHasOutstandingBalance,
  } = useContext(ClientContext);

  const [isSavingClient, setSavingClient] = useState(false);
  const [viewModal, showModal] = useState(false);

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );

  const isNewClient = isNewClientPath(path);
  const isNewDependent = isNewDependentPath(path);
  const isNew = isNewClient || isNewDependent;
  const isPrimaryClient = clientCode === '00' && !isNewDependent;
  const { clientPersonal = {} } = client;
  const {
    firstName = '',
    lastName = '',
    clientInactive = false,
    clientStatus = '',
  } = clientPersonal;

  const titleText = isPrimaryClient
    ? t('components.ClientPage.clientTitle', { title })
    : t('components.ClientPage.dependentTitle', { title });
  const clientStatusText = t(`common.clientStatusByCode.${clientStatus}`);
  const clientSubheadingText = isPrimaryClient
    ? t('components.ClientPage.clientSubheading')
    : t('components.ClientPage.dependentSubheading');
  const subheadingText = isNew
    ? clientSubheadingText
    : t('components.ClientPage.subheading', {
        clientSubheadingText,
        id,
        clientCode,
        clientStatus,
        clientStatusText,
      });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getClientAbortControllerRef,
        getClientNotesAbortControllerRef,
        prepClientAbortControllerRef,
        postClientActiveAbortControllerRef,
        unsetCompanyChangedAbortControllerRef,
      ],
      setLoadingClient,
      setSavingClient,
    }),
    []
  );

  useEffect(
    outstandingBalanceBannerEffect({
      t,
      history,
      presentStyledBanner,
      dismissBanner,
      hasPermission: hasNamedPermission('viewClientOsNotes'),
      hasOutstandingBalance,
      currentRoutePath: path,
      redirectPath: routePathReplacingParams(
        routePaths.clientOutstandingBalanceNotes,
        { id, clientCode: '00' }
      ),
      search,
    }),
    [hasOutstandingBalance]
  );

  useEffect(
    getClientEffect({
      isNew,
      t,
      api,
      cache,
      id,
      clientCode,
      setLoadingClient,
      setClient,
      setHasOutstandingBalance,
      getClientAbortControllerRef,
    }),
    [isNew, id]
  );

  useEffect(
    prepNewClientEffect({
      isNew,
      t,
      api,
      cache,
      id,
      setLoadingClient,
      setClient,
      prepClientAbortControllerRef,
    }),
    [isNew, id]
  );

  useEffect(
    getClientNotesConditionalEffect({
      isNew,
      getClientNotes: getClientNotesEffect({
        t,
        api,
        cache,
        id,
        clientCode,
        query: clientNotesQuery,
        setLoadingClientNotes,
        setClientNotes,
        getClientNotesAbortControllerRef,
      }),
    }),
    [isNew, id]
  );

  const setActiveState = postClientActiveEffect({
    t,
    api,
    cache,
    id,
    clientCode,
    setSavingClient,
    client,
    clientInactive: !clientInactive,
    setClient,
    showModal,
    postClientActiveAbortControllerRef,
  });

  return (
    <Page
      className={classnames('client-page', className)}
      title={titleText}
      header={<SearchHeader />}
      {...pageProps}
    >
      <div className="layout-container">
        <div className="layout-column">
          <ClientBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          {isLoadingClient && <ActivityIndicatorCard />}
          {!isLoadingClient && (
            <React.Fragment>
              <div className="client-page-heading">
                <div className="client-page-heading-left">
                  <div>
                    <p>{subheadingText}</p>
                    <h1>
                      {isNew
                        ? titleForNewClientOrDependent(t, isNewDependent)
                        : `${lastName}, ${firstName}`}
                    </h1>
                  </div>
                  <DeactivateToggle
                    disabled={isNew}
                    isActive={!clientInactive}
                    onActivate={setActiveState}
                    onDeactivate={() => showModal(true)}
                  />
                  <DeactivateModal
                    title={t(
                      'components.ClientInformation.DeactivateModal.title'
                    )}
                    message={t(
                      'components.ClientInformation.DeactivateModal.message'
                    )}
                    mounted={viewModal}
                    onClose={() => showModal(false)}
                    onSave={setActiveState}
                    active={isSavingClient}
                  />
                </div>
                <div className="client-page-heading-right">{actions}</div>
              </div>

              {Object.keys(client).length > 1 && (
                <ClientTabs
                  disabled={isNew}
                  showClientNotesCount={!isLoadingClientNotes}
                  clientNotesCount={clientNotes.length}
                />
              )}
              <div className="client-page-content">{children}</div>
            </React.Fragment>
          )}
        </div>
      </div>
      {!isNewClient && !isNewDependent && (
        <GroupPermissionsGate permittedGroups={clientActionsPermittedGroups}>
          <FABPosition>
            <FAB
              actions={[
                {
                  icon: <FontAwesomeIcon icon={faPlus} />,
                  title: t('components.CaseInformation.createCase'),
                  linkTo: routePathReplacingParams(routePaths.newCase, {
                    id,
                    clientCode,
                  }),
                },
              ]}
            />
          </FABPosition>
          <FABOffset />
        </GroupPermissionsGate>
      )}
    </Page>
  );
};

ClientPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
};

export default ClientPage;
