import { cleanStringField } from 'js/utilities/strings';

export const cleanLawFirm = (lawFirm = {}) => {
  return {
    ...lawFirm,
    firmName: cleanStringField(lawFirm.firmName),
    address: cleanStringField(lawFirm.address),
    address2: cleanStringField(lawFirm.address2),
    city: cleanStringField(lawFirm.city),
    email: cleanStringField(lawFirm.email),
    fax: cleanStringField(lawFirm.fax),
    postalCode: cleanStringField(lawFirm.postalCode),
    primaryPhone: cleanStringField(lawFirm.primaryPhone),
    province: cleanStringField(lawFirm.province),
    secondaryPhone: cleanStringField(lawFirm.secondaryPhone),
  };
};

export const cleanLawFirms = (lawFirms = []) => lawFirms.map(cleanLawFirm);
