import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { StyledBannerContext } from 'js/components/banner-styled';
import { VersionInfoContext } from 'js/components/version-info';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { formatDateFromISOString } from 'js/utilities/dates';
import { preprendHttpScheme } from 'js/utilities/strings';
import DatabaseBackupsBreadcrumbs from './breadcrumbs';
import DatabaseTable from './database-table';
import DatabaseBackupsList from './database-backups-list';
import DatabaseRestoreModal from './database-restore-modal';
import {
  onMountEffect,
  getDatabaseRestoreStatusEffect,
  getDatabaseSessionStatusEffect,
  pollDatabaseSessionStatusEffect,
  getDatabaseBackupsEffect,
  restoreDatabaseEffect,
  onConfirmDatabaseRestoreEffect,
  onCancelDatabaseRestoreEffect,
} from './effects';
import { DATABASE_EXPORT_TYPE } from './functions';

const DatabaseBackups = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);

  const { versionInfo = {} } = useContext(VersionInfoContext);
  const { reportingRestoreEnvironment = {} } = versionInfo;
  const { webServer: reportingWebServer } = reportingRestoreEnvironment;

  const getDatabaseBackupsAbortControllerRef = useRef(null);
  const restoreDatabaseAbortControllerRef = useRef(null);
  const getDatabaseRestoreStatusAbortControllerRef = useRef(null);
  const getDatabaseSessionStatusAbortControllerRef = useRef(null);

  const pollingIntervalRef = useRef(0);

  const [isLoading, setLoading] = useState(false);
  const [isRestoring, setRestoring] = useState(false);
  const isActive = isLoading || isRestoring;

  const [database, setDatabase] = useState({});
  const [reportingDatabase, setReportingDatabase] = useState({});
  const [dailyExports, setDailyExports] = useState([]);
  const [weeklyExports, setWeeklyExports] = useState([]);
  const [monthlyExports, setMonthlyExports] = useState([]);
  const [yearlyExports, setYearlyExports] = useState([]);
  const [exportToRestore, setExportToRestore] = useState({});

  const [sessionId, setSessionId] = useState('');
  const [logs, setLogs] = useState([]);

  const getDatabaseBackups = getDatabaseBackupsEffect({
    t,
    api,
    setLoading,
    setDatabase,
    setReportingDatabase,
    setDailyExports,
    setWeeklyExports,
    setMonthlyExports,
    setYearlyExports,
    getDatabaseBackupsAbortControllerRef,
  });

  useEffect(
    onMountEffect({
      pollingIntervalRef,
      abortControllerRefs: [
        getDatabaseBackupsAbortControllerRef,
        restoreDatabaseAbortControllerRef,
        getDatabaseRestoreStatusAbortControllerRef,
        getDatabaseSessionStatusAbortControllerRef,
      ],
      setLoading,
    }),
    []
  );

  useEffect(
    getDatabaseRestoreStatusEffect({
      t,
      api,
      setLoading,
      setRestoring,
      setSessionId,
      getDatabaseRestoreStatusAbortControllerRef,
      getDatabaseBackups,
    }),
    []
  );

  const getDatabaseSessionStatus = getDatabaseSessionStatusEffect({
    t,
    api,
    setRestoring,
    sessionId,
    setLogs,
    setSessionId,
    getDatabaseSessionStatusAbortControllerRef,
    getDatabaseBackups,
  });

  useEffect(getDatabaseSessionStatus, [sessionId]);

  useEffect(
    pollDatabaseSessionStatusEffect({
      pollingIntervalRef,
      sessionId,
      getDatabaseSessionStatus,
    }),
    [sessionId]
  );

  return (
    <Page
      className="database-backups-page"
      title={t('components.DatabaseBackups.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <DatabaseBackupsBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {isActive && (
            <Fragment>
              <ActivityIndicatorCard
                message={
                  isRestoring
                    ? t(
                        'components.DatabaseBackups.activityIndicator.restoring'
                      )
                    : t('components.DatabaseBackups.activityIndicator.loading')
                }
              />
              <ul className="logs">
                {logs.map((log, idx) => (
                  <li key={idx}>
                    {formatDateFromISOString(
                      log.timestamp,
                      "yyyy/MM/dd 'at' h:mm:ss a"
                    )}
                    : {log.message}
                  </li>
                ))}
              </ul>
            </Fragment>
          )}

          {!isActive && (
            <Fragment>
              <div className="database-backups-heading">
                <h1>{t('components.DatabaseBackups.heading.page')}</h1>
                {reportingWebServer && (
                  <a
                    className="button button-highlight"
                    href={preprendHttpScheme(reportingWebServer)}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {t('components.DatabaseBackups.reportingEnvLink')}
                  </a>
                )}
              </div>

              <h2>{t('components.DatabaseBackups.heading.database')}</h2>

              <DatabaseTable
                database={database}
                reportingDatabase={reportingDatabase}
              />

              <DatabaseBackupsList
                type={DATABASE_EXPORT_TYPE.DAILY}
                backups={dailyExports}
                onClickRestore={setExportToRestore}
              />

              <DatabaseBackupsList
                type={DATABASE_EXPORT_TYPE.WEEKLY}
                backups={weeklyExports}
                onClickRestore={setExportToRestore}
              />

              <DatabaseBackupsList
                type={DATABASE_EXPORT_TYPE.MONTHLY}
                backups={monthlyExports}
                onClickRestore={setExportToRestore}
              />

              <DatabaseBackupsList
                type={DATABASE_EXPORT_TYPE.YEARLY}
                backups={yearlyExports}
                onClickRestore={setExportToRestore}
              />
            </Fragment>
          )}
        </div>
      </div>

      <DatabaseRestoreModal
        backup={exportToRestore}
        mounted={!!exportToRestore.sessionId}
        onConfirm={onConfirmDatabaseRestoreEffect({
          setExportToRestore,
          restoreDatabase: restoreDatabaseEffect({
            t,
            api,
            setRestoring,
            getDatabaseBackups,
            restoreDatabaseAbortControllerRef,
            presentStyledBanner,
            setLogs,
            setSessionId,
          }),
        })}
        onCancel={onCancelDatabaseRestoreEffect({
          setExportToRestore,
        })}
      />
    </Page>
  );
};

export default DatabaseBackups;
