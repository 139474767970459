import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  faDownload,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import { onClickDocumentEffect, deleteFileEffect } from './effects';
import { feedbackPropTypes } from './functions';
import { PromptContext } from 'js/components/prompt';
import { FetchContext } from 'js/components/fetch';

const LawyerFeedbackDocuments = (props) => {
  const { t } = useTranslation();
  const { prompt } = useContext(PromptContext);
  const { api = {} } = useContext(FetchContext);
  const [isActive, setActive] = useState(false);
  const deleteFileAbortControllerRef = useRef(null);
  const [documentIdPendingRetrieval, setDocumentIdPendingRetrieval] = useState(
    ''
  );
  const {
    className,
    feedback = {},
    onClickDocument,
    lawyer = {},
    getLawyerFeedback,
  } = props;
  const { feedbackId, feedBackDocuments = [] } = feedback;
  const { lawyerId = '', staffId = '' } = lawyer;
  const nextLawyerId = lawyerId ? lawyerId : staffId;

  const deleteFile = deleteFileEffect({
    t,
    api,
    prompt,
    setActive,
    deleteFileAbortControllerRef,
    lawyerId: nextLawyerId,
    onComplete: getLawyerFeedback,
  });

  return (
    <ul
      className={classnames(
        'unstyled-list',
        'lawyer-feedback-documents',
        className
      )}
    >
      {feedBackDocuments.map(({ docId, docName, docType }) => {
        const isPendingRetrieval = docId === documentIdPendingRetrieval;
        const icon = isPendingRetrieval ? faSpinner : faDownload;
        return (
          <li key={docId}>
            <span>{`${docName}.${docType}`}</span>
            <div>
              <button
                disabled={!!documentIdPendingRetrieval || isActive}
                onClick={() => deleteFile(docId)}
              >
                <FontAwesomeIcon
                  className={classnames({ 'fa-spin': isPendingRetrieval })}
                  icon={faTrash}
                />
              </button>

              <button
                disabled={!!documentIdPendingRetrieval || isActive}
                onClick={onClickDocumentEffect({
                  feedbackId,
                  docId,
                  setDocumentIdPendingRetrieval,
                  onClickDocument,
                })}
              >
                <FontAwesomeIcon
                  className={classnames({ 'fa-spin': isPendingRetrieval })}
                  icon={icon}
                />
              </button>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

LawyerFeedbackDocuments.propTypes = {
  className: PropTypes.string,
  feedback: PropTypes.shape(feedbackPropTypes).isRequired,
  onClickDocument: PropTypes.func.isRequired,
  lawyer: PropTypes.object,
  getLawyerFeedback: PropTypes.func,
};

export default LawyerFeedbackDocuments;
