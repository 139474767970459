import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const lawyerTypesBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = systemSettingsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.LawyerTypes.breadcrumbs.lawyerTypes'),
      path: currentPath,
    },
  ];

  return history;
};

export const scheduleScrollToLastCardInList = (listElement) => {
  // Wait for the height transition to end before scrolling
  // to the top of the last card element in the list:
  const onTransitionEnd = (e) => {
    const { propertyName, target } = e;
    const isHeightTransition = propertyName === 'height';
    const isCardContentTransition = target.classList.contains(
      'lawyer-type-card-content'
    );
    const isNewCardTransition = isHeightTransition && isCardContentTransition;
    if (isNewCardTransition) {
      listElement.removeEventListener('transitionend', onTransitionEnd);
      const lastCardElement = listElement.querySelector(
        `li:last-child .lawyer-type-card`
      );
      if (lastCardElement) {
        const { top = 0 } = lastCardElement.getBoundingClientRect();
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      }
    }
  };

  listElement.addEventListener('transitionend', onTransitionEnd);
};
