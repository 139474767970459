import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../../components/icons';
import { onClickActionButtonEffect, onActionKeyDownEffect } from './effects';
import { actionPropTypes, fabActionHeight } from './functions';

const defaultActivityIcon = (
  <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
);

const FABAction = (props) => {
  const { className, action = {}, accessory, tabIndex } = props;
  const {
    title,
    icon,
    activityIcon = defaultActivityIcon,
    onClick,
    disabled,
    active: externalActive,
  } = action;
  const [active, setActive] = useState(false);
  const showActiveIcon = (externalActive || active) && activityIcon;
  return (
    <button
      className={classnames('fab-action', className)}
      type="button"
      onClick={onClickActionButtonEffect({ setActive, onClick })}
      onKeyDown={onActionKeyDownEffect()}
      disabled={disabled}
      style={{ height: fabActionHeight }}
      tabIndex={tabIndex}
    >
      {(showActiveIcon || icon) && (
        <span className="fab-action-icon">
          {showActiveIcon ? activityIcon : icon}
        </span>
      )}
      <span className="fab-action-title">{title}</span>
      {accessory && <span className="fab-action-accessory">{accessory}</span>}
    </button>
  );
};

FABAction.propTypes = {
  className: PropTypes.string,
  action: actionPropTypes,
  accessory: PropTypes.node,
  tabIndex: PropTypes.number,
};

export default FABAction;
