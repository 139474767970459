import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';
import { isEmpty } from 'js/utilities/validation';
import FolderList from './folder-list';

const MoveReportModal = (props) => {
  const { t } = useTranslation();
  const { className, mounted, onDismiss, onConfirm, isActive } = props;
  const [nextFolder, setNextFolder] = useState({});
  const hasNextFolder = !isEmpty(nextFolder);

  return (
    <Modal
      className={classnames('move-report-modal', className)}
      onClickCancel={onDismiss}
      titleText={t('components.MoveReportModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
    >
      <div>
        <h2>{t('components.MoveReportModal.title')}</h2>
        {!hasNextFolder && (
          <Fragment>
            <p>{t('components.MoveReportModal.text')}</p>
            <FolderList onSelectFolder={setNextFolder} />
            <div className="button-bar">
              <button
                className="button-link-appearance"
                disabled={isActive}
                onClick={onDismiss}
              >
                {t('common.cancel')}
              </button>
              <button className="button button-highlight" disabled>
                {t('components.MoveReportModal.moveButton')}
              </button>
            </div>
          </Fragment>
        )}
        {hasNextFolder && (
          <Fragment>
            <p>
              {t('components.MoveReportModal.moveText', {
                folderName: nextFolder.name,
              })}
            </p>
            <div className="button-bar">
              <button
                className="button-link-appearance"
                disabled={isActive}
                onClick={() => setNextFolder({})}
              >
                {t('common.back')}
              </button>
              <button
                className="button button-highlight"
                disabled={isActive}
                onClick={() => onConfirm(nextFolder.id)}
              >
                {isActive
                  ? t('common.creating')
                  : t('components.MoveReportModal.moveButton')}
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

MoveReportModal.defaultProps = {
  mounted: false,
};

MoveReportModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default MoveReportModal;
