import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { InputHelp, InputLabel } from 'js/components/design-system';
import {
  onMountClientStatusModalEffect,
  onSubmitClientStatusFormEffect,
  onChangePrimaryClientStatusEffect,
  onInputBlurEffect,
} from './effects';
import {
  clientStatusValidationRules,
  clientStatusRequiredFields,
} from './functions';
import { isEmpty } from 'js/utilities/validation';

const ClientStatusModal = (props) => {
  const { t } = useTranslation();

  const { mounted, item = {}, isSaving, onSubmit, onCancel } = props;
  const { cas_cstat: caseClientStatus = '', cif = '' } = item;

  const [primaryClientStatus, setPrimaryClientStatus] = useState('');

  const formData = { cif, primaryClientStatus };

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const validateAll = (data) => validate(data, clientStatusRequiredFields);
  const touchAll = () => touch(clientStatusRequiredFields);
  const untouchAll = () => untouch(clientStatusRequiredFields);
  const { invalidFields = [] } = validateAll(formData);

  useEffect(
    onMountClientStatusModalEffect({
      mounted,
      setPrimaryClientStatus,
      untouchAll,
    }),
    [mounted]
  );

  return (
    <Modal
      className="client-status-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={onCancel}
      titleText={t('components.PrimaryClientIntegrity.ClientStatusModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.PrimaryClientIntegrity.ClientStatusModal.title')}</h2>
      <form
        onSubmit={onSubmitClientStatusFormEffect({
          validateAll,
          touchAll,
          formData,
          onSubmit,
        })}
      >
        <div className="form-row">
          <span>
            {t('components.PrimaryClientIntegrity.ClientStatusModal.cif')}
          </span>
          <span>{cif}</span>
        </div>
        <div className="form-row">
          <span>
            {t(
              'components.PrimaryClientIntegrity.ClientStatusModal.caseClientStatus'
            )}
          </span>
          <span>{caseClientStatus}</span>
        </div>
        <div className="form-row">
          <InputHelp
            validationContent={t(
              'components.PrimaryClientIntegrity.ClientStatusModal.primaryClientStatusValidation'
            )}
            invalid={
              isTouched('primaryClientStatus') &&
              invalidFields.includes('primaryClientStatus')
            }
          >
            <InputLabel
              content={t(
                'components.PrimaryClientIntegrity.ClientStatusModal.primaryClientStatus'
              )}
            >
              <input
                type="text"
                name="primaryClientStatus"
                value={primaryClientStatus}
                disabled={isSaving}
                onChange={onChangePrimaryClientStatusEffect({
                  setPrimaryClientStatus,
                })}
                onBlur={onInputBlurEffect({ touch })}
              />
            </InputLabel>
          </InputHelp>
        </div>

        <div className="button-bar">
          <button
            type="button"
            className="button-link-appearance"
            disabled={isSaving}
            onClick={onCancel}
          >
            {t(
              'components.PrimaryClientIntegrity.ClientStatusModal.cancelButton'
            )}
          </button>
          <button
            type="submit"
            className="button button-highlight"
            disabled={isSaving || isEmpty(primaryClientStatus)}
          >
            {isSaving
              ? t(
                  'components.PrimaryClientIntegrity.ClientStatusModal.saveButtonActive'
                )
              : t(
                  'components.PrimaryClientIntegrity.ClientStatusModal.saveButton'
                )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

ClientStatusModal.propTypes = {
  mounted: PropTypes.bool,
  item: PropTypes.shape({}).isRequired,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

const ValidatedClientStatusModal = (props) => (
  <ValidationProvider rules={clientStatusValidationRules}>
    <ClientStatusModal {...props} />
  </ValidationProvider>
);

export default ValidatedClientStatusModal;
