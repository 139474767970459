import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const staffLawyerRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setStaffLawyers,
    setTotalResults,
    abortControllerRef,
    officeOpened,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }
    //0810 is the brampton office,
    //0800 is the metro office, they share the same lawyers so send the metro code
    //as brampton doesnt have any lawyers
    const nextOffice = officeOpened === '0810' ? '0800' : officeOpened;
    const endpoint = `/Lawyer/Staff${query.search}&OpenInOfficeCode=${nextOffice}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.StaffLawyerModal.searchError') },
          },
        }
      );
      const { staffLawyerInfo = [], totalCount = 0 } = json;
      setStaffLawyers(staffLawyerInfo);
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousStaffLawyerResultsEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    onChange({ nextQueryParams: nextParams });
  };
};

export const nextStaffLawyerResultsEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    onChange({ nextQueryParams: nextParams });
  };
};

export const staffLawyerResultsPageNumberEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    onChange({ nextQueryParams: nextParams });
  };
};

export const onSearchChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (value = '') => {
    onChange({ param: 'Keyword', value });
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      const nextQueryParams = { [name]: value, Offset: 0 };
      onChange({ nextQueryParams });
    }
  };
};
