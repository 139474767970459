import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Overlay = (props) => {
  const { className, visible, children } = props;
  return (
    <div className={classnames('overlay-container', className)}>
      {children}
      {visible && <div className="overlay" />}
    </div>
  );
};

Overlay.defaultProps = {
  visible: false,
};

Overlay.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
};

export default Overlay;
