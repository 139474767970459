import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const searchSortOptions = [
  {
    key: 'firstname-asc',
    field: 'firstname',
    direction: 'asc',
    i18nKey: 'components.SearchSortMenu.firstNameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'firstname-desc',
    field: 'firstname',
    direction: 'desc',
    i18nKey: 'components.SearchSortMenu.firstNameDesc',
    icon: faSortAlphaDownAlt,
  },
  {
    key: 'lastname-asc',
    field: 'lastname',
    direction: 'asc',
    i18nKey: 'components.SearchSortMenu.lastNameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'lastname-desc',
    field: 'lastname',
    direction: 'desc',
    i18nKey: 'components.SearchSortMenu.lastNameDesc',
    icon: faSortAlphaDownAlt,
  },
];
