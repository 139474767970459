import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { unionBreadcrumbsHistory } from './functions';

const UnionBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="union-breadcrumbs"
      history={unionBreadcrumbsHistory(t, location)}
    />
  );
};

export default UnionBreadcrumbs;
