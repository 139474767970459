import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { StyledBannerContext } from 'js/components/banner-styled';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import {
  onMountEffect,
  getReleaseEffect,
  onChangeLPCodesEffect,
  saveReleaseEffect,
  closeModalEffect,
  saveAndCloseModalEffect,
  getNewReleaseEffect,
  completeNewReleaseEffect,
  noEligibleCompaniesBannerEffect,
} from './effects';
import EmptyState from 'js/components/controls/empty-state';
import { Breadcrumbs } from 'js/components/design-system';
import {
  releaseBreadcrumbsHistory,
  getReleaseColumns,
  returnFilteredData,
} from './functions';
import { SortableTable } from 'js/components/design-system';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import ReleaseHeader from './release-header';
import ManageCodesModal from './manage-codes-modal';
import ReleaseTabs from './release-tabs';
import { Redirect } from 'react-router-dom';

const ReleaseLPCodes = () => {
  const { t } = useTranslation();
  const { api } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );
  const getAbortControllerRef = useRef(null);
  const postAbortControllerRef = useRef(null);
  const getNewAbortControllerRef = useRef(null);
  const location = useLocation();
  const {
    path,
    params: { scheduleId = '', effectiveDate = '' },
  } = useRouteMatch();
  const isNew = effectiveDate === 'new';
  const isCopy = path.includes('copy');

  const [release, setRelease] = useState({});
  const [active, setActive] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [mounted, setMounted] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  const { legalProblemCodeSelectOptions = [], endDate } = release;
  const isArchived = endDate !== null;
  const selectedProblemCodes = legalProblemCodeSelectOptions.filter(
    (lpc) => lpc.selected === true
  );

  const filteredLPC = returnFilteredData(selectedProblemCodes, searchString);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    noEligibleCompaniesBannerEffect({
      t,
      presentStyledBanner,
      dismissBanner,
      release,
      active,
      isArchived,
    }),
    [release, active]
  );

  const getRelease = getReleaseEffect({
    t,
    api,
    setActive,
    setRelease,
    scheduleId,
    effectiveDate,
    getAbortControllerRef,
    cache,
    isNew,
    isCopy,
  });

  useEffect(
    getNewReleaseEffect({
      t,
      api,
      setActive,
      setRelease,
      scheduleId,
      effectiveDate,
      getNewAbortControllerRef,
      cache,
      isNew,
      isCopy,
    }),
    []
  );

  useEffect(getRelease, [effectiveDate]);

  const closeModal = closeModalEffect({
    setMounted,
    cache,
    scheduleId,
    effectiveDate,
    setRelease,
  });

  const saveRelease = saveReleaseEffect({
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    postAbortControllerRef,
    release,
    cache,
    onComplete: getRelease,
    presentStyledBanner,
  });

  const saveNewRelease = completeNewReleaseEffect({
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    postAbortControllerRef,
    release,
    cache,
    onComplete: closeModal,
    presentStyledBanner,
    setRedirectPath,
    isCopy,
  });

  return (
    <Page
      className="release-page"
      title={t('components.FeeSchedulePage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <Breadcrumbs
            className="release-breadcrumbs"
            history={releaseBreadcrumbsHistory(t, location)}
          />
        </div>
      </div>
      {redirectPath && <Redirect to={redirectPath} />}
      {active && <ActivityIndicatorCard />}
      {mounted && (
        <ManageCodesModal
          title={t('components.ReleasePage.manageLpCode.title')}
          mounted={mounted}
          active={active}
          onClose={closeModal}
          legalProblemCodes={legalProblemCodeSelectOptions}
          onChange={onChangeLPCodesEffect({
            release,
            setRelease,
          })}
          onSave={saveAndCloseModalEffect({
            setMounted,
            cache,
            scheduleId,
            effectiveDate,
            release,
          })}
          isCopy={isCopy}
        />
      )}
      {!active && (
        <Fragment>
          <div className="layout-container inset-col-1">
            <div className="layout-column">
              <ReleaseHeader
                release={release}
                isNew={isNew}
                isCopy={isCopy}
                onCreate={saveNewRelease}
                onSave={saveRelease}
                isArchived={isArchived}
              />
            </div>
          </div>
          <div className="layout-container inset-col-1">
            <div className="layout-column">
              <ReleaseTabs
                className="release-tabs"
                currentPath={path}
                isCopy={isCopy}
                isNew={isNew}
                params={{ scheduleId, effectiveDate }}
              />
              <div className="form-row search-header">
                <GeneralSearchInput
                  placeholder={t('components.ReleasePage.searchPlaceholder')}
                  onSearch={setSearchString}
                  onReset={() => setSearchString('')}
                  disabled={active}
                />
                <button
                  className="button button-highlight"
                  onClick={() => setMounted(true)}
                  disabled={mounted}
                >
                  {t('components.ReleasePage.manageCodes')}
                </button>
              </div>
              {/* this text is necessary because we do not have access to all values until release updates */}
              <p className="warning-text">
                {t(`components.ReleasePage.updateWarning`)}
              </p>
              {!active && filteredLPC.length > 0 && (
                <SortableTable
                  columns={getReleaseColumns(t)}
                  data={filteredLPC}
                  initialSortColumnKey={'code'}
                />
              )}
              {!active &&
                legalProblemCodeSelectOptions.length === 0 &&
                filteredLPC.length === 0 && (
                  <EmptyState
                    title={t(`components.ReleasePage.legalProblemCodesEmpty`)}
                  />
                )}
              {!active &&
                filteredLPC.length === 0 &&
                !isEmpty(searchString) && (
                  <EmptyState
                    title={t(
                      `components.ReleasePage.legalProblemCodesEmptyFilter`
                    )}
                  />
                )}
            </div>
          </div>
        </Fragment>
      )}
    </Page>
  );
};

export default ReleaseLPCodes;
