import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';
import { returnlawyerTypeFromId } from 'js/utilities/lawyers';

const LawyerPrintHeader = (props) => {
  const { t } = useTranslation();
  const { className, title, lawyer = {}, ...headerProps } = props;
  const { lawyerInfo = {} } = lawyer;
  const {
    lawyerId,
    staffId,
    firstName = '',
    lastName = '',
    lawyerInactive = false,
  } = lawyerInfo;

  let displayTitle = title;
  if (!displayTitle) {
    const approxLawyerType = returnlawyerTypeFromId(lawyerId || staffId) || 0;
    displayTitle = t(`common.LawyerTypesByEnum.${approxLawyerType}`);
  }

  return (
    <PrintHeader
      className={classnames('lawyer-print-header', className)}
      title={displayTitle}
      heading={
        <Fragment>
          <span>{`${lastName}, ${firstName}`}</span>
          <span>
            {lawyerInactive
              ? t('common.lawyerStatus.inactive')
              : t('common.lawyerStatus.active')}
          </span>
        </Fragment>
      }
      {...headerProps}
    />
  );
};

LawyerPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  lawyer: PropTypes.shape({
    lawyerInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default LawyerPrintHeader;
