import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';
import { isEmpty } from 'js/utilities/validation';

const ReportResultModal = (props) => {
  const { className, onDismiss, reportResult = {} } = props;
  const { objectUrl = '', filename = '' } = reportResult;
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames('report-result-modal', className)}
      onClickCancel={onDismiss}
      titleText={t('components.ReportsFolderDetail.reportResultModal.title')}
      mounted={!isEmpty(objectUrl)}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
    >
      <h2>{t('components.ReportsFolderDetail.reportResultModal.title')}</h2>
      <p>{t('components.ReportsFolderDetail.reportResultModal.text')}</p>
      <div className="button-bar">
        {!isEmpty(filename) && (
          <a className="button" href={objectUrl} download={filename}>
            {t('components.ReportsFolderDetail.reportResultModal.download')}
          </a>
        )}
        <button
          className="button"
          onClick={() =>
            window.open(objectUrl, '_blank', 'noopener,noreferrer')
          }
        >
          {t('components.ReportsFolderDetail.reportResultModal.open')}
        </button>
      </div>
      <div className="button-bar">
        <button className="button button-highlight" onClick={onDismiss}>
          {t('components.ReportsFolderDetail.reportResultModal.done')}
        </button>
      </div>
    </Modal>
  );
};

ReportResultModal.propTypes = {
  className: PropTypes.string,
  onDismiss: PropTypes.func,
  reportResult: PropTypes.shape({
    objectUrl: PropTypes.string,
    filename: PropTypes.string,
  }).isRequired,
};

export default ReportResultModal;
