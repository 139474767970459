import PropTypes from 'prop-types';
import { tableSortDirection } from './functions';

export const columnPropTypes = PropTypes.shape({
  key: PropTypes.string.isRequired,
  headerContent: PropTypes.node.isRequired,
  get: PropTypes.func,
  sort: PropTypes.shape({
    asc: PropTypes.func,
    desc: PropTypes.func,
  }),
  sortable: PropTypes.bool,
});

export const sortDirectionPropTypes = PropTypes.oneOf(
  Object.values(tableSortDirection)
);
