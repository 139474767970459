import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { routePaths } from '../../router/route-paths';

export const baseFeeSchedule = {
  name: '',
  releases: [],
};

export const feeScheduleBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = adminToolsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.FeeSchedulePage.title'),
      path: currentPath,
    },
  ];

  return history;
};

export const feeScheduleTabItems = (t, path) => {
  const activeTab = {
    linkTo: routePaths.feeSchedulesActive,
    isActive: () => path === routePaths.feeSchedulesActive,
    content: t('components.FeeSchedulePage.Active.title'),
  };

  const archivedTab = {
    linkTo: routePaths.feeSchedulesArchived,
    isActive: () => path === routePaths.feeSchedulesArchived,
    content: t('components.FeeSchedulePage.Archived.title'),
  };

  return [activeTab, archivedTab];
};

export const scheduleScrollToLastCardInList = (listElement) => {
  // Wait for the height transition to end before scrolling
  // to the top of the last card element in the list:
  const onTransitionEnd = (e) => {
    const { propertyName, target } = e;
    const isHeightTransition = propertyName === 'height';
    const isCardContentTransition = target.classList.contains(
      'fee-schedule-card-content'
    );
    const isNewCardTransition = isHeightTransition && isCardContentTransition;
    if (isNewCardTransition) {
      listElement.removeEventListener('transitionend', onTransitionEnd);
      const lastCardElement = listElement.querySelector(
        `div:last-child .fee-schedule-card`
      );
      if (lastCardElement) {
        const { top = 0 } = lastCardElement.getBoundingClientRect();
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      }
    }
  };

  listElement.addEventListener('transitionend', onTransitionEnd);
};
