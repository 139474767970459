import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryForLawyerModals = (search = '') => {
  const { modalMounted = '', modalQuery = '' } = paramsFromSearchString(search);

  const params = {
    modalMounted,
    modalQuery,
  };
  return queryForParams(params);
};

export const queryFromModalQuery = (modalQuery = '') => {
  const {
    Keyword = '',
    Order = 'lastName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
    ...otherParams
  } = paramsFromSearchString(modalQuery);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
    ...otherParams,
  };

  return queryForParams(params);
};
