import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  prefixOptions,
  provinceOptions,
} from 'js/hardcoded-data/form-option-data';
import Modal from 'js/components/modal';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  Overlay,
  InputHelp,
  InputLabel,
  StyledSelect,
} from 'js/components/design-system';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import {
  postChequeEffect,
  onSubmitEffect,
  setChequePropertyEffect,
  setChequePropertyRecalculatingTotalEffect,
  setChequePropertyUpdatingRecipientEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onNumericInputBlurEffect,
  onFocusTypeoverInputEffect,
  onCloseNewChequeModalEffect,
} from './effects';
import {
  chequeDefaults,
  isPayeeLawFirm,
  newChequeValidationRules,
  newChequeRequiredFields,
} from './functions';

const NewChequeModal = (props) => {
  const { t } = useTranslation();
  const { api } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const [active, setActive] = useState(false);
  const [cheque, setCheque] = useState(chequeDefaults);
  const postChequeAbortControllerRef = useRef(null);

  const {
    mounted,
    caseOptions = {},
    caseInfo = {},
    client = {},
    lawyer = {},
    onClose,
  } = props;

  const { caseInformation = {} } = caseInfo;
  const { cif: caseNumber = '' } = caseInformation;
  const { payee: payeeOptions = [] } = caseOptions;

  const {
    payee,
    hours,
    fee,
    gst,
    pst,
    total,
    prefix,
    firstName,
    lastName,
    firmName,
    address,
    address2,
    city,
    province,
    postalCode,
  } = cheque;

  const payeeIsLawFirm = isPayeeLawFirm(payee, lawyer);
  const requiredFields = newChequeRequiredFields(payeeIsLawFirm);
  const validateAll = (formData) => validate(formData, requiredFields);
  const touchAll = () => touch(requiredFields);
  const untouchAll = () => untouch(requiredFields);
  const { invalidFields = [] } = validateAll(cheque);

  const onCloseModal = onCloseNewChequeModalEffect({
    onClose,
    setCheque,
    setActive,
    untouchAll,
    abortControllerRefs: [postChequeAbortControllerRef],
  });

  const onSubmit = onSubmitEffect({
    t,
    presentStyledBanner,
    saveEffect: postChequeEffect({
      t,
      api,
      validateAll,
      touchAll,
      caseNumber,
      cheque,
      payeeIsLawFirm,
      setActive,
      onComplete: onCloseModal,
      postChequeAbortControllerRef,
    }),
  });

  const setChequeProperty = setChequePropertyEffect({ setCheque });
  const setChequePropertyRecalculatingTotal = setChequePropertyRecalculatingTotalEffect(
    { setCheque }
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange: setChequeProperty }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const numericInputEventHandlers = {
    onChange: onInputChangeEffect({
      onChange: setChequePropertyRecalculatingTotal,
    }),
    onFocus: onFocusTypeoverInputEffect(),
    onBlur: onNumericInputBlurEffect({
      touch,
      onChange: setChequePropertyRecalculatingTotal,
      toFixed: true,
      precision: 2,
    }),
  };

  return (
    <Modal
      className="new-cheque-modal"
      cancelButtonText={t('components.IssueCheque.NewChequeModal.closeButton')}
      onClickCancel={onCloseModal}
      titleText={t('components.IssueCheque.NewChequeModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.IssueCheque.NewChequeModal.title')}</h2>
      <form onSubmit={onSubmit}>
        <Overlay visible={active}>
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.payeeValidation'
              )}
              invalid={isTouched('payee') && invalidFields.includes('payee')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.payee')}
              >
                <StyledSelect
                  name="payee"
                  value={payee}
                  onChange={onInputChangeEffect({
                    onChange: setChequePropertyUpdatingRecipientEffect({
                      setCheque,
                      client,
                      lawyer,
                      untouch,
                    }),
                  })}
                  onBlur={onInputBlurEffect({ touch })}
                >
                  <option value={''} disabled>
                    {t(
                      'components.IssueCheque.NewChequeModal.payeePlaceholder'
                    )}
                  </option>
                  {payeeOptions.map(({ payeeCode, payeeDesc }) => (
                    <option key={payeeCode} value={payeeCode}>
                      {payeeDesc}
                    </option>
                  ))}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.amountValidation'
              )}
              invalid={isTouched('hours') && invalidFields.includes('hours')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.hours')}
              >
                <input
                  type="text"
                  name="hours"
                  value={hours}
                  {...numericInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.amountValidation'
              )}
              invalid={isTouched('fee') && invalidFields.includes('fee')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.fee')}
              >
                <input
                  type="text"
                  name="fee"
                  value={fee}
                  {...numericInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.amountValidation'
              )}
              invalid={isTouched('gst') && invalidFields.includes('gst')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.gst')}
              >
                <input
                  type="text"
                  name="gst"
                  value={gst}
                  {...numericInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.amountValidation'
              )}
              invalid={isTouched('pst') && invalidFields.includes('pst')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.pst')}
              >
                <input
                  type="text"
                  name="pst"
                  value={pst}
                  {...numericInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputLabel
              content={t('components.IssueCheque.NewChequeModal.total')}
            >
              <input type="text" name="total" value={total} readOnly />
            </InputLabel>
          </div>

          <h3>
            {t('components.IssueCheque.NewChequeModal.recipientInformation')}
          </h3>

          {payeeIsLawFirm && (
            <div className="form-row">
              <InputHelp
                validationContent={t(
                  'components.IssueCheque.NewChequeModal.firmNameValidation'
                )}
                invalid={
                  isTouched('firmName') && invalidFields.includes('firmName')
                }
              >
                <InputLabel
                  content={t('components.IssueCheque.NewChequeModal.firmName')}
                >
                  <input
                    type="text"
                    name="firmName"
                    value={firmName}
                    {...inputEventHandlers}
                  />
                </InputLabel>
              </InputHelp>
            </div>
          )}

          {!payeeIsLawFirm && (
            <div className="form-row">
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.prefix')}
              >
                <StyledSelect
                  name="prefix"
                  value={prefix}
                  {...inputEventHandlers}
                >
                  <option value={''} key={''} disabled>
                    {t(
                      'components.IssueCheque.NewChequeModal.prefixPlaceholder'
                    )}
                  </option>
                  {prefixOptions(t).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </StyledSelect>
              </InputLabel>

              <InputHelp
                validationContent={t(
                  'components.IssueCheque.NewChequeModal.firstNameValidation'
                )}
                invalid={
                  isTouched('firstName') && invalidFields.includes('firstName')
                }
              >
                <InputLabel
                  content={t('components.IssueCheque.NewChequeModal.firstName')}
                >
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    {...inputEventHandlers}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.IssueCheque.NewChequeModal.lastNameValidation'
                )}
                invalid={
                  isTouched('lastName') && invalidFields.includes('lastName')
                }
              >
                <InputLabel
                  content={t('components.IssueCheque.NewChequeModal.lastName')}
                >
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    {...inputEventHandlers}
                  />
                </InputLabel>
              </InputHelp>
            </div>
          )}

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.addressValidation'
              )}
              invalid={
                isTouched('address') && invalidFields.includes('address')
              }
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.address')}
              >
                <input
                  type="text"
                  name="address"
                  value={address}
                  {...inputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.address2Validation'
              )}
              invalid={
                isTouched('address2') && invalidFields.includes('address2')
              }
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.address2')}
              >
                <input
                  type="text"
                  name="address2"
                  value={address2}
                  {...inputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.cityValidation'
              )}
              invalid={isTouched('city') && invalidFields.includes('city')}
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.city')}
              >
                <input
                  type="text"
                  name="city"
                  value={city}
                  {...inputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.provinceValidation'
              )}
              invalid={
                isTouched('province') && invalidFields.includes('province')
              }
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.province')}
              >
                <StyledSelect
                  value={province}
                  name="province"
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t(
                      'components.IssueCheque.NewChequeModal.provincePlaceholder'
                    )}
                  </option>
                  {provinceOptions(t).map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </StyledSelect>
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.IssueCheque.NewChequeModal.postalCodeValidation'
              )}
              invalid={
                isTouched('postalCode') && invalidFields.includes('postalCode')
              }
            >
              <InputLabel
                content={t('components.IssueCheque.NewChequeModal.postalCode')}
              >
                <PostalCodeInput
                  name="postalCode"
                  value={postalCode}
                  onChange={onPostalCodeInputChangeEffect({
                    onChange: setChequeProperty,
                  })}
                  onBlur={onInputBlurEffect({ touch })}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </Overlay>

        <div className="button-bar">
          <button
            className="button-link-appearance"
            disabled={active}
            onClick={onCloseModal}
          >
            {t('components.IssueCheque.NewChequeModal.cancelButton')}
          </button>
          <button
            type="submit"
            className="button button-highlight"
            disabled={active}
          >
            {active
              ? t('components.IssueCheque.NewChequeModal.saveButtonActive')
              : t('components.IssueCheque.NewChequeModal.saveButton')}
          </button>
        </div>
      </form>
    </Modal>
  );
};

NewChequeModal.propTypes = {
  mounted: PropTypes.bool,
  caseOptions: PropTypes.shape({
    payee: PropTypes.arrayOf(
      PropTypes.shape({
        payeeCode: PropTypes.string,
        payeeDesc: PropTypes.string,
      })
    ),
  }).isRequired,
  caseInfo: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  lawyer: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const ValidatedNewChequeModal = (props) => (
  <ValidationProvider rules={newChequeValidationRules}>
    <NewChequeModal {...props} />
  </ValidationProvider>
);

export default ValidatedNewChequeModal;
