/**
 * Tests whether a value is a true object (returns false for Array and null).
 * @memberof module:utilities/object
 * @function
 * @param {Object} val - The value to test.
 * @returns {boolean} - True if the value is an object, is not an Array and is not null. Returns false otherwise.
 */
export const isObject = (val) => {
  return typeof val === 'object' && !Array.isArray(val) && val !== null;
};

export const objectRemovingKeys = (obj = {}, keysToRemove = []) => {
  return Object.keys(obj)
    .filter((key) => !keysToRemove.includes(key))
    .reduce((nextObj, key) => {
      return { ...nextObj, [key]: obj[key] };
    }, {});
};
