import { searchStringFromParams } from 'js/utilities/params';
import { cleanLawFirms, cleanLawFirm } from 'js/utilities/law-firms';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const lawFirmRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setLawFirms,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }
    const endpoint = `/Admin/Firms/${query.search}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.LawFirmListPage.searchError') },
          },
        }
      );
      const { lawFirmInfo = [], totalCount = 0 } = json;
      setLawFirms(cleanLawFirms(lawFirmInfo));
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const lawFirmDetailsRequestEffect = (options = {}) => {
  const {
    t,
    api,
    firmId,
    setInitialActive,
    setLawFirm,
    abortControllerRef,
    isNew,
  } = options;
  return async () => {
    if (isNew) {
      setLawFirm(cleanLawFirm({}));
      setInitialActive(false);
      return;
    }
    const endpoint = `/Admin/Firms/${firmId}`;
    setInitialActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawFirmDetailsPage.requestError'),
            },
          },
        }
      );

      setLawFirm(cleanLawFirm(json));
      setInitialActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setInitialActive(false);
      }
    }
  };
};

export const previousLawFirmResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextLawFirmResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmResultsActiveEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e) => {
    const { name, value } = e.target;
    const nextParams = { ...params, [name]: value };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, Order: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

//form effects
export const updateLawFirmInfoEffect = (options = {}) => {
  const { lawFirm, setLawFirm } = options;
  return (name, value) => setLawFirm({ ...lawFirm, [name]: value });
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const patchLawFirmEffect = (options = {}) => {
  const {
    t,
    api,
    lawFirm,
    isValid,
    setActive,
    touchAllFields,
    resetInteractionCount,
  } = options;

  return async () => {
    const { firmId } = lawFirm;

    if (!isValid || !firmId) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);
    try {
      await api.patchJson(
        `/Admin/Firms/${firmId}`,
        { body: lawFirm },
        {
          success: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPatchError'),
            },
          },
        }
      );
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      setActive(false);
      throw error;
    }
  };
};

export const postLawFirmEffect = (options = {}) => {
  const {
    t,
    api,
    lawFirm,
    isValid,
    setActive,
    setRedirectPath,
    touchAllFields,
    resetInteractionCount,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);

    try {
      const response = await api.postJson(
        `/Admin/Firms`,
        { body: lawFirm },
        {
          success: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPostSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPostError'),
            },
          },
        }
      );

      const { firmId } = response.json;
      const path = routePathReplacingParams(routePaths.lawFirm, {
        firmId,
      });

      resetInteractionCount();
      setActive(false);
      setRedirectPath(path);
    } catch (error) {
      setActive(false);
      throw error;
    }
  };
};

export const saveLawFirmEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const patchActiveLawFirmEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    firmId,
    lawFirmInactive,
    lawFirm,
    setLawFirm,
    showModal,
  } = options;

  return async () => {
    const payload = { inactive: lawFirmInactive };

    setActive(true);
    try {
      await api.patchJson(
        `/Admin/Firms/${firmId}`,
        { body: payload },
        {
          success: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawFirmDetailsPage.requestPatchError'),
            },
          },
        }
      );

      const nextLawFirm = { ...lawFirm, inactive: lawFirmInactive };
      setLawFirm(nextLawFirm);
      setActive(false);
      showModal(false);
    } catch (error) {
      setActive(false);
    }
  };
};
