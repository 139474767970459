import React from 'react';
import { Breadcrumbs, breadcrumbTypes, Tabs } from '../components/navigation';

const Navigation = () => {
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Navigation</h1>

        <h2>Breadcrumbs</h2>
        <Breadcrumbs
          history={[
            breadcrumbTypes.home,
            { content: 'Cases', path: '#' },
            { content: 'Case 12345', path: '#' },
          ]}
        />

        <h2>Tabs</h2>
        <Tabs
          items={[
            {
              linkTo: '#one',
              content: 'One',
              isActive: (currentPath) => currentPath === '#one',
            },
            {
              linkTo: '#two',
              content: 'Two',
              isActive: (currentPath) => currentPath === '#two',
            },
            {
              linkTo: '#three',
              content: 'Three',
              isActive: (currentPath) => currentPath === '#three',
            },
            {
              linkTo: '#four',
              content: 'Four',
              isActive: (currentPath) => currentPath === '#four',
            },
          ]}
          currentPath="#one"
        />
      </div>
    </div>
  );
};

export default Navigation;
