import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPhoneNumberMaskEffect, onChangeEffect } from './effects';

const PhoneNumberInput = (props) => {
  const phoneNumberMaskRef = useRef(null);
  const { onChange, name, ...inputProps } = props;

  useEffect(createPhoneNumberMaskEffect({ phoneNumberMaskRef }), []);

  return (
    <input
      type="tel"
      autoComplete={name}
      name={name}
      onChange={onChangeEffect({ phoneNumberMaskRef, onChange })}
      {...inputProps}
    />
  );
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default PhoneNumberInput;
