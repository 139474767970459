import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const ReportsFoldersSortOptions = [
  {
    key: 'folder-name-asc',
    field: 'name',
    direction: 'asc',
    i18nKey: 'components.ReportsFoldersList.sortMenu.folderAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'folder-name-desc',
    field: 'name',
    direction: 'desc',
    i18nKey: 'components.ReportsFoldersList.sortMenu.folderDesc',
    icon: faSortAlphaDownAlt,
  },
];

export const detailUrlForReportsFolder = (folderId, query = {}) => {
  let path = routePathReplacingParams(routePaths.reportsFolder, { folderId });
  return query.search ? `${path}${query.search}` : path;
};

export const newReportsFolderUrl = (query = {}) => {
  let path = routePaths.newReportsFolder;
  return query.search ? `${path}${query.search}` : path;
};
