import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { clientOutstndingBalanceNotesBreadcrumbsHistory } from './functions';

const ClientOutstndingBalanceNotesBreadcrumbs = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="client-outstanding-balance-notes-breadcrumbs"
      history={clientOutstndingBalanceNotesBreadcrumbsHistory(
        t,
        location,
        match
      )}
    />
  );
};

export default ClientOutstndingBalanceNotesBreadcrumbs;
