import { isEmail, isPostalCode } from 'validator';
import { isValidDate } from 'js/utilities/dates';
import { isObject } from 'js/utilities/object';

export const caseNumberPattern = /^\d{4}\/\d{4}\/\d{5}$/;
export const clientIdPattern = /^\d{9}$/;
export const clientCodePattern = /^\d{2}$/;
export const lawyerIdPattern = /^(\d[a-zA-Z])?\d{4}$/;
export const intakeIdPattern = /^\d+$/;
// eslint-disable-next-line no-useless-escape
export const namePattern = /^[a-zA-Z-'’\(\)\s\.]{2,30}$/;
export const phoneNumberPattern = /^(\d{3}[\s-]*)?\d{3}[\s-]?\d{4}$/;
export const yearPattern = /^(\d{4})?$/;
export const integerPattern = /^\d+$/;
export const floatPattern = /^\d+\.?\d*$/;
export const chequeNumberPattern = /^\d{6}$/;
export const officeCodePattern = /^\d{4}$/;
export const dateStringPattern = /^\d{4}\/\d{2}\/\d{2}$/;

export const validateOptionalName = (name = '') => {
  return namePattern.test(name) || isEmpty(name);
};

export const validateDate = (date, required = true) => {
  if (required) {
    return date ? isValidDate(date) : false;
  } else {
    return date ? isValidDate(date) : true;
  }
};

export const validateEmail = (email = '', required = false) => {
  if (required) {
    return isEmail(email);
  } else {
    return !email ? true : isEmail(email);
  }
};

export const validateYear = (year = '', required = false) => {
  if (required) {
    return !isEmpty(year) && yearPattern.test(year);
  } else {
    return !isEmpty(year) ? yearPattern.test(year) : true;
  }
};

export const validatePhoneNumber = (phone = '', required = false) => {
  if (required) {
    return !isEmpty(phone) && phoneNumberPattern.test(phone);
  } else {
    return !isEmpty(phone) ? phoneNumberPattern.test(phone) : true;
  }
};

export const validatePostalCode = (postalCode, required = true) => {
  return postalCode ? isPostalCode(postalCode, 'CA') : !required;
};

export const validateSelection = (value, permittedValues = []) => {
  return permittedValues.includes(value);
};

/**
 * Validates that a value is empty, meaning that the value is undefined, null, an object with no keys, or an array or string of zero length.
 * @memberof module:utilities/validations
 * @function
 * @param {any} value - The value.
 * @returns {boolean} - True if the value is empty, otherwise false.
 */
export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (isObject(value) && Object.keys(value).length === 0) ||
    value.length === 0
  );
};

export const isOneOf = (value, permittedValues = []) => {
  return permittedValues.includes(value);
};

export const validateStringMaxLength = (value = '', maxLength = 0) => {
  return value.length <= maxLength;
};
