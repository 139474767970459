import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PaginationButton = (props) => {
  const { className, children, ...buttonProps } = props;
  return (
    <button
      type="button"
      className={classnames('pagination-button', className)}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

PaginationButton.propTypes = {
  className: PropTypes.string,
};

export default PaginationButton;
