import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { onChangeInputEffect } from './effects';

const SearchNamesInput = (props) => {
  const { t } = useTranslation();
  const { firstName = '', lastName = '', onChange, ...inputProps } = props;

  return (
    <div className="search-names-input">
      <input
        {...inputProps}
        type="text"
        name="firstName"
        id="firstName"
        autoComplete="firstName"
        placeholder={t(
          'components.controls.SearchInput.placeholders.firstName'
        )}
        value={firstName}
        onChange={onChangeInputEffect({ onChange })}
      />
      <input
        {...inputProps}
        type="text"
        name="lastName"
        id="lastName"
        autoComplete="lastName"
        placeholder={t('components.controls.SearchInput.placeholders.lastName')}
        value={lastName}
        onChange={onChangeInputEffect({ onChange })}
      />
    </div>
  );
};

SearchNamesInput.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchNamesInput;
