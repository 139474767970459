import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import ManageUnionsModal from './manage-unions-modal';
import {
  onMountEffect,
  getLinkedUnionsEffect,
  setManageUnionsModalMountedEffect,
  onSelectUnionEffect,
} from './effects';
import { getLinkedUnionColumns } from './functions';

const LinkedUnionsList = (props) => {
  const { companyCode } = props;
  const abortControllerRef = useRef(null);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [isManageUnionsModalMounted, setManageUnionsModalMounted] = useState(
    false
  );
  const [linkedUnions, setLinkedUnions] = useState([]);
  const history = useHistory();

  const getLinkedUnions = getLinkedUnionsEffect({
    t,
    api,
    companyCode,
    setActive,
    setLinkedUnions,
    abortControllerRef,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(getLinkedUnions, [companyCode]);

  return (
    <div className="linked-unions" data-print-managed>
      <div className="linked-unions-heading">
        <h1>{t('components.CompanyDetailsPage.LinkedUnionsList.title')}</h1>
        <button
          className="button button-highlight page-action-button"
          onClick={() => setManageUnionsModalMounted(true)}
          data-screen-only
        >
          {t(
            'components.CompanyDetailsPage.LinkedUnionsList.manageUnionsButton'
          )}
        </button>
      </div>
      {active && <ActivityIndicatorCard />}
      {!active && linkedUnions.length === 0 && (
        <EmptyState
          title={t(
            'components.CompanyDetailsPage.LinkedUnionsList.emptyStateMessage'
          )}
        />
      )}
      {!active && linkedUnions.length > 0 && (
        <SortableTable
          columns={getLinkedUnionColumns(t)}
          data={linkedUnions}
          initialSortColumnKey={'unionNumber'}
          initialSortDirection={tableSortDirection.asc}
          onClickRow={onSelectUnionEffect({ history })}
        />
      )}
      <ManageUnionsModal
        mounted={isManageUnionsModalMounted}
        setMounted={setManageUnionsModalMountedEffect({
          setManageUnionsModalMounted,
          getLinkedUnions,
        })}
        companyCode={companyCode}
      />
    </div>
  );
};

LinkedUnionsList.propTypes = {
  companyCode: PropTypes.string.isRequired,
};

export default LinkedUnionsList;
