import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './pagination';
import { pageNumberToOffetLimitEffect } from './effects';
import {
  sharedPaginationPropTypes,
  pageNumberFromOffsetAndLimit,
  totalPagesFromTotalResults,
} from './functions';

const OffsetLimitPagination = (props) => {
  const {
    offset,
    limit,
    totalResults,
    onClickPageNumber,
    ...paginationProps
  } = props;

  if (!totalResults) {
    return null;
  }

  return (
    <Pagination
      {...paginationProps}
      currentPage={pageNumberFromOffsetAndLimit(offset, limit)}
      totalPages={totalPagesFromTotalResults(totalResults, limit)}
      onClickPageNumber={pageNumberToOffetLimitEffect({
        limit,
        onClickPageNumber,
      })}
    />
  );
};

OffsetLimitPagination.propTypes = {
  ...sharedPaginationPropTypes,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
};

export default OffsetLimitPagination;
