import React, { Fragment, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyledBannerContext } from 'js/components/banner-styled';
import { ActivityIndicatorCard } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import PrintHeader from '../print-header';
import PrintFooter from '../print-footer';
import { executeRequestEffect } from './effects';

const ListPrintView = (props) => {
  const {
    className,
    beforeRequest,
    request,
    renderTableHeaders,
    renderTableRow,
    emptyStateTitle,
    displayHeader,
    headerProps = {},
    displayFooter,
    footerProps = {},
    printOnLoad,
    children,
  } = props;

  const { presentStyledBanner } = useContext(StyledBannerContext);

  const [isWaiting, setWaiting] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(
    executeRequestEffect({
      beforeRequest,
      request,
      setWaiting,
      setItems,
      printOnLoad,
      presentStyledBanner,
    }),
    []
  );

  return (
    <div className={classnames('list-print-view', className)}>
      {isWaiting && <ActivityIndicatorCard />}
      {!isWaiting && (
        <Fragment>
          {displayHeader && <PrintHeader {...headerProps} />}
          {children && (
            <div className="list-print-view-children" data-print-managed>
              {children}
            </div>
          )}
          <div className="list-print-view-content" data-print-managed>
            {items.length > 0 && (
              <table>
                <thead>{renderTableHeaders()}</thead>
                <tbody>{items.map(renderTableRow)}</tbody>
              </table>
            )}
            {items.length === 0 && <EmptyState title={emptyStateTitle} />}
          </div>
          {displayFooter && <PrintFooter {...footerProps} />}
        </Fragment>
      )}
    </div>
  );
};

ListPrintView.defaultProps = {
  displayHeader: true,
  displayFooter: true,
  printOnLoad: true,
};

ListPrintView.propTypes = {
  className: PropTypes.string,
  beforeRequest: PropTypes.func,
  request: PropTypes.func.isRequired,
  renderTableHeaders: PropTypes.func.isRequired,
  renderTableRow: PropTypes.func.isRequired,
  emptyStateTitle: PropTypes.string.isRequired,
  displayHeader: PropTypes.bool,
  headerProps: PropTypes.shape({
    ...PrintHeader.propTypes,
  }),
  displayFooter: PropTypes.bool,
  footerProps: PropTypes.shape({
    ...PrintFooter.propTypes,
  }),
  printOnLoad: PropTypes.bool,
};

export default ListPrintView;
