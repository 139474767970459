import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { notesSortOptions } from './functions';

const NotesSortMenu = (props) => (
  <SortMenu
    className="notes-sort-menu"
    sortOptions={notesSortOptions}
    {...props}
  />
);

NotesSortMenu.defaultProps = {
  field: 'timestamp',
  direction: 'desc',
};

NotesSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default NotesSortMenu;
