import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { fabActionHeight } from './functions';

const FABOffset = (props) => {
  const { className } = props;
  return (
    <div
      className={classnames('fab-offset', className)}
      style={{ height: fabActionHeight }}
    />
  );
};

FABOffset.propTypes = {
  className: PropTypes.string,
};

export default FABOffset;
