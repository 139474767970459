import { cleanStringField } from 'js/utilities/strings';
import { formatDateFromISOString } from 'js/utilities/dates';
import { roundToFixedPointString } from 'js/utilities/numbers';

export const cleanCheque = (cheque = {}) => {
  const {
    payeeDetails = {},
    amount,
    clientFirstName,
    clientLastName,
    date,
  } = cheque;
  const { firstname = '', lastname = '' } = payeeDetails;
  return {
    ...cheque,
    amount: roundToFixedPointString(amount, 2),
    clientFirstName: cleanStringField(clientFirstName),
    clientLastName: cleanStringField(clientLastName),
    date: formatDateFromISOString(date),
    payee: `${cleanStringField(firstname)} ${cleanStringField(lastname)}`,
  };
};

export const cleanCheques = (cheques = []) => cheques.map(cleanCheque);

export const cleanIssuedCheque = (cheque = {}) => {
  const hours = cheque.hours || 0;
  const fee = cheque.fee || 0;
  const pst = cheque.pst || 0;
  const gst = cheque.gst || 0;
  const total = fee + pst + gst;

  return {
    ...cheque,
    prefix: cleanStringField(cheque.prefix),
    firstName: cleanStringField(cheque.firstName),
    lastName: cleanStringField(cheque.lastName),
    address: cleanStringField(cheque.address),
    address2: cleanStringField(cheque.address2),
    city: cleanStringField(cheque.city),
    province: cleanStringField(cheque.province),
    postalCode: cleanStringField(cheque.postalCode),
    date: formatDateFromISOString(cheque.date),
    hours: roundToFixedPointString(hours, 2),
    fee: roundToFixedPointString(fee, 2),
    pst: roundToFixedPointString(pst, 2),
    gst: roundToFixedPointString(gst, 2),
    total: roundToFixedPointString(total, 2),
  };
};

export const cleanIssuedCheques = (cheques = []) =>
  cheques.map(cleanIssuedCheque);
