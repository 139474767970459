import React from 'react';
import { Switch, Route as BaseRoute } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import GroupPermissionsRoute from './group-permissions-route';
import { userGroups } from 'js/components/group-permissions';
import ProtectedRoute from './protected-route';
import { routePaths } from './route-paths';
import config from 'js/config';
import Login from 'js/components/pages/login';
import Logout from 'js/components/pages/logout';
import UnknownUser from 'js/components/pages/unknown-user';
import Home from 'js/components/pages/home';
import Search from 'js/components/pages/search';
import {
  AdminToolsPage,
  SystemSettingsPage,
  ProceduresPage,
} from 'js/components/pages/admin-tools';
import {
  CompaniesListPage,
  CompanyDetailsPage,
  CompaniesListPrintView,
} from 'js/components/pages/companies';
import {
  ClientInformation,
  ClientDependents,
  ClientDependentOf,
  ClientCaseHistory,
  ClientNotes,
  ClientDocuments,
  ClientRedirect,
  ClientOutstandingBalanceNotes,
  ClientLog,
} from 'js/components/pages/client';
import {
  CaseInformation,
  CaseLawyer,
  CaseBilling,
  CaseNotes,
  CaseClosing,
  CaseReassignment,
  CaseCorrection,
  CaseRedirect,
  CaseLog,
} from 'js/components/pages/case';
import {
  LawyerInformation,
  LawyerPanels,
  LawyerFeedback,
  LawyerCaseHistory,
  LawyerRedirect,
  LawyerPrintView,
} from 'js/components/pages/lawyer';
import {
  ChequeRunCurrent,
  ChequeRunLast,
  //ChequeRunHistory,
  ChequeRunRedirect,
} from 'js/components/pages/cheque-run';
import IssueCheque from 'js/components/pages/issue-cheque';
import {
  LawFirmListPage,
  LawFirmDetailsPage,
  LawFirmsListPrintView,
  LawFirmDetailsPrintView,
} from 'js/components/pages/law-firms';
import LawyerTypes from 'js/components/pages/lawyer-types';
import {
  LegalProblemCodes,
  LegalProblemCodeDetail,
  LegalProblemCodesListPrintView,
} from 'js/components/pages/legal-problem-codes';
import {
  PrintSingleCIF,
  PrintMultipleCIF,
} from 'js/components/pages/print/cif';
import DayEnd from 'js/components/pages/day-end';
import {
  PrintSingleCSQ,
  PrintMultipleCSQ,
} from 'js/components/pages/print/csq';
import {
  PrintSingleNTP,
  PrintMultipleNTP,
} from 'js/components/pages/print/ntp';
import Error403 from 'js/components/pages/errors/403';
import Error404 from 'js/components/pages/errors/404';
import Error500 from 'js/components/pages/errors/500';
import { getDesignSystemRoutes } from 'js/components/design-system/demo';
import { ReportsPage } from 'js/components/pages/reports';
import { FeedbackReportPage } from 'js/components/pages/feedback-reports';
import { StaffListPage, StaffListPrintView } from 'js/components/pages/staff';
import {
  UnionListPage,
  UnionDetailsPage,
  UnionListPrintView,
} from 'js/components/pages/unions';
import OfficesListPage from 'js/components/pages/offices';
import { ProvincesList, ProvinceDetail } from 'js/components/pages/regions';
import CSQEntry from 'js/components/pages/csq-entry';
import {
  FeeScheduleActive,
  FeeScheduleArchived,
} from 'js/components/pages/fee-schedules';
import {
  ReleaseLPCodes,
  ReleaseCompanies,
} from 'js/components/pages/fee-schedules/details-page';
import DatabaseBackups from 'js/components/pages/database-backups';
import PrimaryClientIntegrity from 'js/components/pages/primary-client-integrity';

const Route = Sentry.withSentryRouting(BaseRoute);

/*
 * GroupPermissionsRoute Example:
 *
 * <GroupPermissionsRoute
 *    exact
 *    path={routePaths.myRoutePath}
 *    component={MyComponent}
 *    permittedGroups={[
 *      userGroups.administrator,
 *      userGroups.feedbackSpecialist,
 *    ]}
 *  />
 */

const PROCEDURES_PERMITTED_GROUPS = [
  userGroups.administrator,
  userGroups.intakeSpecialist,
  userGroups.feedbackSpecialist,
  userGroups.dataEntry,
  userGroups.support,
];

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routePaths.login} component={Login} />
      <Route exact path={routePaths.logout} component={Logout} />
      <Route exact path={routePaths.unknownUser} component={UnknownUser} />
      <ProtectedRoute exact path={routePaths.home} component={Home} />
      <ProtectedRoute exact path={routePaths.search} component={Search} />
      {/* Admin */}
      <GroupPermissionsRoute
        exact
        path={routePaths.adminTools}
        component={AdminToolsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <ProtectedRoute
        exact
        path={routePaths.systemSettings}
        component={SystemSettingsPage}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.procedures}
        component={ProceduresPage}
        permittedGroups={PROCEDURES_PERMITTED_GROUPS}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.csqEntry}
        component={CSQEntry}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.issueCheque}
        component={IssueCheque}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <ProtectedRoute
        exact
        path={routePaths.legalProblemCodes}
        component={LegalProblemCodes}
      />
      <ProtectedRoute
        exact
        path={routePaths.legalProblemCodesPrint}
        component={LegalProblemCodesListPrintView}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.reports}
        component={ReportsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.reportsFolder}
        component={ReportsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.newReportsFolder}
        component={ReportsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.reportDetail}
        component={ReportsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.newReport}
        component={ReportsPage}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.feedbackReports}
        component={FeedbackReportPage}
        permittedGroups={[
          userGroups.administrator,
          userGroups.feedbackSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <ProtectedRoute
        exact
        path={routePaths.legalProblemCode}
        component={LegalProblemCodeDetail}
      />
      <ProtectedRoute
        exact
        path={routePaths.newLegalProblemCode}
        component={LegalProblemCodeDetail}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.feeSchedulesActive}
        component={FeeScheduleActive}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.feeSchedulesArchived}
        component={FeeScheduleArchived}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.releaseLPCodes}
        component={ReleaseLPCodes}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.releaseCompanies}
        component={ReleaseCompanies}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.releaseLPCodesCopy}
        component={ReleaseLPCodes}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.releaseCompaniesCopy}
        component={ReleaseCompanies}
        permittedGroups={[
          userGroups.administrator,
          userGroups.complaintSpecialist,
          userGroups.dataEntry,
        ]}
      />

      <ProtectedRoute
        exact
        path={routePaths.lawFirms}
        component={LawFirmListPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawFirmsPrint}
        component={LawFirmsListPrintView}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawFirm}
        component={LawFirmDetailsPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawFirmPrint}
        component={LawFirmDetailsPrintView}
      />
      <ProtectedRoute
        exact
        path={routePaths.newLawFirm}
        component={LawFirmDetailsPage}
      />
      <ProtectedRoute exact path={routePaths.staff} component={StaffListPage} />
      <ProtectedRoute
        exact
        path={routePaths.staffPrint}
        component={StaffListPrintView}
      />
      <ProtectedRoute
        exact
        path={routePaths.companies}
        component={CompaniesListPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.companiesPrint}
        component={CompaniesListPrintView}
      />
      <ProtectedRoute
        exact
        path={routePaths.company}
        component={CompanyDetailsPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.newCompany}
        component={CompanyDetailsPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.unions}
        component={UnionListPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.unionsPrint}
        component={UnionListPrintView}
      />
      <ProtectedRoute
        exact
        path={routePaths.unionDetails}
        component={UnionDetailsPage}
      />

      {/* system settings*/}
      <ProtectedRoute
        exact
        path={routePaths.lawyerTypes}
        component={LawyerTypes}
      />
      <ProtectedRoute
        exact
        path={routePaths.offices}
        component={OfficesListPage}
      />
      <ProtectedRoute
        exact
        path={routePaths.provinces}
        component={ProvincesList}
      />
      <ProtectedRoute
        exact
        path={routePaths.province}
        component={ProvinceDetail}
      />
      <ProtectedRoute
        exact
        path={routePaths.newProvince}
        component={ProvinceDetail}
      />
      {/* Client */}
      <ProtectedRoute
        exact
        path={routePaths.client}
        component={ClientRedirect}
      />
      <ProtectedRoute
        exact
        path={routePaths.newClient}
        component={ClientInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.newDependent}
        component={ClientInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientInformation}
        component={ClientInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientDependents}
        component={ClientDependents}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientDependentOf}
        component={ClientDependentOf}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientCaseHistory}
        component={ClientCaseHistory}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientNotes}
        component={ClientNotes}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientLog}
        component={ClientLog}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <ProtectedRoute
        exact
        path={routePaths.clientDocuments}
        component={ClientDocuments}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.clientOutstandingBalanceNotes}
        component={ClientOutstandingBalanceNotes}
        permittedGroups={[
          userGroups.administrator,
          userGroups.dataEntry,
          userGroups.intakeSpecialist,
          userGroups.feedbackSpecialist,
          userGroups.support,
        ]}
      />
      {/* Case */}
      <ProtectedRoute exact path={routePaths.case} component={CaseRedirect} />
      <ProtectedRoute
        exact
        path={routePaths.caseInformation}
        component={CaseInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.newCase}
        component={CaseInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.copyCase}
        component={CaseInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.caseLawyer}
        component={CaseLawyer}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.caseBilling}
        component={CaseBilling}
        permittedGroups={[
          userGroups.administrator,
          userGroups.feedbackSpecialist,
          userGroups.intakeSpecialist,
          userGroups.support,
          userGroups.dataEntry,
          userGroups.viewOnly,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.caseLog}
        component={CaseLog}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <ProtectedRoute exact path={routePaths.caseNotes} component={CaseNotes} />
      <GroupPermissionsRoute
        exact
        path={routePaths.caseClosing}
        component={CaseClosing}
        permittedGroups={PROCEDURES_PERMITTED_GROUPS}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.caseReassignment}
        component={CaseReassignment}
        permittedGroups={[
          userGroups.administrator,
          userGroups.dataEntry,
          userGroups.intakeSpecialist,
          userGroups.feedbackSpecialist,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.caseCorrection}
        component={CaseCorrection}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      {/* Cheque Run */}
      <GroupPermissionsRoute
        exact
        path={routePaths.chequeRun}
        component={ChequeRunRedirect}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.chequeRunCurrent}
        component={ChequeRunCurrent}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.chequeRunLast}
        component={ChequeRunLast}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      {/*
      <GroupPermissionsRoute
        exact
        path={routePaths.chequeRunHistory}
        component={ChequeRunHistory}
        permittedGroups={PROCEDURES_PERMITTED_GROUPS}
      />
      */}
      {/* Primary Client Integrity */}
      <GroupPermissionsRoute
        exact
        path={routePaths.primaryClientIntegrity}
        component={PrimaryClientIntegrity}
        permittedGroups={[userGroups.administrator, userGroups.dataEntry]}
      />
      {/* Lawyer */}
      <ProtectedRoute
        exact
        path={routePaths.newLawyer}
        component={LawyerInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawyer}
        component={LawyerRedirect}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawyerInformation}
        component={LawyerInformation}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawyerPanels}
        component={LawyerPanels}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.lawyerFeedback}
        component={LawyerFeedback}
        permittedGroups={[
          userGroups.administrator,
          userGroups.feedbackSpecialist,
          userGroups.dataEntry,
        ]}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawyerCaseHistory}
        component={LawyerCaseHistory}
      />
      <ProtectedRoute
        exact
        path={routePaths.lawyerPrint}
        component={LawyerPrintView}
      />
      {/* Print Layouts */}
      <ProtectedRoute
        exact
        path={routePaths.printSingleCIF}
        component={PrintSingleCIF}
      />
      <ProtectedRoute
        exact
        path={routePaths.printMultipleCIF}
        component={PrintMultipleCIF}
      />
      <ProtectedRoute
        exact
        path={routePaths.printSingleCSQ}
        component={PrintSingleCSQ}
      />
      <ProtectedRoute
        exact
        path={routePaths.printMultipleCSQ}
        component={PrintMultipleCSQ}
      />
      <ProtectedRoute
        exact
        path={routePaths.printSingleNTP}
        component={PrintSingleNTP}
      />
      <ProtectedRoute
        exact
        path={routePaths.printMultipleNTP}
        component={PrintMultipleNTP}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.dayEnd}
        component={DayEnd}
        permittedGroups={[
          userGroups.administrator,
          userGroups.feedbackSpecialist,
          userGroups.intakeSpecialist,
          userGroups.dataEntry,
          userGroups.support,
        ]}
      />
      <GroupPermissionsRoute
        exact
        path={routePaths.databaseBackups}
        component={DatabaseBackups}
        permittedGroups={[userGroups.administrator]}
      />
      {/* Design System */}
      {config.exposeDesignSystem === true && getDesignSystemRoutes()}
      {/* Errors */}
      <ProtectedRoute
        exact
        path={routePaths.search404}
        component={Home}
        redirectTo={routePaths.error404}
      />
      <Route exact path={routePaths.error403} component={Error403} />
      <Route exact path={routePaths.error404} component={Error404} />
      <Route exact path={routePaths.error500} component={Error500} />
      {/* Fallback */}
      <Route component={Error404} />
    </Switch>
  );
};

export default Routes;
