export const numberOfDots = 8;
export const defaultDotRadius = 5;
export const defaultDiameter = 60;
export const defaultAnimationDurationMS = 2000;

export const deg2rad = (deg) => (deg * Math.PI) / 180;

export const pointFromCentreOfCircle = (radius, angle) => ({
  x: radius + radius * Math.cos(angle),
  y: radius + radius * Math.sin(angle),
});
