import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  updateLawyerPanelsWithCheckedPanel,
  updateLawyerPanelsWithCheckedSubpanel,
} from 'js/utilities/lawyer-panels';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingPanels,
    setSavingPanels,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingPanels(false);
      setSavingPanels(false);
    };
  };
};

export const onChangeLawyerPanelEffect = (options = {}) => {
  const { setPanels } = options;
  return (e) => {
    const { name: panelKey, checked } = e.target;
    setPanels((panels) =>
      updateLawyerPanelsWithCheckedPanel(panels, panelKey, checked)
    );
  };
};

export const onChangeLawyerSubpanelEffect = (options = {}) => {
  const { panelKey, setPanels } = options;
  return (e) => {
    const { name: subPanelKey, checked } = e.target;
    setPanels((panels) =>
      updateLawyerPanelsWithCheckedSubpanel(
        panels,
        panelKey,
        subPanelKey,
        checked
      )
    );
  };
};

export const getLawyerPanelsEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    setLoadingPanels,
    setPanels,
    getLawyerPanelsAbortControllerRef,
  } = options;

  return async () => {
    if (!lawyerId) {
      return;
    }

    setLoadingPanels(true);

    const url = `/Lawyer/${lawyerId}/Panels`;

    rotateAbortControllerRef(getLawyerPanelsAbortControllerRef);
    const { signal } = getLawyerPanelsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerPanels.getRequestError'),
            },
          },
        }
      );

      const { lawyerPanel = [] } = json;

      setPanels(lawyerPanel);
      setLoadingPanels(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingPanels(false);
      }
    }
  };
};

export const patchLawyerPanelsEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    panels,
    setSavingPanels,
    resetInteractionCount,
    patchLawyerPanelsAbortControllerRef,
  } = options;

  return async () => {
    if (!lawyerId) {
      return;
    }

    setSavingPanels(true);

    const url = `/Lawyer/${lawyerId}/Panels`;
    const body = { lawyerPanel: panels };

    rotateAbortControllerRef(patchLawyerPanelsAbortControllerRef);
    const { signal } = patchLawyerPanelsAbortControllerRef.current;

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.LawyerPanels.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerPanels.patchRequestError'),
            },
          },
        }
      );

      setSavingPanels(false);
      resetInteractionCount();
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingPanels(false);
        throw error;
      }
    }
  };
};
