import React, { Fragment } from 'react';
import marked from 'marked';
import dompurify from 'dompurify';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';

export const markdownToHtml = (markdown = '') => {
  return dompurify.sanitize(marked(markdown));
};

export const renderNtpInstruction = (t, index, group) => {
  const instructionNumber = index + 1;
  const markdown = t(
    `components.PrintLayouts.NTP.${group}.instructions.${instructionNumber}`
  );
  const html = markdownToHtml(markdown);
  return (
    <div className="ntp-print-instruction">
      <div className="ntp-print-instruction-number">{instructionNumber}.</div>
      <div className="ntp-print-instruction-content">
        <div
          className="ntp-print-instruction-text"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  );
};

export const renderFirstNonCooperatingLawyerInstruction = (t) => {
  const markdown1a = t(
    `components.PrintLayouts.NTP.nonCooperatingLawyer.instructions.1a`
  );
  const markdown1b = t(
    `components.PrintLayouts.NTP.nonCooperatingLawyer.instructions.1b`
  );
  const html1a = markdownToHtml(markdown1a);
  const html1b = markdownToHtml(markdown1b);
  return (
    <div className="ntp-print-instruction ntp-print-instruction-first-non-cooperating">
      <div className="ntp-print-instruction-number">1.</div>
      <div className="ntp-print-instruction-content">
        <div
          className="ntp-print-instruction-text"
          dangerouslySetInnerHTML={{ __html: html1a }}
        />
        <ul className="unstyled-list ntp-print-non-cooperating-lawyer-contact">
          <li>
            {t(
              'components.PrintLayouts.NTP.nonCooperatingLawyer.addressLines.1'
            )}
          </li>
          <li>
            {t(
              'components.PrintLayouts.NTP.nonCooperatingLawyer.addressLines.2'
            )}
          </li>
          <li>
            {t(
              'components.PrintLayouts.NTP.nonCooperatingLawyer.addressLines.3'
            )}
          </li>
          <li>
            {t('components.PrintLayouts.NTP.nonCooperatingLawyer.phoneLines.1')}
          </li>
          <li>
            {t('components.PrintLayouts.NTP.nonCooperatingLawyer.phoneLines.2')}
          </li>
          <li>
            {t('components.PrintLayouts.NTP.nonCooperatingLawyer.faxLine')}
          </li>
        </ul>
        <div
          className="ntp-print-instruction-text"
          dangerouslySetInnerHTML={{ __html: html1b }}
        />
      </div>
    </div>
  );
};

export const renderCooperatingLawyerInstructions = (t) => {
  return [...new Array(5)].map((na, index) => (
    <Fragment key={index}>
      <div className="ntp-print-cooperating-lawyer-gutter" />
      {renderNtpInstruction(t, index, 'cooperatingLawyer')}
    </Fragment>
  ));
};

export const renderNonCooperatingLawyerInstructions = (t) => {
  return [...new Array(4)].map((na, index) => (
    <Fragment key={index}>
      <div className="ntp-print-non-cooperating-lawyer-gutter" />
      {index === 0
        ? renderFirstNonCooperatingLawyerInstruction(t)
        : renderNtpInstruction(t, index, 'nonCooperatingLawyer')}
    </Fragment>
  ));
};

export const renderTickbox = (checked = false) => (
  <div className="ntp-print-tickbox">
    {checked && (
      <div className="ntp-print-tickbox-icon-container">
        <FontAwesomeIcon icon={faTimes} />
      </div>
    )}
  </div>
);

//due to how <li className="print-line"> shows text (text can not go to the next line)
//I created this function to split the legal problem description into multiple lines using multiple <li>.
export const renderLegalProblemSection = (legalProblemData, t) => {
  if (!Array.isArray(legalProblemData)) {
    return (
      <li className="print-line">
        <span>
          {t('components.PrintLayouts.NTP.header.metadata.legalProblem')}{' '}
          {legalProblemData}
        </span>
      </li>
    );
  } else {
    return (
      <Fragment>
        <li className="print-line">
          <span>
            {t('components.PrintLayouts.NTP.header.metadata.legalProblem')}{' '}
            {legalProblemData[0]}
          </span>
        </li>
        <li className="print-line">
          <span>{legalProblemData[1]}</span>
        </li>
        <li className="print-line">
          <span>{legalProblemData[2]}</span>
        </li>
      </Fragment>
    );
  }
};
