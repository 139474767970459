import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { ActivityIndicatorCard } from 'js/components/design-system';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import LawyerPage from '../lawyer-page';
import { LawyerProvider } from '../lawyer-context';
import { lawyerPanelsNamedPermissions } from '../lawyer-named-permissions';
import LawyerPanelsColumns from './columns';
import {
  onMountEffect,
  getLawyerPanelsEffect,
  patchLawyerPanelsEffect,
} from './effects';

const LawyerPanels = () => {
  const history = useHistory();
  const { params = {} } = useRouteMatch();
  const { lawyerId } = params;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerPanels');

  const getLawyerPanelsAbortControllerRef = useRef(null);
  const patchLawyerPanelsAbortControllerRef = useRef(null);

  const [isLoadingPanels, setLoadingPanels] = useState(false);
  const [isSavingPanels, setSavingPanels] = useState(false);
  const [panels, setPanels] = useState([]);

  const isActive = isLoadingPanels || isSavingPanels;

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerPanelsAbortControllerRef,
        patchLawyerPanelsAbortControllerRef,
      ],
      setLoadingPanels,
      setSavingPanels,
    }),
    []
  );

  useEffect(
    getLawyerPanelsEffect({
      t,
      api,
      lawyerId,
      setLoadingPanels,
      setPanels,
      getLawyerPanelsAbortControllerRef,
    }),
    [lawyerId]
  );

  const savePanels = patchLawyerPanelsEffect({
    t,
    api,
    lawyerId,
    panels,
    setSavingPanels,
    resetInteractionCount,
    patchLawyerPanelsAbortControllerRef,
  });

  return (
    <LawyerPage
      className="lawyer-panels"
      title={t('components.LawyerPanels.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() =>
              history.push(
                routePathReplacingParams(routePaths.lawyerPrint, { lawyerId })
              )
            }
            disabled={isActive}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={savePanels}
            disabled={isActive || !hasEditPermission}
          >
            {isSavingPanels ? t('common.saving') : t('common.save')}
          </button>
        </Fragment>
      }
    >
      <NavigationSaveModal
        proceedAfter={async () => await savePanels()}
        shouldBlockNavigation={() => hasEditPermission && interactionCount > 0}
      />
      {isLoadingPanels && <ActivityIndicatorCard />}
      {!isLoadingPanels && (
        <form
          onSubmit={(e) => e.preventDefault()}
          onClick={incrementInteractionCount}
        >
          <LawyerPanelsColumns panels={panels} setPanels={setPanels} />
        </form>
      )}
    </LawyerPage>
  );
};

const LawyerPanelsWithContext = (props) => (
  <LawyerProvider>
    <LawyerPanels {...props} />
  </LawyerProvider>
);

const LawyerPanelsWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={lawyerPanelsNamedPermissions}>
    <LawyerPanelsWithContext {...props} />
  </NamedPermissionsProvider>
);

const InteractionTrackingLawyerPanels = (props) => (
  <InteractionTrackingProvider>
    <LawyerPanelsWithNamedPermissions {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingLawyerPanels;
