import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LabelValuePair } from 'js/components/design-system';
import { feedbackPropTypes } from './functions';

const LawyerFeedbackPrintProperties = (props) => {
  const { t } = useTranslation();
  const { className, feedback = {} } = props;
  const {
    typeOfComment = false,
    dateOpened = '',
    dateClosed = '',
    sourceOfCommentDesc = '',
    negativeReasonDesc = '',
    resolvedHowDesc = '',
    resolvedByDesc = '',
  } = feedback;
  const typeOfCommentDesc = typeOfComment
    ? t('components.LawyerFeedbackProperties.positive')
    : t('components.LawyerFeedbackProperties.negative');

  return (
    <div className={classnames('lawyer-feedback-print-properties', className)}>
      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.sourceOfComment')}
        value={sourceOfCommentDesc}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.typeOfComment')}
        value={typeOfCommentDesc}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.negativeReason')}
        value={negativeReasonDesc}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.resolvedHow')}
        value={resolvedHowDesc}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.resolvedBy')}
        value={resolvedByDesc}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.dateOpened')}
        value={dateOpened}
      />

      <LabelValuePair
        label={t('components.LawyerFeedbackProperties.dateClosed')}
        value={dateClosed}
      />
    </div>
  );
};

LawyerFeedbackPrintProperties.propTypes = {
  className: PropTypes.string,
  feedback: PropTypes.shape(feedbackPropTypes).isRequired,
};

export default LawyerFeedbackPrintProperties;
