import { queryForParams } from 'js/utilities/params';
import { defaultTypeFilterForModel } from './functions';

export const onMountEffect = (options = {}) => {
  const {
    query: { params = {} },
    setTypeFilterValue,
    setCityFilterValue,
    setCompanyFilterValue,
    setFirmFilterValue,
  } = options;
  return () => {
    const { type = '', city = '', company = '', firmname = '' } = params;
    setTypeFilterValue(type);
    setCityFilterValue(city);
    setCompanyFilterValue(company);
    setFirmFilterValue(firmname);
  };
};

export const onChangeInputEffect = (options = {}) => {
  const { setValue } = options;
  return (e) => setValue(e.target.value);
};

export const resetEffect = (options = {}) => {
  const { model, params, onApplyFilters } = options;

  const filters = {
    typeFilterValue: defaultTypeFilterForModel(model),
    cityFilterValue: '',
    companyFilterValue: '',
    firmFilterValue: '',
  };

  return applyFiltersEffect({
    model,
    params,
    filters,
    onApplyFilters,
  });
};

export const applyFiltersEffect = (options = {}) => {
  const { model, params, filters = {}, onApplyFilters } = options;
  const {
    typeFilterValue,
    cityFilterValue,
    companyFilterValue,
    firmFilterValue,
  } = filters;
  return () => {
    let nextParams = {
      ...params,
      Offset: 0,
      type: typeFilterValue,
      city: cityFilterValue,
    };

    switch (model) {
      case 'client':
        nextParams = { ...nextParams, company: companyFilterValue };
        break;
      case 'lawyer':
        nextParams = { ...nextParams, firmname: firmFilterValue };
        break;
      default:
        break;
    }

    const nextQuery = queryForParams(nextParams);
    onApplyFilters(nextQuery);
  };
};
