import {
  parseDateFromISOString,
  formatDateFromISOString,
} from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';

export const cleanCompany = (company = {}) => {
  return {
    companyCode: cleanStringField(company.companyCode),
    companyName: cleanStringField(company.companyName),
    coAddr1: cleanStringField(company.coAddr1),
    coAddr2: cleanStringField(company.coAddr2),
    coCity: cleanStringField(company.coCity),
    coProv: cleanStringField(company.coProv),
    coPcode: cleanStringField(company.coPcode),
    coPhone1: cleanStringField(company.coPhone1),
    coPhone2: cleanStringField(company.coPhone2),
    coFax: cleanStringField(company.coFax),
    coQualify: company.coQualify,
    coBenstrt: parseDateFromISOString(company.coBenstrt),
    coBenend: parseDateFromISOString(company.coBenend),
    coInact: company.coInact === true,
  };
};

export const cleanCompanies = (companies = []) => companies.map(cleanCompany);

export const cleanCompanyFeeSchedule = (feeSchedule = {}) => {
  return {
    scheduleCode: cleanStringField(feeSchedule.scheduleCode),
    scheduleName: cleanStringField(feeSchedule.scheduleName),
    versionDate: formatDateFromISOString(feeSchedule.versionDate),
  };
};

export const getCompanyFeeScheduleTitle = (scheduleCode, scheduleName) => {
  if (scheduleCode && scheduleName) {
    return `${scheduleCode} - ${scheduleName}`;
  } else {
    return '';
  }
};
