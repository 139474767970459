import React, { useState, useContext, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import NTPPrintLayout from '../ntp-print-layout';
import { getCaseEffect, printEffect } from './effects';
import Page from 'js/components/page';

const PrintSingleNTP = () => {
  const { t } = useTranslation();
  const { params = {} } = useRouteMatch();
  const cif = decodeURIComponent(params.cif);
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [ntpInfo, setNtpInfo] = useState({});
  const { api = {} } = useContext(FetchContext);
  const getCaseAbortControllerRef = useRef(null);

  useEffect(
    getCaseEffect({
      api,
      cif,
      setNtpInfo,
      setError,
      setActive,
      getCaseAbortControllerRef,
    }),
    []
  );

  useEffect(printEffect({ ntpInfo, active, t }), [
    ntpInfo.caseInfo?.caseInformation?.cif,
    active,
    t,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.ntpData', {
            message: error.message,
          })}
        </p>
      )}
      {!active && !isEmpty(ntpInfo) && <NTPPrintLayout ntpInfo={ntpInfo} />}
    </Page>
  );
};

export default PrintSingleNTP;
