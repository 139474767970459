import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { caseApiKey, cleanCase } from 'js/utilities/cases';
import { clientApiKey, cleanNestedClient } from 'js/utilities/clients';
import { resolveFeedbackErrorMessage } from './functions';
import { formatDate } from 'js/utilities/dates';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const dismissBannerOnUnmountEffect = (options = {}) => {
  const { bannerId, dismissBanner } = options;
  return () => () => dismissBanner(bannerId);
};

export const getCaseMetadataEffect = (options = {}) => {
  const { t, api, setCaseMetadataActive, setCaseMetadata } = options;
  return async (cif) => {
    setCaseMetadataActive(true);

    try {
      // Fetch the case:
      const caseUrl = caseApiKey(cif);
      const { json: caseJson = {} } = await api.getJson(
        caseUrl,
        {},
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.AddLawyerFeedback.caseRequestError'),
            },
          },
        }
      );

      const { caseInformation = {} } = cleanCase(caseJson);
      const {
        clientId,
        clientCode,
        legalProblemCode,
        openDate,
      } = caseInformation;

      // Fetch the client:
      const clientUrl = clientApiKey(clientId, clientCode);
      const { json: clientJson = {} } = await api.getJson(
        clientUrl,
        {},
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.AddLawyerFeedback.clientRequestError'),
            },
          },
        }
      );

      const { clientPersonal = {} } = cleanNestedClient(clientJson);
      const { prefix, firstName, lastName } = clientPersonal;

      const caseMetadata = {
        prefix,
        firstName,
        lastName,
        legalProblemCode,
        dateOpened: openDate,
      };

      setCaseMetadata(caseMetadata);
      setCaseMetadataActive(false);
    } catch (error) {
      setCaseMetadataActive(false);
    }
  };
};

export const updateLawyerFeedbackEffect = (options = {}) => {
  const { lawyerFeedback, setLawyerFeedback } = options;
  return (name, value) => {
    const nextLawyerFeedback = { ...lawyerFeedback, [name]: value };
    setLawyerFeedback(nextLawyerFeedback);
  };
};

export const resolveFeedbackErrorMessageEffect = (options = {}) => {
  const { t } = options;
  return (result) => resolveFeedbackErrorMessage(t, result);
};

export const postLawyerFeedbackEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    lawyerId,
    lawyerFeedback,
    setActive,
    onDismiss,
    postLawyerFeedbackAbortControllerRef,
    presentStyledBanner,
    postFeedbackFiles,
  } = options;

  return async () => {
    const { isValid } = validateAll(lawyerFeedback);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);

    // Convert typeOfComment to a bool and default fields that may not be required
    // Separate the feedBackDocuments array for use in subsequent documents POST requests.
    const {
      typeOfComment,
      negativeReason = '',
      resolvedBy = '',
      resolvedHow = '',
      dateClosed = null,
      feedBackDocuments = [],
      ...feedbackProperties
    } = lawyerFeedback;
    const body = {
      ...feedbackProperties,
      negativeReason,
      resolvedBy,
      resolvedHow,
      dateClosed,
      // Weird key case as expected by the API:
      typeOFComment: typeOfComment === 'true',
    };

    rotateAbortControllerRef(postLawyerFeedbackAbortControllerRef);
    const { signal } = postLawyerFeedbackAbortControllerRef.current;

    try {
      const { json = {} } = await api.postJson(
        `/Lawyer/${lawyerId}/Feedback`,
        { body, signal },
        {
          success: {
            // Defer success banner until all requests are complete
            bypass: true,
          },
          error: {
            context: {
              message: resolveFeedbackErrorMessageEffect({ t }),
            },
          },
        }
      );

      const { feedbackId } = json;

      if (feedbackId && feedBackDocuments.length > 0) {
        try {
          await postFeedbackFiles(feedbackId, feedBackDocuments);
        } catch (error) {
          if (!isAbortError(error)) {
            setActive(false);
          }
        }
      }

      presentStyledBanner('success', {
        content: t('components.AddLawyerFeedback.requestSuccess'),
      });

      setActive(false);
      if (typeof onDismiss === 'function') {
        onDismiss(true);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const patchLawyerFeedbackEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    lawyerId,
    lawyerFeedback,
    setActive,
    onDismiss,
    patchLawyerFeedbackAbortControllerRef,
    presentStyledBanner,
    postFeedbackFiles,
    postDateClosed,
  } = options;

  return async () => {
    const { isValid } = validateAll(lawyerFeedback);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);

    // Convert typeOfComment to a bool and default fields that may not be required
    // Separate the feedBackDocuments array for use in subsequent documents PATCH requests.
    const {
      typeOfComment,
      negativeReason = '',
      resolvedBy = '',
      resolvedHow = '',
      dateClosed = null,
      feedBackDocuments = [],
      feedbackId = '',
      ...feedbackProperties
    } = lawyerFeedback;

    const body = {
      ...feedbackProperties,
      negativeReason,
      resolvedBy,
      resolvedHow,
      dateClosed,
      // Weird key case as expected by the API:
      typeOFComment: typeOfComment || typeOfComment === 'true',
    };

    rotateAbortControllerRef(patchLawyerFeedbackAbortControllerRef);
    const { signal } = patchLawyerFeedbackAbortControllerRef.current;
    const endpoint = `/Lawyer/${lawyerId}/Feedback/${feedbackId}`;

    try {
      await api.patchJson(
        endpoint,
        { body, signal },
        {
          success: {
            // Defer success banner until all requests are complete
            bypass: true,
          },
          error: {
            context: {
              message: resolveFeedbackErrorMessageEffect({ t }),
            },
          },
        }
      );

      if (feedbackId && feedBackDocuments.length > 0) {
        try {
          await postFeedbackFiles(feedbackId, feedBackDocuments);
        } catch (error) {
          if (!isAbortError(error)) {
            setActive(false);
          }
        }
      }

      //allow users to only close feedback once by sending a date object
      //only going forward only reading it as a string from the api
      if (feedbackId && dateClosed && typeof dateClosed !== 'string') {
        try {
          await postDateClosed(feedbackId, dateClosed);
        } catch (error) {
          if (!isAbortError(error)) {
            setActive(false);
          }
        }
      }

      presentStyledBanner('success', {
        content: t('components.AddLawyerFeedback.requestSuccess'),
      });

      setActive(false);
      if (typeof onDismiss === 'function') {
        onDismiss(true);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const postFeedbackFilesEffect = (options = {}) => {
  const { t, api, lawyerId } = options;
  return async (feedbackId, feedBackDocuments) => {
    if (feedbackId && feedBackDocuments.length > 0) {
      const documentForms = feedBackDocuments.reduce((allFiles, file) => {
        if (file instanceof File) {
          const formData = new FormData();
          formData.append('DocumentName', file.name);
          formData.append('Document', file);
          return [...allFiles, formData];
        } else {
          return allFiles;
        }
      }, []);

      const documentRequests = documentForms.map((form) => {
        return api.fetch(
          `/Lawyer/${lawyerId}/FeedBack/Document/${feedbackId}`,
          {
            method: 'POST',
            body: form,
          },
          {
            success: {
              // Defer success banner until all requests are complete
              bypass: true,
            },
            error: {
              context: {
                message: t('components.AddLawyerFeedback.requestError'),
              },
            },
          }
        );
      });
      await Promise.all(documentRequests);
    }
  };
};

export const postDateClosedEffect = (options = {}) => {
  const { t, api, lawyerId } = options;
  return async (feedbackId, dateClosed) => {
    const body = { dateClosed: formatDate(dateClosed) };

    return api.postJson(
      `/Lawyer/${lawyerId}/FeedBack/${feedbackId}/close`,
      {
        method: 'POST',
        body,
      },
      {
        success: {
          // Defer success banner until all requests are complete
          bypass: true,
        },
        error: {
          context: {
            message: t('components.AddLawyerFeedback.requestError'),
          },
        },
      }
    );
  };
};

export const deleteLawyerFeedbackEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    feedbackId,
    setActive,
    onDismiss,
    deleteLawyerFeedbackAbortControllerRef,
  } = options;

  return async () => {
    setActive(true);

    rotateAbortControllerRef(deleteLawyerFeedbackAbortControllerRef);
    const { signal } = deleteLawyerFeedbackAbortControllerRef.current;

    try {
      await api.deleteJson(
        `/Lawyer/${lawyerId}/Feedback/${feedbackId}`,
        { signal },
        {
          success: {
            context: {
              message: t('components.DeleteLawyerFeedback.requestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.DeleteLawyerFeedback.requestError'),
            },
          },
        }
      );

      setActive(false);
      if (typeof onDismiss === 'function') {
        onDismiss(true);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const saveLawyerFeedbackEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner, setBannerId } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        const bannerId = presentStyledBanner('error', {
          content: error.message,
        });
        setBannerId(bannerId);
      }
      throw error;
    }
  };
};

export const editLawyerFeedbackEffect = (options = {}) => {
  const { t, editEffect, presentStyledBanner, setBannerId } = options;
  return async () => {
    try {
      await editEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        const bannerId = presentStyledBanner('error', {
          content: error.message,
        });
        setBannerId(bannerId);
      }
      throw error;
    }
  };
};

export const setEditingFeedbackEffect = (options = {}) => {
  const {
    editingFeedback,
    setLawyerFeedback,
    getCaseMetadata,
    isEditing,
  } = options;
  return () => {
    if (isEditing) {
      setLawyerFeedback(editingFeedback);
      if (editingFeedback.cif) {
        getCaseMetadata(editingFeedback.cif);
      }
    }
  };
};
