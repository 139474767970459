import React, { createContext, useContext, useEffect, useState } from 'react';
import { FetchContext } from 'js/components/fetch';
import { MSALContext } from 'js/components/msal';
import { getVersionInfoEffect } from './effects';

const VersionInfoContext = createContext({
  hasLoaded: false,
  versionInfo: {},
});

const VersionInfoProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [versionInfo, setVersionInfo] = useState({});
  const { api = {} } = useContext(FetchContext);
  const { isReady, account } = useContext(MSALContext);

  useEffect(
    getVersionInfoEffect({
      api,
      isReady,
      account,
      setVersionInfo,
      setHasLoaded,
    }),
    [isReady]
  );

  return (
    <VersionInfoContext.Provider value={{ hasLoaded, versionInfo }}>
      {children}
    </VersionInfoContext.Provider>
  );
};

export { VersionInfoContext, VersionInfoProvider };
