import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ActivityIndicatorDot from './activity-indicator-dot';
import {
  numberOfDots,
  defaultDiameter,
  defaultAnimationDurationMS,
} from './functions';

const ActivityIndicator = (props) => {
  const { className, diameter, animationDurationMS } = props;
  return (
    <div
      className={classnames('activity-indicator', className)}
      style={{ width: diameter, height: diameter }}
    >
      {[...new Array(numberOfDots)].map((na, index) => (
        <ActivityIndicatorDot
          key={index}
          index={index}
          numberOfDots={numberOfDots}
          containerRadius={diameter / 2}
          animationDurationMS={animationDurationMS}
        />
      ))}
    </div>
  );
};

ActivityIndicator.defaultProps = {
  diameter: defaultDiameter,
  animationDurationMS: defaultAnimationDurationMS,
};

ActivityIndicator.propTypes = {
  className: PropTypes.string,
  diameter: PropTypes.number,
  animationDurationMS: PropTypes.number,
};

export default ActivityIndicator;
