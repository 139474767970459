import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChequeRunFailedChequeItem from './failed-cheque-item';
import ChequeRunReportItem from './report-item';
import { onClickConfirmAndUnlockEffect } from './effects';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';

const ChequeRunResults = (props) => {
  const { t } = useTranslation();
  const {
    results = {},
    isConfirmRunActive,
    onClickConfirmRun,
    onClickDeleteRun,
  } = props;
  const { failedCheques = [], reports = [] } = results;
  const [areFailedChequesVisible, setFailedChequesVisible] = useState(false);
  const hasReports = reports.length > 0;

  const downloadAllZip = reports.find((report) => report.content === 'zip');

  return (
    <div className="cheque-run-results">
      {hasReports && downloadAllZip && (
        <div className="download-button-bar">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={downloadAllZip.reportUri}
            download={downloadAllZip.name}
          >
            <FontAwesomeIcon icon={faCloudDownloadAlt} />
            {t('components.ChequeRunResults.downloadAll')}
          </a>
        </div>
      )}

      {failedCheques.length > 0 && (
        <div className="cheque-run-failed-cheques">
          <p>
            <span>
              {t('components.ChequeRunResults.failedCheques.message', {
                count: failedCheques.length,
              })}
            </span>
            <button
              className="button-link-appearance"
              onClick={() => setFailedChequesVisible(!areFailedChequesVisible)}
            >
              {areFailedChequesVisible
                ? t('components.ChequeRunResults.hideDetails')
                : t('components.ChequeRunResults.showDetails')}
            </button>
          </p>
          {areFailedChequesVisible && (
            <ul>
              {failedCheques.map((data, idx) => (
                <ChequeRunFailedChequeItem key={idx} {...data} />
              ))}
            </ul>
          )}
        </div>
      )}

      {hasReports && (
        <ul className="cheque-run-reports">
          {reports.map((report, idx) => {
            if (report.content !== 'zip') {
              return <ChequeRunReportItem key={idx} report={report} />;
            }
          })}
        </ul>
      )}

      {!hasReports && (
        <p>{t('components.ChequeRunResults.reports.emptyState')}</p>
      )}

      <div className="button-bar">
        <button
          className="button"
          disabled={isConfirmRunActive}
          onClick={onClickDeleteRun}
        >
          {t('components.ChequeRunResults.cancelButtonTitle')}
        </button>
        <button
          className="button button-highlight"
          disabled={!hasReports || isConfirmRunActive}
          onClick={onClickConfirmAndUnlockEffect({ onClickConfirmRun })}
        >
          {isConfirmRunActive
            ? t('components.ChequeRunResults.confirmButtonTitleActive')
            : t('components.ChequeRunResults.confirmButtonTitle')}
        </button>
      </div>
    </div>
  );
};

ChequeRunResults.propTypes = {
  results: PropTypes.object.isRequired,
  isConfirmRunActive: PropTypes.bool.isRequired,
  onClickConfirmRun: PropTypes.func.isRequired,
  onClickDeleteRun: PropTypes.func.isRequired,
};

export default ChequeRunResults;
