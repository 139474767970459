import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const lawyerCaseHistorySortOptions = [
  {
    key: 'date-asc',
    field: 'date',
    direction: 'asc',
    i18nKey: 'components.LawyerCaseHistorySortMenu.dateAsc',
    icon: faSortAmountDown,
  },
  {
    key: 'date-desc',
    field: 'date',
    direction: 'desc',
    i18nKey: 'components.LawyerCaseHistorySortMenu.dateDesc',
    icon: faSortAmountDownAlt,
  },
];
