import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView, queryForListPrintView } from 'js/components/print';
import { useTranslation } from 'react-i18next';
import { lawFirmsListPrintRequestEffect } from './effects';
import { queryForLawFirms, getLawFirmPrintAddress } from './functions';

const LawFirmsListPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const { search = '' } = useLocation();
  const query = queryForListPrintView(queryForLawFirms(search));

  return (
    <ListPrintView
      request={lawFirmsListPrintRequestEffect({ api, query })}
      renderTableHeaders={() => (
        <tr>
          <th>{t('components.LawFirmsListPrintView.columns.name')}</th>
          <th>{t('components.LawFirmsListPrintView.columns.address')}</th>
          <th>{t('components.LawFirmsListPrintView.columns.email')}</th>
          <th>{t('components.LawFirmsListPrintView.columns.phone')}</th>
          <th>{t('components.LawFirmsListPrintView.columns.fax')}</th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.firmId}>
          <td>{item.firmName}</td>
          <td
            dangerouslySetInnerHTML={{ __html: getLawFirmPrintAddress(item) }}
          />
          <td>{item.email}</td>
          <td>
            <div>{item.primaryPhone}</div>
            {item.secondaryPhone && <div>{item.secondaryPhone}</div>}
          </td>
          <td>{item.fax}</td>
        </tr>
      )}
      emptyStateTitle={t('components.LawFirmsListPrintView.emptyStateTitle')}
      headerProps={{
        title: t('components.LawFirmsListPrintView.title'),
        heading: t('components.LawFirmsListPrintView.heading'),
        printOnly: false,
      }}
      displayFooter={false}
    />
  );
};

export default LawFirmsListPrintView;
