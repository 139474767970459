import { routePaths } from 'js/components/router/route-paths';
import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const legalProblemCodesBreadcrumbsHistory = (t) => {
  const prevHistory = systemSettingsBreadcrumbsHistory(t);
  const history = [
    ...prevHistory,
    {
      content: t('components.LegalProblemCodes.breadcrumbs.legalProblemCodes'),
      path: routePaths.legalProblemCodes,
    },
  ];

  return history;
};

export const queryForSearchString = (search = '') => {
  const {
    Keyword = '',
    Order = 'codes',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};
