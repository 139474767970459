export const getErrorCode = (error) => {
  const { body = {} } = error;
  const { error_code: code = -1 } = body;
  return code;
};

export const resolveMessageForErrorCode = (
  error,
  defaultMessage = '',
  messagesByErrorCode = {}
) => {
  const code = getErrorCode(error);
  const message = messagesByErrorCode[code];
  return message || defaultMessage;
};

export const parseEmailFromErrorDescription = (error) => {
  // Regexp pattern that matches the loose format of an email
  // delimited by single or double quotes, within a larger string:
  //  - one or more of any character
  //  - @ symbol
  //  - one or more of any character
  //  - dot
  //  - one or more of any character except a single or double quote
  const matchPattern = /('|")(.+@.+\.[a-zA-Z]+)('|")/;

  // Regexp pattern that matches the loose format of an email without
  // surrounding quotes:
  const emailPattern = /^.+@.+\.[a-zA-Z]+$/;

  // Try to find an email delimited by quotes in the error description string:
  const { body = {} } = error;
  const { error_description: description = '' } = body;
  const match = description.match(matchPattern);

  let email = '';

  if (match && match.length > 1) {
    const captureGroups = match.slice(1);
    const capturedEmail = captureGroups.find((group) => {
      return emailPattern.test(group);
    });

    if (capturedEmail) {
      email = capturedEmail;
    }
  }

  return email;
};
