import 'react-datepicker/dist/react-datepicker.css';
import '../css/index.css';

import React from 'react';
import App from 'js/components/app';
import { render } from 'react-dom';

window.onload = () => {
  const jsx = <App />;
  const root = document.querySelector('.app');
  render(jsx, root);
};
