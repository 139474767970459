import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { onKeyDownHeaderMenuItemEffect } from './effects';

const HeaderMenuItem = (props) => {
  const { className, isMenuVisible, setMenuVisibility, children } = props;
  return (
    <li
      role="menuitem"
      className={classnames('header-menu-item', className)}
      tabIndex={isMenuVisible ? 0 : -1}
      onKeyDown={onKeyDownHeaderMenuItemEffect({ setMenuVisibility })}
    >
      {children}
    </li>
  );
};

HeaderMenuItem.propTypes = {
  className: PropTypes.string,
  isMenuVisible: PropTypes.bool.isRequired,
  setMenuVisibility: PropTypes.func.isRequired,
};

export default HeaderMenuItem;
