import classnames from 'classnames';

export const styledBannerTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export const styledBannerOptions = {
  [styledBannerTypes.info]: {
    className: 'banner-info',
  },
  [styledBannerTypes.success]: {
    className: 'banner-success',
    timeout: 4000,
  },
  [styledBannerTypes.warning]: {
    className: 'banner-warning',
  },
  [styledBannerTypes.error]: {
    className: 'banner-error',
    timeout: 4000,
  },
};

export const styledBannerOptionsForType = (bannerType, bannerClassName) => {
  const { className, ...options } = styledBannerOptions[bannerType] || {};
  return {
    className: classnames(className, bannerClassName),
    ...options,
  };
};
