import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { cleanStringField } from 'js/utilities/strings';
import {
  isEmpty,
  validatePhoneNumber,
  validatePostalCode,
} from 'js/utilities/validation';

export const queryForUnions = (search = '') => {
  const {
    Keyword = '',
    SortBy = 'name',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    SortBy,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};

export const unionBreadcrumbsHistory = (t) => {
  const prevHistory = adminToolsBreadcrumbsHistory(t);
  return [
    ...prevHistory,
    {
      content: t('components.UnionListPage.breadcrumbs.unions'),
      path: routePaths.unions,
    },
  ];
};

export const unionDetailsBreadcrumbsHistory = (t, unionNumber) => {
  const prevHistory = unionBreadcrumbsHistory(t);
  return [
    ...prevHistory,
    {
      content:
        unionNumber === 'new'
          ? t('components.UnionDetailsPage.breadcrumbs.new')
          : t('components.UnionDetailsPage.breadcrumbs.details'),
      path: routePathReplacingParams(routePaths.company, { unionNumber }),
    },
  ];
};

export const getSaveButtonTitle = (t, isNew, active) => {
  if (isNew) {
    return active ? t('common.creating') : t('common.create');
  } else {
    return active ? t('common.saving') : t('common.save');
  }
};

export const cleanUnion = (union = {}) => {
  const {
    unionNumber = '',
    unionName = '',
    locAddr1 = '',
    locAddr2 = '',
    locCity = '',
    locProv = '',
    locPcode = '',
    locPhone1 = '',
    locPhone2 = '',
    locFax = '',
    locInact = false,
  } = union;

  return {
    ...union,
    unionNumber: cleanStringField(unionNumber),
    unionName: cleanStringField(unionName),
    locAddr1: cleanStringField(locAddr1),
    locAddr2: cleanStringField(locAddr2),
    locCity: cleanStringField(locCity),
    locProv: cleanStringField(locProv),
    locPcode: cleanStringField(locPcode),
    locPhone1: cleanStringField(locPhone1),
    locPhone2: cleanStringField(locPhone2),
    locFax: cleanStringField(locFax),
    locInact,
  };
};

export const getLinkedCompanyColumns = (t) => {
  const columns = ['companyCode', 'companyName', 'coCity', 'coProv'].map(
    (key) => ({
      key,
      headerContent: t(
        `components.UnionDetailsPage.LinkedCompanyList.columns.${key}`
      ),
    })
  );

  return columns;
};

export const unionValidationRules = {
  unionNumber: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 4;
  },
  unionName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  locPhone1: (data, key) => validatePhoneNumber(data[key], false),
  locPhone2: (data, key) => validatePhoneNumber(data[key], false),
  locFax: (data, key) => validatePhoneNumber(data[key], false),
  locAddr1: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  locAddr2: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  locCity: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 20;
  },
  locPcode: (data, key) => validatePostalCode(data[key], false),
};

export const getUnionPrintAddress = (union = {}) => {
  const { locAddr1, locAddr2, locCity, locProv, locPcode } = union;
  const lines = [locAddr1, locAddr2, `${locCity} ${locProv}`, locPcode];
  return lines
    .map((line) => line.trim())
    .filter((line) => !!line)
    .join('<br />');
};
