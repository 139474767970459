import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Card from '../card';
import {
  textRequiresTruncation,
  truncateTextIfRequired,
} from 'js/utilities/strings';
import { GroupPermissionsContext } from 'js/components/group-permissions/context';
import { userGroups } from 'js/components/group-permissions';

const NoteCard = (props) => {
  const { t } = useTranslation();
  const {
    className,
    id,
    text,
    name,
    date,
    onClickDelete,
    onClickEdit,
    permittedGroups,
    permittedGroupsForDelete,
    disableButtons = false,
  } = props;
  const { matchAnyGroup } = useContext(GroupPermissionsContext);

  const [truncated, setTruncated] = useState(true);
  const requiresTruncation = textRequiresTruncation(text);
  const content = truncateTextIfRequired(text, truncated && requiresTruncation);
  const requiresDeleteButton = typeof onClickDelete === 'function';
  const requiresEditButton = typeof onClickEdit === 'function';
  const requiresFooter = requiresDeleteButton || requiresTruncation;
  const canEdit = matchAnyGroup(permittedGroups);
  const canDelete = matchAnyGroup(permittedGroupsForDelete);

  return (
    <Card className={classnames('note-card', className)}>
      <div className="note-card-header">
        <ul className="note-card-meta">
          <li>{date}</li>
          <li>{t('components.DesignSystem.NoteCard.author', { name })}</li>
        </ul>
      </div>
      {/* Render the full text for print: */}
      <div className="note-card-body" data-print-only>
        {text}
      </div>
      {/* Render the potentially truncated text for screen: */}
      <div className="note-card-body" data-screen-only>
        <p>{content}</p>
      </div>
      {requiresFooter && (
        <div className="note-card-footer" data-screen-only>
          {requiresDeleteButton && canDelete && (
            <button
              className="button-link-appearance"
              onClick={() => onClickDelete(id)}
              disabled={disableButtons}
            >
              {t('common.delete')}
            </button>
          )}
          {requiresEditButton && canEdit && (
            <button
              className="button-link-appearance"
              onClick={() => onClickEdit(id)}
              disabled={disableButtons}
            >
              {t('common.edit')}
            </button>
          )}
          {requiresTruncation && (
            <button
              className="button-link-appearance"
              onClick={() => setTruncated(!truncated)}
            >
              {truncated
                ? t('components.DesignSystem.NoteCard.readMore')
                : t('components.DesignSystem.NoteCard.readLess')}
            </button>
          )}
        </div>
      )}
    </Card>
  );
};

NoteCard.defaultProps = {
  text: '',
  name: '',
  permittedGroups: [userGroups.administrator, userGroups.dataEntry],
  permittedGroupsForDelete: [userGroups.administrator, userGroups.dataEntry],
};

NoteCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  onClickEdit: PropTypes.func,
  permittedGroups: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(userGroups))
  ),
  permittedGroupsForDelete: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(userGroups))
  ),
  disableButtons: PropTypes.bool,
};

export default NoteCard;
