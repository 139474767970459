import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { breadcrumbTypes } from 'js/components/design-system';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryFilteringSearchParamsForLawyerTabs = (search = '') => {
  const permittedKeys = ['from', 'fromTitle'];
  const params = paramsFromSearchString(search);
  const filteredParams = permittedKeys.reduce((nextParams, key) => {
    if (params[key]) {
      return { ...nextParams, [key]: params[key] };
    } else {
      return nextParams;
    }
  }, {});
  return queryForParams(filteredParams);
};

export const linkPropsForRoutePath = (path, params = {}, search = '') => {
  const lawyerPath = routePathReplacingParams(path, params);
  return {
    linkTo: `${lawyerPath}${search}`,
    isActive: (currentPath) => currentPath === path,
  };
};

export const lawyerTabItems = (options = {}) => {
  const {
    t,
    params = {},
    search = '',
    canViewFeedback = false,
    isStaffLawyer = false,
    showLawyerFeedbackCount,
    lawyerFeedbackCount,
  } = options;

  const infoTab = {
    ...linkPropsForRoutePath(routePaths.lawyerInformation, params, search),
    content: t('components.LawyerInformation.title'),
  };

  const panelsTab = {
    ...linkPropsForRoutePath(routePaths.lawyerPanels, params, search),
    content: t('components.LawyerPanels.title'),
  };

  let lawyerFeedbackTabContent = t('components.LawyerFeedback.title');
  if (showLawyerFeedbackCount) {
    lawyerFeedbackTabContent = `${lawyerFeedbackTabContent} (${lawyerFeedbackCount})`;
  }

  const feedbackTab = {
    ...linkPropsForRoutePath(routePaths.lawyerFeedback, params, search),
    content: lawyerFeedbackTabContent,
  };

  const caseHistoryTab = {
    ...linkPropsForRoutePath(routePaths.lawyerCaseHistory, params, search),
    content: t('components.LawyerCaseHistory.title'),
  };

  const commonTabs = [infoTab, panelsTab, caseHistoryTab];
  if (canViewFeedback && !isStaffLawyer) {
    return [...commonTabs, feedbackTab];
  } else if (isStaffLawyer) {
    return [infoTab, caseHistoryTab, feedbackTab];
  } else {
    return commonTabs;
  }
};

export const lawyerBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const { from: fromLocation, fromTitle } = paramsFromSearchString(search);
  const currentPath = `${pathname}${search}`;
  const history = [breadcrumbTypes.home];

  if (fromLocation && fromTitle) {
    history.push({
      content: fromTitle,
      path: fromLocation,
    });
  }

  history.push({
    content: t('components.LawyerPage.breadcrumbs.lawyer'),
    path: currentPath,
  });

  return history;
};
