import React, { useState, useContext, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import NTPPrintLayout from '../ntp-print-layout';
import { getMultipleNTPDataEffect, multiplePrintViewEffect } from './effects';
import EmptyState from 'js/components/controls/empty-state';
import Page from 'js/components/page';

const PrinMultipleNTP = () => {
  const { t } = useTranslation();
  const { params = {} } = useRouteMatch();
  const { date } = params;
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [NTPDataArray, setNTPDataArray] = useState([]);
  const { api = {} } = useContext(FetchContext);
  const getNtpAbortControllerRef = useRef(null);

  const getNTPDataEffectOptions = {
    api,
    date,
    setNTPDataArray,
    setActive,
    setError,
    getNtpAbortControllerRef,
  };

  useEffect(getMultipleNTPDataEffect(getNTPDataEffectOptions), []);
  useEffect(multiplePrintViewEffect({ NTPDataArray, active }), [
    NTPDataArray.length,
    active,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.ntpData', {
            message: error.message,
          })}
        </p>
      )}
      {!active &&
        NTPDataArray.length > 0 &&
        NTPDataArray.map((data, i) => (
          <NTPPrintLayout ntpInfo={data} key={i} />
        ))}
      {!active && !error && NTPDataArray.length === 0 && (
        <EmptyState
          title={t(`components.dayEnd.emptyStateTitle`)}
          subtitle={t(`components.dayEnd.emptyStateSubtitle`, {
            reportType: 'NTP',
            date: decodeURIComponent(date),
          })}
        />
      )}
    </Page>
  );
};

export default PrinMultipleNTP;
