import React from 'react';
import { InactiveTag } from 'js/components/deactivate';
import { cleanStringField } from 'js/utilities/strings';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const cleanLinkedLawyer = (lawyer = {}) => {
  return {
    ...lawyer,
    firstName: cleanStringField(lawyer.firstName),
    lastName: cleanStringField(lawyer.lastName),
    city: cleanStringField(lawyer.city),
    province: cleanStringField(lawyer.province),
  };
};

export const cleanLinkedLawyers = (lawyerInfo = []) =>
  lawyerInfo.map(cleanLinkedLawyer);

export const getLinkedLawyerColumns = (t) => {
  const columns = ['lawyerId', 'firstName', 'lastName', 'city', 'province'].map(
    (key) => ({
      key,
      headerContent: t(
        `components.LawFirmDetailsPage.LinkedLawyerList.columns.${key}`
      ),
    })
  );

  const inactiveColumn = {
    key: 'lawyerInactive',
    headerContent: '',
    sortable: false,
    // eslint-disable-next-line react/display-name
    get: (key, row) => <InactiveTag inactive={row[key] === true} />,
  };

  return [...columns, inactiveColumn];
};

export const queryForSearchString = (search = '') => {
  const {
    Keyword = '',
    Order = 'lastName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};
