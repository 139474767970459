import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { PromptContext } from 'js/components/prompt';
import { CurrentUserContext } from 'js/components/current-user';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { LawyerSummary } from 'js/components/lawyer-summary';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import {
  caseLawyerValidationRules,
  caseLawyerRequiredKeys,
  returnCanEditCase,
} from 'js/utilities/cases';
import CasePage from '../case-page';
import { CaseProvider, CaseContext } from '../case-context';
import { CaseLawyerInformationForm, LawyerCifForm } from '../case-forms';
import { caseLawyerNamedPermissions } from '../case-named-permissions';
import {
  onMountEffect,
  getLawyerEffect,
  updateCaseLawyerInfoEffect,
  patchCaseLawyerEffect,
  saveCaseLawyerEffect,
  selectLawyerEffect,
  setModalParamsEffect,
  resetModalParamsEffect,
  buildNextLawyerQueryEffect,
  setDefaultModalQueryEffect,
  cancelModalEffect,
} from './effects';
import { queryForLawyerModals } from './functions';
import SelectLawyerModal from './select-lawyer-modal';
import StaffLawyerModal from './staff-lawyer/staff-lawyer-modal';
import { LAWYER_TYPES, isNonCooperatingLawyerType } from 'js/utilities/lawyers';
import CoopLawyerModal from './coop-lawyer/coop-lawyer-modal';

const CaseLawyer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  //refs
  const getLawyerAbortControllerRef = useRef(null);
  const patchLawyerAbortControllerRef = useRef(null);

  const { caseInfo, setCaseInfo, caseOptions } = useContext(CaseContext);
  const { caseInformation = {}, caseLawyer = {} } = caseInfo;
  const { lawyerId, lawyerType } = caseLawyer;
  const { referralReasons = [] } = caseOptions;

  const { api = {} } = useContext(FetchContext);
  const { prompt } = useContext(PromptContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { currentUser = {} } = useContext(CurrentUserContext);
  const { officeOpened = '', caseStatus = '', cif = '' } = caseInformation;

  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseLawyer');

  const canEditCase = returnCanEditCase({
    officeOpened,
    currentUser,
    hasEditPermission,
    caseStatus,
    adminOnly: false,
    isNew: false,
    cif: cif,
  });

  //states
  const [active, setActive] = useState(false);
  const [saveActive, setSaveActive] = useState(false);
  const [lawyer, setLawyer] = useState({});
  const [selectLawyerMounted, setSelectLawyerMounted] = useState(false);

  const { params = {} } = useRouteMatch();
  const caseNumber = decodeURIComponent(params.caseNumber);
  const query = queryForLawyerModals(search);
  const { params: nextParams } = query;
  const { modalMounted = '', modalQuery: modalQueryString = '' } = nextParams;
  const noModalsOpen = modalMounted === '' && !selectLawyerMounted;
  const cache = useContext(DataContext);
  const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);

  // The case open date must be shimmed into the validation
  // data to support the first meeting validation rule:
  const validationData = {
    ...caseLawyer,
    caseOpenDate: caseInformation.openDate,
  };
  const { isValid, invalidFields } = validate(
    validationData,
    caseLawyerRequiredKeys
  );
  const touchAll = () => touch(caseLawyerRequiredKeys);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerAbortControllerRef,
        patchLawyerAbortControllerRef,
      ],
      setActive,
    }),
    []
  );

  useEffect(
    getLawyerEffect({
      t,
      api,
      lawyerId,
      lawyerType,
      setActive,
      setLawyer,
      setCaseInfo,
      getLawyerAbortControllerRef,
    }),
    [lawyerId]
  );

  useEffect(
    setDefaultModalQueryEffect({
      history,
      query,
    }),
    []
  );

  const patchEffect = patchCaseLawyerEffect({
    t,
    api,
    prompt,
    setLawyer,
    isValid,
    touchAll,
    caseInfo,
    caseNumber,
    setActive: setSaveActive,
    setCaseInfo,
    resetInteractionCount,
    cache,
    patchLawyerAbortControllerRef,
  });

  const saveCaseLawyer = saveCaseLawyerEffect({
    t,
    saveEffect: patchEffect,
    presentStyledBanner,
  });

  const setLawyerParams = setModalParamsEffect({
    history,
    query,
  });

  const resetLawyerParams = resetModalParamsEffect({
    history,
  });

  const buildQuery = buildNextLawyerQueryEffect({
    history,
    query,
    search,
  });

  const onClose = cancelModalEffect({
    setSelectLawyerMounted,
    resetLawyerParams,
  });

  const selectLawyer = selectLawyerEffect({
    setLawyer,
    setCaseInfo,
    setSelectLawyerMounted,
    setLawyerParams,
    incrementInteractionCount,
  });

  return (
    <CasePage
      className="case-lawyer"
      title={t('components.CaseLawyer.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            disabled={!canEditCase}
            onClick={() => setSelectLawyerMounted(true)}
          >
            {t('components.CaseLawyer.selectLawyer')}
          </button>
          <button
            className="button button-highlight page-action-button"
            disabled={!canEditCase}
            onClick={saveCaseLawyer}
          >
            {saveActive ? t('common.saving') : t('common.save')}
          </button>
        </Fragment>
      }
    >
      <NavigationSaveModal
        proceedAfter={async () => await saveCaseLawyer()}
        shouldBlockNavigation={() =>
          canEditCase && interactionCount > 0 && noModalsOpen
        }
      />
      <SelectLawyerModal
        mounted={selectLawyerMounted && modalMounted === ''}
        onClose={onClose}
        onSelect={setLawyerParams}
        selectLawyer={selectLawyer}
      />
      {modalMounted === LAWYER_TYPES.STAFF && (
        <StaffLawyerModal
          mounted={modalMounted === LAWYER_TYPES.STAFF}
          onSelect={selectLawyer}
          onBack={resetLawyerParams}
          onChange={buildQuery}
          onClose={onClose}
          modalQueryString={modalQueryString}
          officeOpened={caseInformation.officeOpened}
        />
      )}
      {modalMounted === LAWYER_TYPES.COOP && caseInformation && (
        <CoopLawyerModal
          mounted={modalMounted === LAWYER_TYPES.COOP}
          onBack={resetLawyerParams}
          onSelect={selectLawyer}
          onChange={buildQuery}
          CaseInformation={caseInformation}
          onClose={onClose}
          modalQueryString={modalQueryString}
        />
      )}
      {active && <ActivityIndicatorCard />}
      {!active && <LawyerSummary lawyer={lawyer} />}
      <form
        onSubmit={(e) => e.preventDefault()}
        onClick={incrementInteractionCount}
      >
        <CaseLawyerInformationForm
          formData={caseLawyer}
          formOptions={{ referralReasons }}
          onChange={updateCaseLawyerInfoEffect({
            setCaseInfo,
          })}
          isNonCoopLawyer={isNonCoopLawyer}
          invalidFields={invalidFields}
        />
        <LawyerCifForm
          formData={caseLawyer}
          onChange={updateCaseLawyerInfoEffect({
            setCaseInfo,
          })}
          isNonCoopLawyer={isNonCoopLawyer}
        />
      </form>
    </CasePage>
  );
};

const CaseLawyerWithContext = (props) => (
  <CaseProvider>
    <CaseLawyer {...props} />
  </CaseProvider>
);

const CaseLawyerWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={caseLawyerNamedPermissions}>
    <CaseLawyerWithContext {...props} />
  </NamedPermissionsProvider>
);

const ValidatedCaseLawyer = (props) => (
  <ValidationProvider rules={caseLawyerValidationRules}>
    <CaseLawyerWithNamedPermissions {...props} />
  </ValidationProvider>
);

const InteractionTrackingCaseLawyer = (props) => (
  <InteractionTrackingProvider>
    <ValidatedCaseLawyer {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingCaseLawyer;
