import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import FeeScheduleBreadcrumbs from './breadcrumbs';
import FeeScheduleCard from './fee-schedule-card';
import { Redirect } from 'react-router-dom';
import {
  onMountEffect,
  getFeeScheduleEffect,
  updateFeeScheduleEffect,
  setAddingFeeScheduleEffect,
  postFeeScheduleEffect,
  patchFeeScheduleEffect,
  deleteFeeScheduleEffect,
  archiveFeeScheduleEffect,
  completeArchiveEffect,
} from './effects';
import { baseFeeSchedule } from './functions';
import EmptyState from 'js/components/controls/empty-state';
import FeeScheduleTabs from './tabs';

const FeeScheduleActive = () => {
  const { t } = useTranslation();
  const { api } = useContext(FetchContext);

  const getAbortControllerRef = useRef(null);
  const postAbortControllerRef = useRef(null);
  const patchAbortControllerRef = useRef(null);
  const deleteAbortControllerRef = useRef(null);
  const archiveAbortControllerRef = useRef(null);
  const listElementRef = useRef();

  const [isActive, setActive] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isArchiving, setArchiving] = useState(false);
  const [feeSchedules, setFeeSchedules] = useState([]);
  const [editingFeeSchedule, setEditingFeeSchedule] = useState({});
  const [addingFeeSchedule, setAddingFeeSchedule] = useState({});
  const [redirectPath, setRedirect] = useState('');

  const isEditing = !isEmpty(editingFeeSchedule);
  const isAdding = !isEmpty(addingFeeSchedule);
  const isLocked = isEditing || isAdding;

  useEffect(
    onMountEffect({
      abortControllerRefs: [getAbortControllerRef],
      setActive,
    }),
    []
  );

  const getFeeSchedules = getFeeScheduleEffect({
    t,
    api,
    setActive,
    setFeeSchedules,
    getAbortControllerRef,
  });

  useEffect(getFeeSchedules, []);

  const completeArchive = completeArchiveEffect({
    setEditingFeeSchedule,
    getFeeSchedules,
  });

  return (
    <Page
      className="fee-schedule"
      title={t('components.FeeSchedulePage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <FeeScheduleBreadcrumbs />
          {redirectPath && <Redirect to={redirectPath} />}
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {isActive && <ActivityIndicatorCard />}

          {!isActive && (
            <Fragment>
              <div className="fee-schedule-heading">
                <div className="fee-schedule-heading-left">
                  <h1>{t('components.FeeSchedulePage.title')}</h1>
                </div>
                <div className="fee-schedule-heading-right">
                  <button
                    className="button button-highlight page-action-button"
                    onClick={setAddingFeeScheduleEffect({
                      setAddingFeeSchedule,
                      listElementRef,
                      baseFeeSchedule,
                    })}
                  >
                    {t('common.new')}
                  </button>
                </div>
              </div>
              <FeeScheduleTabs />

              {feeSchedules.length === 0 && (
                <EmptyState
                  title={t('components.FeeSchedulePage.emptyTitle')}
                />
              )}

              <ul
                ref={listElementRef}
                className="unstyled-list fee-schedule-list"
              >
                {feeSchedules.length > 0 &&
                  feeSchedules.map((feeSchedule) => {
                    const isEditingFeeSchedule =
                      editingFeeSchedule &&
                      editingFeeSchedule.id === feeSchedule.id;
                    return (
                      <FeeScheduleCard
                        feeSchedule={
                          isEditingFeeSchedule
                            ? editingFeeSchedule
                            : feeSchedule
                        }
                        key={feeSchedule.id}
                        expanded={isEditingFeeSchedule}
                        expandEnabled={!isLocked}
                        setEditingFeeSchedule={setEditingFeeSchedule}
                        isNew={false}
                        isSaving={isSaving}
                        isDeleting={isDeleting}
                        isArchiving={isArchiving}
                        onClickExpand={() => setEditingFeeSchedule(feeSchedule)}
                        onChange={updateFeeScheduleEffect({
                          changingFeeSchedule: editingFeeSchedule,
                          changeFeeScheduleFunc: setEditingFeeSchedule,
                        })}
                        onClickCancel={() => setEditingFeeSchedule({})}
                        onClickArchive={archiveFeeScheduleEffect({
                          t,
                          api,
                          setArchiving,
                          setFeeSchedules,
                          archiveAbortControllerRef,
                          onComplete: completeArchive,
                        })}
                        onClickSave={patchFeeScheduleEffect({
                          t,
                          api,
                          setSaving,
                          editingFeeSchedule,
                          setEditingFeeSchedule,
                          patchAbortControllerRef,
                          onComplete: getFeeSchedules,
                        })}
                        onClickDelete={deleteFeeScheduleEffect({
                          t,
                          api,
                          setDeleting,
                          deleteAbortControllerRef,
                          onComplete: getFeeSchedules,
                        })}
                      />
                    );
                  })}

                {isAdding && (
                  <FeeScheduleCard
                    feeSchedule={addingFeeSchedule}
                    expanded={true}
                    expandEnabled={true}
                    isNew={true}
                    isSaving={isSaving}
                    onChange={updateFeeScheduleEffect({
                      changingFeeSchedule: addingFeeSchedule,
                      changeFeeScheduleFunc: setAddingFeeSchedule,
                    })}
                    onClickCancel={() => setAddingFeeSchedule({})}
                    onClickSave={postFeeScheduleEffect({
                      t,
                      api,
                      setSaving,
                      addingFeeSchedule,
                      setAddingFeeSchedule,
                      postAbortControllerRef,
                      setRedirect,
                    })}
                  />
                )}
              </ul>
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default FeeScheduleActive;
