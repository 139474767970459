import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputLabel, InputHelp, DatePicker } from 'js/components/design-system';
import { resolveDatesForDateRange, saveDateRangeParam } from './date-functions';
import { onDateRangeChangeEffect, onDatePickerChangeEffect } from './effects';
import { storage } from './functions';

const ReportDateRangeForm = (props) => {
  const { t } = useTranslation();

  const {
    params = {},
    setParams,
    invalidFields,
    startDateTouched,
    endDateTouched,
  } = props;

  const { endDate = '', startDate = '', dateRange = '' } = params;

  const onDateRangeChange = onDateRangeChangeEffect({
    setObject: setParams,
    resolveDatesForDateRange,
    storage,
  });

  const onDatePickerChange = (name) =>
    onDatePickerChangeEffect({
      name,
      setObject: setParams,
      saveDateRangeParam,
      storage,
    });

  return (
    <div className="report-date-range-form">
      <p>{t('components.RunEditReportModal.dateRange')}</p>
      <div className="form-row ">
        <div className="form-column">
          <label>
            <input
              type="radio"
              name="dateRange"
              value="generateFirstQuarterly"
              checked={dateRange === 'generateFirstQuarterly'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.firstQuart')}</span>
          </label>

          <label>
            <input
              type="radio"
              name="dateRange"
              value="generateHalfYearly"
              checked={dateRange === 'generateHalfYearly'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.halfYear')}</span>
          </label>

          <label>
            <input
              type="radio"
              name="dateRange"
              value="generateThirdQuarterly"
              checked={dateRange === 'generateThirdQuarterly'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.thirdQuart')}</span>
          </label>
        </div>
        <div className="form-column">
          <label>
            <input
              type="radio"
              name="dateRange"
              value="generateMonthly"
              checked={dateRange === 'generateMonthly'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.month')}</span>
          </label>

          <label>
            <input
              type="radio"
              name="dateRange"
              value="generateYearly"
              checked={dateRange === 'generateYearly'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.yearly')}</span>
          </label>

          <label>
            <input
              type="radio"
              name="dateRange"
              value="custom"
              checked={dateRange === 'custom'}
              onChange={onDateRangeChange}
            />
            <span>{t('components.RunEditReportModal.custom')}</span>
          </label>
        </div>
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.RunEditReportModal.startDateValidation'
              )}
              invalid={startDateTouched && invalidFields.includes('startDate')}
            >
              <InputLabel
                content={t('components.RunEditReportModal.startDate')}
              >
                <DatePicker
                  name="startDate"
                  selected={startDate}
                  readOnly={dateRange !== 'custom'}
                  onChange={onDatePickerChange('startDate')}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.RunEditReportModal.endDateValidation'
              )}
              invalid={endDateTouched && invalidFields.includes('endDate')}
            >
              <InputLabel content={t('components.RunEditReportModal.endDate')}>
                <DatePicker
                  name="endDate"
                  selected={endDate}
                  readOnly={dateRange !== 'custom'}
                  onChange={onDatePickerChange('endDate')}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
      </div>
    </div>
  );
};

ReportDateRangeForm.propTypes = {
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  invalidFields: PropTypes.array,
  startDateTouched: PropTypes.bool,
  endDateTouched: PropTypes.bool,
};

export default ReportDateRangeForm;
