import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { classNameForDatabaseStatus } from './functions';

const DatabaseTable = (props) => {
  const { t } = useTranslation();

  const { database = {}, reportingDatabase = {} } = props;

  const { name, location, status } = database;

  const {
    name: reportingName,
    location: reportingLocation,
    status: reportingStatus,
  } = reportingDatabase;

  return (
    <table className="database-table">
      <thead>
        <tr>
          <th></th>
          <th>{t('components.DatabaseBackups.table.database')}</th>
          <th>{t('components.DatabaseBackups.table.reportingDatabase')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>{t('components.DatabaseBackups.table.name')}</strong>
          </td>
          <td>{name}</td>
          <td>{reportingName}</td>
        </tr>
        <tr>
          <td>
            <strong>{t('components.DatabaseBackups.table.location')}</strong>
          </td>
          <td>{location}</td>
          <td>{reportingLocation}</td>
        </tr>
        <tr>
          <td>
            <strong>{t('components.DatabaseBackups.table.status')}</strong>
          </td>
          <td>
            <span className={classNameForDatabaseStatus(status)}>{status}</span>
          </td>
          <td>
            <span className={classNameForDatabaseStatus(reportingStatus)}>
              {reportingStatus}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

DatabaseTable.propTypes = {
  database: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
  }),
  reportingDatabase: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default DatabaseTable;
