import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  clientStatusOptions,
  prefixOptions,
} from 'js/hardcoded-data/form-option-data';
import {
  StyledSelect,
  DatePicker,
  InputLabel,
  InputHelp,
  Card,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import PhoneInput from 'js/components/controls/phone-input';
import { ValidationContext } from 'js/components/validation';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
} from './effects';

const Spacer = () => <div />;

const PersonalInformation = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editClientInformation');

  const {
    formData = {},
    onChange,
    isPrimaryClient,
    isBirthDateRequired,
  } = props;
  const {
    clientId = '',
    clientStatus = '',
    dateBirth = '',
    dateRetirement = null,
    emailAddress = '',
    firstName = '',
    lastName = '',
    phoneNumber = '',
    prefix = '',
  } = formData;

  const statusOptions = clientStatusOptions(t, isPrimaryClient);
  const prefOptions = prefixOptions(t);

  const commonRequiredFields = [
    'clientId',
    'firstName',
    'lastName',
    'phoneNumber',
    'emailAddress',
    'clientStatus',
  ];

  const requiredFields = isBirthDateRequired
    ? [...commonRequiredFields, 'dateBirth']
    : commonRequiredFields;

  const { invalidFields = [] } = validate(formData, requiredFields);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  return (
    <Card className="client-personal-information-form">
      <h2>{t('components.ClientInformation.Personal.title')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.prefixValidation'
          )}
          invalid={isTouched('prefix') && invalidFields.includes('prefix')}
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.prefix')}
          >
            <StyledSelect
              name="prefix"
              value={prefix}
              {...inputEventHandlers}
              disabled={!hasEditPermission}
            >
              <option value={''}>
                {t('components.ClientInformation.Personal.prefixPlaceholder')}
              </option>
              {prefOptions.map((item, key) => {
                return (
                  <option value={item.value} key={key}>
                    {item.title}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.firstNameValidation'
          )}
          invalid={
            isTouched('firstName') && invalidFields.includes('firstName')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.firstName')}
          >
            <input
              type="text"
              name="firstName"
              autoComplete="firstName"
              value={firstName}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.lastNameValidation'
          )}
          invalid={isTouched('lastName') && invalidFields.includes('lastName')}
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.lastName')}
          >
            <input
              type="text"
              name="lastName"
              autoComplete="lastName"
              value={lastName}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.dateBirthValidation'
          )}
          invalid={
            isTouched('dateBirth') && invalidFields.includes('dateBirth')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.dateBirth')}
          >
            <input
              type="text"
              name="dateBirth"
              autoComplete="off"
              value={dateBirth}
              readOnly={!hasEditPermission}
              placeholder={t(
                'components.ClientInformation.Personal.dateBirthPlaceholder'
              )}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        {isPrimaryClient && (
          <InputLabel
            content={t('components.ClientInformation.Personal.dateRetirement')}
          >
            <DatePicker
              name="dateRetirement"
              selected={dateRetirement}
              disabled={!hasEditPermission}
              {...datePickerEventHandlers('dateRetirement')}
            />
          </InputLabel>
        )}

        <Spacer />
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.phoneNumberValidation'
          )}
          invalid={
            isTouched('phoneNumber') && invalidFields.includes('phoneNumber')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.phoneNumber')}
          >
            <PhoneInput
              name="phoneNumber"
              value={phoneNumber}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.emailAddressValidation'
          )}
          invalid={
            isTouched('emailAddress') && invalidFields.includes('emailAddress')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.emailAddress')}
          >
            <input
              type="email"
              name="emailAddress"
              autoComplete="emailAddress"
              value={emailAddress}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputLabel
          content={t('components.ClientInformation.Personal.clientId')}
        >
          <input type="text" name="clientId" value={clientId} readOnly={true} />
        </InputLabel>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Personal.clientStatusValidation'
          )}
          invalid={
            isTouched('clientStatus') && invalidFields.includes('clientStatus')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Personal.clientStatus')}
          >
            <StyledSelect
              name="clientStatus"
              value={clientStatus}
              disabled={!hasEditPermission}
              {...inputEventHandlers}
            >
              <option value={''} key={''} disabled>
                {t(
                  'components.ClientInformation.Personal.clientStatusPlaceholder'
                )}
              </option>
              {statusOptions.map((item, key) => {
                return (
                  <option
                    value={item.value}
                    key={key}
                  >{`${item.value} - ${item.title}`}</option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

PersonalInformation.defaultProps = {
  isPrimaryClient: true,
  isBirthDateRequired: false,
};

PersonalInformation.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isPrimaryClient: PropTypes.bool,
  isBirthDateRequired: PropTypes.bool,
};

export default PersonalInformation;
