import { zonedTimeToUtc } from 'date-fns-tz';
import { isEmpty } from 'js/utilities/validation';

export const REPORTS_FOLDER_NAME_MAX_LENGTH = 16;

export const reportsFolderDetailValidationRules = {
  folderName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= REPORTS_FOLDER_NAME_MAX_LENGTH;
  },
};

export const reportsFolderDetailRequiredFields = Object.keys(
  reportsFolderDetailValidationRules
);

export const replaceReport = (reports = [], report = {}) => {
  const index = reports.findIndex((r) => r.id === report.id);
  if (index >= 0) {
    const nextReports = [...reports];
    nextReports[index] = report;
    return nextReports;
  } else {
    return reports;
  }
};

export const removeReport = (reports = [], reportId) => {
  return reports.filter((r) => r.id !== reportId);
};

export const convertTimezoneForReportRunParams = (params = {}) => {
  // Important:
  // Pre-convert dates to UTC to negate the TZ offset applied during serialization.
  // This is necessary because the dates will be serialized as UTC but the reporting
  // server will not convert them to local time, and will manipulate them in sketchy
  // ways. It's important that the reporting server recieves the dates without offset.
  // E.g. 2021-11-24:23:59:59 EST is receieved as 2021-11-24:23:59:59 UTC, not as
  // 2021-11-25:04:59:59 UTC. The timezone offset is disregarded.
  const localTimezoneOffset = new Date().getTimezoneOffset();
  return {
    ...params,
    startDate: zonedTimeToUtc(params.startDate, localTimezoneOffset),
    endDate: zonedTimeToUtc(params.endDate, localTimezoneOffset),
  };
};
