import { searchStringFromParams } from 'js/utilities/params';
import { updateReprintChequeCount } from './functions';

export const reprintChequeEffect = (options = {}) => {
  const { setReprintChequeCount } = options;
  return ({ chequeCount }) => {
    setReprintChequeCount(chequeCount);
  };
};

export const resetReprintChequeOnModalUnmountEffect = (options = {}) => {
  const {
    hasReprintChequeCount,
    setReprintChequeCount,
    setLastRunModalMounted,
    setTimestamp,
  } = options;
  return (mounted) => {
    if (hasReprintChequeCount) {
      if (!mounted) {
        setReprintChequeCount(undefined);
      }
    } else {
      setLastRunModalMounted(mounted);
    }

    if (!mounted) {
      setTimestamp(Date.now());
    }
  };
};

export const setReprintChequeCountEffect = (options = {}) => {
  const { query, history } = options;
  return (reprintChequeCount) => {
    const nextParams = updateReprintChequeCount(
      reprintChequeCount,
      query.params
    );
    history.push(searchStringFromParams(nextParams));
  };
};

export const setHasChequesEffect = (options = {}) => {
  const { setHasCheques } = options;
  return (cheques) => setHasCheques(cheques.length > 0);
};
