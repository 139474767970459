import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'js/components/modal';
import {
  onChangeTextEffect,
  onClickDiscardEffect,
  onSubmitEffect,
  setEditingNoteEffect,
  onSubmitEditEffect,
} from './effects';

const NoteModal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    onSubmit,
    onComplete,
    onClickCancel,
    editingNote = {},
    onEdit,
    mounted,
    ...modalProps
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEditing = editingNote && Object.keys(editingNote).length > 0;
  const [text, setText] = useState('');
  const trimmedText = text.trim();
  const submit = onSubmitEffect({
    text: trimmedText,
    setText,
    setIsSubmitting,
    onSubmit,
    onComplete,
  });
  const submitEdit = onSubmitEditEffect({
    text: trimmedText,
    editingNote,
    setText,
    setIsSubmitting,
    onEdit,
    onComplete,
  });
  const cancel = onClickDiscardEffect({ setText, onClickCancel });

  useEffect(
    setEditingNoteEffect({
      editingNote,
      setText,
    }),
    [mounted]
  );

  const submitButtonTitle = isEditing ? t('common.save') : t('common.add');
  const submitFunc = isEditing ? submitEdit : submit;

  return (
    <Modal
      className={classnames('note-modal', className)}
      titleText={t('components.NoteModal.titleText')}
      onClickCancel={cancel}
      initialFocus="textarea[name='note']"
      underlayClickExits={false}
      verticallyCenter
      mounted={mounted}
      allowCancel={false}
      {...modalProps}
    >
      <h2>{t('components.NoteModal.heading')}</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <textarea
          name="note"
          value={text}
          disabled={isSubmitting}
          onChange={onChangeTextEffect({ setText })}
        />
        <div className="button-bar">
          <button
            className="button-link-appearance"
            type="button"
            disabled={isSubmitting}
            onClick={cancel}
            allowCancel={false}
          >
            {t('components.NoteModal.discardButtonText')}
          </button>
          <button
            className="button button-highlight"
            disabled={isSubmitting || trimmedText.length === 0}
            onClick={submitFunc}
          >
            {isSubmitting ? t('common.saving') : submitButtonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
};

NoteModal.propTypes = {
  ...Modal.propTypes,
  onSubmit: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default NoteModal;
