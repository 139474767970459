import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setSaving } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setSaving(false);
    };
  };
};

export const onChangeEditingEffect = (options = {}) => {
  const { isEditing, previousEditingRef, setCityForEditing, city } = options;
  return () => {
    const { current: previousEditing } = previousEditingRef;
    if (isEditing !== previousEditing) {
      if (isEditing) {
        setCityForEditing(city);
      } else {
        setCityForEditing({});
      }
    }
  };
};

export const postCityEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    provinceId,
    city,
    setSaving,
    saveCityAbortControllerRef,
    onSave,
  } = options;
  return async () => {
    const { isValid } = validateAll(city);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(saveCityAbortControllerRef);
    const { signal } = saveCityAbortControllerRef.current;

    try {
      await api.postJson(
        `/Regions/provinces/${provinceId}/cities`,
        { signal, body: city },
        {
          success: {
            context: {
              message: t('components.ProvinceDetail.postCityRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ProvinceDetail.postCityRequestError'),
            },
          },
        }
      );

      setSaving(false);
      onSave();
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const patchCityEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    provinceId,
    city,
    setSaving,
    saveCityAbortControllerRef,
    onSave,
  } = options;
  return async () => {
    const { isValid } = validateAll(city);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(saveCityAbortControllerRef);
    const { signal } = saveCityAbortControllerRef.current;

    try {
      await api.patchJson(
        `/Regions/provinces/${provinceId}/cities/${city.cityCode}`,
        { signal, body: city },
        {
          success: {
            context: {
              message: t('components.ProvinceDetail.patchCityRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ProvinceDetail.patchCityRequestError'),
            },
          },
        }
      );

      setSaving(false);
      onSave();
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const onSubmitCityFormEffect = (options = {}) => {
  const { save } = options;
  return async (e) => {
    e.preventDefault();
    await save();
  };
};

export const saveCityEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const onSaveCityEffect = (options = {}) => {
  const { setCityForEditing, untouchAll, onSave } = options;
  return () => {
    setCityForEditing({});
    untouchAll();

    if (typeof onSave === 'function') {
      onSave();
    }
  };
};

export const onChangeCityInputEffect = (options = {}) => {
  const { setCityForEditing } = options;
  return (e) => {
    const { name, value } = e.target;
    setCityForEditing((city) => ({ ...city, [name]: value }));
  };
};

export const onBlurCityInputEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const updateCityActiveEffect = (options = {}) => {
  const { isCityActive, setCityForEditing, setConfirmDeactivate } = options;
  return () => {
    setCityForEditing((city) => ({ ...city, isActive: isCityActive }));
    setConfirmDeactivate(false);
  };
};

export const setConfirmDeactivateEffect = (options = {}) => {
  const { confirm, setConfirmDeactivate } = options;
  return () => setConfirmDeactivate(confirm);
};

export const onClickEditEffect = (options = {}) => {
  const { cityCode, onClickEdit } = options;
  return () => {
    if (typeof onClickEdit === 'function') {
      onClickEdit(cityCode);
    }
  };
};
