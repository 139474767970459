import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePopper } from 'react-popper';
import {
  onMountEffect,
  onClickReferenceElementEffect,
  onKeyUpReferenceElementEffect,
} from './effects';

const PopoverController = (props = {}) => {
  const { popperOptions = {}, children } = props;
  const { modifiers = [] } = popperOptions;
  const [isVisible, setVisibility] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [contentElement, setContentElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const elementsExcludedFromDocumentClick = [referenceElement, contentElement];

  useEffect(
    onMountEffect({ elementsExcludedFromDocumentClick, setVisibility }),
    elementsExcludedFromDocumentClick
  );

  const { styles = {}, attributes = {}, ...popper } = usePopper(
    referenceElement,
    contentElement,
    {
      ...popperOptions,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        ...modifiers,
      ],
    }
  );

  if (typeof children !== 'function') {
    // eslint-disable-next-line no-console
    console.warn('PopoverController requires a function as child.');
    return null;
  }

  return children({
    referenceAttributes: {
      ref: setReferenceElement,
      onClick: onClickReferenceElementEffect({
        isVisible,
        setVisibility,
        popper,
      }),
      onKeyUp: onKeyUpReferenceElementEffect({
        isVisible,
        setVisibility,
        popper,
      }),
    },
    contentAttributes: {
      ref: setContentElement,
      className: classnames('popover-content', {
        'popover-content-visible': isVisible,
      }),
      style: styles.popper,
      ...attributes.popper,
    },
    arrowAttributes: {
      ref: setArrowElement,
      className: classnames('popover-arrow', {
        'popover-arrow-visible': isVisible,
      }),
      style: styles.arrow,
      ...attributes.arrow,
    },
    isVisible,
    setVisibility,
    popper,
  });
};

PopoverController.propTypes = {
  popperOptions: PropTypes.object,
};

export default PopoverController;
