import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanProvince } from 'js/utilities/regions';
import { scheduleScrollToLastCardInList } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoading, setSaving } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoading(false);
      setSaving(false);
    };
  };
};

export const getProvinceEffect = (options = {}) => {
  const {
    t,
    api,
    isNew,
    provinceId,
    setLoading,
    setProvince,
    getProvinceAbortControllerRef,
  } = options;
  return async () => {
    if (isNew) {
      return;
    }

    setLoading(true);

    rotateAbortControllerRef(getProvinceAbortControllerRef);
    const { signal } = getProvinceAbortControllerRef.current;

    try {
      const { json } = await api.getJson(
        `/Regions/provinces/${provinceId}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ProvinceDetail.getProvinceRequestError'),
            },
          },
        }
      );

      const province = cleanProvince(json);

      setProvince(province);
      setLoading(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false);
      }
    }
  };
};

export const postProvinceEffect = (options = {}) => {
  const {
    t,
    api,
    history,
    validateAll,
    touchAll,
    province,
    setSaving,
    saveProvinceAbortControllerRef,
    resetInteractionCount,
  } = options;
  return async () => {
    const { isValid } = validateAll(province);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(saveProvinceAbortControllerRef);
    const { signal } = saveProvinceAbortControllerRef.current;

    try {
      const { json } = await api.postJson(
        '/Regions/provinces',
        { signal, body: province },
        {
          success: {
            context: {
              message: t(
                'components.ProvinceDetail.postProvinceRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t('components.ProvinceDetail.postProvinceRequestError'),
            },
          },
        }
      );

      setSaving(false);
      resetInteractionCount();

      const { id: provinceId } = cleanProvince(json);

      // Redirect to the province detail on the next frame,
      // after the interaction count has been reset.
      setTimeout(() => {
        const path = routePathReplacingParams(routePaths.province, {
          provinceId,
        });
        history.replace(path);
      });
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const patchProvinceEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    province,
    setSaving,
    saveProvinceAbortControllerRef,
    resetInteractionCount,
  } = options;
  return async () => {
    const { isValid } = validateAll(province);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(saveProvinceAbortControllerRef);
    const { signal } = saveProvinceAbortControllerRef.current;

    try {
      await api.patchJson(
        `/Regions/provinces/${province.id}`,
        { signal, body: province },
        {
          success: {
            context: {
              message: t(
                'components.ProvinceDetail.patchProvinceRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t('components.ProvinceDetail.patchProvinceRequestError'),
            },
          },
        }
      );

      setSaving(false);
      resetInteractionCount();
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const saveProvinceEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const onChangeProvinceInputEffect = (options = {}) => {
  const { setProvince } = options;
  return (e) => {
    const { name, value } = e.target;
    setProvince((province) => ({ ...province, [name]: value }));
  };
};

export const onBlurProvinceInputEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const updateProvinceActiveEffect = (options = {}) => {
  const { isProvinceActive, setProvince, setConfirmDeactivate } = options;
  return () => {
    setProvince((province) => ({ ...province, isActive: isProvinceActive }));
    setConfirmDeactivate(false);
  };
};

export const setConfirmDeactivateEffect = (options = {}) => {
  const { confirm, setConfirmDeactivate } = options;
  return () => setConfirmDeactivate(confirm);
};

export const setAddingCityEffect = (options = {}) => {
  const { setAddingCity, listContainerRef } = options;
  return () => {
    const { current: listContainer } = listContainerRef;
    if (listContainer) {
      scheduleScrollToLastCardInList(listContainer);
      setAddingCity(true);
    }
  };
};

export const onEditCityEffect = (options = {}) => {
  const { setEditingCityCode } = options;
  return (cityCode) => setEditingCityCode(cityCode);
};

export const onCancelCityEffect = (options = {}) => {
  const { setAddingCity, setEditingCityCode } = options;
  return () => {
    setAddingCity(false);
    setEditingCityCode('');
  };
};

export const onSaveCityEffect = (options = {}) => {
  const { setAddingCity, setEditingCityCode, getProvince } = options;
  return () => {
    setAddingCity(false);
    setEditingCityCode('');
    getProvince();
  };
};
