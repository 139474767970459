import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'js/components/design-system';
import { feeScheduleTabItems } from './functions';
import PropTypes from 'prop-types';

const FeeScheduleTabs = (props) => {
  const { path } = useRouteMatch();
  const { disabled = false } = props;
  const { t } = useTranslation();
  return (
    <Tabs
      className="fee-schedule-tabs"
      items={feeScheduleTabItems(t, path)}
      currentPath={path}
      disabled={disabled}
    />
  );
};

FeeScheduleTabs.propTypes = {
  disabled: PropTypes.bool,
};

export default FeeScheduleTabs;
