import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GroupPermissionsGate } from 'js/components/group-permissions';
import ProtectedRoute from './protected-route';
import { routePaths } from './route-paths';

const GroupPermissionsRoute = (props) => {
  const {
    permittedGroups,
    deniedRedirectTo,
    unauthenticatedRedirectTo,
    ...routeProps
  } = props;
  return (
    <ProtectedRoute
      render={() => (
        <GroupPermissionsGate permittedGroups={permittedGroups}>
          {({ hasLoadedGroups, hasMatchingGroup }) => {
            if (hasLoadedGroups) {
              if (hasMatchingGroup) {
                return <Route {...routeProps} />;
              } else {
                return <Redirect to={deniedRedirectTo} />;
              }
            } else {
              return (
                <p className="route-loading-placeholder">
                  Checking permissions...
                </p>
              );
            }
          }}
        </GroupPermissionsGate>
      )}
      redirectTo={unauthenticatedRedirectTo}
    />
  );
};

GroupPermissionsRoute.defaultProps = {
  deniedRedirectTo: routePaths.error403,
};

GroupPermissionsRoute.propTypes = {
  ...Route.propTypes,
  permittedGroups: PropTypes.arrayOf(PropTypes.number),
  deniedRedirectTo: PropTypes.string,
  unauthenticatedRedirectTo: PropTypes.string,
};

export default GroupPermissionsRoute;
