import { cleanStringField, formatPhoneNumber } from 'js/utilities/strings';
import {
  parseDateFromISOString,
  formatDateFromISOString,
} from 'js/utilities/dates';
import { validateFileSizes } from 'js/utilities/files';
import { roundToFixedPointString } from 'js/utilities/numbers';
import {
  caseNumberPattern,
  namePattern,
  validateEmail,
  validateYear,
  // validateDate,
  validatePostalCode,
  validatePhoneNumber,
  isEmpty,
} from 'js/utilities/validation';

export const LAWYER_TYPES = {
  STAFF: 'staff',
  COOP: 'cooperating',
  NON_COOP: 'non-cooperating',
};

export const LAWYER_TYPE_ENUM = {
  STAFF: 1, //staff lawyer
  COOP: 2, //cooperating lawyer
  CLIENT_COOP: 3, //client selected cooperating lawyer
  NON_COOP: 4, // non cooperating lawyer
  NON_COOP_NOTARY: 5, //non cooperating notary
  COOP_NOTARY: 6, //cooperating notary (CN)
  CLIENT_COOP_NOTARY: 7, // client selected cooperating notary
  OUTSIDE_CANADA: 8, //outside canada lawyer
};

export const COOP_LAWYER_SUB_TYPES = (t) => [
  {
    typeId: LAWYER_TYPE_ENUM.COOP,
    label: t('common.CoopLawyerTypes.coop'),
  },
  {
    typeId: LAWYER_TYPE_ENUM.CLIENT_COOP,
    label: t('common.CoopLawyerTypes.clientCoop'),
  },
  {
    typeId: LAWYER_TYPE_ENUM.COOP_NOTARY,
    label: t('common.CoopLawyerTypes.coopNotary'),
  },
  {
    typeId: LAWYER_TYPE_ENUM.CLIENT_COOP_NOTARY,
    label: t('common.CoopLawyerTypes.clientCoopNotary'),
  },
];

export const NONCOOP_LAWYER_SUB_TYPES = (t) => [
  {
    typeId: LAWYER_TYPE_ENUM.NON_COOP,
    label: t('common.NonCoopLawyerTypes.nonCoop'),
  },
  {
    typeId: LAWYER_TYPE_ENUM.NON_COOP_NOTARY,
    label: t('common.NonCoopLawyerTypes.nonCoopNotary'),
  },
  {
    typeId: LAWYER_TYPE_ENUM.OUTSIDE_CANADA,
    label: t('common.NonCoopLawyerTypes.outsideCanadaNotary'),
  },
];

export const isStaffLawyerType = (lawyerType = '') => {
  return parseInt(lawyerType) === LAWYER_TYPE_ENUM.STAFF;
};

export const isStrictCoopLawyerType = (lawyerType = '') => {
  return parseInt(lawyerType) === LAWYER_TYPE_ENUM.COOP;
};

export const isStrictNonCoopLawyerType = (lawyerType = '') => {
  return parseInt(lawyerType) === LAWYER_TYPE_ENUM.NON_COOP;
};

export const isCooperatingLawyerType = (lawyerType = '') => {
  switch (parseInt(lawyerType)) {
    case 2:
    case 3:
    case 6:
    case 7:
      return true;
    default:
      return false;
  }
};

export const isNonCooperatingLawyerType = (lawyerType = '') => {
  switch (parseInt(lawyerType)) {
    case 4:
    case 5:
    case 8:
      return true;
    default:
      return false;
  }
};

export const returnlawyerTypeFromId = (id = '') => {
  const characters = id.length;
  switch (characters) {
    case 4:
      return LAWYER_TYPE_ENUM.STAFF;
    case 6:
      return LAWYER_TYPE_ENUM.COOP;
    case 0:
      return LAWYER_TYPE_ENUM.NON_COOP;
    default:
      return;
  }
};

export const lawyerApiKey = (lawyerId, lawyerApproxType) => {
  if (lawyerApproxType === LAWYER_TYPE_ENUM.STAFF) {
    return `/Lawyer/Staff/${lawyerId}`;
  } else {
    return `/Lawyer/${lawyerId}`;
  }
};

export const cleanLawyer = (lawyer = {}) => {
  const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;
  return {
    lawyerInfo: cleanLawyerInfo(lawyerInfo),
    lawyerFirm: cleanLawyerFirm(lawyerFirm),
  };
};

export const cleanLawyerInfo = (lawyerInfo = {}) => {
  const {
    lawyerId,
    staffId,
    prefix,
    firstName,
    lastName,
    firmName,
    address,
    address2,
    city,
    province,
    postalCode,
    fax,
    phoneNumber,
    email,
    dateOpened,
    calledToBar,
    lawyerNotes,
    customaryFee,
    lawyerInactive, // Coop lawyers
    isActive, // Staff lawyers
  } = lawyerInfo;

  return {
    ...lawyerInfo,
    lawyerId: cleanStringField(lawyerId),
    staffId: cleanStringField(staffId),
    prefix: cleanStringField(prefix),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    firmName: cleanStringField(firmName),
    address: cleanStringField(address),
    address2: cleanStringField(address2),
    city: cleanStringField(city),
    province: cleanStringField(province),
    postalCode: cleanStringField(postalCode),
    fax: cleanStringField(fax),
    phoneNumber: formatPhoneNumber(cleanStringField(phoneNumber)),
    email: cleanStringField(email),
    dateOpened: parseDateFromISOString(dateOpened),
    calledToBar: cleanStringField(calledToBar),
    lawyerNotes: cleanStringField(lawyerNotes),
    customaryFee: roundToFixedPointString(customaryFee, 2),
    lawyerInactive: lawyerInactive === true || isActive === false,
  };
};

export const cleanLawyerFirm = (lawyerFirm = {}) => {
  const {
    firmName,
    primaryPhone,
    secondaryPhone,
    fax,
    email,
    address,
    address2,
    city,
    province,
    postalCode,
    firmId,
  } = lawyerFirm;
  return {
    firmName: cleanStringField(firmName),
    primaryPhone: cleanStringField(primaryPhone),
    secondaryPhone: cleanStringField(secondaryPhone),
    fax: cleanStringField(fax),
    email: cleanStringField(email),
    address: cleanStringField(address),
    address2: cleanStringField(address2),
    city: cleanStringField(city),
    province: cleanStringField(province),
    postalCode: cleanStringField(postalCode),
    firmId: cleanStringField(firmId),
  };
};

export const cleanLawyerFeedbackRecord = (record = {}) => {
  const {
    feedbackId = 0,
    cif,
    complainDate,
    dateOpened,
    dateClosed,
    negativeReason,
    negativeReasonDesc,
    author,
    notes,
    resolvedBy,
    resolvedByDesc,
    resolvedHow,
    resolvedHowDesc,
    sourceOfComment,
    sourceOfCommentDesc,
    typeOFComment,
    client = {},
    legalProblemCode: legalProblem = {},
    feedBackDocuments = [],
  } = record;

  const {
    id: clientId = '',
    clientCode = '',
    prefix = '',
    firstName = '',
    lastName = '',
  } = client;
  const { code: legalProblemCode = '' } = legalProblem;

  const metadata = {
    prefix: cleanStringField(prefix),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    cif: cleanStringField(cif),
    legalProblemCode: cleanStringField(legalProblemCode),
    dateOpened: parseDateFromISOString(dateOpened),
    dateClosed: parseDateFromISOString(dateClosed),
  };

  const parsedDate = parseDateFromISOString(complainDate);
  const timestamp = parsedDate ? parsedDate.getTime() : 0;

  return {
    metadata,
    timestamp,
    feedbackId,
    cif: cleanStringField(cif),
    clientId: cleanStringField(clientId),
    clientCode: cleanStringField(clientCode),
    complainDate: formatDateFromISOString(complainDate),
    dateOpened: formatDateFromISOString(dateOpened),
    dateClosed: formatDateFromISOString(dateClosed),
    negativeReason: cleanStringField(negativeReason),
    negativeReasonDesc: cleanStringField(negativeReasonDesc),
    author: cleanStringField(author),
    notes: cleanStringField(notes),
    resolvedBy: cleanStringField(resolvedBy),
    resolvedByDesc: cleanStringField(resolvedByDesc),
    resolvedHow: cleanStringField(resolvedHow),
    resolvedHowDesc: cleanStringField(resolvedHowDesc),
    sourceOfComment: cleanStringField(sourceOfComment),
    sourceOfCommentDesc: cleanStringField(sourceOfCommentDesc),
    typeOfComment: typeOFComment === true,
    feedBackDocuments: cleanLawyerFeedbackDocuments(feedBackDocuments),
  };
};

export const cleanLawyerFeedback = (records = []) =>
  records.map(cleanLawyerFeedbackRecord);

export const cleanLawyerFeedbackDocument = (doc = {}) => {
  const { docId, docName, docType, dateUploaded } = doc;

  return {
    docId: cleanStringField(docId),
    docName: cleanStringField(docName),
    docType: cleanStringField(docType),
    dateUploaded: formatDateFromISOString(dateUploaded),
  };
};

export const cleanLawyerFeedbackDocuments = (docs = []) =>
  docs.map(cleanLawyerFeedbackDocument);

export const isNewLawyerPath = (path) => {
  return path.split('/').pop() === 'new';
};

export const LAWYER_FIRM_NAME_MAX_CHARS = 60;

export const getLawyerInformationValidationRules = (
  isNew = false,
  isStaffLawyer = false
) => {
  return {
    firstName: namePattern,
    lastName: namePattern,
    firmName: (data, key) => {
      // Valid if empty or <= the character limit:
      const value = data[key];
      if (!isEmpty(value)) {
        return value.length <= LAWYER_FIRM_NAME_MAX_CHARS;
      } else {
        return true;
      }
    },
    phoneNumber: (data, key) => validatePhoneNumber(data[key], !isStaffLawyer),
    fax: (data, key) => validatePhoneNumber(data[key]),
    email: (data, key) => validateEmail(data[key]),
    calledToBar: (data, key) => validateYear(data[key], isNew),
    language: (data) =>
      data.langEnglish || data.langFrench || data.langOther || isStaffLawyer,
    address: (data, key) => {
      const value = data[key];
      const maxLength = 30;
      if (data.addressSameAsFirm === true || isStaffLawyer) {
        return isEmpty(value) || value.length <= maxLength;
      } else {
        return !isEmpty(value) && value.length <= maxLength;
      }
    },
    address2: (data, key) => {
      const value = data[key];
      return isEmpty(value) || value.length <= 30;
    },
    city: (data, key) => {
      const value = data[key];
      const maxLength = 20;
      if (data.addressSameAsFirm === true || isStaffLawyer) {
        return isEmpty(value) || value.length <= maxLength;
      } else {
        return !isEmpty(value) && value.length <= maxLength;
      }
    },
    postalCode: (data, key) =>
      validatePostalCode(data[key]) ||
      data.addressSameAsFirm === true ||
      isStaffLawyer,
    province: (data, key) =>
      !isEmpty(data[key]) || data.addressSameAsFirm === true || isStaffLawyer,
    provId: (data, key) => !isEmpty(data[key]) || isStaffLawyer,
    cityId: (data, key) => !isEmpty(data[key]) || isStaffLawyer,
    customaryFee: (data, key) => {
      const value = parseFloat(data[key]);
      const isPositiveNumber = !isNaN(value) && value > 0;
      return isPositiveNumber || isStaffLawyer;
    },
  };
};

export const lawyerAddressRequiredFields = [
  'address',
  'city',
  'postalCode',
  'province',
];

export const getLawyerInformationFormRequiredFields = (
  isNew = false,
  isStaffLawyer = false
) => {
  const commonRequiredFields = [
    'firstName',
    'lastName',
    'phoneNumber',
    'fax',
    'email',
  ];
  const nonStaffLawyerRequiredFields = [
    'language',
    'provId',
    'cityId',
    'customaryFee',
  ];
  const newLawyerRequiredFields = ['calledToBar'];

  if (isNew) {
    return [
      ...commonRequiredFields,
      ...nonStaffLawyerRequiredFields,
      ...newLawyerRequiredFields,
    ];
  } else if (isStaffLawyer) {
    return commonRequiredFields;
  } else {
    //Coop lawyer
    return [...commonRequiredFields, ...nonStaffLawyerRequiredFields];
  }
};

export const getLawyerInformationRequiredFields = (isNew = false) => {
  return [
    ...getLawyerInformationFormRequiredFields(isNew),
    ...lawyerAddressRequiredFields,
  ];
};

export const lawyerFeedbackValidationRules = {
  cif: caseNumberPattern,
  sourceOfComment: (data, key) => !isEmpty(data[key]),
  typeOfComment: (data, key) => !isEmpty(data[key]),
  negativeReason: (data, key) =>
    !isEmpty(data[key]) ||
    data.typeOfComment === 'true' ||
    data.typeOfComment === true,
  //date can be a string or date object, leaving this here in case that changes
  // dateClosed: (data, key) => validateDate(data[key], false),
  feedBackDocuments: (data, key) => validateFileSizes(data[key]),
};

export const lawyerFeedbackRequiredFields = Object.keys(
  lawyerFeedbackValidationRules
);

export const newLawyerData = () => {
  //defaulting barrister to true when there is no lawfirm
  return {
    lawyerInfo: { ...cleanLawyerInfo({}), barrister: true },
    lawyerFirm: cleanLawyerFirm({}),
  };
};
