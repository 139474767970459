import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanCaseLog } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingLogData } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingLogData(false);
    };
  };
};

export const getCaseLogEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setLogData,
    setLoadingLogData,
    getCaseLogAbortControllerRef,
  } = options;

  return async () => {
    if (!caseNumber) {
      return;
    }

    setLoadingLogData(true);

    rotateAbortControllerRef(getCaseLogAbortControllerRef);
    const { signal } = getCaseLogAbortControllerRef.current;

    const url = `/Case/log/${encodeURIComponent(caseNumber)}`;
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );
      const data = cleanCaseLog(json);

      setLogData(data);
      setLoadingLogData(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingLogData(false);
        throw error;
      }
    }
  };
};
