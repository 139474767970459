import { animatingClassName, performExpansion } from './functions';

export const initializeExpandedEffect = (options = {}) => {
  const { expanded, previousExpandedRef, cardElementRef } = options;
  return () => {
    const { current: cardElement } = cardElementRef;

    if (expanded && cardElement) {
      performExpansion({ expanded, cardElement, onHeightTransitionEndEffect });
    }

    previousExpandedRef.current = expanded;
  };
};

export const onChangeExpandedEffect = (options = {}) => {
  const { expanded, previousExpandedRef, cardElementRef } = options;
  return () => {
    const { current: previousExpanded } = previousExpandedRef;
    const { current: cardElement } = cardElementRef;

    if (expanded !== previousExpanded && cardElement) {
      performExpansion({ expanded, cardElement, onHeightTransitionEndEffect });
    }

    previousExpandedRef.current = expanded;
  };
};

export const onHeightTransitionEndEffect = (options = {}) => {
  const { expanded, cardElement, contentElement } = options;
  const onTransitionEnd = (e) => {
    if (e.propertyName === 'height' && e.target === contentElement) {
      cardElement.classList.remove(animatingClassName);
      contentElement.removeEventListener('transitionend', onTransitionEnd);
      if (expanded) {
        contentElement.style.height = 'auto';
      }
    }
  };
  return onTransitionEnd;
};
