import React, { createContext, useContext, useEffect, useState } from 'react';
import { FetchContext } from 'js/components/fetch';
import { MSALContext } from 'js/components/msal';
import { getCurrentUserEffect } from './effects';

const CurrentUserContext = createContext({
  hasLoaded: false,
  currentUser: {},
});

const CurrentUserProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const { api = {} } = useContext(FetchContext);
  const { isReady, account } = useContext(MSALContext);

  useEffect(
    getCurrentUserEffect({
      isReady,
      account,
      api,
      setCurrentUser,
      setHasLoaded,
    }),
    [isReady]
  );

  return (
    <CurrentUserContext.Provider value={{ hasLoaded, currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export { CurrentUserContext, CurrentUserProvider };
