import IMask from 'imask';
import { postalCodeMaskPattern } from 'js/utilities/masks';

export const createPostalCodeMaskEffect = (options = {}) => {
  const { postalCodeMaskRef } = options;
  return () => {
    postalCodeMaskRef.current = IMask.createMask({
      mask: postalCodeMaskPattern,
    });
  };
};

export const onChangeEffect = (options = {}) => {
  const { postalCodeMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = postalCodeMaskRef;
    if (mask) {
      const { value } = e.target;
      const formattedValue = mask.resolve(value).toUpperCase();
      onChange(e, formattedValue);
    }
  };
};

// For export:
export const onPostalCodeInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e, formattedValue) => {
    if (typeof onChange === 'function') {
      const { name } = e.target;
      onChange(name, formattedValue);
    }
  };
};
