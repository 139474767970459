import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'js/components/modal';

const SaveModal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    mounted,
    onClickCancel,
    onClickDiscard,
    onClickProceed,
    titleText = t('components.SaveModal.titleText'),
    messageText,
    cancelButtonText = t('components.SaveModal.cancelButtonText'),
    discardButtonText = t('components.SaveModal.discardButtonText'),
    proceedButtonText = t('components.SaveModal.proceedButtonText'),
  } = props;

  return (
    <Modal
      className={classnames('save-modal', className)}
      cancelButtonText={cancelButtonText}
      onClickCancel={onClickCancel}
      titleText={titleText}
      mounted={mounted}
      initialFocus="#save-modal-proceed"
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{titleText}</h2>
      {messageText && <p>{messageText}</p>}
      <div className="button-bar">
        <button className="button-link-appearance" onClick={onClickDiscard}>
          {discardButtonText}
        </button>
        <button
          id="save-modal-proceed"
          className="button button-highlight"
          onClick={onClickProceed}
        >
          {proceedButtonText}
        </button>
      </div>
    </Modal>
  );
};

SaveModal.defaultProps = {
  mounted: false,
};

SaveModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickProceed: PropTypes.func,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  discardButtonText: PropTypes.string,
  proceedButtonText: PropTypes.string,
};

export default SaveModal;
