export const isEventInsideElement = (event, element) => {
  const { clientX, clientY } = event;
  const { top, bottom, left, right } = element.getBoundingClientRect();

  const isInsideX = clientX >= left && clientX <= right;
  const isInsideY = clientY >= top && clientY <= bottom;
  const isInside = isInsideX && isInsideY;

  return isInside;
};
