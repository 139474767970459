import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import { InactiveTag } from 'js/components/deactivate';
import {
  onListMountEffect,
  companiesListRequestEffect,
  previousCompaniesListResultsEffect,
  nextCompaniesListResultsEffect,
  companiesListResultsPageNumberEffect,
  companiesListResultsSortEffect,
  companiesListSearchEffect,
  companiesListSearchResetEffect,
} from './effects';
import { queryForCompaniesList } from './functions';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListCard,
} from 'js/components/design-system';
import CompaniesListSortMenu from 'js/components/menus/companies-list-sort-menu';
import CompaniesListBreadcrumbs from './companies-list-breadcrumbs';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { Link } from 'react-router-dom';
import EmptyState from 'js/components/controls/empty-state';

const CompaniesListPage = () => {
  const { t } = useTranslation();
  const abortControllerRef = useRef(null);
  const history = useHistory();
  const { search = '' } = useLocation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const query = queryForCompaniesList(search);

  useEffect(
    onListMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    companiesListRequestEffect({
      t,
      api,
      query,
      setActive,
      setCompanies,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="companies-list-pagination-container">
      <OffsetLimitPagination
        className="companies-list-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousCompaniesListResultsEffect({
          history,
          query,
        })}
        onClickNext={nextCompaniesListResultsEffect({
          history,
          query,
        })}
        onClickPageNumber={companiesListResultsPageNumberEffect({
          history,
          query,
        })}
      />
    </div>
  );

  return (
    <Page
      className="companies-list-page"
      title={t('components.CompaniesListPage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container ">
        <div className="layout-column">
          <CompaniesListBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <div className="companies-list-page-heading">
            <div className="companies-list-page-heading-left">
              <h1>{t('components.CompaniesListPage.title')}</h1>
            </div>
            <div className="companies-list-page-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() =>
                  history.push(routePaths.companiesPrint + query.search)
                }
              >
                {t('common.print')}
              </button>
              <Link
                className="button button-highlight page-action-button"
                to={routePaths.newCompany}
              >
                {t('common.new')}
              </Link>
            </div>
          </div>

          <div className="companies-list-page-search-bar">
            <GeneralSearchInput
              placeholder={t('components.CompaniesListPage.searchPlaceholder')}
              initialQuery={query}
              onSearch={companiesListSearchEffect({ history, query })}
              onReset={companiesListSearchResetEffect({ history, query })}
            />
            <CompaniesListSortMenu
              field={query.params.SortBy}
              direction={query.params.Sort}
              onSelect={companiesListResultsSortEffect({ history, query })}
            />
          </div>

          {active && <ActivityIndicatorCard />}

          {!active && companies.length === 0 && (
            <EmptyState
              title={t(`components.CompaniesListPage.emptyStateMessage`)}
            />
          )}

          {!active && companies.length > 0 && (
            <Fragment>
              {pagination}
              <ul className="unstyled-list companies-list">
                {companies.map((company) => {
                  const { companyCode, companyName, coInact } = company;
                  return (
                    <li key={companyCode}>
                      <ListCard
                        key={companyCode}
                        heading={
                          <div className="inactive-tag-container">
                            <span>{`${companyCode} ${companyName}`}</span>
                            <InactiveTag inactive={coInact} />
                          </div>
                        }
                        linkTo={routePathReplacingParams(routePaths.company, {
                          companyCode,
                        })}
                      />
                    </li>
                  );
                })}
              </ul>
              {pagination}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default CompaniesListPage;
