import { parseISO, parse, format, isValid } from 'date-fns';

export const defaultDateFormat = 'yyyy/MM/dd';

export const parseDateFromISOString = (isoString) => {
  return isoString ? parseISO(isoString) : null;
};

export const formatDateFromISOString = (
  isoString,
  dateFormat = defaultDateFormat
) => {
  try {
    return isoString ? format(parseISO(isoString), dateFormat) : '';
  } catch (error) {
    return '';
  }
};

export const parseDate = (
  dateString = '',
  dateFormat = defaultDateFormat,
  sourceDate = new Date()
) => {
  return parse(dateString, dateFormat, sourceDate);
};

export const formatDate = (date, dateFormat = defaultDateFormat) => {
  return isValidDate(date) ? format(date, dateFormat) : '';
};

export const isValidDate = (date) => isValid(date);

export const formatDateFromString = (date) => {
  const splitDate = date && date.split('/');

  return date && date.length === 10
    ? new Date(`${splitDate[0]}-${splitDate[1]}-${splitDate[2]}T05:00:00Z`)
    : null;
};
