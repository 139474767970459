import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';

const ErrorPage = (props) => {
  const { className, title, heading, icon, content } = props;
  return (
    <Page
      className={classnames('error-page', className)}
      title={title}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <div className="error-page-icon">{icon}</div>
          <div className="error-page-content">
            <h1>{heading}</h1>
            {content}
          </div>
        </div>
      </div>
    </Page>
  );
};

ErrorPage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

export default ErrorPage;
