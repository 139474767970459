import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import { Checkbox, SortableTable } from 'js/components/design-system';
import { getManageCompanyColumns } from './functions';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import { returnFilteredData } from './functions';
import EmptyState from 'js/components/controls/empty-state';

const ManageCompaniesModal = (props) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const {
    title,
    active,
    mounted,
    onClose,
    onSave,
    companies = [],
    onChange,
    isCopy,
  } = props;

  const renderCheckboxForRow = (key, company) => {
    return (
      <Checkbox
        checked={company.selected}
        onChange={() => onChange(company)}
        key={key}
      />
    );
  };

  const filteredCompanies = returnFilteredData(companies, searchString);
  const STRONG_TAGS = {
    openStrong: '<strong>',
    closeStrong: '</strong>',
  };

  return (
    <Modal
      className="manage-codes-modal"
      cancelButtonText={t('components.DeactivateModal.closeButton')}
      onClickCancel={onClose}
      titleText={title}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{title}</h2>
      <p>{t(`components.ReleasePage.manageCompanies.checkMessage`)}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: t(`components.ReleasePage.manageCompanies.releaseMessage`, {
            ...STRONG_TAGS,
          }),
        }}
      />
      <div className="table-container">
        <GeneralSearchInput
          placeholder={t(
            'components.ReleasePage.manageCompanies.searchPlaceholder'
          )}
          onSearch={setSearchString}
          onReset={() => setSearchString('')}
          disabled={active}
        />
        <SortableTable
          columns={getManageCompanyColumns(t, renderCheckboxForRow)}
          data={filteredCompanies}
          initialSortColumnKey={'code'}
        />
        {filteredCompanies.length === 0 && (
          <EmptyState
            title={t(`components.ReleasePage.manageCompanies.companiesEmpty`)}
          />
        )}
      </div>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          onClick={onClose}
          disabled={active}
        >
          {t('components.ReleasePage.manageLpCode.cancelButton')}
        </button>
        <button
          className="button button-highlight"
          onClick={onSave}
          disabled={active || filteredCompanies.length === 0}
        >
          {isCopy
            ? t('components.ReleasePage.manageLpCode.copyButton')
            : t('components.ReleasePage.manageLpCode.saveButton')}
        </button>
      </div>
    </Modal>
  );
};

ManageCompaniesModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  mounted: PropTypes.bool,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  companies: PropTypes.array,
  isCopy: PropTypes.bool,
};

export default ManageCompaniesModal;
