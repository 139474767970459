import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import { CurrentUserContext } from 'js/components/current-user';
import {
  Card,
  InputLabel,
  InputHelp,
  DatePicker,
  StyledSelect,
  Checkbox,
  StrikethroughInput,
} from 'js/components/design-system';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onNumericInputBlurEffect,
  onFocusTypeoverInput,
  onDatePickerChangeEffect,
  onCheckboxChangeEffect,
  updateDefaultCSQFlagEffect,
} from './effects';
import { getPayeeOptions, canEditBilling } from './functions';
import {
  caseStatuses,
  caseBillingRequiredKeysForLawyerType,
  OTHER_PAYEE,
  calculatePlanTotal,
} from 'js/utilities/cases';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import PayeeAddressForm from './payee-address-form';
import { REFERRAL_LP_CODES } from 'js/utilities/legal-problem-codes';

const Spacer = () => <div />;

const CaseBillingForm = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseBilling');
  const { currentUser = {} } = useContext(CurrentUserContext);
  const { office = {} } = currentUser;

  const {
    className,
    formData = {},
    formOptions = {},
    onChange,
    lawyerType,
    caseClosing = false,
    closingInvalidFields = [],
    isAdmin,
    caseStatus,
    caseInfo = {},
  } = props;
  const { caseInformation = {} } = caseInfo;
  const { legalProblemCode = '' } = caseInformation;

  const {
    cheques = [],
    dateClosed = null,
    lawyerInvoiceDate = '',
    printCSQ = false,
    payee = '',
    planHours = '',
    planFees = '',
    planTaxes = '',
    clientHours = '',
    clientFees = '',
    csqThreshold = 0,
  } = formData;
  const hasReferralLp = REFERRAL_LP_CODES.includes(legalProblemCode);

  const isActive = caseStatus === caseStatuses.active;
  const hasOveride = isAdmin && office.code === '0100'; //head office
  const canEdit = canEditBilling({
    isAdmin,
    isActive,
    hasEditPermission,
    caseClosing,
  });

  useEffect(
    updateDefaultCSQFlagEffect({
      onChange,
      planFees,
      planTaxes,
      csqThreshold,
      calculatePlanTotal,
      formData,
      currentUserOffice: office.code,
      lawyerType,
    }),
    [planFees, planTaxes]
  );

  const { payee: payeeOptions = [] } = formOptions;

  const requiredFields = caseBillingRequiredKeysForLawyerType(lawyerType);
  const { invalidFields = [] } = validate(formData, requiredFields);

  const numericInputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onNumericInputBlurEffect({
      touch,
      onChange,
      toFixed: true,
      precision: 2,
    }),
    onFocus: onFocusTypeoverInput(),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  return (
    <Card className={classnames('case-billing-form', className)}>
      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseBillingForm.dateClosedValidation'
              )}
              invalid={
                isTouched('dateClosed') && invalidFields.includes('dateClosed')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseBillingForm.dateClosed'
                )}
              >
                <DatePicker
                  name="dateClosed"
                  selected={dateClosed}
                  readOnly={!canEdit}
                  {...datePickerEventHandlers('dateClosed')}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseBillingForm.payeeValidation'
              )}
              invalid={isTouched('payee') && invalidFields.includes('payee')}
            >
              <InputLabel
                content={t('components.CaseInformation.CaseBillingForm.payee')}
              >
                <StyledSelect
                  name="payee"
                  value={payee}
                  onChange={onInputChangeEffect({ onChange })}
                  disabled={!canEdit}
                >
                  <option key={''} value={''} disabled>
                    {t('common.defaultSelectPlaceholder')}
                  </option>
                  {getPayeeOptions(payeeOptions, lawyerType, t).map(
                    ({ payeeCode, payeeDesc, isDisabled }) => (
                      <option
                        key={payeeCode}
                        value={payeeCode}
                        disabled={isDisabled}
                      >
                        {`${payeeCode} \u2013 ${payeeDesc}`}
                      </option>
                    )
                  )}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
            <Spacer />
          </div>

          {payee === OTHER_PAYEE && caseClosing && (
            <PayeeAddressForm
              formData={formData}
              onChange={onChange}
              invalidFields={closingInvalidFields}
            />
          )}

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseBillingForm.lawyerInvoiceDateValidation'
              )}
              invalid={
                isTouched('lawyerInvoiceDate') &&
                invalidFields.includes('lawyerInvoiceDate')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseBillingForm.lawyerInvoiceDate'
                )}
              >
                <DatePicker
                  name="lawyerInvoiceDate"
                  selected={lawyerInvoiceDate}
                  {...datePickerEventHandlers('lawyerInvoiceDate')}
                  readOnly={!canEdit}
                />
              </InputLabel>
            </InputHelp>

            <InputLabel content="">
              <Checkbox
                label={t('components.CaseInformation.CaseBillingForm.printCSQ')}
                name="printCSQ"
                checked={printCSQ}
                onChange={onCheckboxChangeEffect({ onChange })}
                disabled={!canEdit}
              />
            </InputLabel>

            <Spacer />
          </div>
          <div className="form-row">
            <InputLabel
              content={t(
                'components.CaseInformation.CaseBillingForm.chequeNumbers'
              )}
            >
              <StrikethroughInput
                items={cheques
                  .filter(({ chequeNumber }) => chequeNumber > 0)
                  .map(({ chequeNumber, status }) => ({
                    text: chequeNumber.toString(),
                    struck: status === 'R', //reversed
                  }))}
              />
            </InputLabel>
          </div>
        </div>

        <div className="form-column">
          <h3>
            {t('components.CaseInformation.CaseBillingForm.chargedToPlan')}
          </h3>

          <InputHelp
            validationContent={t(
              'components.CaseInformation.CaseBillingForm.chargeValidation'
            )}
            invalid={
              isTouched('planHours') && invalidFields.includes('planHours')
            }
          >
            <InputLabel
              content={t(
                'components.CaseInformation.CaseBillingForm.planHours'
              )}
            >
              <input
                type="text"
                name="planHours"
                value={planHours}
                readOnly={!canEdit || (hasReferralLp && !hasOveride)}
                {...numericInputEventHandlers}
              />
            </InputLabel>
          </InputHelp>

          <InputHelp
            validationContent={t(
              'components.CaseInformation.CaseBillingForm.chargeValidation'
            )}
            invalid={
              isTouched('planFees') && invalidFields.includes('planFees')
            }
          >
            <InputLabel
              content={t('components.CaseInformation.CaseBillingForm.planFees')}
            >
              <input
                type="text"
                name="planFees"
                value={planFees}
                readOnly={!canEdit || (hasReferralLp && !hasOveride)}
                {...numericInputEventHandlers}
              />
            </InputLabel>
          </InputHelp>

          <InputHelp
            validationContent={t(
              'components.CaseInformation.CaseBillingForm.chargeValidation'
            )}
            invalid={
              isTouched('planTaxes') && invalidFields.includes('planTaxes')
            }
          >
            <InputLabel
              content={t(
                'components.CaseInformation.CaseBillingForm.planTaxes'
              )}
            >
              <input
                type="text"
                name="planTaxes"
                value={planTaxes}
                readOnly={!canEdit || (hasReferralLp && !hasOveride)}
                {...numericInputEventHandlers}
              />
            </InputLabel>
          </InputHelp>

          <InputLabel
            content={t('components.CaseInformation.CaseBillingForm.planTotal')}
          >
            <input
              type="text"
              name="planTotal"
              value={calculatePlanTotal(planFees, planTaxes)}
              readOnly
            />
          </InputLabel>
        </div>

        <div className="form-column">
          <h3>
            {t('components.CaseInformation.CaseBillingForm.chargedToClient')}
          </h3>

          <InputHelp
            validationContent={t(
              'components.CaseInformation.CaseBillingForm.chargeValidation'
            )}
            invalid={
              isTouched('clientHours') && invalidFields.includes('clientHours')
            }
          >
            <InputLabel
              content={t(
                'components.CaseInformation.CaseBillingForm.clientHours'
              )}
            >
              <input
                type="text"
                name="clientHours"
                value={clientHours}
                readOnly={!canEdit}
                {...numericInputEventHandlers}
              />
            </InputLabel>
          </InputHelp>

          <InputHelp
            validationContent={t(
              'components.CaseInformation.CaseBillingForm.chargeValidation'
            )}
            invalid={
              isTouched('clientFees') && invalidFields.includes('clientFees')
            }
          >
            <InputLabel
              content={t(
                'components.CaseInformation.CaseBillingForm.clientFees'
              )}
            >
              <input
                type="text"
                name="clientFees"
                value={clientFees}
                readOnly={!canEdit}
                {...numericInputEventHandlers}
              />
            </InputLabel>
          </InputHelp>
        </div>
      </div>
    </Card>
  );
};

CaseBillingForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  caseInfo: PropTypes.object.isRequired,
  formOptions: PropTypes.shape({
    payee: PropTypes.arrayOf(
      PropTypes.shape({
        payeeCode: PropTypes.string.isRequired,
        payeeDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  lawyerType: PropTypes.string,
  caseClosing: PropTypes.bool,
  closingInvalidFields: PropTypes.array,
  isAdmin: PropTypes.bool,
  caseStatus: PropTypes.string,
};

export default CaseBillingForm;
