export const binaryRadioGroupValues = {
  on: 'on',
  off: 'off',
};

export const booleanFromBinaryRadioGroupValue = (value) => {
  switch (value) {
    case binaryRadioGroupValues.on:
      return true;
    case binaryRadioGroupValues.off:
      return false;
    default:
      return undefined;
  }
};
