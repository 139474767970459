import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { routePaths } from 'js/components/router/route-paths';
import { paramsFromSearchString } from 'js/utilities/params';
import { validateResponse404TypeAndParams } from 'js/utilities/errors/response-404';

const STRONG_TAGS = {
  openStrong: '<strong>',
  closeStrong: '</strong>',
};

const HomeResponse404 = () => {
  const { t } = useTranslation();
  const { search = '' } = useLocation();
  const {
    params: { type = '' },
  } = useRouteMatch();
  const searchParams = paramsFromSearchString(search);

  if (!validateResponse404TypeAndParams(type, searchParams)) {
    return <Redirect to={routePaths.error404} />;
  }

  return (
    <div className="home-response-404">
      <h1>{t('components.HomeResponse404.heading')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t(`components.HomeResponse404.messages.${type}`, {
            ...STRONG_TAGS,
            ...searchParams,
          }),
        }}
      />
      <p>{t('components.HomeResponse404.action')}</p>
    </div>
  );
};

export default HomeResponse404;
