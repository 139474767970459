import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, InputHelp } from 'js/components/design-system';
import {
  //   createCaseNumberMaskEffect,
  onChangeEffect,
  onKeyDownEffect,
} from './effects';

const ButtonInput = (props) => {
  const {
    className,
    inputName,
    value,
    isValid,
    onChange,
    onBlur,
    onConfirm,
    onReset,
    canReset,
    buttonText,
    inputLabel,
    errorText,
    placeholderText,
    disableConfirm,
  } = props;

  return (
    <div
      className={classnames('button-input-container', className, {
        canReset: canReset === false,
      })}
    >
      <label htmlFor="button-input">{inputLabel}</label>
      <InputHelp validationContent={errorText} invalid={!isValid}>
        <div className="button-input-group">
          {canReset && (
            <button className="button-input-reset" onClick={onReset}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          )}
          <input
            type="text"
            id="button-input"
            className="button-input"
            autoComplete="off"
            name={inputName}
            value={value}
            onChange={onChangeEffect({ onChange })}
            onBlur={onBlur}
            onKeyDown={onKeyDownEffect({ onConfirm })}
            placeholder={placeholderText}
          />
          <button
            className="button button-highlight button-input-confirm"
            onClick={onConfirm}
            disabled={disableConfirm}
          >
            {buttonText}
          </button>
        </div>
      </InputHelp>
    </div>
  );
};

ButtonInput.defaultProps = {
  canReset: true,
  isValid: true,
  disableConfirm: false,
};

ButtonInput.propTypes = {
  className: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  buttonText: PropTypes.string,
  canReset: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  inputLabel: PropTypes.string,
  errorText: PropTypes.string,
  placeholderText: PropTypes.string,
};

export default ButtonInput;
