import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FABPosition = (props) => {
  const { className, children } = props;
  return (
    <div className={classnames('fab-position', className)}>
      <div className="fab-position-content">{children}</div>
    </div>
  );
};

FABPosition.propTypes = {
  className: PropTypes.string,
};

export default FABPosition;
