export const createCaseCopy = (data = {}) => {
  const { caseInformation = {}, caseLawyer = {} } = data;
  return {
    caseInformation: createCaseInformationCopy(caseInformation),
    caseLawyer: caseLawyer,
  };
};

export const createCaseInformationCopy = (data) => {
  //some items are removed for copy
  return {
    ...data,
    caseStatus: '',
    cif: '',
    intakeId: '',
    lastEdit: '',
    openDate: '',
  };
};
