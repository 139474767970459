import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from 'js/components/current-user';
import { ValidationContext } from 'js/components/validation';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import {
  Card,
  InputLabel,
  InputHelp,
  DatePicker,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { caseInformationEditingRequiredKeys } from 'js/utilities/cases';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onCheckboxChangeEffect,
  setAvailableOfficesEffect,
} from './effects';

const Spacer = () => <div />;

const CaseInformationForm = (props) => {
  const { t } = useTranslation();
  const [availableOffices, setAvailableOffices] = useState([]);
  const [isOfficeEditable, setOfficeEditable] = useState([]);
  const { hasLoaded: hasLoadedCurrentUser, currentUser } = useContext(
    CurrentUserContext
  );
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseInformation');
  const canEditOpenDate = hasNamedPermission('editCaseOpenDate');

  const {
    className,
    formData = {},
    formOptions = {},
    onChange,
    isNew,
    isCopy,
  } = props;

  const isNewOrCopy = isNew || isCopy;

  const {
    archiveBox = '',
    archiveFile = '',
    cif = '',
    intakeId = '',
    legalProblemCode = '',
    officeOpened = '',
    openDate = null,
    shreddedDate = null,
    lastEdit = null,
    reTransferDate = null,
    otherParty = false,
    specialInstructions = '',
    openedInOffice = {},
  } = formData;

  const { legalProblems = [], offices = [] } = formOptions;

  const { invalidFields = [] } = validate(
    formData,
    caseInformationEditingRequiredKeys
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  useEffect(
    setAvailableOfficesEffect({
      isNewOrCopy,
      currentUser,
      offices,
      openedInOffice,
      setAvailableOffices,
      setOfficeEditable,
      onChange,
    }),
    [isNewOrCopy, hasLoadedCurrentUser, offices.length, openedInOffice.code]
  );

  return (
    <Card className={classnames('case-information-form', className)}>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.CaseInformationForm.cifValidation'
          )}
          invalid={isTouched('cif') && invalidFields.includes('cif')}
        >
          <InputLabel
            content={t('components.CaseInformation.CaseInformationForm.cif')}
          >
            <input type="text" name="cif" value={cif} readOnly />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.CaseInformationForm.intakeIdValidation'
          )}
          invalid={isTouched('intakeId') && invalidFields.includes('intakeId')}
        >
          <InputLabel
            content={t(
              'components.CaseInformation.CaseInformationForm.intakeId'
            )}
          >
            <input type="text" name="intakeId" value={intakeId} readOnly />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.CaseInformationForm.legalProblemCodeValidation'
          )}
          invalid={
            isTouched('legalProblemCode') &&
            invalidFields.includes('legalProblemCode')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.CaseInformationForm.legalProblemCode'
            )}
          >
            <StyledSelect
              name="legalProblemCode"
              value={legalProblemCode}
              disabled={!hasEditPermission}
              {...inputEventHandlers}
            >
              <option key={''} value={''} disabled>
                {t(
                  'components.CaseInformation.CaseInformationForm.legalProblemCodePlaceholder'
                )}
              </option>
              {legalProblems.map(
                ({ legalProblemCode: lpCode, legalProblemDesc: lpDesc }) => (
                  <option
                    key={lpCode}
                    value={lpCode}
                  >{`${lpCode} \u2013 ${lpDesc}`}</option>
                )
              )}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.CaseInformationForm.officeOpenedValidation'
          )}
          invalid={
            isTouched('officeOpened') && invalidFields.includes('officeOpened')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.CaseInformationForm.officeOpened'
            )}
          >
            <StyledSelect
              name="officeOpened"
              value={officeOpened}
              disabled={!hasEditPermission || !isOfficeEditable}
              {...inputEventHandlers}
            >
              {isOfficeEditable && (
                <option value="" disabled>
                  {t(
                    'components.CaseInformation.CaseInformationForm.officeOpenedPlaceholder'
                  )}
                </option>
              )}
              {availableOffices.map(({ officeCode, officeName }) => (
                <option
                  key={officeCode}
                  value={officeCode}
                >{`${officeCode} \u2013 ${officeName}`}</option>
              ))}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>

      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.openDateValidation'
              )}
              invalid={
                isTouched('openDate') && invalidFields.includes('openDate')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.openDate'
                )}
              >
                <DatePicker
                  name="openDate"
                  selected={openDate}
                  disabled={isNew || isCopy}
                  {...datePickerEventHandlers('openDate')}
                  readOnly={!canEditOpenDate}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.shreddedDateValidation'
              )}
              invalid={
                isTouched('shreddedDate') &&
                invalidFields.includes('shreddedDate')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.shreddedDate'
                )}
              >
                <DatePicker
                  name="shreddedDate"
                  selected={shreddedDate}
                  {...datePickerEventHandlers('shreddedDate')}
                  readOnly={!hasEditPermission}
                />
              </InputLabel>
            </InputHelp>

            <Checkbox
              label={t(
                'components.CaseInformation.CaseInformationForm.otherParty'
              )}
              name="otherParty"
              checked={otherParty}
              onChange={onCheckboxChangeEffect({ onChange })}
              disabled={!hasEditPermission}
            />
          </div>
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.lastEditValidation'
              )}
              invalid={
                isTouched('lastEdit') && invalidFields.includes('lastEdit')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.lastEdit'
                )}
              >
                <DatePicker
                  readOnly
                  name="lastEdit"
                  selected={lastEdit}
                  {...datePickerEventHandlers('lastEdit')}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.reTransferDateValidation'
              )}
              invalid={
                isTouched('reTransferDate') &&
                invalidFields.includes('reTransferDate')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.reTransferDate'
                )}
              >
                <DatePicker
                  name="reTransferDate"
                  selected={reTransferDate}
                  {...datePickerEventHandlers('reTransferDate')}
                  readOnly={!hasEditPermission}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.archiveBoxValidation'
              )}
              invalid={
                isTouched('archiveBox') && invalidFields.includes('archiveBox')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.archiveBox'
                )}
              >
                <input
                  type="text"
                  name="archiveBox"
                  autoComplete="off"
                  value={archiveBox}
                  {...inputEventHandlers}
                  readOnly={!hasEditPermission}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.CaseInformation.CaseInformationForm.archiveFileValidation'
              )}
              invalid={
                isTouched('archiveFile') &&
                invalidFields.includes('archiveFile')
              }
            >
              <InputLabel
                content={t(
                  'components.CaseInformation.CaseInformationForm.archiveFile'
                )}
              >
                <input
                  type="text"
                  autoComplete="off"
                  name="archiveFile"
                  value={archiveFile}
                  {...inputEventHandlers}
                  readOnly={!hasEditPermission}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
        <div className="form-column">
          <div className="form-row">
            <InputLabel
              content={t(
                'components.CaseInformation.CaseInformationForm.specialInstructions'
              )}
            >
              <textarea
                name="specialInstructions"
                value={specialInstructions}
                {...inputEventHandlers}
                readOnly={!hasEditPermission}
              />
            </InputLabel>
          </div>
        </div>
      </div>
    </Card>
  );
};

CaseInformationForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  isCopy: PropTypes.bool.isRequired,
  formOptions: PropTypes.shape({
    legalProblems: PropTypes.arrayOf(
      PropTypes.shape({
        legalProblemCode: PropTypes.string.isRequired,
        legalProblemDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
    offices: PropTypes.arrayOf(
      PropTypes.shape({
        officeCode: PropTypes.string.isRequired,
        officeName: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CaseInformationForm;
