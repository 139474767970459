import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'js/utilities/validation';
import Prompt from './prompt';
import {
  promptEffect,
  onConfirmPromptEffect,
  onCancelPromptEffect,
} from './effects';

const PromptContext = createContext({});

const PromptProvider = (props) => {
  const { rules = {}, children } = props;
  const [promiseCallbacks, setPromiseCallbacks] = useState({});
  const [promptProps, setPromptProps] = useState({});

  return (
    <PromptContext.Provider
      value={{
        prompt: promptEffect({ rules, setPromiseCallbacks, setPromptProps }),
      }}
    >
      {children}
      <Prompt
        {...promptProps}
        mounted={!isEmpty(promptProps)}
        onConfirm={onConfirmPromptEffect({
          promiseCallbacks,
          setPromiseCallbacks,
          setPromptProps,
        })}
        onCancel={onCancelPromptEffect({
          promiseCallbacks,
          setPromiseCallbacks,
          setPromptProps,
        })}
      />
    </PromptContext.Provider>
  );
};

PromptProvider.defaultProps = {
  rules: {},
};

PromptProvider.propTypes = {
  rules: PropTypes.objectOf(
    PropTypes.shape({
      test: PropTypes.func.isRequired,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      allowConfirm: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      confirmButtonTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      confirmButtonClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      allowCancel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      cancelButtonTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      cancelButtonClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
    })
  ).isRequired,
};

export { PromptContext, PromptProvider };
