import {
  subjectChangeRequiresNewCriteria,
  getIndexOfSearchSubject,
  getInitialSearchCriteriaForSubject,
  validateSearchCriteria,
  routePathForSearch,
  searchSubjectForModel,
} from './functions';

export const initializeSearchTermEffect = (options = {}) => {
  const { initialQuery = {}, setSearchTerm } = options;
  const {
    params: { Keyword: initialSearchTerm = '' },
  } = initialQuery;
  return () => setSearchTerm(initialSearchTerm);
};

export const restoreSearchSubjectAndCriteriaEffect = (options = {}) => {
  const {
    initialModel = '',
    initialQuery = {},
    subjects = [],
    restoreSearchSubject,
    setSelectedSubject,
    setSelectedSubjectIndex,
    setSearchCriteria,
  } = options;
  return () => {
    // Restore the search subject and criteria from the initial query:
    const { params = {} } = initialQuery;
    const { firstName = '', lastName = '', phoneNumber = '' } = params;

    let subject = restoreSearchSubject();

    if (initialModel && !subject) {
      subject = searchSubjectForModel(initialModel);
    }

    const subjectIndex = getIndexOfSearchSubject(subjects, subject);

    if (subjectIndex >= 0) {
      setSelectedSubject(subject);
      setSelectedSubjectIndex(subjectIndex);

      switch (subject) {
        case 'clientName':
        case 'lawyerName':
          setSearchCriteria({ firstName, lastName });
          break;
        case 'clientPhoneNumber':
        case 'lawyerPhoneNumber':
          setSearchCriteria({ phoneNumber });
          break;
        default:
          setSearchCriteria(getInitialSearchCriteriaForSubject(subject));
          break;
      }
    }
  };
};

export const onSelectSubjectEffect = (options = {}) => {
  const {
    selectedSubject,
    subjects,
    untouchAll,
    persistSearchSubject,
    setSelectedSubject,
    setSelectedSubjectIndex,
    setSearchCriteria,
  } = options;
  return (subject) => {
    const index = getIndexOfSearchSubject(subjects, subject);
    if (index >= 0) {
      untouchAll();
      persistSearchSubject(subject);
      setSelectedSubject(subject);
      setSelectedSubjectIndex(index);
      // Change the criteria if the new new subject's criteria
      // is incompatible with the current subject's criteria:
      if (subjectChangeRequiresNewCriteria(selectedSubject, subject)) {
        setSearchCriteria(getInitialSearchCriteriaForSubject(subject));
      }
    }
  };
};

export const onChangeSearchCriteriaEffect = (options = {}) => {
  const { setSearchCriteria } = options;
  return (name, value) => {
    setSearchCriteria((searchCriteria) => ({
      ...searchCriteria,
      [name]: value,
    }));
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { onEnter } = options;
  return (e) => {
    if (e.key === 'Enter' && typeof onEnter === 'function') {
      onEnter();
    }
  };
};

export const onSubmitSearchEffect = (options = {}) => {
  const {
    subject,
    searchCriteria = {},
    initialModel,
    initialQuery,
    validate,
    touch,
    history,
  } = options;
  return () => {
    const isValid = validateSearchCriteria({
      subject,
      searchCriteria,
      validate,
    });
    if (!isValid) {
      touch(Object.keys(searchCriteria));
      return;
    }

    const path = routePathForSearch(
      subject,
      searchCriteria,
      initialModel,
      initialQuery
    );
    if (path) {
      history.push(path);
    }
  };
};
