import { ALL_FILTER, defaultTypeFilterForModel } from './filter/functions';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { cleanStringField } from 'js/utilities/strings';

export const clientTypeByEnumValue = (value) => {
  switch (parseInt(value)) {
    case 1:
      return 'All';
    case 2:
      return 'PrimaryClient';
    case 3:
      return 'Spouse';
    case 4:
      return 'SurvivingSpouse';
    case 5:
      return 'Dependent';
    default:
      return '';
  }
};

export const lawyerTypeByEnumValue = (value) => {
  switch (parseInt(value)) {
    case 1:
      return 'All';
    case 2:
      return 'Staff';
    case 3:
      return 'Cooperating';
    default:
      return '';
  }
};

export const queryForSearchString = (model, search = '') => {
  const {
    firstName = '',
    lastName = '',
    phoneNumber = '',
    type = defaultTypeFilterForModel(model),
    status = ALL_FILTER,
    city = '',
    company = '',
    firmname = '',
    // Pagination params:
    SortBy = 'firstname',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  let nextParams = {
    firstName,
    lastName,
    phoneNumber,
    status,
    type,
    city,
  };

  switch (model) {
    case 'client':
      nextParams = { ...nextParams, company };
      break;
    case 'lawyer':
      nextParams = { ...nextParams, firmname };
      break;
    default:
      break;
  }

  const paginationParams = {
    SortBy,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  const params = {
    ...nextParams,
    ...paginationParams,
  };

  return queryForParams(params);
};

export const searchUrlForModel = (model, search = '') => {
  switch (model) {
    case 'client':
      return `/search/client${search}`;
    case 'lawyer':
      return `/search/lawyer${search}`;
    default:
      return '';
  }
};

export const cleanSearchResults = (results = [], model) => {
  switch (model) {
    case 'client':
      return results.map(cleanClientSearchResult);
    case 'lawyer':
      return results.map(cleanLawyerSearchResult);
    default:
      return [];
  }
};

export const cleanClientSearchResult = (client = {}) => {
  const {
    id,
    clientCode,
    firstName,
    lastName,
    companyName,
    unionName,
    city,
    type,
    inactive,
    employeeNumber,
  } = client;

  return {
    id: cleanStringField(id),
    clientCode: cleanStringField(clientCode),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    companyName: cleanStringField(companyName),
    unionName: cleanStringField(unionName),
    city: cleanStringField(city),
    type: clientTypeByEnumValue(type),
    typeValue: parseInt(type),
    inactive: inactive === true,
    employeeNumber: cleanStringField(employeeNumber),
  };
};

export const cleanLawyerSearchResult = (lawyer = {}) => {
  const {
    id,
    firstName,
    lastName,
    firmname,
    city,
    province,
    type,
    inactive,
  } = lawyer;

  return {
    id: cleanStringField(id),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    firmname: cleanStringField(firmname),
    city: cleanStringField(city),
    province: cleanStringField(province),
    type: lawyerTypeByEnumValue(type),
    typeValue: parseInt(type),
    inactive: inactive === true,
  };
};
