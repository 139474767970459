import { breadcrumbTypes } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';

export const adminToolsBreadcrumbsHistory = (t) => {
  return [
    breadcrumbTypes.home,
    {
      content: t('components.AdminPage.breadcrumbs.adminTools'),
      path: routePaths.adminTools,
    },
  ];
};

export const systemSettingsBreadcrumbsHistory = (t) => {
  const prevHistory = adminToolsBreadcrumbsHistory(t);

  return [
    ...prevHistory,
    {
      content: t('components.AdminPage.breadcrumbs.systemSettings'),
      path: routePaths.systemSettings,
    },
  ];
};

export const proceduresBreadcrumbsHistory = (t) => {
  return [
    breadcrumbTypes.home,
    {
      content: t('components.AdminPage.breadcrumbs.procedures'),
      path: routePaths.procedures,
    },
  ];
};
