import React, {
  useRef,
  useContext,
  useState,
  useEffect,
  Fragment,
} from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListButtonCard,
  StyledSelect,
  InputLabel,
} from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import {
  onMountEffect,
  getCoopOptions,
  coopLawyerRequestEffect,
  previousCoopLawyerResultsEffect,
  nextCoopLawyerResultsEffect,
  coopLawyerResultsPageNumberEffect,
  onInputChangeEffect,
  onSearchChangeEffect,
} from './effects';
import { queryFromModalQueryString, LAWYER_ACTIVE_STATUS } from './functions';
import { LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';
import EmptyState from 'js/components/controls/empty-state';

const CoopLawyerModal = (props) => {
  const { t } = useTranslation();
  const {
    mounted,
    onBack,
    onSelect,
    onChange,
    CaseInformation,
    onClose,
    modalQueryString,
  } = props;
  const { legalProblemCode } = CaseInformation;

  const abortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [coopLawyers, setCoopLawyers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  const [panels, setPanels] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const [cityOptions, setCityOptions] = useState([]);
  const [lawyerSubPanelOptions, setLawyerSubPanelOptions] = useState([]);
  const [hidden, setHidden] = useState(true);
  const nextQuery = queryFromModalQueryString(modalQueryString);
  const {
    params: {
      PanelId = '',
      Province = '',
      City = '',
      SubPanelId = '',
      Keyword = '',
      ActiveStatus = LAWYER_ACTIVE_STATUS.ACTIVE, //defaulted to active lawyers
    },
  } = nextQuery;
  const [firstCall, setFirstCall] = useState(true);
  const hideResults = !PanelId && !Province && !Keyword;
  const showContent = !active && !hidden && !hideResults;

  useEffect(
    onMountEffect({
      abortControllerRef,
      setActive,
    }),
    []
  );

  useEffect(
    getCoopOptions({
      t,
      api,
      abortControllerRef,
      setActive,
      legalProblemCode,
      setProvinces,
      setPanels,
    }),
    [legalProblemCode]
  );

  useEffect(
    coopLawyerRequestEffect({
      t,
      api,
      query: nextQuery,
      setActive,
      setCoopLawyers,
      setTotalResults,
      abortControllerRef,
      setCityOptions,
      setLawyerSubPanelOptions,
      setHidden,
      firstCall,
      setFirstCall,
    }),
    [firstCall, nextQuery.search]
  );

  let savedPanel = {};
  if (PanelId) {
    savedPanel = panels.find((p) => p.panId === Number(PanelId)) || {};
  }

  const pagination = (
    <div className={classnames('pagination-container', { hidden })}>
      <OffsetLimitPagination
        className="law-firm-pagination"
        offset={nextQuery.params.Offset}
        limit={nextQuery.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousCoopLawyerResultsEffect({
          query: nextQuery,
          onChange,
        })}
        onClickNext={nextCoopLawyerResultsEffect({
          query: nextQuery,
          onChange,
        })}
        onClickPageNumber={coopLawyerResultsPageNumberEffect({
          query: nextQuery,
          onChange,
        })}
      />
    </div>
  );

  return (
    <Modal
      className={'select-lawyer-modal search-lawyer-modal'}
      backButtonText={t(`common.back`)}
      onClickBack={onBack}
      onClickCancel={onClose}
      titleText={t('components.CoopLawyerModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.CoopLawyerModal.title')}</h2>
      {!active && (
        <div className="layout-container  inset-col-1">
          <div className="layout-column">
            <InputLabel content={t('components.CoopLawyerModal.panel')}>
              <StyledSelect
                value={PanelId}
                name="PanelId"
                onChange={onInputChangeEffect({
                  onChange,
                  clear: 'SubPanelId',
                })}
              >
                <option value="" disabled>
                  {t('components.CoopLawyerModal.panelPlaceholder')}
                </option>
                {panels.map((pan) => {
                  return (
                    <option value={pan.panId} key={pan.panId}>
                      {t(`common.lawyerPanels.${pan.panKey}.title`)}
                    </option>
                  );
                })}
              </StyledSelect>
            </InputLabel>
          </div>
          <div className="layout-column">
            <InputLabel content={t('components.CoopLawyerModal.province')}>
              <StyledSelect
                value={Province}
                name="Province"
                onChange={onInputChangeEffect({ onChange, clear: 'City' })}
              >
                <option value="" disabled>
                  {t('components.CoopLawyerModal.provincePlaceholder')}
                </option>
                {provinces.map((prov) => {
                  return (
                    <option value={prov.provId} key={prov.provId}>
                      {prov.provName}
                    </option>
                  );
                })}
              </StyledSelect>
            </InputLabel>
          </div>
          <div className="layout-column go-button-container">
            <button
              className="button"
              onClick={() => setFirstCall(false)}
              disabled={!Province || !PanelId || !firstCall}
            >
              {t('components.CoopLawyerModal.go')}
            </button>
          </div>
        </div>
      )}
      {firstCall && (
        <Fragment>
          <div className="layout-container inset-col-1 search-divider">
            <div className="line-divider" /> <p>{t('common.or')}</p>{' '}
            <div className="line-divider" />
          </div>
          <h2>{t('components.CoopLawyerModal.searchById')}</h2>
          <div className="layout-container  inset-col-1">
            <div className="layout-column ">
              <GeneralSearchInput
                name="Keyword"
                placeholder={t('components.CoopLawyerModal.searchId')}
                initialQuery={nextQuery}
                onSearch={onSearchChangeEffect({ onChange, setFirstCall })}
                onReset={onSearchChangeEffect({ onChange })}
              />
            </div>
            <div className="layout-column" />
          </div>
        </Fragment>
      )}

      <div
        className={classnames(
          'layout-container  inset-col-1 search-container',
          { hidden }
        )}
      >
        <div className="layout-column">
          <GeneralSearchInput
            name="Keyword"
            placeholder={t('components.CoopLawyerModal.searchPlaceholder')}
            initialQuery={nextQuery}
            onSearch={onSearchChangeEffect({ onChange })}
            onReset={onSearchChangeEffect({ onChange })}
          />
        </div>
        <div className="layout-column" />
      </div>
      {/*HIDDEN ITEMS*/}
      <div
        className={classnames(
          'layout-container  inset-col-1 lawyer-search-second-step',
          { hidden }
        )}
      >
        <div className="layout-column">
          <StyledSelect
            value={SubPanelId}
            name="SubPanelId"
            onChange={onInputChangeEffect({ onChange })}
            disabled={lawyerSubPanelOptions.length === 0}
          >
            <option value="">
              {t('components.CoopLawyerModal.subPanelPlaceholder')}
            </option>
            {savedPanel.panKey &&
              lawyerSubPanelOptions.map((subPan) => {
                return (
                  <option value={subPan.subpanId} key={subPan.subpanId}>
                    {t(
                      `common.lawyerPanels.${savedPanel.panKey}.subPanels.${subPan.subpanKey}`
                    )}
                  </option>
                );
              })}
          </StyledSelect>
        </div>
        <div className="layout-column">
          <StyledSelect
            value={City}
            name="City"
            onChange={onInputChangeEffect({ onChange })}
            disabled={cityOptions.length === 0}
          >
            <option value="">
              {t('components.CoopLawyerModal.cityPlaceholder')}
            </option>
            {cityOptions.map((c, key) => {
              return (
                <option value={c.cityCode} key={key}>
                  {c.cityName}
                </option>
              );
            })}
          </StyledSelect>
        </div>
        <div className="layout-column">
          <StyledSelect
            value={ActiveStatus}
            name="ActiveStatus"
            onChange={onInputChangeEffect({ onChange })}
          >
            <option value={LAWYER_ACTIVE_STATUS.ALL}>
              {t('components.CoopLawyerModal.allLawyers')}
            </option>
            <option value={LAWYER_ACTIVE_STATUS.ACTIVE}>
              {t('components.CoopLawyerModal.activeLawyers')}
            </option>
            <option value={LAWYER_ACTIVE_STATUS.INACTIVE}>
              {t('components.CoopLawyerModal.inactiveLawyers')}
            </option>
          </StyledSelect>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {!active && !hidden && !hideResults && pagination}
          {active && <ActivityIndicatorCard />}
          {showContent &&
            coopLawyers.map((coopLawyer) => {
              return (
                <ListButtonCard
                  key={coopLawyer.lawyerId}
                  onClick={() =>
                    onSelect({
                      lawyer: coopLawyer,
                      lawyerType: LAWYER_TYPE_ENUM.COOP,
                    })
                  }
                  heading={
                    <Fragment>
                      <span>{`${coopLawyer.lastName}, ${coopLawyer.firstName}`}</span>
                      <InactiveTag inactive={!coopLawyer.isActive} />
                    </Fragment>
                  }
                  subheading={`${t('components.CoopLawyerModal.lawyerId')}: ${
                    coopLawyer.lawyerId
                  }`}
                />
              );
            })}
          {showContent && coopLawyers.length === 0 && (
            <EmptyState
              title={t(`components.CoopLawyerModal.emptyStateMessage`)}
            />
          )}
          {showContent && pagination}
        </div>
      </div>
    </Modal>
  );
};

CoopLawyerModal.propTypes = {
  ...Modal.propTypes,
  onSelect: PropTypes.func.isRequired,
  caseLawyerInfo: PropTypes.object,
};

export default CoopLawyerModal;
