import React, { useEffect, createRef } from 'react';
import { printManagerMountEffect } from './effects';
import { contentElementClassName, childrenElementClassName } from './functions';

const PrintManager = ({ children }) => {
  const containerRef = createRef();

  useEffect(printManagerMountEffect({ containerRef }), []);

  return (
    <div className="print-manager" ref={containerRef}>
      <div className={contentElementClassName} />
      <div className={childrenElementClassName}>{children}</div>
    </div>
  );
};

export default PrintManager;
