import { userGroups } from 'js/components/group-permissions';

export const caseInformationNamedPermissions = {
  editCaseInformation: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
  editCaseOpenDate: [userGroups.administrator],
};

export const caseBillingNamedPermissions = {
  editCaseBilling: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const caseLawyerNamedPermissions = {
  editCaseLawyer: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const caseNotesNamedPermissions = {
  addCaseNotes: [
    userGroups.administrator,
    userGroups.intakeSpecialist,
    userGroups.dataEntry,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const caseClosingNamedPermissions = {
  ...caseInformationNamedPermissions,
  ...caseBillingNamedPermissions,
  ...caseLawyerNamedPermissions,
};
