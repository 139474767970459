export const onChangeEffect = (options = {}) => {
  const { setFilterQuery } = options;
  return (e) => {
    setFilterQuery(e.target.value);
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { executeFilter } = options;
  return (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      executeFilter();
    }
  };
};

export const onFilterEffect = (options = {}) => {
  const { filterQuery, onFilter } = options;
  return () => {
    if (typeof onFilter === 'function') {
      onFilter(filterQuery);
    }
  };
};

export const onResetFilterEffect = (options = {}) => {
  const { setFilterQuery, onReset } = options;
  return () => {
    setFilterQuery('');
    if (typeof onReset === 'function') {
      onReset();
    }
  };
};
