import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Disclosure = (props) => {
  const {
    className,
    heading,
    showContentAction,
    hideContentAction,
    contentVisible,
    children,
  } = props;
  const containerClassNames = classnames(
    'disclosure',
    { 'disclosure-content-visible': contentVisible },
    className
  );
  const action = contentVisible ? hideContentAction : showContentAction;

  return (
    <div className={containerClassNames}>
      <div className="disclosure-header">
        <h2>{heading}</h2>
        <div>{action}</div>
      </div>
      <div className="disclosure-content">{children}</div>
    </div>
  );
};

Disclosure.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.node,
  showContentAction: PropTypes.node,
  hideContentAction: PropTypes.node,
  contentVisible: PropTypes.bool,
};

export default Disclosure;
