import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import { nl2br } from 'js/utilities/strings';
import ChequeRunActions from '../cheque-run-actions';
import ChequeRunResults from '../cheque-run-results';
import ChequeRunError from '../cheque-run-error';
import { onClickConfirmDeleteRunEffect } from './effects';

const ChequeRunModalContentLocked = (props) => {
  const { t } = useTranslation();
  const {
    title,
    reprintLastRun,
    reprintChequeCount,
    lockSystemActive,
    onClickUnlock,
    isCreateRunActive,
    onClickCreateRun,
    isConfirmRunActive,
    onClickConfirmRun,
    isDeleteRunActive,
    onClickDeleteRun,
    chequeRunResults,
    chequeRunError,
  } = props;

  const [isConfirmingDeleteRun, setConfirmingDeleteRun] = useState(false);
  const isActive = isCreateRunActive || isConfirmRunActive;
  const displayResults = !isEmpty(chequeRunResults) && isEmpty(chequeRunError);
  const displayError = !isEmpty(chequeRunError);
  const displayActions = !displayResults && !displayError;

  return (
    <div className="cheque-run-modal-content cheque-run-modal-content-locked">
      <div className="cheque-run-modal-content-locked-body">
        {isConfirmingDeleteRun && (
          <div className="cheque-run-modal-confirm-delete-run">
            <h1>{t('components.ChequeRunModal.confirmDeleteRunTitle')}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: nl2br(
                  t('components.ChequeRunModal.confirmDeleteRunMessage')
                ),
              }}
            />
            <div className="button-bar">
              <button
                className="button"
                disabled={isDeleteRunActive}
                onClick={() => setConfirmingDeleteRun(false)}
              >
                {t('components.ChequeRunModal.cancelDeleteRunButtonTitle')}
              </button>
              <button
                className="button button-highlight"
                disabled={isDeleteRunActive}
                onClick={onClickConfirmDeleteRunEffect({
                  onClickDeleteRun,
                  setConfirmingDeleteRun,
                })}
              >
                {isDeleteRunActive
                  ? t(
                      'components.ChequeRunModal.confirmDeleteRunButtonTitleActive'
                    )
                  : t('components.ChequeRunModal.confirmDeleteRunButtonTitle')}
              </button>
            </div>
          </div>
        )}

        {!isConfirmingDeleteRun && (
          <Fragment>
            <FontAwesomeIcon icon={faMoneyCheckAlt} />
            <h2>{title}</h2>
            {isActive && <ActivityIndicatorCard message="" />}
            {!isActive && displayActions && (
              <ChequeRunActions
                lockSystemActive={lockSystemActive}
                onClickUnlock={onClickUnlock}
                isCreateRunActive={isCreateRunActive}
                onClickCreateRun={onClickCreateRun}
                chequeRunOptions={{
                  reprintLastRun,
                  reprintChequeCount,
                }}
              />
            )}
            {!isActive && displayResults && (
              <ChequeRunResults
                results={chequeRunResults}
                isConfirmRunActive={isConfirmRunActive}
                onClickConfirmRun={onClickConfirmRun}
                onClickDeleteRun={() => setConfirmingDeleteRun(true)}
              />
            )}
            {!isActive && displayError && (
              <ChequeRunError
                error={chequeRunError}
                lockSystemActive={lockSystemActive}
                onClickUnlock={onClickUnlock}
                isDeleteRunActive={isDeleteRunActive}
                onClickDeleteRun={onClickDeleteRun}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

ChequeRunModalContentLocked.defaultProps = {
  reprintLastRun: false,
};

ChequeRunModalContentLocked.propTypes = {
  title: PropTypes.string.isRequired,
  reprintLastRun: PropTypes.bool,
  reprintChequeCount: PropTypes.number,
  lockSystemActive: PropTypes.bool.isRequired,
  onClickUnlock: PropTypes.func.isRequired,
  isCreateRunActive: PropTypes.bool.isRequired,
  onClickCreateRun: PropTypes.func.isRequired,
  isConfirmRunActive: PropTypes.bool.isRequired,
  onClickConfirmRun: PropTypes.func.isRequired,
  isDeleteRunActive: PropTypes.bool.isRequired,
  onClickDeleteRun: PropTypes.func.isRequired,
  chequeRunResults: PropTypes.object.isRequired,
  chequeRunError: PropTypes.object.isRequired,
};

export default ChequeRunModalContentLocked;
