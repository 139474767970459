import { cleanStringField } from 'js/utilities/strings';
import { formatDate } from 'js/utilities/dates';

export const getReportsFolderUrl = (folderId) => {
  return `/Reports/folders/${folderId}`;
};

export const cleanReport = (report = {}) => ({
  ...report,
  reportName: cleanStringField(report.reportName),
  filter: cleanStringField(report.filter),
});

export const cleanReports = (reports = []) => reports.map(cleanReport);

export const cleanReportFolder = (folder = {}) => {
  const { name, reports = [] } = folder;
  const owner = folder.owner || {};
  return {
    ...folder,
    name: cleanStringField(name),
    owner: {
      ...owner,
      firstname: cleanStringField(owner.firstname),
      lastname: cleanStringField(owner.lastname),
    },
    reports: cleanReports(reports),
  };
};

export const cleanReportFolders = (folders = []) =>
  folders.map(cleanReportFolder);

export const extensionForReportContentType = (contentType = '') => {
  switch (contentType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/xml':
      return 'xml';
    case 'application/rtf':
      return 'rtf';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.ms-excel':
      return 'xls';
    default:
      return contentType.split('/').pop();
  }
};

export const filenameForReport = (report = {}, contentType = '') => {
  const { reportName = '' } = report;
  const filename = reportName.trim().replace(/\s+/, '_');
  const date = formatDate(new Date(), 'yyyy-MM-dd-h_mma');
  const extension = extensionForReportContentType(contentType);
  return `${filename}-${date}.${extension}`;
};
