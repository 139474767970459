import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, FontAwesomeIcon } from 'js/components/design-system';
import { defaultGetIndexOfSortOption } from './functions';
import { onSelectEffect } from './effects';

const SortMenu = (props) => {
  const {
    className,
    sortOptions,
    getIndexOfSortOption,
    field,
    direction,
    onSelect,
    disabled,
  } = props;
  const { t } = useTranslation();

  return (
    <DropdownMenu
      className={classnames('sort-menu', className)}
      options={sortOptions.map(({ i18nKey, icon }) => ({
        content: (
          <Fragment>
            <FontAwesomeIcon icon={icon} /> {t(i18nKey)}
          </Fragment>
        ),
        onSelect: onSelectEffect({ sortOptions, onSelect }),
      }))}
      selectedOptionIndex={getIndexOfSortOption(sortOptions, field, direction)}
      disabled={disabled}
    />
  );
};

SortMenu.defaultProps = {
  getIndexOfSortOption: defaultGetIndexOfSortOption,
};

SortMenu.propTypes = {
  className: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
      i18nKey: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
    })
  ).isRequired,
  getIndexOfSortOption: PropTypes.func,
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SortMenu;
