import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props) => {
  const { className, ...iconProps } = props;
  return (
    <FontAwesomeIcon
      className={classnames('fa-icon', className)}
      {...iconProps}
    />
  );
};

Icon.propTypes = {
  ...FontAwesomeIcon.propTypes,
  className: PropTypes.string,
};

export default Icon;
