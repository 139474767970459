import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import ErrorPage from './error';

const Error403 = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      className="error-403"
      title={t('components.Error403.title')}
      heading={t('components.Error403.heading')}
      icon={<FontAwesomeIcon icon={faMinusCircle} />}
      content={
        <Fragment>
          <p>{t('components.Error403.messageLine1')}</p>
          <p>{t('components.Error403.messageLine2')}</p>
        </Fragment>
      }
    />
  );
};

export default Error403;
