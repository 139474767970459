import { isEmpty } from 'js/utilities/validation';
import { impliedGroupsForUserAccessLevel, matchAnyGroup } from './groups';

export const loadGroupsEffect = (options = {}) => {
  const { hasLoadedUser, currentUser, setGroups, setHasLoadedGroups } = options;
  return () => {
    if (!hasLoadedUser) {
      return;
    }

    let groups = [];

    if (!isEmpty(currentUser) && !isEmpty(currentUser.userSecurity)) {
      const {
        userSecurity: { accessLevel },
      } = currentUser;
      groups = impliedGroupsForUserAccessLevel(accessLevel);
    }

    setGroups(groups);
    setHasLoadedGroups(true);
  };
};

export const matchAnyGroupEffect = (options = {}) => {
  const { groups = [] } = options;
  return (permittedGroups = []) => matchAnyGroup(groups, permittedGroups);
};

export const hasMatchingGroupEffect = (options = {}) => {
  const {
    permittedGroups,
    hasLoadedGroups,
    matchAnyGroup,
    setHasMatchingGroup,
    setHasInitialized,
  } = options;
  return () => {
    if (hasLoadedGroups) {
      const hasMatch = matchAnyGroup(permittedGroups);
      setHasMatchingGroup(hasMatch);
      setHasInitialized(true);
    }
  };
};

export const hasNamedPermissionEffect = (options = {}) => {
  const { namedPermissions = {}, hasLoadedGroups, matchAnyGroup } = options;
  return (name) => {
    if (!name || !hasLoadedGroups) {
      return false;
    }

    const groups = namedPermissions[name];

    if (!isEmpty(groups)) {
      return matchAnyGroup(groups);
    } else {
      return false;
    }
  };
};
