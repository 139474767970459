import {
  getEmptyLawyerType,
  cleanLawyerType,
  cleanLawyerTypes,
} from 'js/utilities/lawyer-types';
import { scheduleScrollToLastCardInList } from './functions';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const setEditingLawyerTypeEffect = (options = {}) => {
  const { lawyerType, setEditingLawyerType } = options;
  return () => setEditingLawyerType({ ...lawyerType });
};

export const resetEditingLawyerTypeEffect = (options = {}) =>
  setEditingLawyerTypeEffect({ ...options, lawyerType: {} });

export const setAddingLawyerTypeEffect = (options = {}) => {
  const { setAddingLawyerType, listElementRef } = options;
  return () => {
    const { current: listElement } = listElementRef;
    if (listElement) {
      scheduleScrollToLastCardInList(listElement);
      setAddingLawyerType(getEmptyLawyerType());
    }
  };
};

export const resetAddingLawyerTypeEffect = (options = {}) => {
  const { setAddingLawyerType } = options;
  return () => setAddingLawyerType({});
};

export const updateLawyerTypeEffect = (options = {}) => {
  const { allowUpdate, lawyerType, setLawyerType } = options;
  return (name, value) => {
    if (!allowUpdate) {
      return;
    }
    const nextLawyerType = { ...lawyerType, [name]: value };
    setLawyerType(nextLawyerType);
  };
};

export const getLawyerTypesAndOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setLawyerTypes,
    setAccountOptions,
    getLawyerTypesAbortControllerRef,
  } = options;
  return async () => {
    setActive(true);

    rotateAbortControllerRef(getLawyerTypesAbortControllerRef);
    const { signal } = getLawyerTypesAbortControllerRef.current;

    try {
      const lawyerTypesUrl = '/Admin/LawyerTypes';
      const lawyerTypesRequest = api.getJson(
        lawyerTypesUrl,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerTypes.getLawyerTypesRequestError'),
            },
          },
        }
      );

      const accountOptionsUrl = '/Admin/LawyerTypes/GLAccts';
      const accountOptionsRequest = api.getJson(
        accountOptionsUrl,
        {},
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.LawyerTypes.getLawyerTypeOptionsRequestError'
              ),
            },
          },
        }
      );

      const [
        { json: lawyerTypesJson = {} },
        { json: accountOptionsJson = [] },
      ] = await Promise.all([lawyerTypesRequest, accountOptionsRequest]);

      const { lawyerType: receivedLawyerTypes = [] } = lawyerTypesJson;
      const cleanedLawyerTypes = cleanLawyerTypes(receivedLawyerTypes);

      setLawyerTypes(cleanedLawyerTypes);
      setAccountOptions(accountOptionsJson);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const patchLawyerTypeEffect = (options = {}) => {
  const {
    t,
    api,
    editingLawyerType,
    lawyerTypes,
    setLawyerTypes,
    setSaving,
    onComplete,
  } = options;

  return async () => {
    setSaving(true);

    try {
      const { code } = editingLawyerType;
      const url = `/Admin/LawyerTypes/${code}`;
      const body = editingLawyerType;

      const { json = {} } = await api.patchJson(
        url,
        { body },
        {
          success: {
            context: {
              message: t('components.LawyerTypes.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerTypes.patchRequestError'),
            },
          },
        }
      );

      // Replace the edited type in the lawyer types
      // array rather than reloading everything.
      const cleanedLawyerType = cleanLawyerType(json);
      const nextLawyerTypes = lawyerTypes.map((lawyerType) => {
        const nextLawyerType =
          lawyerType.code === cleanedLawyerType.code
            ? cleanedLawyerType
            : lawyerType;
        return { ...nextLawyerType };
      });

      setLawyerTypes(nextLawyerTypes);
      setSaving(false);
      onComplete();
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };
};

export const postLawyerTypeEffect = (options = {}) => {
  const {
    t,
    api,
    addingLawyerType,
    lawyerTypes,
    setLawyerTypes,
    setSaving,
    onComplete,
  } = options;

  return async () => {
    setSaving(true);

    const url = `/Admin/LawyerTypes`;
    const body = { lawyerType: [addingLawyerType] };

    try {
      const { json = {} } = await api.postJson(
        url,
        { body },
        {
          success: {
            context: {
              message: t('components.LawyerTypes.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerTypes.postRequestError'),
            },
          },
        }
      );

      const { lawyerType: receivedLawyerTypes = [] } = json;
      const cleanedLawyerTypes = cleanLawyerTypes(receivedLawyerTypes);
      const [addedLawyerType = {}] = cleanedLawyerTypes;

      // Append the added type to the lawyer types
      // array rather than reloading everything.
      setLawyerTypes([...lawyerTypes, addedLawyerType]);
      setSaving(false);
      onComplete();
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };
};
