import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, InputHelp } from 'js/components/design-system';
import {
  createCaseNumberMaskEffect,
  onChangeEffect,
  onKeyDownEffect,
} from './effects';

const CaseNumberInput = (props) => {
  const { t } = useTranslation();
  const caseNumberMaskRef = useRef(null);
  const {
    className,
    inputRef,
    inputName,
    value,
    isValid,
    onChange,
    onBlur,
    onConfirm,
    onReset,
  } = props;

  useEffect(createCaseNumberMaskEffect({ caseNumberMaskRef }), []);

  return (
    <div className={classnames('case-number-input-container', className)}>
      <label htmlFor="case-number-input">
        {t('components.controls.CaseNumberInput.label')}
      </label>
      <InputHelp
        validationContent={t('components.controls.CaseNumberInput.validation')}
        invalid={!isValid}
      >
        <div className="case-number-input-group">
          <button className="case-number-input-reset" onClick={onReset}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <input
            ref={inputRef}
            type="text"
            id="case-number-input"
            className="case-number-input"
            autoComplete="off"
            name={inputName}
            value={value}
            onChange={onChangeEffect({ caseNumberMaskRef, onChange })}
            onBlur={onBlur}
            onKeyDown={onKeyDownEffect({ onConfirm })}
          />
          <button
            className="button button-highlight case-number-input-confirm"
            onClick={onConfirm}
          >
            {t('components.controls.CaseNumberInput.confirm')}
          </button>
        </div>
      </InputHelp>
    </div>
  );
};

CaseNumberInput.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.object,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CaseNumberInput;
