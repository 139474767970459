import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '../../components/icons';
import FABAction from './fab-action';
import FABActionLink from './fab-action-link';
import {
  onClickActionEffect,
  onContainerTransitionEndEffect,
  manageContainerTransitionListenerEffect,
  updateActionsPositionEffect,
} from './effects';
import {
  actionPropTypes,
  actionLinkPropTypes,
  getActionsHeight,
  getActionsBottomPosition,
  getAccessoryIcon,
  isActionLink,
} from './functions';

const FAB = (props) => {
  const { className, actions = [] } = props;
  const [expanded, setExpanded] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [height, setHeight] = useState(
    getActionsHeight(expanded, actions.length)
  );
  const [bottom, setBottom] = useState(
    getActionsBottomPosition(expanded, actions.length)
  );
  const containerRef = useRef(null);

  useEffect(
    manageContainerTransitionListenerEffect({
      containerRef,
      onTransitionEnd: onContainerTransitionEndEffect({
        containerRef,
        setAnimated,
      }),
    }),
    []
  );

  useEffect(
    updateActionsPositionEffect({
      actions,
      expanded,
      shouldAnimate: true,
      setAnimated,
      setHeight,
      setBottom,
    }),
    [expanded]
  );

  useEffect(
    updateActionsPositionEffect({
      actions,
      expanded,
      shouldAnimate: false,
      setAnimated,
      setHeight,
      setBottom,
    }),
    [actions.length]
  );

  return (
    <div
      className={classnames('fab', className)}
      style={{ height, transitionProperty: animated ? 'height' : 'none' }}
      ref={containerRef}
    >
      <div
        className="fab-actions"
        style={{ bottom, transitionProperty: animated ? 'bottom' : 'none' }}
      >
        {actions.map((action, idx) => {
          const onClick = onClickActionEffect({
            expanded,
            setExpanded,
            onClick: action.onClick,
          });

          const accessory =
            idx === 0 && actions.length > 1 ? (
              <FontAwesomeIcon icon={getAccessoryIcon(expanded)} />
            ) : null;

          const tabIndex = expanded || idx === 0 ? 0 : -1;

          if (isActionLink(action)) {
            return (
              <FABActionLink
                key={idx}
                action={{ ...action, onClick }}
                tabIndex={tabIndex}
              />
            );
          } else {
            return (
              <FABAction
                key={idx}
                action={{ ...action, onClick }}
                accessory={accessory}
                tabIndex={tabIndex}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

FAB.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([actionPropTypes, actionLinkPropTypes])
  ).isRequired,
};

export default FAB;
