import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';

const DeactivateModal = (props) => {
  const { t } = useTranslation();
  const { title, message, mounted, onClose, onSave } = props;
  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t('components.DeactivateModal.closeButton')}
      onClickCancel={onClose}
      titleText={title}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{title}</h2>
      {message && <p>{message}</p>}
      <div className="button-bar">
        <button className="button-link-appearance" onClick={onClose}>
          {t('components.DeactivateModal.cancelButton')}
        </button>
        <button className="button button-highlight" onClick={onSave}>
          {t('components.DeactivateModal.saveButton')}
        </button>
      </div>
    </Modal>
  );
};

DeactivateModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  mounted: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default DeactivateModal;
