import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView, queryForListPrintView } from 'js/components/print';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'js/utilities/dates';
import { companiesListPrintRequestEffect } from './effects';
import { queryForCompaniesList, getCompanyPrintAddress } from './functions';

const CompaniesListPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const { search = '' } = useLocation();
  const query = queryForListPrintView(queryForCompaniesList(search));

  return (
    <ListPrintView
      request={companiesListPrintRequestEffect({ api, query })}
      renderTableHeaders={() => (
        <tr>
          <th>{t('components.CompaniesListPrintView.columns.code')}</th>
          <th>{t('components.CompaniesListPrintView.columns.name')}</th>
          <th>{t('components.CompaniesListPrintView.columns.address')}</th>
          <th>{t('components.CompaniesListPrintView.columns.phone')}</th>
          <th>{t('components.CompaniesListPrintView.columns.fax')}</th>
          <th>{t('components.CompaniesListPrintView.columns.benefitStart')}</th>
          <th>{t('components.CompaniesListPrintView.columns.benefitEnd')}</th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.companyCode}>
          <td>{item.companyCode}</td>
          <td>{item.companyName}</td>
          <td
            dangerouslySetInnerHTML={{ __html: getCompanyPrintAddress(item) }}
          />
          <td>
            <div>{item.coPhone1}</div>
            {item.coPhone2 && <div>{item.coPhone2}</div>}
          </td>
          <td>{item.coFax}</td>
          <td>{formatDate(item.coBenstrt)}</td>
          <td>{formatDate(item.coBenend)}</td>
        </tr>
      )}
      emptyStateTitle={t('components.CompaniesListPrintView.emptyStateTitle')}
      headerProps={{
        title: t('components.CompaniesListPrintView.title'),
        heading: t('components.CompaniesListPrintView.heading'),
        printOnly: false,
      }}
      displayFooter={false}
    />
  );
};

export default CompaniesListPrintView;
