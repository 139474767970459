import PropTypes from 'prop-types';

export const sharedPaginationPropTypes = {
  className: PropTypes.string,
  surroundingPages: PropTypes.number,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPageNumber: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  previousButtonContent: PropTypes.node,
  nextButtonContent: PropTypes.node,
  lowRangeSpacer: PropTypes.node,
  highRangeSpacer: PropTypes.node,
};

export const calculatePageRange = (
  currentPage = 1,
  surroundingPages = 1,
  totalPages = 0
) => {
  const firstPage = 1;
  const inset = surroundingPages * 2;
  const pageRangeStartMin = totalPages - inset;
  const pageRangeEndMax = firstPage + inset;
  const pageRangeStart = Math.min(
    currentPage - surroundingPages,
    pageRangeStartMin
  );
  const pageRangeEnd = Math.max(
    currentPage + surroundingPages,
    pageRangeEndMax
  );
  const pageRangeLength = pageRangeEnd - pageRangeStart + 1;
  const pageRange = [...new Array(pageRangeLength)]
    .map((na, idx) => idx + pageRangeStart)
    .filter((num) => num > firstPage && num < totalPages);
  return pageRange;
};

export const pageNumberFromOffsetAndLimit = (offset = 0, limit = 1) => {
  return Math.floor(offset / limit) + 1;
};

export const offsetFromPageNumberAndLimit = (pageNumber = 1, limit = 1) => {
  return (pageNumber - 1) * limit;
};

export const totalPagesFromTotalResults = (totalResults = 0, limit = 1) => {
  if (totalResults > 0) {
    return Math.ceil(totalResults / limit);
  } else {
    return 0;
  }
};
