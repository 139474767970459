import React from 'react';
import {
  faPrint,
  faFile,
  faBan,
  faCopy,
  faThumbsDown,
  faUndo,
  faFolderOpen,
  faShuffle,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { caseStatuses } from 'js/utilities/cases';
import { patchCaseStatusEffect, reopenCaseEffect } from './effects';

export const getPrintActions = (options = {}) => {
  const { t, caseNumber, disabled, ntpPermitted, cifPermitted } = options;
  const actions = [
    {
      icon: <FontAwesomeIcon icon={faPrint} />,
      activityIcon: null,
      title: t('common.print'),
      disabled,
    },
  ];

  if (ntpPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faFile} />,
      title: t('components.CasePage.fabs.ntp'),
      linkTo: routePathReplacingParams(routePaths.printSingleNTP, {
        cif: encodeURIComponent(caseNumber),
      }),
      // Leaving the following lines commented in case we decide to open print views in a new window:
      // linkHref: routePathReplacingParams(routePaths.printSingleNTP, {
      //   cif: encodeURIComponent(caseNumber),
      // }),
      // linkProps: { target: '_blank', rel: 'noreferrer noopener' },
      disabled,
    });
  }

  if (cifPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faFile} />,
      title: t('components.CasePage.fabs.cif'),

      linkTo: routePathReplacingParams(routePaths.printSingleCIF, {
        cif: encodeURIComponent(caseNumber),
      }),
      // Leaving the following lines commented in case we decide to open print views in a new window:
      // linkHref: routePathReplacingParams(routePaths.printSingleCIF, {
      //   cif: encodeURIComponent(caseNumber),
      // }),
      // linkProps: { target: '_blank', rel: 'noreferrer noopener' },
      disabled,
    });
  }

  return actions;
};

export const getCaseActions = (options = {}) => {
  const {
    t,
    caseNumber,
    caseStatus,
    disabled,
    copyPermitted,
    reassignPermitted,
    correctionPermitted,
    reopenPermitted,
    denyPermitted,
    voidPermitted,
    patchCaseStatusOptions = {},
    setNextCaseStatus,
  } = options;

  const isVoid = caseStatus === caseStatuses.void;
  const isClosed = caseStatus === caseStatuses.closed;
  const isDenied = caseStatus === caseStatuses.denied;
  const actions = [
    {
      icon: <FontAwesomeIcon icon={faFile} />,
      activityIcon: null,
      title: t('components.CasePage.fabs.actions'),
      disabled,
    },
  ];

  if (!isClosed && !isDenied && !isVoid) {
    actions.push({
      icon: <FontAwesomeIcon icon={faFile} />,
      activityIcon: null,
      title: t('components.CasePage.fabs.closeCase'),
      disabled,
      linkTo: `${routePaths.caseClosing}?casenumber=${encodeURIComponent(
        caseNumber
      )}`,
    });
  }

  if (correctionPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
      title: t('components.CasePage.fabs.correct'),
      linkTo: `${routePaths.caseCorrection}?casenumber=${encodeURIComponent(
        caseNumber
      )}`,
      disabled,
    });
  }

  if (copyPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faCopy} />,
      title: t('components.CasePage.fabs.copy'),
      linkTo: routePathReplacingParams(routePaths.copyCase, {
        caseNumber: encodeURIComponent(caseNumber),
      }),
      disabled,
    });
  }

  if (reassignPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faShuffle} />,
      title: t('components.CasePage.fabs.reassign'),
      linkTo: `${routePaths.caseReassignment}?casenumber=${encodeURIComponent(
        caseNumber
      )}`,
      disabled,
    });
  }

  if (isClosed && reopenPermitted) {
    actions.push({
      icon: <FontAwesomeIcon icon={faFolderOpen} />,
      title: t('components.CasePage.fabs.reopen'),
      onClick: reopenCaseEffect(patchCaseStatusOptions),
      disabled,
    });
  }

  if (!isClosed && denyPermitted) {
    actions.push({
      icon: isDenied ? (
        <FontAwesomeIcon icon={faUndo} />
      ) : (
        <FontAwesomeIcon icon={faThumbsDown} />
      ),
      title: isDenied
        ? t('components.CasePage.fabs.undoDeny')
        : t('components.CasePage.fabs.deny'),
      onClick: !isDenied
        ? () => setNextCaseStatus(caseStatuses.denied)
        : patchCaseStatusEffect({
            ...patchCaseStatusOptions,
            caseStatus: caseStatuses.active,
          }),
      disabled,
    });
  }

  if (!isClosed && voidPermitted) {
    actions.push({
      icon: isVoid ? (
        <FontAwesomeIcon icon={faUndo} />
      ) : (
        <FontAwesomeIcon icon={faBan} />
      ),
      title: isVoid
        ? t('components.CasePage.fabs.undoVoid')
        : t('components.CasePage.fabs.void'),
      onClick: !isVoid
        ? () => setNextCaseStatus(caseStatuses.void)
        : patchCaseStatusEffect({
            ...patchCaseStatusOptions,
            caseStatus: caseStatuses.active,
          }),
      disabled,
    });
  }

  return actions;
};
