import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChequeRunPage from '../cheque-run-page';
import ChequeRunView from '../cheque-run-view';
import ChequeRunModal from '../cheque-run-modal';
import {
  reprintChequeEffect,
  resetReprintChequeOnModalUnmountEffect,
  setReprintChequeCountEffect,
  setHasChequesEffect,
} from './effects';
import { queryFromSearchString } from './functions';

const ChequeRunLast = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const [lockSystem, setLockSystem] = useState({});
  const [lockSystemActive, setLockSystemActive] = useState(false);
  const [lastRunModalMounted, setLastRunModalMounted] = useState(false);
  const [hasCheques, setHasCheques] = useState(false);
  const [timestamp, setTimestamp] = useState(0);

  const query = queryFromSearchString(search);
  // reprintChequeCount may be undefined:
  const { reprintChequeCount } = query.params;
  const hasReprintChequeCount = !isNaN(reprintChequeCount);

  const setReprintChequeCount = setReprintChequeCountEffect({
    query,
    history,
  });

  return (
    <ChequeRunPage
      className="cheque-run-last"
      title={t('components.ChequeRunLast.title')}
      onLoadLockSystem={setLockSystem}
      onChangeLockSystemActivity={setLockSystemActive}
      actions={
        <button
          className="button button-highlight cheque-run-action-button"
          disabled={lockSystemActive || !hasCheques}
          onClick={() => setLastRunModalMounted(true)}
        >
          {t('components.ChequeRunLast.start')}
        </button>
      }
    >
      <ChequeRunModal
        title={
          hasReprintChequeCount
            ? t('components.ChequeRunLast.reprintModalTitle')
            : t('components.ChequeRunLast.modalTitle')
        }
        reprintLastRun={!hasReprintChequeCount}
        reprintChequeCount={reprintChequeCount}
        mounted={lastRunModalMounted || hasReprintChequeCount}
        setMounted={resetReprintChequeOnModalUnmountEffect({
          hasReprintChequeCount,
          setReprintChequeCount,
          setLastRunModalMounted,
          setTimestamp,
        })}
        lockSystem={lockSystem}
        lockSystemActive={lockSystemActive}
      />
      <ChequeRunView
        className="cheque-run-last-view"
        timestamp={timestamp}
        endpoint="/Cheque/LastRun"
        onLoadCheques={setHasChequesEffect({ setHasCheques })}
        onClickPrintCheque={reprintChequeEffect({
          setReprintChequeCount,
        })}
      />
    </ChequeRunPage>
  );
};

export default ChequeRunLast;
