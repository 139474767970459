import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../../icons';
import TableSortButton from './sort-button';
import {
  initializeTableEffect,
  onChangeTableSortEffect,
  onClickTableRowEffect,
} from './effects';
import {
  tableSortDirection,
  stringifyColumns,
  stringifyData,
} from './functions';
import { columnPropTypes, sortDirectionPropTypes } from './prop-types';

const SortableTable = (props) => {
  const {
    className,
    columns,
    data,
    initialSortColumnKey,
    initialSortDirection,
    clickableRowAccessory,
    onClickRow,
    printManaged,
  } = props;
  const [sortColumnKey, setSortColumnKey] = useState(initialSortColumnKey);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const areRowsClickable = typeof onClickRow === 'function';

  // Initialize table columns and data once on mount:
  useEffect(
    initializeTableEffect({
      columns,
      data,
      sortColumnKey,
      sortDirection,
      setTableColumns,
      setTableData,
    }),
    []
  );

  // Reinitialize table columns and data only when the props change:
  useEffect(
    initializeTableEffect({
      columns,
      data,
      sortColumnKey,
      sortDirection,
      setTableColumns,
      setTableData,
    }),
    [stringifyColumns(columns), stringifyData(data)]
  );

  return (
    <table
      className={classnames(
        'sortable-table',
        { 'sortable-table-rows-clickable': areRowsClickable },
        className
      )}
      data-print-managed={printManaged}
    >
      <thead>
        <tr>
          {tableColumns.map((column) => (
            <th key={column.key}>
              <TableSortButton
                column={column}
                sortColumnKey={sortColumnKey}
                sortDirection={sortDirection}
                onChangeSort={onChangeTableSortEffect({
                  tableColumns,
                  data,
                  setSortColumnKey,
                  setSortDirection,
                  setTableData,
                })}
              />
            </th>
          ))}
          {areRowsClickable && <th data-screen-only />}
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ row, values }, rowIdx) => (
          <tr key={rowIdx} onClick={onClickTableRowEffect({ row, onClickRow })}>
            {values.map((value, valIdx) => (
              <td key={`${rowIdx}-${valIdx}`}>{value}</td>
            ))}
            {areRowsClickable && (
              <td data-screen-only>
                <div className="sortable-table-row-accessory">
                  {clickableRowAccessory}
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SortableTable.defaultProps = {
  columns: [],
  data: [],
  initialSortColumnKey: '',
  initialSortDirection: tableSortDirection.asc,
  clickableRowAccessory: <FontAwesomeIcon icon={faChevronRight} />,
  printManaged: false,
};

SortableTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(columnPropTypes).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialSortColumnKey: PropTypes.string,
  initialSortDirection: sortDirectionPropTypes,
  clickableRowAccessory: PropTypes.node,
  onClickRow: PropTypes.func,
  printManaged: PropTypes.bool,
};

export default SortableTable;
