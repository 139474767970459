import React, { useContext, useEffect, useState, useRef } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { PromptContext } from 'js/components/prompt';
import { CurrentUserContext } from 'js/components/current-user';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { ClientSummary } from 'js/components/client-summary';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import {
  caseInformationValidationRules,
  caseInformationRequiredKeys,
  caseInformationEditingRequiredKeys,
  returnCanEditCase,
} from 'js/utilities/cases';
import CasePage from '../case-page';
import { CaseProvider, CaseContext } from '../case-context';
import { CaseInformationForm } from '../case-forms';
import { caseInformationNamedPermissions } from '../case-named-permissions';
import {
  onMountEffect,
  getClientEffect,
  updateCaseInformationEffect,
  patchCaseInfoEffect,
  postCaseInfoEffect,
  postCaseCopyEffect,
  saveCaseInformationEffect,
  updateClientCompanyCodeEffect,
  updateClientLocalCodeEffect,
  saveAndCloseModalEffect,
  patchComapnyLocalEffect,
  companyChangedBannerEffect,
} from './effects';
import { getSaveButtonParams } from './functions';
import { FontAwesomeIcon } from 'js/components/design-system';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import UpdateCompanyCodeModal from './update-client-modal';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import { isEmpty } from 'js/utilities/validation';

const CaseInformation = () => {
  const { t } = useTranslation();

  const { caseInfo, setCaseInfo } = useContext(CaseContext);
  const { caseInformation = {} } = caseInfo;
  const {
    clientId: caseClientId,
    clientCode: caseClientCode,
    caseCompany = {},
    localInfo = {},
    officeOpened = '',
    caseStatus = '',
    cif = '',
    legalProblems: caseLegalProblems = [],
  } = caseInformation;

  const { hasLoaded: hasLoadedUser, currentUser = {} } = useContext(
    CurrentUserContext
  );
  const hasUser = hasLoadedUser && !isEmpty(currentUser);

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { prompt } = useContext(PromptContext);
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);

  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseInformation');

  const getClientAbortControllerRef = useRef(null);
  const patchCaseAbortControllerRef = useRef(null);
  const postCaseAbortControllerRef = useRef(null);

  const [redirectTo, setRedirect] = useState('');
  const [active, setActive] = useState(false);
  const [saveActive, setSaveActive] = useState(false);
  const [client, setClient] = useState({});
  const [clientOptions, setClientOptions] = useState({});
  const {
    offices = [],
    legalProblems: clientLegalProblems = [],
  } = clientOptions;
  const [mounted, setMounted] = useState(false);

  const { path, params = {} } = useRouteMatch();
  const isNew = path.includes('new');
  const isCopy = path.includes('copy');

  const caseNumber = decodeURIComponent(params.caseNumber);
  const matchClientId = decodeURIComponent(params.id);
  const matchClientCode = decodeURIComponent(params.clientCode);
  const clientId = isNew ? matchClientId : caseClientId;
  const clientCode = isNew ? matchClientCode : caseClientCode;
  const legalProblems = isNew ? clientLegalProblems : caseLegalProblems;

  const canEditCase = returnCanEditCase({
    officeOpened,
    currentUser,
    hasEditPermission,
    caseStatus,
    adminOnly: false,
    isNew,
    isCopy,
    cif: cif,
  });

  const hasClient = Object.keys(client).length > 0;

  const validateKeys =
    isNew || isCopy
      ? caseInformationRequiredKeys
      : caseInformationEditingRequiredKeys;
  const { isValid } = validate(caseInformation, validateKeys);
  const touchAll = () => {
    return touch(validateKeys);
  };

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getClientAbortControllerRef,
        patchCaseAbortControllerRef,
        postCaseAbortControllerRef,
      ],
      setActive,
      setSaveActive,
    }),
    []
  );

  useEffect(
    getClientEffect({
      t,
      api,
      clientId,
      clientCode,
      setActive,
      setClient,
      setClientOptions,
      getClientAbortControllerRef,
    }),
    [clientId, clientCode]
  );

  useEffect(
    updateClientCompanyCodeEffect({
      caseCompany,
      client,
      setClient,
    }),
    [caseCompany, client]
  );

  const presentCompanyBanner = companyChangedBannerEffect({
    t,
    presentStyledBanner,
    dismissBanner,
    caseInfo,
  });

  useEffect(
    updateClientLocalCodeEffect({
      localInfo,
      client,
      setClient,
    }),
    [localInfo, client]
  );

  const patchEffect = patchCaseInfoEffect({
    t,
    api,
    cache,
    prompt,
    caseInfo,
    isValid,
    touchAll,
    setActive: setSaveActive,
    setCaseInfo,
    resetInteractionCount,
    patchCaseAbortControllerRef,
  });

  const patchLocalCompany = patchComapnyLocalEffect({
    t,
    api,
    cache,
    prompt,
    caseInfo,
    isValid,
    touchAll,
    setActive: setSaveActive,
    setCaseInfo,
    resetInteractionCount,
    patchCaseAbortControllerRef,
    presentCompanyBanner,
  });

  const postEffect = postCaseInfoEffect({
    t,
    api,
    prompt,
    caseInfo,
    client,
    isValid,
    setActive: setSaveActive,
    setRedirect,
    touchAll,
    resetInteractionCount,
    postCaseAbortControllerRef,
  });

  const copyEffect = postCaseCopyEffect({
    t,
    api,
    prompt,
    isValid,
    caseNumber,
    caseInfo,
    client,
    setActive: setSaveActive,
    setRedirect,
    touchAll,
    resetInteractionCount,
    postCaseAbortControllerRef,
  });

  const { saveEffect, buttonText } = getSaveButtonParams({
    t,
    active: saveActive,
    isNew,
    isCopy,
    postEffect,
    patchEffect,
    copyEffect,
  });

  const saveCaseInformation = saveCaseInformationEffect({
    t,
    saveEffect,
    presentStyledBanner,
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <CasePage
      className="case-information"
      title={t('components.CaseInformation.title')}
      actions={
        <button
          className="button button-highlight page-action-button"
          disabled={!canEditCase || saveActive}
          onClick={saveCaseInformation}
        >
          {buttonText}
        </button>
      }
    >
      <NavigationSaveModal
        proceedAfter={async () => await saveCaseInformation()}
        shouldBlockNavigation={() => canEditCase && interactionCount > 0}
      />
      {active && <ActivityIndicatorCard />}
      {mounted && (
        <UpdateCompanyCodeModal
          mounted={mounted}
          onClose={() => setMounted(false)}
          caseCompany={caseCompany}
          clientInfo={client}
          onChange={updateCaseInformationEffect({
            setCaseInfo,
          })}
          onEdit={saveAndCloseModalEffect({
            patchEffect: patchLocalCompany,
            setMounted,
          })}
        />
      )}
      {!active && hasClient && (
        <ClientSummary
          client={client}
          headerAccessory={
            hasUser && (
              // Actions Menu
              <GroupPermissionsGate
                permittedGroups={[userGroups.administrator]}
              >
                <button
                  className="edit-client-button"
                  onClick={() => setMounted(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </GroupPermissionsGate>
            )
          }
        />
      )}
      <form
        onSubmit={(e) => e.preventDefault()}
        onClick={incrementInteractionCount}
      >
        <CaseInformationForm
          formData={caseInformation}
          isNew={isNew}
          isCopy={isCopy}
          formOptions={{ legalProblems, offices }}
          onChange={updateCaseInformationEffect({
            setCaseInfo,
          })}
        />
      </form>
    </CasePage>
  );
};

const CaseInformationWithContext = (props) => (
  <CaseProvider>
    <CaseInformation {...props} />
  </CaseProvider>
);

const CaseInformationWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={caseInformationNamedPermissions}>
    <CaseInformationWithContext {...props} />
  </NamedPermissionsProvider>
);

const ValidatedCaseInformation = (props) => (
  <ValidationProvider rules={caseInformationValidationRules}>
    <CaseInformationWithNamedPermissions {...props} />
  </ValidationProvider>
);

const InteractionTrackingCaseInformation = (props) => (
  <InteractionTrackingProvider>
    <ValidatedCaseInformation {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingCaseInformation;
