import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { scheduleScrollToLastCardInList } from './functions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const updateFeeScheduleEffect = (options = {}) => {
  const { changingFeeSchedule, changeFeeScheduleFunc } = options;
  return (name, value) => {
    const nextFeeSchedule = { ...changingFeeSchedule, [name]: value };
    changeFeeScheduleFunc(nextFeeSchedule);
  };
};

export const getFeeScheduleEffect = (options = {}) => {
  const { t, api, setActive, setFeeSchedules, getAbortControllerRef } = options;
  return async () => {
    const endpoint = `/FeeSchedule`;
    setActive(true);
    rotateAbortControllerRef(getAbortControllerRef);
    const { signal } = getAbortControllerRef.current;
    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.FeeScheduleListPage.searchError'),
            },
          },
        }
      );

      setFeeSchedules(json);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const setFeeScheduleEffect = (options = {}) => {
  const { feeSchedules, setFeeSchedules } = options;
  //used to update an individual FS
  return async (feeSchedule) => {
    let nextSchedules = [...feeSchedules];
    const index = nextSchedules.findIndex((fs) => fs.id === feeSchedule.id);
    if (index !== -1) {
      nextSchedules[index] = feeSchedule;
      setFeeSchedules(nextSchedules);
    }
  };
};

export const setAddingFeeScheduleEffect = (options = {}) => {
  const { setAddingFeeSchedule, listElementRef, baseFeeSchedule } = options;
  return () => {
    const { current: listElement } = listElementRef;
    if (listElement) {
      setAddingFeeSchedule(baseFeeSchedule);
      scheduleScrollToLastCardInList(listElement);
    }
  };
};

export const resetAddingOfficeEffect = (options = {}) => {
  const { setAddingOffice } = options;
  return () => setAddingOffice({});
};

export const postFeeScheduleEffect = (options = {}) => {
  const {
    t,
    api,
    setSaving,
    addingFeeSchedule,
    setAddingFeeSchedule,
    postAbortControllerRef,
    setRedirect,
  } = options;

  return async () => {
    const endpoint = `/FeeSchedule`;

    setSaving(true);

    rotateAbortControllerRef(postAbortControllerRef);
    const { signal } = postAbortControllerRef.current;

    try {
      const { json = {} } = await api.postJson(
        endpoint,
        { signal, body: addingFeeSchedule },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.FeeScheduleListPage.searchError'),
            },
          },
        }
      );

      const { id } = json;
      const nextPagePath = routePathReplacingParams(routePaths.releaseLPCodes, {
        scheduleId: id,
        effectiveDate: 'new',
      });

      setAddingFeeSchedule({});
      setSaving(false);
      setRedirect(nextPagePath);
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const patchFeeScheduleEffect = (options = {}) => {
  const {
    t,
    api,
    setSaving,
    editingFeeSchedule,
    setEditingFeeSchedule,
    patchAbortControllerRef,
    onComplete,
  } = options;

  return async () => {
    const { name, id } = editingFeeSchedule;

    const endpoint = `/FeeSchedule/${id}`;
    setSaving(true);

    rotateAbortControllerRef(patchAbortControllerRef);
    const { signal } = patchAbortControllerRef.current;

    try {
      await api.patchJson(
        endpoint,
        {
          signal,
          body: { name: name },
        },
        {
          success: {
            context: {
              message: t('components.FeeSchedulePage.patchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.FeeSchedulePage.patchError'),
            },
          },
        }
      );

      setEditingFeeSchedule({});
      setSaving(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const deleteFeeScheduleEffect = (options = {}) => {
  const { t, api, setDeleting, deleteAbortControllerRef, onComplete } = options;

  return async (feeSchedule) => {
    const { id } = feeSchedule;

    const endpoint = `/FeeSchedule/${id}`;
    setDeleting(true);

    rotateAbortControllerRef(deleteAbortControllerRef);
    const { signal } = deleteAbortControllerRef.current;

    try {
      await api.deleteJson(
        endpoint,
        {
          signal,
        },
        {
          success: {
            context: {
              message: t('components.FeeSchedulePage.deleteSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.FeeSchedulePage.deleteError'),
            },
          },
        }
      );

      setDeleting(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setDeleting(false);
      }
    }
  };
};

export const completeArchiveEffect = (options = {}) => {
  const { setEditingFeeSchedule, getFeeSchedules } = options;
  return async () => {
    setEditingFeeSchedule({});
    getFeeSchedules();
  };
};

export const archiveFeeScheduleEffect = (options = {}) => {
  const {
    t,
    api,
    setArchiving,
    archiveAbortControllerRef,
    onComplete,
  } = options;
  return async (feeSchedule) => {
    const { activeRelease, id: scheduleId } = feeSchedule;

    const endpoint = `/FeeSchedule/${scheduleId}/release/${activeRelease.effectiveDate}/archive`;

    setArchiving(true);

    rotateAbortControllerRef(archiveAbortControllerRef);
    const { signal } = archiveAbortControllerRef.current;
    try {
      await api.postJson(
        endpoint,
        { signal },
        {
          success: {
            context: {
              message: t('components.FeeSchedulePage.archiveSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.FeeSchedulePage.archiveError'),
            },
          },
        }
      );

      setArchiving(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setArchiving(false);
      }
    }
  };
};
