import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  GroupPermissionsContext,
  userGroups,
} from 'js/components/group-permissions';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import EmptyState from 'js/components/controls/empty-state';
import { PrintFooter } from 'js/components/print';
import ReportsBreadcrumbs from '../breadcrumbs';
import ReportsPrintHeader from '../reports-print-header';
import ReportsFoldersList from '../reports-folders-list';
import ReportsFolderDetail from '../reports-folder-detail';
import {
  onMountEffect,
  onChangeFolderEffect,
  getReportsFoldersEffect,
  previousReportsFoldersEffect,
  nextReportsFoldersEffect,
  reportsFoldersPageNumberEffect,
  reportsFoldersSortEffect,
  reportsFoldersSearchEffect,
  reportsFoldersSearchResetEffect,
  onCreateFolderEffect,
  onUpdateFolderEffect,
  onDeleteFolderEffect,
} from './effects.js';
import { queryForReportsFolders } from './functions';

const ReportsPage = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { matchAnyGroup } = useContext(GroupPermissionsContext);

  const history = useHistory();

  const { search = '' } = useLocation();
  const query = queryForReportsFolders(search);

  const { params = {} } = useRouteMatch();
  const { folderId = '' } = params;
  const hasFolder = !!folderId;

  const getReportsFoldersAbortControllerRef = useRef(null);
  const folderIdRef = useRef(folderId);

  const [isLoadingFolders, setLoadingFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [totalFolders, setTotalFolders] = useState(0);

  const getReportsFolders = getReportsFoldersEffect({
    t,
    api,
    query,
    setLoadingFolders,
    setFolders,
    setTotalFolders,
    getReportsFoldersAbortControllerRef,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [getReportsFoldersAbortControllerRef],
      setLoadingFolders,
    }),
    []
  );

  useEffect(getReportsFolders, [query.search]);

  useEffect(
    onChangeFolderEffect({ folderId, folderIdRef, getReportsFolders }),
    [folderId]
  );

  return (
    <Page
      className="reports-list-page"
      title={t('components.ReportsPage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container ">
        <div className="layout-column">
          <ReportsBreadcrumbs />
        </div>
      </div>

      <ReportsPrintHeader
        heading={t('components.ReportsPage.title')}
        title={t('components.ReportsPage.title')}
      />

      <div className="layout-container reports-folders-list-layout">
        <div className="layout-column">
          <ReportsFoldersList
            query={query}
            folders={folders}
            totalFolders={totalFolders}
            selectedFolderId={folderId}
            isLoadingFolders={isLoadingFolders}
            allowNewFolder={matchAnyGroup([
              userGroups.administrator,
              userGroups.dataEntry,
            ])}
            onClickPrevious={previousReportsFoldersEffect({ history, query })}
            onClickNext={nextReportsFoldersEffect({ history, query })}
            onClickPageNumber={reportsFoldersPageNumberEffect({
              history,
              query,
            })}
            onSearch={reportsFoldersSearchEffect({ history, query })}
            onSearchReset={reportsFoldersSearchResetEffect({ history, query })}
            onSort={reportsFoldersSortEffect({ history, query })}
          />
        </div>
        <div className="layout-column">
          {!hasFolder && (
            <EmptyState
              title={t('components.ReportsPage.emptyStateTitle')}
              subtitle={t('components.ReportsPage.emptyStateSubtitle')}
            />
          )}
          {hasFolder && (
            <ReportsFolderDetail
              query={query}
              folderId={folderId}
              onCreateFolder={onCreateFolderEffect({ history, query })}
              onUpdateFolder={onUpdateFolderEffect({ setFolders })}
              onDeleteFolder={onDeleteFolderEffect({ history, query })}
            />
          )}
        </div>
      </div>

      <PrintFooter />
    </Page>
  );
};

export default ReportsPage;
