import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  InputLabel,
  Card,
  InputHelp,
  Checkbox,
} from 'js/components/design-system';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import { ValidationContext } from 'js/components/validation';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { lawyerAddressRequiredFields } from 'js/utilities/lawyers';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onCheckboxChangeEffect,
} from './effects';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';

const Spacer = () => <div />;

const LawyerAddressForm = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const {
    formData = {},
    onChange,
    isStaffLawyer,
    isBarristerSolicitor,
  } = props;
  const {
    addressSameAsFirm = false,
    address = '',
    address2 = '',
    postalCode = '',
    province = '',
    city = '',
  } = formData;

  const provinces = provinceOptions(t);
  const requiredFields = isStaffLawyer ? [] : lawyerAddressRequiredFields;
  const { invalidFields = [] } = validate(formData, requiredFields);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerInformation');

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className="lawyer-address-form">
      <div className="lawyer-address-form-heading">
        <h2>{t('components.LawyerInformation.LawyerAddressForm.title')}</h2>
        {!isStaffLawyer && !isBarristerSolicitor && (
          <Checkbox
            label={t(
              'components.LawyerInformation.LawyerAddressForm.addressSameAsFirm'
            )}
            name="addressSameAsFirm"
            checked={addressSameAsFirm}
            onChange={onCheckboxChangeEffect({ onChange })}
            disabled={!hasEditPermission}
          />
        )}
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerAddressForm.addressValidation'
          )}
          invalid={isTouched('address') && invalidFields.includes('address')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerAddressForm.address'
            )}
          >
            <input
              type="text"
              name="address"
              autoComplete="address"
              value={address}
              disabled={addressSameAsFirm}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerAddressForm.address2Validation'
          )}
          invalid={isTouched('address2') && invalidFields.includes('address2')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerAddressForm.address2'
            )}
          >
            <input
              name="address2"
              autoComplete="address2"
              type="text"
              value={address2}
              disabled={addressSameAsFirm}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerAddressForm.cityValidation'
          )}
          invalid={isTouched('city') && invalidFields.includes('city')}
        >
          <InputLabel
            content={t('components.LawyerInformation.LawyerAddressForm.city')}
          >
            <input
              type="text"
              name="city"
              autoComplete="city"
              value={city}
              disabled={addressSameAsFirm}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerAddressForm.provinceValidation'
          )}
          invalid={isTouched('province') && invalidFields.includes('province')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerAddressForm.province'
            )}
          >
            <StyledSelect
              value={province}
              name="province"
              disabled={addressSameAsFirm || !hasEditPermission}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {provinces.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerAddressForm.postalCodeValidation'
          )}
          invalid={
            isTouched('postalCode') && invalidFields.includes('postalCode')
          }
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerAddressForm.postalCode'
            )}
          >
            <PostalCodeInput
              name="postalCode"
              placeholder="L6L 6L6"
              autoComplete="postalCode"
              value={postalCode}
              disabled={addressSameAsFirm}
              readOnly={!hasEditPermission}
              onChange={onPostalCodeInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

LawyerAddressForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeMulti: PropTypes.func,
  isStaffLawyer: PropTypes.bool,
  isBarristerSolicitor: PropTypes.bool,
};

export default LawyerAddressForm;
