import { legalProblemCodesBreadcrumbsHistory } from '../list-page/functions';

export const legalProblemCodeBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHistory = legalProblemCodesBreadcrumbsHistory(t);
  const currentContent = isNewPath(pathname)
    ? t('components.LegalProblemCodeDetail.breadcrumbs.new')
    : t('components.LegalProblemCodeDetail.breadcrumbs.details');

  const history = [
    ...prevHistory,
    {
      content: currentContent,
      path: currentPath,
    },
  ];

  return history;
};

export const isNewPath = (pathname) => /\/new$/.test(pathname);
