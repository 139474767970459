import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LabelValuePair = (props) => {
  const { className, label = '', value = '' } = props;
  return (
    <div className={classnames('label-value-pair', className)}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};

LabelValuePair.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.node,
};

export default LabelValuePair;
