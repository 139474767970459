import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PrintHeader } from 'js/components/print';

const StaffPrintHeader = (props) => {
  const { className, ...headerProps } = props;

  return (
    <PrintHeader
      className={classnames('staff-print-header', className)}
      {...headerProps}
    />
  );
};

StaffPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default StaffPrintHeader;
