import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cleanCifData } from './functions';
import { isEmpty } from 'js/utilities/validation';
import classnames from 'classnames';
import { REFERRAL_LP_CODES } from 'js/utilities/legal-problem-codes';

// Placeholder case and client info
// to be removed after integration:
const CIFPrintLayout = ({ cifData }) => {
  const { t } = useTranslation();
  const formData = cleanCifData(cifData, t);

  const SERVICES_PROVIDED = {
    noServiceProvided: '0',
    adviceOnlyPhone: '1',
    adviceOnlyPerson: '2',
    notarizationOnly: '3',
    adviceInPerson: '4',
    officeWorkOnly: '5',
    lawyerOnRecord: '6',
  };

  const PAYEE_TYPES = {
    LAWYER: '1',
    CLIENT: '2',
    OTHER: '3',
  };

  const hasReferralLp = REFERRAL_LP_CODES.includes(formData.legalProblemCode);

  return (
    <div className="print-layout cif-print-layout">
      <div className="print-safe-area cif-print-safe-area">
        {/* Begin Header */}
        <div className="cif-print-header">
          <div className="cif-print-header-content">
            <div className="print-line">
              <span>{t('components.PrintLayouts.CIF.header.title')}</span>
            </div>
            <div className="print-line">
              <h1>
                {t('components.PrintLayouts.CIF.header.heading', {
                  cif: formData.cif,
                })}
              </h1>
            </div>
            <div className="print-line">
              <span>
                {t('components.PrintLayouts.CIF.header.legalProblemCode')}{' '}
                {formData.legalProblem}
              </span>
            </div>
          </div>
          <ul className="unstyled-list cif-print-header-metadata">
            <li className="print-line">
              <span>
                {t('components.PrintLayouts.CIF.header.intakeId')}{' '}
                {formData.intakeId}
              </span>
            </li>
            <li className="print-line">
              <span>
                {t('components.PrintLayouts.CIF.header.dateOpened')}{' '}
                {formData.openDate}
              </span>
            </li>
          </ul>
        </div>
        {/* End Header */}

        <div className="cif-print-gutter" />

        {/* Begin Part A */}
        <div className="cif-print-part-a">
          <div className="cif-print-section-header cif-print-part-a-header">
            <div>{t('components.PrintLayouts.CIF.partA.title')}</div>
            <div>{/* Line */}</div>
          </div>
          <div className="cif-print-part-a-gutter" />
          <div className="cif-print-part-a-participant">
            <div className="cif-print-part-a-participant-primary">
              <div className="print-line">
                <span className="cif-print-part-a-participant-status">
                  {t('components.PrintLayouts.CIF.partA.status')}
                  {!isEmpty(formData.clientStatus) &&
                    ` ${formData.clientStatus} - ${formData.clientStatusDesc}`}
                </span>
              </div>
              <div className="print-line">
                <h2 className="cif-print-part-a-participant-name">
                  {!isEmpty(formData.clientFirstName) &&
                    `${formData.clientPrefix} ${formData.clientFirstName} ${formData.clientLastName}`}
                </h2>
              </div>
              <div className="cif-print-part-a-gutter" />
              <div className="cif-print-part-a-metadata">
                <ul className="unstyled-list">
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.clientId')}{' '}
                      {formData.clientId}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.email')}{' '}
                      {formData.clientEmail}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.phone')}{' '}
                      {formData.clientPhone}
                    </span>
                  </li>
                  <li className="print-line" />
                  <li className="print-line">
                    <span>
                      {!isEmpty(formData.clientAddress) &&
                        `${formData.clientAddress} ${formData.clientAddress2}`}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {!isEmpty(formData.clientAddress) &&
                        `${formData.clientCity}, ${formData.clientProvince} ${formData.clientPostal}`}
                    </span>
                  </li>
                </ul>
                <ul className="unstyled-list">
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.company')}{' '}
                      {formData.clientCompanyCode}
                      {' \u2013 '}
                      {formData.clientCompanyName}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.local')}{' '}
                      {formData.clientLocalCode}
                      {' \u2013 '}
                      {formData.clientLocalName}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.seniorityDate')}{' '}
                      {formData.clientSeniorityDate}
                    </span>
                  </li>
                  <li className="print-line">
                    <span>
                      {t('components.PrintLayouts.CIF.partA.employeeNumber')}{' '}
                      {formData.clientEmployeeNumber}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {formData.dependentExist && (
              <div className="cif-print-part-a-participant-secondary">
                <div className="print-line">
                  <span className="cif-print-part-a-participant-status">
                    {t('components.PrintLayouts.CIF.partA.status')}{' '}
                    {!isEmpty(formData.dependentStatus) &&
                      ` ${formData.dependentStatus} - ${formData.dependentStatusDesc}`}
                  </span>
                </div>
                <div className="print-line">
                  <h2 className="cif-print-part-a-participant-name">
                    {!isEmpty(formData.dependentFirstName) &&
                      `${formData.dependentTitle} ${formData.dependentFirstName} ${formData.dependentLastName}`}
                  </h2>
                </div>
                <div className="cif-print-part-a-gutter" />
                <div className="cif-print-part-a-metadata">
                  <ul className="unstyled-list">
                    <li className="print-line">
                      <span>
                        {t('components.PrintLayouts.CIF.partA.email')}{' '}
                        {formData.dependentEmail}
                      </span>
                    </li>
                    <li className="print-line">
                      <span>
                        {t('components.PrintLayouts.CIF.partA.phone')}{' '}
                        {formData.dependentPhone}
                      </span>
                    </li>
                    <li className="print-line" />
                    <li className="print-line">
                      <span>
                        {!isEmpty(formData.dependentAddress) &&
                          `${formData.dependentAddress} ${formData.dependentAddress2}`}
                      </span>
                    </li>
                    <li className="print-line">
                      <span>
                        {!isEmpty(formData.dependentAddress) &&
                          `${formData.dependentCity}, ${formData.dependentProvince} ${formData.dependentPostal}`}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="cif-print-part-a-gutter" />
          <div className="cif-print-part-a-instructions">
            <h3>
              {t('components.PrintLayouts.CIF.partA.specialInstructions')}
            </h3>
            <p>{formData.specialInstructions}</p>
          </div>
        </div>
        {/* End Part A */}

        <div className="cif-print-gutter" />

        {/* Begin Part B */}
        <div className="cif-print-part-b">
          <div className="cif-print-section-header cif-print-part-b-header">
            <div>{t('components.PrintLayouts.CIF.partB.title')}</div>
            <div>{/* Line */}</div>
          </div>
          <div className="cif-print-part-b-gutter" />

          {formData.isCoop && (
            <div className="cif-print-part-b-metadata">
              <ul className="unstyled-list">
                <li className="print-line">
                  <h2>
                    {!isEmpty(formData.lawyerFirstName) &&
                      `${formData.lawyerFirstName} ${formData.lawyerLastName}`}
                  </h2>
                </li>
                <li className="print-line">
                  <span>
                    {t('components.PrintLayouts.CIF.partB.firm')}{' '}
                    {formData.lawyerFirmName}
                  </span>
                </li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line">
                  <span>
                    {!isEmpty(formData.lawyerAddress) &&
                      `${formData.lawyerAddress} ${formData.lawyerAddress2}`}
                  </span>
                </li>
                <li className="print-line">
                  <span>
                    {!isEmpty(formData.lawyerAddress) &&
                      `${formData.lawyerCity}, ${formData.lawyerProvince} ${formData.lawyerPostal}`}
                  </span>
                </li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line">
                  <span>
                    {t('components.PrintLayouts.CIF.partB.lawyerId')}{' '}
                    {formData.lawyerId}
                  </span>
                </li>
                <li className="print-line">
                  <span>
                    {t('components.PrintLayouts.CIF.partB.phone')}{' '}
                    {formData.lawyerPhone}
                  </span>
                </li>
              </ul>
            </div>
          )}

          {formData.isStaff && (
            <div className="cif-print-part-b-metadata">
              <ul className="unstyled-list">
                <li className="print-line">
                  <h2>
                    {!isEmpty(formData.staffLawyerFirstName) &&
                      `${formData.staffLawyerFirstName} ${formData.staffLawyerLastName}`}
                  </h2>
                </li>
                <li className="print-line">
                  <span>
                    {t('components.PrintLayouts.CIF.partB.staffLawyer', {
                      staffLawyerId: formData.staffLawyerId,
                    })}
                  </span>
                </li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line"></li>
                <li className="print-line"></li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line"></li>
                <li className="print-line"></li>
              </ul>
            </div>
          )}

          {!formData.isCoop && !formData.isStaff && (
            <div className="cif-print-part-b-metadata">
              <ul className="unstyled-list">
                <li className="print-line">
                  <h2>
                    {!formData.isNonCoop &&
                      t('components.PrintLayouts.CIF.partB.noLawyer')}
                    {formData.isNonCoopLawyer &&
                      t('components.PrintLayouts.CIF.partB.nonCoopLawyer')}
                    {formData.isNonCoopNotary &&
                      t('components.PrintLayouts.CIF.partB.nonCoopNotary')}
                    {formData.isOutsideCanadaLawyer &&
                      t('components.PrintLayouts.CIF.partB.outsideCanada')}
                  </h2>
                </li>
                <li className="print-line"></li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line"></li>
                <li className="print-line"></li>
              </ul>
              <ul className="unstyled-list">
                <li className="print-line"></li>
                <li className="print-line"></li>
              </ul>
            </div>
          )}

          <div className="cif-print-part-b-gutter" />
          <div className="cif-print-part-b-lawyer-activity">
            <div className="cif-print-part-b-lawyer-services">
              <div className="cif-print-part-b-lawyer-activity-heading">
                {t(
                  'components.PrintLayouts.CIF.partB.servicesProvided.heading'
                )}
              </div>
              <div className="cif-print-part-b-lawyer-activity-gutter"></div>
              <ul className="unstyled-list">
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.noServiceProvided,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.noServicesProvided'
                    )}
                  </span>
                </li>
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.notarizationOnly,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.notarizationOnly'
                    )}
                  </span>
                </li>
                <li className="cif-print-part-b-lawyer-services-list-gutter" />
                <li>
                  {t(
                    'components.PrintLayouts.CIF.partB.servicesProvided.adviceOnly'
                  )}
                </li>
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.adviceOnlyPhone,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.telephone'
                    )}
                  </span>
                </li>
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.adviceOnlyPerson,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.inPerson'
                    )}
                  </span>
                </li>
                <li className="cif-print-part-b-lawyer-services-list-gutter" />
                <li>
                  {t(
                    'components.PrintLayouts.CIF.partB.servicesProvided.moreThanAdvice'
                  )}
                </li>
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.officeWorkOnly,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.officeWorkOnly'
                    )}
                  </span>
                </li>
                <li>
                  <div
                    className={classnames('cif-print-tickbox', {
                      ticked:
                        formData.servicesProvidedCode ===
                        SERVICES_PROVIDED.lawyerOnRecord,
                    })}
                  />
                  <span>
                    {t(
                      'components.PrintLayouts.CIF.partB.servicesProvided.lawyerOnRecord'
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <div className="cif-print-part-b-legal-problem">
              <div className="cif-print-part-b-lawyer-activity-heading">
                {t(
                  'components.PrintLayouts.CIF.partB.servicesProvided.legalProblemOutcome'
                )}
              </div>
              <ul className="unstyled-list">
                {/* Empty lines */}
                <li />
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </div>
        {/* End Part B */}

        <div className="cif-print-gutter" />

        {/* Begin Part C */}
        <div className="cif-print-part-c">
          <div className="cif-print-section-header cif-print-part-c-header">
            <div>{t('components.PrintLayouts.CIF.partC.title')}</div>
            <div>{/* Line */}</div>
          </div>
          <div className="cif-print-part-c-gutter" />
          <div className="cif-print-part-c-billing-activity">
            <div className="cif-print-part-c-billing-activity-plan">
              <div className="cif-print-part-c-billing-activity-heading">
                {t('components.PrintLayouts.CIF.partC.chargeToPlan.heading')}
              </div>
              <div className="cif-print-part-c-billing-activity-gutter"></div>
              <div className="cif-print-part-c-billing-activity-table">
                {hasReferralLp && (
                  <p className="referralBenefit">
                    {t('components.PrintLayouts.CIF.partC.referralBenefit')}
                  </p>
                )}
                <div className="cif-print-part-c-billing-activity-table-header">
                  <div>{t('components.PrintLayouts.CIF.partC.hours')}</div>
                  <div>{t('components.PrintLayouts.CIF.partC.hourlyRate')}</div>
                  <div>{t('components.PrintLayouts.CIF.partC.amount')}</div>
                </div>
                <div className="cif-print-part-c-billing-activity-table-body">
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div className="text-container">{formData.planHours}</div>
                    <div className="text-container">
                      {formData.lawyerCustomaryFee}
                    </div>
                    <div className="text-container">{formData.planFees}</div>
                  </div>
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div>
                      {t(
                        'components.PrintLayouts.CIF.partC.chargeToPlan.instructions'
                      )}
                    </div>
                    <div>{t('components.PrintLayouts.CIF.partC.tax')}</div>
                    <div className="text-container">{formData.planTaxes}</div>
                  </div>
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div>{/* Space */}</div>
                    <div>{t('components.PrintLayouts.CIF.partC.total')}</div>
                    <div className="text-container">
                      {/* Space */}
                      {formData.planTotal}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cif-print-part-c-billing-activity-client">
              <div className="cif-print-part-c-billing-activity-heading">
                {t('components.PrintLayouts.CIF.partC.chargeToClient.heading')}
              </div>
              <div className="cif-print-part-c-billing-activity-gutter"></div>
              <div className="cif-print-part-c-billing-activity-table">
                <div className="cif-print-part-c-billing-activity-table-header">
                  <div>{t('components.PrintLayouts.CIF.partC.hours')}</div>
                  <div>{t('components.PrintLayouts.CIF.partC.hourlyRate')}</div>
                  <div>{t('components.PrintLayouts.CIF.partC.amount')}</div>
                </div>
                <div className="cif-print-part-c-billing-activity-table-body">
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div className="text-container">{formData.clientHours}</div>
                    <div className="text-container">
                      {formData.lawyerCustomaryFee}
                    </div>
                    <div className="text-container">{formData.clientFees}</div>
                  </div>
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div>
                      {t(
                        'components.PrintLayouts.CIF.partC.chargeToClient.instructions'
                      )}
                    </div>
                    <div>{t('components.PrintLayouts.CIF.partC.tax')}</div>
                    <div>{/* Space */}</div>
                  </div>
                  <div className="cif-print-part-c-billing-activity-table-row">
                    <div>{/* Space */}</div>
                    <div>{t('components.PrintLayouts.CIF.partC.total')}</div>
                    <div className="text-container">{formData.clientFees}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Part C */}

        {/* Begin Part D */}
        <div className="cif-print-part-d">
          <div className="cif-print-section-header cif-print-part-d-header">
            <div>{t('components.PrintLayouts.CIF.partD.title')}</div>
          </div>
          <div className="cif-print-part-d-gutter" />
          <div className="cif-print-part-d-content">
            <div className="cif-print-part-d-content-details">
              <div className="cif-print-part-d-content-details-gutter" />
              <div className="cif-print-part-d-content-details-row">
                <div className="cif-print-part-d-content-details-field">
                  <div>
                    {t('components.PrintLayouts.CIF.partD.transferDate')}
                  </div>
                  {formData.reTransferDate ? (
                    <div>{formData.reTransferDate}</div>
                  ) : (
                    <div className="cif-print-date-placeholder">
                      <span>/</span>
                      <span>/</span>
                    </div>
                  )}
                </div>
                <div className="cif-print-part-d-content-details-field">
                  <div>
                    {t('components.PrintLayouts.CIF.partD.invoiceDate')}
                  </div>
                  {formData.lawyerInvoiceDate ? (
                    <div>{formData.lawyerInvoiceDate}</div>
                  ) : (
                    <div className="cif-print-date-placeholder">
                      <span>/</span>
                      <span>/</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="cif-print-part-d-content-details-gutter" />
              <div className="cif-print-part-d-content-details-row">
                <div className="cif-print-part-d-content-details-field">
                  <div>{t('components.PrintLayouts.CIF.partD.planTotal')}</div>
                  <div>{/* Line */}</div>
                </div>
                <div className="cif-print-part-d-content-details-field">
                  <div>{t('components.PrintLayouts.CIF.partD.csqSent')}</div>
                  <div>{/* Line */}</div>
                </div>
              </div>
              <div className="cif-print-part-d-content-details-gutter" />
              <div className="cif-print-part-d-content-details-row">
                <div className="cif-print-part-d-content-details-field">
                  <div>
                    {t('components.PrintLayouts.CIF.partD.referralReason')}
                  </div>
                  <div>
                    {formData.referralReasonCode} -{' '}
                    {formData.referralReasonDesc}
                  </div>
                </div>
                <div className="cif-print-part-d-content-details-tickbox">
                  <div className="cif-print-tickbox" />
                  <div>
                    {t('components.PrintLayouts.CIF.partD.declarationSent')}
                  </div>
                </div>
              </div>
              <div className="cif-print-part-d-content-details-gutter-wide" />
              <div className="cif-print-part-d-content-details-tickbox-row">
                <div>{t('components.PrintLayouts.CIF.partD.cifSentTo')}</div>
                <div className="cif-print-part-d-content-details-tickbox-group">
                  <div>
                    <div
                      className={classnames('cif-print-tickbox', {
                        ticked: formData.sentToLawyer === true,
                      })}
                    />
                    <div>{t('components.PrintLayouts.CIF.partD.lawyer')}</div>
                  </div>
                  <div>
                    <div
                      className={classnames('cif-print-tickbox', {
                        ticked: formData.sentToLawyer === false, //this is often returned as null and is checked off
                      })}
                    />
                    <div>{t('components.PrintLayouts.CIF.partD.client')}</div>
                  </div>
                </div>
              </div>
              <div className="cif-print-part-d-content-details-gutter-wide" />
              <div className="cif-print-part-d-content-details-tickbox-row">
                <div>{t('components.PrintLayouts.CIF.partD.payee')}</div>
                <div className="cif-print-part-d-content-details-tickbox-group">
                  <div>
                    <div
                      className={classnames('cif-print-tickbox', {
                        ticked: formData.payee === PAYEE_TYPES.LAWYER,
                      })}
                    />
                    <div>{t('components.PrintLayouts.CIF.partD.lawyer')}</div>
                  </div>
                  <div>
                    <div
                      className={classnames('cif-print-tickbox', {
                        ticked: formData.payee === PAYEE_TYPES.CLIENT,
                      })}
                    />
                    <div>{t('components.PrintLayouts.CIF.partD.client')}</div>
                  </div>
                  <div>
                    <div
                      className={classnames('cif-print-tickbox', {
                        ticked: formData.payee === PAYEE_TYPES.OTHER,
                      })}
                    />
                    <div>{t('components.PrintLayouts.CIF.partD.lawFirm')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cif-print-part-d-content-certification">
              <div className="cif-print-part-d-content-certification-header">
                {t('components.PrintLayouts.CIF.partD.certification.heading')}
              </div>
              <p className="cif-print-part-d-content-certification-message">
                {t('components.PrintLayouts.CIF.partD.certification.message')}
              </p>
              <div className="cif-print-part-d-content-certification-signature">
                <div>{/* Line */}</div>
                <div>
                  {t(
                    'components.PrintLayouts.CIF.partD.certification.signature'
                  )}
                </div>
              </div>
              <div className="cif-print-part-d-content-certification-gutter" />
              <div className="cif-print-part-d-content-certification-date">
                <div>{/* Line */}</div>
                <div>
                  {t('components.PrintLayouts.CIF.partD.certification.date')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Part D */}
      </div>
    </div>
  );
};

CIFPrintLayout.propTypes = {
  cifData: PropTypes.shape({
    caseInfo: PropTypes.shape({
      caseInformationResponse: PropTypes.shape({
        cif: PropTypes.string,
        legalProblemCode: PropTypes.string,
        legalProblemDesc: PropTypes.string,
        intakeId: PropTypes.string,
        dateOpened: PropTypes.string,
        specialInstructions: PropTypes.string,
      }),
    }),
    primaryInfo: PropTypes.shape({
      clientPersonal: PropTypes.shape({
        clientId: PropTypes.string,
        clientCode: PropTypes.string,
        clientStatusDescription: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        prefix: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      clientEmployment: PropTypes.shape({
        employeeNumber: PropTypes.string,
        seniorityDate: PropTypes.string,
      }),
      clientAddress: PropTypes.shape({
        address: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
      }),
    }),
    dependentInfo: PropTypes.shape({
      clientPersonal: PropTypes.shape({
        clientId: PropTypes.string,
        clientCode: PropTypes.string,
        clientStatusDescription: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        prefix: PropTypes.string,
      }),
      clientEmployment: PropTypes.shape({
        employeeNumber: PropTypes.string,
        seniorityDate: PropTypes.string,
      }),
      clientAddress: PropTypes.shape({
        address: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
      }),
    }),
    lawyerInfo: PropTypes.shape({
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      province: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    staffLawyerInfo: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

export default CIFPrintLayout;
