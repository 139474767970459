import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';
import { cleanClientNotes, sortClientNotes } from 'js/utilities/clients';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingClientNotes } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingClientNotes(false);
    };
  };
};

export const getClientNotesEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    id,
    clientCode,
    query,
    setLoadingClientNotes,
    setClientNotes,
    getClientNotesAbortControllerRef,
  } = options;
  return async (ignoreCache = false) => {
    const endpoint = `/Client/${id}/${clientCode}/notes`;
    const record = cache.get(endpoint);

    if (!record || ignoreCache) {
      setLoadingClientNotes(true);

      rotateAbortControllerRef(getClientNotesAbortControllerRef);
      const { signal } = getClientNotesAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.ClientNotes.requestError'),
              },
            },
          }
        );

        const clientNotes = cleanClientNotes(json);

        let sortedNotes = [...clientNotes];
        if (query) {
          sortedNotes = sortClientNotes(clientNotes, query);
        }

        cache.set(endpoint, clientNotes);
        setClientNotes(sortedNotes);
        setLoadingClientNotes(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingClientNotes(false);
        }
      }
    } else {
      const { value: clientNotes } = record;

      let sortedNotes = [...clientNotes];
      if (query) {
        sortedNotes = sortClientNotes(clientNotes, query);
      }

      setClientNotes(sortedNotes);
    }
  };
};

export const sortClientNotesEffect = (options = {}) => {
  const { query, setClientNotes } = options;
  return () => {
    setClientNotes((clientNotes) => sortClientNotes(clientNotes, query));
  };
};

export const onChangeSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, orderBy: field, order: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const presentNoteModalEffect = (options = {}) => {
  const { setPresentNoteModal, willPresent = false, setEditingNote } = options;
  return () => {
    setPresentNoteModal(willPresent);
    if (!willPresent) {
      setEditingNote({});
    }
  };
};

export const saveNoteEffect = (options = {}) => {
  const { t, api, id, clientCode, postNoteAbortControllerRef } = options;
  return async (text = '') => {
    // Errors will be caught by the note modal:
    rotateAbortControllerRef(postNoteAbortControllerRef);
    const { signal } = postNoteAbortControllerRef.current;

    await api.postJson(
      `/Client/${id}/${clientCode}/notes`,
      {
        body: { text },
        signal,
      },
      {
        success: {
          context: {
            message: t('components.ClientNotes.postRequestSuccess'),
          },
        },
        error: {
          context: {
            message: t('components.ClientNotes.postRequestError'),
          },
        },
      }
    );
  };
};

export const onCompleteNoteEffect = (options = {}) => {
  const { dismissNoteModal, getClientNotes, setEditingNote } = options;
  return async () => {
    setEditingNote({});
    dismissNoteModal();
    await getClientNotes(true);
  };
};

export const setEditNoteEffect = (options = {}) => {
  const { setEditingNote, setPresentNoteModal } = options;
  return async (note = {}) => {
    if (typeof setEditingNote === 'function') {
      setEditingNote(note);
      setPresentNoteModal(true);
    }
  };
};

export const onEditNoteEffect = (options = {}) => {
  const { t, api, id, clientCode, patchNoteAbortControllerRef } = options;
  return async (text, editingNote) => {
    const { id: editingId } = editingNote;
    // Errors will be caught by the note modal:
    rotateAbortControllerRef(patchNoteAbortControllerRef);
    const { signal } = patchNoteAbortControllerRef.current;

    await api.patchJson(
      `/Client/${id}/${clientCode}/notes/${editingId}`,
      {
        body: { ...editingNote, text },
        signal,
      },
      {
        success: {
          context: {
            message: t('components.CaseNotes.patchRequestSuccess'),
          },
        },
        error: {
          context: {
            message: t('components.CaseNotes.patchRequestError'),
          },
        },
      }
    );
  };
};

export const deleteNoteEffect = (options = {}) => {
  const {
    t,
    api,
    noteIdPendingDeletion,
    setNoteIdPendingDeletion,
    setDeleting,
    getClientNotes,
    deleteNoteAbortControllerRef,
    id,
    clientCode,
  } = options;
  return async () => {
    setDeleting(true);

    rotateAbortControllerRef(deleteNoteAbortControllerRef);
    const { signal } = deleteNoteAbortControllerRef.current;

    try {
      await api.deleteJson(
        `/Client/${id}/${clientCode}/notes/${encodeURIComponent(
          noteIdPendingDeletion
        )}`,
        { signal },
        {
          success: {
            context: {
              message: t('components.ClientNotes.deleteRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ClientNotes.deleteRequestError'),
            },
          },
        }
      );
      setDeleting(false);
      setNoteIdPendingDeletion('');
      getClientNotes(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setDeleting(false);
      }
    }
  };
};
