import {
  CLIPBOARD_WRITE_STATUS,
  CLIPBOARD_WRITE_STATUS_RESET_TIME_MS,
} from './functions';

export const clipboardWriteTextEffect = (options = {}) => {
  const { clipboardText, setWriteStatus } = options;
  return async () => {
    try {
      setWriteStatus(CLIPBOARD_WRITE_STATUS.ACTIVE);
      await navigator.clipboard.writeText(clipboardText);
      setWriteStatus(CLIPBOARD_WRITE_STATUS.SUCCESS);
      setTimeout(
        () => setWriteStatus(CLIPBOARD_WRITE_STATUS.UNKNOWN),
        CLIPBOARD_WRITE_STATUS_RESET_TIME_MS
      );
    } catch (error) {
      setWriteStatus(CLIPBOARD_WRITE_STATUS.ERROR);
      setTimeout(
        () => setWriteStatus(CLIPBOARD_WRITE_STATUS.UNKNOWN),
        CLIPBOARD_WRITE_STATUS_RESET_TIME_MS
      );
    }
  };
};
