import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { fileSizeLimitMB, validateFileSizes } from 'js/utilities/files';
import { cleanDocuments } from './functions';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingDocuments,
    setUploadingDocument,
    setDeletingDocument,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingDocuments(false);
      setUploadingDocument(false);
      setDeletingDocument(false);
    };
  };
};

export const getDocumentsEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    setLoadingDocuments,
    setDocuments,
    getDocumentsAbortControllerRef,
  } = options;
  return async () => {
    setLoadingDocuments(true);

    rotateAbortControllerRef(getDocumentsAbortControllerRef);
    const { signal } = getDocumentsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Client/${id}/${clientCode}/documents`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientDocuments.requestError'),
            },
          },
        }
      );

      setDocuments(cleanDocuments(json));
      setLoadingDocuments(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingDocuments(false);
      }
    }
  };
};

export const getDocumentEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    openDocumentUrl,
    getDocumentAbortControllerRef,
  } = options;

  return async ({ id: docId }) => {
    if (!docId) {
      return;
    }

    const url = `/Client/${id}/${clientCode}/documents/${docId}`;

    rotateAbortControllerRef(getDocumentAbortControllerRef);
    const { signal } = getDocumentAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerFeedback.getDocumentRequestError'),
            },
          },
        }
      );

      const { documentUri } = json;
      if (documentUri) {
        openDocumentUrl(documentUri);
      }
    } catch (error) {
      // Do nothing
    }
  };
};

export const postDocumentEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    setUploadingDocument,
    presentStyledBanner,
    postDocumentAbortControllerRef,
    onComplete,
  } = options;
  return async (e) => {
    const { files: filesList } = e.target;
    const files = Array.from(filesList);
    const multiple = files.length > 1;

    // Reset the file input:
    e.target.value = '';

    // Validate that all files respect the file size limit:
    if (!validateFileSizes(files)) {
      presentStyledBanner('error', {
        content: t('common.fileSize', { limit: fileSizeLimitMB }),
      });
      return;
    }

    setUploadingDocument(true);

    rotateAbortControllerRef(postDocumentAbortControllerRef);
    const { signal } = postDocumentAbortControllerRef.current;

    try {
      const documentForms = files.map((file) => {
        const formData = new FormData();
        formData.append('DocumentName', file.name);
        formData.append('Document', file);
        return formData;
      });

      const documentRequests = documentForms.map((form) => {
        return api.fetch(
          `/Client/${id}/${clientCode}/documents`,
          {
            method: 'POST',
            body: form,
            signal,
          },
          {
            success: {
              // Defer success banner until all requests are complete
              bypass: true,
            },
            error: {
              context: {
                message: multiple
                  ? t('components.ClientDocuments.postRequestErrorMultiple')
                  : t('components.ClientDocuments.postRequestError'),
              },
            },
          }
        );
      });

      await Promise.all(documentRequests);

      presentStyledBanner('success', {
        content: multiple
          ? t('components.ClientDocuments.postRequestSuccessMultiple')
          : t('components.ClientDocuments.postRequestSuccess'),
      });

      setUploadingDocument(false);
      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setUploadingDocument(false);
      }
    }
  };
};

export const deleteDocumentEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    documentId,
    setDeletingDocument,
    deleteDocumentAbortControllerRef,
    onComplete,
  } = options;

  return async () => {
    if (!documentId) {
      return;
    }

    setDeletingDocument(true);

    const url = `/Client/${id}/${clientCode}/documents/${documentId}`;

    rotateAbortControllerRef(deleteDocumentAbortControllerRef);
    const { signal } = deleteDocumentAbortControllerRef.current;

    try {
      await api.deleteJson(
        url,
        { signal },
        {
          success: {
            context: {
              message: t(
                'components.ClientDocuments.deleteDocumentRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ClientDocuments.deleteDocumentRequestError'
              ),
            },
          },
        }
      );

      setDeletingDocument(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setDeletingDocument(false);
      }
    }
  };
};

export const onClickUploadFileEffect = (options = {}) => {
  const { fileInputRef } = options;
  return () => {
    const { current: input } = fileInputRef;
    if (input) {
      input.click();
    }
  };
};

export const onClickDeleteDocumentEffect = (options = {}) => {
  const { setDocumentPendingDeletion } = options;
  return (doc) => setDocumentPendingDeletion(doc);
};

export const onConfirmDeleteDocumentEffect = (options = {}) => {
  const { setDocumentPendingDeletion, deleteDocument } = options;
  return async () => {
    await deleteDocument();
    setDocumentPendingDeletion({});
  };
};

export const onCancelDeleteDocumentEffect = (options = {}) => {
  const { setDocumentPendingDeletion } = options;
  return () => setDocumentPendingDeletion({});
};
