import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InsetListItem = (props) => {
  const { className, children } = props;
  return (
    <li className={classnames('inset-list-item', className)}>
      <div className="list-item-inset">
        <div className="list-item-inset-inner" />
      </div>
      {children}
    </li>
  );
};

InsetListItem.propTypes = {
  className: PropTypes.string,
};

export default InsetListItem;
