export const focusPreviousSiblingElement = (element) => {
  const siblings = Array.from(element.parentNode.childNodes);
  if (siblings.length > 0) {
    let prevIndex = siblings.indexOf(element) - 1;
    if (prevIndex < 0) {
      prevIndex = siblings.length - 1;
    }
    siblings[prevIndex].focus();
  }
};

export const focusNextSiblingElement = (element) => {
  const siblings = Array.from(element.parentNode.childNodes);
  if (siblings.length > 0) {
    let nextIndex = siblings.indexOf(element) + 1;
    if (nextIndex >= siblings.length) {
      nextIndex = 0;
    }
    siblings[nextIndex].focus();
  }
};
