import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { caseNumberMaskPattern } from 'js/utilities/masks';
import { createMaskEffect, onChangeMaskedInputEffect } from './effects';

const SearchCaseNumberInput = (props) => {
  const { t } = useTranslation();
  const maskRef = useRef(null);
  const { caseNumber = '', onChange, ...inputProps } = props;

  useEffect(createMaskEffect({ maskRef, mask: caseNumberMaskPattern }), []);

  return (
    <div className="search-case-number-input">
      <input
        {...inputProps}
        type="text"
        name="caseNumber"
        autoComplete="off"
        placeholder={t(
          'components.controls.SearchInput.placeholders.caseNumber'
        )}
        value={caseNumber}
        onChange={onChangeMaskedInputEffect({ maskRef, onChange })}
      />
    </div>
  );
};

SearchCaseNumberInput.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchCaseNumberInput;
