import { cleanClientSummary } from 'js/components/client-summary';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { routePaths } from 'js/components/router/route-paths';
import { cleanCase } from 'js/utilities/cases';
import { cleanClientOptions } from 'js/utilities/clients';
import { queryForParams } from 'js/utilities/params';
import { focusInputRef } from './functions';

export const focusInputEffect = (options = {}) => {
  const { inputRef } = options;
  return () => focusInputRef(inputRef);
};

export const onChangeCaseNumberInputEffect = (options = {}) => {
  const { setInputCaseNumber } = options;
  return (name, value) => setInputCaseNumber(value);
};

export const onChangeInputEffect = (options = {}) => {
  const { setValue } = options;
  return (e) => {
    const { value } = e.target;
    setValue(value);
  };
};

export const onBlurInputEffect = (options = {}) => {
  const { touch } = options;
  return (e) => {
    const { name, value = '' } = e.target;
    if (value.length > 0) {
      touch(name);
    }
  };
};

export const onKeyPressEffect = (options = {}) => {
  const { onEnter } = options;
  return (e) => {
    if (e.key === 'Enter' && typeof onEnter === 'function') {
      onEnter();
    }
  };
};

export const setConfirmedCaseNumberEffect = (options = {}) => {
  const {
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  } = options;
  return () => {
    if (isInputCaseNumberValid) {
      setConfirmedCaseNumber(inputCaseNumber);
      untouchCaseNumberInput();
      history.push(queryForParams({ casenumber: inputCaseNumber }));
    } else {
      touchCaseNumberInput();
    }
  };
};

export const setConfirmedNextClientIdEffect = (options = {}) => {
  const {
    isInputNextClientValid,
    inputNextClientId,
    inputNextClientCode,
    setConfirmedNextClientId,
    setConfirmedNextClientCode,
    touchNextClientInputs,
    untouchNextClientInputs,
  } = options;
  return () => {
    if (isInputNextClientValid) {
      setConfirmedNextClientId(inputNextClientId);
      setConfirmedNextClientCode(inputNextClientCode);
      untouchNextClientInputs();
    } else {
      touchNextClientInputs();
    }
  };
};

export const resetCaseEffect = (options = {}) => {
  const {
    setCaseInfo,
    setClient,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    untouchCaseNumberInput,
    caseNumberInputRef,
  } = options;
  return () => {
    setCaseInfo({});
    setClient({});
    setInputCaseNumber('');
    setConfirmedCaseNumber('');
    untouchCaseNumberInput();
    focusInputRef(caseNumberInputRef);
  };
};

export const resetNewClientIdEffect = (options = {}) => {
  const {
    setNextClient,
    setInputNextClientId,
    setInputNextClientCode,
    setConfirmedNextClientId,
    setConfirmedNextClientCode,
    untouchNextClientInputs,
    nextClientIdInputRef,
  } = options;
  return () => {
    setNextClient({});
    setInputNextClientId('');
    setInputNextClientCode('');
    setConfirmedNextClientId('');
    setConfirmedNextClientCode('');
    untouchNextClientInputs();
    focusInputRef(nextClientIdInputRef);
  };
};

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setCaseInfoActive,
    setClientActive,
    setNextClientActive,
    setCaseReassignmentActive,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setCaseInfoActive(false);
      setClientActive(false);
      setNextClientActive(false);
      setCaseReassignmentActive(false);
    };
  };
};

export const onUpdateCaseNumberEffect = (options = {}) => {
  const {
    caseNumberParam,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    resetCase,
  } = options;

  return () => {
    if (caseNumberParam) {
      setInputCaseNumber(caseNumberParam);
      setConfirmedCaseNumber(caseNumberParam);
    } else {
      resetCase();
    }
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setCaseInfoActive,
    setCaseInfo,
    getCaseInfoAbortControllerRef,
  } = options;
  return async () => {
    if (!caseNumber) {
      setCaseInfo({});
      setCaseInfoActive(false);
      return;
    }

    setCaseInfoActive(true);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = `/Case/${encodeURIComponent(caseNumber)}?isClosing=true`;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseReassignment.caseRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      const cleanCaseData = cleanCase(json, true);
      const { caseInformation = {} } = cleanCaseData;
      const relevantCaseData = {
        caseInformation,
      };

      setCaseInfo(relevantCaseData);
      setCaseInfoActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseInfoActive(false);
      }
    }
  };
};

export const getClientEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    setClientActive,
    setClient,
    setClientOptions,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (!clientId || !clientCode) {
      return;
    }

    setClientActive(true);

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal } = getClientAbortControllerRef.current;

    try {
      const url = `/Client/${clientId}/${clientCode}`;
      const { json: clientJson = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseReassignment.clientRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      setClient(cleanClientSummary(clientJson));

      if (typeof setClientOptions === 'function') {
        setClientOptions(cleanClientOptions(clientJson));
      }

      setClientActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setClientActive(false);
      }
    }
  };
};

export const confirmReassignCaseEffect = (options = {}) => {
  const { setConfirmingCaseReassignment } = options;
  return async () => setConfirmingCaseReassignment(true);
};

export const cancelReassignCaseEffect = (options = {}) => {
  const { setConfirmingCaseReassignment } = options;
  return async () => setConfirmingCaseReassignment(false);
};

export const reassignCaseEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    clientId,
    clientCode,
    setCaseReassignmentActive,
    caseReassignmentAbortControllerRef,
    setConfirmingCaseReassignment,
    history,
  } = options;
  return async () => {
    if (!caseNumber || !clientId || !clientCode) {
      return;
    }

    setCaseReassignmentActive(true);

    rotateAbortControllerRef(caseReassignmentAbortControllerRef);
    const { signal } = caseReassignmentAbortControllerRef.current;

    try {
      const [year, officeId, caseId] = caseNumber.split('/');
      const url = `/Case/${year}/${officeId}/${caseId}/reassign`;
      const body = { newClientId: clientId, newClientCode: clientCode };

      await api.postJson(
        url,
        { signal, body },
        {
          success: {
            context: {
              message: t(
                'components.CaseReassignment.caseReassignmentSuccessMessage',
                { caseNumber, clientId, clientCode }
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.CaseReassignment.caseReassignmentRequestError',
                { caseNumber, clientId, clientCode }
              ),
              preventRedirect: true,
            },
          },
        }
      );

      setCaseReassignmentActive(false);
      setConfirmingCaseReassignment(false);

      history.replace(routePaths.caseReassignment);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseReassignmentActive(false);
      }
    }
  };
};
