import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'js/components/modal';
import {
  addBeforeUnloadListenerEffect,
  navigationBlockEffect,
  navigationBlockUnmountEffect,
  onClickCancelEffect,
  onClickDiscardEffect,
  onClickProceedEffect,
} from './effects';
import { onBeforeUnload, executeNavigationAction } from './functions';

const NavigationModal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    shouldBlockNavigation,
    proceedAfter,
    titleText = t('components.NavigationModal.titleText'),
    messageText,
    cancelButtonText = t('components.NavigationModal.cancelButtonText'),
    discardButtonText = t('components.NavigationModal.discardButtonText'),
    proceedButtonText = t('components.NavigationModal.proceedButtonText'),
  } = props;
  const history = useHistory();
  const [isConfirming, setIsConfirming] = useState(false);
  const unblockRef = useRef(() => {});
  const locationRef = useRef(null);
  const actionRef = useRef('');

  useEffect(addBeforeUnloadListenerEffect({ onBeforeUnload }), []);

  useEffect(
    navigationBlockEffect({
      history,
      locationRef,
      actionRef,
      unblockRef,
      setIsConfirming,
      shouldBlockNavigation,
    }),
    [shouldBlockNavigation]
  );

  useEffect(
    navigationBlockUnmountEffect({
      locationRef,
      actionRef,
      unblockRef,
      setIsConfirming,
    }),
    []
  );

  return (
    <Modal
      className={classnames('navigation-modal', className)}
      cancelButtonText={cancelButtonText}
      onClickCancel={onClickCancelEffect({ setIsConfirming })}
      titleText={titleText}
      mounted={isConfirming}
      initialFocus="#navigation-modal-proceed"
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{titleText}</h2>
      {messageText && <p>{messageText}</p>}
      <div className="button-bar">
        <button
          className="button-link-appearance"
          onClick={onClickDiscardEffect({
            history,
            locationRef,
            actionRef,
            unblockRef,
            setIsConfirming,
            executeNavigationAction,
          })}
        >
          {discardButtonText}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          onClick={onClickProceedEffect({
            proceedAfter,
            history,
            locationRef,
            actionRef,
            unblockRef,
            setIsConfirming,
            executeNavigationAction,
          })}
        >
          {proceedButtonText}
        </button>
      </div>
    </Modal>
  );
};

NavigationModal.defaultProps = {
  shouldBlockNavigation: () => false,
  proceedAfter: () => Promise.resolve(),
};

NavigationModal.propTypes = {
  className: PropTypes.string,
  shouldBlockNavigation: PropTypes.func,
  proceedAfter: PropTypes.func,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  discardButtonText: PropTypes.string,
  proceedButtonText: PropTypes.string,
};

export default NavigationModal;
