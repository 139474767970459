import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PrintHeader } from 'js/components/print';

const CasePrintHeader = (props) => {
  const { className, caseInfo = {}, ...headerProps } = props;
  const { caseInformation = {} } = caseInfo;
  const { cif = '' } = caseInformation;

  return (
    <PrintHeader
      className={classnames('case-print-header', className)}
      heading={cif}
      {...headerProps}
    />
  );
};

CasePrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  caseInfo: PropTypes.shape({
    caseInformation: PropTypes.shape({
      cif: PropTypes.string,
    }),
  }).isRequired,
};

export default CasePrintHeader;
