import { isEmpty } from 'js/utilities/validation';

export const cityValidationRules = {
  cityCode: (data, key) => !isEmpty(data[key]) && data[key].length === 1,
  name: (data, key) => !isEmpty(data[key]) && data[key].length <= 30,
};

export const cityRequiredFields = Object.keys(cityValidationRules);

export const getSaveButtonTitle = (t, isNew, isSaving) => {
  if (isNew) {
    return isSaving ? t('common.creating') : t('common.create');
  } else {
    return isSaving ? t('common.saving') : t('common.save');
  }
};
