import React, { Fragment } from 'react';
import { cleanStringField } from 'js/utilities/strings';
import { displayValueForUnionId, unionIdSortPredicates } from '../functions';

export const cleanManagedUnion = (union = {}) => {
  return {
    // IMPORTANT: DO NOT clean/trim the unionId string!
    // Some values are padded with spaces that are
    // required for the values to match in the database.
    // This is dirty but is not changeable at this time.
    // We apologize to future maintainers.
    // The unionId is cleaned by comparableValueForUnionId
    // and displayValueForUnionId, both of which are located
    // in ../functions, so the spaces will not affect sorting
    // or rendering.
    unionId: union.unionId,
    unionName: cleanStringField(union.unionName),
    unionCity: cleanStringField(union.unionCity),
    unionProv: cleanStringField(union.unionProv),
    checked: union.checked === true,
  };
};

export const cleanManagedUnions = (unions = []) => {
  return unions.map(cleanManagedUnion);
};

export const getManagedUnionIds = (unions = []) =>
  unions
    .filter(({ checked }) => checked === true)
    .map(({ unionId }) => unionId);

export const updateUnionsWithManagedUnionIds = (
  unions = [],
  managedUnionIds = []
) => {
  return unions.map((union) => ({
    ...union,
    checked: managedUnionIds.includes(union.unionId),
  }));
};

export const filterUnionsMatchingQuery = (unions = [], query = '') => {
  if (query) {
    const pattern = new RegExp(query, 'gi');
    return unions.filter((union) => {
      return (
        pattern.test(union.unionId) ||
        pattern.test(union.unionName) ||
        pattern.test(union.unionCity)
      );
    });
  } else {
    return unions;
  }
};

export const getManagedUnionColumns = (t, renderCheckboxForRow) => {
  const unionIdColumn = {
    key: 'unionId',
    headerContent: t(
      'components.CompanyDetailsPage.ManageUnionsModal.columns.unionId'
    ),
    // eslint-disable-next-line react/display-name
    get: (key, row) => {
      return (
        <Fragment>
          {renderCheckboxForRow(key, row)}
          <span>{displayValueForUnionId(row[key])}</span>
        </Fragment>
      );
    },
    sort: unionIdSortPredicates,
  };

  const columns = ['unionName', 'unionCity'].map((key) => ({
    key,
    headerContent: t(
      `components.CompanyDetailsPage.ManageUnionsModal.columns.${key}`
    ),
  }));

  return [unionIdColumn, ...columns];
};
