import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { companiesListSortOptions } from './functions';

const CompaniesListSortMenu = (props) => (
  <SortMenu
    className="companies-list-sort-menu"
    sortOptions={companiesListSortOptions}
    {...props}
  />
);

CompaniesListSortMenu.defaultProps = {
  field: 'codes',
  direction: 'asc',
};

CompaniesListSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CompaniesListSortMenu;
