import { formatDateFromISOString } from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';

export const clientSummaryMode = {
  full: 'full',
  minimal: 'minimal',
};

export const cleanClientSummary = (client = {}) => {
  const {
    clientAccount = {},
    clientAddress = {},
    clientEmployment = {},
    clientPersonal = {},
  } = client;
  const { feeScheduleId, feeScheduleName, version } = clientAccount;
  const { address, address2, city, province, postalCode } = clientAddress;
  const {
    company = {},
    local = {},
    employeeNumber,
    seniorityDate,
  } = clientEmployment;
  const {
    clientId,
    clientCode,
    dateBirth,
    clientStatus,
    emailAddress,
    phoneNumber,
    prefix,
    firstName,
    lastName,
  } = clientPersonal;
  const { companyName } = company;
  const { localCode, localName } = local;

  return {
    clientId: cleanStringField(clientId),
    clientCode: cleanStringField(clientCode),
    clientStatus: cleanStringField(clientStatus),
    prefix: cleanStringField(prefix),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    dateOfBirth: cleanStringField(dateBirth),
    employeeNumber: cleanStringField(employeeNumber),
    companyName: cleanStringField(companyName),
    localCode: cleanStringField(localCode),
    localName: cleanStringField(localName),
    feeScheduleId: cleanStringField(feeScheduleId),
    feeScheduleName: cleanStringField(feeScheduleName),
    addressLine1: cleanStringField(address),
    addressLine2: cleanStringField(address2),
    city: cleanStringField(city),
    province: cleanStringField(province),
    postalCode: cleanStringField(postalCode),
    phoneNumber: cleanStringField(phoneNumber),
    emailAddress: cleanStringField(emailAddress),
    seniorityDate: formatDateFromISOString(seniorityDate),
    version: formatDateFromISOString(version),
  };
};
