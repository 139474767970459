import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { contextValueForResources } from './functions';

const FetchContext = createContext({});

const FetchProvider = (props) => {
  const { resources = [], children } = props;
  return (
    <FetchContext.Provider value={contextValueForResources(resources)}>
      {children}
    </FetchContext.Provider>
  );
};

FetchProvider.defaultProps = {
  resources: [],
};

FetchProvider.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      baseUrl: PropTypes.string.isRequired,
      augmentFetchOptions: PropTypes.func,
      defaultSuccessCallback: PropTypes.func,
      defaultFailureCallback: PropTypes.func,
    })
  ).isRequired,
};

export { FetchContext, FetchProvider };
