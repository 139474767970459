export const routePaths = {
  home: '/',
  login: '/login',
  logout: '/logout',
  unknownUser: '/unknown-user',
  search: '/search/:model',
  // Admin:
  adminTools: '/admin-tools',
  systemSettings: '/system-settings',
  lawyerTypes: '/system-settings/lawyer-types',
  staff: '/staff',
  staffPrint: '/staff/print',
  companies: '/companies',
  companiesPrint: '/companies/print',
  company: '/companies/:companyCode',
  newCompany: '/companies/new',
  unions: '/unions',
  unionsPrint: '/unions/print',
  unionDetails: '/unions/:unionNumber',
  lawFirms: '/law-firms',
  lawFirmsPrint: '/law-firms/print',
  feeSchedulesActive: '/fee-schedules/active',
  feeSchedulesArchived: '/fee-schedules/archived',
  releaseLPCodes: '/fee-schedules/:scheduleId/:effectiveDate/lpCopdes',
  releaseCompanies: '/fee-schedules/:scheduleId/:effectiveDate/companies',
  releaseLPCodesCopy: '/fee-schedules/:scheduleId/:effectiveDate/lpCopdes/copy',
  releaseCompaniesCopy:
    '/fee-schedules/:scheduleId/:effectiveDate/companies/copy',

  offices: '/system-settings/offices',
  legalProblemCodes: '/system-settings/legal-problem-codes',
  legalProblemCodesPrint: '/system-settings/legal-problem-codes/print',
  legalProblemCode: '/system-settings/legal-problem-codes/:code',
  newLegalProblemCode: '/system-settings/legal-problem-codes/new',
  provinces: '/system-settings/provinces',
  province: '/system-settings/provinces/:provinceId',
  newProvince: '/system-settings/provinces/new',
  databaseBackups: '/system-settings/database-backups',
  reports: '/reports/folders',
  reportsFolder: '/reports/folders/:folderId',
  newReportsFolder: '/reports/folders/new',
  reportDetail: '/reports/folders/:folderId/contents/:reportId',
  newReport: '/reports/folders/:folderId/contents/new',
  feedbackReports: '/feedback-reports',
  procedures: '/procedures',
  caseClosing: '/procedures/case-closing',
  caseReassignment: '/procedures/case-reassignment',
  caseCorrection: '/procedures/case-correction',
  issueCheque: '/procedures/issue-cheque',
  csqEntry: '/procedures/csq-entry',
  chequeRun: '/procedures/cheque-run',
  chequeRunCurrent: '/procedures/cheque-run/current',
  chequeRunLast: '/procedures/cheque-run/last',
  primaryClientIntegrity: '/procedures/primary-client-integrity',
  //chequeRunHistory: '/procedures/cheque-run/history',
  // Case:
  case: '/cases/:caseNumber',
  newCase: '/clients/:id/:clientCode/new-case',
  caseInformation: '/cases/:caseNumber/information',
  caseLawyer: '/cases/:caseNumber/lawyer',
  caseBilling: '/cases/:caseNumber/billing',
  caseLog: '/cases/:caseNumber/log',
  caseNotes: '/cases/:caseNumber/notes',
  copyCase: '/cases/:caseNumber/copy',
  // Client:
  client: '/clients/:id/:clientCode',
  newClient: '/clients/new',
  newDependent: '/clients/:id/00/new-dependent',
  clientInformation: '/clients/:id/:clientCode/information',
  clientDependents: '/clients/:id/:clientCode/dependents',
  clientDependentOf: '/clients/:id/:clientCode/dependent-of',
  clientCaseHistory: '/clients/:id/:clientCode/case-history',
  clientNotes: '/clients/:id/:clientCode/notes',
  clientDocuments: '/clients/:id/:clientCode/documents',
  clientLog: '/clients/:id/:clientCode/log',
  clientOutstandingBalanceNotes:
    '/clients/:id/:clientCode/outstanding-balance-notes',
  // Lawyer:
  newLawyer: '/lawyers/new',
  lawyer: '/lawyers/:lawyerId',
  lawyerInformation: '/lawyers/:lawyerId/information',
  lawyerPanels: '/lawyers/:lawyerId/panels',
  lawyerFeedback: '/lawyers/:lawyerId/feedback',
  lawyerCaseHistory: '/lawyers/:lawyerId/case-history',
  lawyerPrint: '/lawyers/:lawyerId/print',
  // Law Firm:
  lawFirm: '/law-firms/:firmId',
  lawFirmPrint: '/law-firms/:firmId/print',
  newLawFirm: '/law-firms/new',
  // Print Layouts:
  printSingleCIF: '/print/cif/:cif',
  printMultipleCIF: '/print/cifs/:date',
  printSingleCSQ: '/print/csq/:cif/:language',
  printMultipleCSQ: '/print/csqs/:date',
  printSingleNTP: '/print/ntp/:cif',
  printMultipleNTP: '/print/ntps/:date',
  // Day End:
  dayEnd: '/dayend',
  // Errors:
  error403: '/error/403',
  error404: '/error/404',
  error500: '/error/500',
  search404: '/error/404/:type',
};

export const routePathReplacingParams = (path, params = {}) => {
  return Object.keys(params).reduce((next, key) => {
    const pattern = new RegExp(`:${key}`, 'g');
    const value = params[key];
    return next.replace(pattern, value);
  }, path);
};
