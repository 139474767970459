export default {
  header: {
    title: 'UNIFOR Legal Services Plan',
    heading: 'Client Satisfaction Questionnaire',
    clientNameAddress: 'Client Name & Address:',
  },
  footer: {
    line1: 'Thank you very much for completing this questionnaire!',
    line2:
      'Please return it in the attached stamped, self-addressed envelope as soon as possible.',
  },
  information: {
    lawyerId: 'Lawyer ID:',
    lawyerName: 'Lawyer Name:',
    clientName: 'Client Name:',
    caseNumber: 'Case Number:',
    email: 'Your Email:',
    phone: 'Your Telephone #:',
    legalProblemCode: 'LP Code:',
    contact: 'Please contact me regarding my case',
  },
  questions: {
    1: 'Were you treated courteously by your lawyer?',
    2: 'Were you treated courteously by other persons working in the office?',
    3: 'Did your lawyer clearly explain your legal problem and the stages needed to\nresolve it?',
    4: 'How well did your lawyer keep you informed about what was happening in\nyour case?',
    5: 'Were the questions you asked your lawyer answered to your satisfaction?',
    6: 'Did you think your case was completed within a reasonable time?',
    7: "Were you satisfied with your lawyer's work?",
    8: 'Were you satisfied with the outcome of your case?',
    9: 'Would you want to use this lawyer again the next time you require legal services?',
    10: 'Please write down any other comments that you may have. We welcome any\ncriticisms or praise for the program that you may wish to share with us.',
  },
  answers: {
    general: {
      positive: 'Yes',
      neutral: 'Maybe',
      negative: 'No',
    },
    clarity: {
      positive: 'Very clearly',
      neutral: 'Somewhat confusing',
      negative: 'Not clearly',
    },
    information: {
      positive: 'Very well informed',
      neutral: 'Somewhat well informed',
      negative: 'Not well informed',
    },
    satisfaction: {
      positive: 'Very satisfied',
      neutral: 'Somewhat satisfied',
      negative: 'Not satisfied',
    },
  },
};
