import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanReportFolders } from 'js/utilities/reports';
import { searchStringFromParams } from 'js/utilities/params';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingFolders } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingFolders(false);
    };
  };
};

export const getReportsFoldersEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setLoadingFolders,
    setFolders,
    setTotalFolders,
    getReportsFoldersAbortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }

    setLoadingFolders(true);

    rotateAbortControllerRef(getReportsFoldersAbortControllerRef);
    const { signal } = getReportsFoldersAbortControllerRef.current;

    try {
      const endpoint = `/Reports/folders/${query.search}`;
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.MoveReportModal.getFoldersRequestError'),
            },
          },
        }
      );

      const { items = [], totalItems = 0 } = json;
      const folders = cleanReportFolders(items);

      setFolders(folders);
      setTotalFolders(totalItems);
      setLoadingFolders(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingFolders(false);
      }
    }
  };
};

export const previousReportsFoldersEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};

export const nextReportsFoldersEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};

export const reportsFoldersPageNumberEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};

export const reportsFoldersSortEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, Order: field, Sort: direction };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};

export const reportsFoldersSearchEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};

export const reportsFoldersSearchResetEffect = (options = {}) => {
  const {
    query: { params = {} },
    setSearch,
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    const nextSearch = searchStringFromParams(nextParams);
    setSearch(nextSearch);
  };
};
