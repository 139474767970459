import React, { useContext } from 'react';
import { ValidationContext } from 'js/components/validation';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InputHelp,
  InputLabel,
  StyledSelect,
  DatePicker,
  Card,
} from 'js/components/design-system';
import PhoneInput from 'js/components/controls/phone-input';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';
import { getCompanyFeeScheduleTitle } from 'js//utilities/companies';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onNumberInputChangeEffect,
} from './effects';
import { companyRequiredFields } from './functions';

const Spacer = () => <div />;

const CompanyForm = (props) => {
  const { t } = useTranslation();
  const { formData = {}, feeSchedule = {}, onChange, isNew } = props;
  const provinces = provinceOptions(t);
  const { validate, touch, isTouched } = useContext(ValidationContext);

  const {
    companyCode = '',
    companyName = '',
    coPhone1 = '',
    coPhone2 = '',
    coFax = '',
    coAddr1 = '',
    coAddr2 = '',
    coCity = '',
    coProv = '',
    coPcode = '',
    coBenstrt = null,
    coBenend = null,
  } = formData;

  const {
    scheduleCode = '',
    scheduleName = '',
    versionDate = '',
  } = feeSchedule;

  const { invalidFields = [] } = validate(formData, companyRequiredFields);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  return (
    <Card className="company-form">
      <h2>{t('components.CompanyDetailsPage.formTitle')}</h2>

      {/* FIRST ROW */}
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.companyCodeValidation'
          )}
          invalid={
            isTouched('companyCode') && invalidFields.includes('companyCode')
          }
        >
          <InputLabel content={t('components.CompanyDetailsPage.companyCode')}>
            <input
              type="text"
              name="companyCode"
              value={companyCode}
              readOnly={!isNew}
              onChange={onNumberInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.companyNameValidation'
          )}
          invalid={
            isTouched('companyName') && invalidFields.includes('companyName')
          }
        >
          <InputLabel content={t('components.CompanyDetailsPage.companyName')}>
            <input
              type="text"
              name="companyName"
              value={companyName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.primaryPhoneValidation'
          )}
          invalid={isTouched('coPhone1') && invalidFields.includes('coPhone1')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.primaryPhone')}>
            <PhoneInput
              name="coPhone1"
              value={coPhone1}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.secondaryPhoneValidation'
          )}
          invalid={isTouched('coPhone2') && invalidFields.includes('coPhone2')}
        >
          <InputLabel
            content={t('components.CompanyDetailsPage.secondaryPhone')}
          >
            <PhoneInput
              name="coPhone2"
              value={coPhone2}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.CompanyDetailsPage.faxValidation')}
          invalid={isTouched('coFax') && invalidFields.includes('coFax')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.fax')}>
            <PhoneInput
              name="coFax"
              value={coFax}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>

      {/* SECOND ROW */}
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.addressValidation'
          )}
          invalid={isTouched('coAddr1') && invalidFields.includes('coAddr1')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.address')}>
            <input
              type="text"
              name="coAddr1"
              value={coAddr1}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.address2Validation'
          )}
          invalid={isTouched('coAddr2') && invalidFields.includes('coAddr2')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.address2')}>
            <input
              type="text"
              name="coAddr2"
              value={coAddr2}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.CompanyDetailsPage.cityValidation')}
          invalid={isTouched('coCity') && invalidFields.includes('coCity')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.city')}>
            <input
              type="text"
              name="coCity"
              value={coCity}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.provinceValidation'
          )}
          invalid={isTouched('coProv') && invalidFields.includes('coProv')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.province')}>
            <StyledSelect value={coProv} name="coProv" {...inputEventHandlers}>
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {provinces.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.postalCodeValidation'
          )}
          invalid={isTouched('coPcode') && invalidFields.includes('coPcode')}
        >
          <InputLabel content={t('components.CompanyDetailsPage.postalCode')}>
            <PostalCodeInput
              name="coPcode"
              value={coPcode}
              onChange={onPostalCodeInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>

      {/* THIRD ROW */}
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.benefitStartDateValidation'
          )}
          invalid={
            isTouched('coBenstrt') && invalidFields.includes('coBenstrt')
          }
        >
          <InputLabel
            content={t('components.CompanyDetailsPage.benefitStartDate')}
          >
            <DatePicker
              name="coBenstrt"
              selected={coBenstrt}
              {...datePickerEventHandlers('coBenstrt')}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CompanyDetailsPage.benefitEndDateValidation'
          )}
          invalid={isTouched('coBenend') && invalidFields.includes('coBenend')}
        >
          <InputLabel
            content={t('components.CompanyDetailsPage.benefitEndDate')}
          >
            <DatePicker
              name="coBenend"
              selected={coBenend}
              {...datePickerEventHandlers('coBenend')}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>

      {/* FOURTH ROW */}
      <div className="form-row">
        <InputLabel content={t('components.CompanyDetailsPage.feeSchedule')}>
          <input
            type="text"
            name="feeSchedule"
            value={getCompanyFeeScheduleTitle(scheduleCode, scheduleName)}
            readOnly
          />
        </InputLabel>

        <InputLabel
          content={t('components.CompanyDetailsPage.feeScheduleVersion')}
        >
          <input type="text" name="versionDate" value={versionDate} readOnly />
        </InputLabel>

        <Spacer />
      </div>
    </Card>
  );
};

CompanyForm.propTypes = {
  formData: PropTypes.object.isRequired,
  feeSchedule: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
};

export default CompanyForm;
