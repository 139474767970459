import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import { FetchContext } from 'js/components/fetch';
import { Redirect } from 'react-router-dom';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import CompanyPrintDetails from './company-print-details';
import CompanyPrintHeader from './company-print-header';
import {
  onDetailsMountEffect,
  missingFeeScheduleBannerEffect,
  companyDetailsRequestEffect,
  updateCompanyEffect,
  patchCompanyEffect,
  postCompanyEffect,
  saveCompanyEffect,
  patchActiveCompanyEffect,
} from './effects';
import {
  companyValidationRules,
  companyRequiredFields,
  getSaveButtonTitle,
} from './functions';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { StyledBannerContext } from 'js/components/banner-styled';
import CompanyDetailsBreadcrumbs from './company-details-breadcrumbs';
import { useRouteMatch } from 'react-router-dom';
import CompanyForm from './company-form';
import LinkedUnionsList from './linked-unions';

const CompanyDetailsPage = () => {
  const { t } = useTranslation();
  const abortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const [initalActive, setInitialActive] = useState(false);
  const [initalLoadComplete, setInitialLoadComplete] = useState(false);
  const [saveActive, setSaveActive] = useState(false);
  const [company, setCompany] = useState({});
  const [feeSchedule, setFeeSchedule] = useState({});
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const {
    params: { companyCode },
  } = useRouteMatch();
  const isNew = companyCode === 'new';
  const [redirectPath, setRedirectPath] = useState('');
  const [viewModal, showModal] = useState(false);
  const active = initalActive || saveActive;

  useEffect(
    onDetailsMountEffect({
      abortControllerRefs: [abortControllerRef],
      setInitialActive,
      setSaveActive,
    }),
    []
  );

  useEffect(
    missingFeeScheduleBannerEffect({
      t,
      presentStyledBanner,
      dismissBanner,
      feeSchedule,
    }),
    [feeSchedule.scheduleCode]
  );

  useEffect(
    companyDetailsRequestEffect({
      t,
      api,
      companyCode,
      setInitialActive,
      setInitialLoadComplete,
      setCompany,
      setFeeSchedule,
      setRedirectPath,
      abortControllerRef,
      isNew,
    }),
    [companyCode]
  );

  const { isValid } = validate(company, companyRequiredFields);
  const touchAllFields = () => touch(companyRequiredFields);

  const patchEffect = patchCompanyEffect({
    t,
    api,
    company,
    isValid,
    setCompany,
    setSaveActive,
    touchAllFields,
    resetInteractionCount,
  });

  const postEffect = postCompanyEffect({
    t,
    api,
    company,
    isValid,
    setSaveActive,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
  });

  const onSaveCompany = saveCompanyEffect({
    t,
    saveEffect: isNew ? postEffect : patchEffect,
    presentStyledBanner,
  });

  const { coInact: companyInactive = false } = company;

  const setActiveState = patchActiveCompanyEffect({
    t,
    api,
    setSaveActive,
    companyCode,
    companyInactive: !companyInactive,
    company,
    setCompany,
    showModal,
  });
  const companyTitle = isNew
    ? t('components.CompanyDetailsPage.newTitle')
    : company.companyName;

  return (
    <Page
      className="company-details-page"
      title={t('components.CompanyDetailsPage.title')}
      header={<FixedHeader />}
    >
      <NavigationSaveModal
        proceedAfter={async () => await onSaveCompany()}
        shouldBlockNavigation={() => interactionCount > 0}
      />

      {redirectPath && <Redirect to={redirectPath} />}

      <div className="layout-container ">
        <div className="layout-column">
          <CompanyDetailsBreadcrumbs />
        </div>
      </div>

      <CompanyPrintHeader
        title={t('components.CompanyDetailsPage.subtitle')}
        company={company}
      />

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="company-details-page-heading">
            <div className="company-details-page-heading-left">
              <p>{t('components.CompanyDetailsPage.subtitle')}</p>
              {isNew && <h1>{companyTitle}</h1>}
              {initalLoadComplete && (
                <div>
                  <h1>{companyTitle}</h1>
                  <DeactivateToggle
                    disabled={isNew}
                    isActive={!companyInactive}
                    onActivate={setActiveState}
                    onDeactivate={() => showModal(true)}
                  />
                  <DeactivateModal
                    title={t(
                      'components.CompanyDetailsPage.DeactivateModal.title'
                    )}
                    message={t(
                      'components.CompanyDetailsPage.DeactivateModal.message'
                    )}
                    mounted={viewModal}
                    onClose={() => showModal(false)}
                    onSave={setActiveState}
                    active={active}
                  />
                </div>
              )}
            </div>
            <div className="company-details-page-heading-right">
              {!isNew && (
                <button
                  className="button button-highlight page-action-button"
                  onClick={() => window.print()}
                  disabled={active}
                >
                  {t('common.print')}
                </button>
              )}
              <button
                className="button button-highlight page-action-button"
                onClick={onSaveCompany}
                disabled={active}
              >
                {getSaveButtonTitle(t, isNew, saveActive)}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {initalActive && <ActivityIndicatorCard />}
          {!initalActive && (
            <Fragment>
              <form
                onClick={incrementInteractionCount}
                onSubmit={(e) => e.preventDefault()}
              >
                <CompanyForm
                  formData={company}
                  feeSchedule={feeSchedule}
                  onChange={updateCompanyEffect({ setCompany })}
                  isNew={isNew}
                />
              </form>
              <CompanyPrintDetails
                company={company}
                feeSchedule={feeSchedule}
              />
            </Fragment>
          )}
        </div>
      </div>
      {!isNew && initalLoadComplete && (
        <div className="layout-container  inset-col-1">
          <div className="layout-column">
            <LinkedUnionsList companyCode={companyCode} />
          </div>
        </div>
      )}
    </Page>
  );
};

const ValidatedCompanyDetailsPage = () => {
  return (
    <ValidationProvider rules={{ ...companyValidationRules }}>
      <CompanyDetailsPage />
    </ValidationProvider>
  );
};

const InteractionTrackingCompanyDetailsPage = () => (
  <InteractionTrackingProvider>
    <ValidatedCompanyDetailsPage />
  </InteractionTrackingProvider>
);

export default InteractionTrackingCompanyDetailsPage;
