import React from 'react';
import AriaModal from 'react-aria-modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from 'js/components/design-system';
import { faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Modal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    cancelButtonText = t('components.Modal.cancelButtonText'),
    onClickCancel,
    allowCancel,
    allowBack,
    onClickBack,
    backButtonText,
    children,
    ...modalProps
  } = props;
  return (
    <AriaModal {...modalProps}>
      <div className={classnames('modal', className)}>
        <div className="modal-toolbar">
          {allowBack && onClickBack ? (
            <button className="button-link-appearance" onClick={onClickBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
              {backButtonText}
            </button>
          ) : (
            <div />
          )}
          {allowCancel && (
            <button className="button-link-appearance" onClick={onClickCancel}>
              <FontAwesomeIcon icon={faTimes} />
              {cancelButtonText}
            </button>
          )}
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </AriaModal>
  );
};

Modal.defaultProps = {
  allowCancel: true,
  allowBack: true,
};

Modal.propTypes = {
  ...AriaModal.propTypes,
  className: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onClickCancel: PropTypes.func,
  allowCancel: PropTypes.bool,
  allowBack: PropTypes.bool,
  onClickBack: PropTypes.func,
  backButtonText: PropTypes.string,
};

export default Modal;
