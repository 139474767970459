import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView } from 'js/components/print';
import { lawFirmsLinkedLawyersPrintRequestEffect } from './linked-lawyers/effects';
import { onMountEffect, lawFirmDetailsRequestEffect } from './effects';
import { getLawFirmPrintAddress } from './functions';

const noop = () => {};

const LawFirmDetailsPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const {
    params: { firmId },
  } = useRouteMatch();

  const abortControllerRef = useRef(null);

  const [lawFirm, setLawFirm] = useState(true);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive: noop,
    }),
    []
  );

  const {
    firmName = '',
    primaryPhone = '',
    secondaryPhone = '',
    fax = '',
    email = '',
    inactive = false,
  } = lawFirm;

  return (
    <ListPrintView
      className="law-firm-details-print-view"
      beforeRequest={lawFirmDetailsRequestEffect({
        t,
        api,
        firmId,
        setInitialActive: noop,
        setLawFirm,
        abortControllerRef,
      })}
      request={lawFirmsLinkedLawyersPrintRequestEffect({ api, firmId })}
      renderTableHeaders={() => (
        <tr>
          <th>
            {t(
              'components.LawFirmDetailsPrintView.LinkedLawyers.columns.lawyerId'
            )}
          </th>
          <th>
            {t(
              'components.LawFirmDetailsPrintView.LinkedLawyers.columns.firstName'
            )}
          </th>
          <th>
            {t(
              'components.LawFirmDetailsPrintView.LinkedLawyers.columns.lastName'
            )}
          </th>
          <th>
            {t('components.LawFirmDetailsPrintView.LinkedLawyers.columns.city')}
          </th>
          <th>
            {t(
              'components.LawFirmDetailsPrintView.LinkedLawyers.columns.province'
            )}
          </th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.lawyerId}>
          <td>{item.lawyerId}</td>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.city}</td>
          <td>{item.province}</td>
        </tr>
      )}
      emptyStateTitle={t(
        'components.LawFirmDetailsPrintView.LinkedLawyers.emptyStateTitle'
      )}
      headerProps={{
        className: 'law-firm-details-print-view-header',
        title: t('components.LawFirmDetailsPrintView.title'),
        heading: (
          <Fragment>
            <span>{firmName}</span>
            <span>
              {inactive
                ? t('components.LawFirmDetailsPrintView.status.inactive')
                : t('components.LawFirmDetailsPrintView.status.active')}
            </span>
          </Fragment>
        ),
        printOnly: false,
      }}
      displayFooter={false}
    >
      <Fragment>
        <div className="law-firm-details-print-view-contact">
          <h2>{t('components.LawFirmDetailsPrintView.contact.title')}</h2>
          <div className="law-firm-details-print-view-contact-row">
            <div className="law-firm-details-print-view-contact-item">
              <span>
                {t('components.LawFirmDetailsPrintView.contact.primaryPhone')}
              </span>
              <span>{primaryPhone}</span>
            </div>
            <div className="law-firm-details-print-view-contact-item">
              <span>
                {t('components.LawFirmDetailsPrintView.contact.secondaryPhone')}
              </span>
              <span>{secondaryPhone}</span>
            </div>
            <div className="law-firm-details-print-view-contact-item">
              <span>{t('components.LawFirmDetailsPrintView.contact.fax')}</span>
              <span>{fax}</span>
            </div>
            <div className="law-firm-details-print-view-contact-item">
              <span>
                {t('components.LawFirmDetailsPrintView.contact.email')}
              </span>
              <span>{email}</span>
            </div>
          </div>
          <div className="law-firm-details-print-view-contact-row">
            <div className="law-firm-details-print-view-contact-item">
              <span>
                {t('components.LawFirmDetailsPrintView.contact.address')}
              </span>
              <span>{getLawFirmPrintAddress(lawFirm, true)}</span>
            </div>
          </div>
        </div>

        <h2>{t('components.LawFirmDetailsPrintView.LinkedLawyers.heading')}</h2>
      </Fragment>
    </ListPrintView>
  );
};

export default LawFirmDetailsPrintView;
