import React, { Fragment } from 'react';
import { InactiveTag } from 'js/components/deactivate';
import { routePathForClient, routePathForLawyer } from 'js/utilities/clients';
import SearchResultsClientCard from './card-client';
import SearchResultsLawyerCard from './card-lawyer';

export const cardComponentForModel = (model) => {
  switch (model) {
    case 'client':
      return SearchResultsClientCard;
    case 'lawyer':
      return SearchResultsLawyerCard;
    default:
      return null;
  }
};

export const renderCardHeading = (t, text = '', inactive = false) => (
  <div className="inactive-tag-container">
    <span>{text}</span>
    <InactiveTag inactive={inactive} />
  </div>
);

export const listCardPropsForClient = (t, data = {}, location = {}) => {
  const {
    firstName,
    lastName,
    typeValue,
    companyName,
    unionName,
    inactive = false,
    employeeNumber,
  } = data;

  const heading = t('components.SearchResultsClientCard.heading', {
    firstName,
    lastName,
  });

  const status = t(`common.clientStatusByCode.${typeValue}`);

  let subheading = status;

  if (companyName) {
    const subheadingKey = unionName
      ? 'subheadingWithCompanyAndUnion'
      : 'subheadingWithCompany';
    subheading = t(`components.SearchResultsClientCard.${subheadingKey}`, {
      status,
      companyName,
      unionName,
    });
  }

  const subheadingContainer = (
    <Fragment>
      {subheading}
      <br />
      {employeeNumber &&
        t(`components.SearchResultsClientCard.employeeNumber`, {
          employeeNumber,
        })}
    </Fragment>
  );

  return {
    linkTo: routePathForClient(data, location),
    heading: renderCardHeading(t, heading, inactive),
    subheading: subheadingContainer,
  };
};

export const listCardPropsForLawyer = (t, data = {}, location = {}) => {
  const { firstName, lastName, city, province, inactive = false } = data;

  const heading = t('components.SearchResultsLawyerCard.heading', {
    firstName,
    lastName,
  });

  const subheading = t('components.SearchResultsLawyerCard.subheading', {
    city,
    province,
  });

  return {
    linkTo: routePathForLawyer(data, location),
    heading: renderCardHeading(t, heading, inactive),
    subheading,
  };
};
