import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'js/components/controls/phone-input';
import { prefixOptions } from 'js/hardcoded-data/form-option-data';
import { ValidationContext } from 'js/components/validation';
import {
  Card,
  InputLabel,
  InputHelp,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { getLawyerInformationFormRequiredFields } from 'js/utilities/lawyers';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onCheckboxChangeEffect,
} from './effects';

const Spacer = () => <div />;

const LawyerInformationForm = (props) => {
  const { t } = useTranslation();
  const prefOptions = prefixOptions(t);
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerInformation');

  const {
    className,
    formData = {},
    onChange,
    isNew,
    options,
    isStaffLawyer,
  } = props;

  const {
    prefix = '',
    firstName = '',
    lastName = '',
    phoneNumber = '',
    email = '',
    langEnglish = false,
    langFrench = false,
    langOther = false,
    provId = '',
    cityId = '',
    lawyerId = '',
    fax = '',
  } = formData;

  const requiredFields = getLawyerInformationFormRequiredFields(
    isNew,
    isStaffLawyer
  );
  const { invalidFields = [] } = validate(formData, requiredFields);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const {
    cityOptions = [],
    provinceOptions = [],
    citiesByProvince = {},
  } = options;

  const cities =
    provId && citiesByProvince[provId] ? citiesByProvince[provId] : [];

  const { cityName = '' } =
    cityOptions.find(
      (c) => c.cityCode === cityId && c.provinceCode === provId
    ) || {};

  const { provName = '' } =
    provinceOptions.find((p) => p.provId === provId) || {};

  return (
    <Card className={classnames('lawyer-information-form', className)}>
      <h2>{t('components.LawyerInformation.LawyerInformationForm.title')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.prefixValidation'
          )}
          invalid={isTouched('prefix') && invalidFields.includes('prefix')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.prefix'
            )}
          >
            <StyledSelect
              name="prefix"
              value={prefix}
              disabled={!hasEditPermission}
              {...inputEventHandlers}
            >
              <option value={''}>
                {t(
                  'components.LawyerInformation.LawyerInformationForm.prefixPlaceholder'
                )}
              </option>
              {prefOptions.map((item, key) => {
                return (
                  <option value={item.value} key={key}>
                    {item.title}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.firstNameValidation'
          )}
          invalid={
            isTouched('firstName') && invalidFields.includes('firstName')
          }
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.firstName'
            )}
          >
            <input
              type="text"
              name="firstName"
              autoComplete="firstName"
              value={firstName}
              {...inputEventHandlers}
              readOnly={!hasEditPermission}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.lastNameValidation'
          )}
          invalid={isTouched('lastName') && invalidFields.includes('lastName')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.lastName'
            )}
          >
            <input
              type="text"
              name="lastName"
              autoComplete="lastName"
              value={lastName}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.phoneNumberValidation'
          )}
          invalid={
            isTouched('phoneNumber') && invalidFields.includes('phoneNumber')
          }
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.phoneNumber'
            )}
          >
            <PhoneInput
              name="phoneNumber"
              value={phoneNumber}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.faxValidation'
          )}
          invalid={isTouched('fax') && invalidFields.includes('fax')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.fax'
            )}
          >
            <PhoneInput
              name="fax"
              value={fax}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.LawyerInformationForm.emailValidation'
          )}
          invalid={isTouched('email') && invalidFields.includes('email')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerInformationForm.email'
            )}
          >
            <input
              type="email"
              name="email"
              autoComplete="email"
              value={email}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>
      </div>

      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            {isNew && (
              <Fragment>
                <InputHelp
                  validationContent={t(
                    'components.LawyerInformation.LawyerAddressForm.provinceValidation'
                  )}
                  invalid={
                    isTouched('provId') && invalidFields.includes('provId')
                  }
                >
                  <InputLabel
                    content={t(
                      'components.LawyerInformation.LawyerAddressForm.province'
                    )}
                  >
                    <StyledSelect
                      value={provId}
                      name="provId"
                      disabled={!hasEditPermission}
                      {...inputEventHandlers}
                    >
                      <option value="" disabled>
                        {t(
                          'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                        )}
                      </option>
                      {provinceOptions.map((prov) => {
                        return (
                          <option value={prov.provId} key={prov.provId}>
                            {prov.provName}
                          </option>
                        );
                      })}
                    </StyledSelect>
                  </InputLabel>
                </InputHelp>

                <InputHelp
                  validationContent={t(
                    'components.LawyerInformation.LawyerAddressForm.cityIdValidation'
                  )}
                  invalid={
                    isTouched('cityId') && invalidFields.includes('cityId')
                  }
                >
                  <InputLabel
                    content={t(
                      'components.LawyerInformation.LawyerAddressForm.city'
                    )}
                  >
                    <StyledSelect
                      value={cityId}
                      name="cityId"
                      disabled={!provId || !hasEditPermission}
                      {...inputEventHandlers}
                    >
                      <option value="" disabled>
                        {t(
                          'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                        )}
                      </option>
                      {cities &&
                        cities.map((city, k) => {
                          return (
                            <option value={city.cityCode} key={k}>
                              {city.cityName}
                            </option>
                          );
                        })}
                    </StyledSelect>
                  </InputLabel>
                </InputHelp>
              </Fragment>
            )}

            {!isNew && (
              <Fragment>
                <InputLabel
                  content={t(
                    'components.LawyerInformation.LawyerAddressForm.province'
                  )}
                >
                  <input
                    type="text"
                    name="provId"
                    value={`${provId} - ${provName}`}
                    readOnly
                  />
                </InputLabel>

                <InputLabel
                  content={t(
                    'components.LawyerInformation.LawyerAddressForm.city'
                  )}
                >
                  <input
                    type="text"
                    name="lawyerId"
                    value={`${cityId} - ${cityName}`}
                    readOnly
                  />
                </InputLabel>
              </Fragment>
            )}

            <InputLabel
              content={t(
                'components.LawyerInformation.LawyerInformationForm.lawyerId'
              )}
            >
              <input type="text" name="lawyerId" value={lawyerId} readOnly />
            </InputLabel>

            <InputHelp
              validationContent={t(
                `components.LawyerInformation.LawyerInformationForm.languageValidation`
              )}
              invalid={
                isTouched(`language`) && invalidFields.includes(`language`)
              }
            >
              <InputLabel
                element="div"
                content={t(
                  `components.LawyerInformation.LawyerInformationForm.language`
                )}
              >
                <div className="language-container">
                  <Checkbox
                    label={t(
                      `components.LawyerInformation.LawyerInformationForm.langEnglish`
                    )}
                    name="langEnglish"
                    checked={langEnglish}
                    onChange={onCheckboxChangeEffect({ onChange })}
                    disabled={!hasEditPermission}
                  />
                  <Checkbox
                    label={t(
                      `components.LawyerInformation.LawyerInformationForm.langFrench`
                    )}
                    name="langFrench"
                    checked={langFrench}
                    onChange={onCheckboxChangeEffect({ onChange })}
                    disabled={!hasEditPermission}
                  />
                  <Checkbox
                    label={t(
                      `components.LawyerInformation.LawyerInformationForm.langOther`
                    )}
                    name="langOther"
                    checked={langOther}
                    onChange={onCheckboxChangeEffect({ onChange })}
                    disabled={!hasEditPermission}
                  />
                </div>
                <Spacer />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
      </div>
    </Card>
  );
};

LawyerInformationForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    cityOptions: PropTypes.arrayOf(PropTypes.object),
    provinceOptions: PropTypes.arrayOf(PropTypes.object),
    citiesByProvince: PropTypes.objectOf(PropTypes.array),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isStaffLawyer: PropTypes.bool,
};

export default LawyerInformationForm;
