import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faBars,
  faUserCircle,
  faFileAlt,
  faCogs,
  faSyncAlt,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import PopoverController from 'js/components/popover-controller';
import { CurrentUserContext } from 'js/components/current-user';
import { routePaths } from 'js/components/router/route-paths';
import { isEmpty } from 'js/utilities/validation';
import logo from 'assets/unifor-logo.png';
import HeaderMenuLinkedItem from './header-menu-linked-item';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import { VersionInfoModal } from 'js/components/version-info';

const FixedHeader = (props) => {
  const { className, children } = props;
  const { t } = useTranslation();
  const { hasLoaded: hasLoadedUser, currentUser = {} } = useContext(
    CurrentUserContext
  );
  const hasUser = hasLoadedUser && !isEmpty(currentUser);

  return (
    <header className={classnames('fixed-header', className)}>
      <div className="header-content">
        <Link to={routePaths.home}>
          <img className="header-logo" src={logo} alt={t('common.logoAlt')} />
        </Link>
        {children}
        <div className="header-menus">
          {hasUser && (
            // Actions Menu
            <GroupPermissionsGate
              permittedGroups={[
                userGroups.administrator,
                userGroups.feedbackSpecialist,
                userGroups.intakeSpecialist,
                userGroups.dataEntry,
                userGroups.support,
              ]}
            >
              {({ hasLoadedGroups, hasMatchingGroup }) => {
                if (!hasLoadedGroups || !hasMatchingGroup) {
                  return null;
                }
                return (
                  <PopoverController
                    popperOptions={{ placement: 'bottom-end' }}
                  >
                    {({
                      referenceAttributes,
                      contentAttributes,
                      isVisible,
                      setVisibility,
                    }) => (
                      <Fragment>
                        <button
                          className={classnames('header-menu-button', {
                            'header-menu-button-active': isVisible,
                          })}
                          {...referenceAttributes}
                        >
                          <FontAwesomeIcon icon={faBars} />
                          <span>{t('components.FixedHeader.menu')}</span>
                        </button>
                        <ul
                          {...contentAttributes}
                          className={classnames(
                            contentAttributes.className,
                            'header-menu'
                          )}
                        >
                          <GroupPermissionsGate
                            permittedGroups={[
                              userGroups.administrator,
                              userGroups.dataEntry,
                            ]}
                          >
                            <HeaderMenuLinkedItem
                              linkTo={routePaths.adminTools}
                              icon={<FontAwesomeIcon icon={faCogs} />}
                              content={t('components.FixedHeader.adminTools')}
                              isMenuVisible={isVisible}
                              setMenuVisibility={setVisibility}
                            />
                          </GroupPermissionsGate>
                          <GroupPermissionsGate
                            permittedGroups={[
                              userGroups.administrator,
                              userGroups.dataEntry,
                            ]}
                          >
                            <HeaderMenuLinkedItem
                              linkTo={routePaths.reports}
                              icon={<FontAwesomeIcon icon={faFileAlt} />}
                              content={t('components.FixedHeader.reports')}
                              isMenuVisible={isVisible}
                              setMenuVisibility={setVisibility}
                            />
                          </GroupPermissionsGate>
                          <GroupPermissionsGate
                            permittedGroups={[
                              userGroups.administrator,
                              userGroups.feedbackSpecialist,
                              userGroups.dataEntry,
                            ]}
                          >
                            <HeaderMenuLinkedItem
                              linkTo={routePaths.feedbackReports}
                              icon={<FontAwesomeIcon icon={faFileAlt} />}
                              content={t(
                                'components.FixedHeader.feedbackReports'
                              )}
                              isMenuVisible={isVisible}
                              setMenuVisibility={setVisibility}
                            />
                          </GroupPermissionsGate>
                          <GroupPermissionsGate
                            permittedGroups={[
                              userGroups.administrator,
                              userGroups.feedbackSpecialist,
                              userGroups.intakeSpecialist,
                              userGroups.dataEntry,
                              userGroups.support,
                            ]}
                          >
                            <HeaderMenuLinkedItem
                              linkTo={routePaths.procedures}
                              icon={<FontAwesomeIcon icon={faSyncAlt} />}
                              content={t('components.FixedHeader.procedures')}
                              isMenuVisible={isVisible}
                              setMenuVisibility={setVisibility}
                            />
                          </GroupPermissionsGate>
                          <GroupPermissionsGate
                            permittedGroups={[
                              userGroups.administrator,
                              userGroups.feedbackSpecialist,
                              userGroups.intakeSpecialist,
                              userGroups.dataEntry,
                              userGroups.support,
                            ]}
                          >
                            <HeaderMenuLinkedItem
                              linkTo={routePaths.dayEnd}
                              icon={<FontAwesomeIcon icon={faClock} />}
                              content={t('components.FixedHeader.dayEnd')}
                              isMenuVisible={isVisible}
                              setMenuVisibility={setVisibility}
                            />
                          </GroupPermissionsGate>
                        </ul>
                      </Fragment>
                    )}
                  </PopoverController>
                );
              }}
            </GroupPermissionsGate>
          )}
          {hasUser && (
            // Account Menu
            <PopoverController popperOptions={{ placement: 'bottom-end' }}>
              {({
                referenceAttributes,
                contentAttributes,
                isVisible,
                setVisibility,
              }) => (
                <Fragment>
                  <button
                    className={classnames('header-menu-button', {
                      'header-menu-button-active': isVisible,
                    })}
                    {...referenceAttributes}
                  >
                    <FontAwesomeIcon icon={faUserCircle} />
                    <span>{currentUser.firstname}</span>
                  </button>
                  <ul
                    {...contentAttributes}
                    className={classnames(
                      contentAttributes.className,
                      'header-menu'
                    )}
                  >
                    <HeaderMenuLinkedItem
                      linkTo={routePaths.logout}
                      content={t('components.FixedHeader.logout')}
                      isMenuVisible={isVisible}
                      setMenuVisibility={setVisibility}
                    />
                    <VersionInfoModal
                      setMenuVisibility={setVisibility}
                      isMenuVisible={isVisible}
                    />
                  </ul>
                </Fragment>
              )}
            </PopoverController>
          )}
        </div>
      </div>
    </header>
  );
};

FixedHeader.propTypes = {
  className: PropTypes.string,
};

export default FixedHeader;
