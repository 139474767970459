import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SVG = (props) => {
  const { className, svg, ...containerProps } = props;
  return (
    <div
      className={classnames('svg', className)}
      dangerouslySetInnerHTML={{ __html: svg }}
      {...containerProps}
    />
  );
};

SVG.propTypes = {
  className: PropTypes.string,
  svg: PropTypes.node,
};

export default SVG;
