import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';

const DuplicateReportModal = (props) => {
  const { className, mounted, onDismiss, onConfirm, isActive } = props;
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames('duplicate-report-modal', className)}
      onClickCancel={onDismiss}
      titleText={t('components.DuplicateReportModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
    >
      <div className={classnames('delete-lawyer-feedback', className)}>
        <h2>{t('components.DuplicateReportModal.title')}</h2>
        <p>{t('components.DuplicateReportModal.text')}</p>
        <div className="button-bar">
          <button
            className="button-link-appearance"
            disabled={isActive}
            onClick={onDismiss}
          >
            {t('common.cancel')}
          </button>
          <button
            className="button button-highlight"
            disabled={isActive}
            onClick={onConfirm}
          >
            {isActive
              ? t('common.creating')
              : t('components.DuplicateReportModal.duplicateButton')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DuplicateReportModal.defaultProps = {
  mounted: false,
  isActive: false,
};

DuplicateReportModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default DuplicateReportModal;
