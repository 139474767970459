import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import {
  Card,
  InputLabel,
  InputHelp,
  DatePicker,
  Checkbox,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { getLawyerInformationFormRequiredFields } from 'js/utilities/lawyers';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onCustomaryFeeInputBlurEffect,
  onCheckboxChangeEffect,
} from './effects';

const LawyerBillingForm = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { className, formData = {}, onChange, isNew, isStaffLawyer } = props;

  const {
    customaryFee = '',
    calledToBar = '',
    lawyerNotes = '',
    dateOpened = null,
    barrister = false,
    payFirm = false,
  } = formData;

  const requiredFields = getLawyerInformationFormRequiredFields(
    isNew,
    isStaffLawyer
  );
  const { invalidFields = [] } = validate(formData, requiredFields);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerInformation');

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className={classnames('lawyer-billing-form', className)}>
      <h2>{t('components.LawyerInformation.LawyerBillingForm.title')}</h2>

      <div className={classnames('form-row', { isStaffLawyer })}>
        <div className="form-column">
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerBillingForm.dateOpened'
            )}
          >
            <DatePicker name="dateOpened" selected={dateOpened} readOnly />
          </InputLabel>
        </div>

        <div className="form-column">
          <InputHelp
            validationContent={t(
              'components.LawyerInformation.LawyerBillingForm.calledToBarValidation'
            )}
            invalid={
              isTouched('calledToBar') && invalidFields.includes('calledToBar')
            }
          >
            <InputLabel
              content={t(
                'components.LawyerInformation.LawyerBillingForm.calledToBar'
              )}
            >
              <input
                type="text"
                name="calledToBar"
                autoComplete="off"
                value={calledToBar}
                readOnly={!hasEditPermission}
                {...inputEventHandlers}
              />
            </InputLabel>
          </InputHelp>
        </div>

        {!isStaffLawyer && (
          <div className="form-column">
            <InputHelp
              validationContent={t(
                'components.LawyerInformation.LawyerBillingForm.customaryFeeValidation'
              )}
              invalid={
                isTouched('customaryFee') &&
                invalidFields.includes('customaryFee')
              }
            >
              <InputLabel
                content={t(
                  'components.LawyerInformation.LawyerBillingForm.customaryFee'
                )}
              >
                <input
                  type="text"
                  name="customaryFee"
                  autoComplete="off"
                  value={customaryFee}
                  onChange={onInputChangeEffect({ onChange })}
                  readOnly={!hasEditPermission}
                  onBlur={onCustomaryFeeInputBlurEffect({ touch, onChange })}
                />
              </InputLabel>
            </InputHelp>
          </div>
        )}
        {!isStaffLawyer && (
          <div className="form-column">
            <Checkbox
              label={t(
                'components.LawyerInformation.LawyerBillingForm.payFirm'
              )}
              name="payFirm"
              checked={payFirm}
              disabled={barrister || !hasEditPermission}
              onChange={onCheckboxChangeEffect({ onChange })}
            />
          </div>
        )}

        <div className="form-column">
          <InputLabel
            content={t(
              'components.LawyerInformation.LawyerBillingForm.lawyerNotes'
            )}
          >
            <textarea
              name="lawyerNotes"
              value={lawyerNotes}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </div>
      </div>
    </Card>
  );
};

LawyerBillingForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isStaffLawyer: PropTypes.bool,
};

export default LawyerBillingForm;
