export const initializeSearchTermEffect = (options = {}) => {
  const { initialQuery = {}, setSearchTerm } = options;
  const {
    params: { Keyword: initialSearchTerm = '' },
  } = initialQuery;
  return () => setSearchTerm(initialSearchTerm);
};

export const onChangeEffect = (options = {}) => {
  const {
    minimumTermLength,
    termLengthInvalid,
    setTermLengthInvalid,
    setSearchTerm,
  } = options;
  return (e) => {
    const { value = '' } = e.target;
    const termLength = value.length;

    if (termLengthInvalid && termLength >= minimumTermLength) {
      setTermLengthInvalid(false);
    }

    setSearchTerm(e.target.value);
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { minimumTermLength, setTermLengthInvalid, executeSearch } = options;
  return (e) => {
    const {
      key,
      target: { value = '' },
    } = e;
    const termLength = value.length;

    if (key === 'Enter') {
      e.preventDefault();
      if (termLength === 0 || termLength >= minimumTermLength) {
        executeSearch();
      } else {
        setTermLengthInvalid(true);
      }
    }
  };
};

export const onSearchEffect = (options = {}) => {
  const { minimumTermLength, setTermLengthInvalid, executeSearch } = options;
  return (e) => {
    const {
      target: { value = '' },
    } = e;
    const termLength = value.length;
    if (termLength === 0 || termLength >= minimumTermLength) {
      executeSearch();
    } else {
      setTermLengthInvalid(true);
    }
  };
};

export const onExecuteSearchEffect = (options = {}) => {
  const { searchTerm, onSearch } = options;
  return () => {
    if (typeof onSearch === 'function') {
      onSearch(searchTerm);
    }
  };
};

export const onResetSearchEffect = (options = {}) => {
  const { setSearchTerm, setTermLengthInvalid, onReset } = options;
  return () => {
    setSearchTerm('');
    setTermLengthInvalid(false);
    if (typeof onReset === 'function') {
      onReset();
    }
  };
};
