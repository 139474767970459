import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { NamedPermissionsProvider } from 'js/components/group-permissions';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { ClientSummary } from 'js/components/client-summary';
import ClientPage from '../client-page';
import { ClientProvider } from '../client-context';
import { sharedClientNamedPermissions } from '../client-named-permissions';
import { onMountEffect, getPrimaryClientEffect } from './effects';

const ClientDependentOf = (props) => {
  const { match = {} } = props;
  const {
    params: { id },
  } = match;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [isLoadingPrimaryClient, setLoadingPrimaryClient] = useState(false);
  const [primaryClient, setPrimaryClient] = useState({});
  const hasPrimaryClient = Object.keys(primaryClient).length > 0;
  const getPrimaryClientAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getPrimaryClientAbortControllerRef],
      setLoadingPrimaryClient,
    }),
    []
  );

  useEffect(
    getPrimaryClientEffect({
      t,
      api,
      id,
      setLoadingPrimaryClient,
      setPrimaryClient,
      getPrimaryClientAbortControllerRef,
    }),
    []
  );

  return (
    <ClientPage
      className="client-dependent-of"
      title={t('components.ClientDependentOf.title')}
    >
      {isLoadingPrimaryClient && <ActivityIndicatorCard />}
      {!isLoadingPrimaryClient && hasPrimaryClient && (
        <ClientSummary client={primaryClient} />
      )}
    </ClientPage>
  );
};

const ClientDependentOfWithContext = (props) => (
  <ClientProvider>
    <ClientDependentOf {...props} />
  </ClientProvider>
);

const ClientDependentOfWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={sharedClientNamedPermissions}>
    <ClientDependentOfWithContext {...props} />
  </NamedPermissionsProvider>
);

export default ClientDependentOfWithNamedPermissions;
