import {
  setColumnDefaults,
  prepareTableData,
  sortTableData,
} from './functions';

export const initializeTableEffect = (options = {}) => {
  const {
    columns = [],
    data = [],
    sortColumnKey,
    sortDirection,
    setTableColumns,
    setTableData,
  } = options;
  return () => {
    const tableColumns = setColumnDefaults(columns);
    const initialColumn = tableColumns.find(({ key }) => key === sortColumnKey);
    let sortedData = data;

    if (initialColumn) {
      sortedData = sortTableData(data, initialColumn, sortDirection);
    }

    const tableData = prepareTableData(tableColumns, sortedData);

    setTableColumns(tableColumns);
    setTableData(tableData);
  };
};

export const onChangeTableSortEffect = (options = {}) => {
  const {
    tableColumns = [],
    data = [],
    setSortColumnKey,
    setSortDirection,
    setTableData,
  } = options;
  return (column, direction) => {
    const sortedData = sortTableData(data, column, direction);
    const tableData = prepareTableData(tableColumns, sortedData);
    setSortColumnKey(column.key);
    setSortDirection(direction);
    setTableData(tableData);
  };
};

export const onClickTableRowEffect = (options = {}) => {
  const { row, onClickRow } = options;
  return () => {
    if (typeof onClickRow === 'function') {
      onClickRow(row);
    }
  };
};

export const onClickSortButtonEffect = (options = {}) => {
  const { column, direction, onChangeSort } = options;
  return () => onChangeSort(column, direction);
};
