import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  getReportsFolderUrl,
  cleanReportFolder,
  cleanReports,
  filenameForReport,
} from 'js/utilities/reports';
import { isEmpty } from 'js/utilities/validation';
import {
  replaceReport,
  removeReport,
  convertTimezoneForReportRunParams,
} from './functions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingFolder } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingFolder(false);
    };
  };
};

export const onChangeFolderEffect = (options = {}) => {
  const {
    folderId,
    setFolder,
    setFolderName,
    setReports,
    setReportsFilterIndex,
    untouch,
    getReportsFolder,
  } = options;
  return () => {
    setFolder({});
    setFolderName('');
    setReports([]);
    setReportsFilterIndex(0);
    untouch('folderName');

    if (folderId && folderId !== 'new') {
      getReportsFolder();
    }
  };
};

export const getReportsFolderEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    folderId,
    setLoadingFolder,
    setFolder,
    setFolderName,
    setReports,
    getReportsFolderAbortControllerRef,
  } = options;

  return async () => {
    if (!folderId || folderId === 'new') {
      return;
    }

    const endpoint = getReportsFolderUrl(folderId);
    const record = cache.get(endpoint);

    if (!record) {
      setLoadingFolder(true);

      rotateAbortControllerRef(getReportsFolderAbortControllerRef);
      const { signal } = getReportsFolderAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t(
                  'components.ReportsFolderDetail.getFolderRequestError'
                ),
              },
            },
          }
        );

        const folder = cleanReportFolder(json);
        const { name = '', reports = [] } = folder;

        cache.set(endpoint, folder);
        setFolder(folder);
        setFolderName(name);
        setReports(reports);
        setLoadingFolder(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingFolder(false);
        }
      }
    } else {
      const { value: folder = {} } = record;
      const { name = '', reports = [] } = folder;
      setFolder(folder);
      setFolderName(name);
      setReports(reports);
      setLoadingFolder(false);
    }
  };
};

export const postReportsFolderEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    folderName = '',
    setSavingFolder,
    onCreateFolder,
    postReportsFolderAbortControllerRef,
  } = options;

  return async (reportData = {}) => {
    const isSaveAs = !isEmpty(reportData);
    const { folderName: saveAsFolderName = '' } = reportData;
    const newFolderName = !isSaveAs ? folderName : saveAsFolderName;
    if (!newFolderName) {
      return;
    }

    setSavingFolder(true);

    rotateAbortControllerRef(postReportsFolderAbortControllerRef);
    const { signal } = postReportsFolderAbortControllerRef.current;

    const endpoint = `/Reports/folders`;
    const body = { folderName: newFolderName };

    try {
      const { json = {} } = await api.postJson(
        endpoint,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.postFolderRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.postFolderRequestError'
              ),
            },
          },
        }
      );

      const folder = cleanReportFolder(json);
      const { id: folderId } = folder;
      const key = getReportsFolderUrl(folderId);

      cache.set(key, folder);
      setSavingFolder(false);

      if (typeof onCreateFolder === 'function' && !isSaveAs) {
        onCreateFolder(folder);
      }
      return json;
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingFolder(false);
      }
    }
  };
};

export const postReportsFolderFrequencyEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    folderId,
    setSavingFolder,
    setFolder,
    setReports,
    postReportsFolderFrequencyAbortControllerRef,
  } = options;

  return async (body) => {
    setSavingFolder(true);

    rotateAbortControllerRef(postReportsFolderFrequencyAbortControllerRef);
    const { signal } = postReportsFolderFrequencyAbortControllerRef.current;

    const endpoint = `/Reports/folders/${folderId}/frequency`;

    try {
      const { json = [] } = await api.postJson(
        endpoint,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.postFolderFrequencyRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.postFolderFrequencyRequestError'
              ),
            },
          },
        }
      );

      const reports = cleanReports(json);
      const key = getReportsFolderUrl(folderId);
      const record = cache.get(key);

      if (record) {
        const nextFolder = { ...record.value, reports };
        cache.set(key, nextFolder);
      }

      setFolder((folder) => ({ ...folder, reports }));
      setReports(reports);
      setSavingFolder(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingFolder(false);
      }
    }
  };
};

export const putReportsFolderEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    folderId,
    folderName,
    setSavingFolder,
    setFolder,
    setFolderName,
    onUpdateFolder,
    putReportsFolderAbortControllerRef,
  } = options;

  return async () => {
    setSavingFolder(true);

    rotateAbortControllerRef(putReportsFolderAbortControllerRef);
    const { signal } = putReportsFolderAbortControllerRef.current;

    const endpoint = getReportsFolderUrl(folderId);
    const body = { id: folderId, name: folderName };

    try {
      const { json = {} } = await api.putJson(
        endpoint,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.putFolderRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.putFolderRequestError'
              ),
            },
          },
        }
      );

      const folder = cleanReportFolder(json);
      const { name = '' } = folder;

      cache.set(endpoint, folder);
      setFolder(folder);
      setFolderName(name);
      setSavingFolder(false);

      if (typeof onUpdateFolder === 'function') {
        onUpdateFolder(folder);
      }
      return json;
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingFolder(false);
      }
    }
  };
};

export const deleteReportsFolderEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    folderId,
    setDeletingFolder,
    setConfirmingDelete,
    onDeleteFolder,
    deleteReportsFolderAbortControllerRef,
  } = options;

  return async () => {
    setDeletingFolder(true);

    rotateAbortControllerRef(deleteReportsFolderAbortControllerRef);
    const { signal } = deleteReportsFolderAbortControllerRef.current;

    const endpoint = getReportsFolderUrl(folderId);

    try {
      await api.deleteJson(
        endpoint,
        { signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.deleteFolderRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.deleteFolderRequestError'
              ),
            },
          },
        }
      );

      cache.delete(endpoint);

      setDeletingFolder(false);
      setConfirmingDelete(false);

      if (typeof onDeleteFolder === 'function') {
        onDeleteFolder(folderId);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setDeletingFolder(false);
      }
    }
  };
};

export const onSubmitReportsFolderEffect = (options = {}) => {
  const {
    folderId,
    validateAll,
    touchAll,
    postReportsFolder,
    putReportsFolder,
  } = options;
  return (e) => {
    e.preventDefault();

    if (!folderId) {
      return;
    }

    const { isValid } = validateAll();
    if (!isValid) {
      touchAll();
      return;
    }

    if (folderId === 'new') {
      postReportsFolder();
    } else {
      putReportsFolder();
    }
  };
};

export const onChangeFolderNameEffect = (options = {}) => {
  const { setFolderName } = options;
  return (e) => setFolderName(e.target.value);
};

export const onBlurFolderNameEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onFilterReportsEffect = (options = {}) => {
  const { setReportsFilterIndex, setReports, folder } = options;
  return (e, index, name) => {
    const { reports = [] } = folder;
    let nextReports = [];

    if (name === 'all') {
      nextReports = [...reports];
    } else {
      nextReports = reports.filter((report) => report[name] === true);
    }
    setReportsFilterIndex(index);
    setReports(nextReports);
  };
};

export const putReportItemEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    putReportItemAbortControllerRef,
  } = options;
  return async (report) => {
    if (!report.id) {
      return;
    }

    setModifyingReport(true);

    rotateAbortControllerRef(putReportItemAbortControllerRef);
    const { signal } = putReportItemAbortControllerRef.current;

    try {
      const { json } = await api.putJson(
        '/ReportItem',
        { body: report, signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.putReportItemRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.putReportItemRequestError'
              ),
            },
          },
        }
      );

      const nextFolder = {
        ...folder,
        reports: replaceReport(folder.reports, report),
      };

      const folderKey = getReportsFolderUrl(folder.id);
      cache.set(folderKey, nextFolder);

      setFolder(nextFolder);
      setReports((reports) => replaceReport(reports, report));
      setModifyingReport(false);
      return json;
    } catch (error) {
      if (!isAbortError(error)) {
        setModifyingReport(false);
        // rethrow because the run/edit modal
        // awaits this request
        throw error;
      }
    }
  };
};

export const moveReportItemEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    moveReportItemAbortControllerRef,
  } = options;

  return async (reportId, destinationFolderId) => {
    if (!reportId || !destinationFolderId) {
      return;
    }

    setModifyingReport(true);

    rotateAbortControllerRef(moveReportItemAbortControllerRef);
    const { signal } = moveReportItemAbortControllerRef.current;

    const body = {
      reportId,
      destinationFolderId,
    };

    try {
      await api.putJson(
        `/ReportItem/moveFolder`,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.moveReportItemRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.moveReportItemRequestError'
              ),
            },
          },
        }
      );

      const nextFolder = {
        ...folder,
        reports: removeReport(folder.reports, reportId),
      };

      const folderKey = getReportsFolderUrl(folder.id);
      cache.set(folderKey, nextFolder);

      const destinationFolderKey = getReportsFolderUrl(destinationFolderId);
      cache.delete(destinationFolderKey);

      setFolder(nextFolder);
      setReports((reports) => removeReport(reports, reportId));
      setModifyingReport(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setModifyingReport(false);
      }
    }
  };
};

export const duplicateReportItemEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    duplicateReportItemAbortControllerRef,
  } = options;
  return async (report) => {
    if (!report.id) {
      return;
    }

    setModifyingReport(true);

    rotateAbortControllerRef(duplicateReportItemAbortControllerRef);
    const { signal } = duplicateReportItemAbortControllerRef.current;

    try {
      const { json = {} } = await api.postJson(
        `/ReportItem?reportId=${report.id}`,
        { signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.duplicateReportItemRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.duplicateReportItemRequestError'
              ),
            },
          },
        }
      );

      const nextFolder = {
        ...folder,
        reports: [...folder.reports, json],
      };

      const folderKey = getReportsFolderUrl(folder.id);
      cache.set(folderKey, nextFolder);

      setFolder(nextFolder);
      setReports((reports) => [...reports, json]);
      setModifyingReport(false);
      return json;
    } catch (error) {
      if (!isAbortError(error)) {
        setModifyingReport(false);
      }
    }
  };
};

export const deleteReportItemEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    deleteReportItemAbortControllerRef,
  } = options;
  return async (report) => {
    if (!report.id) {
      return;
    }

    setModifyingReport(true);

    rotateAbortControllerRef(deleteReportItemAbortControllerRef);
    const { signal } = deleteReportItemAbortControllerRef.current;

    try {
      await api.deleteJson(
        `/ReportItem?reportId=${report.id}`,
        { signal },
        {
          success: {
            context: {
              message: t(
                'components.ReportsFolderDetail.deleteReportItemRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.deleteReportItemRequestError'
              ),
            },
          },
        }
      );

      const nextFolder = {
        ...folder,
        reports: removeReport(folder.reports, report.id),
      };

      const folderKey = getReportsFolderUrl(folder.id);
      cache.set(folderKey, nextFolder);

      setFolder(nextFolder);
      setReports((reports) => removeReport(reports, report.id));
      setModifyingReport(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setModifyingReport(false);
      }
    }
  };
};

export const runReportItemEffect = (options = {}) => {
  const {
    t,
    api,
    setRunningReport,
    setReportResult,
    runReportItemAbortControllerRef,
  } = options;
  return async (report, params) => {
    if (!report.id || isEmpty(params)) {
      return;
    }

    setRunningReport(true);

    rotateAbortControllerRef(runReportItemAbortControllerRef);
    const { signal } = runReportItemAbortControllerRef.current;

    const endpoint = `/ReportItem/generate/${encodeURIComponent(report.id)}`;
    const body = JSON.stringify(convertTimezoneForReportRunParams(params));

    try {
      const { response } = await api.fetch(
        endpoint,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          signal,
          body,
        },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.runReportItemRequestError'
              ),
            },
          },
        }
      );

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      if (objectUrl) {
        const filename = filenameForReport(report, blob.type);
        setReportResult({ objectUrl, filename });
      } else {
        throw new Error('Invalid report file data.');
      }

      setRunningReport(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setRunningReport(false);
        // rethrow because the run/edit modal
        // awaits this request
        throw error;
      }
    }
  };
};

export const saveAsReportEffect = (options = {}) => {
  const { t, api, history, abortControllerRefs } = options;

  return async (reportData = {}) => {
    const { report, nextReport, saveType = 'existing' } = reportData;
    const {
      nextReportName = '',
      nextFolderId = '',
      newFolderName = '',
    } = nextReport;

    const {
      postReportsFolderAbortControllerRef,
      putReportItemAbortControllerRef,
      moveReportItemAbortControllerRef,
      duplicateReportItemAbortControllerRef,
    } = abortControllerRefs;

    try {
      //duplicate the report
      rotateAbortControllerRef(duplicateReportItemAbortControllerRef);
      const {
        signal: duplicateSignal,
      } = duplicateReportItemAbortControllerRef.current;

      const { json: duplicateResponse = {} } = await api.postJson(
        `/ReportItem?reportId=${report.id}`,
        { signal: duplicateSignal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.duplicateReportItemRequestError'
              ),
            },
          },
        }
      );
      const nextReport = {
        ...duplicateResponse,
        reportName: nextReportName,
      };
      //update the report
      rotateAbortControllerRef(putReportItemAbortControllerRef);
      const { signal: updateSignal } = putReportItemAbortControllerRef.current;

      const { json: putReportResponse = {} } = await api.putJson(
        '/ReportItem',
        { body: nextReport, signal: updateSignal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.ReportsFolderDetail.putReportItemRequestError'
              ),
            },
          },
        }
      );
      const { id = '' } = putReportResponse;
      let moveReportBody = { reportId: id, destinationFolderId: nextFolderId };
      if (saveType !== 'existing') {
        //NEW FOLDER PROCESS
        //create folder
        rotateAbortControllerRef(postReportsFolderAbortControllerRef);
        const {
          signal: createSignal,
        } = postReportsFolderAbortControllerRef.current;

        const { json: newFolderResponse = {} } = await api.postJson(
          `/Reports/folders`,
          { body: { folderName: newFolderName }, signal: createSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t(
                  'components.ReportsFolderDetail.postFolderRequestError'
                ),
              },
            },
          }
        );
        //update destination id
        moveReportBody.destinationFolderId = newFolderResponse.id;
      }
      //Move new report to new folder
      rotateAbortControllerRef(moveReportItemAbortControllerRef);
      const { signal: moveSignal } = moveReportItemAbortControllerRef.current;

      await api.putJson(
        `/ReportItem/moveFolder`,
        {
          body: moveReportBody,
          signal: moveSignal,
        },
        {
          success: {
            context: {
              message: t('components.ReportsFolderDetail.saveAsRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ReportsFolderDetail.saveAsRequestError'),
            },
          },
        }
      );
      const path = routePathReplacingParams(routePaths.reportsFolder, {
        folderId: moveReportBody.destinationFolderId,
      });
      history.push(path);
    } catch (error) {
      if (!isAbortError(error)) {
        throw error;
      }
    }
  };
};
