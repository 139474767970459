import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Link, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from 'js/components/fetch';
import { CurrentUserContext } from 'js/components/current-user';
import { DataContext } from 'js/components/data';
import {
  NoteCard,
  ActivityIndicatorCard,
  FontAwesomeIcon,
} from 'js/components/design-system';
import NoteModal from 'js/components/note-modal';
import NotesSortMenu from 'js/components/menus/notes-sort-menu';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { PrintFooter } from 'js/components/print';
import EmptyState from 'js/components/controls/empty-state';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import CasePrintHeader from '../case-print-header';
import CasePage from '../case-page';
import { CaseProvider, CaseContext } from '../case-context';
import { caseNotesNamedPermissions } from '../case-named-permissions';
import { userGroups } from 'js/components/group-permissions';
import DeleteNoteModal from './delete-note-modal';
import {
  getCaseNotesEffect,
  getClientNotesCountEffect,
  sortCaseNotesEffect,
  onChangeSortEffect,
  presentNoteModalEffect,
  saveNoteEffect,
  onCompleteNoteEffect,
  setEditNoteEffect,
  onEditNoteEffect,
  deleteNoteEffect,
  onMountEffect,
} from './effects';
import { queryForSearchString } from './functions';
import { returnCanEditCase } from 'js/utilities/cases';

const CaseNotes = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const caseNumber = decodeURIComponent(params.caseNumber);
  const { search } = useLocation();
  const query = queryForSearchString(search);

  const getCaseNotesAbortControllerRef = useRef(null);
  const deleteNoteAbortControllerRef = useRef(null);
  const postNoteAbortControllerRef = useRef(null);
  const patchNoteAbortControllerRef = useRef(null);
  const getClientNotesCountAbortControllerRef = useRef(null);

  const { t } = useTranslation();

  const {
    caseInfo,
    caseNotes,
    setCaseNotes,
    isLoadingCaseNotes,
    setLoadingCaseNotes,
  } = useContext(CaseContext);
  const { caseInformation = {} } = caseInfo;
  const {
    clientId = '',
    clientCode = '',
    officeOpened = '',
    caseStatus = '',
    cif = '',
  } = caseInformation;
  const { currentUser = {} } = useContext(CurrentUserContext);

  const cache = useContext(DataContext);
  const { api = {} } = useContext(FetchContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditNotesPermission = hasNamedPermission('addCaseNotes');

  const canAddEditCase = returnCanEditCase({
    officeOpened,
    currentUser,
    hasEditPermission: hasEditNotesPermission,
    caseStatus,
    adminOnly: false,
    isNew: false,
    cif: cif,
  });

  const [presentNoteModal, setPresentNoteModal] = useState(false);
  const [editingNote, setEditingNote] = useState({});
  const [noteIdPendingDeletion, setNoteIdPendingDeletion] = useState('');
  const [isDeleting, setDeleting] = useState(false);
  const [isLoadingClientNotesCount, setLoadingClientNotesCount] = useState(
    false
  );
  const [clientNotesCount, setClientNotesCount] = useState(0);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseNotesAbortControllerRef,
        deleteNoteAbortControllerRef,
        postNoteAbortControllerRef,
        patchNoteAbortControllerRef,
        getClientNotesCountAbortControllerRef,
      ],
      setLoadingCaseNotes,
    }),
    []
  );

  const getCaseNotes = getCaseNotesEffect({
    t,
    api,
    cache,
    caseNumber,
    query,
    setLoadingCaseNotes,
    setCaseNotes,
    getCaseNotesAbortControllerRef,
  });

  const dismissNoteModal = presentNoteModalEffect({
    setPresentNoteModal,
    willPresent: false,
    setEditingNote,
  });

  useEffect(
    sortCaseNotesEffect({
      query,
      setCaseNotes,
    }),
    [query.search]
  );

  const editNote = setEditNoteEffect({
    setEditingNote,
    presentNoteModal,
    setPresentNoteModal,
    patchNoteAbortControllerRef,
  });

  useEffect(
    getClientNotesCountEffect({
      t,
      api,
      cache,
      clientId,
      clientCode,
      setLoadingClientNotesCount,
      setClientNotesCount,
      getClientNotesCountAbortControllerRef,
    }),
    [clientId, clientCode]
  );

  return (
    <CasePage
      className="case-notes"
      title={t('components.CaseNotes.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() => window.print()}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={presentNoteModalEffect({
              setPresentNoteModal,
              willPresent: true,
            })}
            disabled={!canAddEditCase}
          >
            {t('components.CaseNotes.addNote')}
          </button>
        </Fragment>
      }
    >
      <CasePrintHeader
        title={t('components.CaseNotes.printHeaderTitle')}
        caseInfo={caseInfo}
      />
      {isLoadingCaseNotes && <ActivityIndicatorCard />}

      {!isLoadingCaseNotes && (
        <Fragment>
          <div className="case-notes-actions">
            <div>
              {caseNotes.length > 0 && (
                <NotesSortMenu
                  field={query.params.orderBy}
                  direction={query.params.order}
                  onSelect={onChangeSortEffect({ history, query })}
                />
              )}
            </div>
            {clientId && clientCode && (
              <Link
                className="button client-notes-link"
                to={routePathReplacingParams(routePaths.clientNotes, {
                  id: clientId,
                  clientCode,
                })}
              >
                {isLoadingClientNotesCount
                  ? t('components.CaseNotes.clientNotesLink')
                  : t('components.CaseNotes.clientNotesLinkWithCount', {
                      count: clientNotesCount,
                    })}
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )}
          </div>

          {caseNotes.length === 0 && (
            <EmptyState title={t('components.CaseNotes.emptyStateMessage')} />
          )}

          {caseNotes.length > 0 && (
            <div className="note-cards-list" data-print-managed>
              {caseNotes.map((note) => (
                <NoteCard
                  key={note.id}
                  onClickEdit={() => editNote(note)}
                  onClickDelete={setNoteIdPendingDeletion}
                  permittedGroups={[
                    userGroups.administrator,
                    userGroups.dataEntry,
                    userGroups.feedbackSpecialist,
                    userGroups.support,
                  ]}
                  disableButtons={!canAddEditCase}
                  {...note}
                />
              ))}
            </div>
          )}
        </Fragment>
      )}

      <PrintFooter />

      <NoteModal
        mounted={presentNoteModal}
        onSubmit={saveNoteEffect({
          t,
          api,
          caseNumber,
          postNoteAbortControllerRef,
        })}
        onEdit={onEditNoteEffect({ t, api, patchNoteAbortControllerRef })}
        onComplete={onCompleteNoteEffect({ dismissNoteModal, getCaseNotes })}
        onClickCancel={dismissNoteModal}
        editingNote={editingNote}
      />

      <DeleteNoteModal
        mounted={!!noteIdPendingDeletion}
        isDeleting={isDeleting}
        onClose={() => setNoteIdPendingDeletion('')}
        onClickDelete={deleteNoteEffect({
          t,
          api,
          noteIdPendingDeletion,
          setNoteIdPendingDeletion,
          setDeleting,
          getCaseNotes,
          deleteNoteAbortControllerRef,
        })}
      />
    </CasePage>
  );
};

const CaseNotesWithContext = (props) => (
  <CaseProvider>
    <CaseNotes {...props} />
  </CaseProvider>
);

const CaseNotesWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={caseNotesNamedPermissions}>
    <CaseNotesWithContext {...props} />
  </NamedPermissionsProvider>
);

export default CaseNotesWithNamedPermissions;
