import {
  focusPreviousSiblingElement,
  focusNextSiblingElement,
} from 'js/utilities/dom';

export const onMountEffect = (options = {}) => {
  const { optionsListRef, setOpen } = options;
  const onDocumentClick = onDocumentClickEffect({ optionsListRef, setOpen });
  return () => {
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  };
};

export const onDocumentClickEffect = (options = {}) => {
  const { optionsListRef, setOpen } = options;
  return (e) => {
    const optionsList = optionsListRef.current;

    if (!optionsList) {
      return;
    }

    // Close the dropdown when the user clicks outside:
    const { clientX, clientY } = e;
    const { top, bottom, left, right } = optionsList.getBoundingClientRect();

    const insideX = clientX >= left && clientX <= right;
    const insideY = clientY >= top && clientY <= bottom;
    const inside = insideX && insideY;

    if (!inside) {
      setOpen(false);
    }
  };
};

export const toggleOpenEffect = (options = {}) => {
  const { open, setOpen, disabled } = options;
  if (!disabled) {
    return () => setOpen(!open);
  }
};

export const onSelectOptionEffect = (options = {}) => {
  const { optionIndex, onSelect, setOpen } = options;
  return (e) => {
    setOpen(false);
    onSelect(e, optionIndex);
  };
};

export const onSelectionKeyDownEffect = (options = {}) => {
  const { open } = options;
  return (e) => {
    const shouldOpen = e.key === ' ' && !open;
    const shouldClose = e.key === 'Escape' && open;
    if (shouldOpen || shouldClose) {
      e.preventDefault();
      e.target.click();
    }
  };
};

export const onOptionKeyDownEffect = (options = {}) => {
  const { onSelect, setOpen } = options;
  return (e) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault();
        onSelect(e);
        break;
      case 'Escape':
        e.preventDefault();
        setOpen(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        focusPreviousSiblingElement(e.target);
        break;
      case 'ArrowDown':
        e.preventDefault();
        focusNextSiblingElement(e.target);
        break;
      default:
        break;
    }
  };
};
