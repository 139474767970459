import { caseApiKey, cleanCase } from 'js/utilities/cases';
import { clientApiKey, cleanClient } from 'js/utilities/clients';
import {
  LAWYER_TYPE_ENUM,
  lawyerApiKey,
  cleanLawyer,
} from 'js/utilities/lawyers';
import { formatNtpInfo } from './functions';
import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const getCaseEffect = (options = {}) => {
  const {
    api,
    cif,
    setNtpInfo,
    setError,
    setActive,
    getCaseAbortControllerRef,
  } = options;
  return async () => {
    setActive(true);

    // TODO:
    // This could be made more efficient by loading the data from the new /Case/PrintCIF
    // endpoint instead of making separate calls to case, client and lawyer.
    rotateAbortControllerRef(getCaseAbortControllerRef);
    const { signal } = getCaseAbortControllerRef.current;

    try {
      const caseUrl = caseApiKey(cif);
      const { json: caseJson = {} } = await api.getJson(
        caseUrl,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      const caseInfo = cleanCase(caseJson);
      const {
        caseInformation: { clientId, clientCode },
        caseLawyer: { lawyerId, lawyerType },
      } = caseInfo;

      const isLawyerOutsideCanada =
        parseInt(lawyerType) === LAWYER_TYPE_ENUM.OUTSIDE_CANADA;

      const clientUrl = clientApiKey(clientId, clientCode);
      const { json: clientJson } = await api.getJson(
        clientUrl,
        {},
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      const client = cleanClient(clientJson);
      let lawyer = {};

      if (lawyerId && !isLawyerOutsideCanada) {
        const lawyerUrl = lawyerApiKey(lawyerId);
        const { json: lawyerJson } = await api.getJson(
          lawyerUrl,
          {},
          {
            success: { bypass: true },
            error: { bypass: true },
          }
        );
        lawyer = cleanLawyer(lawyerJson);
      }

      const ntpInfo = formatNtpInfo(caseInfo, client, lawyer);
      setNtpInfo(ntpInfo);
      setError(null);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setError(error);
        setNtpInfo({});
        setActive(false);
      }
    }
  };
};

export const printEffect = (options = {}) => {
  const { ntpInfo, active, t } = options;

  return () => {
    if (ntpInfo.cif && !active && typeof t === 'function') {
      window.print();
    }
  };
};
