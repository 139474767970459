import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './icon';

const NamedIcon = (props) => {
  const { className, svgName } = props;
  return (
    <Icon
      className={classnames(`icon-${svgName}`, className)}
      svg={require(`../../assets/svg/${svgName}.svg`)}
    />
  );
};

NamedIcon.propTypes = {
  className: PropTypes.string,
  svgName: PropTypes.string.isRequired,
};

export default NamedIcon;
