import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import { roundToFixedPointString } from 'js/utilities/numbers';

const ReleasePrintLegalProblemItem = (props) => {
  const { t } = useTranslation();
  const { className, legalProblem = {} } = props;
  const {
    legalProblem: { legalProblemCode = '', legalProblemDesc = '' },
    frequency = 0,
    transferLimit = 0,
    benefitType = '',
    serviceProvided = '',
    csqTotalHours = 0,
    autoVoidCloseStaffLawyer = '',
    autoVoidCloseDaysStaffLawyer = 0,
    autoVoidCloseNonStaffLawyer = '',
    autoVoidCloseDaysNonStaffLawyer = 0,
    actualRate = 0,
    forcast: forecast = 0,
    warn = false,
    blockFee = false,
    lawPanel = 0,
    lawSPanel = 0,
    closingSt = '',
    staffMaxPrepaidHours = 0,
    staffMaxPrepaidHoursCheck = false,
    staffMaxPrepaidAmount = 0,
    staffMaxPrepaidAmountCheck = false,
    staffPrepaidRate = 0,
    spartRate: staffParticipantRate = 0,
    nonStaffMaxPrePaidHours = 0,
    nonStaffMaxPrePaidHoursCheck = false,
    nonStaffMaxPrepaidAmount = 0,
    nonStaffMaxPrepaidAmountCheck = false,
    nonStaffPrepaidRate = 0,
    npartRate: nonStaffParticipantRate = 0,
  } = legalProblem;

  const CheckIcon = () => (
    <FontAwesomeIcon
      className="release-print-legal-problem-check"
      icon={faCheck}
    />
  );

  return (
    <li className={classnames('release-print-legal-problem', className)}>
      <ul className="release-print-legal-problem-metadata">
        <li>
          <span>{t('components.ReleasePrintLayout.legalProblemCode')}</span>
          <span>{legalProblemCode}</span>
        </li>
        <li>
          <span>{t('components.ReleasePrintLayout.legalProblemDesc')}</span>
          <span>{legalProblemDesc}</span>
        </li>
      </ul>

      <div className="release-print-legal-problem-details">
        <ul>
          <li>
            <span>{t('components.ReleasePrintLayout.serviceFrequency')}</span>
            <span>{roundToFixedPointString(frequency, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.transferLimit')}</span>
            <span>{roundToFixedPointString(transferLimit, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.benefitType')}</span>
            <span>{benefitType}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.serviceProvided')}</span>
            <span>{serviceProvided}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.csqThreshold')}</span>
            <span>{roundToFixedPointString(csqTotalHours, 2)}</span>
          </li>
        </ul>
        <ul>
          <li>
            <span>{t('components.ReleasePrintLayout.staffCloseOrVoid')}</span>
            <span>
              {t('components.ReleasePrintLayout.closeOrVoid', {
                action: autoVoidCloseStaffLawyer,
                days: roundToFixedPointString(autoVoidCloseDaysStaffLawyer, 2),
              })}
            </span>
          </li>
          <li>
            <span>
              {t('components.ReleasePrintLayout.nonStaffCloseOrVoid')}
            </span>
            <span>
              {t('components.ReleasePrintLayout.closeOrVoid', {
                action: autoVoidCloseNonStaffLawyer,
                days: roundToFixedPointString(
                  autoVoidCloseDaysNonStaffLawyer,
                  2
                ),
              })}
            </span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.actualRate')}</span>
            <span>{roundToFixedPointString(actualRate, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.forecastAmount')}</span>
            <span>{roundToFixedPointString(forecast, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.warn')}</span>
            <span>{warn ? t('common.yes') : t('common.no')}</span>
          </li>
        </ul>
        <ul>
          <li>
            <span>{t('components.ReleasePrintLayout.block')}</span>
            <span>{blockFee ? t('common.yes') : t('common.no')}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.lawPanel')}</span>
            <span>{roundToFixedPointString(lawPanel, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.lawSubPanel')}</span>
            <span>{roundToFixedPointString(lawSPanel, 2)}</span>
          </li>
          <li>
            <span>{t('components.ReleasePrintLayout.closingStatus')}</span>
            <span>{closingSt}</span>
          </li>
        </ul>
      </div>

      <table>
        <thead>
          <tr>
            <th />
            <th>{t('components.ReleasePrintLayout.staff')}</th>
            <th>{t('components.ReleasePrintLayout.nonStaff')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('components.ReleasePrintLayout.maxPrepaidHours')}</td>
            <td>
              <span>
                {roundToFixedPointString(staffMaxPrepaidHours, 2)}
                {staffMaxPrepaidHoursCheck && <CheckIcon />}
              </span>
            </td>
            <td>
              <span>
                {roundToFixedPointString(nonStaffMaxPrePaidHours, 2)}
                {nonStaffMaxPrePaidHoursCheck && <CheckIcon />}
              </span>
            </td>
          </tr>
          <tr>
            <td>{t('components.ReleasePrintLayout.maxPrepaidAmount')}</td>
            <td>
              <span>
                {roundToFixedPointString(staffMaxPrepaidAmount, 2)}
                {staffMaxPrepaidAmountCheck && <CheckIcon />}
              </span>
            </td>
            <td>
              <span>
                {roundToFixedPointString(nonStaffMaxPrepaidAmount, 2)}
                {nonStaffMaxPrepaidAmountCheck && <CheckIcon />}
              </span>
            </td>
          </tr>
          <tr>
            <td>{t('components.ReleasePrintLayout.prepaidRate')}</td>
            <td>{roundToFixedPointString(staffPrepaidRate, 2)}</td>
            <td>{roundToFixedPointString(nonStaffPrepaidRate, 2)}</td>
          </tr>
          <tr>
            <td>{t('components.ReleasePrintLayout.participantRate')}</td>
            <td>{roundToFixedPointString(staffParticipantRate, 2)}</td>
            <td>{roundToFixedPointString(nonStaffParticipantRate, 2)}</td>
          </tr>
        </tbody>
      </table>
    </li>
  );
};

ReleasePrintLegalProblemItem.propTypes = {
  className: PropTypes.string,
  legalProblem: PropTypes.object.isRequired,
};

export default ReleasePrintLegalProblemItem;
