export const onChangeInputEffect = (options = {}) => {
  const { setValue } = options;
  return (e) => setValue(e.target.value);
};

export const onBlurInputEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onBeginEditingEffect = (options = {}) => {
  const {
    lawyer = {},
    setBarristerSolicitorEditedName,
    setEditing,
    onBeginEditing,
  } = options;
  return () => {
    const { lawyerInfo = {} } = lawyer;
    const { firmName = '' } = lawyerInfo;
    setBarristerSolicitorEditedName(firmName);
    setEditing(true);

    if (typeof onBeginEditing === 'function') {
      onBeginEditing();
    }
  };
};

export const onCancelEditingEffect = (options = {}) => {
  const {
    untouchAll,
    setBarristerSolicitorEditedName,
    setEditing,
    onCancelEditing,
  } = options;
  return () => {
    untouchAll();
    setBarristerSolicitorEditedName('');
    setEditing(false);

    if (typeof onCancelEditing === 'function') {
      onCancelEditing();
    }
  };
};

export const onCommitEditingEffect = (options = {}) => {
  const {
    validateAll,
    touchAll,
    untouchAll,
    lawyer = {},
    barristerSolicitorEditedName,
    setBarristerSolicitorEditedName,
    setEditing,
    onCommitEditing,
  } = options;
  return () => {
    const { isValid } = validateAll();
    if (!isValid) {
      touchAll();
      return;
    }

    untouchAll();
    setBarristerSolicitorEditedName('');
    setEditing(false);

    if (typeof onCommitEditing === 'function') {
      const { lawyerInfo = {} } = lawyer;
      const nextLawyerInfo = {
        ...lawyerInfo,
        firmName: barristerSolicitorEditedName,
      };
      const nextLawyer = { ...lawyer, lawyerInfo: nextLawyerInfo };
      onCommitEditing(nextLawyer);
    }
  };
};
