import { routePaths } from 'js/components/router/route-paths';
import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { isEmpty } from 'js/utilities/validation';

export const provinceDetailBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = systemSettingsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.ProvincesList.breadcrumbs.provinces'),
      path: routePaths.provinces,
    },
    {
      content: t('components.ProvinceDetail.breadcrumbs.province'),
      path: currentPath,
    },
  ];

  return history;
};

export const scheduleScrollToLastCardInList = (listElement) => {
  setTimeout(() => {
    const lastCardElement = listElement.querySelector(
      `li:last-child .cities-list-card`
    );
    if (lastCardElement) {
      const { top = 0 } = lastCardElement.getBoundingClientRect();
      window.scrollTo({ top, left: 0, behavior: 'smooth' });
    }
  });
};

export const provinceValidationRules = {
  id: (data, key) => !isEmpty(data[key]) && data[key].length === 1,
  name: (data, key) => !isEmpty(data[key]) && data[key].length <= 30,
};

export const provinceRequiredFields = Object.keys(provinceValidationRules);

export const getSaveButtonTitle = (t, isNew, isSaving) => {
  if (isNew) {
    return isSaving ? t('common.creating') : t('common.create');
  } else {
    return isSaving ? t('common.saving') : t('common.save');
  }
};
