import React from 'react';
import { Route } from 'react-router-dom';
import Home from './home';
import Text from './text';
import Buttons from './buttons';
import Forms from './forms';
import Cards from './cards';
import Navigation from './navigation';
import Menus from './menus';
import Tables from './tables';
import Animations from './animations';

const designSystemIndexPaths = (basePath) => ({
  root: `/${basePath}`,
  text: `/${basePath}/text`,
  buttons: `/${basePath}/buttons`,
  forms: `/${basePath}/forms`,
  cards: `/${basePath}/cards`,
  navigation: `/${basePath}/navigation`,
  menus: `/${basePath}/menus`,
  tables: `/${basePath}/tables`,
  animations: `/${basePath}/animations`,
});

const getDesignSystemRoutes = (props = {}) => {
  const { basePath = 'design-system' } = props;
  const indexPaths = designSystemIndexPaths(basePath);
  const Index = (props) => <Home indexPaths={indexPaths} {...props} />;

  const components = {
    [indexPaths.root]: Index,
    [indexPaths.text]: Text,
    [indexPaths.buttons]: Buttons,
    [indexPaths.forms]: Forms,
    [indexPaths.cards]: Cards,
    [indexPaths.navigation]: Navigation,
    [indexPaths.menus]: Menus,
    [indexPaths.tables]: Tables,
    [indexPaths.animations]: Animations,
  };

  return Object.keys(components).map((path) => (
    <Route key={path} exact path={path} component={components[path]} />
  ));
};

export default getDesignSystemRoutes;
