import { cleanStringField } from 'js/utilities/strings';

export const cleanOfficeOptions = (data) => {
  return data.map((office) => {
    return {
      ...office,
      officeName: cleanStringField(office.officeName),
      officeCode: cleanStringField(office.officeCode),
    };
  });
};

export const securityOptions = (t) => {
  return [
    {
      value: '1',
      label: t('components.StaffPage.security.admin'),
    },
    {
      value: '2',
      label: t('components.StaffPage.security.dataEntry'),
    },
    {
      value: '3',
      label: t('components.StaffPage.security.intake'),
    },
    {
      value: '4',
      label: t('components.StaffPage.security.viewOnly'),
    },
    {
      value: '8',
      label: t('components.StaffPage.security.feedback'),
    },
    {
      value: '9',
      label: t('components.StaffPage.security.support'),
    },
  ];
};
