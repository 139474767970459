import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  renderCooperatingLawyerInstructions,
  renderNonCooperatingLawyerInstructions,
  renderTickbox,
  renderLegalProblemSection,
} from './functions';

const NTPPrintLayout = ({ ntpInfo = {} }) => {
  const { t } = useTranslation();
  const { cif, legalProblem, client = {}, lawyer = {} } = ntpInfo;

  return (
    <div className="print-layout ntp-print-layout">
      <div className="print-safe-area ntp-print-safe-area">
        {/* Begin Header */}
        <div className="ntp-print-header">
          <div className="print-line">
            <span>{t('components.PrintLayouts.NTP.header.title')}</span>
          </div>
          <div className="print-line">
            <h1>{t('components.PrintLayouts.NTP.header.heading')}</h1>
          </div>
          <div className="ntp-print-header-gutter" />
          <div className="ntp-print-header-warning">
            <div className="print-line">
              <h2>{t('components.PrintLayouts.NTP.header.warningHeading')}</h2>
            </div>
            <div className="print-line">
              <span>
                {t('components.PrintLayouts.NTP.header.warningLine1')}
              </span>
            </div>
            <div className="print-line">
              <span>
                {t('components.PrintLayouts.NTP.header.warningLine2')}
              </span>
            </div>
          </div>
          <div className="ntp-print-header-gutter" />
          <div className="ntp-print-header-gutter" />
          <div className="ntp-print-header-metadata">
            <ul className="unstyled-list">
              {renderLegalProblemSection(legalProblem, t)}
            </ul>
            <ul className="unstyled-list">
              <li className="print-line">
                <span>
                  {t(
                    'components.PrintLayouts.NTP.header.metadata.participantName',
                    {
                      firstName: client.firstName,
                      lastName: client.lastName,
                    }
                  )}
                </span>
              </li>
              <li className="print-line">
                <span>
                  {t('components.PrintLayouts.NTP.header.metadata.caseNumber')}{' '}
                  {cif}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* End Header */}

        <div className="ntp-print-gutter" />

        {/* Begin Cooperating Lawyer */}
        <div className="ntp-print-cooperating-lawyer">
          <div className="ntp-print-cooperating-lawyer-gutter" />
          <div className="ntp-print-cooperating-lawyer-heading">
            {renderTickbox(lawyer.isCooperatingLawyer)}
            <div className="print-line">
              <h3>
                {t('components.PrintLayouts.NTP.cooperatingLawyer.heading')}
              </h3>
            </div>
          </div>

          {renderCooperatingLawyerInstructions(t)}

          <div className="ntp-print-cooperating-lawyer-gutter" />
          <div className="ntp-print-cooperating-lawyer-metadata">
            <div className="ntp-print-cooperating-lawyer-metadata-row">
              <div className="ntp-print-cooperating-lawyer-metadata-label">
                {t(
                  'components.PrintLayouts.NTP.cooperatingLawyer.metadata.lawyerName'
                )}
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-value">
                <div className="print-line">
                  <span>
                    {lawyer.lastName}
                    {lawyer.firstName && ', '}
                    {lawyer.firstName}
                  </span>
                </div>
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-label">
                {t(
                  'components.PrintLayouts.NTP.cooperatingLawyer.metadata.lawyerId'
                )}
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-value">
                <div className="print-line">
                  <span>{lawyer.lawyerId}</span>
                </div>
              </div>
            </div>
            <div className="ntp-print-cooperating-lawyer-metadata-row">
              <div className="ntp-print-cooperating-lawyer-metadata-label">
                {t(
                  'components.PrintLayouts.NTP.cooperatingLawyer.metadata.lawFirm'
                )}
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-value">
                <div className="print-line">
                  <span>{lawyer.firmName}</span>
                </div>
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-label">
                {t(
                  'components.PrintLayouts.NTP.cooperatingLawyer.metadata.phone'
                )}
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-value">
                <div className="print-line">
                  <span>{lawyer.phone}</span>
                </div>
              </div>
            </div>
            <div className="ntp-print-cooperating-lawyer-metadata-row">
              <div className="ntp-print-cooperating-lawyer-metadata-label">
                {t(
                  'components.PrintLayouts.NTP.cooperatingLawyer.metadata.address'
                )}
              </div>
              <div className="ntp-print-cooperating-lawyer-metadata-value">
                <div className="print-line">
                  <span>
                    {lawyer.address} {lawyer.address2}
                  </span>
                </div>
                <div className="print-line">
                  <span>
                    {lawyer.city}
                    {lawyer.city && ', '}
                    {lawyer.province} {lawyer.postalCode}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Cooperating Lawyer */}

        <div className="ntp-print-gutter" />

        {/* Begin Non-cooperating Lawyer */}
        <div className="ntp-print-non-cooperating-lawyer">
          <div className="ntp-print-non-cooperating-lawyer-gutter" />

          <div className="ntp-print-non-cooperating-lawyer-heading">
            {renderTickbox(!lawyer.isCooperatingLawyer)}
            <div className="print-line">
              <h3>
                {t('components.PrintLayouts.NTP.nonCooperatingLawyer.heading')}
              </h3>
            </div>
          </div>

          {renderNonCooperatingLawyerInstructions(t)}
        </div>
        {/* End Non-cooperating Lawyer */}
      </div>
    </div>
  );
};

NTPPrintLayout.propTypes = {
  ntpInfo: PropTypes.shape({
    cif: PropTypes.string.isRequired,
    client: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    lawyer: PropTypes.shape({
      lawyerId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      firmName: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      address2: PropTypes.string,
      city: PropTypes.string.isRequired,
      province: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      isCooperatingLawyer: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};

export default NTPPrintLayout;
