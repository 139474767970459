import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ActionCard, InputLabel } from 'js/components/design-system';
import { typeFiltersForModel } from './functions';
import {
  onMountEffect,
  onChangeInputEffect,
  resetEffect,
  applyFiltersEffect,
} from './effects';

const SearchFilter = (props) => {
  const { className, model, query = {}, onApplyFilters } = props;
  const { t } = useTranslation();
  const [typeFilterValue, setTypeFilterValue] = useState('all');
  const [cityFilterValue, setCityFilterValue] = useState('');
  const [companyFilterValue, setCompanyFilterValue] = useState('');
  const [firmFilterValue, setFirmFilterValue] = useState('');

  const isClient = model === 'client';
  const isLawyer = model === 'lawyer';
  const typeFilters = typeFiltersForModel(model);

  useEffect(
    onMountEffect({
      query,
      setTypeFilterValue,
      setCityFilterValue,
      setCompanyFilterValue,
      setFirmFilterValue,
    }),
    [query.search]
  );

  return (
    <ActionCard
      className={classnames('search-filter', className)}
      displayHeader={false}
      footerAction={
        <button
          onClick={applyFiltersEffect({
            model,
            params: query.params,
            filters: {
              typeFilterValue,
              cityFilterValue,
              companyFilterValue,
              firmFilterValue,
            },
            onApplyFilters,
          })}
        >
          {t('components.SearchFilter.apply')}
        </button>
      }
    >
      <div className="search-filter-heading">
        <h3>
          {isClient
            ? t('components.SearchFilter.clients')
            : t('components.SearchFilter.lawyers')}
        </h3>

        <button
          className="button-link-appearance"
          onClick={resetEffect({
            model,
            params: query.params,
            onApplyFilters,
          })}
        >
          {t('components.SearchFilter.reset')}
        </button>
      </div>

      <div className="search-filter-radio-group">
        {Object.keys(typeFilters).map((key) => {
          const typeValue = typeFilters[key];
          return (
            <label key={key}>
              <input
                type="radio"
                name="type"
                value={typeValue}
                checked={typeFilterValue === typeValue}
                onChange={onChangeInputEffect({
                  setValue: setTypeFilterValue,
                })}
              />
              <span>
                {isClient
                  ? t(`components.SearchFilter.clientTypeFilter.${key}`)
                  : t(`components.SearchFilter.lawyerTypeFilter.${key}`)}
              </span>
            </label>
          );
        })}
      </div>

      <div className="search-filter-inputs">
        {isClient && (
          <InputLabel content={t('components.SearchFilter.company')}>
            <input
              type="text"
              name="company"
              value={companyFilterValue}
              onChange={onChangeInputEffect({
                setValue: setCompanyFilterValue,
              })}
            />
          </InputLabel>
        )}

        {isLawyer && (
          <InputLabel content={t('components.SearchFilter.firm')}>
            <input
              type="text"
              name="firm"
              value={firmFilterValue}
              onChange={onChangeInputEffect({ setValue: setFirmFilterValue })}
            />
          </InputLabel>
        )}

        <InputLabel content={t('components.SearchFilter.city')}>
          <input
            type="text"
            name="city"
            value={cityFilterValue}
            onChange={onChangeInputEffect({ setValue: setCityFilterValue })}
          />
        </InputLabel>
      </div>
    </ActionCard>
  );
};

SearchFilter.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default SearchFilter;
