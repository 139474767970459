import IMask from 'imask';
import { officeCodeMaskPattern } from 'js/utilities/masks';

export const createOfficeCodeMaskEffect = (options = {}) => {
  const { officeCodeMaskRef } = options;
  return () => {
    officeCodeMaskRef.current = IMask.createMask({
      mask: officeCodeMaskPattern,
    });
  };
};

export const onClickCancelEffect = (options = {}) => {
  const {
    interactionCount,
    setConfirmCancel,
    onClickCancel,
    untouchAll,
  } = options;
  return (e) => {
    if (interactionCount > 0) {
      setConfirmCancel(true);
    } else {
      onClickCancel(e);
      untouchAll();
    }
  };
};

export const onConfirmCancelEffect = (options = {}) => {
  const {
    untouchAll,
    resetInteractionCount,
    setConfirmCancel,
    onClickCancel,
  } = options;
  return async (e) => {
    untouchAll();
    resetInteractionCount();
    setConfirmCancel(false);
    onClickCancel(e);
  };
};

export const onConfirmSaveEffect = (options = {}) => {
  const {
    t,
    isValid,
    touchAll,
    resetInteractionCount,
    setConfirmCancel,
    onClickSave,
  } = options;
  return async (e) => {
    setConfirmCancel(false);

    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    await onClickSave(e);
    resetInteractionCount();
  };
};

export const saveOfficeEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
};

export const onOfficeCodeInputChangeEffect = (options = {}) => {
  const { officeCodeMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = officeCodeMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onSelectionChangeEffect = (options = {}) => {
  const { onChange, touch } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    touch(name);
  };
};

export const updateOfficeInactiveEffect = (options = {}) => {
  const { inactive, onChange, setConfirmDeactivate } = options;
  return () => {
    onChange('officeInact', inactive);
    setConfirmDeactivate(false);
  };
};

export const setConfirmDeactivateEffect = (options = {}) => {
  const { confirm, setConfirmDeactivate } = options;
  return () => setConfirmDeactivate(confirm);
};
