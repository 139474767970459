import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';

const ClientPrintHeader = (props) => {
  const { t } = useTranslation();
  const { className, client = {}, displayClientId, ...headerProps } = props;
  const { clientPersonal = {} } = client;
  const {
    clientId = '',
    clientCode = '',
    clientStatus = '',
    firstName = '',
    lastName = '',
  } = clientPersonal;

  return (
    <PrintHeader
      className={classnames('client-print-header', className)}
      heading={
        <Fragment>
          <span>{`${lastName}, ${firstName}`}</span>
          {displayClientId && (
            <span>
              {t('components.ClientPrintHeader.clientId', {
                clientId,
                clientCode,
                clientStatus,
                clientStatusText: t(
                  `common.clientStatusByCode.${clientStatus}`
                ),
              })}
            </span>
          )}
        </Fragment>
      }
      {...headerProps}
    />
  );
};

ClientPrintHeader.defaultProps = {
  displayClientId: false,
};

ClientPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  client: PropTypes.shape({
    clientPersonal: PropTypes.shape({
      clientId: PropTypes.string,
      clientCode: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  displayClientId: PropTypes.bool,
};

export default ClientPrintHeader;
