import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  cleanLegalProblemCode,
  cleanLegalProblemCodeFormOptions,
  prepareLegalProblemCodeForApi,
} from 'js/utilities/legal-problem-codes';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLegalProblemCodeActive,
    setFormOptionsActive,
    setSaving,
    setRedirectPath,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLegalProblemCodeActive(false);
      setFormOptionsActive(false);
      setSaving(false);
      setRedirectPath('');
    };
  };
};

export const getFormOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    setFormOptionsActive,
    setFormOptions,
    getFormOptionsAbortControllerRef,
  } = options;

  return async () => {
    setFormOptionsActive(true);

    rotateAbortControllerRef(getFormOptionsAbortControllerRef);
    const { signal } = getFormOptionsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Admin/LegalCodes/Options',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.LegalProblemCodeDetail.getFormOptionsRequestError'
              ),
            },
          },
        }
      );

      setFormOptions(cleanLegalProblemCodeFormOptions(json));
      setFormOptionsActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setFormOptionsActive(false);
      }
    }
  };
};

export const getLegalProblemCodeEffect = (options = {}) => {
  const {
    t,
    api,
    isNew,
    code,
    setLegalProblemCodeActive,
    setLegalProblemCode,
    setDisplayName,
    getLegalProblemCodeAbortControllerRef,
  } = options;

  return async () => {
    if (!code || isNew) {
      return;
    }

    setLegalProblemCodeActive(true);

    rotateAbortControllerRef(getLegalProblemCodeAbortControllerRef);
    const { signal } = getLegalProblemCodeAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Admin/LegalCodes/${code}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LegalProblemCodeDetail.getRequestError'),
            },
          },
        }
      );

      const legalProblemCode = cleanLegalProblemCode(json);
      const { name: displayName } = legalProblemCode;

      setLegalProblemCode(legalProblemCode);
      setDisplayName(displayName);
      setLegalProblemCodeActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLegalProblemCodeActive(false);
      }
    }
  };
};

export const patchLegalProblemCodeEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    code,
    legalProblemCode,
    setSaving,
    setLegalProblemCode,
    setDisplayName,
    resetInteractionCount,
    patchLegalProblemCodeAbortControllerRef,
  } = options;

  return async () => {
    const { isValid } = validateAll(legalProblemCode);

    if (!isValid || !code) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(patchLegalProblemCodeAbortControllerRef);
    const { signal } = patchLegalProblemCodeAbortControllerRef.current;

    const body = prepareLegalProblemCodeForApi(legalProblemCode);

    try {
      const { json = {} } = await api.patchJson(
        `/Admin/LegalCodes/${code}`,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.LegalProblemCodeDetail.patchRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t('components.LegalProblemCodeDetail.patchRequestError'),
            },
          },
        }
      );

      const nextLegalProblemCode = cleanLegalProblemCode(json);
      const { name: displayName } = nextLegalProblemCode;

      setSaving(false);
      setLegalProblemCode(nextLegalProblemCode);
      setDisplayName(displayName);
      resetInteractionCount(0);
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const postLegalProblemCodeActiveEffect = (options = {}) => {
  const {
    t,
    api,
    code,
    legalProblemCode,
    setSaving,
    setLegalProblemCode,
    setShowModal,
    postLegalProblemCodeActiveAbortControllerRef,
  } = options;

  return async () => {
    try {
      setSaving(true);

      rotateAbortControllerRef(postLegalProblemCodeActiveAbortControllerRef);
      const { signal } = postLegalProblemCodeActiveAbortControllerRef.current;

      const inactive = !legalProblemCode.inactive;

      await api.postJson(
        `/Admin/LegalCodes/${code}/active`,
        { body: { inactive }, signal },
        {
          success: {
            context: {
              message: t(
                'components.LegalProblemCodeDetail.patchRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t('components.LegalProblemCodeDetail.patchRequestError'),
            },
          },
        }
      );

      const nextLegalProblemCode = { ...legalProblemCode, inactive };

      setLegalProblemCode(nextLegalProblemCode);
      setSaving(false);
      setShowModal(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const postLegalProblemCodeEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    legalProblemCode,
    setSaving,
    resetInteractionCount,
    setRedirectPath,
    postLegalProblemCodeAbortControllerRef,
  } = options;

  return async () => {
    const { isValid } = validateAll(legalProblemCode);

    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setSaving(true);

    rotateAbortControllerRef(postLegalProblemCodeAbortControllerRef);
    const { signal } = postLegalProblemCodeAbortControllerRef.current;

    const body = legalProblemCode;

    try {
      const { json = {} } = await api.postJson(
        '/Admin/LegalCodes',
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.LegalProblemCodeDetail.postRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t('components.LegalProblemCodeDetail.postRequestError'),
            },
          },
        }
      );

      // Build the redirect path to the new legal problem type,
      // falling back to the list route if the code is not returned:
      const { code } = json;
      const redirectPath = code
        ? routePathReplacingParams(routePaths.legalProblemCode, { code })
        : routePaths.legalProblemCodes;

      setSaving(false);
      resetInteractionCount();
      setRedirectPath(redirectPath);
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const saveLegalProblemCodeEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const updateLegalProblemCodeEffect = (options = {}) => {
  const { legalProblemCode, setLegalProblemCode } = options;
  return (name, value) => {
    // Changing lawPanel should reset lawSubPanel:
    const update = { [name]: value };
    if (name === 'lawPanel') {
      update['lawSubPanel'] = 0;
    }
    setLegalProblemCode({ ...legalProblemCode, ...update });
  };
};
