import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../icons';
import { PaginationButton, PaginationNumberButton } from './buttons';
import { sharedPaginationPropTypes, calculatePageRange } from './functions';

/*
 ** Pagination Rules:
 **
 ** The pagination UI displays page numbers for the first, current and last pages.
 ** It also displays page numbers before and after the current page depending on the
 ** value of the `surroundingPages` prop. If `surroundingPages` is 1 the pagination
 ** will display:
 **
 ** <first> <current - 1> <current> <current + 1>  <last>
 **
 ** If `surroundingPages` is 2 the pagination will display:
 **
 ** <first> <current - 2> <current - 1> <current> <current + 1> <current + 2>  <last>
 **
 ** If the first or last surrounding page(s) overlap with the first or last page number,
 ** the redundant page numbers are omitted. For example, if the current page number is 2
 ** and `surroundingPages` is 1, the pagination will display `1 2 3` rather than `1 1 2 3`.
 **
 ** If there is a gap between the first or last page and the first or last surrounding page,
 ** a spacer is inserted. The value of this spacer is defined by the `lowRangeSpacer` prop
 ** (between the first page and the first surrounding page) and the `highRangeSpacer` prop
 ** (between the last surrounding page and the last page). Both props default to the ellipsis.
 ** For example, if there are 8 pages and the current page number is 5 the pagination will display:
 **
 ** 1 ... 4 5 6 ... 8
 **
 ** All the possible displays for a sequence of 8 pages are shown before. The current page is
 ** indicated by angle brackets:
 **
 ** <1> 2 3 ... 8
 ** 1 <2> 3 ... 8
 ** 1 2 <3> 4 ... 8
 ** 1 ... 3 <4> 5 ... 8
 ** 1 ... 4 <5> 6 ... 8
 ** 1 ... 5 <6> 7 8
 ** 1 ... 6 <7> 8
 ** 1 ... 6 7 <8>
 */

const Pagination = (props) => {
  const {
    className,
    currentPage,
    totalPages,
    surroundingPages,
    onClickPrevious,
    onClickNext,
    onClickPageNumber,
    disabled,
    previousButtonContent,
    nextButtonContent,
    lowRangeSpacer,
    highRangeSpacer,
  } = props;

  if (!totalPages) {
    return null;
  }

  const pageRange = calculatePageRange(
    currentPage,
    surroundingPages,
    totalPages
  );
  const hasPageRange = pageRange.length > 0;
  const showLowRangeSpacer = hasPageRange && pageRange[0] > 2;
  const showHighRangeSpacer =
    hasPageRange && pageRange[pageRange.length - 1] < totalPages - 1;
  const showLastPage = totalPages > 1;

  return (
    <ul className={classnames('pagination', className)}>
      <li>
        <PaginationButton
          className="pagination-previous-button"
          onClick={onClickPrevious}
          disabled={currentPage <= 1 || disabled}
        >
          {previousButtonContent}
        </PaginationButton>
      </li>
      <li>
        <PaginationNumberButton
          pageNumber={1}
          currentPage={currentPage}
          onClick={onClickPageNumber}
          disabled={disabled}
        />
      </li>
      {showLowRangeSpacer && (
        <li className="pagination-spacer">{lowRangeSpacer}</li>
      )}
      {pageRange.map((num) => (
        <li key={num}>
          <PaginationNumberButton
            pageNumber={num}
            currentPage={currentPage}
            onClick={onClickPageNumber}
            disabled={disabled}
          />
        </li>
      ))}
      {showHighRangeSpacer && (
        <li className="pagination-spacer">{highRangeSpacer}</li>
      )}
      {showLastPage && (
        <li>
          <PaginationNumberButton
            pageNumber={totalPages}
            currentPage={currentPage}
            onClick={onClickPageNumber}
            disabled={disabled}
          />
        </li>
      )}
      <li>
        <PaginationButton
          className="pagination-next-button"
          onClick={onClickNext}
          disabled={currentPage >= totalPages || disabled}
        >
          {nextButtonContent}
        </PaginationButton>
      </li>
    </ul>
  );
};

Pagination.defaultProps = {
  // The number of pages to display before and after the current page
  surroundingPages: 1,
  // Text or icon content for the previous and next buttons
  previousButtonContent: <FontAwesomeIcon icon={faChevronLeft} />,
  nextButtonContent: <FontAwesomeIcon icon={faChevronRight} />,
  // Text or icon content for the low and high range spacers
  // (see pagination rules comment above)
  lowRangeSpacer: <FontAwesomeIcon icon={faEllipsisH} />,
  highRangeSpacer: <FontAwesomeIcon icon={faEllipsisH} />,
};

Pagination.propTypes = {
  ...sharedPaginationPropTypes,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;
