import { cleanStringField } from 'js/utilities/strings';
import { parseDateFromISOString } from 'js/utilities/dates';
import { roundToFixedPointString, sumOf } from 'js/utilities/numbers';
import {
  resolveDefaultServicesProvidedForLegalProblemCodeAndLawyer,
  resolveDefaultPayeeForLegalProblemCodeAndLawyer,
} from 'js/utilities/cases';
import { STAFF_OFFICE_LEGAL_PROBLEM_CODES } from 'js/utilities/cases/case-defaults';
import { LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';

export const calculatePlanTotal = (planFees, planTaxes) => {
  const sum = sumOf([planFees, planTaxes]);
  return roundToFixedPointString(sum, 2);
};

export const cleanCase = (data = {}, isClosing = false) => {
  const {
    caseInformationResponse = {},
    caseLawyerResponse = {},
    caseBillingResponse = {},
  } = data;

  const caseInformation = cleanCaseInformation(caseInformationResponse);
  const caseLawyer = cleanCaseLawyer(
    caseLawyerResponse,
    caseInformation.legalProblemCode,
    isClosing
  );
  const caseBilling = cleanCaseBilling(
    caseBillingResponse,
    caseInformation.legalProblemCode,
    caseLawyer.lawyerType,
    isClosing
  );

  return {
    caseInformation,
    caseLawyer,
    caseBilling,
  };
};

export const cleanCaseInformation = (data) => {
  const {
    archiveBox,
    archiveFile,
    caseStatus,
    cif,
    intakeId,
    clientId,
    clientCode,
    clientStatus,
    primaryClientStatus,
    lastEdit,
    legalProblemCode,
    legalProblemDesc,
    legalProblemCodes = [],
    openDate,
    reTransferDate,
    shreddedDate,
    specialInstructions,
    otherParty,
    companyChanged,
    caseCompany = {},
    localInfo = {},
    openedInOffice = {},
  } = data;

  return {
    archiveBox: cleanStringField(archiveBox),
    archiveFile: cleanStringField(archiveFile),
    caseStatus: cleanStringField(caseStatus),
    cif: cleanStringField(cif),
    intakeId: cleanStringField(intakeId),
    clientId: cleanStringField(clientId),
    clientCode: cleanStringField(clientCode),
    clientStatus: cleanStringField(clientStatus),
    primaryClientStatus: cleanStringField(primaryClientStatus),
    lastEdit: parseDateFromISOString(lastEdit),
    legalProblemCode: cleanStringField(legalProblemCode),
    legalProblemDesc: cleanStringField(legalProblemDesc),
    legalProblems: cleanCaseLegalProblems(legalProblemCodes),
    officeOpened: cleanStringField(openedInOffice.code),
    openDate: parseDateFromISOString(openDate),
    reTransferDate: parseDateFromISOString(reTransferDate),
    shreddedDate: parseDateFromISOString(shreddedDate),
    specialInstructions: cleanStringField(specialInstructions),
    otherParty: otherParty === true,
    companyChanged: companyChanged === true,
    caseCompany: cleanCaseCompany(caseCompany),
    localInfo,
    openedInOffice,
  };
};

export const cleanCaseCompany = (data) => {
  const { companyCode, companyName } = data;
  return {
    companyCode: cleanStringField(companyCode),
    companyName: cleanStringField(companyName),
  };
};

export const cleanCaseLawyer = (data, legalProblemCode, isClosing) => {
  const {
    lawyerId,
    lawyerType,
    contactPerson,
    firstMeeting,
    reasonCode,
    referralReasonCode,
    servicesProvidedCode,
    sentToLawyer,
    stateTrustee,
    provId,
    cityId,
  } = data;

  // Convert the lawyer type to a string and remove any leading zeros:
  const cleanLawyerType = cleanStringField(lawyerType).replace(/^0+/, '');

  let resolvedServicesProvidedCode = servicesProvidedCode;

  if (isClosing) {
    resolvedServicesProvidedCode = resolveDefaultServicesProvidedForLegalProblemCodeAndLawyer(
      legalProblemCode,
      cleanLawyerType,
      cleanStringField(servicesProvidedCode)
    );
  }

  return {
    lawyerId: cleanStringField(lawyerId),
    lawyerType: cleanLawyerType,
    contactPerson: cleanStringField(contactPerson),
    firstMeeting: parseDateFromISOString(firstMeeting),
    reasonCode: cleanStringField(reasonCode),
    referralReasonCode: cleanStringField(referralReasonCode),
    servicesProvidedCode: resolvedServicesProvidedCode,
    sentToLawyer: typeof sentToLawyer === 'boolean' ? `${sentToLawyer}` : '',
    stateTrustee: stateTrustee === true,
    provId: cleanStringField(provId),
    cityId: cleanStringField(cityId),
  };
};

export const cleanCaseBilling = (
  data,
  legalProblemCode,
  lawyerType,
  isClosing
) => {
  const {
    clientHours = '',
    clientFees = 0,
    planHours = '',
    planFees = 0,
    planTaxes = 0,
    dateClosed = '',
    lawyerInvoiceDate = '',
    payee = 0,
    cheques = [],
    csqThreshold = 0,
  } = data;

  const nextClientHours = clientHours !== null ? clientHours.toString() : '';
  const nextPlanHours = planHours !== null ? planHours.toString() : '';
  const resolvedPayee = resolveDefaultPayeeForLegalProblemCodeAndLawyer(
    legalProblemCode,
    lawyerType,
    cleanStringField(payee)
  );
  //staff lp codes can be closed by staff members and they are populated by the lp code
  //all other lp codes are defaulted to 0 for closing the case and setting final cheque
  //for fee values to appear the case must have a staff lawyer and a staff lp code
  const isStaffLP = STAFF_OFFICE_LEGAL_PROBLEM_CODES.includes(legalProblemCode);
  const canShowValue =
    isStaffLP && Number(lawyerType) === LAWYER_TYPE_ENUM.STAFF;

  return {
    clientHours:
      isClosing && !canShowValue
        ? 0
        : roundToFixedPointString(nextClientHours, 2),
    clientFees:
      isClosing && !canShowValue
        ? roundToFixedPointString(0, 2)
        : roundToFixedPointString(clientFees, 2),
    planHours:
      isClosing && !canShowValue
        ? 0
        : roundToFixedPointString(nextPlanHours, 2),
    planFees:
      isClosing && !canShowValue
        ? roundToFixedPointString(0, 2)
        : roundToFixedPointString(planFees, 2),
    planTaxes:
      isClosing && !canShowValue
        ? roundToFixedPointString(0, 2)
        : roundToFixedPointString(planTaxes, 2),
    dateClosed: parseDateFromISOString(dateClosed),
    lawyerInvoiceDate: parseDateFromISOString(lawyerInvoiceDate),
    payee: resolvedPayee,
    cheques,
    csqThreshold,
  };
};

export const cleanCaseLegalProblems = (data = []) => {
  return data.map((legalProblem) => {
    return {
      legalProblemCode: cleanStringField(legalProblem.legalProblemCode),
      legalProblemDesc: cleanStringField(legalProblem.legalProblemDesc),
    };
  });
};
