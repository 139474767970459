import { styledBannerOptionsForType } from './functions';

export const presentStyledBannerEffect = (options = {}) => {
  const { presentBanner } = options;
  return (bannerType, bannerOptions = {}) => {
    const { className, ...otherOptions } = bannerOptions;
    return presentBanner({
      ...styledBannerOptionsForType(bannerType, className),
      ...otherOptions,
    });
  };
};
