import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import EmptyState from 'js/components/controls/empty-state';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import CaseNumberInput from 'js/components/controls/case-number-input';
import {
  ClientSummary,
  ClientSummarySkeleton,
  clientSummaryMode,
} from 'js/components/client-summary';
import { isEmpty } from 'js/utilities/validation';
import IssueChequeBreadcrumbs from './breadcrumbs';
import {
  onMountEffect,
  focusCaseNumberInputEffect,
  onChangeInputCaseNumberEffect,
  onBlurInputCaseNumberEffect,
  setConfirmedCaseNumberEffect,
  resetCaseNumberEffect,
  getCaseOptionsEffect,
  getCaseInfoEffect,
  getChequesEffect,
  getClientAndLawyerEffect,
  reverseChequeEffect,
} from './effects';
import {
  queryForSearchString,
  issueChequeValidationRules,
  issueChequeRequiredFields,
  getChequesColumns,
} from './functions';
import NewChequeModal from './new-cheque-modal';
import ReverseChequeModal from './reverse-cheque-modal';

const IssueCheque = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const {
    params: { caseNumber: confirmedCaseNumber },
  } = queryForSearchString(search);
  const getCaseOptionsAbortControllerRef = useRef(null);
  const getCaseInfoAbortControllerRef = useRef(null);
  const getChequesAbortControllerRef = useRef(null);
  const getClientAbortControllerRef = useRef(null);
  const getLawyerAbortControllerRef = useRef(null);
  const reverseChequeAbortControllerRef = useRef(null);
  const caseNumberInputRef = useRef(null);

  const [isNewChequeModalMounted, setNewChequeModalMounted] = useState(false);
  const [inputCaseNumber, setInputCaseNumber] = useState(confirmedCaseNumber);
  const [caseOptionsActive, setCaseOptionsActive] = useState(false);
  const [caseOptions, setCaseOptions] = useState({});
  const [caseInfoActive, setCaseInfoActive] = useState(false);
  const [caseInfo, setCaseInfo] = useState({});
  const [chequesActive, setChequesActive] = useState(false);
  const [cheques, setCheques] = useState([]);
  const [clientAndLawyerActive, setClientAndLawyerActive] = useState(false);
  const [clientAndLawyerLoaded, setClientAndLawyerLoaded] = useState(false);
  const [client, setClient] = useState({});
  const [clientSummary, setClientSummary] = useState({});
  const [lawyer, setLawyer] = useState({});
  const [chequePendingReversal, setChequePendingReversal] = useState({});
  const [isReversing, setReversing] = useState(false);

  const { api = {} } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const active =
    caseOptionsActive ||
    caseInfoActive ||
    clientAndLawyerActive ||
    chequesActive;

  const hasCase = !isEmpty(caseInfo);
  const hasClient = !isEmpty(client);

  const { caseInformation = {}, caseLawyer = {} } = caseInfo;
  const { clientId, clientCode } = caseInformation;
  const { lawyerId, lawyerType } = caseLawyer;

  const { isValid: isInputCaseNumberValid } = validate(
    { inputCaseNumber },
    issueChequeRequiredFields
  );
  const touchCaseNumberInput = () => touch('inputCaseNumber');
  const untouchCaseNumberInput = () => untouch('inputCaseNumber');

  const getCheques = getChequesEffect({
    t,
    api,
    caseNumber: confirmedCaseNumber,
    setChequesActive,
    setCheques,
    getChequesAbortControllerRef,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseOptionsAbortControllerRef,
        getCaseInfoAbortControllerRef,
        getChequesAbortControllerRef,
        getClientAbortControllerRef,
        getLawyerAbortControllerRef,
        reverseChequeAbortControllerRef,
      ],
      setCaseOptionsActive,
      setCaseInfoActive,
      setChequesActive,
      setClientAndLawyerActive,
      setClientAndLawyerLoaded,
      setReversing,
    }),
    []
  );

  useEffect(
    getCaseOptionsEffect({
      t,
      api,
      setCaseOptionsActive,
      setCaseOptions,
      getCaseOptionsAbortControllerRef,
    }),
    []
  );

  useEffect(
    focusCaseNumberInputEffect({
      caseNumberInputRef,
    }),
    []
  );

  useEffect(
    getCaseInfoEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      untouchCaseNumberInput,
      setCaseInfoActive,
      setCaseInfo,
      setClient,
      setClientSummary,
      setLawyer,
      setClientAndLawyerActive,
      setClientAndLawyerLoaded,
      getCaseInfoAbortControllerRef,
      getClientAbortControllerRef,
      getLawyerAbortControllerRef,
    }),
    [confirmedCaseNumber]
  );

  useEffect(getCheques, [confirmedCaseNumber]);

  useEffect(
    getClientAndLawyerEffect({
      t,
      api,
      clientId,
      clientCode,
      lawyerId,
      lawyerType,
      setClientAndLawyerActive,
      setClientAndLawyerLoaded,
      setClient,
      setClientSummary,
      setLawyer,
      getClientAbortControllerRef,
      getLawyerAbortControllerRef,
    }),
    [clientId, clientCode, lawyerId]
  );

  return (
    <Page
      className="issue-cheque"
      title={t('components.IssueCheque.title')}
      header={<FixedHeader />}
    >
      <NewChequeModal
        mounted={isNewChequeModalMounted}
        caseOptions={caseOptions}
        caseInfo={caseInfo}
        client={client}
        lawyer={lawyer}
        onClose={() => {
          setNewChequeModalMounted(false);
          getCheques();
        }}
      />
      <ReverseChequeModal
        chequePendingReversal={chequePendingReversal}
        isReversing={isReversing}
        onClose={() => setChequePendingReversal({})}
        onClickReverse={reverseChequeEffect({
          t,
          api,
          setReversing,
          chequePendingReversal,
          setChequePendingReversal,
          onComplete: getCheques,
          reverseChequeAbortControllerRef,
        })}
      />
      <div className="layout-container">
        <div className="layout-column">
          <IssueChequeBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <CaseNumberInput
            inputRef={caseNumberInputRef}
            inputName="inputCaseNumber"
            value={inputCaseNumber}
            isValid={!isTouched('inputCaseNumber') || isInputCaseNumberValid}
            onChange={onChangeInputCaseNumberEffect({
              setInputCaseNumber,
            })}
            onBlur={onBlurInputCaseNumberEffect({
              touchCaseNumberInput,
            })}
            onConfirm={setConfirmedCaseNumberEffect({
              history,
              isInputCaseNumberValid,
              inputCaseNumber,
              touchCaseNumberInput,
              untouchCaseNumberInput,
            })}
            onReset={resetCaseNumberEffect({
              history,
              setCaseInfo,
              setCheques,
              setClient,
              setClientSummary,
              setLawyer,
              setInputCaseNumber,
              setClientAndLawyerLoaded,
              untouchCaseNumberInput,
              caseNumberInputRef,
            })}
          />

          {!hasClient && !clientAndLawyerLoaded && (
            <ClientSummarySkeleton
              className="issue-cheque-client-summary"
              animated={active}
              mode={clientSummaryMode.minimal}
            />
          )}

          {hasClient && (
            <ClientSummary
              className="issue-cheque-client-summary"
              client={clientSummary}
              mode={clientSummaryMode.minimal}
            />
          )}

          <div className="issue-cheque-controls">
            <button
              className="button button-highlight"
              onClick={() => setNewChequeModalMounted(true)}
              disabled={active || !hasCase}
            >
              {t('components.IssueCheque.newCheque')}
            </button>
          </div>

          {hasCase && (
            <Fragment>
              {chequesActive && <ActivityIndicatorCard />}

              {!chequesActive && cheques.length === 0 && (
                <EmptyState
                  title={t('components.IssueCheque.emptyState', {
                    caseNumber: confirmedCaseNumber,
                  })}
                />
              )}

              {!chequesActive && cheques.length > 0 && (
                <SortableTable
                  className="cheques-table"
                  columns={getChequesColumns(t, setChequePendingReversal)}
                  data={cheques}
                  initialSortColumnKey={'chequeNumber'}
                  initialSortDirection={tableSortDirection.desc}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

const ValidatedIssueCheque = () => (
  <ValidationProvider rules={issueChequeValidationRules}>
    <IssueCheque />
  </ValidationProvider>
);

export default ValidatedIssueCheque;
