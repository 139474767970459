import React, { useRef, useContext, useState, useEffect } from 'react';
import { FetchContext } from 'js/components/fetch';
import { Redirect } from 'react-router-dom';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import {
  onMountEffect,
  unionDetailsRequestEffect,
  updateUnionInfoEffect,
  unionCompaniesRequestEffect,
  patchUnionEffect,
  postUnionEffect,
  saveUnionEffect,
  postUnionActiveEffect,
} from './effects';
import { unionValidationRules, getSaveButtonTitle } from './functions';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { StyledBannerContext } from 'js/components/banner-styled';
import UnionDetailsBreadcrumbs from './union-details-breadcrumbs';
import { useRouteMatch } from 'react-router-dom';
import UnionForm from './union-form';
import LinkedCompanyList from './linked-companies';
import UnionPrintDetails from './union-details-print';
import UnionPrintHeader from './union-print-header';

const UnionDetailsPage = (props) => {
  const { className, title, ...pageProps } = props;
  const { t } = useTranslation();

  const getUnionAbortControllerRef = useRef(null);
  const abortCompanyControllerRef = useRef(null);
  const postUnionAbortControllerRef = useRef(null);
  const patchUnionAbortControllerRef = useRef(null);
  const postUnionActiveAbortControllerRef = useRef(null);

  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [initalActive, setInitialActive] = useState(false);
  const [union, setUnion] = useState({});
  const [companies, setCompanies] = useState([]);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const {
    params: { unionNumber },
  } = useRouteMatch();
  const isNew = unionNumber === 'new';
  const [redirectPath, setRedirectPath] = useState('');
  const [viewModal, showModal] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getUnionAbortControllerRef,
        abortCompanyControllerRef,
        postUnionAbortControllerRef,
        postUnionActiveAbortControllerRef,
        patchUnionAbortControllerRef,
      ],
      setActive,
    }),
    []
  );

  useEffect(
    unionDetailsRequestEffect({
      t,
      api,
      unionNumber,
      setInitialActive,
      setUnion,
      setRedirectPath,
      getUnionAbortControllerRef,
      isNew,
    }),
    []
  );

  useEffect(
    unionCompaniesRequestEffect({
      t,
      api,
      unionNumber,
      setInitialActive,
      setCompanies,
      setRedirectPath,
      abortCompanyControllerRef,
      isNew,
    }),
    []
  );

  const requiredFields = Object.keys(unionValidationRules);

  const { isValid, invalidFields } = validate(union, requiredFields);

  const touchAllFields = () => touch(requiredFields);

  const patchEffect = patchUnionEffect({
    t,
    api,
    union,
    isValid,
    setUnion,
    setActive,
    touchAllFields,
    resetInteractionCount,
    setDidSubmit,
    patchUnionAbortControllerRef,
  });

  const postEffect = postUnionEffect({
    t,
    api,
    union,
    isValid,
    setActive,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
    setDidSubmit,
    postUnionAbortControllerRef,
  });

  const onSaveUnion = saveUnionEffect({
    t,
    saveEffect: isNew ? postEffect : patchEffect,
    presentStyledBanner,
  });

  const { locInact = false } = union;

  const setActiveState = postUnionActiveEffect({
    t,
    api,
    setActive,
    unionNumber,
    union,
    setUnion,
    showModal,
    postUnionActiveAbortControllerRef,
  });

  return (
    <Page
      className={classnames('union-details-page', className)}
      title={t('components.UnionDetailsPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <NavigationSaveModal
        proceedAfter={async () => await onSaveUnion()}
        shouldBlockNavigation={() => interactionCount > 0}
      />
      {redirectPath && <Redirect to={redirectPath} />}
      <div className="layout-container ">
        <div className="layout-column">
          <UnionDetailsBreadcrumbs />
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="union-details-page-heading">
            <div className="union-details-page-heading-left">
              <UnionPrintHeader
                title={t('components.UnionDetailsPage.subtitle')}
                union={union}
              />
              <p>{t('components.UnionDetailsPage.subtitle')}</p>
              <div>
                <h1>
                  {isNew
                    ? t('components.UnionDetailsPage.newTitle')
                    : union.unionName}
                </h1>
                <DeactivateToggle
                  disabled={isNew}
                  isActive={!locInact}
                  onActivate={setActiveState}
                  onDeactivate={() => showModal(true)}
                />
                <DeactivateModal
                  title={t('components.UnionDetailsPage.DeactivateModal.title')}
                  message={t(
                    'components.UnionDetailsPage.DeactivateModal.message'
                  )}
                  mounted={viewModal}
                  onClose={() => showModal(false)}
                  onSave={setActiveState}
                  active={active}
                />
              </div>
            </div>
            <div className="union-details-page-heading-right">
              {!isNew && (
                <button
                  className="button button-highlight page-action-button"
                  onClick={() => window.print()}
                  disabled={active}
                >
                  {t('common.print')}
                </button>
              )}
              <button
                className="button button-highlight page-action-button"
                onClick={isNew ? postEffect : patchEffect}
                disabled={active}
              >
                {getSaveButtonTitle(t, isNew, active)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {initalActive && <ActivityIndicatorCard />}
          {!initalActive && (
            <form
              onClick={incrementInteractionCount}
              onSubmit={(e) => e.preventDefault()}
            >
              <UnionForm
                formData={union}
                onChange={updateUnionInfoEffect({
                  union,
                  setUnion,
                })}
                invalidFields={invalidFields}
                didSubmit={didSubmit}
                requiredFields={requiredFields}
                isNew={isNew}
              />
              <UnionPrintDetails union={union} />
            </form>
          )}
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <LinkedCompanyList
            companies={companies}
            isNew={isNew}
            active={initalActive}
          />
        </div>
      </div>
    </Page>
  );
};

UnionDetailsPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

const ValidUnionDetails = () => {
  return (
    <ValidationProvider rules={unionValidationRules}>
      <UnionDetailsPage />
    </ValidationProvider>
  );
};

const InteractionTrackingUnionDetails = () => (
  <InteractionTrackingProvider>
    <ValidUnionDetails />
  </InteractionTrackingProvider>
);

export default InteractionTrackingUnionDetails;
