import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { NoteCard, ActivityIndicatorCard } from 'js/components/design-system';
import NoteModal from 'js/components/note-modal';
import NotesSortMenu from 'js/components/menus/notes-sort-menu';
import { PrintFooter } from 'js/components/print';
import EmptyState from 'js/components/controls/empty-state';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import ClientPrintHeader from '../client-print-header';
import ClientPage from '../client-page';
import { ClientContext, ClientProvider } from '../client-context';
import { clientNotesNamedPermissions } from '../client-named-permissions';
import DeleteNoteModal from './delete-note-modal';
import {
  onMountEffect,
  getClientNotesEffect,
  sortClientNotesEffect,
  onChangeSortEffect,
  presentNoteModalEffect,
  saveNoteEffect,
  onCompleteNoteEffect,
  setEditNoteEffect,
  deleteNoteEffect,
  onEditNoteEffect,
} from './effects';
import { queryForSearchString } from './functions';
import { userGroups } from 'js/components/group-permissions';

const ClientNotes = () => {
  const {
    params: { id, clientCode = '00' },
  } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const query = queryForSearchString(search);

  const getClientNotesAbortControllerRef = useRef(null);
  const deleteNoteAbortControllerRef = useRef(null);
  const postNoteAbortControllerRef = useRef(null);
  const patchNoteAbortControllerRef = useRef(null);

  const {
    client,
    clientNotes,
    setClientNotes,
    isLoadingClientNotes,
    setLoadingClientNotes,
  } = useContext(ClientContext);

  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasAddNotesPermission = hasNamedPermission('addClientNotes');

  const [presentNoteModal, setPresentNoteModal] = useState(false);
  const [editingNote, setEditingNote] = useState({});
  const [noteIdPendingDeletion, setNoteIdPendingDeletion] = useState('');
  const [isDeleting, setDeleting] = useState(false);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getClientNotesAbortControllerRef,
        deleteNoteAbortControllerRef,
        postNoteAbortControllerRef,
        patchNoteAbortControllerRef,
      ],
      setLoadingClientNotes,
    }),
    []
  );

  const getClientNotes = getClientNotesEffect({
    t,
    api,
    cache,
    id,
    clientCode,
    query,
    setLoadingClientNotes,
    setClientNotes,
    getClientNotesAbortControllerRef,
  });

  const dismissNoteModal = presentNoteModalEffect({
    setPresentNoteModal,
    willPresent: false,
    setEditingNote,
  });

  useEffect(
    sortClientNotesEffect({
      query,
      setClientNotes,
    }),
    [query.search]
  );

  const editNote = setEditNoteEffect({
    setEditingNote,
    presentNoteModal,
    setPresentNoteModal,
    patchNoteAbortControllerRef,
  });

  return (
    <ClientPage
      className="client-notes"
      title={t('components.ClientNotes.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() => window.print()}
            disabled={clientNotes.length === 0}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={presentNoteModalEffect({
              setPresentNoteModal,
              willPresent: true,
            })}
            disabled={!hasAddNotesPermission}
          >
            {t('components.ClientNotes.addNote')}
          </button>
        </Fragment>
      }
    >
      <ClientPrintHeader
        title={t('components.ClientNotes.printHeaderTitle')}
        client={client}
      />
      {isLoadingClientNotes && <ActivityIndicatorCard />}

      {!isLoadingClientNotes && (
        <Fragment>
          {clientNotes.length === 0 && (
            <EmptyState title={t('components.ClientNotes.emptyStateMessage')} />
          )}
          {clientNotes.length > 0 && (
            <Fragment>
              <NotesSortMenu
                field={query.params.orderBy}
                direction={query.params.order}
                onSelect={onChangeSortEffect({ history, query })}
              />
              <div className="note-cards-list" data-print-managed>
                {clientNotes.map((note) => (
                  <NoteCard
                    key={note.id}
                    onClickEdit={() => editNote(note)}
                    onClickDelete={setNoteIdPendingDeletion}
                    permittedGroups={[
                      userGroups.administrator,
                      userGroups.dataEntry,
                      userGroups.feedbackSpecialist,
                      userGroups.support,
                    ]}
                    {...note}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      <PrintFooter />

      <NoteModal
        mounted={presentNoteModal}
        onSubmit={saveNoteEffect({
          t,
          api,
          id,
          clientCode,
          postNoteAbortControllerRef,
        })}
        onComplete={onCompleteNoteEffect({
          dismissNoteModal,
          getClientNotes,
          setEditingNote,
        })}
        onEdit={onEditNoteEffect({
          t,
          api,
          id,
          clientCode,
          patchNoteAbortControllerRef,
        })}
        onClickCancel={dismissNoteModal}
        editingNote={editingNote}
      />
      <DeleteNoteModal
        mounted={!!noteIdPendingDeletion}
        isDeleting={isDeleting}
        onClose={() => setNoteIdPendingDeletion('')}
        onClickDelete={deleteNoteEffect({
          t,
          api,
          noteIdPendingDeletion,
          setNoteIdPendingDeletion,
          setDeleting,
          getClientNotes,
          deleteNoteAbortControllerRef,
          id,
          clientCode,
        })}
      />
    </ClientPage>
  );
};

const ClientNotesWithContext = (props) => (
  <ClientProvider>
    <ClientNotes {...props} />
  </ClientProvider>
);

const ClientNotesWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={clientNotesNamedPermissions}>
    <ClientNotesWithContext {...props} />
  </NamedPermissionsProvider>
);

export default ClientNotesWithNamedPermissions;
