import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListCard,
} from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import LegalProblemCodesSortMenu from 'js/components/menus/legal-problem-codes-sort-menu';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import LegalProblemCodesBreadcrumbs from './breadcrumbs';
import {
  onMountEffect,
  searchRequestEffect,
  searchResultsSortEffect,
  previousSearchResultsEffect,
  nextSearchResultsEffect,
  searchResultsPageNumberEffect,
  searchEffect,
  searchResetEffect,
} from './effects';
import { queryForSearchString } from './functions';
import EmptyState from 'js/components/controls/empty-state';

const LegalProblemCodes = () => {
  const history = useHistory();
  const { search = '' } = useLocation();
  const abortControllerRef = useRef(null);
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [legalProblemCodes, setLegalProblemCodes] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const { api = {} } = useContext(FetchContext);
  const query = queryForSearchString(search);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    searchRequestEffect({
      t,
      api,
      query,
      setActive,
      setLegalProblemCodes,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="legal-problem-codes-pagination-container">
      <OffsetLimitPagination
        className="legal-problem-codes-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousSearchResultsEffect({
          history,
          query,
        })}
        onClickNext={nextSearchResultsEffect({ history, query })}
        onClickPageNumber={searchResultsPageNumberEffect({
          history,
          query,
        })}
      />
    </div>
  );

  return (
    <Page
      className="legal-problem-codes"
      title={t('components.LegalProblemCodes.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <LegalProblemCodesBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          <div className="legal-problem-codes-heading">
            <div className="legal-problem-codes-heading-left">
              <h1>{t('components.LegalProblemCodes.heading')}</h1>
            </div>
            <div className="legal-problem-codes-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() =>
                  history.push(routePaths.legalProblemCodesPrint + query.search)
                }
              >
                {t('common.print')}
              </button>
              <Link
                className="button button-highlight page-action-button"
                to={routePaths.newLegalProblemCode}
              >
                {t('common.new')}
              </Link>
            </div>
          </div>

          {active && <ActivityIndicatorCard />}

          {!active && legalProblemCodes.length === 0 && (
            <EmptyState
              title={t('components.LegalProblemCodes.emptyStateMessage')}
            />
          )}

          {!active && legalProblemCodes.length > 0 && (
            <Fragment>
              <div className="legal-problem-codes-search-bar">
                <GeneralSearchInput
                  placeholder={t(
                    'components.LegalProblemCodes.searchPlaceholder'
                  )}
                  initialQuery={query}
                  onSearch={searchEffect({ history, query })}
                  onReset={searchResetEffect({ history, query })}
                />
                <LegalProblemCodesSortMenu
                  field={query.params.Order}
                  direction={query.params.Sort}
                  onSelect={searchResultsSortEffect({ history, query })}
                />
              </div>
              {pagination}
              <ul className="unstyled-list legal-problem-codes-list">
                {legalProblemCodes.map((legalProblemCode) => {
                  const { code, name, inactive } = legalProblemCode;
                  return (
                    <li key={code}>
                      <ListCard
                        key={code}
                        heading={
                          <div className="inactive-tag-container">
                            <span>{code}</span>
                            <span>{name}</span>
                            {/* Explicit conversion to boolean because `inactive` may be null: */}
                            <InactiveTag inactive={inactive === true} />
                          </div>
                        }
                        linkTo={routePathReplacingParams(
                          routePaths.legalProblemCode,
                          { code }
                        )}
                      />
                    </li>
                  );
                })}
              </ul>
              {pagination}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default LegalProblemCodes;
