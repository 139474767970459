import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import Page from 'js/components/page';
import SearchHeader from 'js/components/headers/search-header';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';
import {
  LAWYER_TYPE_ENUM,
  returnlawyerTypeFromId,
  isNewLawyerPath,
} from 'js/utilities/lawyers';
import { getLawyerFeedbackEffect } from '../lawyer-feedback/effects';
import { queryForSearchString as lawyerFeedbackQueryForSearchString } from '../lawyer-feedback/functions';
import { LawyerContext } from '../lawyer-context';
import LawyerBreadcrumbs from './breadcrumbs';
import LawyerTabs from './tabs';
import {
  onMountEffect,
  getLawyerRecordEffect,
  prepNewLawyerEffect,
  patchActiveLawyerEffect,
  getLawyerFeedbackConditionalEffect,
} from './effects';
import { GroupPermissionsContext } from 'js/components/group-permissions/context';
import { userGroups } from 'js/components/group-permissions';

const LawyerPage = (props) => {
  const { className, title, actions, children, ...pageProps } = props;
  const { t } = useTranslation();
  const { matchAnyGroup } = useContext(GroupPermissionsContext);
  const { search } = useLocation();
  const {
    path,
    params: { lawyerId },
  } = useRouteMatch();

  let lawyerFeedbackQuery;
  if (path === routePaths.lawyerFeedback) {
    lawyerFeedbackQuery = lawyerFeedbackQueryForSearchString(search);
  }

  const getLawyerAbortControllerRef = useRef(null);
  const patchActiveLawyerAbortControllerRef = useRef(null);
  const getLawyerFeedbackAbortControllerRef = useRef(null);

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const {
    lawyer,
    setLawyer,
    isLoadingLawyer,
    setLoadingLawyer,
    lawyerFeedback,
    setLawyerFeedback,
    isLoadingLawyerFeedback,
    setLoadingLawyerFeedback,
  } = useContext(LawyerContext);

  const [isSavingLawyer, setSavingLawyer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isNew = isNewLawyerPath(path);
  const { lawyerInfo = {} } = lawyer;
  const { firstName = '', lastName = '', lawyerInactive = false } = lawyerInfo;
  const lawyerApproxType = returnlawyerTypeFromId(lawyerId);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerAbortControllerRef,
        patchActiveLawyerAbortControllerRef,
        getLawyerFeedbackAbortControllerRef,
      ],
      setLoadingLawyer,
      setLoadingLawyerFeedback,
      setSavingLawyer,
    }),
    [isNew, lawyerId]
  );

  useEffect(
    getLawyerRecordEffect({
      isNew,
      t,
      api,
      cache,
      lawyerId,
      lawyerApproxType,
      setLoadingLawyer,
      setLawyer,
      getLawyerAbortControllerRef,
    }),
    [isNew, lawyerId]
  );

  useEffect(
    prepNewLawyerEffect({
      isNew,
      setLawyer,
    }),
    [isNew]
  );

  const hasAccessToFeedback = matchAnyGroup([
    userGroups.administrator,
    userGroups.feedbackSpecialist,
    userGroups.dataEntry,
  ]);

  useEffect(
    getLawyerFeedbackConditionalEffect({
      isNew,
      hasAccessToFeedback,
      getLawyerFeedback: getLawyerFeedbackEffect({
        t,
        api,
        cache,
        query: lawyerFeedbackQuery,
        lawyerId,
        setLoadingLawyerFeedback,
        setLawyerFeedback,
        getLawyerFeedbackAbortControllerRef,
        hasAccessToFeedback,
      }),
    }),
    [lawyerId, isNew]
  );

  const setLawyerActive = patchActiveLawyerEffect({
    t,
    api,
    cache,
    lawyerId,
    lawyer,
    lawyerApproxType,
    lawyerInactive: !lawyerInactive,
    setSavingLawyer,
    setLawyer,
    setShowModal,
    patchActiveLawyerAbortControllerRef,
  });

  const isStaffLawyer = lawyerApproxType === LAWYER_TYPE_ENUM.STAFF;
  const subheadingContent = isStaffLawyer
    ? t('components.LawyerPage.staffSubheading', { lawyerId })
    : t('components.LawyerPage.lawyerSubheading', { lawyerId });

  return (
    <Page
      className={classnames('lawyer-page', className)}
      title={t('components.LawyerPage.title', { title })}
      header={<SearchHeader />}
      {...pageProps}
    >
      <div className="layout-container">
        <div className="layout-column">
          <LawyerBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          {isLoadingLawyer && <ActivityIndicatorCard />}
          {!isLoadingLawyer && (
            <Fragment>
              <div className="lawyer-page-heading">
                <div className="lawyer-page-heading-left">
                  <div>
                    <p>{subheadingContent}</p>
                    <h1>
                      {isNew
                        ? t('components.LawyerPage.newLawyerTitle')
                        : `${lastName}, ${firstName}`}
                    </h1>
                  </div>
                  <DeactivateToggle
                    disabled={isNew}
                    isActive={!lawyerInactive}
                    onActivate={setLawyerActive}
                    onDeactivate={() => setShowModal(true)}
                  />
                  <DeactivateModal
                    title={t('components.LawyerPage.DeactivateModal.title')}
                    message={t('components.LawyerPage.DeactivateModal.message')}
                    mounted={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={setLawyerActive}
                    active={isSavingLawyer}
                  />
                </div>
                <div className="lawyer-page-heading-right">{actions}</div>
              </div>
              <LawyerTabs
                disabled={isNew}
                isStaffLawyer={isStaffLawyer}
                showLawyerFeedbackCount={!isLoadingLawyerFeedback}
                lawyerFeedbackCount={lawyerFeedback.length}
              />
              <div className="lawyer-page-content">{children}</div>
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

LawyerPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
};

export default LawyerPage;
