import getClient from './client';
import { login, acquireToken } from './auth';
import { defaultLoginRequestForAudience } from './requests';

export const initializeClientEffect = (options) => {
  const { config = {}, setClient } = options;
  return () => {
    const client = getClient(config);
    setClient(client);
  };
};

export const initializeCurrentAccountEffect = (options) => {
  const {
    client,
    setAccount,
    setIsAuthenticating,
    setIsReady,
    useRedirectFlow = false,
  } = options;
  return async () => {
    if (!client) {
      return;
    }

    setIsAuthenticating(true);

    let response = await client.handleRedirectPromise();
    if (!response) {
      const accounts = client.getAllAccounts();
      if (accounts.length > 0) {
        response = await acquireToken(
          client,
          { account: accounts[0] },
          useRedirectFlow
        );
      }
    }

    const account = response ? response.account : null;

    setAccount(account);
    setIsAuthenticating(false);
    setIsReady(true);
  };
};

export const loginEffect = (options) => {
  const {
    client,
    audience,
    setAccount,
    setIsAuthenticating,
    useRedirectFlow = false,
  } = options;
  const defaultRequest = defaultLoginRequestForAudience(audience);
  return async (request = defaultRequest) => {
    setIsAuthenticating(true);
    const response = await login(client, request, useRedirectFlow);
    const account = response ? response.account : null;
    setAccount(account);
    setIsAuthenticating(false);
    return response;
  };
};

export const logoutEffect = (options) => {
  const { client, setAccount, setIsAuthenticating } = options;
  return async (request) => {
    setIsAuthenticating(true);
    await client.logout(request);
    setAccount(null);
    setIsAuthenticating(false);
  };
};

export const acquireTokenEffect = (options) => {
  const {
    client,
    account,
    audience,
    setIsAuthenticating,
    useRedirectFlow = false,
  } = options;
  const defaultRequest = defaultLoginRequestForAudience(audience);
  return async (request = defaultRequest) => {
    setIsAuthenticating(true);
    const response = await acquireToken(
      client,
      { ...request, account },
      useRedirectFlow
    );
    setIsAuthenticating(false);
    return response;
  };
};
