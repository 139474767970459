import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { FetchContext } from 'js/components/fetch';
import {
  InputLabel,
  InputHelp,
  StyledSelect,
} from 'js/components/design-system';
import Modal from 'js/components/modal';
import { saveAsValidationRules } from './functions';
import {
  onMountEffect,
  getAllFoldersEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onConfirmSaveAsEffect,
} from './effects';
import { faFolder, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';

const EXISTING = 'existing';
const NEW = 'new';

const SaveAsModal = (props) => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const getReportsFoldersAbortControllerRef = useRef(null);

  const [loadingFolders, setLoadingFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [active, setActive] = useState(false);
  const [saveType, setSaveType] = useState(EXISTING);
  const defaultNextReport = {
    nextReportName: '',
    nextFolderName: '',
    nextFolderId: '',
  };
  const [nextReport, setNextReport] = useState(defaultNextReport);

  const {
    className,
    mounted,
    report = {},
    folderData = {},
    onSaveAsReport,
    onDismiss,
  } = props;

  const {
    nextReportName = '',
    nextFolderId = '',
    newFolderName = '',
  } = nextReport;

  const { owner = {}, name: folderName = '' } = folderData;
  const ownerNameValue = `${owner.code} - ${owner.firstname} ${owner.lastname}`;

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const requiredFields =
    saveType === EXISTING
      ? ['nextReportName', 'nextFolderId']
      : ['nextReportName', 'newFolderName'];

  const { isValid, invalidFields = [] } = validate(nextReport, requiredFields);

  const touchAll = () => touch(requiredFields);
  const untouchAll = () => untouch(requiredFields);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getReportsFoldersAbortControllerRef],
    }),
    []
  );

  useEffect(
    getAllFoldersEffect({
      t,
      mounted,
      api,
      setLoadingFolders,
      setFolders,
      getReportsFoldersAbortControllerRef,
    }),
    [mounted]
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ setObject: setNextReport }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const title = t('components.SaveAsModal.title');

  return (
    <Modal
      className={classnames('save-as-modal', className)}
      cancelButtonText={t('common.cancel')}
      onClickCancel={onDismiss}
      titleText={title}
      mounted={mounted}
      initialFocus={'#next-report-name'}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{title}</h2>
      <h3>{t('components.SaveAsModal.origin')}</h3>
      <div className="form-row">
        <InputHelp
          validationContent={t('components.SaveAsModal.reportNameError')}
          invalid={
            isTouched('reportName') && invalidFields.includes('reportName')
          }
        >
          <InputLabel content={t('components.SaveAsModal.reportName')}>
            <input
              type="text"
              name="reportName"
              id="report-name"
              value={report.reportName}
              readOnly
            />
          </InputLabel>
        </InputHelp>
      </div>

      <div className="form-row">
        <InputLabel content={t('components.SaveAsModal.owner')}>
          <input
            type="text"
            name="owner"
            id="owner"
            value={ownerNameValue}
            readOnly
          />
        </InputLabel>

        <InputLabel content={t('components.SaveAsModal.folderName')}>
          <input
            type="text"
            name="folderName"
            id="folderName"
            value={folderName}
            readOnly
          />
        </InputLabel>
      </div>

      <h3>{t('components.SaveAsModal.destination')}</h3>
      <div className="form-row">
        <InputHelp
          validationContent={t('components.SaveAsModal.nextReportNameError')}
          invalid={
            isTouched('nextReportName') &&
            invalidFields.includes('nextReportName')
          }
        >
          <InputLabel content={t('components.SaveAsModal.reportName')}>
            <input
              type="text"
              name="nextReportName"
              id="next-report-name"
              value={nextReportName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>
      </div>
      {saveType === EXISTING && (
        <div className="form-row input-button-container">
          <button
            className="button-link-appearance"
            onClick={() => setSaveType(NEW)}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            {t('components.SaveAsModal.newFolderButton')}
          </button>

          <InputHelp
            validationContent={t('components.SaveAsModal.nextFolderIdError')}
            invalid={
              isTouched('nextFolderId') &&
              invalidFields.includes('nextFolderId')
            }
          >
            <InputLabel content={t('components.SaveAsModal.nextFolderName')}>
              <StyledSelect
                name="nextFolderId"
                value={nextFolderId}
                disabled={loadingFolders}
                {...inputEventHandlers}
              >
                <option value="" disabled>
                  {t('components.SaveAsModal.nextFolderPlaceholder')}
                </option>
                {folders.map((folder) => (
                  <option value={folder.id} key={folder.id}>
                    {folder.name}
                  </option>
                ))}
              </StyledSelect>
            </InputLabel>
          </InputHelp>
        </div>
      )}

      {saveType === NEW && (
        <div className="form-row input-button-container">
          <button
            className="button-link-appearance"
            onClick={() => setSaveType(EXISTING)}
          >
            <FontAwesomeIcon icon={faFolder} />
            {t('components.SaveAsModal.existingFolderButton')}
          </button>

          <InputHelp
            validationContent={t('components.SaveAsModal.newFolderNameError')}
            invalid={
              isTouched('newFolderName') &&
              invalidFields.includes('newFolderName')
            }
          >
            <InputLabel content={t('components.SaveAsModal.newFolderName')}>
              <input
                type="text"
                name="newFolderName"
                id="newFolderName"
                value={newFolderName}
                {...inputEventHandlers}
              />
            </InputLabel>
          </InputHelp>
        </div>
      )}

      <div className="button-container">
        <button className="button" onClick={onDismiss}>
          {t('common.cancel')}
        </button>

        <button
          className="button button-highlight"
          onClick={onConfirmSaveAsEffect({
            isValid,
            touchAll,
            report,
            nextReport,
            saveType,
            onSaveAsReport,
            onDismiss,
            untouchAll,
            setActive,
          })}
          disabled={active}
        >
          {active && t('common.saving')}
          {!active && t('common.save')}
        </button>
      </div>
    </Modal>
  );
};

SaveAsModal.defaultProps = {
  mounted: false,
};

SaveAsModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  report: PropTypes.object.isRequired,
  onSaveAsReport: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const ValidatedSaveAsModal = (props) => (
  <ValidationProvider rules={saveAsValidationRules}>
    <SaveAsModal {...props} />
  </ValidationProvider>
);

ValidatedSaveAsModal.propTypes = { ...SaveAsModal.propTypes };

export default ValidatedSaveAsModal;
