import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const getLegalProblemCodesEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setLegalProblemCodes,
    legalProblemCodesAbortControllerRef,
  } = options;
  return async () => {
    const endpoint = `/Reports/feedback/legalproblemcodes`;
    setActive(true);

    rotateAbortControllerRef(legalProblemCodesAbortControllerRef);
    const { signal } = legalProblemCodesAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ReportListPage.searchError'),
            },
          },
        }
      );
      setLegalProblemCodes(json);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const runFeedbackReportEffect = (options = {}) => {
  const {
    t,
    api,
    runningReport,
    reportBody,
    setActive,
    postReportAbortControllerRef,
  } = options;

  return async () => {
    setActive(true);
    const { endpoint } = runningReport;
    const {
      startDate = '',
      endDate = '',
      lawyerId = '',
      legalProblemCode = {},
      yearEndFeedbackReportType = '',
    } = reportBody;
    rotateAbortControllerRef(postReportAbortControllerRef);
    const { signal } = postReportAbortControllerRef.current;

    const body = {
      startDate,
      endDate,
      yearEndFeedbackReportType,
      lawyerId,
      legalProblemCode,
    };

    try {
      const { response = {} } = await api.fetch(
        endpoint,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          signal,
          body: JSON.stringify(body),
        },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.FeedbackReportPage.requestError'),
            },
          },
        }
      );

      const nextResponse = await response.blob();
      const documentUri = URL.createObjectURL(nextResponse);

      if (documentUri) {
        window.open(documentUri, '_blank', 'noopener,noreferrer');
      }
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const onChangeEffect = (options = {}) => {
  const { reportBody, setReportBody } = options;
  return (name, value) => {
    setReportBody({ ...reportBody, [name]: value });
  };
};

export const onCloseModalEffect = (options = {}) => {
  const { setRunningReport, setReportBody, defaultReportBody } = options;
  return () => {
    setRunningReport({});
    setReportBody(defaultReportBody);
  };
};
