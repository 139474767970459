import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'js/components/design-system';
import {
  clientTabItems,
  queryFilteringSearchParamsForClientTabs,
} from './functions';
import { GroupPermissionsContext } from 'js/components/group-permissions/context';
import { userGroups } from 'js/components/group-permissions';

const ClientTabs = (props) => {
  const { disabled, showClientNotesCount, clientNotesCount } = props;
  const {
    path,
    params: { id, clientCode },
  } = useRouteMatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = queryFilteringSearchParamsForClientTabs(search);
  const { hasLoadedGroups, matchAnyGroup } = useContext(
    GroupPermissionsContext
  );
  const isAdmin =
    hasLoadedGroups &&
    matchAnyGroup([userGroups.administrator, userGroups.dataEntry]);

  const items = clientTabItems({
    t,
    path,
    params: { id, clientCode },
    search: query.search,
    showClientNotesCount,
    clientNotesCount,
    isAdmin,
  });

  return (
    <Tabs
      className="client-tabs"
      items={items}
      currentPath={path}
      disabled={disabled}
    />
  );
};

ClientTabs.propTypes = {
  disabled: PropTypes.bool,
  showClientNotesCount: PropTypes.bool,
  clientNotesCount: PropTypes.number,
};

export default ClientTabs;
