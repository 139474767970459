import config from 'js/config';

export const getVersionInfoEffect = (options = {}) => {
  const { api, isReady, account, setVersionInfo, setHasLoaded } = options;
  return async () => {
    if (!isReady) {
      return;
    }

    // The /Account/whatdis endpoint requires an access token,
    // so prevent the request if the user hasn't authenticated.
    if (!account) {
      setHasLoaded(true);
      return;
    }

    try {
      const { json } = await api.getJson(
        '/Account/whatdis',
        {},
        { success: { bypass: true }, error: { bypass: true } }
      );
      setVersionInfo(json);
      setHasLoaded(true);
    } catch (error) {
      if (config.debug) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      setHasLoaded(true);
    }
  };
};

export const setMountedEffect = (options = {}) => {
  const { setMounted, setMenuVisibility } = options;
  return () => {
    setMounted(true);
    setMenuVisibility(false);
  };
};
