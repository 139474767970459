import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchResultsCard from './card';

const SearchResults = (props) => {
  const { className, model, results = [] } = props;
  return (
    <div className={classnames('search-results', className)}>
      {results.map((data, idx) => (
        <SearchResultsCard key={idx} model={model} data={data} />
      ))}
    </div>
  );
};

SearchResults.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SearchResults;
