import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { NamedPermissionsProvider } from 'js/components/group-permissions';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import ReportResultModal from 'js/components/pages/reports/reports-folder-detail/report-result-modal';
import { isEmpty } from 'js/utilities/validation';
import ClientPrintHeader from '../client-print-header';
import ClientPage from '../client-page';
import { ClientContext, ClientProvider } from '../client-context';
import { sharedClientNamedPermissions } from '../client-named-permissions';
import {
  onMountEffect,
  getCaseHistoryEffect,
  getCaseHistoryReportEffect,
  onSelectCaseEffect,
} from './effects';
import { getCaseHistoryColumns } from './functions';

const ClientCaseHistory = (props) => {
  const { match = {}, history } = props;
  const {
    params: { id, clientCode = '00' },
  } = match;
  const { t } = useTranslation();
  const { client } = useContext(ClientContext);
  const { api = {} } = useContext(FetchContext);

  const getCaseHistoryAbortControllerRef = useRef(null);
  const getReportAbortControllerRef = useRef(null);

  const [isLoadingCaseHistory, setLoadingCaseHistory] = useState(false);
  const [caseHistory, setCaseHistory] = useState([]);
  const [isLoadingReport, setLoadingReport] = useState(false);
  const [reportResult, setReportResult] = useState({});

  const isActive = isLoadingCaseHistory || isLoadingReport;

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseHistoryAbortControllerRef,
        getReportAbortControllerRef,
      ],
      setLoadingCaseHistory,
    }),
    []
  );

  useEffect(
    getCaseHistoryEffect({
      t,
      api,
      id,
      clientCode,
      setLoadingCaseHistory,
      setCaseHistory,
      getCaseHistoryAbortControllerRef,
    }),
    []
  );

  const getCaseHistoryReport = getCaseHistoryReportEffect({
    t,
    api,
    id,
    clientCode,
    setLoadingReport,
    setReportResult,
    getReportAbortControllerRef,
  });

  return (
    <ClientPage
      className="client-case-history"
      title={t('components.ClientCaseHistory.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() => window.print()}
            disabled={isActive}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={getCaseHistoryReport}
            disabled={isActive}
          >
            {isLoadingReport
              ? t('components.ClientCaseHistory.generatingReport')
              : t('components.ClientCaseHistory.generateReport')}
          </button>
        </Fragment>
      }
    >
      <ClientPrintHeader
        title={t('components.ClientCaseHistory.printHeaderTitle')}
        client={client}
        displayClientId
      />
      {isLoadingCaseHistory && <ActivityIndicatorCard />}

      {!isLoadingCaseHistory && (
        <Fragment>
          {caseHistory.length === 0 && (
            <EmptyState
              title={t('components.ClientCaseHistory.emptyStateMessage')}
            />
          )}
          {caseHistory.length > 0 && (
            <SortableTable
              columns={getCaseHistoryColumns(t)}
              data={caseHistory}
              initialSortColumnKey={'openDate'}
              initialSortDirection={tableSortDirection.desc}
              onClickRow={onSelectCaseEffect({ history })}
              printManaged={true}
            />
          )}
        </Fragment>
      )}

      {!isEmpty(reportResult) && (
        <ReportResultModal
          onDismiss={() => setReportResult({})}
          reportResult={reportResult}
        />
      )}
    </ClientPage>
  );
};

const ClientCaseHistoryWithContext = (props) => (
  <ClientProvider>
    <ClientCaseHistory {...props} />
  </ClientProvider>
);

const ClientCaseHistoryWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={sharedClientNamedPermissions}>
    <ClientCaseHistoryWithContext {...props} />
  </NamedPermissionsProvider>
);

export default ClientCaseHistoryWithNamedPermissions;
