import React, { createContext, useState } from 'react';
import {
  incrementInteractionCountEffect,
  resetInteractionCountEffect,
} from './effects';

const InteractionTrackingContext = createContext({
  interactionCount: 0,
  incrementInteractionCount: () => {},
  resetInteractionCount: () => {},
});

const InteractionTrackingProvider = ({ children }) => {
  const [interactionCount, setInteractionCount] = useState(0);
  return (
    <InteractionTrackingContext.Provider
      value={{
        interactionCount,
        incrementInteractionCount: incrementInteractionCountEffect({
          setInteractionCount,
        }),
        resetInteractionCount: resetInteractionCountEffect({
          setInteractionCount,
        }),
      }}
    >
      {children}
    </InteractionTrackingContext.Provider>
  );
};

export { InteractionTrackingContext, InteractionTrackingProvider };
