import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { MSALProvider } from 'js/components/msal';
import { DataProvider, defaultDataDebugger } from 'js/components/data';
import { StyledBannerProvider } from 'js/components/banner-styled';
import { CurrentUserProvider } from 'js/components/current-user';
import { VersionInfoProvider } from 'js/components/version-info';
import { GroupPermissionsProvider } from 'js/components/group-permissions';
import { Router, Routes } from 'js/components/router';
import { PromptProvider } from 'js/components/prompt';
import PrintManager from 'js/components/print-manager';
import config from 'js/config';
import AppFetchProvider from './app-fetch-provider';
import { getPromptRules } from 'js/utilities/prompts';
import { initializeSentry } from 'js/utilities/sentry';
import 'js/i18n';

const App = () => {
  const { t } = useTranslation();

  useEffect(() => initializeSentry({ t, history: createBrowserHistory() }), []);

  return (
    <MSALProvider
      config={config.msal}
      audience={config.api.audience}
      useRedirectFlow
    >
      <Router>
        <DataProvider debug={defaultDataDebugger(config.debug)}>
          <StyledBannerProvider>
            <AppFetchProvider>
              <VersionInfoProvider>
                <CurrentUserProvider>
                  <GroupPermissionsProvider>
                    <PromptProvider rules={getPromptRules(t)}>
                      <PrintManager>
                        <Routes />
                      </PrintManager>
                    </PromptProvider>
                  </GroupPermissionsProvider>
                </CurrentUserProvider>
              </VersionInfoProvider>
            </AppFetchProvider>
          </StyledBannerProvider>
        </DataProvider>
      </Router>
    </MSALProvider>
  );
};

export default Sentry.withProfiler(App);
