import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { databaseBackupsBreadcrumbsHistory } from './functions';

const DatabaseBackupsBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="database-backups-breadcrumbs"
      history={databaseBackupsBreadcrumbsHistory(t, location)}
    />
  );
};

export default DatabaseBackupsBreadcrumbs;
