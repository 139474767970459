import { isEmpty, validateDate } from 'js/utilities/validation';

export const storage = localStorage;

export const BOOL_OPERATORS = {
  AND: 'AND',
  OR: 'OR',
};

export const OPERATOR_VALUES = [
  'equal',
  'notEqual',
  'greaterThan',
  'greaterOrEqual',
  'lessThan',
  'lessOrEqual',
  'begins',
  'contains',
  'notContains',
  'like',
  'isEmpty',
  'notEmpty',
  'oneOf',
];

export const getOperatorOptions = (t) =>
  OPERATOR_VALUES.map((value) => ({
    title: t(`components.RunEditReportModal.operators.${value}`),
    value,
  }));

export const REPORT_FORMATS = [
  'PDF',
  'Xml',
  'Word',
  'Excel',
  'RichText',
  'EditableRichText',
];

export const DATE_RANGE_FILTER = {
  0: '',
  1: 'generateMonthly',
  2: 'generateFirstQuarterly',
  3: 'generateHalfYearly',
  4: 'generateThirdQuarterly',
  5: 'generateYearly',
};

export const getReportFormatOptions = (
  t,
  setRunParams,
  setSelectedFormatIndex
) => {
  return REPORT_FORMATS.map((format) => ({
    content: t(`components.RunEditReportModal.formats.${format}`),
    onSelect: (e, index) => {
      setRunParams((runParams) => ({
        ...runParams,
        reportOutputType: REPORT_FORMATS[index],
      }));
      setSelectedFormatIndex(index);
    },
  }));
};

export const INITIAL_REPORT_FORMAT_INDEX = 0;

export const INITIAL_RUN_PARAMS = {
  startDate: null,
  endDate: null,
  status: '',
  reportOutputType: REPORT_FORMATS[INITIAL_REPORT_FORMAT_INDEX],
};

export const equalToString = (fieldName, fieldValue) => {
  return `{${fieldName}} = "${fieldValue}"`;
};

export const notEqualToString = (fieldName, fieldValue) => {
  return `{${fieldName}} <> "${fieldValue}"`;
};

export const greaterThanString = (fieldName, fieldValue) => {
  return `{${fieldName}} > ${fieldValue}`;
};

export const greaterOrEqualString = (fieldName, fieldValue) => {
  return `{${fieldName}} >= ${fieldValue}`;
};

export const lessOrEqualString = (fieldName, fieldValue) => {
  return `{${fieldName}} <= ${fieldValue}`;
};

export const lessThanString = (fieldName, fieldValue) => {
  return `{${fieldName}} < ${fieldValue}`;
};

export const beginsWithString = (fieldName, fieldValue) => {
  return `{${fieldName}} StartsWith "${fieldValue}"`;
};

export const containsString = (fieldName, fieldValue) => {
  return `"${fieldValue}" in {${fieldName}}`;
};

export const doesNotContainString = (fieldName, fieldValue) => {
  return `not ("${fieldValue}" in {${fieldName}})`;
};

export const likeString = (fieldName, fieldValue) => {
  return `{${fieldName}} Like "${fieldValue}"`;
};

export const isEmptyString = (fieldName) => {
  return `{${fieldName}} = ""`;
};

export const isNotEmptyString = (fieldName) => {
  return `{${fieldName}} <> ""`;
};

export const isOneOfString = (fieldName, fieldValue) => {
  const list = fieldValue.split(',');
  const strings = list.map((i) => `"${i}"`);
  return `{${fieldName}} in [${strings}]`;
};

export const returnFunctionString = (options = {}) => {
  const { operator, fieldName, value } = options;

  switch (operator) {
    case 'equal':
      return equalToString(fieldName, value);
    case 'notEqual':
      return notEqualToString(fieldName, value);
    case 'begins':
      return beginsWithString(fieldName, value);
    case 'contains':
      return containsString(fieldName, value);
    case 'notContains':
      return doesNotContainString(fieldName, value);
    case 'like':
      return likeString(fieldName, value);
    case 'isEmpty':
      return isEmptyString(fieldName);
    case 'notEmpty':
      return isNotEmptyString(fieldName);
    case 'oneOf':
      return isOneOfString(fieldName, value);
    case 'greaterThan':
      return greaterThanString(fieldName, value);
    case 'greaterOrEqual':
      return greaterOrEqualString(fieldName, value);
    case 'lessOrEqual':
      return lessOrEqualString(fieldName, value);
    case 'lessThan':
      return lessThanString(fieldName, value);
    default:
      return '';
  }
};

export const filterValidationRules = {
  //these are used for the inner form for updating the script
  //value is not included because it's too hard to track here
  //validation for value is in state
  fieldName: (data, key) => !isEmpty(data[key]),
  operator: (data, key) => !isEmpty(data[key]),
};

export const filterRequiredFields = Object.keys(filterValidationRules);

export const runEditReportValidationRules = (useRunMode = false) => ({
  //these are used for the general form, watching script length and name
  reportName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  startDate: (data, key) => validateDate(data[key], useRunMode),
  endDate: (data, key) => validateDate(data[key], useRunMode),
});

export const runReportRequiredFields = ['startDate', 'endDate'];
export const editReportRequiredFields = ['reportName'];

export const resolveRunParamsForReport = (report = {}, runParams = {}) => {
  const { filter = '' } = report;

  // Filter out keys that are only used locally:
  const nextRunParams = Object.keys(runParams).reduce((next, key) => {
    if (key !== 'dateRange') {
      return { ...next, [key]: runParams[key] };
    } else {
      return next;
    }
  }, {});

  return { ...nextRunParams, filterQuery: filter };
};

export const findCaseStatus = (filter = '') => {
  const cleanedUp = filter
    .replaceAll(/\s+/g, '')
    .replaceAll(/\\"/g, '"')
    .toLowerCase();
  const pattern = new RegExp('{case.cas_status}="([a-zA-Z])"');
  const matches = pattern.exec(cleanedUp);
  if (matches && matches.length >= 2) {
    return matches[1].toUpperCase();
  } else {
    return null;
  }
};
