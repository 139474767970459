export const defaultSortPredicate = (field, direction, item1, item2) => {
  const value1 = item1[field];
  const value2 = item2[field];

  if (value1 !== value2) {
    if (direction === 'desc') {
      return value1 > value2 ? -1 : 1;
    } else {
      return value1 > value2 ? 1 : -1;
    }
  } else {
    return 0;
  }
};
