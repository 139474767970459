import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import Page from 'js/components/page';
import SearchHeader from 'js/components/headers/search-header';
import { NoteCard, ActivityIndicatorCard } from 'js/components/design-system';
import NoteModal from 'js/components/note-modal';
import NotesSortMenu from 'js/components/menus/notes-sort-menu';
import EmptyState from 'js/components/controls/empty-state';
import { PrintFooter } from 'js/components/print';
import { userGroups } from 'js/components/group-permissions';
import ClientPrintHeader from '../client-print-header';
import {
  onMountEffect,
  getClientEffect,
  getNotesEffect,
  sortNotesEffect,
  onChangeSortEffect,
  presentNoteModalEffect,
  postNoteEffect,
  onCompleteNoteEffect,
  deleteNoteEffect,
} from './effects';
import { queryForSearchString } from './functions';
import ClientOutstndingBalanceNotesBreadcrumbs from './breadcrumbs';
import DeleteNoteModal from './delete-note-modal';

const ClientOutstandingBalanceNotes = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = queryForSearchString(search);
  const getClientAbortControllerRef = useRef(null);
  const getNotesAbortControllerRef = useRef(null);
  const postNoteAbortControllerRef = useRef(null);
  const deleteNoteAbortControllerRef = useRef(null);
  const [clientActive, setClientActive] = useState(false);
  const [notesActive, setNotesActive] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [client, setClient] = useState({});
  const [notes, setNotes] = useState([]);
  const [noteIdPendingDeletion, setNoteIdPendingDeletion] = useState('');
  const [presentNoteModal, setPresentNoteModal] = useState(false);
  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const {
    params: { id, clientCode = '00' },
  } = useRouteMatch();
  const { clientPersonal = {} } = client;
  const { firstName = '', lastName = '' } = clientPersonal;
  const active = clientActive || notesActive;

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getClientAbortControllerRef,
        getNotesAbortControllerRef,
        postNoteAbortControllerRef,
        deleteNoteAbortControllerRef,
      ],
      setClientActive,
      setNotesActive,
      setDeleting,
    }),
    []
  );

  useEffect(
    getClientEffect({
      t,
      api,
      cache,
      id,
      clientCode,
      setClientActive,
      setClient,
      getClientAbortControllerRef,
    }),
    [id, clientCode]
  );

  const getNotes = getNotesEffect({
    t,
    api,
    id,
    clientCode,
    query,
    setNotesActive,
    setNotes,
    getNotesAbortControllerRef,
  });

  const dismissNoteModal = presentNoteModalEffect({
    setPresentNoteModal,
    willPresent: false,
  });

  useEffect(getNotes, []);

  useEffect(
    sortNotesEffect({
      query,
      setNotes,
    }),
    [query.search]
  );

  return (
    <Page
      className="client-outstanding-balance-notes"
      title={t('components.ClientOutstandingBalanceNotes.title', {
        firstName,
        lastName,
      })}
      header={<SearchHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <ClientOutstndingBalanceNotesBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          <ClientPrintHeader
            title={t(
              'components.ClientOutstandingBalanceNotes.printHeaderTitle'
            )}
            client={client}
          />
          {active && <ActivityIndicatorCard />}
          {!active && (
            <Fragment>
              <div className="client-outstanding-balance-notes-heading">
                <p>{t('components.ClientOutstandingBalanceNotes.subtitle')}</p>
                <h1>
                  {lastName}, {firstName}
                </h1>
              </div>
              <div className="client-outstanding-balance-notes-actions">
                <div>
                  {notes.length > 0 && (
                    <NotesSortMenu
                      field={query.params.orderBy}
                      direction={query.params.order}
                      onSelect={onChangeSortEffect({ history, query })}
                    />
                  )}
                </div>
                <div>
                  <button
                    className="button button-highlight page-action-button"
                    disabled={active || notes.length === 0}
                    onClick={() => window.print()}
                  >
                    {t('common.print')}
                  </button>
                  <button
                    className="button button-highlight page-action-button"
                    disabled={active}
                    onClick={presentNoteModalEffect({
                      setPresentNoteModal,
                      willPresent: true,
                    })}
                  >
                    {t('components.ClientOutstandingBalanceNotes.addNote')}
                  </button>
                </div>
              </div>
              {notes.length === 0 && (
                <EmptyState
                  title={t(
                    'components.ClientOutstandingBalanceNotes.emptyStateMessage'
                  )}
                />
              )}
              {notes.length > 0 && (
                <div className="note-cards-list" data-print-managed>
                  {notes.map((note) => (
                    <NoteCard
                      key={note.id}
                      onClickDelete={setNoteIdPendingDeletion}
                      permittedGroups={[
                        userGroups.administrator,
                        userGroups.dataEntry,
                        userGroups.intakeSpecialist,
                        userGroups.feedbackSpecialist,
                        userGroups.support,
                      ]}
                      permittedGroupsForDelete={[
                        userGroups.administrator,
                        userGroups.dataEntry,
                        userGroups.intakeSpecialist,
                      ]}
                      {...note}
                    />
                  ))}
                </div>
              )}
            </Fragment>
          )}
          <PrintFooter />
          <NoteModal
            mounted={presentNoteModal}
            onSubmit={postNoteEffect({
              t,
              api,
              id,
              clientCode,
              postNoteAbortControllerRef,
            })}
            onComplete={onCompleteNoteEffect({
              dismissNoteModal,
              getNotes,
            })}
            onClickCancel={dismissNoteModal}
          />
          <DeleteNoteModal
            mounted={!!noteIdPendingDeletion}
            isDeleting={isDeleting}
            onClose={() => setNoteIdPendingDeletion('')}
            onClickDelete={deleteNoteEffect({
              t,
              api,
              id,
              clientCode,
              noteIdPendingDeletion,
              setNoteIdPendingDeletion,
              setDeleting,
              getNotes,
              deleteNoteAbortControllerRef,
            })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ClientOutstandingBalanceNotes;
