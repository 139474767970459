import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'js/utilities/dates';
import logo from 'assets/unifor-logo.png';

const PrintHeader = (props) => {
  const { t } = useTranslation();
  const { className, title, heading, printOnly } = props;
  return (
    <div
      className={classnames('print-header', className)}
      data-print-managed
      data-print-only={printOnly}
    >
      <img className="print-header-logo" src={logo} alt={t('common.logoAlt')} />
      <div className="print-header-content">
        <div className="print-header-title">{title}</div>
        <h1>{heading}</h1>
      </div>
      <div className="print-header-date">{formatDate(Date.now())}</div>
    </div>
  );
};

PrintHeader.defaultProps = {
  printOnly: true,
};

PrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  printOnly: PropTypes.bool,
};

export default PrintHeader;
