import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { isExecutableQuery } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getCoopOptions = (options = {}) => {
  const {
    t,
    api,
    abortControllerRef,
    setActive,
    legalProblemCode,
    setProvinces,
    setPanels,
  } = options;

  return async () => {
    if (!legalProblemCode) {
      return;
    }
    const endpoint = `/Lawyer/CoopLawyers/Options?LegalProblemCode=${legalProblemCode}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;
    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.CoopLawyerModal.searchError') },
          },
        }
      );
      const { lawyerPanelOptions = [], provinceOptions = 0 } = json;
      setProvinces(provinceOptions);
      setPanels(lawyerPanelOptions);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const coopLawyerRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setCoopLawyers,
    setTotalResults,
    abortControllerRef,
    setCityOptions,
    setLawyerSubPanelOptions,
    setHidden,
    firstCall,
    setFirstCall,
  } = options;
  return async () => {
    if (!query.search || firstCall) {
      return;
    }

    if (!isExecutableQuery(query)) {
      setCoopLawyers([]);
      setCityOptions([]);
      setLawyerSubPanelOptions([]);
      setTotalResults(0);
      return;
    }

    const endpoint = `/Lawyer/CoopLawyers/${query.search}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.CoopLawyerModal.searchError') },
          },
        }
      );
      const {
        coopLawyerInfo = [],
        totalCount = 0,
        cityOptions = [],
        lawyerSubPanelOptions = [],
      } = json;
      setCoopLawyers(coopLawyerInfo);
      setCityOptions(cityOptions);
      setLawyerSubPanelOptions(lawyerSubPanelOptions);
      setTotalResults(totalCount);
      setHidden(false);
      setActive(false);
      setFirstCall(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousCoopLawyerResultsEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    onChange({ nextQueryParams: nextParams });
  };
};

export const nextCoopLawyerResultsEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    onChange({ nextQueryParams: nextParams });
  };
};

export const coopLawyerResultsPageNumberEffect = (options = {}) => {
  const {
    query: { params = {} },
    onChange,
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    onChange({ nextQueryParams: nextParams });
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange, clear } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      if (clear) {
        const nextQueryParams = { [name]: value, [clear]: '', Offset: 0 };
        onChange({ nextQueryParams });
      } else {
        const nextQueryParams = { [name]: value, Offset: 0 };
        onChange({ nextQueryParams });
      }
    }
  };
};

export const onSearchChangeEffect = (options = {}) => {
  const { onChange, setFirstCall } = options;

  return (value = '') => {
    onChange({ param: 'Keyword', value });
    if (typeof setFirstCall === 'function' && value !== '') {
      setFirstCall(false);
    }
  };
};
