import { render, unmountComponentAtNode } from 'react-dom';
import { v4 as uuid } from 'uuid';

/* Utility */

export const topx = (value) => `${value}px`;

/* Banner Options */

export const defaultBannerOptions = {
  className: '',
  // Time in ms after which the banner will be dismissed,
  // or zero to require manual dismissal:
  timeout: 0,
  // Set false to disable the progress animation when timeout > 0:
  progress: true,
  // Set false to disable the initial opacity animation:
  animated: true,
  // Text or node to render as content:
  content: null,
  // Text or node to render as dismiss button content,
  // or null/falsey to omit the dismiss button:
  dismissButtonContent: '\u00d7',
  // Function to call when the banner is dismissed via the dismiss button:
  onDismiss: null,
};

/* Top-level Presentation Functions */

export const presentBanner = (container, bannerOptions = {}) => {
  const resolvedBannerOptions = { ...defaultBannerOptions, ...bannerOptions };
  const {
    className,
    timeout,
    progress,
    animated,
    content,
    dismissButtonContent,
    onDismiss,
  } = resolvedBannerOptions;

  const bannerId = uuid();
  const bannerClassNames = ['banner', ...className.split(/\s+/)].filter(
    (cls) => !!cls
  );

  const banner = document.createElement('div');
  banner.classList.add(...bannerClassNames);
  banner.setAttribute('data-banner-id', bannerId);
  banner.setAttribute('data-banner-timeout', timeout);
  banner.setAttribute('data-banner-progress', progress ? '1' : '0');
  banner.addEventListener('transitionend', onBannerTransitionEnd);

  const progressContainer = document.createElement('div');
  progressContainer.classList.add('banner-progress');

  const progressValue = document.createElement('div');
  progressValue.classList.add('banner-progress-value');

  progressContainer.appendChild(progressValue);

  const bannerContent = document.createElement('div');
  bannerContent.classList.add('banner-content');

  const bannerContentWrapper = document.createElement('div');
  bannerContentWrapper.classList.add('banner-content-wrapper');

  const bannerDismissButton = document.createElement('button');
  bannerDismissButton.setAttribute('type', 'button');
  bannerDismissButton.classList.add('banner-dismiss-button');
  bannerDismissButton.addEventListener('click', () => {
    if (typeof onDismiss === 'function') {
      onDismiss(bannerId);
    } else {
      dismissBanner(container, bannerId);
    }
  });

  bannerContent.appendChild(bannerContentWrapper);

  if (dismissButtonContent) {
    bannerContent.appendChild(bannerDismissButton);
  }

  banner.appendChild(progressContainer);
  banner.appendChild(bannerContent);
  container.appendChild(banner);

  render(content, bannerContentWrapper);

  if (dismissButtonContent) {
    render(dismissButtonContent, bannerDismissButton);
  }

  if (animated) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        banner.style.opacity = 1;
      });
    });
  } else {
    banner.style.opacity = 1;
  }

  return bannerId;
};

export const dismissBanner = (container, bannerId, animated = true) => {
  if (!container) {
    return;
  }

  const banner = container.querySelector(
    `.banner[data-banner-id="${bannerId}"]`
  );
  if (banner) {
    if (animated) {
      banner.style.opacity = 0;
    } else {
      removeBanner(banner);
    }
  }
};

/* Removal and Cleanup */

const removeBanner = (banner) => {
  // Remove the banner by unmounting it and animating
  // the remaining banners to their new positions.
  if (!banner || !banner.parentElement) {
    return;
  }

  const container = banner.parentElement;
  const allBanners = Array.from(container.querySelectorAll('.banner'));
  const bannerIdx = allBanners.indexOf(banner);
  const remainingBanners = allBanners.slice(bannerIdx + 1);
  const remainingBannerStartPositions = getVerticalPositionsOfBanners(
    remainingBanners
  );

  unmountBanner(banner);

  // Reset the remaining banners to their resting positions:
  remainingBanners.forEach(resetBannerPosition);

  requestAnimationFrame(() => {
    // Get the banners' resting positions and animate to them from the start positions.
    const remainingBannerEndPositions = getVerticalPositionsOfBanners(
      remainingBanners
    );
    animateVerticalPositionsOfBanners(
      remainingBanners,
      remainingBannerStartPositions,
      remainingBannerEndPositions
    );
  });
};

const unmountBanner = (banner) => {
  // Unmount the banner's content nodes and remove the banner from its parent element.
  const bannerContentWrapper = banner.querySelector('.banner-content-wrapper');
  if (bannerContentWrapper) {
    unmountComponentAtNode(bannerContentWrapper);
  }

  const bannerDismissButton = banner.querySelector('.banner-dismiss-button');
  if (bannerDismissButton) {
    unmountComponentAtNode(bannerDismissButton);
  }

  banner.removeEventListener('transitionend', onBannerTransitionEnd);

  if (banner.parentElement) {
    banner.parentElement.removeChild(banner);
  }
};

/* Transition Event Handlers */

const onBannerTransitionEnd = (e) => {
  const { target, propertyName } = e;

  if (!target.classList.contains('banner')) {
    return;
  }

  switch (propertyName) {
    case 'top':
      onBannerPositionTransitionEnd(target);
      break;
    case 'opacity':
      onBannerOpacityTransitionEnd(target);
      break;
    default:
      break;
  }
};

const onBannerPositionTransitionEnd = (banner) => {
  // Respond to the end of a position transition on the banner.
  resetBannerPosition(banner);
};

const onBannerOpacityTransitionEnd = (banner) => {
  // Respond to the end of an opacity transition on the banner.
  // If the banner faded in, run the progress value animation and schedule dismissal.
  // If the banner faded out, remove it.
  const container = banner.parentElement;
  const bannerId = banner.getAttribute('data-banner-id');
  const timeout = parseInt(banner.getAttribute('data-banner-timeout'));
  const progress = parseInt(banner.getAttribute('data-banner-progress'));
  const opacity = parseFloat(banner.style.opacity);
  const isFadeIn = Math.round(opacity) === 1;

  if (isFadeIn) {
    if (timeout > 0) {
      if (progress) {
        animateBannerProgressValue(banner, timeout);
      }
      setTimeout(() => dismissBanner(container, bannerId), timeout);
    }
  } else {
    removeBanner(banner);
  }
};

/* Positioning and Animations */

const resetBannerPosition = (banner) => {
  // Reset the banner to the default positioning.
  banner.style.position = '';
  banner.style.top = '';
  banner.style.maxWidth = '';
};

const getVerticalPositionsOfBanners = (banners = []) => {
  // Get the current y-axis positions of an array of banners.
  return banners.reduce((positions, banner) => {
    const { y: position } = banner.getBoundingClientRect();
    const bannerId = banner.getAttribute('data-banner-id');
    return { ...positions, [bannerId]: position };
  }, {});
};

const animateVerticalPositionsOfBanners = (
  banners = [],
  startPositions = {},
  endPositions = {}
) => {
  banners.forEach((banner) => {
    if (banner.parentElement) {
      const bannerId = banner.getAttribute('data-banner-id');
      const startPosition = startPositions[bannerId];
      const endPosition = endPositions[bannerId];

      banner.style.position = 'fixed';
      banner.style.top = topx(startPosition);
      banner.style.maxWidth = topx(banner.parentElement.offsetWidth);

      requestAnimationFrame(() => {
        banner.style.top = topx(endPosition);
      });
    }
  });
};

const animateBannerProgressValue = (banner, timeout = 0) => {
  const progressValue = banner.querySelector('.banner-progress-value');
  progressValue.style.width = '0';
  progressValue.style.transition = `width ${timeout}ms linear`;

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      if (banner) {
        progressValue.style.width = topx(banner.offsetWidth);
      }
    });
  });
};
