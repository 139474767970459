import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, InputHelp } from 'js/components/design-system';
import {
  onChangeEffect,
  onKeyDownEffect,
  createDayEndDateMaskEffect,
} from './effects';

const DayEndDateInput = (props) => {
  const { t } = useTranslation();
  const dayEndDateMaskRef = useRef(null);
  const {
    className,
    inputRef,
    inputName,
    value,
    isValid,
    onChange,
    onBlur,
    onConfirm,
    onReset,
    buttonDisabled,
  } = props;

  useEffect(createDayEndDateMaskEffect({ dayEndDateMaskRef }), []);

  return (
    <div className={classnames('day-end-date-container', className)}>
      <label htmlFor="day-end-date-input">
        {t('components.controls.DayEndDateInput.label')}
      </label>
      <InputHelp
        validationContent={t('components.controls.DayEndDateInput.validation')}
        invalid={!isValid}
      >
        <div className="day-end-date-group">
          <button className="day-end-date-reset" onClick={onReset}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <input
            ref={inputRef}
            type="text"
            id="day-end-date-input"
            className="day-end-date-input"
            autoComplete="off"
            name={inputName}
            value={value}
            onChange={onChangeEffect({ dayEndDateMaskRef, onChange })}
            onBlur={onBlur}
            onKeyDown={onKeyDownEffect({ onConfirm })}
            placeholder={t('components.controls.DayEndDateInput.defaultValue')}
          />
          <button
            className="button button-highlight day-end-date-confirm"
            onClick={onConfirm}
            disabled={buttonDisabled}
          >
            {t('components.controls.DayEndDateInput.confirm')}
          </button>
        </div>
      </InputHelp>
    </div>
  );
};

DayEndDateInput.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.object,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
};

export default DayEndDateInput;
