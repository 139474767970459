import IMask from 'imask';
import { caseNumberMaskPattern } from 'js/utilities/masks';

export const createCaseNumberMaskEffect = (options = {}) => {
  const { caseNumberMaskRef } = options;
  return () => {
    caseNumberMaskRef.current = IMask.createMask({
      mask: caseNumberMaskPattern,
    });
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { onConfirm } = options;
  return (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onConfirm(e);
    }
  };
};

export const onChangeEffect = (options = {}) => {
  const { caseNumberMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = caseNumberMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};
