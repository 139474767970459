import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanClientLog } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingLogData } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingLogData(false);
    };
  };
};

export const getClientLogEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    setLogData,
    setLoadingLogData,
    getClientLogAbortControllerRef,
  } = options;

  return async () => {
    if (!clientId && !clientCode) {
      return;
    }

    setLoadingLogData(true);

    rotateAbortControllerRef(getClientLogAbortControllerRef);
    const { signal } = getClientLogAbortControllerRef.current;

    const url = `/Client/${clientId}/${clientCode}/log`;
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );
      const data = cleanClientLog(json);

      setLogData(data);
      setLoadingLogData(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingLogData(false);
        throw error;
      }
    }
  };
};
