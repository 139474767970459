import React, { Fragment } from 'react';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { cleanStringField } from 'js/utilities/strings';
import { parseDateFromISOString } from 'js/utilities/dates';
import { roundToFixedPointString } from 'js/utilities/numbers';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const releaseBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = adminToolsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.FeeSchedulePage.title'),
      path: routePaths.feeSchedulesActive,
    },
    {
      content: t('components.ReleasePage.title'),
      path: currentPath,
    },
  ];

  return history;
};

export const releaseTabItems = (t, path = '', params = {}) => {
  const { scheduleId = '', effectiveDate = '', isCopy = '' } = params;
  const lpCodeRoute = isCopy
    ? routePaths.releaseLPCodesCopy
    : routePaths.releaseLPCodes;
  const companyRoute = isCopy
    ? routePaths.releaseCompaniesCopy
    : routePaths.releaseCompanies;

  const lpCodeLink = routePathReplacingParams(lpCodeRoute, {
    scheduleId,
    effectiveDate,
  });
  const companyLink = routePathReplacingParams(companyRoute, {
    scheduleId,
    effectiveDate,
  });

  const activeTab = {
    linkTo: lpCodeLink,
    isActive: () =>
      path === routePaths.releaseLPCodes ||
      path === routePaths.releaseLPCodesCopy,
    content: t('components.ReleasePage.legalProblemCodes'),
  };

  const archivedTab = {
    linkTo: companyLink,
    isActive: () =>
      path === routePaths.releaseCompanies ||
      path === routePaths.releaseCompaniesCopy,
    content: t('components.ReleasePage.companies'),
  };

  return [activeTab, archivedTab];
};

export const getReleaseColumns = (t) => {
  const columns = [
    'code',
    'name',
    'actualRate',
    'forecast',
    'csqThreshold',
  ].map((key) => ({
    key,
    headerContent: t(`components.ReleasePage.lpcTable.${key}`),
  }));

  return columns;
};

export const getLpCodeColumns = (t, renderCheckboxForRow) => {
  const lpCodeColumn = {
    key: 'code',
    headerContent: t(`components.ReleasePage.manageLpCode.code`),
    // eslint-disable-next-line react/display-name
    get: (key, row) => {
      return (
        <Fragment>
          {renderCheckboxForRow(key, row)}
          {row.code}
        </Fragment>
      );
    },
  };

  const columns = ['name'].map((key) => ({
    key,
    headerContent: t(`components.ReleasePage.manageLpCode.${key}`),
  }));

  return [lpCodeColumn, ...columns];
};

export const getManageCompanyColumns = (t, renderCheckboxForRow) => {
  const lpCodeColumn = {
    key: 'companyCode',
    headerContent: t(`components.ReleasePage.manageCompanies.companyCode`),
    // eslint-disable-next-line react/display-name
    get: (key, row) => {
      return (
        <Fragment>
          {renderCheckboxForRow(key, row)}
          {row.companyCode}
        </Fragment>
      );
    },
  };

  const columns = ['companyName'].map((key) => ({
    key,
    headerContent: t(`components.ReleasePage.manageCompanies.${key}`),
  }));

  return [lpCodeColumn, ...columns];
};

export const getCompanyColumns = (t) => {
  const columns = ['companyCode', 'companyName', 'coCity'].map((key) => ({
    key,
    headerContent: t(`components.ReleasePage.companiesTable.${key}`),
  }));

  return columns;
};

export const cleanLegalProblemCodeSelectOptions = (data = {}) => {
  //csq is mislabled on the api
  const { feeScheduleLegalProblemDetails = {}, name = '', code = '' } = data;
  let nextProblemDetails = {};
  if (feeScheduleLegalProblemDetails) {
    nextProblemDetails = feeScheduleLegalProblemDetails;
  }
  const {
    actualRate = 0,
    forcast = 0,
    csqTotalHours: csqThreshold = 0,
  } = nextProblemDetails;

  return {
    ...data,
    actualRate: roundToFixedPointString(actualRate, 2),
    forecast: roundToFixedPointString(forcast, 2),
    csqThreshold: roundToFixedPointString(csqThreshold, 2),
    code: cleanStringField(code),
    name: cleanStringField(name),
  };
};

export const cleanCompanies = (data = {}) => {
  const { companyCode = '', companyName = '' } = data;

  return {
    ...data,
    companyCode: cleanStringField(companyCode),
    companyName: cleanStringField(companyName),
    selected: true,
  };
};

export const reduceActiveLpCodes = (data = []) => {
  const activeCodes = data.reduce((next, lpc) => {
    if (lpc.selected === true) {
      return [...next, lpc.code];
    } else {
      return [...next];
    }
  }, []);

  return activeCodes;
};

export const cleanRelease = (data = []) => {
  const {
    effectiveDate,
    endDate,
    legalProblemCodeSelectOptions,
    associatedCompanies = [],
  } = data;
  return {
    ...data,
    effectiveDate: parseDateFromISOString(effectiveDate),
    endDate: parseDateFromISOString(endDate),
    associatedCompanies: associatedCompanies.map(cleanCompanies),
    legalProblemCodeSelectOptions: legalProblemCodeSelectOptions.map(
      cleanLegalProblemCodeSelectOptions
    ),
  };
};

export const createReleaseEndpoint = (scheduleId, effectiveDate) => {
  return `/FeeSchedule/${scheduleId}/release/${effectiveDate}`;
};

export const returnFilteredData = (data, searchString) => {
  return data.filter((item) => {
    return Object.values(item).reduce((next, value = '') => {
      if (value) {
        const match = value
          .toString()
          .toLowerCase()
          .includes(searchString.toLowerCase());
        return next || match;
      } else {
        return next;
      }
    }, false);
  });
};

export const returnModalCompanies = (
  eligibleCompanies = [],
  associatedCompanies = []
) => {
  const associatedCompanyCodes = associatedCompanies.map(
    (company) => company.companyCode
  );

  const nextEligible = eligibleCompanies.filter(
    (company) => !associatedCompanyCodes.includes(company.companyCode)
  );

  return [...associatedCompanies, ...nextEligible];
};

export const prepareLegalProblemsForPrint = (legalProblemCodeSelectOptions) => {
  return legalProblemCodeSelectOptions.reduce((legalProblems, option) => {
    const { selected, feeScheduleLegalProblemDetails } = option;
    if (selected && feeScheduleLegalProblemDetails) {
      const { serviceProvided } = feeScheduleLegalProblemDetails;
      const nextLegalProblem = {
        ...feeScheduleLegalProblemDetails,
        serviceProvided: cleanStringField(serviceProvided),
      };
      return [...legalProblems, nextLegalProblem];
    } else {
      return legalProblems;
    }
  }, []);
};

export const prepareLegalProblemsForPrintMemo = ({
  legalProblemCodeSelectOptions,
}) => {
  return () => prepareLegalProblemsForPrint(legalProblemCodeSelectOptions);
};
