import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import {
  isEmpty,
  validatePostalCode,
  validatePhoneNumber,
  validateDate,
} from 'js/utilities/validation';

export const validateCompanyNumber = (data = '') => {
  const charCount = data.length;
  if (charCount >= 2 && charCount <= 3) {
    return true;
  } else {
    return false;
  }
};

export const companyValidationRules = {
  companyCode: (data, key) => validateCompanyNumber(data[key]),
  companyName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  coPhone1: (data, key) => validatePhoneNumber(data[key], true),
  coPhone2: (data, key) => validatePhoneNumber(data[key], false),
  coFax: (data, key) => validatePhoneNumber(data[key], false),
  coAddr1: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  coAddr2: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  coCity: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 20;
  },
  coProv: (data, key) => !isEmpty(data[key]),
  coPcode: (data, key) => validatePostalCode(data[key], true),
  coBenstrt: (data, key) => validateDate(data[key], true),
  coBenend: (data, key) => validateDate(data[key], false),
};

export const companyRequiredFields = Object.keys(companyValidationRules);

export const queryForCompaniesList = (search = '') => {
  const {
    Keyword = '',
    SortBy = 'codes',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    SortBy,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};

export const getSaveButtonTitle = (t, isNew, active) => {
  if (isNew) {
    return active ? t('common.creating') : t('common.create');
  } else {
    return active ? t('common.saving') : t('common.save');
  }
};

export const companiesListBreadcrumbsHistory = (t) => {
  const prevHistory = adminToolsBreadcrumbsHistory(t);
  return [
    ...prevHistory,
    {
      content: t('components.CompaniesListPage.breadcrumbs.companies'),
      path: routePaths.companies,
    },
  ];
};

export const companyDetailsBreadcrumbsHistory = (t, companyCode) => {
  const prevHistory = companiesListBreadcrumbsHistory(t);
  return [
    ...prevHistory,
    {
      content:
        companyCode === 'new'
          ? t('components.CompanyDetailsPage.breadcrumbs.new')
          : t('components.CompanyDetailsPage.breadcrumbs.details'),
      path: routePathReplacingParams(routePaths.company, { companyCode }),
    },
  ];
};

export const getCompanyPrintAddress = (company = {}) => {
  const { coAddr1, coAddr2, coCity, coProv, coPcode } = company;
  const lines = [coAddr1, coAddr2, `${coCity} ${coProv}`, coPcode];
  return lines
    .map((line) => line.trim())
    .filter((line) => !!line)
    .join('<br />');
};
