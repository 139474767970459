import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputLabel = (props) => {
  const {
    className,
    element: Element,
    content,
    disabled,
    contentAfterChildren,
    children,
  } = props;
  const dynamicClassNames = {
    'input-label-disabled': disabled,
  };

  const contentElement = <div className="input-label-content">{content}</div>;

  return (
    <Element
      className={classnames('input-label', dynamicClassNames, className)}
    >
      {content && !contentAfterChildren && contentElement}
      {children}
      {content && contentAfterChildren && contentElement}
    </Element>
  );
};

InputLabel.defaultProps = {
  element: 'label',
  disabled: false,
  contentAfterChildren: false,
};

InputLabel.propTypes = {
  className: PropTypes.string,
  element: PropTypes.string,
  content: PropTypes.node,
  disabled: PropTypes.bool,
  contentAfterChildren: PropTypes.bool,
};

export default InputLabel;
