import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { onActionKeyDownEffect } from './effects';
import { actionLinkPropTypes, fabActionHeight } from './functions';

const FABActionLink = (props) => {
  const { className, action = {}, tabIndex } = props;
  const {
    title,
    icon,
    linkTo,
    linkHref,
    linkProps,
    onClick,
    disabled,
  } = action;

  const commonProps = {
    ...linkProps,
    className: classnames(
      'fab-action',
      'fab-action-link',
      { 'fab-action-link-disabled': disabled },
      className
    ),
    style: { height: fabActionHeight },
  };

  const content = (
    <Fragment>
      {icon && <span className="fab-action-icon">{icon}</span>}
      <span className="fab-action-title">{title}</span>
    </Fragment>
  );

  if (disabled) {
    return <span {...commonProps}>{content}</span>;
  } else if (linkTo) {
    return (
      <Link
        {...commonProps}
        to={linkTo}
        onClick={onClick}
        onKeyDown={onActionKeyDownEffect()}
        tabIndex={tabIndex}
      >
        {content}
      </Link>
    );
  } else if (linkHref) {
    return (
      <a
        {...commonProps}
        href={linkHref}
        onClick={onClick}
        onKeyDown={onActionKeyDownEffect()}
        tabIndex={tabIndex}
      >
        {content}
      </a>
    );
  } else {
    return null;
  }
};

FABActionLink.defaultProps = {
  linkProps: {},
};

FABActionLink.propTypes = {
  className: PropTypes.string,
  action: actionLinkPropTypes,
  tabIndex: PropTypes.number,
};

export default FABActionLink;
