import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { breadcrumbTypes } from 'js/components/design-system';
import {
  paramsFromSearchString,
  searchStringFromParams,
  queryForParams,
} from 'js/utilities/params';
import { cleanStringField } from 'js/utilities/strings';
import {
  formatDateFromISOString,
  parseDateFromISOString,
} from 'js/utilities/dates';
import { defaultSortPredicate } from 'js/utilities/sort';

export const clientOutstndingBalanceNotesBreadcrumbsHistory = (
  t,
  location = {},
  match = {}
) => {
  const { pathname, search } = location;
  const {
    params: { id, clientCode },
  } = match;
  const { from: fromLocation } = paramsFromSearchString(search);
  const clientPathname = routePathReplacingParams(routePaths.client, {
    id,
    clientCode,
  });
  const clientSearch = fromLocation
    ? searchStringFromParams({ from: fromLocation })
    : '';
  const clientPath = `${clientPathname}${clientSearch}`;
  const currentPath = `${pathname}${search}`;
  const history = [breadcrumbTypes.home];

  if (fromLocation) {
    history.push({
      content: t('components.ClientPage.breadcrumbs.search'),
      path: fromLocation,
    });
  }

  history.push({
    content: t('components.ClientPage.breadcrumbs.client'),
    path: clientPath,
  });

  history.push({
    content: t('components.ClientPage.breadcrumbs.outstandingBalanceNotes'),
    path: currentPath,
  });

  return history;
};

export const cleanOutstandingBalanceNotesItem = (item = {}) => {
  const parsedDate = parseDateFromISOString(item.date);
  const timestamp = parsedDate ? parsedDate.getTime() : 0;
  return {
    ...item,
    text: cleanStringField(item.text),
    name: cleanStringField(item.name),
    date: formatDateFromISOString(item.date),
    timestamp,
  };
};

export const cleanOutstandingBalanceNotes = (notes = []) => {
  return notes.map(cleanOutstandingBalanceNotesItem);
};

export const queryForSearchString = (search = '') => {
  const {
    orderBy = 'timestamp',
    order = 'desc',
    ...otherParams
  } = paramsFromSearchString(search);

  const params = {
    ...otherParams,
    orderBy,
    order,
  };

  return queryForParams(params);
};

export const sortOutstandingBalanceNotes = (notes = [], query = {}) => {
  const { params = {} } = query;
  const { orderBy, order } = params;

  if (orderBy && order) {
    return [...notes].sort((item1, item2) =>
      defaultSortPredicate(orderBy, order, item1, item2)
    );
  } else {
    return notes;
  }
};
