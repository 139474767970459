import {
  clientIdPattern,
  namePattern,
  validatePhoneNumber,
  validateYear,
  validateDate,
  validateEmail,
  validatePostalCode,
  isEmpty,
} from 'js/utilities/validation';

export const personalInfoValidationRules = (
  isNew = false,
  isPrimaryClient = false
) => {
  return {
    clientId: clientIdPattern,
    firstName: namePattern,
    lastName: namePattern,
    phoneNumber: (data, key) => validatePhoneNumber(data[key], isNew),
    emailAddress: (data, key) => validateEmail(data[key]),
    dateBirth: (data, key) =>
      validateYear(data[key], isNew && !isPrimaryClient),
    dateRetirement: (data, key) => validateDate(data[key]),
    clientStatus: (data, key) => !isEmpty(data[key]),
  };
};

export const addressValidationRules = {
  address: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  address2: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  city: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 20;
  },
  postalCode: (data, key) => validatePostalCode(data[key], false),
};

export const clientEmploymentValidationRules = {
  employeeNumber: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 10;
  },
  seniorityDate: (data, key) => validateDate(data[key]),
  company: (data, key) => !isEmpty(data[key] && data[key].companyCode),
  local: (data, key) => !isEmpty(data[key] && data[key].localCode),
};

export const getRequiredFields = (
  isNewClient = false,
  isNewDependent = false,
  isPrimaryClient = false,
  isBirthDateRequired = false
) => {
  const commonRequiredFields = [
    'firstName',
    'lastName',
    'phoneNumber',
    'address',
    'address2',
    'city',
    'postalCode',
    'clientStatus',
  ];

  const primaryClientRequiredFields = [
    'company',
    'local',
    'employeeNumber',
    'seniorityDate',
  ];

  const newClientOrDependentRequiredFields = ['emailAddress'];

  const requiredFieldsForNewClient = [
    ...commonRequiredFields,
    ...primaryClientRequiredFields,
    ...newClientOrDependentRequiredFields,
  ];
  const requiredFieldsForNewDependent = [
    ...commonRequiredFields,
    ...newClientOrDependentRequiredFields,
    'clientId',
  ];
  const requiredFieldsForEditingClient = [
    ...commonRequiredFields,
    ...primaryClientRequiredFields,
    'clientId',
  ];
  const requiredFieldsForEditingDependent = [
    ...commonRequiredFields,
    'clientId',
  ];

  let requiredFields = [];

  if (isNewClient) {
    requiredFields = requiredFieldsForNewClient;
  } else if (isNewDependent) {
    requiredFields = requiredFieldsForNewDependent;
  } else if (isPrimaryClient) {
    requiredFields = requiredFieldsForEditingClient;
  } else {
    requiredFields = requiredFieldsForEditingDependent;
  }

  if (isBirthDateRequired) {
    return [...requiredFields, 'dateBirth'];
  } else {
    return requiredFields;
  }
};

export const getSaveButtonTitle = (t, isNew, active) => {
  if (isNew) {
    return active ? t('common.creating') : t('common.create');
  } else {
    return active ? t('common.saving') : t('common.save');
  }
};

export const isDependentInfo = (clientInfo = {}) => {
  const { clientPersonal = {} } = clientInfo;
  return clientPersonal.clientStatus === '5';
};
