import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from 'js/components/data';
import { FetchContext } from 'js/components/fetch';
import { PromptContext } from 'js/components/prompt';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  GroupPermissionsContext,
  NamedPermissionsProvider,
  userGroups,
} from 'js/components/group-permissions';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  Overlay,
  FontAwesomeIcon,
  FAB,
  FABPosition,
  FABOffset,
} from 'js/components/design-system';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import CaseNumberInput from 'js/components/controls/case-number-input';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  ClientSummary,
  ClientSummarySkeleton,
} from 'js/components/client-summary';
import {
  LawyerSummary,
  LawyerSummarySkeleton,
} from 'js/components/lawyer-summary';
import {
  caseClosingValidationRules,
  caseClosingRequiredKeysForLawyerType,
} from 'js/utilities/cases';
import { isNonCooperatingLawyerType } from 'js/utilities/lawyers';
import { paramsFromSearchString } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';
import {
  CaseInformationForm,
  CaseBillingForm,
  LawyerActivityForm,
} from '../case-forms';
import { caseClosingNamedPermissions } from '../case-named-permissions';
import CaseClosingBreadcrumbs from './breadcrumbs';
import {
  windowEventListenerEffect,
  focusCaseNumberInputEffect,
  onChangeInputCaseNumberEffect,
  onBlurInputCaseNumberEffect,
  setConfirmedCaseNumberEffect,
  resetCaseNumberEffect,
  onMountEffect,
  getCaseInfoEffect,
  getCaseOptionsEffect,
  getClientAndLawyerEffect,
  updateCaseInfoEffect,
  closeCaseEffect,
  onCaseClosedEffect,
} from './effects';

const CaseClosing = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const { casenumber: caseNumberParam = '' } = paramsFromSearchString(search);

  //refs
  const caseNumberInputRef = useRef(null);
  const getCaseInfoAbortControllerRef = useRef(null);
  const getCaseOptionsAbortControllerRef = useRef(null);
  const getClientAbortControllerRef = useRef(null);
  const getLawyerAbortControllerRef = useRef(null);
  const caseClosingAbortControllerRef = useRef(null);

  //states
  const [inputCaseNumber, setInputCaseNumber] = useState('');
  const [confirmedCaseNumber, setConfirmedCaseNumber] = useState('');
  const [caseClosingActive, setCaseClosingActive] = useState(false);
  const [caseInfoActive, setCaseInfoActive] = useState(false);
  const [caseInfo, setCaseInfo] = useState({});
  const [caseOptionsActive, setCaseOptionsActive] = useState(false);
  const [caseOptions, setCaseOptions] = useState({});
  const [clientAndLawyerActive, setClientAndLawyerActive] = useState(false);
  const [clientAndLawyerLoaded, setClientAndLawyerLoaded] = useState(false);
  const [client, setClient] = useState({});
  const [clientOptions, setClientOptions] = useState({});
  const [lawyer, setLawyer] = useState({});

  //contexts
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { api = {} } = useContext(FetchContext);
  const { prompt } = useContext(PromptContext);
  const cache = useContext(DataContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const active = caseInfoActive || caseOptionsActive || clientAndLawyerActive;

  const { matchAnyGroup } = useContext(GroupPermissionsContext);
  const isAdmin = matchAnyGroup([userGroups.administrator]);

  const hasCase = !isEmpty(caseInfo);
  const hasClient = !isEmpty(client);
  const hasLawyer = !isEmpty(lawyer);

  const { caseInformation = {}, caseLawyer = {}, caseBilling = {} } = caseInfo;
  const { clientId, clientCode, legalProblems = [] } = caseInformation;
  const { lawyerId, lawyerType } = caseLawyer;

  const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);
  const {
    payee = [],
    serviceProvidedReason = [],
    serviceProvided = [],
  } = caseOptions;
  const { offices = [] } = clientOptions;

  const { isValid: isInputCaseNumberValid } = validate({ inputCaseNumber }, [
    'inputCaseNumber',
  ]);

  const validationData = {
    ...caseInformation,
    ...caseBilling,
    ...caseLawyer,
  };

  const requiredFields = caseClosingRequiredKeysForLawyerType(lawyerType);

  const validateAll = (data) => validate(data, requiredFields);
  const { invalidFields } = validateAll(validationData);

  const touchCaseNumberInput = () => touch('inputCaseNumber');
  const untouchCaseNumberInput = () => untouch('inputCaseNumber');
  const touchAll = () => touch(requiredFields);
  const untouchAll = () => untouch(requiredFields);

  const confirmCaseNumber = setConfirmedCaseNumberEffect({
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  });

  const closeCase = closeCaseEffect({
    t,
    api,
    validateAll,
    touchAll,
    prompt,
    presentStyledBanner,
    caseNumber: confirmedCaseNumber,
    caseInfo,
    setCaseClosingActive,
    caseClosingAbortControllerRef,
    onCaseClosed: onCaseClosedEffect({
      cache,
      setCaseInfo,
      setClient,
      setLawyer,
      setInputCaseNumber,
      setConfirmedCaseNumber,
      setClientAndLawyerLoaded,
      untouchCaseNumberInput,
      untouchAll,
      caseNumberInputRef,
    }),
  });

  useEffect(windowEventListenerEffect({ closeCase }));

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseInfoAbortControllerRef,
        getCaseOptionsAbortControllerRef,
        getClientAbortControllerRef,
        getLawyerAbortControllerRef,
        caseClosingAbortControllerRef,
      ],
      setCaseInfoActive,
      setCaseOptionsActive,
      setClientAndLawyerActive,
      setClientAndLawyerLoaded,
      setCaseClosingActive,
      caseNumberParam,
      setConfirmedCaseNumber,
      setInputCaseNumber,
    }),
    []
  );

  useEffect(
    focusCaseNumberInputEffect({
      caseNumberInputRef,
    }),
    []
  );

  useEffect(
    getCaseOptionsEffect({
      t,
      api,
      cache,
      setCaseOptionsActive,
      setCaseOptions,
      getCaseOptionsAbortControllerRef,
    }),
    []
  );

  useEffect(
    getCaseInfoEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      setCaseInfoActive,
      setCaseInfo,
      untouchAll,
      getCaseInfoAbortControllerRef,
      presentStyledBanner,
    }),
    [confirmedCaseNumber]
  );

  useEffect(
    getClientAndLawyerEffect({
      t,
      api,
      clientId,
      clientCode,
      lawyerId,
      lawyerType,
      setClientAndLawyerActive,
      setClientAndLawyerLoaded,
      setClient,
      setClientOptions,
      setLawyer,
      getClientAbortControllerRef,
      getLawyerAbortControllerRef,
    }),
    [clientId, clientCode, lawyerId]
  );

  return (
    <Page
      className="case-closing"
      title={t('components.CaseClosing.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <CaseClosingBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <CaseNumberInput
            inputRef={caseNumberInputRef}
            inputName="inputCaseNumber"
            value={inputCaseNumber}
            isValid={!isTouched('inputCaseNumber') || isInputCaseNumberValid}
            onChange={onChangeInputCaseNumberEffect({
              setInputCaseNumber,
            })}
            onBlur={onBlurInputCaseNumberEffect({
              touchCaseNumberInput,
            })}
            onConfirm={confirmCaseNumber}
            onReset={resetCaseNumberEffect({
              setCaseInfo,
              setClient,
              setLawyer,
              setInputCaseNumber,
              setConfirmedCaseNumber,
              setClientAndLawyerLoaded,
              untouchCaseNumberInput,
              untouchAll,
              caseNumberInputRef,
            })}
          />

          {!hasClient && !clientAndLawyerLoaded && (
            <ClientSummarySkeleton animated={active} />
          )}
          {hasClient && <ClientSummary client={client} />}
          {!hasLawyer && !clientAndLawyerLoaded && (
            <LawyerSummarySkeleton animated={active} />
          )}
          {(hasLawyer || isNonCoopLawyer) && (
            <LawyerSummary
              lawyer={lawyer}
              externalNonCoopLawyer={isNonCoopLawyer}
              buttonRoute={routePathReplacingParams(routePaths.caseLawyer, {
                caseNumber: encodeURIComponent(confirmedCaseNumber),
              })}
              routeButtonText={t('components.CaseClosing.changeLawyer')}
            />
          )}

          <Overlay visible={!hasCase}>
            <form>
              <CaseInformationForm
                isNew={false}
                isCopy={false}
                formData={caseInformation}
                formOptions={{ legalProblems, offices }}
                onChange={updateCaseInfoEffect({
                  caseInfo,
                  setCaseInfo,
                  groupName: 'caseInformation',
                })}
              />
              <CaseBillingForm
                formData={caseBilling}
                caseInfo={caseInfo}
                formOptions={{ payee }}
                onChange={updateCaseInfoEffect({
                  caseInfo,
                  setCaseInfo,
                  groupName: 'caseBilling',
                })}
                lawyerType={lawyerType}
                caseClosing={true}
                closingInvalidFields={invalidFields}
                isAdmin={isAdmin}
              />
              <LawyerActivityForm
                formData={caseLawyer}
                formOptions={{ serviceProvidedReason, serviceProvided }}
                caseClosing={true}
                onChange={updateCaseInfoEffect({
                  caseInfo,
                  setCaseInfo,
                  groupName: 'caseLawyer',
                })}
              />
            </form>
          </Overlay>
        </div>
      </div>
      {hasCase && (
        <Fragment>
          <FABPosition>
            <FAB
              actions={[
                {
                  title: t('components.CaseClosing.closeCase'),
                  icon: <FontAwesomeIcon icon={faTimesCircle} />,
                  active: caseClosingActive,
                  disabled: caseClosingActive,
                  onClick: closeCase,
                },
              ]}
            />
          </FABPosition>
          <FABOffset />
        </Fragment>
      )}
    </Page>
  );
};

const CaseClosingWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={caseClosingNamedPermissions}>
    <CaseClosing {...props} />
  </NamedPermissionsProvider>
);

const ValidatedCaseClosing = () => (
  <ValidationProvider rules={caseClosingValidationRules}>
    <CaseClosingWithNamedPermissions />
  </ValidationProvider>
);

export default ValidatedCaseClosing;
