import { cleanStringField } from 'js/utilities/strings';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [] } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
    };
  };
};

export const onEmploymentMount = (options = {}) => {
  const { t, api, setCompanyCodes, employmentAbortControllerRef } = options;
  const companyListEndpoint = `/Common/CompanyList`;
  const jsonOptions = {
    success: { bypass: true },
    error: {
      context: {
        message: t('components.ClientCaseHistory.requestError'),
      },
    },
  };
  //this function used to grab default locals as well, but we now don't grab the local list on employment
  //until there is a company code. this way the locals only ever reflect the selected company
  return async () => {
    rotateAbortControllerRef(employmentAbortControllerRef);
    const { signal } = employmentAbortControllerRef.current;

    try {
      const { json = [] } = await api.getJson(
        companyListEndpoint,
        { signal },
        jsonOptions
      );
      setCompanyCodes(json);
    } catch (error) {
      if (!isAbortError(error)) {
        setCompanyCodes([]);
      }
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

//this select effect returns an object
export const onSelectChangeEffect = (options = {}) => {
  const { onChange, untouch, selectOptions, searchKey } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      const valueItem = selectOptions.find((op) => op[searchKey] === value);
      onChange(name, valueItem);
      // Untouch the local if the company was changed:
      if (searchKey === 'companyCode') {
        untouch('local');
      }
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
    }
  };
};

export const onCheckEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, checked } = e.target;
      onChange(name, checked);
    }
  };
};

export const fetchLocalOptionsEffect = (options = {}) => {
  const { t, api, companyCode, setLocals, localAbortControllerRef } = options;
  return async () => {
    // Reset the locals and return if the company code is empty:
    if (!companyCode) {
      setLocals([]);
      return;
    }

    rotateAbortControllerRef(localAbortControllerRef);
    const { signal } = localAbortControllerRef.current;

    const url = `/Common/LocalList?companyCode=${companyCode}`;

    try {
      const { json = [] } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientLocal.requestError'),
            },
          },
        }
      );
      const locals = json.map((local) => ({
        localName: cleanStringField(local.localName),
        localCode: cleanStringField(local.localCode),
        isActive: local.isActive === true,
      }));
      setLocals(locals);
    } catch (error) {
      if (!isAbortError(error)) {
        setLocals([]);
      }
    }
  };
};
