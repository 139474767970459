import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanClientSummary } from 'js/components/client-summary';
import { routePaths } from 'js/components/router/route-paths';
import { caseApiKey, cleanCase, cleanCaseOptions } from 'js/utilities/cases';
import { cleanIssuedCheques } from 'js/utilities/cheques';
import { cleanNestedClient } from 'js/utilities/clients';
import {
  LAWYER_TYPE_ENUM,
  lawyerApiKey,
  cleanLawyer,
  isNonCooperatingLawyerType,
} from 'js/utilities/lawyers';
import { roundToFixedPointString } from 'js/utilities/numbers';
import { queryForParams } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';
import {
  chequeDefaults,
  newChequeValidationRules,
  focusInputRef,
  calculateChequeTotal,
  resolveRecipientFieldsForPayee,
} from './functions';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setCaseOptionsActive,
    setCaseInfoActive,
    setChequesActive,
    setClientAndLawyerActive,
    setClientAndLawyerLoaded,
    setReversing,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setCaseOptionsActive(false);
      setCaseInfoActive(false);
      setChequesActive(false);
      setClientAndLawyerActive(false);
      setClientAndLawyerLoaded(false);
      setReversing(false);
    };
  };
};

export const focusCaseNumberInputEffect = (options = {}) => {
  const { caseNumberInputRef } = options;
  return () => focusInputRef(caseNumberInputRef);
};

export const onChangeInputCaseNumberEffect = (options = {}) => {
  const { setInputCaseNumber } = options;
  return (name, value) => setInputCaseNumber(value);
};

export const onBlurInputCaseNumberEffect = (options = {}) => {
  const { touchCaseNumberInput } = options;
  return (e) => {
    const { value = '' } = e.target;
    if (value.length > 0) {
      touchCaseNumberInput();
    }
  };
};

export const setConfirmedCaseNumberEffect = (options = {}) => {
  const {
    history,
    isInputCaseNumberValid,
    inputCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
  } = options;
  return () => {
    if (isInputCaseNumberValid) {
      untouchCaseNumberInput();
      const nextQuery = queryForParams({ caseNumber: inputCaseNumber });
      history.replace(nextQuery.search);
    } else {
      touchCaseNumberInput();
    }
  };
};

export const resetCaseNumberEffect = (options = {}) => {
  const {
    history,
    setCaseInfo,
    setCheques,
    setClient,
    setClientSummary,
    setLawyer,
    setInputCaseNumber,
    setClientAndLawyerLoaded,
    untouchCaseNumberInput,
    caseNumberInputRef,
  } = options;
  return () => {
    setCaseInfo({});
    setCheques([]);
    setClient({});
    setClientSummary({});
    setLawyer({});
    setInputCaseNumber('');
    setClientAndLawyerLoaded(false);
    untouchCaseNumberInput();
    focusInputRef(caseNumberInputRef);
    history.replace(routePaths.issueCheque);
  };
};

export const getCaseOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    setCaseOptionsActive,
    setCaseOptions,
    getCaseOptionsAbortControllerRef,
  } = options;
  return async () => {
    setCaseOptionsActive(true);

    rotateAbortControllerRef(getCaseOptionsAbortControllerRef);
    const { signal } = getCaseOptionsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Common/CaseOptions',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.IssueCheque.caseOptionsRequestError'),
            },
          },
        }
      );

      setCaseOptions(cleanCaseOptions(json));
      setCaseOptionsActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseOptionsActive(false);
      }
    }
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    untouchCaseNumberInput,
    setCaseInfoActive,
    setCaseInfo,
    setClient,
    setClientSummary,
    setLawyer,
    setClientAndLawyerActive,
    setClientAndLawyerLoaded,
    getCaseInfoAbortControllerRef,
    getClientAbortControllerRef,
    getLawyerAbortControllerRef,
  } = options;
  return async () => {
    if (!caseNumber) {
      return;
    }

    untouchCaseNumberInput();
    setCaseInfo({});
    setClient({});
    setClientSummary({});
    setLawyer({});
    setClientAndLawyerActive(false);
    setClientAndLawyerLoaded(false);
    setCaseInfoActive(true);

    // Abort any pending client and lawyer requests:
    executeAbortControllerRefs([
      getClientAbortControllerRef,
      getLawyerAbortControllerRef,
    ]);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = caseApiKey(caseNumber);
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.IssueCheque.caseRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      setCaseInfo(cleanCase(json));
      setCaseInfoActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseInfoActive(false);
      }
    }
  };
};

export const getClientAndLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    lawyerId,
    lawyerType,
    setClientAndLawyerActive,
    setClientAndLawyerLoaded,
    setClient,
    setClientSummary,
    setLawyer,
    getClientAbortControllerRef,
    getLawyerAbortControllerRef,
  } = options;
  return async () => {
    const hasClientOrLawyer = !!(clientId && clientCode) || !!lawyerId;
    if (!hasClientOrLawyer) {
      return;
    }

    setClientAndLawyerActive(true);
    setClientAndLawyerLoaded(false);

    let client = {};
    let clientSummary = {};
    let lawyer = {};

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal: clientSignal } = getClientAbortControllerRef.current;

    rotateAbortControllerRef(getLawyerAbortControllerRef);
    const { signal: lawyerSignal } = getLawyerAbortControllerRef.current;

    try {
      if (clientId && clientCode) {
        const clientUrl = `/Client/${clientId}/${clientCode}`;
        const { json: clientJson = {} } = await api.getJson(
          clientUrl,
          { signal: clientSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseClosing.clientRequestError'),
              },
            },
          }
        );
        client = cleanNestedClient(clientJson);
        clientSummary = cleanClientSummary(clientJson);
      }

      const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);
      const isStaffLawyer = Number(lawyerType) === LAWYER_TYPE_ENUM.STAFF;

      if (lawyerId && !isNonCoopLawyer) {
        const lawyerUrl = lawyerApiKey(lawyerId, Number(lawyerType));
        const { json: lawyerJson = {} } = await api.getJson(
          lawyerUrl,
          { signal: lawyerSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseClosing.lawyerRequestError'),
              },
            },
          }
        );

        let preparedLawyerJson = lawyerJson;

        if (isStaffLawyer) {
          preparedLawyerJson = {
            lawyerInfo: { ...lawyerJson, lawyerType: LAWYER_TYPE_ENUM.STAFF },
          };
        }

        lawyer = cleanLawyer(preparedLawyerJson);
      }

      setClient(client);
      setClientSummary(clientSummary);
      setLawyer(lawyer);
      setClientAndLawyerActive(false);
      setClientAndLawyerLoaded(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setClientAndLawyerActive(false);
        setClientAndLawyerLoaded(true);
      }
    }
  };
};

export const getChequesEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setChequesActive,
    setCheques,
    getChequesAbortControllerRef,
  } = options;
  return async () => {
    if (!caseNumber) {
      return;
    }

    setChequesActive(true);

    rotateAbortControllerRef(getChequesAbortControllerRef);
    const { signal } = getChequesAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Procedure/Cheque/${encodeURIComponent(caseNumber)}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.IssueCheque.chequesRequestError'),
            },
          },
        }
      );

      const { cheques = [] } = json;

      setCheques(cleanIssuedCheques(cheques));
      setChequesActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setChequesActive(false);
      }
    }
  };
};

export const postChequeEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    caseNumber,
    cheque,
    payeeIsLawFirm,
    setActive,
    onComplete,
    postChequeAbortControllerRef,
  } = options;

  return async () => {
    const { isValid } = validateAll(cheque);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);

    rotateAbortControllerRef(postChequeAbortControllerRef);
    const { signal } = postChequeAbortControllerRef.current;

    const url = `/Procedure/Cheque/${encodeURIComponent(caseNumber)}`;

    const {
      firstName,
      lastName,
      firmName,
      chequeNumber,
      hours,
      fee,
      gst,
      pst,
      // eslint-disable-next-line no-unused-vars
      total, // discarded
      ...chequeProperties
    } = cheque;

    const body = {
      ...chequeProperties,
      // Use the firm name as first name with an
      // empty last name if the payee is a law firm:
      firstName: payeeIsLawFirm ? firmName : firstName,
      lastName: payeeIsLawFirm ? '' : lastName,
      chequeNumber: Number(chequeNumber),
      hours: Number(hours),
      fee: Number(fee),
      gst: Number(gst),
      pst: Number(pst),
    };

    try {
      await api.postJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.IssueCheque.NewChequeModal.postChequeSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.IssueCheque.NewChequeModal.postChequeError'
              ),
            },
          },
        }
      );

      setActive(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const reverseChequeEffect = (options = {}) => {
  const {
    t,
    api,
    setReversing,
    chequePendingReversal,
    setChequePendingReversal,
    onComplete,
    reverseChequeAbortControllerRef,
  } = options;
  return async () => {
    if (isEmpty(chequePendingReversal)) {
      return;
    }

    setReversing(true);

    rotateAbortControllerRef(reverseChequeAbortControllerRef);
    const { signal } = reverseChequeAbortControllerRef.current;

    const { chequeCount, chequeNumber, status } = chequePendingReversal;

    // Reverse cheques that have been assigned cheque numbers:
    let url = `/Procedure/Cheque/${chequeCount}/reverse`;
    let requestFunc = api.postJson;

    // Delete newly issued cheques that haven't been assigned cheque numbers:
    if (status === 'I' && chequeNumber === 0) {
      url = `/Procedure/Cheque/${chequeCount}`;
      requestFunc = api.deleteJson;
    }

    try {
      await requestFunc(
        url,
        { signal },
        {
          success: {
            context: {
              message: () => {
                return chequeNumber
                  ? t(
                      'components.IssueCheque.NewChequeModal.chequeReversalSuccess',
                      { chequeNumber }
                    )
                  : t(
                      'components.IssueCheque.NewChequeModal.newChequeReversalSuccess'
                    );
              },
            },
          },
          error: {
            context: {
              message: () => {
                return chequeNumber
                  ? t(
                      'components.IssueCheque.NewChequeModal.chequeReversalError',
                      { chequeNumber }
                    )
                  : t(
                      'components.IssueCheque.NewChequeModal.newChequeReversalError'
                    );
              },
            },
          },
        }
      );

      setChequePendingReversal({});
      setReversing(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setReversing(false);
        throw error;
      }
    }
  };
};

export const onSubmitEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const setChequePropertyEffect = (options = {}) => {
  const { setCheque } = options;
  return (name, value) => {
    setCheque((cheque) => ({ ...cheque, [name]: value }));
  };
};

export const setChequePropertyRecalculatingTotalEffect = (options = {}) => {
  const { setCheque } = options;
  return (name, value) => {
    setCheque((cheque) => {
      const nextCheque = { ...cheque, [name]: value };
      const total = calculateChequeTotal(nextCheque);
      return { ...nextCheque, total: roundToFixedPointString(total, 2) };
    });
  };
};

export const setChequePropertyUpdatingRecipientEffect = (options = {}) => {
  const { setCheque, client, lawyer, untouch } = options;
  return (name, value) => {
    setCheque((cheque) => {
      const nextCheque = { ...cheque, [name]: value };
      const recipientFields = resolveRecipientFieldsForPayee(
        nextCheque.payee,
        client,
        lawyer
      );
      return { ...nextCheque, ...recipientFields };
    });
    untouch(Object.keys(newChequeValidationRules));
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onNumericInputBlurEffect = (options = {}) => {
  const { touch, onChange, toFixed = false, precision = 1 } = options;
  return (e) => {
    const { name, value } = e.target;
    let nextValue = '';

    if (toFixed) {
      nextValue = roundToFixedPointString(value, precision);
    } else {
      nextValue = !isNaN(Number(value)) ? value : '';
    }

    if (typeof onChange === 'function') {
      onChange(name, nextValue);
    }
    touch(name);
  };
};

export const onFocusTypeoverInputEffect = () => {
  return (e) => {
    const { target } = e;
    const { value = '' } = target;
    target.setSelectionRange(0, value.length);
  };
};

export const onCloseNewChequeModalEffect = (options = {}) => {
  const {
    onClose,
    setCheque,
    setActive,
    untouchAll,
    abortControllerRefs = [],
  } = options;
  return () => {
    // Reset the cheque data:
    setCheque(chequeDefaults);
    setActive(false);
    untouchAll();
    executeAbortControllerRefs(abortControllerRefs);

    if (typeof onClose === 'function') {
      onClose();
    }
  };
};
