import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import EmptyState from 'js/components/controls/empty-state';
import DatabaseBackupsListCard from './database-backups-list-card';
import { DATABASE_EXPORT_TYPE } from './functions';

const DatabaseBackupsList = (props) => {
  const { t } = useTranslation();
  const { className, type, backups = [], onClickRestore } = props;

  return (
    <div className={classnames('database-backups-list', className)}>
      <h2>{t(`components.DatabaseBackups.heading.${type}`)}</h2>

      {backups.length === 0 && (
        <EmptyState
          title={t(`components.DatabaseBackups.emptyStateMessage.${type}`)}
        />
      )}

      {backups.length > 0 && (
        <ul className="unstyled-list">
          {backups.map((backup) => {
            return (
              <li key={backup.sessionId}>
                <DatabaseBackupsListCard
                  backup={backup}
                  onClickRestore={onClickRestore}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

DatabaseBackupsList.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DATABASE_EXPORT_TYPE)).isRequired,
  backups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickRestore: PropTypes.func.isRequired,
};

export default DatabaseBackupsList;
