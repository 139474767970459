import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';

const CompanyPrintHeader = (props) => {
  const { className, company = {}, ...headerProps } = props;
  const { companyName = '', coInact: companyInactive = false } = company;
  const { t } = useTranslation();
  return (
    <PrintHeader
      className={classnames('company-print-header', className)}
      heading={
        <Fragment>
          <span>{companyName}</span>
          <span>
            {companyInactive
              ? t('common.companyStatus.inactive')
              : t('common.companyStatus.active')}
          </span>
        </Fragment>
      }
      {...headerProps}
    />
  );
};

CompanyPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  company: PropTypes.shape({
    companyName: PropTypes.string,
    coInact: PropTypes.bool,
  }).isRequired,
};

export default CompanyPrintHeader;
