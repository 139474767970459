export const getLawyerLanguages = (t, lawyerInfo = {}) => {
  const {
    langEnglish = false,
    langFrench = false,
    langOther = false,
  } = lawyerInfo;
  const languages = {
    english: langEnglish,
    french: langFrench,
    other: langOther,
  };
  const languageStrings = Object.keys(languages).reduce((acc, key) => {
    if (languages[key]) {
      return [...acc, t(`components.LawyerPrintView.languages.${key}`)];
    } else {
      return acc;
    }
  }, []);
  return languageStrings.join(', ');
};

export const getLawyerAddress = (t, lawyerInfo = {}) => {
  const { addressSameAsFirm = false } = lawyerInfo;
  if (addressSameAsFirm) {
    return t('components.LawyerPrintView.details.addressSameAsFirm');
  } else {
    return getAddress(lawyerInfo);
  }
};

export const getAddress = (lawyerOrFirm = {}) => {
  const {
    address = '',
    address2 = '',
    city = '',
    province = '',
    postalCode = '',
  } = lawyerOrFirm;
  const lines = [`${address} ${address2}`, `${city} ${province} ${postalCode}`];
  return lines
    .map((line) => line.trim())
    .filter((line) => !!line)
    .join(', ');
};
