import PropTypes from 'prop-types';

export const feedbackDocumentPropTypes = PropTypes.shape({
  docId: PropTypes.string.isRequired,
  docName: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  dateUploaded: PropTypes.string.isRequired,
});

export const feedbackPropTypes = {
  feedbackId: PropTypes.number.isRequired,
  complainDate: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired.isRequired,
  cif: PropTypes.string.isRequired.isRequired,
  sourceOfComment: PropTypes.string.isRequired,
  sourceOfCommentDesc: PropTypes.string.isRequired,
  typeOfComment: PropTypes.bool.isRequired,
  negativeReason: PropTypes.string.isRequired,
  negativeReasonDesc: PropTypes.string.isRequired,
  resolvedHow: PropTypes.string.isRequired,
  resolvedHowDesc: PropTypes.string.isRequired,
  resolvedBy: PropTypes.string.isRequired,
  resolvedByDesc: PropTypes.string.isRequired,
  dateClosed: PropTypes.string.isRequired,
  feedBackDocuments: PropTypes.arrayOf(feedbackDocumentPropTypes).isRequired,
};
