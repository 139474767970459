import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ActionCard = (props) => {
  const {
    className,
    heading,
    subheading,
    displayHeader,
    headerAction,
    displayFooter,
    footerAction,
    children,
  } = props;

  return (
    <div className={classnames('action-card', className)}>
      {displayHeader && (
        <div className="action-card-header">
          <div>
            <h2>{heading}</h2>
            <p>{subheading}</p>
          </div>
          <div>{headerAction}</div>
        </div>
      )}
      <div className="action-card-content">{children}</div>
      {displayFooter && (
        <div className="action-card-footer">{footerAction}</div>
      )}
    </div>
  );
};

ActionCard.defaultProps = {
  displayHeader: true,
  displayFooter: true,
};

ActionCard.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  displayHeader: PropTypes.bool,
  headerAction: PropTypes.node,
  displayFooter: PropTypes.bool,
  footerAction: PropTypes.node,
};

export default ActionCard;
