import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const PrintFooter = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  return (
    <div
      className={classnames('print-footer', className)}
      data-print-managed
      data-print-only
    >
      <div>{t('components.PrintFooter.line1')}</div>
      <div>{t('components.PrintFooter.line2')}</div>
    </div>
  );
};

PrintFooter.propTypes = {
  className: PropTypes.string,
};

export default PrintFooter;
