import React, { createContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  setRecordEffect,
  getRecordEffect,
  deleteRecordEffect,
  deleteRecordsMatchingPatternEffect,
  deleteAllRecordsEffect,
  addListenerEffect,
  removeListenerEffect,
} from './effects';

const DataContext = createContext({
  get: () => {},
  set: () => {},
  delete: () => {},
});

const DataProvider = ({ debug, children }) => {
  const dataRef = useRef({});
  const listenersRef = useRef({});
  return (
    <DataContext.Provider
      value={{
        get: getRecordEffect({ dataRef, debug }),
        set: setRecordEffect({ dataRef, listenersRef, debug }),
        delete: deleteRecordEffect({ dataRef, listenersRef, debug }),
        deleteMatchingPattern: deleteRecordsMatchingPatternEffect({
          dataRef,
          listenersRef,
          debug,
        }),
        deleteAll: deleteAllRecordsEffect({ dataRef, listenersRef, debug }),
        addListener: addListenerEffect({ listenersRef, debug }),
        removeListener: removeListenerEffect({ listenersRef, debug }),
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  debug: PropTypes.func,
};

export { DataContext, DataProvider };
