import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView, queryForListPrintView } from 'js/components/print';
import { useTranslation } from 'react-i18next';
import { staffListPrintRequestEffect } from './effects';
import {
  queryForSearchString,
  getStaffPrintOffice,
  getStaffPrintAccessLevel,
} from './functions';

const StaffListPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const { search = '' } = useLocation();
  const query = queryForListPrintView(queryForSearchString(search));

  return (
    <ListPrintView
      request={staffListPrintRequestEffect({ api, query })}
      renderTableHeaders={() => (
        <tr>
          <th>{t('components.StaffListPrintView.columns.code')}</th>
          <th>{t('components.StaffListPrintView.columns.firstName')}</th>
          <th>{t('components.StaffListPrintView.columns.lastName')}</th>
          <th>{t('components.StaffListPrintView.columns.email')}</th>
          <th>{t('components.StaffListPrintView.columns.office')}</th>
          <th>{t('components.StaffListPrintView.columns.accessLevel')}</th>
          <th>{t('components.StaffListPrintView.columns.staffLawyer')}</th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.usrCode}>
          <td>{item.usrCode}</td>
          <td>{item.usrFname}</td>
          <td>{item.usrLname}</td>
          <td>{item.usrEmail}</td>
          <td>{getStaffPrintOffice(item.usrOfficecode, item.officeName)}</td>
          <td>{getStaffPrintAccessLevel(t, item.usrSecur)}</td>
          <td>{item.usrStlaw ? t('common.yes') : t('common.no')}</td>
        </tr>
      )}
      emptyStateTitle={t('components.StaffListPrintView.emptyStateTitle')}
      headerProps={{
        title: t('components.StaffListPrintView.title'),
        heading: t('components.StaffListPrintView.heading'),
        printOnly: false,
      }}
      displayFooter={false}
    />
  );
};

export default StaffListPrintView;
