export const defaultGetIndexOfSortOption = (
  sortOptions = [],
  field,
  direction
) => {
  return sortOptions.findIndex(
    (option) =>
      option.field.toLowerCase() === field.toLowerCase() &&
      option.direction.toLowerCase() === direction.toLowerCase()
  );
};
