import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  DatePicker,
  InputLabel,
  Card,
  InputHelp,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { FetchContext } from 'js/components/fetch';
import { ValidationContext } from 'js/components/validation';
import {
  onMountEffect,
  onEmploymentMount,
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onSelectChangeEffect,
  fetchLocalOptionsEffect,
} from './effects';

const Spacer = () => <div />;

const Employment = (props) => {
  const { api = {} } = useContext(FetchContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editClientInformation');
  const [companyCodes, setCompanyCodes] = useState([]);
  const [locals, setLocals] = useState([]);
  const { t } = useTranslation();
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);

  const employmentAbortControllerRef = useRef(null);
  const localAbortControllerRef = useRef(null);

  const { formData = {}, onChange } = props;
  const {
    company = {},
    local = {},
    employeeNumber = '',
    seniorityDate = '',
  } = formData;
  const { companyCode = '' } = company;
  const { localCode = '' } = local;

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        employmentAbortControllerRef,
        localAbortControllerRef,
      ],
    }),
    []
  );

  useEffect(
    onEmploymentMount({
      t,
      api,
      setCompanyCodes,
      setLocals,
      employmentAbortControllerRef,
    }),
    []
  );

  useEffect(
    fetchLocalOptionsEffect({
      t,
      api,
      companyCode,
      setLocals,
      onChange,
      localAbortControllerRef,
    }),
    [companyCode]
  );

  const { invalidFields = [] } = validate(formData, [
    'employeeNumber',
    'seniorityDate',
    'company',
    'local',
  ]);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  const includesLocal = locals.find((loc) => loc.localCode === localCode);
  const includedLocal = includesLocal ? localCode : '';
  return (
    <Card className="client-employment-form">
      <h2>{t('components.ClientInformation.Employment.title')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.ClientInformation.Employment.companyValidation'
          )}
          invalid={isTouched('company') && invalidFields.includes('company')}
        >
          <InputLabel
            content={t('components.ClientInformation.Employment.company')}
          >
            <StyledSelect
              value={companyCode}
              onChange={onSelectChangeEffect({
                onChange,
                untouch,
                selectOptions: companyCodes,
                searchKey: 'companyCode',
              })}
              onBlur={onInputBlurEffect({ touch })}
              name="company"
              disabled={!hasEditPermission}
            >
              <option value="" disabled>
                {t(
                  'components.ClientInformation.Employment.companyPlaceholder'
                )}
              </option>
              {companyCodes.map((comp) => {
                return (
                  <option value={comp.companyCode} key={comp.companyCode}>
                    {`${comp.companyCode} - ${comp.companyName}`}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Employment.localValidation'
          )}
          invalid={
            (isTouched('local') && invalidFields.includes('local')) ||
            !includesLocal
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Employment.local')}
          >
            <StyledSelect
              value={includedLocal}
              onChange={onSelectChangeEffect({
                onChange,
                untouch,
                selectOptions: locals,
                searchKey: 'localCode',
              })}
              onBlur={onInputBlurEffect({ touch })}
              name="local"
              disabled={!hasEditPermission || !companyCode}
            >
              <option value="" disabled>
                {t('components.ClientInformation.Employment.localPlaceholder')}
              </option>
              {locals.map((loc, key) => {
                let optionText = `${loc.localCode} - ${loc.localName}`;
                if (!loc.isActive) {
                  optionText = t(
                    'components.ClientInformation.Employment.localInactiveOption',
                    { optionText }
                  );
                }
                return (
                  <option key={key} value={loc.localCode}>
                    {optionText}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Employment.employeeNumberValidation'
          )}
          invalid={
            isTouched('employeeNumber') &&
            invalidFields.includes('employeeNumber')
          }
        >
          <InputLabel
            content={t(
              'components.ClientInformation.Employment.employeeNumber'
            )}
          >
            <input
              type="text"
              autoComplete="off"
              value={employeeNumber}
              name="employeeNumber"
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Employment.seniorityDateValidation'
          )}
          invalid={
            isTouched('seniorityDate') &&
            invalidFields.includes('seniorityDate')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Employment.seniorityDate')}
          >
            <DatePicker
              selected={seniorityDate}
              name="seniorityDate"
              readOnly={!hasEditPermission}
              {...datePickerEventHandlers('seniorityDate')}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

Employment.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
};

export default Employment;
