export const filterCheckedPanels = (panels = [], checked = true) => {
  return panels.reduce((acc, panel) => {
    const { checked: panelChecked, lawyerSubpanels = [] } = panel;
    const isChecked = panelChecked === checked;
    const hasCheckedSubPanels =
      lawyerSubpanels.filter((subPanel) => subPanel.checked).length > 0;
    if (isChecked || hasCheckedSubPanels) {
      return [
        ...acc,
        {
          ...panel,
          lawyerSubpanels: filterCheckedPanels(lawyerSubpanels, checked),
        },
      ];
    } else {
      return acc;
    }
  }, []);
};

// Update Lawyer Panels in the local hash format in response to checkbox events:

// A change to the checked status for a panel will be applied to all subpanels.
// If the panel is checked, all corresponding subpanels will be checked.
// If the panel is unchecked, all corresponding subpanels will be unchecked.
export const updateLawyerPanelsWithCheckedPanel = (
  panels = [],
  checkedPanelKey,
  checked
) => {
  const panelIdx = panels.findIndex((p) => p.panelKey === checkedPanelKey);
  if (panelIdx < 0) {
    return panels;
  }

  const panel = panels[panelIdx];
  const { lawyerSubpanels = [] } = panel;
  const nextSubpanels = lawyerSubpanels.map((subPanel) => ({
    ...subPanel,
    checked,
  }));
  const nextPanel = { ...panel, checked, lawyerSubpanels: nextSubpanels };
  const nextPanels = panels.map((p, idx) => (idx === panelIdx ? nextPanel : p));

  return nextPanels;
};

// A change to the checked status for a subpanel will be applied to the subpanel.
// If the subpanel is checked, the corresponding panel will be checked.
// If the subpanel and all its siblings are unchecked, the corresponding panel will be unchecked.
// The panel will be checked if one or more of its subpanels are checked.
export const updateLawyerPanelsWithCheckedSubpanel = (
  panels = [],
  panelKey,
  checkedSubPanelKey,
  checked
) => {
  const panelIdx = panels.findIndex((p) => p.panelKey === panelKey);
  if (panelIdx < 0) {
    return panels;
  }

  const panel = panels[panelIdx];
  const { lawyerSubpanels = [] } = panel;

  const nextSubpanels = lawyerSubpanels.map((subPanel) => {
    const { subPanelKey, checked: subPanelChecked } = subPanel;
    const nextChecked =
      subPanelKey === checkedSubPanelKey ? checked : subPanelChecked;
    return { ...subPanel, checked: nextChecked };
  });

  const numCheckedSubPanels = nextSubpanels.filter(
    (subPanel) => subPanel.checked
  ).length;

  const nextPanel = {
    ...panel,
    checked: numCheckedSubPanels > 0,
    lawyerSubpanels: nextSubpanels,
  };

  const nextPanels = panels.map((p, idx) => (idx === panelIdx ? nextPanel : p));

  return nextPanels;
};
