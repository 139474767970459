import React from 'react';

export const maxServiceFrequencyYears = 5;

export const serviceFrequencyOptions = (t) => {
  return [...new Array(maxServiceFrequencyYears)].map((na, idx) => {
    const years = idx + 1;
    return (
      <option key={years} value={years}>
        {t('components.LegalProblemCodeForm.serviceFrequencyOption', { years })}
      </option>
    );
  });
};

export const getLawyerPanelOptions = (t, panels = []) => {
  return panels.map(({ panId, panKey }) => (
    <option key={panKey} value={panId}>
      {t(`common.lawyerPanels.${panKey}.title`)}
    </option>
  ));
};

export const getLawyerSubPanelOptions = (t, panels = [], selectedPanelId) => {
  const panel = panels.find((p) => p.panId === parseInt(selectedPanelId));
  if (panel) {
    const { panKey, subPanels = [] } = panel;
    return subPanels.map(({ subpanId, subpanKey }) => (
      <option key={subpanKey} value={subpanId}>
        {t(`common.lawyerPanels.${panKey}.subPanels.${subpanKey}`)}
      </option>
    ));
  } else {
    return null;
  }
};
