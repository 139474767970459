import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  lawyerFeedbackValidationRules,
  lawyerFeedbackRequiredFields,
} from 'js/utilities/lawyers';
import { isEmpty } from 'js/utilities/validation';
import { LawyerFeedbackForm } from '../../lawyer-forms';
import {
  onMountEffect,
  dismissBannerOnUnmountEffect,
  getCaseMetadataEffect,
  updateLawyerFeedbackEffect,
  postLawyerFeedbackEffect,
  saveLawyerFeedbackEffect,
  setEditingFeedbackEffect,
  editLawyerFeedbackEffect,
  patchLawyerFeedbackEffect,
  postFeedbackFilesEffect,
  postDateClosedEffect,
} from './effects';
import { getLawyerFeedbackFormOptions } from './functions';

const AddLawyerFeedback = (props) => {
  const {
    className,
    lawyerId,
    onDismiss,
    editingFeedback,
    isEditing = false,
  } = props;
  const { t } = useTranslation();
  const postLawyerFeedbackAbortControllerRef = useRef(null);
  const patchLawyerFeedbackAbortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const { validate, touch } = useContext(ValidationContext);
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );
  const [active, setActive] = useState(false);
  const [isCaseMetadataActive, setCaseMetadataActive] = useState(false);
  const [caseMetadata, setCaseMetadata] = useState({});
  const [lawyerFeedback, setLawyerFeedback] = useState({});
  const [bannerId, setBannerId] = useState('');

  const isFormDisabled =
    active || isCaseMetadataActive || isEmpty(caseMetadata);
  const validateAll = (data) => validate(data, lawyerFeedbackRequiredFields);
  const touchAll = () => touch(lawyerFeedbackRequiredFields);

  useEffect(
    onMountEffect({
      abortControllerRefs: [postLawyerFeedbackAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(dismissBannerOnUnmountEffect({ bannerId, dismissBanner }), [
    bannerId,
  ]);

  const postFeedbackFiles = postFeedbackFilesEffect({
    t,
    api,
    validateAll,
    touchAll,
    lawyerId,
    lawyerFeedback,
    setActive,
    onDismiss,
    postLawyerFeedbackAbortControllerRef,
    presentStyledBanner,
  });

  const postDateClosed = postDateClosedEffect({
    t,
    api,
    lawyerId,
  });

  const postLawyerFeedback = postLawyerFeedbackEffect({
    t,
    api,
    validateAll,
    touchAll,
    lawyerId,
    lawyerFeedback,
    setActive,
    onDismiss,
    postLawyerFeedbackAbortControllerRef,
    presentStyledBanner,
    postFeedbackFiles,
    postDateClosed,
  });

  const patchLawyerFeedback = patchLawyerFeedbackEffect({
    t,
    api,
    validateAll,
    touchAll,
    lawyerId,
    lawyerFeedback,
    setActive,
    onDismiss,
    patchLawyerFeedbackAbortControllerRef,
    presentStyledBanner,
    postFeedbackFiles,
    postDateClosed,
  });

  const saveLawyerFeedback = saveLawyerFeedbackEffect({
    t,
    saveEffect: postLawyerFeedback,
    presentStyledBanner,
    setBannerId,
  });

  const editLawyerFeedback = editLawyerFeedbackEffect({
    t,
    editEffect: patchLawyerFeedback,
    presentStyledBanner,
    setBannerId,
  });

  const getCaseMetadata = getCaseMetadataEffect({
    t,
    api,
    setCaseMetadataActive,
    setCaseMetadata,
  });

  useEffect(
    setEditingFeedbackEffect({
      editingFeedback,
      setLawyerFeedback,
      getCaseMetadata,
      isEditing,
    }),
    [editingFeedback]
  );

  const saveButtonText = isEditing
    ? t('components.AddLawyerFeedback.edit')
    : t('components.AddLawyerFeedback.save');

  return (
    <div className={classnames('add-lawyer-feedback', className)}>
      <h2>
        {isEditing
          ? t('components.AddLawyerFeedback.editTitle')
          : t('components.AddLawyerFeedback.title')}
      </h2>
      <LawyerFeedbackForm
        formData={lawyerFeedback}
        formOptions={getLawyerFeedbackFormOptions(t)}
        onChange={updateLawyerFeedbackEffect({
          lawyerFeedback,
          setLawyerFeedback,
        })}
        isCaseMetadataActive={isCaseMetadataActive}
        caseMetadata={caseMetadata}
        getCaseMetadata={getCaseMetadata}
        isFormDisabled={isFormDisabled}
      />
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={active}
          onClick={() => onDismiss(false)}
        >
          {t('components.AddLawyerFeedback.discard')}
        </button>
        <button
          className="button button-highlight"
          disabled={isFormDisabled}
          onClick={isEditing ? editLawyerFeedback : saveLawyerFeedback}
        >
          {active ? t('common.saving') : saveButtonText}
        </button>
      </div>
    </div>
  );
};

AddLawyerFeedback.propTypes = {
  className: PropTypes.string,
  lawyerId: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  editingFeedback: PropTypes.object,
  isEditing: PropTypes.bool,
};

const ValidatedAddLawyerFeedback = (props) => {
  return (
    <ValidationProvider rules={lawyerFeedbackValidationRules}>
      <AddLawyerFeedback {...props} />
    </ValidationProvider>
  );
};

export default ValidatedAddLawyerFeedback;
