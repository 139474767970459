import {
  paramsFromSearchString,
  searchStringFromParams,
} from 'js/utilities/params';
import { formatDate, parseDate } from 'js/utilities/dates';
import { dateStringPattern, validateDate } from 'js/utilities/validation';

export const AUTO_VOID_PARAM = 'autovoid';

export const DATE_INPUT_NAME = 'date';

export const getDefaultDate = () => formatDate(new Date());

export const searchStringContainsAutoVoidParam = (search = '') => {
  const params = paramsFromSearchString(search);
  const index = Object.keys(params).findIndex(
    (key) => key.toLowerCase() === AUTO_VOID_PARAM
  );
  return index >= 0;
};

export const searchStringApplyingAutoVoidParam = (search = '') => {
  if (!searchStringContainsAutoVoidParam()) {
    const params = paramsFromSearchString(search);
    const nextParams = { ...params, [AUTO_VOID_PARAM]: '1' };
    return searchStringFromParams(nextParams);
  } else {
    return search;
  }
};

export const searchStringRemovingAutoVoidParam = (search = '') => {
  const params = paramsFromSearchString(search);
  const nextParams = Object.keys(params).reduce((next, key) => {
    if (key.toLowerCase() !== AUTO_VOID_PARAM) {
      return { ...next, [key]: params[key] };
    } else {
      return next;
    }
  }, {});
  return searchStringFromParams(nextParams);
};

export const autoVoidValidationRules = {
  [DATE_INPUT_NAME]: (data, key) => {
    const value = data[key];
    if (dateStringPattern.test(value)) {
      return validateDate(parseDate(value));
    } else {
      return false;
    }
  },
};

export const autoVoidRequiredFields = Object.keys(autoVoidValidationRules);
