import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { StyledBannerContext } from 'js/components/banner-styled';
import { FetchContext } from 'js/components/fetch';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
} from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';
import { isEmpty } from 'js/utilities/validation';
import StaffBreadcrumbs from './breadcrumbs';
import StaffCard from './staff-card';
import {
  onMountEffect,
  setEditingStaffEffect,
  resetEditingStaffEffect,
  resetAddingStaffEffect,
  updateStaffEffect,
  getStaffEffect,
  patchStaffEffect,
  postStaffEffect,
  getOfficesEffect,
  previousStaffResultsEffect,
  nextStaffResultsEffect,
  staffResultsPageNumberEffect,
  staffResultsSortEffect,
  staffSearchEffect,
  staffSearchResetEffect,
  reinviteStaffEffect,
  onPageChangeEffect,
} from './effects';
import { queryForSearchString } from './functions';
import EmptyState from 'js/components/controls/empty-state';
import StaffSortMenu from 'js/components/menus/staff-sort-menu';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import { PrintFooter } from 'js/components/print';
import StaffPrintHeader from './staff-print-header';

const StaffListPage = () => {
  const { t } = useTranslation();
  const abortControllerRef = useRef(null);
  const postAbortControllerRef = useRef(null);
  const patchAbortControllerRef = useRef(null);
  const officeAbortControllerRef = useRef(null);
  const reinviteAbortControllerRef = useRef(null);

  const [active, setActive] = useState(false);
  const [saving, setSaving] = useState(false);
  const defaultNewStaff = {
    usrCode: '',
    usrEmail: '',
    usrFname: '',
    usrInact: false,
    usrLname: '',
    usrOfficecode: '',
    usrSecur: '',
    usrStlaw: false,
  };

  const [isAdding, setIsAdding] = useState(false);
  const [addingStaff, setAddingStaff] = useState(defaultNewStaff);
  const [editingStaff, setEditingStaff] = useState({});

  const [staff, setStaff] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [offices, setOffices] = useState([]);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { api = {} } = useContext(FetchContext);
  const listElementRef = useRef();
  const isEditing = !isEmpty(editingStaff);
  const isLocked = isEditing || isAdding;
  const history = useHistory();
  const { search = '' } = useLocation();
  const query = queryForSearchString(search);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        abortControllerRef,
        postAbortControllerRef,
        patchAbortControllerRef,
        officeAbortControllerRef,
        reinviteAbortControllerRef,
      ],
      setActive,
    }),
    []
  );

  useEffect(
    getStaffEffect({
      t,
      api,
      setActive,
      setStaff,
      query,
      abortControllerRef,
      setTotalResults,
    }),
    [query.search]
  );

  useEffect(onPageChangeEffect({ editingStaff, setEditingStaff, staff }), [
    query.search,
    staff,
  ]);

  useEffect(
    getOfficesEffect({
      t,
      api,
      setActive,
      setOffices,
      officeAbortControllerRef,
    }),
    []
  );

  const patchStaff = patchStaffEffect({
    t,
    api,
    editingStaff,
    staff,
    setStaff,
    setSaving,
    patchAbortControllerRef,
    onComplete: resetEditingStaffEffect({
      setEditingStaff,
    }),
  });

  const reinviteStaff = reinviteStaffEffect({
    t,
    api,
    setSaving,
    reinviteAbortControllerRef,
    patchStaff: patchStaffEffect({
      t,
      api,
      editingStaff,
      staff,
      setStaff,
      setSaving,
      patchAbortControllerRef,
    }),
    onComplete: resetEditingStaffEffect({
      setEditingStaff,
    }),
  });

  const pagination = (
    <div className="staff-list-pagination-container">
      <OffsetLimitPagination
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousStaffResultsEffect({
          history,
          query,
        })}
        onClickNext={nextStaffResultsEffect({ history, query })}
        onClickPageNumber={staffResultsPageNumberEffect({
          history,
          query,
        })}
        disabled={isLocked}
      />
    </div>
  );

  return (
    <Page title={t('components.StaffPage.title')} header={<FixedHeader />}>
      <div className="layout-container">
        <div className="layout-column">
          <StaffBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {active && <ActivityIndicatorCard />}
          <StaffPrintHeader
            heading={t('components.StaffPage.title')}
            title={''}
          />
          {!active && (
            <Fragment>
              <div className="lawyer-types-heading">
                <div className="lawyer-types-heading-left">
                  <h1>{t('components.StaffPage.title')}</h1>
                </div>
                <div className="lawyer-types-heading-right">
                  <button
                    className="button button-highlight page-action-button"
                    onClick={() =>
                      history.push(routePaths.staffPrint + query.search)
                    }
                    disabled={isLocked}
                  >
                    {t('common.print')}
                  </button>
                  <button
                    className="button button-highlight page-action-button"
                    onClick={() => setIsAdding(true)}
                    disabled={isLocked}
                  >
                    {t('common.new')}
                  </button>
                </div>
              </div>

              <div className="law-firms-list-page-search-bar">
                <GeneralSearchInput
                  placeholder={t('components.StaffPage.searchPlaceholder')}
                  initialQuery={query}
                  onSearch={staffSearchEffect({ history, query })}
                  onReset={staffSearchResetEffect({ history, query })}
                  disabled={isLocked}
                />
                <StaffSortMenu
                  field={query.params.SortBy}
                  direction={query.params.Sort}
                  onSelect={staffResultsSortEffect({ history, query })}
                  disabled={isLocked}
                />
              </div>
              {!active && staff.length <= 0 && (
                <EmptyState
                  title={t('components.StaffPage.emptyTitle')}
                  subtitle={t('components.StaffPage.emptySubtitle')}
                />
              )}
              {isAdding && (
                <StaffCard
                  staffUser={addingStaff}
                  officeOptions={offices}
                  expanded={isAdding}
                  expandEnabled={!isLocked}
                  saving={saving}
                  isNew={true}
                  onClickCancel={resetAddingStaffEffect({
                    setAddingStaff,
                    setIsAdding,
                    defaultNewStaff,
                  })}
                  onClickSave={postStaffEffect({
                    t,
                    api,
                    addingStaff,
                    staff,
                    setStaff,
                    setSaving,
                    presentStyledBanner,
                    postAbortControllerRef,
                    onComplete: resetAddingStaffEffect({
                      setAddingStaff,
                      setIsAdding,
                      defaultNewStaff,
                    }),
                  })}
                  onChange={updateStaffEffect({
                    allowUpdate: isAdding,
                    changingStaff: addingStaff,
                    changeStaffFunc: setAddingStaff,
                  })}
                />
              )}
              {pagination}
              <ul
                ref={listElementRef}
                className="unstyled-list lawyer-types-list"
              >
                {!active &&
                  staff.length > 0 &&
                  staff.map((staffUser) => {
                    const isEditingStaff =
                      editingStaff &&
                      editingStaff.usrCode === staffUser.usrCode;
                    return (
                      <StaffCard
                        staffUser={isEditingStaff ? editingStaff : staffUser}
                        key={staffUser.usrCode}
                        officeOptions={offices}
                        expanded={isEditingStaff}
                        expandEnabled={!isLocked}
                        saving={saving}
                        isNew={false}
                        onClickExpand={setEditingStaffEffect({
                          staffUser,
                          setEditingStaff,
                        })}
                        onClickCancel={resetEditingStaffEffect({
                          setEditingStaff,
                        })}
                        onClickSave={patchStaff}
                        onClickInvite={reinviteStaff}
                        onChange={updateStaffEffect({
                          allowUpdate: isEditingStaff,
                          changingStaff: editingStaff,
                          changeStaffFunc: setEditingStaff,
                        })}
                      />
                    );
                  })}
              </ul>
              {pagination}
            </Fragment>
          )}
          <PrintFooter />
        </div>
      </div>
    </Page>
  );
};

export default StaffListPage;
