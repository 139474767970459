import React, { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import OfficesListBreadcrumbs from './breadcrumbs';
import OfficeCard from './office-card';
import {
  onMountEffect,
  setEditingOfficeEffect,
  resetEditingOfficeEffect,
  setAddingOfficeEffect,
  resetAddingOfficeEffect,
  updateOfficeEffect,
  getOfficesEffect,
  patchOfficeEffect,
  postOfficeEffect,
} from './effects';

const OfficesListPage = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingOfficeIndex, setEditingOfficeIndex] = useState(-1);
  const [editingOffice, setEditingOffice] = useState({});
  const [addingOffice, setAddingOffice] = useState({});
  const [offices, setOffices] = useState([]);
  const { api = {} } = useContext(FetchContext);
  const listElementRef = useRef();
  const isEditing = !isEmpty(editingOffice);
  const isAdding = !isEmpty(addingOffice);
  const isLocked = isEditing || isAdding;

  const getOfficesAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getOfficesAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    getOfficesEffect({
      t,
      api,
      setActive,
      setOffices,
      getOfficesAbortControllerRef,
    }),
    []
  );

  return (
    <Page
      className="offices-list-page"
      title={t('components.OfficesListPage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <OfficesListBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {active && <ActivityIndicatorCard />}
          {!active && (
            <React.Fragment>
              <div className="offices-list-heading">
                <div className="offices-list-heading-left">
                  <h1>{t('components.OfficesListPage.heading')}</h1>
                </div>
                <div className="offices-list-heading-right">
                  <button
                    className="button button-highlight page-action-button"
                    onClick={setAddingOfficeEffect({
                      setAddingOffice,
                      listElementRef,
                    })}
                    disabled={isLocked}
                  >
                    {t('common.new')}
                  </button>
                </div>
              </div>
              <ul ref={listElementRef} className="unstyled-list offices-list">
                {offices.map((office, idx) => {
                  const isEditingOffice =
                    isEditing && editingOfficeIndex === idx;
                  const referenceOffice = isEditingOffice
                    ? editingOffice
                    : office;
                  return (
                    <li key={office.officeCode}>
                      <OfficeCard
                        office={referenceOffice}
                        expanded={isEditingOffice}
                        expandEnabled={!isLocked}
                        saving={saving}
                        onClickExpand={setEditingOfficeEffect({
                          office,
                          setEditingOffice,
                          setEditingOfficeIndex,
                          nextEditingOfficeIndex: idx,
                        })}
                        onClickCancel={resetEditingOfficeEffect({
                          setEditingOffice,
                          setEditingOfficeIndex,
                        })}
                        onClickSave={patchOfficeEffect({
                          t,
                          api,
                          editingOffice,
                          offices,
                          setOffices,
                          setSaving,
                          onComplete: resetEditingOfficeEffect({
                            setEditingOffice,
                            setEditingOfficeIndex,
                          }),
                        })}
                        onChange={updateOfficeEffect({
                          allowUpdate: isEditingOffice,
                          office: editingOffice,
                          setOffice: setEditingOffice,
                        })}
                      />
                    </li>
                  );
                })}

                {isAdding && (
                  <li>
                    <OfficeCard
                      office={addingOffice}
                      expanded={true}
                      isNewOffice={true}
                      saving={saving}
                      onClickExpand={() => {}}
                      onClickCancel={resetAddingOfficeEffect({
                        setAddingOffice,
                      })}
                      onClickSave={postOfficeEffect({
                        t,
                        api,
                        addingOffice,
                        offices,
                        setOffices,
                        setSaving,
                        onComplete: resetAddingOfficeEffect({
                          setAddingOffice,
                        }),
                      })}
                      onChange={updateOfficeEffect({
                        allowUpdate: true,
                        office: addingOffice,
                        setOffice: setAddingOffice,
                      })}
                    />
                  </li>
                )}
              </ul>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default OfficesListPage;
