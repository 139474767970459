import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faLock,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import { nl2br } from 'js/utilities/strings';

const ChequeRunModalContentUnlocked = (props) => {
  const { lockSystemActive, onClickCancel, onClickLock } = props;
  const { t } = useTranslation();
  return (
    <div className="cheque-run-modal-content cheque-run-modal-content-unlocked">
      <div className="cheque-run-lock-icon-group">
        <FontAwesomeIcon className="cheque-run-lock-icon" icon={faLock} />
        <FontAwesomeIcon
          className="cheque-run-alert-icon"
          icon={faExclamationTriangle}
        />
      </div>
      <p
        className="cheque-run-lock-alert"
        dangerouslySetInnerHTML={{
          __html: nl2br(t('components.ChequeRunModal.lockAlert')),
        }}
      />
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={lockSystemActive}
          onClick={onClickCancel}
        >
          {t('components.ChequeRunModal.lockCancelButtonTitle')}
        </button>
        <button
          className="button button-highlight"
          disabled={lockSystemActive}
          onClick={onClickLock}
        >
          {lockSystemActive
            ? t('components.ChequeRunModal.lockConfirmButtonTitleActive')
            : t('components.ChequeRunModal.lockConfirmButtonTitle')}
        </button>
      </div>
    </div>
  );
};

ChequeRunModalContentUnlocked.propTypes = {
  lockSystemActive: PropTypes.bool.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickLock: PropTypes.func.isRequired,
};

export default ChequeRunModalContentUnlocked;
