import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'js/components/design-system';

const EmptyState = (props) => {
  const { title, subtitle } = props;

  return (
    <Card className="empty-state">
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </Card>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default EmptyState;
