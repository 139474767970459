export const requestWithDefaultScopes = (audience) => {
  return { scopes: [`${audience}/.default`] };
};

export const defaultLoginRequestForAudience = (audience) => {
  if (audience) {
    return requestWithDefaultScopes(audience);
  } else {
    return {};
  }
};
