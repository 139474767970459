import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  LAWYER_TYPE_ENUM,
  lawyerApiKey,
  cleanLawyer,
} from 'js/utilities/lawyers';
import { filterCheckedPanels } from 'js/utilities/lawyer-panels';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoading } = options;
  return () => {
    executeAbortControllerRefs(abortControllerRefs);
    setLoading(false);
  };
};

export const getLawyerPrintDataEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    lawyerApproxType,
    setLoading,
    setLawyer,
    setPanels,
    getLawyerAbortControllerRef,
    getPanelsAbortControllerRef,
  } = options;
  return async () => {
    try {
      setLoading(true);

      rotateAbortControllerRef(getLawyerAbortControllerRef);
      const { signal: lawyerSignal } = getLawyerAbortControllerRef.current;

      const { json: lawyerJson = {} } = await api.getJson(
        lawyerApiKey(lawyerId, lawyerApproxType),
        { signal: lawyerSignal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerPrintView.getLawyerError'),
            },
          },
        }
      );

      let lawyer = {};

      if (lawyerApproxType === LAWYER_TYPE_ENUM.STAFF) {
        lawyer = cleanLawyer({ lawyerInfo: { ...lawyerJson } });
      } else {
        lawyer = cleanLawyer(lawyerJson);
      }

      setLawyer(lawyer);

      if (lawyerApproxType !== LAWYER_TYPE_ENUM.STAFF) {
        rotateAbortControllerRef(getPanelsAbortControllerRef);
        const { signal: panelsSignal } = getPanelsAbortControllerRef.current;

        const { json: panelsJson = {} } = await api.getJson(
          `/Lawyer/${lawyerId}/Panels`,
          { signal: panelsSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.LawyerPrintView.getLawyerPanelsError'),
              },
            },
          }
        );

        const { lawyerPanel = [] } = panelsJson;

        setPanels(filterCheckedPanels(lawyerPanel));
      }

      setLoading(false);

      setTimeout(() => window.print());
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false);
      }
    }
  };
};
