import { isBefore } from 'date-fns';
import {
  caseNumberPattern,
  intakeIdPattern,
  floatPattern,
  validateDate,
  isEmpty,
  isOneOf,
  validateStringMaxLength,
  clientIdPattern,
  clientCodePattern,
} from 'js/utilities/validation';
import {
  isStaffLawyerType,
  isStrictNonCoopLawyerType,
} from 'js/utilities/lawyers';
import { REAL_ESTATE_LP_CODES } from 'js/utilities/legal-problem-codes';

export const caseInformationValidationRules = {
  cif: caseNumberPattern,
  intakeId: intakeIdPattern,
  openDate: (data, key) => validateDate(data[key]),
  shreddedDate: (data, key) => validateDate(data[key], false),
  lastEdit: (data, key) => validateDate(data[key], false),
  reTransferDate: (data, key) => {
    const isTransferDateRequired = REAL_ESTATE_LP_CODES.includes(
      data.legalProblemCode
    );
    return validateDate(data[key], isTransferDateRequired);
  },
  legalProblemCode: (data, key) => !isEmpty(data[key]),
  officeOpened: (data, key) => !isEmpty(data[key]),
  archiveBox: (data, key) => validateStringMaxLength(data[key], 16),
  archiveFile: (data, key) => validateStringMaxLength(data[key], 16),
};

export const caseInformationRequiredKeys = [
  'legalProblemCode',
  'officeOpened',
  'officeOpened',
  'archiveBox',
  'archiveFile',
  'reTransferDate',
];

export const caseInformationEditingRequiredKeys = [
  ...caseInformationRequiredKeys,
  'cif',
  'intakeId',
  'openDate',
  'shreddedDate',
  'lastEdit',
];

export const OTHER_PAYEE = '3';

export const validateOtherPayeeField = (maxLength = 0, allowEmpty = false) => {
  return (data, key) => {
    const value = data[key];
    if (data.payee === OTHER_PAYEE) {
      if (!isEmpty(value)) {
        if (maxLength > 0) {
          return validateStringMaxLength(value, maxLength);
        } else {
          return true;
        }
      } else {
        return allowEmpty;
      }
    } else {
      return true;
    }
  };
};

export const validateSentToLawyer = (data, key) => {
  const isStaffLawyer = isStaffLawyerType(data.lawyerType);
  const isBooleanString = isOneOf(data[key], ['true', 'false']);
  return isStaffLawyer || isBooleanString;
};

export const validateCaseLawyerProvinceOrCityId = (data, key) => {
  const isNonCoopLawyer = isStrictNonCoopLawyerType(data.lawyerType);
  return isNonCoopLawyer ? !isEmpty(data[key]) : true;
};

export const validateOptionalFloatField = (data, key) => {
  const value = data[key];
  if (isEmpty(value)) {
    return true;
  } else {
    return floatPattern.test(value);
  }
};

export const caseLawyerValidationRules = {
  firstMeeting: (data, key) => {
    const { caseOpenDate } = data;
    if (!validateDate(caseOpenDate)) {
      return false;
    }

    const value = data[key];
    if (value) {
      return validateDate(value) && isBefore(value, caseOpenDate);
    } else {
      return true;
    }
  },
  referralReasonCode: (data, key) => !isEmpty(data[key]),
  sentToLawyer: validateSentToLawyer,
  //prov and city ids are used only for non coop lawyers and are otherwise ignored
  provId: validateCaseLawyerProvinceOrCityId,
  cityId: validateCaseLawyerProvinceOrCityId,
};

export const caseLawyerRequiredKeys = Object.keys(caseLawyerValidationRules);

export const caseClosingLawyerInfoValidationRules = {
  lawyerType: (data, key) => !isEmpty(data[key]),
  firstMeeting: (data, key) => validateDate(data[key], false),
  servicesProvidedCode: (data, key) => !isEmpty(data[key]),
  reasonCode: (data, key) =>
    !isEmpty(data[key]) || data.servicesProvidedCode !== '0',
  //prov and city ids are used only for non coop lawyers and are otherwise ignored
  provId: validateCaseLawyerProvinceOrCityId,
  cityId: validateCaseLawyerProvinceOrCityId,
};

export const caseClosingLawyerInfoRequiredKeys = Object.keys(
  caseClosingLawyerInfoValidationRules
);

export const caseBillingValidationRules = {
  dateClosed: (data, key) => validateDate(data[key], false),
  lawyerInvoiceDate: (data, key) => validateDate(data[key]),
  planHours: validateOptionalFloatField,
  planFees: floatPattern,
  planTaxes: floatPattern,
  clientHours: validateOptionalFloatField,
  clientFees: floatPattern,
  payee: (data, key) => !isEmpty(data[key]),
  payeeOtherPayeeName: validateOtherPayeeField(30),
  payeeOtherAddress: validateOtherPayeeField(30),
  payeeOtherAddress2: validateOtherPayeeField(30, true),
  payeeOtherCity: validateOtherPayeeField(20),
  payeeOtherProv: validateOtherPayeeField(2, true),
  payeeOtherPostalCode: validateOtherPayeeField(7, true),
};

export const caseBillingRequiredKeysForLawyerType = (
  lawyerType,
  isClosingCase = false
) => {
  let requiredKeys = [
    'dateClosed',
    'planHours',
    'planFees',
    'planTaxes',
    'clientHours',
    'clientFees',
    'payee',
  ];

  if (isClosingCase) {
    requiredKeys = [
      ...requiredKeys,
      'payeeOtherPayeeName',
      'payeeOtherAddress',
      'payeeOtherAddress2',
      'payeeOtherCity',
      'payeeOtherProv',
      'payeeOtherPostalCode',
    ];
  }

  if (!isStaffLawyerType(lawyerType)) {
    requiredKeys = [...requiredKeys, 'lawyerInvoiceDate'];
  }

  return requiredKeys;
};

export const caseClosingValidationRules = {
  ...caseInformationValidationRules,
  ...caseBillingValidationRules,
  ...caseClosingLawyerInfoValidationRules,
  inputCaseNumber: caseNumberPattern,
  dateClosed: (data, key) => validateDate(data[key]),
};

export const caseClosingRequiredKeysForLawyerType = (lawyerType) => [
  ...caseInformationEditingRequiredKeys,
  ...caseBillingRequiredKeysForLawyerType(lawyerType, true),
  ...caseClosingLawyerInfoRequiredKeys,
  'dateClosed',
];

export const caseReassignmentValidationRules = {
  inputCaseNumber: caseNumberPattern,
  inputNextClientId: clientIdPattern,
  inputNextClientCode: clientCodePattern,
  // The case information form is read-only in this context,
  // but still wants validation rules. Stub the rules out with
  // functions that return true so the form is happy and we
  // don't get any unexpected validation errors.
  ...Object.keys(caseInformationValidationRules).reduce((acc, key) => {
    return { ...acc, [key]: () => true };
  }, {}),
};

export const caseCorrectionValidationRules = {
  inputCaseNumber: caseNumberPattern,
  clientStatus: (data, key) => !isEmpty(data[key]),
  primaryClientStatus: (data, key) => !isEmpty(data[key]),
  // The case information form is read-only in this context,
  // but still wants validation rules. Stub the rules out with
  // functions that return true so the form is happy and we
  // don't get any unexpected validation errors.
  ...Object.keys(caseInformationValidationRules).reduce((acc, key) => {
    return { ...acc, [key]: () => true };
  }, {}),
};

export const caseCorrectionRequiredFields = [
  'clientStatus',
  'primaryClientStatus',
];
