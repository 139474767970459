import React, { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import EmptyState from 'js/components/controls/empty-state';
import { PrintHeader } from 'js/components/print';
import { isEmpty } from 'js/utilities/validation';
import DataIntegrityBreadcrumbs from './breadcrumbs';
import {
  onMountEffect,
  getDataIntegrityReportEffect,
  onClickUpdateClientStatusEffect,
  postClientStatusEffect,
} from './effects';
import { getDataIntegrityReportColumns } from './functions';
import ClientStatusModal from './client-status-modal';

const PrimaryClientIntegrity = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const getDataIntegrityReportAbortControllerRef = useRef(null);
  const postClientStatusAbortControllerRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [items, setItems] = useState([]);
  const [itemToUpdate, setItemToUpdate] = useState({});

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getDataIntegrityReportAbortControllerRef,
        postClientStatusAbortControllerRef,
      ],
      setLoading,
    }),
    []
  );

  useEffect(
    getDataIntegrityReportEffect({
      t,
      api,
      setLoading,
      setItems,
      getDataIntegrityReportAbortControllerRef,
    }),
    []
  );

  return (
    <Page
      className="primary-client-integrity"
      title={t('components.PrimaryClientIntegrity.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <DataIntegrityBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <PrintHeader
            title={t('components.PrimaryClientIntegrity.printTitle')}
            heading={t('components.PrimaryClientIntegrity.printHeading')}
          />

          <div className="primary-client-integrity-heading">
            <h1>{t('components.PrimaryClientIntegrity.title')}</h1>
            <button
              className="button button-highlight page-action-button"
              onClick={() => window.print()}
              disabled={isLoading}
            >
              {t('common.print')}
            </button>
          </div>

          {isLoading && <ActivityIndicatorCard />}

          {!isLoading && items.length === 0 && (
            <EmptyState
              title={t('components.PrimaryClientIntegrity.emptyState')}
            />
          )}

          {!isLoading && items.length > 0 && (
            <SortableTable
              className="primary-client-integrity-table"
              columns={getDataIntegrityReportColumns(
                t,
                onClickUpdateClientStatusEffect({ setItemToUpdate })
              )}
              data={items}
              initialSortColumnKey={'cas_clodt'}
              initialSortDirection={tableSortDirection.desc}
              printManaged
            />
          )}
        </div>
      </div>
      <ClientStatusModal
        mounted={!isEmpty(itemToUpdate)}
        item={itemToUpdate}
        isSaving={isSaving}
        onSubmit={postClientStatusEffect({
          t,
          api,
          setSaving,
          setItems,
          setItemToUpdate,
          postClientStatusAbortControllerRef,
        })}
        onCancel={() => setItemToUpdate({})}
      />
    </Page>
  );
};

export default PrimaryClientIntegrity;
