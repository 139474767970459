import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GroupPermissionsContext } from './context';
import { hasMatchingGroupEffect } from './effects';

const GroupPermissionsGate = (props) => {
  const { permittedGroups = [], children } = props;
  const { hasLoadedGroups, matchAnyGroup } = useContext(
    GroupPermissionsContext
  );
  const [hasMatchingGroup, setHasMatchingGroup] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const isReady = hasLoadedGroups && hasInitialized;

  useEffect(
    hasMatchingGroupEffect({
      permittedGroups,
      hasLoadedGroups,
      matchAnyGroup,
      setHasMatchingGroup,
      setHasInitialized,
    }),
    [hasLoadedGroups]
  );

  if (typeof children === 'function') {
    return children({
      hasLoadedGroups: isReady,
      hasMatchingGroup,
      matchAnyGroup,
    });
  } else {
    return isReady && hasMatchingGroup ? children : null;
  }
};

GroupPermissionsGate.propTypes = {
  permittedGroups: PropTypes.arrayOf(PropTypes.number),
};

export default GroupPermissionsGate;
