import React, { useRef, useContext, useState, useEffect } from 'react';
import { FetchContext } from 'js/components/fetch';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  onMountEffect,
  lawFirmDetailsRequestEffect,
  updateLawFirmInfoEffect,
  patchLawFirmEffect,
  postLawFirmEffect,
  saveLawFirmEffect,
  patchActiveLawFirmEffect,
} from './effects';
import {
  lawFirmValidationRules,
  getRequiredFields,
  getSaveButtonTitle,
} from './functions';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { StyledBannerContext } from 'js/components/banner-styled';
import LawFirmBreadcrumbs from './law-firm-breadcrumbs';
import LawFirmForm from './law-firm-form';
import LinkedLawyerList from './linked-lawyers';

const LawFirmDetailsPage = (props) => {
  const { className, title, ...pageProps } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const abortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [initalActive, setInitialActive] = useState(true);
  const [lawFirm, setLawFirm] = useState({});
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const {
    params: { firmId },
  } = useRouteMatch();
  const isNew = firmId === 'new';
  const [redirectPath, setRedirectPath] = useState('');
  const [viewModal, showModal] = useState(false);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    lawFirmDetailsRequestEffect({
      t,
      api,
      firmId,
      setInitialActive,
      setLawFirm,
      setRedirectPath,
      abortControllerRef,
      isNew,
    }),
    []
  );

  const requiredFields = getRequiredFields();

  const { isValid } = validate(lawFirm, requiredFields);

  const touchAllFields = () => touch(requiredFields);

  const patchEffect = patchLawFirmEffect({
    t,
    api,
    lawFirm,
    isValid,
    setLawFirm,
    setActive,
    touchAllFields,
    resetInteractionCount,
  });

  const postEffect = postLawFirmEffect({
    t,
    api,
    lawFirm,
    isValid,
    setActive,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
  });

  const onSaveLawFirm = saveLawFirmEffect({
    t,
    saveEffect: isNew ? postEffect : patchEffect,
    presentStyledBanner,
  });

  const { inactive: lawFirmInactive = false } = lawFirm;

  const setActiveState = patchActiveLawFirmEffect({
    t,
    api,
    setActive,
    firmId,
    lawFirmInactive: !lawFirmInactive,
    lawFirm,
    setLawFirm,
    showModal,
  });

  return (
    <Page
      className={classnames('law-firm-details-page', className)}
      title={t('components.LawFirmDetailsPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <NavigationSaveModal
        proceedAfter={async () => await onSaveLawFirm()}
        shouldBlockNavigation={() => interactionCount > 0}
      />
      {redirectPath && <Redirect to={redirectPath} />}
      <div className="layout-container ">
        <div className="layout-column">
          <LawFirmBreadcrumbs />
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="law-firm-details-page-heading">
            <div className="law-firm-details-page-heading-left">
              <p>{t('components.LawFirmDetailsPage.subtitle')}</p>
              <div>
                <h1>
                  {isNew
                    ? t('components.LawFirmDetailsPage.newTitle')
                    : lawFirm.firmName}
                </h1>
                <DeactivateToggle
                  disabled={isNew}
                  isActive={!lawFirmInactive}
                  onActivate={setActiveState}
                  onDeactivate={() => showModal(true)}
                />
                <DeactivateModal
                  title={t(
                    'components.LawFirmDetailsPage.DeactivateModal.title'
                  )}
                  message={t(
                    'components.LawFirmDetailsPage.DeactivateModal.message'
                  )}
                  mounted={viewModal}
                  onClose={() => showModal(false)}
                  onSave={setActiveState}
                  active={active}
                />
              </div>
            </div>
            <div className="law-firm-details-page-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() =>
                  history.push(
                    routePathReplacingParams(routePaths.lawFirmPrint, {
                      firmId,
                    })
                  )
                }
              >
                {t('common.print')}
              </button>
              <button
                className="button button-highlight page-action-button"
                onClick={isNew ? postEffect : patchEffect}
                disabled={active}
              >
                {getSaveButtonTitle(t, isNew, active)}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {initalActive && <ActivityIndicatorCard />}
          {!initalActive && (
            <form
              onClick={incrementInteractionCount}
              onSubmit={(e) => e.preventDefault()}
            >
              <LawFirmForm
                formData={lawFirm}
                onChange={updateLawFirmInfoEffect({
                  lawFirm,
                  setLawFirm,
                })}
              />
            </form>
          )}
        </div>
      </div>
      {!isNew && !initalActive && (
        <div className="layout-container  inset-col-1">
          <div className="layout-column">
            <LinkedLawyerList firmId={firmId} isNew={isNew} />
          </div>
        </div>
      )}
    </Page>
  );
};

LawFirmDetailsPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

const ValidLawFirmDetails = () => {
  return (
    <ValidationProvider rules={{ ...lawFirmValidationRules }}>
      <LawFirmDetailsPage />
    </ValidationProvider>
  );
};

const InteractionTrackingLawFirmDetails = () => (
  <InteractionTrackingProvider>
    <ValidLawFirmDetails />
  </InteractionTrackingProvider>
);

export default InteractionTrackingLawFirmDetails;
