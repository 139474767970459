export const ALL_FILTER = 'All';

export const clientTypeFilters = {
  all: ALL_FILTER,
  active: 'PrimaryClient',
  spouse: 'Spouse',
  survivingSpouse: 'SurvivingSpouse',
  dependent: 'Dependent',
  retiree: 'Retiree',
};

export const lawyerTypeFilters = {
  cooperating: 'Cooperating',
  staff: 'Staff',
};

export const typeFiltersForModel = (model) => {
  switch (model) {
    case 'client':
      return clientTypeFilters;
    case 'lawyer':
      return lawyerTypeFilters;
    default:
      return {};
  }
};

export const defaultTypeFilterForModel = (model) => {
  switch (model) {
    case 'client':
      return clientTypeFilters.all;
    case 'lawyer':
      return lawyerTypeFilters.cooperating;
    default:
      return '';
  }
};
