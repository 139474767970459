import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const InactiveTag = (props) => {
  const { t } = useTranslation();
  const { className, inactive, invert } = props;
  const combinedClassNames = classnames(
    'tag tag-error',
    { 'tag-error-inverse': invert },
    'inactive-tag',
    className
  );

  if (inactive) {
    return (
      <span className={combinedClassNames}>
        {t('components.InactiveTag.inactive')}
      </span>
    );
  } else {
    return null;
  }
};

InactiveTag.defaultProps = {
  inactive: false,
  invert: false,
};

InactiveTag.propTypes = {
  className: PropTypes.string,
  inactive: PropTypes.bool,
  invert: PropTypes.bool,
};

export default InactiveTag;
