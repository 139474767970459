import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import {
  ExpandingCard,
  ExpandingCardContent,
} from 'js/components/design-system';
import { StyledBannerContext } from 'js/components/banner-styled';
import SaveModal from 'js/components/save-modal';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  DeactivateModal,
  DeactivateToggle,
  InactiveTag,
} from 'js/components/deactivate';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import PhoneInput from 'js/components/controls/phone-input';
import {
  InputHelp,
  InputLabel,
  StyledSelect,
} from 'js/components/design-system';
import {
  OFFICE_TYPES,
  officeValidationRules,
  officeRequiredFields,
} from 'js/utilities/offices';
import {
  createOfficeCodeMaskEffect,
  onClickCancelEffect,
  onConfirmCancelEffect,
  onConfirmSaveEffect,
  saveOfficeEffect,
  onInputChangeEffect,
  onOfficeCodeInputChangeEffect,
  onInputBlurEffect,
  onSelectionChangeEffect,
  updateOfficeInactiveEffect,
  setConfirmDeactivateEffect,
} from './effects';

const OfficeCard = (props) => {
  const { t } = useTranslation();
  const {
    office = {},
    expanded,
    expandEnabled,
    isNewOffice,
    saving,
    onClickExpand,
    onClickCancel,
    onClickSave,
    onChange,
  } = props;
  const {
    officeCode = '',
    officeType = '',
    officeName = '',
    officeAddr1 = '',
    officeAddr2 = '',
    officeCity = '',
    officeProv = '',
    officePcode = '',
    officePhone1 = '',
    officePhone2 = '',
    officeFax = '',
    officeInact = false,
  } = office;

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const { isValid, invalidFields = [] } = validate(
    office,
    officeRequiredFields
  );
  const touchAll = () => touch(officeRequiredFields);
  const untouchAll = () => untouch(officeRequiredFields);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const officeCodeMaskRef = useRef(null);

  const provinces = provinceOptions(t);

  useEffect(createOfficeCodeMaskEffect({ officeCodeMaskRef }), []);

  const discard = onConfirmCancelEffect({
    untouchAll,
    resetInteractionCount,
    setConfirmCancel,
    onClickCancel,
  });

  const save = saveOfficeEffect({
    t,
    presentStyledBanner,
    saveEffect: onConfirmSaveEffect({
      t,
      isValid,
      touchAll,
      resetInteractionCount,
      setConfirmCancel,
      onClickSave,
    }),
  });

  return (
    <ExpandingCard className="office-card" expanded={expanded}>
      <div
        className={classnames('office-card-heading', {
          'office-card-heading-expanded': expanded,
        })}
      >
        {!expanded && (
          <Fragment>
            <h2>{officeCode}</h2>
            <h2 className="inactive-tag-container">
              <span>{officeName}</span>
              <InactiveTag invert inactive={officeInact} />
            </h2>
            <div className="office-card-actions">
              <button
                className="button-link-appearance"
                onClick={onClickExpand}
                disabled={!expandEnabled}
              >
                {t('components.OfficeCard.expand')}
              </button>
            </div>
          </Fragment>
        )}
        {expanded && (
          <Fragment>
            {!isNewOffice && <h2>{officeCode}</h2>}
            {isNewOffice && (
              <InputHelp
                validationContent={t('components.OfficeCard.codeValidation')}
                invalid={
                  isTouched('officeCode') &&
                  invalidFields.includes('officeCode')
                }
              >
                <input
                  type="text"
                  name="officeCode"
                  placeholder={t('components.OfficeCard.codePlaceholder')}
                  value={officeCode}
                  disabled={saving}
                  onChange={onOfficeCodeInputChangeEffect({
                    officeCodeMaskRef,
                    onChange,
                  })}
                  onBlur={onInputBlurEffect({ touch })}
                  onClick={incrementInteractionCount}
                />
              </InputHelp>
            )}
            <InputHelp
              validationContent={t('components.OfficeCard.nameValidation')}
              invalid={
                isTouched('officeName') && invalidFields.includes('officeName')
              }
            >
              <input
                type="text"
                name="officeName"
                placeholder={t('components.OfficeCard.namePlaceholder')}
                value={officeName}
                disabled={saving}
                onChange={onInputChangeEffect({ onChange })}
                onBlur={onInputBlurEffect({ touch })}
                onClick={incrementInteractionCount}
              />
            </InputHelp>
            <div className="office-card-actions">
              <button
                className="button-link-appearance"
                disabled={saving}
                onClick={onClickCancelEffect({
                  interactionCount,
                  setConfirmCancel,
                  onClickCancel,
                  untouchAll,
                })}
              >
                {t('components.OfficeCard.cancel')}
              </button>
              <button
                className="button-link-appearance"
                disabled={saving}
                onClick={save}
              >
                {saving ? t('common.saving') : t('common.save')}
              </button>
            </div>
          </Fragment>
        )}
      </div>
      <div className="office-card-body">
        <ExpandingCardContent className="office-card-content">
          <form
            onSubmit={(e) => e.preventDefault()}
            onClick={incrementInteractionCount}
          >
            <div className="form-row">
              <InputHelp
                validationContent={t(
                  'components.OfficeCard.primaryPhoneValidation'
                )}
                invalid={
                  isTouched('officePhone1') &&
                  invalidFields.includes('officePhone1')
                }
              >
                <InputLabel content={t('components.OfficeCard.primaryPhone')}>
                  <PhoneInput
                    name="officePhone1"
                    value={officePhone1}
                    disabled={saving}
                    onChange={onChange}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.OfficeCard.secondaryPhoneValidation'
                )}
                invalid={
                  isTouched('officePhone2') &&
                  invalidFields.includes('officePhone2')
                }
              >
                <InputLabel content={t('components.OfficeCard.secondaryPhone')}>
                  <PhoneInput
                    name="officePhone2"
                    value={officePhone2}
                    disabled={saving}
                    onChange={onChange}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t('components.OfficeCard.faxValidation')}
                invalid={
                  isTouched('officeFax') && invalidFields.includes('officeFax')
                }
              >
                <InputLabel content={t('components.OfficeCard.fax')}>
                  <PhoneInput
                    name="officeFax"
                    value={officeFax}
                    disabled={saving}
                    onChange={onChange}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.OfficeCard.officeTypeValidation'
                )}
                invalid={
                  isTouched('officeType') &&
                  invalidFields.includes('officeType')
                }
              >
                <InputLabel
                  element="div"
                  content={t('components.OfficeCard.officeType')}
                >
                  <div className="radio-group radio-group-horizontal">
                    <label>
                      <input
                        type="radio"
                        name="officeType"
                        value={OFFICE_TYPES.HEAD}
                        checked={officeType === OFFICE_TYPES.HEAD}
                        disabled={saving}
                        onChange={onSelectionChangeEffect({ onChange, touch })}
                      />
                      <span>{t('components.OfficeCard.yes')}</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="officeType"
                        value={OFFICE_TYPES.SATELLITE}
                        checked={officeType === OFFICE_TYPES.SATELLITE}
                        disabled={saving}
                        onChange={onSelectionChangeEffect({ onChange, touch })}
                      />
                      <span>{t('components.OfficeCard.no')}</span>
                    </label>
                  </div>
                </InputLabel>
              </InputHelp>

              <DeactivateToggle
                disabled={saving || isNewOffice}
                isActive={!officeInact}
                onActivate={updateOfficeInactiveEffect({
                  inactive: false,
                  onChange,
                  setConfirmDeactivate,
                })}
                onDeactivate={setConfirmDeactivateEffect({
                  confirm: true,
                  setConfirmDeactivate,
                })}
              />
            </div>
            <div className="form-row">
              <InputHelp
                validationContent={t(
                  'components.OfficeCard.addressLine1Validation'
                )}
                invalid={
                  isTouched('officeAddr1') &&
                  invalidFields.includes('officeAddr1')
                }
              >
                <InputLabel content={t('components.OfficeCard.addressLine1')}>
                  <input
                    type="text"
                    name="officeAddr1"
                    value={officeAddr1}
                    disabled={saving}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.OfficeCard.addressLine2Validation'
                )}
                invalid={
                  isTouched('officeAddr2') &&
                  invalidFields.includes('officeAddr2')
                }
              >
                <InputLabel content={t('components.OfficeCard.addressLine2')}>
                  <input
                    type="text"
                    name="officeAddr2"
                    value={officeAddr2}
                    disabled={saving}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t('components.OfficeCard.cityValidation')}
                invalid={
                  isTouched('officeCity') &&
                  invalidFields.includes('officeCity')
                }
              >
                <InputLabel content={t('components.OfficeCard.city')}>
                  <input
                    type="text"
                    name="officeCity"
                    value={officeCity}
                    disabled={saving}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.OfficeCard.provinceValidation'
                )}
                invalid={
                  isTouched('officeProv') &&
                  invalidFields.includes('officeProv')
                }
              >
                <InputLabel content={t('components.OfficeCard.province')}>
                  <StyledSelect
                    name="officeProv"
                    value={officeProv}
                    disabled={saving}
                    onChange={onSelectionChangeEffect({ onChange, touch })}
                    onBlur={onInputBlurEffect({ touch })}
                  >
                    <option value="" disabled>
                      {t('components.OfficeCard.provincePlaceholder')}
                    </option>
                    {provinces.map((item) => {
                      return (
                        <option value={item.value} key={item.value}>
                          {item.value}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.OfficeCard.postalCodeValidation'
                )}
                invalid={
                  isTouched('officePcode') &&
                  invalidFields.includes('officePcode')
                }
              >
                <InputLabel content={t('components.OfficeCard.postalCode')}>
                  <PostalCodeInput
                    name="officePcode"
                    placeholder="L6L 6L6"
                    value={officePcode}
                    disabled={saving}
                    onChange={onPostalCodeInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </InputLabel>
              </InputHelp>
            </div>
          </form>
        </ExpandingCardContent>
      </div>
      <DeactivateModal
        title={t('components.OfficeCard.DeactivateModal.title')}
        message={t('components.OfficeCard.DeactivateModal.message')}
        mounted={confirmDeactivate}
        onClose={setConfirmDeactivateEffect({
          confirm: false,
          setConfirmDeactivate,
        })}
        onSave={updateOfficeInactiveEffect({
          inactive: true,
          onChange,
          setConfirmDeactivate,
        })}
      />
      <SaveModal
        mounted={confirmCancel}
        onClickCancel={() => setConfirmCancel(false)}
        onClickDiscard={discard}
        onClickProceed={save}
      />
      <NavigationSaveModal
        proceedAfter={async () => await save()}
        shouldBlockNavigation={() => interactionCount > 0 && !confirmCancel}
      />
    </ExpandingCard>
  );
};

OfficeCard.defaultProps = {
  expandEnabled: true,
  isNewOffice: false,
  saving: false,
};

OfficeCard.propTypes = {
  ...ExpandingCard.propTypes,
  office: PropTypes.object.isRequired,
  expandEnabled: PropTypes.bool,
  isNewOffice: PropTypes.bool,
  saving: PropTypes.bool,
  onClickExpand: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ValidatedOfficeCard = (props) => {
  return (
    <ValidationProvider rules={officeValidationRules}>
      <OfficeCard {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingOfficeCard = (props) => (
  <InteractionTrackingProvider>
    <ValidatedOfficeCard {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingOfficeCard;
