import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'assets/unifor-logo-login.png';

const AuthPlaceholder = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="auth-placeholder">
      <div className="auth-placeholder-spacer" />
      <div className="auth-placeholder-content">
        <img
          className="auth-placeholder-logo"
          src={logo}
          alt={t('common.logoAlt')}
        />
        {children}
      </div>
      <div className="auth-placeholder-spacer" />
    </div>
  );
};

export default AuthPlaceholder;
