import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { lawFirmSortOptions } from './functions';

const LawFirmSortMenu = (props) => (
  <SortMenu
    className="law-firm-sort-menu"
    sortOptions={lawFirmSortOptions}
    {...props}
  />
);

LawFirmSortMenu.defaultProps = {
  field: 'firmName',
  direction: 'asc',
};

LawFirmSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default LawFirmSortMenu;
