import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import Modal from 'js/components/modal';
import { useLocation, useHistory } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import {
  onMountEffect,
  lawFirmRequestEffect,
  previousLawFirmResultsEffect,
  nextLawFirmResultsEffect,
  lawFirmResultsPageNumberEffect,
  lawFirmResultsSortEffect,
  lawFirmSearchEffect,
  lawFirmSearchResetEffect,
  lawFirmResultsActiveEffect,
} from './effects';
import { queryForLawFirms, FIRM_ACTIVE_STATUS } from './functions';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListButtonCard,
  StyledSelect,
} from 'js/components/design-system';
import LawFirmSortMenu from 'js/components/menus/law-firm-sort-menu';
import { InactiveTag } from 'js/components/deactivate';

const SelectLawfirmModal = (props) => {
  const { mounted, onClose, onSelectFirm, onSelectBarrister } = props;
  const { t } = useTranslation();

  const abortControllerRef = useRef(null);
  const history = useHistory();
  const { search = '' } = useLocation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [lawFirms, setLawFirms] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const query = queryForLawFirms(search);
  const { ActiveStatus } = query.params;

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );
  useEffect(
    lawFirmRequestEffect({
      t,
      api,
      query,
      setActive,
      setLawFirms,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="select-lawfirm-pagination-container">
      <OffsetLimitPagination
        className="law-firm-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousLawFirmResultsEffect({
          history,
          query,
        })}
        onClickNext={nextLawFirmResultsEffect({ history, query })}
        onClickPageNumber={lawFirmResultsPageNumberEffect({
          history,
          query,
        })}
      />
    </div>
  );

  return (
    <Modal
      className="select-lawfirm-modal"
      cancelButtonText={t('common.cancel')}
      onClickCancel={onClose}
      titleText={t('components.LawyerPage.lawFirmModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <div className="select-lawfirm-modal-header">
        <h2>{t('components.LawyerPage.lawFirmModal.title')}</h2>
        <div>
          <p>{t('components.LawyerPage.lawFirmModal.soleProprietor')}</p>
          <button
            className="button button-highlight page-action-button"
            onClick={onSelectBarrister}
          >
            {t('components.LawyerPage.lawFirmModal.barristerButton')}
          </button>
        </div>
      </div>

      <div className="law-firms-list-page-search-bar">
        <GeneralSearchInput
          placeholder={t('components.LawFirmListPage.searchPlaceholder')}
          initialQuery={query}
          onSearch={lawFirmSearchEffect({ history, query })}
          onReset={lawFirmSearchResetEffect({ history, query })}
        />
        <StyledSelect
          value={ActiveStatus}
          name="ActiveStatus"
          onChange={lawFirmResultsActiveEffect({ history, query })}
        >
          <option value={FIRM_ACTIVE_STATUS.ALL}>
            {t('components.LawFirmListPage.activeDropdown.allFirms')}
          </option>
          <option value={FIRM_ACTIVE_STATUS.ACTIVE}>
            {t('components.LawFirmListPage.activeDropdown.activeFirms')}
          </option>
          <option value={FIRM_ACTIVE_STATUS.INACTIVE}>
            {t('components.LawFirmListPage.activeDropdown.inactiveFirms')}
          </option>
        </StyledSelect>
        <LawFirmSortMenu
          field={query.params.Order}
          direction={query.params.Sort}
          onSelect={lawFirmResultsSortEffect({ history, query })}
        />
      </div>

      {active && <ActivityIndicatorCard />}

      {!active && lawFirms.length === 0 && (
        <p>{t(`components.LawFirmListPage.emptyStateMessage`)}</p>
      )}

      {!active && lawFirms.length > 0 && (
        <Fragment>
          {pagination}
          <ul className="unstyled-list law-firms-list">
            {lawFirms
              .filter(({ firmName }) => firmName.length > 0)
              .map((lawFirm) => {
                const { firmId, firmName, inactive } = lawFirm;
                return (
                  <li key={firmId}>
                    <ListButtonCard
                      onClick={() => onSelectFirm(lawFirm)}
                      heading={
                        <Fragment>
                          <span>{firmName}</span>
                          <InactiveTag inactive={inactive} />
                        </Fragment>
                      }
                    />
                  </li>
                );
              })}
          </ul>
          {pagination}
        </Fragment>
      )}
    </Modal>
  );
};

SelectLawfirmModal.propTypes = {
  mounted: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSelectFirm: PropTypes.func,
  onSelectBarrister: PropTypes.func,
};

export default SelectLawfirmModal;
