import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { routePaths } from 'js/components/router/route-paths';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import {
  validateEmail,
  validatePostalCode,
  isEmpty,
  validatePhoneNumber,
} from 'js/utilities/validation';

export const FIRM_ACTIVE_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const getRequiredFields = () => {
  return [
    'firmName',
    'address',
    'address2',
    'city',
    'email',
    'postalCode',
    'primaryPhone',
    'province',
  ];
};

export const lawFirmValidationRules = {
  firmName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 150;
  },
  address: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 50;
  },
  address2: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  city: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 50;
  },
  email: (data, key) => validateEmail(data[key], true),
  fax: (data, key) => !isEmpty(data[key]) && validatePhoneNumber(data[key]),
  postalCode: (data, key) => validatePostalCode(data[key]),
  primaryPhone: (data, key) => validatePhoneNumber(data[key]),
  province: (data, key) => !isEmpty(data[key]),
  secondaryPhone: (data, key) =>
    !isEmpty(data[key]) && validatePhoneNumber(data[key]),
};

export const queryForLawFirms = (search = '') => {
  const {
    Keyword = '',
    Order = 'firmName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
    ActiveStatus = FIRM_ACTIVE_STATUS.ACTIVE,
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
    ActiveStatus,
  };

  return queryForParams(params);
};

export const getSaveButtonTitle = (t, isNew, active) => {
  if (isNew) {
    return active ? t('common.creating') : t('common.create');
  } else {
    return active ? t('common.saving') : t('common.save');
  }
};

export const lawFirmBreadcrumbsHistory = (t) => {
  const prevHistory = adminToolsBreadcrumbsHistory(t);
  return [
    ...prevHistory,
    {
      content: t('components.LawFirmListPage.breadcrumbs.firms'),
      path: routePaths.lawFirms,
    },
  ];
};

export const getLawFirmPrintAddress = (lawFirm = {}, asSingleLine = false) => {
  const {
    address = '',
    address2 = '',
    city = '',
    province = '',
    postalCode = '',
  } = lawFirm;

  let lines = [];
  let delimiter = '';

  if (asSingleLine) {
    lines = [`${address} ${address2}`, `${city} ${province} ${postalCode}`];
    delimiter = ', ';
  } else {
    lines = [address, address2, `${city} ${province}`, postalCode];
    delimiter = '<br />';
  }

  return lines
    .map((line) => line.trim())
    .filter((line) => !!line)
    .join(delimiter);
};
