import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { InputHelp } from 'js/components/design-system';
import {
  DeactivateModal,
  DeactivateToggle,
  InactiveTag,
} from 'js/components/deactivate';
import {
  onMountEffect,
  onChangeEditingEffect,
  postCityEffect,
  patchCityEffect,
  saveCityEffect,
  onSaveCityEffect,
  onSubmitCityFormEffect,
  onChangeCityInputEffect,
  onBlurCityInputEffect,
  updateCityActiveEffect,
  setConfirmDeactivateEffect,
  onClickEditEffect,
} from './effects';
import {
  cityValidationRules,
  cityRequiredFields,
  getSaveButtonTitle,
} from './functions';

const CitiesListCard = (props) => {
  const {
    provinceId = '',
    city = {},
    isNew,
    isEditing,
    isEditingDisabled,
    onClickEdit,
    onClickCancel,
    onSave,
  } = props;
  const { t } = useTranslation();
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [cityForEditing, setCityForEditing] = useState({});

  const { api = {} } = useContext(FetchContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const validateAll = (data) => validate(data, cityRequiredFields);
  const touchAll = () => touch(cityRequiredFields);
  const untouchAll = () => untouch(cityRequiredFields);
  const { isFieldValid } = validateAll(cityForEditing);

  const previousEditingRef = useRef(isEditing);
  const saveCityAbortControllerRef = useRef(null);

  const resolvedCity = isEditing ? cityForEditing : city;
  const {
    cityCode = '',
    name = '',
    isActive: isCityActive = false,
  } = resolvedCity;

  useEffect(
    onMountEffect({
      abortControllerRefs: [saveCityAbortControllerRef],
      setSaving,
    }),
    []
  );

  useEffect(
    onChangeEditingEffect({
      isEditing,
      previousEditingRef,
      setCityForEditing,
      city,
    }),
    [isEditing]
  );

  const saveEffect = isNew ? postCityEffect : patchCityEffect;

  const save = saveCityEffect({
    t,
    presentStyledBanner,
    saveEffect: saveEffect({
      t,
      api,
      validateAll,
      touchAll,
      provinceId,
      city: cityForEditing,
      setSaving,
      saveCityAbortControllerRef,
      onSave: onSaveCityEffect({
        setCityForEditing,
        untouchAll,
        onSave,
      }),
    }),
  });

  return (
    <div className="cities-list-card">
      {!isEditing && (
        <div className="cities-list-card-content">
          <div className="cities-list-card-content-left">
            <h2>
              <span>{cityCode}</span>
              <span>{name}</span>
              <InactiveTag invert inactive={!isCityActive} />
            </h2>
          </div>
          <div className="cities-list-card-content-right">
            <button
              className="button-link-appearance"
              type="button"
              onClick={onClickEditEffect({ cityCode, onClickEdit })}
              disabled={isEditingDisabled}
            >
              {t('common.edit')}
            </button>
          </div>
        </div>
      )}
      {isEditing && (
        <Fragment>
          <form
            className="cities-list-card-form"
            onSubmit={onSubmitCityFormEffect({ save })}
          >
            <div className="cities-list-card-form-left">
              <InputHelp
                validationContent={t(
                  `components.ProvinceDetail.cityCodeValidation`
                )}
                invalid={isTouched('cityCode') && !isFieldValid('cityCode')}
              >
                <input
                  type="text"
                  name="cityCode"
                  placeholder={t(
                    `components.ProvinceDetail.cityCodePlaceholder`
                  )}
                  value={cityCode}
                  onChange={onChangeCityInputEffect({ setCityForEditing })}
                  onBlur={onBlurCityInputEffect({ touch })}
                  readOnly={!isNew}
                />
              </InputHelp>

              <InputHelp
                validationContent={t(
                  `components.ProvinceDetail.cityNameValidation`
                )}
                invalid={isTouched('name') && !isFieldValid('name')}
              >
                <input
                  type="text"
                  name="name"
                  placeholder={t(
                    `components.ProvinceDetail.cityNamePlaceholder`
                  )}
                  value={name}
                  onChange={onChangeCityInputEffect({ setCityForEditing })}
                  onBlur={onBlurCityInputEffect({ touch })}
                />
              </InputHelp>

              <DeactivateToggle
                disabled={isSaving || isNew}
                isActive={isCityActive}
                onActivate={updateCityActiveEffect({
                  isCityActive: true,
                  setCityForEditing,
                  setConfirmDeactivate,
                })}
                onDeactivate={setConfirmDeactivateEffect({
                  confirm: true,
                  setConfirmDeactivate,
                })}
              />
            </div>
            <div className="cities-list-card-form-right">
              <button
                className="button-link-appearance"
                type="button"
                onClick={onClickCancel}
                disabled={isSaving}
              >
                {t('common.cancel')}
              </button>
              <button
                className="button-link-appearance"
                type="submit"
                disabled={isSaving}
              >
                {getSaveButtonTitle(t, isNew, isSaving)}
              </button>
            </div>
          </form>
          <DeactivateModal
            title={t('components.ProvinceDetail.DeactivateCityModal.title')}
            message={t('components.ProvinceDetail.DeactivateCityModal.message')}
            mounted={confirmDeactivate}
            onClose={setConfirmDeactivateEffect({
              confirm: false,
              setConfirmDeactivate,
            })}
            onSave={updateCityActiveEffect({
              isCityActive: false,
              setCityForEditing,
              setConfirmDeactivate,
            })}
          />
        </Fragment>
      )}
    </div>
  );
};

CitiesListCard.defaultProps = {
  isNew: false,
  isEditing: false,
  isEditingDisabled: false,
  isSaving: false,
};

CitiesListCard.propTypes = {
  provinceId: PropTypes.string.isRequired,
  city: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  isEditing: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
  isSaving: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickCancel: PropTypes.func,
  onSave: PropTypes.func,
};

const ValidatedCitiesListCard = (props) => {
  return (
    <ValidationProvider rules={cityValidationRules}>
      <CitiesListCard {...props} />
    </ValidationProvider>
  );
};

export default ValidatedCitiesListCard;
