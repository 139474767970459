export const setModalMountedEffect = (options = {}) => {
  const { setModalMounted, setTimestamp } = options;
  return (mounted) => {
    setModalMounted(mounted);
    if (!mounted) {
      setTimestamp(Date.now());
    }
  };
};

export const setHasChequesEffect = (options = {}) => {
  const { setHasCheques } = options;
  return (cheques) => setHasCheques(cheques.length > 0);
};
