import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

const CaseRedirect = (props) => {
  const {
    match: {
      params: { caseNumber },
    },
    location: { search = '' },
  } = props;
  const path = routePathReplacingParams(routePaths.caseInformation, {
    caseNumber,
  });
  return <Redirect to={`${path}${search}`} />;
};

export default CaseRedirect;
