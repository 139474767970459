import { formatNtpInfo } from './functions';
import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const getMultipleNTPDataEffect = (options = {}) => {
  const {
    api,
    date,
    setNTPDataArray,
    setActive,
    setError,
    getNtpAbortControllerRef,
  } = options;

  return async () => {
    setActive(true);
    const url = `/dayendreport/ntp?reportDate=${date}`;

    rotateAbortControllerRef(getNtpAbortControllerRef);
    const { signal } = getNtpAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      const ntpData = json
        .map((item) => formatNtpInfo(item))
        .filter(({ lawyer }) => !lawyer.isStaffLawyer);

      setNTPDataArray(ntpData);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setError(error);
        setNTPDataArray([]);
        setActive(false);
      }
    }
  };
};

export const multiplePrintViewEffect = (options = {}) => {
  const { NTPDataArray, active } = options;
  return () => {
    if (NTPDataArray.length > 0 && !active) {
      window.print();
    }
  };
};
