import IMask from 'imask';
import { dateMaskPattern } from 'js/utilities/masks';

export const createDateMaskEffect = (options = {}) => {
  const { dateMaskRef } = options;
  return () => {
    dateMaskRef.current = IMask.createMask({
      mask: dateMaskPattern,
    });
  };
};

export const onChangeEffect = (options = {}) => {
  const { dateMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = dateMaskRef;
    if (mask) {
      const value = mask.resolve(e.target.value);
      const nextEvent = {
        ...e,
        target: { ...e.target, value },
        currentTarget: { ...e.currentTarget, value },
      };
      onChange(nextEvent);
    }
  };
};
