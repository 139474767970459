import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Overlay } from '../overlay';

const Skeleton = (props) => {
  const { className, animated, overlayVisible, children } = props;
  return (
    <Overlay
      className={classnames(
        'skeleton',
        { 'skeleton-animated': animated },
        className
      )}
      visible={overlayVisible}
    >
      <div className="skeleton-content">{children}</div>
    </Overlay>
  );
};

Skeleton.defaultProps = {
  animated: false,
  overlayVisible: true,
};

Skeleton.propTypes = {
  className: PropTypes.string,
  animated: PropTypes.bool,
  overlayVisible: PropTypes.bool,
};

export default Skeleton;
