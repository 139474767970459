import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { formatDateFromISOString } from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';

export const queryForCaseHistory = (search = '') => {
  const {
    Order = 'date',
    Sort = 'desc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};

export const getCaseHistoryColumns = (t) => {
  return [
    'cif',
    'caseStatus',
    'legalProblemCode',
    'openDate',
    'shreddedDate',
    'reTransferDate',
    'clientId',
    'clientCode',
    'box',
    'file',
  ].map((key) => ({
    key,
    headerContent: t(`components.LawyerCaseHistory.columns.${key}`),
    sortable: false,
  }));
};

export const cleanCaseHistoryItem = (item = {}) => {
  return {
    cif: cleanStringField(item.cif),
    caseStatus: cleanStringField(item.caseStatus),
    legalProblemCode: cleanStringField(item.legalProblemCode),
    openDate: formatDateFromISOString(item.openDate),
    shreddedDate: formatDateFromISOString(item.shreddedDate),
    reTransferDate: formatDateFromISOString(item.reTransferDate),
    clientId: cleanStringField(item.clientId),
    clientCode: cleanStringField(item.clientCode),
    box: cleanStringField(item.box),
    file: cleanStringField(item.file),
  };
};

export const cleanCaseHistory = (caseHistory = []) => {
  return caseHistory.map(cleanCaseHistoryItem);
};
