import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IndentedListCard } from 'js/components/design-system';
import { listCardPropsForClient } from './functions';

const SearchResultsClientCard = (props) => {
  const { className, data = {} } = props;
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <IndentedListCard
      className={classnames(
        'search-results-card search-results-client-card',
        className
      )}
      {...listCardPropsForClient(t, data, location)}
    />
  );
};

SearchResultsClientCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default SearchResultsClientCard;
