import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'js/components/design-system';
import PropTypes from 'prop-types';

const DeactivateToggle = (props = {}) => {
  const { t } = useTranslation();
  const { disabled, isActive, onActivate, onDeactivate } = props;

  return (
    <div className="deactivate-toggle-container">
      <Toggle
        onLabel={t('components.DeactivateToggle.activate')}
        onLabelActive={t('components.DeactivateToggle.active')}
        offLabel={t('components.DeactivateToggle.deactivate')}
        offLabelActive={t('components.DeactivateToggle.inactive')}
        name="status"
        isOn={isActive}
        onChange={isActive ? onDeactivate : onActivate}
        disabled={disabled}
      />
    </div>
  );
};

DeactivateToggle.defaultProps = {
  isActive: false,
};

DeactivateToggle.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default DeactivateToggle;
