import {
  focusPreviousSiblingElement,
  focusNextSiblingElement,
} from 'js/utilities/dom';
import { getActionsHeight, getActionsBottomPosition } from './functions';

export const onClickActionEffect = (options = {}) => {
  const { expanded, setExpanded, onClick } = options;
  return async (e) => {
    if (typeof onClick === 'function') {
      await onClick(e);
    }
    setExpanded(!expanded);
  };
};

export const onClickActionButtonEffect = (options = {}) => {
  const { setActive, onClick } = options;
  return async (e) => {
    if (typeof onClick === 'function') {
      setActive(true);
      await onClick(e);
      setActive(false);
    }
  };
};

export const onContainerTransitionEndEffect = (options = {}) => {
  const { containerRef, setAnimated } = options;
  return (e) => {
    const { target, propertyName } = e;
    const { current: container } = containerRef;
    if (target === container && propertyName === 'height') {
      setAnimated(false);
    }
  };
};

export const manageContainerTransitionListenerEffect = (options = {}) => {
  const { containerRef, onTransitionEnd } = options;
  return () => {
    const { current: container } = containerRef;

    if (container) {
      container.addEventListener('transitionend', onTransitionEnd);
    }

    return () => {
      if (container) {
        container.removeEventListener('transitionend', onTransitionEnd);
      }
    };
  };
};

export const updateActionsPositionEffect = (options = {}) => {
  const {
    actions,
    expanded,
    shouldAnimate,
    setAnimated,
    setHeight,
    setBottom,
  } = options;
  return () => {
    const height = getActionsHeight(expanded, actions.length);
    const bottom = getActionsBottomPosition(expanded, actions.length);
    setAnimated(shouldAnimate);
    setHeight(height);
    setBottom(bottom);
  };
};

export const onActionKeyDownEffect = () => {
  return (e) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        focusNextSiblingElement(e.target);
        break;
      case 'ArrowDown':
        e.preventDefault();
        focusPreviousSiblingElement(e.target);
        break;
      default:
        break;
    }
  };
};
