import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  prepareCaseLawyerRequestBody,
  prepareCaseBillingRequestBody,
} from 'js/utilities/cases';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const updateCaseBillingEffect = (options = {}) => {
  const { setCaseInfo } = options;
  return (name, value) =>
    setCaseInfo((caseInfo) => {
      const { caseBilling = {} } = caseInfo;
      return { ...caseInfo, caseBilling: { ...caseBilling, [name]: value } };
    });
};

export const patchCaseBillingEffect = (options = {}) => {
  const {
    t,
    api,
    isValid,
    caseNumber,
    caseInfo,
    setActive,
    touchAll,
    resetInteractionCount,
    patchCaseBillingAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid || !caseNumber) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    setActive(true);

    rotateAbortControllerRef(patchCaseBillingAbortControllerRef);
    const { signal } = patchCaseBillingAbortControllerRef.current;

    const { caseLawyer = {}, caseBilling = {} } = caseInfo;

    const url = `/Case/${encodeURIComponent(caseNumber)}`;
    const body = {
      ...caseInfo,
      caseLawyer: prepareCaseLawyerRequestBody(caseLawyer),
      caseBilling: prepareCaseBillingRequestBody(caseBilling),
    };

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const saveCaseBillingEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};
