export const onKeyDownEffect = () => {
  return (e) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault();
        e.target.click();
        break;
      default:
        break;
    }
  };
};
