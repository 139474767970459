export * from './validation-rules';
export * from './clean-case';
export * from './clean-case-options';
export * from './copy-case';
export * from './case-defaults';
export * from './case-request-body';
import { userGroups } from 'js/components/group-permissions';

export const caseStatuses = {
  active: 'Active',
  closed: 'Closed',
  denied: 'Denied',
  void: 'Void',
};

export const caseStatusesByCode = {
  O: 'Open Date',
  C: 'Close Date',
  A: 'Active',
  T: 'Complete',
  D: 'Denied',
  V: 'Void',
  AC: 'Auto Close',
  AV: 'Auto Void',
};

export const caseApiKey = (caseNumber) =>
  `/Case/${encodeURIComponent(caseNumber)}`;

export const caseOpenedInHeadOffice = (cif) => {
  //the last 4 digits in the case ID - if they are in the 9000 range this implies that a case was opened by HQ on behalf of another office.
  const openedByHeadOfficeRegex = /^\d{4}\/\d{4}\/9\d{4}$/g;
  return openedByHeadOfficeRegex.test(cif);
};

export const returnCanEditCase = ({
  officeOpened = '',
  currentUser = {},
  hasEditPermission,
  caseStatus,
  adminOnly = false,
  isNew = false,
  isCopy = false,
  cif = '',
}) => {
  // Update 17256: Any user in any office (aside from view only) can edit an active case
  // Brampton and Metro are able to edit each others active cases
  // When a case is closed, only admin and data entry can make any changes (permission level 1 & 2)
  // intake should be able to edit CLOSED cases. For example to enter the shredding date, and in some offices to enter the archive box information.
  //However - only admin and data entry can change the closing values in the billing section.

  // Admin level and data entry can edit any case for any office.
  // Office permissions apply to Notes as well - Oakville users can not add notes on Windsor Cases

  //update Nov 1 2022 (17256) The Feedback Specialist is Intake backup and as such needs all the rights that INTAKE has.
  //update Oct 2 2023 (17939) intake should be able to edit closed cases including adding notes
  const { office = '', userSecurity = {} } = currentUser;
  const { code: officeCode = '' } = office;
  const { accessLevel } = userSecurity;

  const HEADOFFICE = '0100';

  //case was opened by head office for a staff office
  const openedByHead = caseOpenedInHeadOffice(cif);

  const intakeUser =
    accessLevel === userGroups.intakeSpecialist ||
    accessLevel === userGroups.feedbackSpecialist;

  const hasAdmin =
    accessLevel === userGroups.administrator ||
    accessLevel === userGroups.dataEntry;
  const hasOverridePrivilege = hasAdmin || intakeUser;
  //new cases do not have officeOpened or caseStatus
  const hasMatchingOffice = officeOpened ? officeOpened === officeCode : true;
  const caseActive = caseStatus ? caseStatus === 'Active' : true;

  if (hasAdmin || office === HEADOFFICE) {
    //admin and head office can do what they want
    return true;
  }
  if ((isNew || isCopy) && hasEditPermission) {
    //permitted users can open or copy a case
    return true;
  } else if (!adminOnly && hasOverridePrivilege && hasMatchingOffice) {
    //admin and intake can update any case in their office
    return true;
  } else if (
    !adminOnly &&
    hasOverridePrivilege &&
    openedByHead &&
    officeCode === HEADOFFICE
  ) {
    //cases opened in staff offices by head office users should be able to be edited by head office users
    return true;
  } else if (!adminOnly && hasEditPermission && caseActive) {
    //Any user in any office (aside from view only) can edit an active case
    return true;
  } else if (intakeUser && officeCode === HEADOFFICE && !caseActive) {
    //intake should be able to edit closed cases including adding notes
    return true;
  } else {
    return false;
  }
};
