import { cleanStringField } from 'js/utilities/strings';
import { formatDateFromISOString } from 'js/utilities/dates';

export const cleanCaseLogRow = (data = {}) => {
  return {
    caseId: cleanStringField(data.caseId),
    date: formatDateFromISOString(data.date),
    field: cleanStringField(data.field),
    id: cleanStringField(data.id),
    newValue: cleanStringField(data.newValue),
    oldValue: cleanStringField(data.oldValue),
    updatedById: cleanStringField(data.updatedById),
  };
};

export const cleanCaseLog = (data = []) => {
  return data.map(cleanCaseLogRow);
};

export const getCaseLogColumns = (t) => {
  //not inlcuding IDs in this table
  const columns = [
    // 'caseId',
    'date',
    'field',
    // 'id',
    'newValue',
    'oldValue',
    'updatedById',
  ].map((key) => ({
    key,
    headerContent: t(`components.CaseLog.columns.${key}`),
  }));

  return columns;
};
