import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from './card';
import { FontAwesomeIcon } from '../icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ListButtonCard = (props) => {
  const {
    className,
    heading,
    subheading,
    accessory,
    onClick,
    ...cardProps
  } = props;
  return (
    <Card className={classnames('list-button-card', className)} {...cardProps}>
      <button onClick={onClick}>
        <div className="list-button-card-content">
          <h2>{heading}</h2>
          <p>{subheading}</p>
        </div>
        <div className="list-button-card-accessory">{accessory}</div>
      </button>
    </Card>
  );
};

ListButtonCard.defaultProps = {
  accessory: <FontAwesomeIcon icon={faChevronRight} />,
};

ListButtonCard.propTypes = {
  ...Card.propTypes,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  accessory: PropTypes.node,
};

export default ListButtonCard;
