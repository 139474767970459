export const sourceOfCommentCodes = ['LE', 'TC', 'CS', 'ME'];
export const negativeReasonCodes = ['CO', 'SE', 'LW', 'BL'];
export const resolvedHowCodes = ['NA', 'LE', 'TC'];
export const resolvedByCodes = ['PE', 'EX', 'IR', 'DD', 'CM', 'CD', 'NA'];

export const getLocalizedOptions = (t, values = [], i18nPrefix = '') => {
  return values.map((value) => ({ value, title: t(`${i18nPrefix}.${value}`) }));
};

export const getLawyerFeedbackFormOptions = (t) => ({
  sourceOfCommentOptions: getLocalizedOptions(
    t,
    sourceOfCommentCodes,
    'common.sourceOfCommentOptionsByCode'
  ),
  negativeReasonOptions: getLocalizedOptions(
    t,
    negativeReasonCodes,
    'common.negativeReasonOptionsByCode'
  ),
  resolvedHowOptions: getLocalizedOptions(
    t,
    resolvedHowCodes,
    'common.resolvedHowOptionsByCode'
  ),
  resolvedByOptions: getLocalizedOptions(
    t,
    resolvedByCodes,
    'common.resolvedByOptionsByCode'
  ),
});

export const resolveFeedbackErrorMessage = (t, error) => {
  const { body = {} } = error;
  const { error: errorMessage } = body;
  if (
    errorMessage.includes(
      'Unable to create feedback for case, lawyer assigned to case is'
    )
  ) {
    return t(`components.AddLawyerFeedback.requestErrorNoCase`);
  } else {
    return t('components.AddLawyerFeedback.requestError');
  }
};
