import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';
import EmptyState from 'js/components/controls/empty-state';
import ProvincesListPrintHeader from './provinces-list-print-header';
import ProvincesListBreadcrumbs from './breadcrumbs';
import ProvincesListCard from './provinces-list-card';
import { onMountEffect, getProvincesEffect } from './effects';

const ProvincesList = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [isActive, setActive] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const getProvincesAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getProvincesAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    getProvincesEffect({
      t,
      api,
      setActive,
      setProvinces,
      getProvincesAbortControllerRef,
    }),
    []
  );

  return (
    <Page
      className="provinces-list-page"
      title={t('components.ProvincesList.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <ProvincesListBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          <div className="provinces-list-heading">
            <div className="provinces-list-heading-left">
              <h1>{t('components.ProvincesList.heading')}</h1>
            </div>
            <div className="provinces-list-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() => window.print()}
                disabled={isActive}
              >
                {t('common.print')}
              </button>
              <Link
                className="button button-highlight page-action-button"
                to={routePaths.newProvince}
              >
                {t('common.new')}
              </Link>
            </div>
          </div>

          <ProvincesListPrintHeader />

          {isActive && <ActivityIndicatorCard />}

          {!isActive && provinces.length === 0 && (
            <EmptyState
              title={t(`components.ProvincesList.emptyStateMessage`)}
            />
          )}

          {!isActive && provinces.length > 0 && (
            <ul className="unstyled-list provinces-list" data-print-managed>
              {provinces.map((province) => {
                return (
                  <li key={province.id}>
                    <ProvincesListCard province={province} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ProvincesList;
