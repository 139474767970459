import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import { InactiveTag } from 'js/components/deactivate';
import {
  onMountEffect,
  unionRequestEffect,
  previousUnionResultsEffect,
  nextUnionResultsEffect,
  unionResultsPageNumberEffect,
  unionResultsSortEffect,
  unionSearchEffect,
  unionSearchResetEffect,
} from './effects';
import { queryForUnions } from './functions';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListCard,
} from 'js/components/design-system';
import UnionSortMenu from 'js/components/menus/union-sort-menu';
import UnionBreadcrumbs from './breadcrumbs';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { Link } from 'react-router-dom';
import EmptyState from 'js/components/controls/empty-state';

const UnionListPage = (props) => {
  const { className, title, ...pageProps } = props;
  const { t } = useTranslation();

  const abortControllerRef = useRef(null);
  const history = useHistory();
  const { search = '' } = useLocation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [unions, setUnions] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const query = queryForUnions(search);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    unionRequestEffect({
      t,
      api,
      query,
      setActive,
      setUnions,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="union-list-pagination-container">
      <OffsetLimitPagination
        className="law-firm-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousUnionResultsEffect({
          history,
          query,
        })}
        onClickNext={nextUnionResultsEffect({ history, query })}
        onClickPageNumber={unionResultsPageNumberEffect({
          history,
          query,
        })}
      />
    </div>
  );

  return (
    <Page
      className={classnames('union-list-page', className)}
      title={t('components.UnionListPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <div className="layout-container ">
        <div className="layout-column">
          <UnionBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <div className="union-list-page-heading">
            <div className="union-list-page-heading-left">
              <h1>{t('components.UnionListPage.title')}</h1>
            </div>
            <div className="union-list-page-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() =>
                  history.push(routePaths.unionsPrint + query.search)
                }
              >
                {t('common.print')}
              </button>
              <Link
                className="button button-highlight page-action-button"
                to={routePathReplacingParams(routePaths.unionDetails, {
                  unionNumber: 'new',
                })}
              >
                {t('common.new')}
              </Link>
            </div>
          </div>

          <div className="union-list-page-search-bar">
            <GeneralSearchInput
              placeholder={t('components.UnionListPage.searchPlaceholder')}
              initialQuery={query}
              onSearch={unionSearchEffect({ history, query })}
              onReset={unionSearchResetEffect({ history, query })}
            />
            <UnionSortMenu
              field={query.params.SortBy}
              direction={query.params.Sort}
              onSelect={unionResultsSortEffect({ history, query })}
            />
          </div>

          {active && <ActivityIndicatorCard />}

          {!active && unions.length === 0 && (
            <EmptyState
              title={t(`components.UnionListPage.emptyStateMessage`)}
            />
          )}

          {!active && unions.length > 0 && (
            <Fragment>
              {pagination}
              <ul className="unstyled-list union-list">
                {unions.map((union) => {
                  const { unionNumber, unionName, locInact } = union;
                  return (
                    <li key={unionNumber}>
                      <ListCard
                        key={unionNumber}
                        heading={
                          <div className="inactive-tag-container">
                            <span>{`${unionNumber} ${unionName}`}</span>
                            <InactiveTag inactive={locInact} />
                          </div>
                        }
                        linkTo={routePathReplacingParams(
                          routePaths.unionDetails,
                          { unionNumber }
                        )}
                      />
                    </li>
                  );
                })}
              </ul>
              {pagination}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

UnionListPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

export default UnionListPage;
