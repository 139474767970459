import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { lawyerIdMaskPattern } from 'js/utilities/masks';
import { createMaskEffect, onChangeMaskedInputEffect } from './effects';

const SearchLawyerIdInput = (props) => {
  const { t } = useTranslation();
  const maskRef = useRef(null);
  const { lawyerId = '', onChange, ...inputProps } = props;

  useEffect(createMaskEffect({ maskRef, mask: lawyerIdMaskPattern }), []);

  return (
    <div className="search-lawyer-id-input">
      <input
        {...inputProps}
        type="text"
        name="lawyerId"
        autoComplete="off"
        placeholder={t('components.controls.SearchInput.placeholders.lawyerId')}
        value={lawyerId}
        onChange={onChangeMaskedInputEffect({ maskRef, onChange })}
      />
    </div>
  );
};

SearchLawyerIdInput.propTypes = {
  lawyerId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchLawyerIdInput;
