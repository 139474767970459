import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MSALContext } from 'js/components/msal';
import Page from 'js/components/page';
import AuthPlaceholder from 'js/components/auth-placeholder';
import { routePaths } from 'js/components/router/route-paths';

const UnknownUser = () => {
  const { t } = useTranslation();
  const { account } = useContext(MSALContext);

  return (
    <Page className="unknown-user" title={t('components.UnknownUser.title')}>
      <AuthPlaceholder>
        <p className="unknown-user-message">
          {t('components.UnknownUser.accountMessage')}
        </p>
        {account && (
          <p>
            <small>
              {t('components.UnknownUser.accountInfo', {
                username: account.username,
              })}
            </small>
          </p>
        )}
        <Link className="button button-highlight" to={routePaths.logout}>
          {t('components.UnknownUser.logout')}
        </Link>
      </AuthPlaceholder>
    </Page>
  );
};

export default UnknownUser;
