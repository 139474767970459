import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '../icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const StyledSelect = (props) => {
  const { className, children, ...selectProps } = props;
  return (
    <div className={classnames('styled-select', className)}>
      <select {...selectProps}>{children}</select>
      <div className="styled-select-icon">
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </div>
  );
};

StyledSelect.propTypes = {
  className: PropTypes.string,
};

export default StyledSelect;
