import PropTypes from 'prop-types';

export const DATABASE_RESTORE_MODAL_CONTENT_PROP_TYPES = {
  backup: PropTypes.shape({
    sessionId: PropTypes.string,
    startDate: PropTypes.string,
  }),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export const databaseRestoreValidationRules = {
  isDeleteConfirmed: (data, key) => data[key] === true,
};
