import SearchNamesInput from './names-input';
import SearchClientIdInput from './client-id-input';
import SearchLawyerIdInput from './lawyer-id-input';
import SearchCaseNumberInput from './case-number-input';
import SearchPhoneNumberInput from './phone-number-input';

const SubjectInputs = {
  clientName: SearchNamesInput,
  clientId: SearchClientIdInput,
  clientPhoneNumber: SearchPhoneNumberInput,
  lawyerName: SearchNamesInput,
  lawyerId: SearchLawyerIdInput,
  lawyerPhoneNumber: SearchPhoneNumberInput,
  caseNumber: SearchCaseNumberInput,
};

export default SubjectInputs;
