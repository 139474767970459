import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import { FetchContext } from 'js/components/fetch';
import {
  Card,
  InputLabel,
  InputHelp,
  DatePicker,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onCheckboxChangeEffect,
  getProvinceOptionsEffect,
} from './effects';
import { returnLawyerTypeData } from './functions';

const CaseLawyerInformationForm = (props) => {
  const { t } = useTranslation();
  const getProvincesAbortControllerRef = useRef(null);
  const { touch, isTouched } = useContext(ValidationContext);
  const { api = {} } = useContext(FetchContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseLawyer');
  const [options, setOptions] = useState({});

  const {
    className,
    formData = {},
    formOptions = {},
    onChange,
    isNonCoopLawyer = false,
    invalidFields,
  } = props;

  const {
    referralReasonCode = '',
    stateTrustee = false,
    firstMeeting = null,
    contactPerson = '',
    lawyerType = '',
    cityId = '',
    provId = '',
  } = formData;

  const { referralReasons = [] } = formOptions;
  useEffect(
    getProvinceOptionsEffect({
      t,
      setOptions,
      api,
      isNonCoopLawyer,
      getProvincesAbortControllerRef,
    }),
    [isNonCoopLawyer]
  );

  const { provinceOptions = [], citiesByProvince = {} } = options;
  const cities =
    provId && citiesByProvince[provId] ? citiesByProvince[provId] : [];

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  const lawyerTypeData = returnLawyerTypeData(lawyerType, t);

  return (
    <Card className={classnames('case-lawyer-information-form', className)}>
      <h2>{t('components.CaseInformation.LawyerInformationForm.title')}</h2>
      <div className="form-row">
        <InputLabel
          content={t(
            'components.CaseInformation.LawyerInformationForm.lawyerType'
          )}
        >
          <StyledSelect
            name="lawyerType"
            value={lawyerType}
            onChange={onInputChangeEffect({ onChange })}
            disabled={
              Object.keys(lawyerTypeData).length === 0 || !hasEditPermission
            }
          >
            <option key={''} value={''} disabled>
              {t('common.defaultSelectPlaceholder')}
            </option>
            {lawyerTypeData.map((item) => (
              <option
                key={item.typeId}
                value={item.typeId}
              >{`${item.typeId} \u2013 ${item.label}`}</option>
            ))}
          </StyledSelect>
        </InputLabel>
        <InputHelp
          validationContent={t(
            'components.CaseInformation.LawyerInformationForm.referralReasonCodeValidation'
          )}
          invalid={
            isTouched('referralReasonCode') &&
            invalidFields.includes('referralReasonCode')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.LawyerInformationForm.referralReasonCode'
            )}
          >
            <StyledSelect
              name="referralReasonCode"
              value={referralReasonCode}
              onChange={onInputChangeEffect({ onChange })}
              disabled={!hasEditPermission}
            >
              <option key={''} value={''} disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {referralReasons.map(
                ({
                  referralReasonCode: reasonCode,
                  referralReasondesc: reasonDesc,
                }) => (
                  <option
                    key={reasonCode}
                    value={reasonCode}
                  >{`${reasonCode} \u2013 ${reasonDesc}`}</option>
                )
              )}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputLabel content="">
          <Checkbox
            label={t(
              'components.CaseInformation.LawyerInformationForm.stateTrustee'
            )}
            name="stateTrustee"
            checked={stateTrustee}
            onChange={onCheckboxChangeEffect({ onChange })}
            disabled={!hasEditPermission}
          />
        </InputLabel>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.LawyerInformationForm.firstMeetingValidation'
          )}
          invalid={
            isTouched('firstMeeting') && invalidFields.includes('firstMeeting')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.LawyerInformationForm.firstMeeting'
            )}
          >
            <DatePicker
              name="firstMeeting"
              selected={firstMeeting}
              {...datePickerEventHandlers('firstMeeting')}
              readOnly={!hasEditPermission}
            />
          </InputLabel>
        </InputHelp>

        <InputLabel
          content={t(
            'components.CaseInformation.LawyerInformationForm.contactPerson'
          )}
        >
          <input
            type="text"
            name="contactPerson"
            value={contactPerson}
            readOnly={!hasEditPermission}
            {...inputEventHandlers}
          />
        </InputLabel>
      </div>
      {isNonCoopLawyer && (
        <div className="form-row">
          <InputHelp
            validationContent={t(
              'components.LawyerInformation.LawyerAddressForm.provinceValidation'
            )}
            invalid={isTouched('provId') && invalidFields.includes('provId')}
          >
            <InputLabel
              content={t(
                'components.LawyerInformation.LawyerAddressForm.province'
              )}
            >
              <StyledSelect
                value={provId}
                name="provId"
                onBlur={onInputBlurEffect({ touch })}
                {...inputEventHandlers}
              >
                <option value="" disabled>
                  {t(
                    'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                  )}
                </option>
                {provinceOptions.map((prov) => {
                  return (
                    <option value={prov.provId} key={prov.provId}>
                      {prov.provName}
                    </option>
                  );
                })}
              </StyledSelect>
            </InputLabel>
          </InputHelp>

          <InputHelp
            validationContent={t(
              'components.LawyerInformation.LawyerAddressForm.cityValidation'
            )}
            invalid={isTouched('cityId') && invalidFields.includes('cityId')}
          >
            <InputLabel
              content={t('components.LawyerInformation.LawyerAddressForm.city')}
            >
              <StyledSelect
                value={cityId}
                name="cityId"
                disabled={!provId}
                {...inputEventHandlers}
              >
                <option value="" disabled>
                  {t(
                    'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                  )}
                </option>
                {cities &&
                  cities.map((city, k) => {
                    return (
                      <option value={city.cityCode} key={k}>
                        {city.cityName}
                      </option>
                    );
                  })}
              </StyledSelect>
            </InputLabel>
          </InputHelp>
          <div />
        </div>
      )}
    </Card>
  );
};

CaseLawyerInformationForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  formOptions: PropTypes.shape({
    referralReasons: PropTypes.arrayOf(
      PropTypes.shape({
        referralReasonCode: PropTypes.string.isRequired,
        referralReasondesc: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isNonCoopLawyer: PropTypes.bool,
  invalidFields: PropTypes.array,
};

export default CaseLawyerInformationForm;
