import {
  LAWYER_TYPE_ENUM,
  isStaffLawyerType,
  isCooperatingLawyerType,
  isNonCooperatingLawyerType,
} from 'js/utilities/lawyers';
import { formatDateFromISOString } from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';
import { roundToFixedPointString } from 'js/utilities/numbers';
import { isEmpty } from 'js/utilities/validation';

export const cleanCifData = (data, t) => {
  const {
    caseInfo,
    primaryInfo,
    dependentInfo,
    lawyerInfo,
    staffLawyerInfo,
  } = data;

  const {
    caseInformationResponse = {},
    caseLawyerResponse = {},
    caseBillingResponse = {},
  } = caseInfo || {};

  const {
    clientPersonal: primaryPersonal = {},
    clientAddress: primaryAddress = {},
    clientEmployment: primaryEmployment = {},
    clientInactive = false,
  } = primaryInfo || {};

  const {
    clientPersonal: dependentPersonal = {},
    clientAddress: dependentAddress = {},
  } = dependentInfo || {};

  const {
    lawyerType,
    sentToLawyer,
    referralReasonCode,
    referralReasonDesc,
    servicesProvidedCode,
  } = caseLawyerResponse || {};

  const {
    cif = '',
    legalProblemCode = '',
    legalProblemDesc = '',
    intakeId = '',
    openDate = '',
    specialInstructions = '',
    reTransferDate = '',
    caseStatus = '',
  } = caseInformationResponse;

  const {
    planFees = '',
    planHours = '',
    planTaxes = '',
    clientFees = '',
    clientHours = '',
    payee = '',
    lawyerInvoiceDate = '',
  } = caseBillingResponse;

  const {
    firstName: lawyerFirstName = '',
    lastName: lawyerLastName = '',
    firmName: lawyerFirmName = '',
    address: lawyerAddress = '',
    address2: lawyerAddress2 = '',
    city: lawyerCity,
    province: lawyerProvince = '',
    postalCode: lawyerPostalCode = '',
    lawyerId = '',
    phoneNumber: lawyerPhone = '',
    // customaryFee: lawyerCustomaryFee = 0,
  } = lawyerInfo || {};

  const {
    firstName: staffLawyerFirstName = '',
    lastName: staffLawyerLastName = '',
    staffId = '',
  } = staffLawyerInfo || {};

  const cifData = {
    //case info
    cif: cif,
    intakeId: intakeId,
    openDate: formatDateFromISOString(openDate),
    specialInstructions: specialInstructions,
    legalProblem: legalProblemCode
      ? ` ${legalProblemCode}: ${legalProblemDesc}`
      : '',
    legalProblemCode: cleanStringField(legalProblemCode),
    sentToLawyer: sentToLawyer,
    referralReasonCode: cleanStringField(referralReasonCode),
    referralReasonDesc: cleanStringField(referralReasonDesc),
    reTransferDate: formatDateFromISOString(reTransferDate),

    //primary client
    clientStatus: cleanStringField(primaryPersonal.clientStatus),
    clientStatusDesc: cleanStringField(primaryPersonal.clientStatusDescription),
    clientFirstName: cleanStringField(primaryPersonal.firstName),
    clientLastName: cleanStringField(primaryPersonal.lastName),
    clientPrefix: cleanStringField(primaryPersonal.prefix),
    clientId: cleanStringField(primaryPersonal.clientId),
    clientEmail: cleanStringField(primaryPersonal.emailAddress),
    clientPhone: cleanStringField(primaryPersonal.phoneNumber),
    clientAddress: cleanStringField(primaryAddress.address),
    clientAddress2: cleanStringField(primaryAddress.address2),
    clientCity: cleanStringField(primaryAddress.city),
    clientProvince: cleanStringField(primaryAddress.province),
    clientPostal: cleanStringField(primaryAddress.postalCode),
    clientCompanyCode: cleanStringField(primaryEmployment.company?.companyCode),
    clientCompanyName: cleanStringField(primaryEmployment.company?.companyName),
    clientLocalCode: cleanStringField(primaryEmployment.local?.localCode),
    clientLocalName: cleanStringField(primaryEmployment.local?.localName),
    clientSeniorityDate: formatDateFromISOString(
      primaryEmployment.seniorityDate
    ),
    clientEmployeeNumber: primaryEmployment.employeeNumber,
    clientInactive,

    //dependent client
    dependentClientId: dependentPersonal.clientId,
    dependentStatus: cleanStringField(dependentPersonal.clientStatus),
    dependentStatusDesc: cleanStringField(
      dependentPersonal.clientStatusDescription
    ),
    dependentFirstName: cleanStringField(dependentPersonal.firstName),
    dependentLastName: cleanStringField(dependentPersonal.lastName),
    dependentTitle: cleanStringField(dependentPersonal.title),
    dependentEmail: cleanStringField(dependentPersonal.emailAddress),
    dependentPhone: cleanStringField(dependentPersonal.phoneNumber),
    dependentAddress: cleanStringField(dependentAddress.address),
    dependentAddress2: cleanStringField(dependentAddress.address2),
    dependentCity: cleanStringField(dependentAddress.city),
    dependentProvince: cleanStringField(dependentAddress.province),
    dependentPostal: cleanStringField(dependentAddress.postalCode),
    dependentExist: !isEmpty(dependentPersonal.clientId),

    //lawyer
    lawyerId,
    isCoop: isCooperatingLawyerType(lawyerType),
    isNonCoop: isNonCooperatingLawyerType(lawyerType),
    isNonCoopLawyer: parseInt(lawyerType) === LAWYER_TYPE_ENUM.NON_COOP,
    isNonCoopNotary: parseInt(lawyerType) === LAWYER_TYPE_ENUM.NON_COOP_NOTARY,
    isOutsideCanadaLawyer:
      parseInt(lawyerType) === LAWYER_TYPE_ENUM.OUTSIDE_CANADA,
    lawyerFirstName: cleanStringField(lawyerFirstName),
    lawyerLastName: cleanStringField(lawyerLastName),
    lawyerFirmName: cleanStringField(lawyerFirmName),
    lawyerAddress: cleanStringField(lawyerAddress),
    lawyerAddress2: cleanStringField(lawyerAddress2),
    lawyerCity: cleanStringField(lawyerCity),
    lawyerProvince: cleanStringField(lawyerProvince),
    lawyerPostal: cleanStringField(lawyerPostalCode),
    lawyerPhone: cleanStringField(lawyerPhone),
    servicesProvidedCode: cleanStringField(servicesProvidedCode),
    lawyerCustomaryFee: t('common.currency', {
      value: roundToFixedPointString(0, 2), //was lawyerCustomaryFee
    }),

    //staff lawyer
    isStaff: isStaffLawyerType(lawyerType),
    staffLawyerFirstName: cleanStringField(staffLawyerFirstName),
    staffLawyerLastName: cleanStringField(staffLawyerLastName),
    staffLawyerId: staffId,

    //billing information
    payee,
    lawyerInvoiceDate: formatDateFromISOString(lawyerInvoiceDate),
    planFees: t('common.currency', {
      value: roundToFixedPointString(planFees, 2),
    }),
    planHours,
    planTaxes: t('common.currency', {
      value: roundToFixedPointString(planTaxes, 2),
    }),
    planTotal: t('common.currency', {
      value: roundToFixedPointString(planFees + planTaxes, 2),
    }),
    clientFees: t('common.currency', {
      value: roundToFixedPointString(clientFees, 2),
    }),
    clientHours,
  };

  // Billing values must be left blank when
  // printing a CIF for an active case:
  if (caseStatus === 'Active') {
    cifData.lawyerCustomaryFee = '';
    cifData.planFees = '';
    cifData.planHours = '';
    cifData.planTaxes = '';
    cifData.clientFees = '';
    cifData.clientHours = '';
    cifData.planTotal = '';
  }

  return cifData;
};
