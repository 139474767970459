import { isEventInsideElement } from 'js/utilities/events';

export const onMountEffect = (options = {}) => {
  const { elementsExcludedFromDocumentClick, setVisibility } = options;
  const onDocumentClick = onDocumentClickEffect({
    elementsExcludedFromDocumentClick,
    setVisibility,
  });
  return () => {
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  };
};

export const onDocumentClickEffect = (options = {}) => {
  const { elementsExcludedFromDocumentClick, setVisibility } = options;
  return (e) => {
    const isInsideExcludedElement = elementsExcludedFromDocumentClick.reduce(
      (isInside, element) => {
        if (element) {
          return isInside || isEventInsideElement(e, element);
        } else {
          return isInside;
        }
      },
      false
    );

    if (!isInsideExcludedElement) {
      setVisibility(false);
    }
  };
};

export const onClickReferenceElementEffect = (options = {}) => {
  const { isVisible, setVisibility, popper } = options;
  return () => {
    if (popper.update) {
      popper.update();
    }
    setVisibility(!isVisible);
  };
};

export const onKeyUpReferenceElementEffect = (options = {}) => {
  const { isVisible, setVisibility, popper } = options;
  return (e) => {
    if (e.key === ' ') {
      e.preventDefault();
      if (popper.update) {
        popper.update();
      }
      setVisibility(!isVisible);
    }
  };
};
