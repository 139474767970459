import { useContext } from 'react';
import PropTypes from 'prop-types';
import { MSALContext } from './context';

const MSALRenderController = (props) => {
  const {
    renderWhenAuthenticated,
    renderWhenUnauthenticated,
    renderUntilReady,
  } = props;
  const { isReady, account } = useContext(MSALContext);

  if (isReady && account) {
    return renderWhenAuthenticated();
  } else if (isReady && !account) {
    return renderWhenUnauthenticated();
  } else {
    return renderUntilReady();
  }
};

MSALRenderController.defaultProps = {
  renderUntilReady: () => null,
};

MSALRenderController.propTypes = {
  renderWhenAuthenticated: PropTypes.func.isRequired,
  renderWhenUnauthenticated: PropTypes.func.isRequired,
  renderUntilReady: PropTypes.func,
};

export default MSALRenderController;
