import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';
import DatabaseRestoreModalContent from './content';
import { DATABASE_RESTORE_MODAL_CONTENT_PROP_TYPES } from './functions';

const DatabaseRestoreModal = (props) => {
  const { t } = useTranslation();

  const { mounted, ...contentProps } = props;
  const { onCancel } = contentProps;

  const title = t('components.DatabaseBackups.modal.title');

  return (
    <Modal
      className="database-restore-modal"
      onClickCancel={onCancel}
      titleText={title}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
    >
      <h2>{title}</h2>
      <DatabaseRestoreModalContent {...contentProps} />
    </Modal>
  );
};

DatabaseRestoreModal.defaultProps = {
  mounted: false,
};

DatabaseRestoreModal.propTypes = {
  mounted: PropTypes.bool,
  ...DATABASE_RESTORE_MODAL_CONTENT_PROP_TYPES,
};

export default DatabaseRestoreModal;
