import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataContext } from 'js/components/data';
import { PromptContext } from 'js/components/prompt';
import { FetchContext } from 'js/components/fetch';
import { Redirect } from 'react-router-dom';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import { StyledBannerContext } from 'js/components/banner-styled';
import { isNewClientPath, isNewDependentPath } from 'js/utilities/clients';
import ClientPage from '../client-page';
import {
  PersonalInformation,
  Address,
  Employment,
  Account,
} from '../client-forms';
import { ClientContext, ClientProvider } from '../client-context';
import { clientInformationNamedPermissions } from '../client-named-permissions';
import {
  onMountEffect,
  updateClientInformationEffect,
  patchClientInformationEffect,
  postClientInformationEffect,
  saveClientEffect,
} from './effects';
import {
  personalInfoValidationRules,
  addressValidationRules,
  clientEmploymentValidationRules,
  getRequiredFields,
  getSaveButtonTitle,
} from './functions';

const ClientInformation = () => {
  const { path, params } = useRouteMatch();
  const { id, clientCode = '00' } = params;
  const { t } = useTranslation();

  const {
    client,
    setClient,
    hasOutstandingBalance,
    setHasOutstandingBalance,
  } = useContext(ClientContext);

  const { prompt } = useContext(PromptContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editClientInformation');

  const [active, setActive] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  const isNewClient = isNewClientPath(path);
  const isNewDependent = isNewDependentPath(path);
  const isNew = isNewClient || isNewDependent;
  const isPrimaryClient = clientCode === '00' && !isNewDependent;

  const {
    clientAccount = {},
    clientAddress = {},
    clientEmployment = {},
    clientPersonal = {},
  } = client;

  const validateInfo = {
    ...clientAccount,
    ...clientAddress,
    ...clientEmployment,
    ...clientPersonal,
  };

  const isBirthDateRequired =
    isNewDependent && validateInfo.clientStatus === '5';

  const requiredFields = getRequiredFields(
    isNewClient,
    isNewDependent,
    isPrimaryClient,
    isBirthDateRequired
  );

  const { isValid } = validate(validateInfo, requiredFields);
  const touchAllFields = () => touch(requiredFields);

  const patchClientAbortControllerRef = useRef(null);
  const postClientAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        patchClientAbortControllerRef,
        postClientAbortControllerRef,
      ],
      setActive,
    }),
    []
  );

  const patchEffect = patchClientInformationEffect({
    t,
    api,
    cache,
    prompt,
    id,
    clientCode,
    isPrimaryClient,
    setActive,
    isValid,
    client,
    setClient,
    hasOutstandingBalance,
    setHasOutstandingBalance,
    touchAllFields,
    resetInteractionCount,
    patchClientAbortControllerRef,
  });

  const postEffect = postClientInformationEffect({
    t,
    api,
    cache,
    prompt,
    setActive,
    isValid,
    isNewDependent,
    client,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
    postClientAbortControllerRef,
  });

  const saveClient = saveClientEffect({
    t,
    saveEffect: isNew ? postEffect : patchEffect,
    presentStyledBanner,
  });

  return (
    <ClientPage
      className="client-information"
      title={t('components.ClientInformation.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={saveClient}
            disabled={active || !hasEditPermission}
          >
            {getSaveButtonTitle(t, isNew, active)}
          </button>
        </Fragment>
      }
    >
      <NavigationSaveModal
        proceedAfter={async () => await saveClient()}
        shouldBlockNavigation={() => hasEditPermission && interactionCount > 0}
      />
      <form
        onSubmit={(e) => e.preventDefault()}
        onClick={incrementInteractionCount}
      >
        {redirectPath && <Redirect to={redirectPath} />}
        <PersonalInformation
          formData={clientPersonal}
          onChange={updateClientInformationEffect({
            client,
            setClient,
            group: 'clientPersonal',
          })}
          isPrimaryClient={isPrimaryClient}
          isNew={isNew}
          isBirthDateRequired={isBirthDateRequired}
        />

        <Address
          formData={clientAddress}
          onChange={updateClientInformationEffect({
            client,
            setClient,
            group: 'clientAddress',
          })}
        />

        {isPrimaryClient && (
          <Employment
            formData={clientEmployment}
            onChange={updateClientInformationEffect({
              client,
              setClient,
              group: 'clientEmployment',
            })}
          />
        )}

        {isPrimaryClient && !isNew && (
          <Account
            clientId={id}
            formData={clientAccount}
            onChange={updateClientInformationEffect({
              client,
              setClient,
              group: 'clientAccount',
            })}
          />
        )}
      </form>
    </ClientPage>
  );
};

const ClientInformationWithContext = (props) => (
  <ClientProvider>
    <ClientInformation {...props} />
  </ClientProvider>
);

const ClientInformationWithNamedPermissions = (props) => (
  <NamedPermissionsProvider
    namedPermissions={clientInformationNamedPermissions}
  >
    <ClientInformationWithContext {...props} />
  </NamedPermissionsProvider>
);

const ValidatedClientInformation = (props) => {
  const { path, params } = useRouteMatch();
  const { clientCode = '00' } = params;
  const isNewClient = isNewClientPath(path);
  const isNewDependent = isNewDependentPath(path);
  const isNew = isNewClient || isNewDependent;
  const isPrimaryClient = clientCode === '00' && !isNewDependent;
  const personalInfoValidation = personalInfoValidationRules(
    isNew,
    isPrimaryClient
  );

  return (
    <ValidationProvider
      rules={{
        ...personalInfoValidation,
        ...addressValidationRules,
        ...clientEmploymentValidationRules,
      }}
    >
      <ClientInformationWithNamedPermissions {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingClientInformation = (props) => (
  <InteractionTrackingProvider>
    <ValidatedClientInformation {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingClientInformation;
