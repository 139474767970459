import {
  fetchRequest,
  getJson,
  postJson,
  putJson,
  patchJson,
  deleteJson,
} from './requests';

export const configuredFetchRequestEffect = (options = {}) => {
  const {
    requestFunc,
    baseUrl,
    augmentFetchOptions,
    defaultSuccessCallback,
    defaultErrorCallback,
  } = options;
  return async (resource, fetchOptions = {}, defaultCallbackOptions = {}) => {
    const url = `${baseUrl}${resource}`;

    let resolvedFetchOptions = fetchOptions;
    if (typeof augmentFetchOptions === 'function') {
      resolvedFetchOptions = await augmentFetchOptions(fetchOptions);
    }

    const { success = {}, error = {} } = defaultCallbackOptions;
    const {
      bypass: bypassDefaultSuccessCallback = false,
      context: defaultSuccessCallbackContext = {},
    } = success;
    const {
      bypass: bypassDefaultErrorCallback = false,
      context: defaultErrorCallbackContext = {},
    } = error;

    try {
      const result = await requestFunc(url, resolvedFetchOptions);
      if (
        typeof defaultSuccessCallback === 'function' &&
        !bypassDefaultSuccessCallback
      ) {
        await defaultSuccessCallback(result, defaultSuccessCallbackContext);
      }
      return result;
    } catch (error) {
      if (
        typeof defaultErrorCallback === 'function' &&
        !bypassDefaultErrorCallback
      ) {
        await defaultErrorCallback(error, defaultErrorCallbackContext);
      }
      throw error;
    }
  };
};

export const contextValueForResources = (resources = []) => {
  return resources.reduce((next, resource) => {
    const { key, ...resourceOptions } = resource;
    return {
      ...next,
      [key]: {
        getJson: configuredFetchRequestEffect({
          requestFunc: getJson,
          ...resourceOptions,
        }),
        postJson: configuredFetchRequestEffect({
          requestFunc: postJson,
          ...resourceOptions,
        }),
        putJson: configuredFetchRequestEffect({
          requestFunc: putJson,
          ...resourceOptions,
        }),
        patchJson: configuredFetchRequestEffect({
          requestFunc: patchJson,
          ...resourceOptions,
        }),
        deleteJson: configuredFetchRequestEffect({
          requestFunc: deleteJson,
          ...resourceOptions,
        }),
        fetch: configuredFetchRequestEffect({
          requestFunc: fetchRequest,
          ...resourceOptions,
        }),
      },
    };
  }, {});
};
