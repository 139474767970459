import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
  useMemo,
} from 'react';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { onMountEffect, getReleaseEffect } from './effects';
import EmptyState from 'js/components/controls/empty-state';
import { Breadcrumbs } from 'js/components/design-system';
import { Redirect } from 'react-router-dom';
import {
  releaseBreadcrumbsHistory,
  getCompanyColumns,
  returnFilteredData,
  returnModalCompanies,
} from './functions';
import {
  closeModalEffect,
  saveAndCloseModalEffect,
  onChangeCompaniesEffect,
  noEligibleCompaniesBannerEffect,
  saveReleaseEffect,
  completeNewReleaseEffect,
  getEligibleCompaniesEffect,
} from './effects';
import { StyledBannerContext } from 'js/components/banner-styled';
import { SortableTable } from 'js/components/design-system';
import ReleaseTabs from './release-tabs';
import ReleaseHeader from './release-header';
import ManageCompaniesModal from './manage-companies-modal';
import GeneralSearchInput from 'js/components/controls/general-search-input';

const ReleaseCompanies = () => {
  const { t } = useTranslation();
  const { api } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const getAbortControllerRef = useRef(null);
  const postAbortControllerRef = useRef(null);
  const getEligbleAbortControllerRef = useRef(null);
  const location = useLocation();
  const {
    path,
    params: { scheduleId = '', effectiveDate },
  } = useRouteMatch();
  const isNew = effectiveDate === 'new';
  const isCopy = path.includes('copy');
  const { presentStyledBanner, dismissBanner } = useContext(
    StyledBannerContext
  );

  const [searchString, setSearchString] = useState('');
  const [mounted, setMounted] = useState(false);

  const [release, setRelease] = useState({});
  const [active, setActive] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');
  const [eligibleCompanies, setEligibleCompanies] = useState([]);

  const { associatedCompanies = [], endDate } = release;
  const isArchived = endDate !== null;
  const selectedCompanies = associatedCompanies.filter(
    (company) => company.selected === true
  );

  useEffect(
    onMountEffect({
      abortControllerRefs: [getAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    noEligibleCompaniesBannerEffect({
      t,
      presentStyledBanner,
      dismissBanner,
      release,
      active,
      isArchived,
    }),
    [release, active]
  );

  useEffect(
    getEligibleCompaniesEffect({
      t,
      api,
      setActive,
      scheduleId,
      effectiveDate,
      setEligibleCompanies,
      getEligbleAbortControllerRef,
      isNew,
    }),
    []
  );

  const getRelease = getReleaseEffect({
    t,
    api,
    setActive,
    setRelease,
    scheduleId,
    effectiveDate,
    getAbortControllerRef,
    cache,
    isNew,
    isCopy,
    setEligibleCompanies,
  });

  useEffect(getRelease, [effectiveDate]);

  const closeModal = closeModalEffect({
    setMounted,
    cache,
    scheduleId,
    effectiveDate,
    setRelease,
  });

  const saveRelease = saveReleaseEffect({
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    postAbortControllerRef,
    release,
    cache,
    onComplete: getRelease,
    presentStyledBanner,
  });

  const saveNewRelease = completeNewReleaseEffect({
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    postAbortControllerRef,
    release,
    cache,
    onComplete: closeModal,
    presentStyledBanner,
    setRedirectPath,
    isCopy,
  });
  const filteredCompanies = returnFilteredData(selectedCompanies, searchString);
  const modalCompanies = useMemo(
    () => returnModalCompanies(eligibleCompanies, associatedCompanies),
    [eligibleCompanies, associatedCompanies]
  );

  return (
    <Page
      className="release-page"
      title={t('components.FeeSchedulePage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <Breadcrumbs
            className="release-breadcrumbs"
            history={releaseBreadcrumbsHistory(t, location)}
          />
        </div>
      </div>
      {active && <ActivityIndicatorCard />}
      {redirectPath && <Redirect to={redirectPath} />}
      {mounted && (
        <ManageCompaniesModal
          title={t('components.ReleasePage.manageCompanies.title')}
          mounted={mounted}
          active={active}
          onClose={closeModal}
          onChange={onChangeCompaniesEffect({
            release,
            eligibleCompanies,
            setRelease,
            setEligibleCompanies,
          })}
          companies={modalCompanies}
          onSave={saveAndCloseModalEffect({
            setMounted,
            cache,
            scheduleId,
            effectiveDate,
            release,
            eligibleCompanies,
            setRelease,
            setEligibleCompanies,
          })}
          isCopy={isCopy}
        />
      )}
      {!active && (
        <Fragment>
          <div className="layout-container inset-col-1">
            <div className="layout-column">
              <ReleaseHeader
                release={release}
                isNew={isNew}
                isCopy={isCopy}
                onCreate={saveNewRelease}
                onSave={saveRelease}
                isArchived={isArchived}
              />
            </div>
          </div>
          <div className="layout-container inset-col-1">
            <div className="layout-column">
              <ReleaseTabs
                className="release-tabs"
                currentPath={path}
                isCopy={isCopy}
                isNew={isNew}
                params={{ scheduleId, effectiveDate }}
              />
              <div className="form-row search-header">
                <GeneralSearchInput
                  placeholder={t(
                    'components.ReleasePage.manageCompanies.searchPlaceholder'
                  )}
                  onSearch={setSearchString}
                  onReset={() => setSearchString('')}
                  disabled={active}
                />
                <button
                  className="button button-highlight"
                  onClick={() => setMounted(true)}
                  disabled={mounted}
                >
                  {t('components.ReleasePage.manageCompanies.buttonTitle')}
                </button>
              </div>
              {!active && filteredCompanies.length > 0 && (
                <SortableTable
                  columns={getCompanyColumns(t)}
                  data={filteredCompanies}
                  initialSortColumnKey={'code'}
                />
              )}
              {!active && filteredCompanies.length === 0 && (
                <EmptyState
                  title={t(`components.ReleasePage.companiesEmpty`)}
                />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Page>
  );
};

export default ReleaseCompanies;
