import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { companyDetailsBreadcrumbsHistory } from './functions';

const CompanyDetailsBreadcrumbs = () => {
  const { t } = useTranslation();
  const {
    params: { companyCode },
  } = useRouteMatch();
  return (
    <Breadcrumbs
      className="company-details-breadcrumbs"
      history={companyDetailsBreadcrumbsHistory(t, companyCode)}
    />
  );
};

export default CompanyDetailsBreadcrumbs;
