import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const provincesListBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = systemSettingsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.ProvincesList.breadcrumbs.provinces'),
      path: currentPath,
    },
  ];

  return history;
};
