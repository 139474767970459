export const reportsDateRanges = [
  'generateMonthly',
  'generateFirstQuarterly',
  'generateHalfYearly',
  'generateThirdQuarterly',
  'generateYearly',
];

export const reportsListFilters = ['all', ...reportsDateRanges];

export const reportsListFilterOptions = (t, onSelect) => {
  return reportsListFilters.map((name) => ({
    name,
    content: t(`components.ReportsList.filters.${name}`),
    onSelect: (e, index) => onSelect(e, index, name),
  }));
};

export const reportModifyOptions = (options = {}) => {
  const {
    t,
    setEditModalMounted,
    setMoveModalMounted,
    setDuplicateModalMounted,
    setDeleteModalMounted,
    setSaveAsModalMounted,
  } = options;
  return [
    {
      content: t('components.ReportsList.modifyOptions.modify'),
      onSelect: () => {},
      disabled: true,
    },
    {
      content: t('components.ReportsList.modifyOptions.edit'),
      onSelect: () => setEditModalMounted(true),
    },
    {
      content: t('components.ReportsList.modifyOptions.saveAs'),
      onSelect: () => setSaveAsModalMounted(true),
    },
    {
      content: t('components.ReportsList.modifyOptions.move'),
      onSelect: () => setMoveModalMounted(true),
    },
    {
      content: t('components.ReportsList.modifyOptions.duplicate'),
      onSelect: () => setDuplicateModalMounted(true),
    },
    {
      content: t('components.ReportsList.modifyOptions.delete'),
      onSelect: () => setDeleteModalMounted(true),
    },
  ];
};

export const allReportsChecked = (reports = [], property = '') => {
  const uncheckedIdx = reports.findIndex((report) => report[property] !== true);
  return uncheckedIdx === -1;
};
