import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import { isEmpty } from 'js/utilities/validation';

const ReverseChequeModal = (props) => {
  const { t } = useTranslation();
  const { chequePendingReversal, isReversing, onClose, onClickReverse } = props;
  const hasCheque = !isEmpty(chequePendingReversal);
  const { chequeNumber, firstName = '', lastName = '' } = chequePendingReversal;

  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={onClose}
      titleText={t('components.IssueCheque.ReverseChequeModal.title')}
      mounted={hasCheque}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.IssueCheque.ReverseChequeModal.title')}</h2>
      <p>
        {t('components.IssueCheque.ReverseChequeModal.text', {
          chequeNumber: chequeNumber || t('common.na'),
          firstName,
          lastName,
        })}
      </p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isReversing}
          onClick={onClose}
        >
          {t('common.cancel')}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          disabled={isReversing}
          onClick={onClickReverse}
        >
          {isReversing
            ? t('components.IssueCheque.ReverseChequeModal.reversing')
            : t('components.IssueCheque.ReverseChequeModal.reverse')}
        </button>
      </div>
    </Modal>
  );
};

ReverseChequeModal.defaultProps = {
  isReversing: false,
};

ReverseChequeModal.propTypes = {
  chequePendingReversal: PropTypes.shape({
    chequeNumber: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  isReversing: PropTypes.bool,
  onClose: PropTypes.func,
  onClickReverse: PropTypes.func,
};

export default ReverseChequeModal;
