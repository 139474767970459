import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import {
  SortableTable,
  ActivityIndicatorCard,
  OffsetLimitPagination,
} from 'js/components/design-system';
import { PrintFooter } from 'js/components/print';
import EmptyState from 'js/components/controls/empty-state';
import LawyerCaseHistorySortMenu from 'js/components/menus/lawyer-case-history-sort-menu';
import LawyerPrintHeader from '../lawyer-print-header';
import LawyerPage from '../lawyer-page';
import { LawyerContext, LawyerProvider } from '../lawyer-context';
import {
  onMountEffect,
  getCaseHistoryEffect,
  onSelectCaseEffect,
  previousCaseHistoryResultsEffect,
  nextCaseHistoryResultsEffect,
  caseHistoryResultsPageNumberEffect,
  caseHistoryResultsSortEffect,
} from './effects';
import { queryForCaseHistory, getCaseHistoryColumns } from './functions';

const LawyerCaseHistory = () => {
  const history = useHistory();
  const {
    params: { lawyerId },
  } = useRouteMatch();
  const { search } = useLocation();
  const query = queryForCaseHistory(search);

  const { t } = useTranslation();
  const { lawyer } = useContext(LawyerContext);
  const { api = {} } = useContext(FetchContext);

  const [isLoadingCaseHistory, setLoadingCaseHistory] = useState(false);
  const [caseHistory, setCaseHistory] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  const getCaseHistoryAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getCaseHistoryAbortControllerRef],
      setLoadingCaseHistory,
    }),
    []
  );

  useEffect(
    getCaseHistoryEffect({
      t,
      api,
      query,
      lawyerId,
      setLoadingCaseHistory,
      setCaseHistory,
      setTotalResults,
      getCaseHistoryAbortControllerRef,
    }),
    [query.search]
  );

  return (
    <LawyerPage
      className="lawyer-case-history"
      title={t('components.LawyerCaseHistory.title')}
      actions={
        <button
          className="button button-highlight page-action-button"
          onClick={() => window.print()}
        >
          {t('common.print')}
        </button>
      }
    >
      <LawyerPrintHeader
        title={t('components.LawyerCaseHistory.printHeaderTitle')}
        lawyer={lawyer}
      />
      {isLoadingCaseHistory && <ActivityIndicatorCard />}

      {!isLoadingCaseHistory && (
        <Fragment>
          <div className="lawyer-case-history-sort-container">
            <LawyerCaseHistorySortMenu
              field={query.params.Order}
              direction={query.params.Sort}
              onSelect={caseHistoryResultsSortEffect({ history, query })}
            />
          </div>
          {caseHistory.length === 0 && (
            <EmptyState
              title={t('components.LawyerCaseHistory.emptyStateMessage')}
            />
          )}
          {caseHistory.length > 0 && (
            <SortableTable
              columns={getCaseHistoryColumns(t)}
              data={caseHistory}
              initialSortColumnKey={'date'}
              initialSortDirection={query.params.Sort}
              onClickRow={onSelectCaseEffect({ history })}
              printManaged={true}
            />
          )}
          <div className="lawyer-case-history-pagination-container">
            <OffsetLimitPagination
              className="lawyer-case-history-pagination"
              offset={query.params.Offset}
              limit={query.params.Limit}
              totalResults={totalResults}
              onClickPrevious={previousCaseHistoryResultsEffect({
                history,
                query,
              })}
              onClickNext={nextCaseHistoryResultsEffect({
                history,
                query,
              })}
              onClickPageNumber={caseHistoryResultsPageNumberEffect({
                history,
                query,
              })}
            />
          </div>
        </Fragment>
      )}

      <PrintFooter />
    </LawyerPage>
  );
};

const LawyerCaseHistoryWithContext = (props) => (
  <LawyerProvider>
    <LawyerCaseHistory {...props} />
  </LawyerProvider>
);

export default LawyerCaseHistoryWithContext;
