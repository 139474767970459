import { isCooperatingLawyerType } from 'js/utilities/lawyers';

export const resolveStringValueForCooperatingLawyer = (
  value = '',
  isCooperatingLawyer = false
) => {
  return isCooperatingLawyer ? value : '';
};

export const formatNtpInfo = (caseInfo = {}, client = {}, lawyer = {}) => {
  const { caseInformation = {}, caseLawyer = {} } = caseInfo;
  const { cif, legalProblemDesc } = caseInformation;
  const { lawyerType } = caseLawyer;

  const { clientPersonal = {} } = client;
  const {
    firstName: clientFirstName,
    lastName: clientLastName,
  } = clientPersonal;

  const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;
  const {
    lawyerId = '',
    firstName: lawyerFirstName = '',
    lastName: lawyerLastName = '',
    address: lawyerAddress = '',
    address2: lawyerAddress2 = '',
    city: lawyerCity = '',
    province: lawyerProvince = '',
    postalCode: lawyerPostalCode = '',
    phoneNumber: lawyerPhone = '',
  } = lawyerInfo;
  const { firmName = '' } = lawyerFirm;

  const isCooperatingLawyer = isCooperatingLawyerType(lawyerType);

  return {
    cif,
    legalProblem: legalProblemDesc,
    client: {
      firstName: clientFirstName,
      lastName: clientLastName,
    },
    lawyer: {
      isCooperatingLawyer,
      lawyerId: resolveStringValueForCooperatingLawyer(
        lawyerId,
        isCooperatingLawyer
      ),
      firmName: resolveStringValueForCooperatingLawyer(
        firmName,
        isCooperatingLawyer
      ),
      firstName: resolveStringValueForCooperatingLawyer(
        lawyerFirstName,
        isCooperatingLawyer
      ),
      lastName: resolveStringValueForCooperatingLawyer(
        lawyerLastName,
        isCooperatingLawyer
      ),
      address: resolveStringValueForCooperatingLawyer(
        lawyerAddress,
        isCooperatingLawyer
      ),
      address2: resolveStringValueForCooperatingLawyer(
        lawyerAddress2,
        isCooperatingLawyer
      ),
      city: resolveStringValueForCooperatingLawyer(
        lawyerCity,
        isCooperatingLawyer
      ),
      province: resolveStringValueForCooperatingLawyer(
        lawyerProvince,
        isCooperatingLawyer
      ),
      postalCode: resolveStringValueForCooperatingLawyer(
        lawyerPostalCode,
        isCooperatingLawyer
      ),
      phone: resolveStringValueForCooperatingLawyer(
        lawyerPhone,
        isCooperatingLawyer
      ),
    },
  };
};
