import React from 'react';
import { numericTableSortOptions } from 'js/components/design-system';
import {
  caseNumberPattern,
  floatPattern,
  validatePostalCode,
  isEmpty,
} from 'js/utilities/validation';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { proceduresBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const chequePayee = {
  lawyer: '1',
  client: '2',
  other: '3',
};

export const chequeRecipientDefaults = {
  prefix: '',
  firstName: '',
  lastName: '',
  firmName: '',
  address: '',
  address2: '',
  city: '',
  province: '',
  postalCode: '',
};

export const chequeDefaults = {
  payee: '',
  date: null,
  chequeNumber: '',
  hours: '0.00',
  fee: '0.00',
  gst: '0.00',
  pst: '0.00',
  total: '0.00',
  ...chequeRecipientDefaults,
};

export const isPayeeLawFirm = (payee = '', lawyer = {}) => {
  const { lawyerInfo = {} } = lawyer;
  const { payFirm = false } = lawyerInfo;
  return payee === chequePayee.lawyer && payFirm;
};

export const calculateChequeTotal = (cheque) => {
  const fee = Number(cheque.fee);
  const gst = Number(cheque.gst);
  const pst = Number(cheque.pst);
  return fee + gst + pst;
};

export const resolveRecipientFieldsForClient = (client = {}) => {
  const { clientPersonal = {}, clientAddress = {} } = client;
  const { prefix = '', firstName = '', lastName = '' } = clientPersonal;
  const {
    address = '',
    address2 = '',
    city = '',
    province = '',
    postalCode = '',
  } = clientAddress;
  return {
    ...chequeRecipientDefaults,
    prefix,
    firstName,
    lastName,
    address,
    address2,
    city,
    province,
    postalCode,
  };
};

export const resolveRecipientFieldsForLawyer = (lawyer = {}) => {
  const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;

  if (lawyerInfo.payFirm) {
    return resolveRecipientFieldsForLawFirm(lawyerFirm);
  }

  const {
    prefix = '',
    firstName = '',
    lastName = '',
    address = '',
    address2 = '',
    city = '',
    province = '',
    postalCode = '',
  } = lawyerInfo;

  return {
    ...chequeRecipientDefaults,
    prefix,
    firstName,
    lastName,
    address,
    address2,
    city,
    province,
    postalCode,
  };
};

export const resolveRecipientFieldsForLawFirm = (firm = {}) => {
  const {
    firmName = '',
    address = '',
    address2 = '',
    city = '',
    province = '',
    postalCode = '',
  } = firm;

  return {
    ...chequeRecipientDefaults,
    firmName,
    address,
    address2,
    city,
    province,
    postalCode,
  };
};

export const resolveRecipientFieldsForPayee = (
  payee,
  client = {},
  lawyer = {}
) => {
  switch (payee) {
    case chequePayee.client:
      return resolveRecipientFieldsForClient(client);
    case chequePayee.lawyer:
      return resolveRecipientFieldsForLawyer(lawyer);
    default:
      return chequeRecipientDefaults;
  }
};

export const queryForSearchString = (search = '') => {
  const { caseNumber = '' } = paramsFromSearchString(search);
  return queryForParams({ caseNumber });
};

export const issueChequeBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;

  return [
    ...proceduresBreadcrumbsHistory(t),
    {
      content: t('components.IssueCheque.breadcrumbs.issueCheque'),
      path: currentPath,
    },
  ];
};

export const focusInputRef = (inputRef) => {
  const { current: input } = inputRef;
  if (input) {
    input.focus();
  }
};

export const issueChequeValidationRules = {
  inputCaseNumber: caseNumberPattern,
};

export const issueChequeRequiredFields = Object.keys(
  issueChequeValidationRules
);

// chequeNamePattern is similar to namePattern from js/utilities/validation
// with a longer character length allowance to match the database field length:
// eslint-disable-next-line no-useless-escape
export const chequeNamePattern = /^[a-zA-Z-'’\(\)\s\.]{2,150}$/;

export const newChequeValidationRules = {
  payee: (data, key) => Object.values(chequePayee).includes(data[key]),
  hours: floatPattern,
  fee: floatPattern,
  gst: floatPattern,
  pst: floatPattern,
  firstName: chequeNamePattern,
  lastName: chequeNamePattern,
  firmName: (data, key) => !isEmpty(data[key]) && data[key].length <= 150,
  address: (data, key) => !isEmpty(data[key]) && data[key].length <= 150,
  address2: (data, key) => isEmpty(data[key]) || data[key].length <= 150,
  city: (data, key) => !isEmpty(data[key]) && data[key].length <= 20,
  province: (data, key) => !isEmpty(data[key]),
  postalCode: (data, key) => validatePostalCode(data[key]),
};

export const newChequeRequiredFields = (payeeIsLawFirm = false) => {
  const commonFields = [
    'payee',
    'hours',
    'fee',
    'gst',
    'pst',
    'address',
    'address2',
    'city',
    'province',
    'postalCode',
  ];
  if (payeeIsLawFirm) {
    return [...commonFields, 'firmName'];
  } else {
    return [...commonFields, 'firstName', 'lastName'];
  }
};

export const getChequesColumns = (t, onClickReverseCheque) => {
  const chequeNumberColumn = {
    key: 'chequeNumber',
    headerContent: t('components.IssueCheque.columns.chequeNumber'),
    sort: numericTableSortOptions,
    get: (key, row) => {
      const value = row[key];
      if (value) {
        return value.toString();
      } else {
        return t('common.na');
      }
    },
  };

  const nameColumn = {
    key: 'payee',
    headerContent: t('components.IssueCheque.columns.payee'),
    get: (key, row) => {
      const { firstName = '', lastName = '' } = row;
      const fullName = `${lastName}, ${firstName}`;
      const payeeName = lastName ? fullName : firstName;
      return payeeName;
    },
    sort: {
      asc: (key, row1, row2) => {
        const { firstName: firstName1, lastName: lastName1 } = row1;
        const fullName1 = `${lastName1}, ${firstName1}`;
        const payeeName1 = lastName1 ? fullName1 : firstName1;
        const { firstName: firstName2, lastName: lastName2 } = row2;
        const fullName2 = `${lastName2}, ${firstName2}`;
        const payeeName2 = lastName2 ? fullName2 : firstName2;
        if (payeeName1.toLowerCase() !== payeeName2.toLowerCase()) {
          return payeeName1 > payeeName2 ? 1 : -1;
        } else {
          return 0;
        }
      },
      desc: (key, row1, row2) => {
        const { firstName: firstName1, lastName: lastName1 } = row1;
        const fullName1 = `${lastName1}, ${firstName1}`;
        const payeeName1 = lastName1 ? fullName1 : firstName1;
        const { firstName: firstName2, lastName: lastName2 } = row2;
        const fullName2 = `${lastName2}, ${firstName2}`;
        const payeeName2 = lastName2 ? fullName2 : firstName2;
        if (payeeName1.toLowerCase() !== payeeName2.toLowerCase()) {
          return payeeName1 < payeeName2 ? 1 : -1;
        } else {
          return 0;
        }
      },
    },
  };

  const dateColumn = {
    key: 'date',
    headerContent: t('components.IssueCheque.columns.date'),
  };

  const totalColumn = {
    key: 'total',
    headerContent: t('components.IssueCheque.columns.total'),
    get: (key, row) => t('common.currency', { value: row[key] }),
    sort: numericTableSortOptions,
  };

  const statusColumn = {
    key: 'status',
    headerContent: t('components.IssueCheque.columns.status'),
  };

  const actionsColumn = {
    key: 'actions',
    headerContent: '',
    sortable: false,
    /* eslint-disable react/display-name */
    get: (key, row) => {
      const issued = row.status === 'I';
      const printed = row.status === 'P';
      //we believe status X is how the system can tell what was in the last cheque run
      const lastRun = row.status === 'X';
      if (issued || printed || lastRun) {
        return (
          <button
            className="button-link-appearance"
            onClick={() => onClickReverseCheque(row)}
          >
            {t('components.IssueCheque.reverseCheque')}
          </button>
        );
      } else {
        return null;
      }
    },
    /* eslint-enable react/display-name */
  };

  return [
    chequeNumberColumn,
    nameColumn,
    dateColumn,
    totalColumn,
    statusColumn,
    actionsColumn,
  ];
};
