import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanDataIntegrityReportItems } from './functions';
import { isEmpty } from 'js/utilities/validation';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoading } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoading(false);
    };
  };
};

export const getDataIntegrityReportEffect = (options = {}) => {
  const {
    t,
    api,
    setLoading,
    setItems,
    getDataIntegrityReportAbortControllerRef,
  } = options;
  return async () => {
    setLoading(true);

    rotateAbortControllerRef(getDataIntegrityReportAbortControllerRef);
    const { signal } = getDataIntegrityReportAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Admin/Data/Integrity/PrimaryClient',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.PrimaryClientIntegrity.getDataIntegrityReportRequestError'
              ),
            },
          },
        }
      );

      const { items = [] } = json;

      setItems(cleanDataIntegrityReportItems(items));
      setLoading(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false);
      }
    }
  };
};

export const onClickUpdateClientStatusEffect = (options = {}) => {
  const { setItemToUpdate } = options;
  return (item) => setItemToUpdate(item);
};

export const postClientStatusEffect = (options = {}) => {
  const {
    t,
    api,
    setSaving,
    setItems,
    setItemToUpdate,
    postClientStatusAbortControllerRef,
  } = options;
  return async (formData) => {
    try {
      setSaving(true);

      rotateAbortControllerRef(postClientStatusAbortControllerRef);
      const { signal } = postClientStatusAbortControllerRef.current;

      const { cif, primaryClientStatus } = formData;
      const body = { CIF: cif, NewClientStatus: primaryClientStatus };

      await api.postJson(
        '/Admin/Data/Integrity/PrimaryClient',
        { body, signal },
        {
          success: {
            context: {
              message: t(
                'components.PrimaryClientIntegrity.postClientStatusRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.PrimaryClientIntegrity.postClientStatusRequestError'
              ),
            },
          },
        }
      );

      // Update the items array with the new primary
      // client status for the updated cif:
      setItems((items) => {
        return items.map((item) => {
          if (item.cif === cif) {
            return {
              ...item,
              cas_priclistat: primaryClientStatus,
              updated: true,
            };
          } else {
            return item;
          }
        });
      });

      setItemToUpdate({});
      setSaving(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

// Client Status Modal

export const onMountClientStatusModalEffect = (options = {}) => {
  const { mounted, setPrimaryClientStatus, untouchAll } = options;
  return () => {
    // Abort requests on modal unmount:
    if (!mounted) {
      setPrimaryClientStatus('');
      untouchAll();
    }
  };
};

export const onSubmitClientStatusFormEffect = (options = {}) => {
  const {
    // validateAll,
    touchAll,
    formData,
    onSubmit,
  } = options;
  return (e) => {
    e.preventDefault();
    //removed the validations because in card #16555 the client has asked that there be nothing validating what status is sent,
    //but was encouraged to still show a guiding error message to hopefully prevent non-status values
    //was assured by the client that improper values would just appear in this report to be corrected again

    // const { isValid } = validateAll(formData);

    if (!isEmpty(formData.primaryClientStatus)) {
      onSubmit(formData);
    } else {
      touchAll();
    }
    // if (isValid) {
    //   onSubmit(formData);
    // } else {
    //   touchAll();
    // }
  };
};

export const onChangePrimaryClientStatusEffect = (options = {}) => {
  const { setPrimaryClientStatus } = options;
  return (e) => {
    const { value } = e.target;
    if (value) {
      const intValue = parseInt(value);
      if (!isNaN(intValue)) {
        setPrimaryClientStatus(intValue);
      }
    } else {
      setPrimaryClientStatus('');
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};
