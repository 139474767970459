import {
  resolveCreateChequeRunPath,
  resolveDeleteChequeRunPath,
} from './functions';

export const mountModalOnLockEffect = (options = {}) => {
  const { lockRef, lock, mounted, setMounted } = options;
  return () => {
    if (!mounted && !lockRef.current && lock) {
      setMounted(true);
    }
    lockRef.current = lock;
  };
};

export const onModalMountChangeEffect = (options = {}) => {
  const { mounted, setChequeRunResults, setChequeRunError } = options;
  return () => {
    if (!mounted) {
      setChequeRunResults({});
      setChequeRunError({});
    }
  };
};

export const onClickCancelEffect = (options = {}) => {
  const { setMounted } = options;
  return () => setMounted(false);
};

export const onClickLockEffect = (options = {}) => {
  const { toggleLockSystem } = options;
  return async () => await toggleLockSystem();
};

export const onClickUnlockEffect = (options = {}) => {
  const { toggleLockSystem, setMounted } = options;
  return async () => {
    await toggleLockSystem();
    setMounted(false);
  };
};

export const createRunEffect = (options = {}) => {
  const {
    api,
    setCreateRunActive,
    setChequeRunResults,
    setChequeRunError,
  } = options;
  return async (cheque, chequeRunOptions = {}) => {
    setCreateRunActive(true);

    const { reprintLastRun = false, reprintChequeCount } = chequeRunOptions;

    const path = resolveCreateChequeRunPath(reprintLastRun, reprintChequeCount);

    try {
      const { json = {} } = await api.postJson(
        path,
        { body: cheque },
        {
          success: {
            bypass: true,
          },
          error: {
            bypass: true,
          },
        }
      );

      setChequeRunResults(json);
      setChequeRunError({});
      setCreateRunActive(false);
    } catch (error) {
      setChequeRunError(error);
      setCreateRunActive(false);
    }
  };
};

export const deleteRunEffect = (options = {}) => {
  const {
    api,
    setActive,
    setChequeRunResults,
    setChequeRunError,
    toggleLockSystem,
    setMounted,
  } = options;
  return async (shouldConfirmRun = false, deleteOptions = {}) => {
    try {
      setActive(true);

      const {
        reprintLastRun = false,
        reprintSingleCheque = false,
        unlock = false,
      } = deleteOptions;

      const path = resolveDeleteChequeRunPath(
        reprintLastRun,
        reprintSingleCheque,
        shouldConfirmRun
      );
      const deleteOnly = shouldConfirmRun ? 'false' : 'true';

      await api.deleteJson(
        `${path}?deleteOnly=${deleteOnly}`,
        {},
        {
          success: {
            bypass: true,
          },
          error: {
            bypass: true,
          },
        }
      );

      if (unlock) {
        await toggleLockSystem();
        setMounted(false);
      }

      setChequeRunResults({});
      setChequeRunError({});
      setActive(false);
    } catch (error) {
      setChequeRunError(error);
      setActive(false);
    }
  };
};

export const onClickConfirmRunEffect = (options = {}) => {
  const { setConfirmRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setConfirmRunActive,
  });
  return (e, deleteOptions) => deleteRun(true, deleteOptions);
};

export const onClickDeleteRunEffect = (options = {}) => {
  const { setDeleteRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setDeleteRunActive,
  });
  return (e, deleteOptions) => deleteRun(false, deleteOptions);
};

export const onClickConfirmReprintLastRunEffect = (options = {}) => {
  const { setConfirmRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setConfirmRunActive,
  });
  return (e, deleteOptions = {}) =>
    deleteRun(true, { ...deleteOptions, reprintLastRun: true, unlock: true });
};

export const onClickDeleteReprintLastRunEffect = (options = {}) => {
  const { setDeleteRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setDeleteRunActive,
  });
  return (e, deleteOptions = {}) =>
    deleteRun(false, { ...deleteOptions, reprintLastRun: true });
};

export const onClickConfirmReprintSingleChequeEffect = (options = {}) => {
  const { setConfirmRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setConfirmRunActive,
  });
  return (e, deleteOptions = {}) =>
    deleteRun(true, {
      ...deleteOptions,
      reprintSingleCheque: true,
      unlock: true,
    });
};

export const onClickDeleteReprintSingleChequeEffect = (options = {}) => {
  const { setDeleteRunActive, ...effectOptions } = options;
  const deleteRun = deleteRunEffect({
    ...effectOptions,
    setActive: setDeleteRunActive,
  });
  return (e, deleteOptions = {}) =>
    deleteRun(false, {
      ...deleteOptions,
      reprintSingleCheque: true,
    });
};

export const onClickConfirmDeleteRunEffect = (options = {}) => {
  const { onClickDeleteRun, setConfirmingDeleteRun } = options;
  return async () => {
    await onClickDeleteRun();
    setConfirmingDeleteRun(false);
  };
};

export const resolveOnClickConfirmRunEffect = (
  reprintLastRun,
  reprintChequeCount
) => {
  if (reprintLastRun) {
    return onClickConfirmReprintLastRunEffect;
  } else if (!isNaN(reprintChequeCount)) {
    return onClickConfirmReprintSingleChequeEffect;
  } else {
    return onClickConfirmRunEffect;
  }
};

export const resolveOnClickDeleteRunEffect = (
  reprintLastRun,
  reprintChequeCount
) => {
  if (reprintLastRun) {
    return onClickDeleteReprintLastRunEffect;
  } else if (!isNaN(reprintChequeCount)) {
    return onClickDeleteReprintSingleChequeEffect;
  } else {
    return onClickDeleteRunEffect;
  }
};
