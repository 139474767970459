import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputHelp, Checkbox } from 'js/components/design-system';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { formatDateFromISOString } from 'js/utilities/dates';
import { DATABASE_BACKUPS_DATE_FORMAT } from '../functions';
import {
  onCheckboxChangeEffect,
  onSubmitDatabaseRestoreFormEffect,
} from './effects';
import {
  DATABASE_RESTORE_MODAL_CONTENT_PROP_TYPES,
  databaseRestoreValidationRules,
} from './functions';

const DatabaseRestoreModalContent = (props) => {
  const { t } = useTranslation();

  const { backup = {}, onConfirm, onCancel } = props;

  const [isDeleteConfirmed, setDeleteConfirmed] = useState(false);

  const { validate, touch, isTouched } = useContext(ValidationContext);
  const inputData = { isDeleteConfirmed };
  const validateAll = () => validate(inputData, Object.keys(inputData));
  const touchAll = () => touch(Object.keys(inputData));
  const { invalidFields = [] } = validateAll();

  return (
    <form
      className="database-restore-modal-content"
      onSubmit={onSubmitDatabaseRestoreFormEffect({
        validateAll,
        touchAll,
        backup,
        onConfirm,
      })}
    >
      <p>{t('components.DatabaseBackups.modal.message')}</p>

      <ul>
        <li>
          <strong>{t('components.DatabaseBackups.modal.date')}</strong>{' '}
          <span>
            {formatDateFromISOString(
              backup.startTime,
              DATABASE_BACKUPS_DATE_FORMAT
            )}
          </span>
        </li>
      </ul>

      <InputHelp
        validationContent={t(
          'components.DatabaseBackups.modal.confirmDeleteValidation'
        )}
        invalid={
          isTouched('isDeleteConfirmed') &&
          invalidFields.includes('isDeleteConfirmed')
        }
      >
        <Checkbox
          label={t('components.DatabaseBackups.modal.confirmDelete')}
          checked={isDeleteConfirmed}
          name="isDeleteConfirmed"
          onChange={onCheckboxChangeEffect({ setChecked: setDeleteConfirmed })}
        />
      </InputHelp>

      <div className="button-bar">
        <button
          type="button"
          className="button-link-appearance"
          onClick={onCancel}
        >
          {t('common.cancel')}
        </button>
        <button type="submit" className="button button-highlight">
          {t('components.DatabaseBackups.modal.confirmButtonText')}
        </button>
      </div>
    </form>
  );
};

DatabaseRestoreModalContent.propTypes = {
  ...DATABASE_RESTORE_MODAL_CONTENT_PROP_TYPES,
};

const ValidatedDatabaseRestoreModalContent = (props) => (
  <ValidationProvider rules={databaseRestoreValidationRules}>
    <DatabaseRestoreModalContent {...props} />
  </ValidationProvider>
);

export default ValidatedDatabaseRestoreModalContent;
