import React, { Fragment, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import {
  Card,
  InputHelp,
  InputLabel,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { legalProblemCodeRequiredFields } from 'js/utilities/legal-problem-codes';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onNumericInputBlurEffect,
  onCheckboxChangeEffect,
  createLpCodeMaskEffect,
  onLpCodeInputChangeEffect,
} from './effects';
import {
  serviceFrequencyOptions,
  getLawyerPanelOptions,
  getLawyerSubPanelOptions,
} from './functions';

const Spacer = () => <div />;

const LegalProblemCodeForm = (props) => {
  const { formData = {}, formOptions = {}, onChange } = props;
  const lpCodeMaskRef = useRef(null);
  const {
    code = '',
    name = '',
    serviceFrequency = '',
    staffCloseOrVoid = '',
    staffCloseOrVoidIn = '',
    nonStaffCloseOrVoid = '',
    nonStaffCloseOrVoidIn = '',
    staffPrePopCheck = false,
    staffMaxPrepaidHoursCheck = false,
    staffMaxPrepaidHours = '',
    staffMaxPrepaidAmountCheck = false,
    staffMaxPrepaidAmount = '',
    staffPrepaidRate = '',
    staffParticipantRate = '',
    nonStaffPrePopCheck = false,
    nonStaffMaxPrepaidHoursCheck = false,
    nonStaffMaxPrepaidHours = '',
    nonStaffMaxPrepaidAmountCheck = false,
    nonStaffMaxPrepaidAmount = '',
    nonStaffPrepaidRate = '',
    nonStaffParticipantRate = '',
    actualRate = '',
    forecast = '',
    csqThreshold = '',
    serviceProvided = '',
    benefitType = '',
    lawPanel = 0,
    lawSubPanel = 0,
  } = formData;

  const {
    serviceProvided: serviceProvidedOptions = [],
    benefitTypes: benefitTypesOptions = [],
    lawyerPanels = [],
  } = formOptions;

  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { invalidFields = [] } = validate(
    formData,
    legalProblemCodeRequiredFields
  );

  useEffect(createLpCodeMaskEffect({ lpCodeMaskRef }), []);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const integerInputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onNumericInputBlurEffect({ touch, onChange }),
  };

  const currencyInputEventHandlers = {
    onChange: onInputChangeEffect({ touch, onChange }),
    onBlur: onNumericInputBlurEffect({
      touch,
      onChange,
      toFixed: true,
      precision: 2,
    }),
  };

  const closeOrVoidOptions = (
    <Fragment>
      <option value="" disabled>
        {t('common.defaultSelectPlaceholder')}
      </option>
      <option value="V">{t('components.LegalProblemCodeForm.void')}</option>
      <option value="C">{t('components.LegalProblemCodeForm.close')}</option>
    </Fragment>
  );

  return (
    <Card className="legal-problem-code-form">
      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.codeValidation'
              )}
              invalid={isTouched('code') && invalidFields.includes('code')}
            >
              <InputLabel content={t('components.LegalProblemCodeForm.code')}>
                <input
                  type="text"
                  name="code"
                  value={code}
                  onChange={onLpCodeInputChangeEffect({
                    lpCodeMaskRef,
                    onChange,
                  })}
                  onBlur={onInputBlurEffect({ touch })}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.nameValidation'
              )}
              invalid={isTouched('name') && invalidFields.includes('name')}
            >
              <InputLabel content={t('components.LegalProblemCodeForm.name')}>
                <input
                  type="text"
                  name="name"
                  value={name}
                  {...inputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
        <div className="form-column" />
      </div>

      <div className="form-row print-area">
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.serviceProvidedValidation'
              )}
              invalid={
                isTouched('serviceProvided') &&
                invalidFields.includes('serviceProvided')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.serviceProvided')}
              >
                <StyledSelect
                  name="serviceProvided"
                  value={serviceProvided}
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t('common.defaultSelectPlaceholder')}
                  </option>
                  {serviceProvidedOptions.map(
                    ({ servicesProvidedCode, servicesProvidedDesc }) => (
                      <option
                        key={servicesProvidedCode}
                        value={servicesProvidedCode}
                      >
                        {`${servicesProvidedCode} \u2013 ${servicesProvidedDesc}`}
                      </option>
                    )
                  )}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
          </div>
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.serviceFrequencyValidation'
              )}
              invalid={
                isTouched('serviceFrequency') &&
                invalidFields.includes('serviceFrequency')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.serviceFrequency')}
              >
                <StyledSelect
                  name="serviceFrequency"
                  value={serviceFrequency}
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t('common.defaultSelectPlaceholder')}
                  </option>
                  {serviceFrequencyOptions(t)}
                </StyledSelect>
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.benefitTypeValidation'
              )}
              invalid={
                isTouched('benefitType') &&
                invalidFields.includes('benefitType')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.benefitType')}
              >
                <StyledSelect
                  name="benefitType"
                  value={benefitType}
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t('common.defaultSelectPlaceholder')}
                  </option>
                  {benefitTypesOptions.map(({ benefitCode, benefitDesc }) => (
                    <option key={benefitCode} value={benefitCode}>
                      {benefitDesc}
                    </option>
                  ))}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
          </div>
        </div>
        <div className="form-column">
          <div className="staff-close-or-void-input-group">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.staffCloseOrVoidValidation'
              )}
              invalid={
                isTouched('staffCloseOrVoid') &&
                invalidFields.includes('staffCloseOrVoid')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.staffCloseOrVoid')}
              >
                <StyledSelect
                  name="staffCloseOrVoid"
                  value={staffCloseOrVoid}
                  {...inputEventHandlers}
                >
                  {closeOrVoidOptions}
                </StyledSelect>
              </InputLabel>
            </InputHelp>

            <div className="staff-close-or-void-input-group-text">
              {t('components.LegalProblemCodeForm.in')}
            </div>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.staffCloseOrVoidInValidation'
              )}
              invalid={
                isTouched('staffCloseOrVoidIn') &&
                invalidFields.includes('staffCloseOrVoidIn')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.days')}
                contentAfterChildren
              >
                <input
                  type="text"
                  name="staffCloseOrVoidIn"
                  value={staffCloseOrVoidIn}
                  {...integerInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="staff-close-or-void-input-group">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.nonStaffCloseOrVoidValidation'
              )}
              invalid={
                isTouched('nonStaffCloseOrVoid') &&
                invalidFields.includes('nonStaffCloseOrVoid')
              }
            >
              <InputLabel
                content={t(
                  'components.LegalProblemCodeForm.nonStaffCloseOrVoid'
                )}
              >
                <StyledSelect
                  name="nonStaffCloseOrVoid"
                  value={nonStaffCloseOrVoid}
                  {...inputEventHandlers}
                >
                  {closeOrVoidOptions}
                </StyledSelect>
              </InputLabel>
            </InputHelp>

            <div className="staff-close-or-void-input-group-text">
              {t('components.LegalProblemCodeForm.in')}
            </div>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.nonStaffCloseOrVoidInValidation'
              )}
              invalid={
                isTouched('nonStaffCloseOrVoidIn') &&
                invalidFields.includes('nonStaffCloseOrVoidIn')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.days')}
                contentAfterChildren
              >
                <input
                  type="text"
                  name="nonStaffCloseOrVoidIn"
                  value={nonStaffCloseOrVoidIn}
                  {...integerInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.actualRateValidation'
              )}
              invalid={
                isTouched('actualRate') && invalidFields.includes('actualRate')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.actualRate')}
              >
                <input
                  type="text"
                  name="actualRate"
                  value={actualRate}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.forecastValidation'
              )}
              invalid={
                isTouched('forecast') && invalidFields.includes('forecast')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.forecast')}
              >
                <input
                  type="text"
                  name="forecast"
                  value={forecast}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.csqThresholdValidation'
              )}
              invalid={
                isTouched('csqThreshold') &&
                invalidFields.includes('csqThreshold')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.csqThreshold')}
              >
                <input
                  type="text"
                  name="csqThreshold"
                  value={csqThreshold}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
        <div className="form-column" />
      </div>

      <div className="form-row">
        <div className="form-column">
          <div className="form-row">
            <h3>{t('components.LegalProblemCodeForm.staff')}</h3>
            <Checkbox
              label={t('components.LegalProblemCodeForm.prepopulateOnCase')}
              name="staffPrePopCheck"
              checked={staffPrePopCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />
          </div>

          <div className="form-row">
            <Checkbox
              name="staffMaxPrepaidHoursCheck"
              checked={staffMaxPrepaidHoursCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.maxPrepaidHoursValidation'
              )}
              invalid={
                isTouched('staffMaxPrepaidHours') &&
                invalidFields.includes('staffMaxPrepaidHours')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.maxPrepaidHours')}
              >
                <input
                  type="text"
                  name="staffMaxPrepaidHours"
                  value={staffMaxPrepaidHours}
                  disabled={!staffMaxPrepaidHoursCheck}
                  {...integerInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <Checkbox
              name="staffMaxPrepaidAmountCheck"
              checked={staffMaxPrepaidAmountCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.maxPrepaidAmountValidation'
              )}
              invalid={
                isTouched('staffMaxPrepaidAmount') &&
                invalidFields.includes('staffMaxPrepaidAmount')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.maxPrepaidAmount')}
              >
                <input
                  type="text"
                  name="staffMaxPrepaidAmount"
                  value={staffMaxPrepaidAmount}
                  disabled={!staffMaxPrepaidAmountCheck}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.prepaidRateValidation'
              )}
              invalid={
                isTouched('staffPrepaidRate') &&
                invalidFields.includes('staffPrepaidRate')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.prepaidRate')}
              >
                <input
                  type="text"
                  name="staffPrepaidRate"
                  value={staffPrepaidRate}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.participantRateValidation'
              )}
              invalid={
                isTouched('staffParticipantRate') &&
                invalidFields.includes('staffParticipantRate')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.participantRate')}
              >
                <input
                  type="text"
                  name="staffParticipantRate"
                  value={staffParticipantRate}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>

        <div className="form-column">
          <div className="form-row">
            <h3>{t('components.LegalProblemCodeForm.nonStaff')}</h3>
            <Checkbox
              label={t('components.LegalProblemCodeForm.prepopulateOnCase')}
              name="nonStaffPrePopCheck"
              checked={nonStaffPrePopCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />
          </div>

          <div className="form-row">
            <Checkbox
              name="nonStaffMaxPrepaidHoursCheck"
              checked={nonStaffMaxPrepaidHoursCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />

            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.maxPrepaidHoursValidation'
              )}
              invalid={
                isTouched('nonStaffMaxPrepaidHours') &&
                invalidFields.includes('nonStaffMaxPrepaidHours')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.maxPrepaidHours')}
              >
                <input
                  type="text"
                  name="nonStaffMaxPrepaidHours"
                  value={nonStaffMaxPrepaidHours}
                  disabled={!nonStaffMaxPrepaidHoursCheck}
                  {...integerInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <Checkbox
              name="nonStaffMaxPrepaidAmountCheck"
              checked={nonStaffMaxPrepaidAmountCheck}
              onChange={onCheckboxChangeEffect({ onChange })}
            />
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.maxPrepaidAmountValidation'
              )}
              invalid={
                isTouched('nonStaffMaxPrepaidAmount') &&
                invalidFields.includes('nonStaffMaxPrepaidAmount')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.maxPrepaidAmount')}
              >
                <input
                  type="text"
                  name="nonStaffMaxPrepaidAmount"
                  value={nonStaffMaxPrepaidAmount}
                  disabled={!nonStaffMaxPrepaidAmountCheck}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.prepaidRateValidation'
              )}
              invalid={
                isTouched('nonStaffPrepaidRate') &&
                invalidFields.includes('nonStaffPrepaidRate')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.prepaidRate')}
              >
                <input
                  type="text"
                  name="nonStaffPrepaidRate"
                  value={nonStaffPrepaidRate}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>

          <div className="form-row">
            <InputHelp
              validationContent={t(
                'components.LegalProblemCodeForm.participantRateValidation'
              )}
              invalid={
                isTouched('nonStaffParticipantRate') &&
                invalidFields.includes('nonStaffParticipantRate')
              }
            >
              <InputLabel
                content={t('components.LegalProblemCodeForm.participantRate')}
              >
                <input
                  type="text"
                  name="nonStaffParticipantRate"
                  value={nonStaffParticipantRate}
                  {...currencyInputEventHandlers}
                />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
      </div>

      {/* Empty form row and column needed to apply a bottom border with a controlled width: */}
      <div className="form-row">
        <div className="form-column" />
      </div>

      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LegalProblemCodeForm.lawPanelValidation'
          )}
          invalid={isTouched('lawPanel') && invalidFields.includes('lawPanel')}
        >
          <InputLabel content={t('components.LegalProblemCodeForm.lawPanel')}>
            <StyledSelect
              name="lawPanel"
              value={lawPanel}
              {...inputEventHandlers}
            >
              <option value="0" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {getLawyerPanelOptions(t, lawyerPanels)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LegalProblemCodeForm.lawSubPanelValidation'
          )}
          invalid={
            isTouched('lawSubPanel') && invalidFields.includes('lawSubPanel')
          }
        >
          <InputLabel
            content={t('components.LegalProblemCodeForm.lawSubPanel')}
          >
            <StyledSelect
              name="lawSubPanel"
              value={lawSubPanel}
              {...inputEventHandlers}
            >
              <option value="0" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {getLawyerSubPanelOptions(t, lawyerPanels, lawPanel)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

LegalProblemCodeForm.propTypes = {
  formData: PropTypes.object.isRequired,
  formOptions: PropTypes.shape({
    serviceProvided: PropTypes.arrayOf(
      PropTypes.shape({
        servicesProvidedCode: PropTypes.string.isRequired,
        servicesProvidedDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
    benefitTypes: PropTypes.arrayOf(
      PropTypes.shape({
        benefitCode: PropTypes.string.isRequired,
        benefitDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
    lawyerPanels: PropTypes.arrayOf(
      PropTypes.shape({
        panId: PropTypes.number.isRequired,
        panKey: PropTypes.string.isRequired,
        subPanels: PropTypes.arrayOf(
          PropTypes.shape({
            panId: PropTypes.number.isRequired,
            subpanId: PropTypes.number.isRequired,
            subpanKey: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};

export default LegalProblemCodeForm;
