import IMask from 'imask';
import { dateMaskPattern } from 'js/utilities/masks';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  DATE_INPUT_NAME,
  getDefaultDate,
  searchStringRemovingAutoVoidParam,
} from './functions';

export const unmountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => () => {
    executeAbortControllerRefs(abortControllerRefs);
    setActive(false);
  };
};

export const createDateMaskEffect = (options = {}) => {
  const { dateMaskRef } = options;
  return () => {
    dateMaskRef.current = IMask.createMask({
      mask: dateMaskPattern,
    });
  };
};

export const closeModalEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setDate,
    untouch,
    search,
    history,
  } = options;
  return () => {
    executeAbortControllerRefs(abortControllerRefs);
    setDate(getDefaultDate());
    untouch(DATE_INPUT_NAME);
    const nextSearch = searchStringRemovingAutoVoidParam(search);
    history.replace(nextSearch);
  };
};

export const onChangeDateEffect = (options = {}) => {
  const { dateMaskRef, setDate } = options;
  return (name, value) => {
    const { current: mask } = dateMaskRef;
    if (mask) {
      setDate(mask.resolve(value));
    }
  };
};

export const onBlurDateEffect = (options = {}) => {
  const { touch } = options;
  return () => touch(DATE_INPUT_NAME);
};

export const onResetDateEffect = (options = {}) => {
  const { setDate, untouch } = options;
  return () => {
    setDate('');
    untouch(DATE_INPUT_NAME);
  };
};

export const onConfirmDateEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    validateAll,
    touchAll,
    date,
    setActive,
    onClose,
    abortControllerRef,
  } = options;
  return async () => {
    const { isValid } = validateAll({ date });
    if (!isValid) {
      touchAll();
      return;
    }

    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const body = {
        dryrun: false,
        countOnly: true,
        date: date.replaceAll('/', '-'),
      };

      await api.postJson(
        '/Procedure/acv',
        { body, signal },
        {
          success: {
            context: {
              message: (result) => {
                // Format the success message with the results counts:
                const { json = {} } = result;
                const { voided = {}, closed = {} } = json;
                const { count: voidedCount = 0 } = voided;
                const { count: closedCount = 0 } = closed;

                const voidedKey =
                  voidedCount === 1
                    ? 'postRequestSuccessSingluar'
                    : 'postRequestSuccessPlural';

                const voidedMessage = t(
                  `components.AutoVoidModal.${voidedKey}`,
                  {
                    count: voidedCount,
                    action: t('components.AutoVoidModal.voided'),
                  }
                );

                const closedKey =
                  closedCount === 1
                    ? 'postRequestSuccessSingluar'
                    : 'postRequestSuccessPlural';

                const closedMessage = t(
                  `components.AutoVoidModal.${closedKey}`,
                  {
                    count: closedCount,
                    action: t('components.AutoVoidModal.closed'),
                  }
                );

                const message = `${voidedMessage} ${closedMessage}`;

                return message;
              },
            },
          },
          error: {
            context: {
              message: t('components.AutoVoidModal.postRequestError'),
            },
          },
        }
      );

      // Clear all cases from the local cache:
      cache.deleteMatchingPattern(/^\/Case\//);

      setActive(false);
      onClose();
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};
