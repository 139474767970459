import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IndentedCard, Skeleton } from 'js/components/design-system';

const LawFirmSummarySkeleton = (props) => {
  const { className, animated } = props;
  return (
    <Skeleton
      className={classnames('law-firm-summary-skeleton', className)}
      animated={animated}
    >
      <IndentedCard>
        <div className="skeleton-row">
          <div className="skeleton-column">
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column" />
        </div>
        <div className="skeleton-row">
          <div className="skeleton-column">
            <div className="skeleton-bone" />
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column">
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column">
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column">
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column" />
        </div>
      </IndentedCard>
    </Skeleton>
  );
};

LawFirmSummarySkeleton.defaultProps = {
  animated: false,
};

LawFirmSummarySkeleton.propTypes = {
  className: PropTypes.string,
  animated: PropTypes.bool,
};

export default LawFirmSummarySkeleton;
