export const userGroups = {
  administrator: 1,
  dataEntry: 2,
  intakeSpecialist: 3,
  viewOnly: 4,
  feedbackSpecialist: 8,
  support: 9,
};

export const userGroupsMatrix = {
  [userGroups.administrator]: Object.values(userGroups),
  [userGroups.feedbackSpecialist]: [
    userGroups.feedbackSpecialist,
    userGroups.viewOnly,
  ],
  [userGroups.dataEntry]: [userGroups.dataEntry, userGroups.viewOnly],
  [userGroups.intakeSpecialist]: [
    userGroups.intakeSpecialist,
    userGroups.viewOnly,
  ],
  [userGroups.support]: [userGroups.support, userGroups.viewOnly],
  [userGroups.viewOnly]: [userGroups.viewOnly],
};

export const impliedGroupsForUserAccessLevel = (accessLevel) => {
  return userGroupsMatrix[accessLevel] || [];
};

export const matchAnyGroup = (groups = [], permittedGroups = []) => {
  return permittedGroups.filter((group) => groups.includes(group)).length > 0;
};
