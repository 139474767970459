import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IndentedCard, Skeleton } from 'js/components/design-system';
import { clientSummaryMode } from './functions';

const ClientSummarySkeleton = (props) => {
  const { className, animated, mode } = props;
  return (
    <Skeleton
      className={classnames('client-summary-skeleton', className)}
      animated={animated}
    >
      <IndentedCard>
        <div className="skeleton-row">
          <div className="skeleton-column">
            <div className="skeleton-bone" />
          </div>
          <div className="skeleton-column" />
        </div>
        <div className="skeleton-row">
          <div className="skeleton-column">
            <div className="skeleton-bone" />
            {mode === clientSummaryMode.full && (
              <Fragment>
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
                <div className="skeleton-break" />
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
              </Fragment>
            )}
          </div>
          <div className="skeleton-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
              </Fragment>
            )}
          </div>
          <div className="skeleton-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
                <div className="skeleton-break" />
                <div className="skeleton-bone" />
              </Fragment>
            )}
          </div>
          <div className="skeleton-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                <div className="skeleton-bone" />
                <div className="skeleton-bone" />
              </Fragment>
            )}
          </div>
          <div className="skeleton-column" />
        </div>
      </IndentedCard>
    </Skeleton>
  );
};

ClientSummarySkeleton.defaultProps = {
  animated: false,
  mode: clientSummaryMode.full,
};

ClientSummarySkeleton.propTypes = {
  className: PropTypes.string,
  animated: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(clientSummaryMode)),
};

export default ClientSummarySkeleton;
