import { rotateAbortControllerRef } from 'js/components/fetch';
import { REAL_ESTATE_LP_CODES } from 'js/utilities/legal-problem-codes';

export const getSaveButtonParams = (options = {}) => {
  const {
    t,
    active,
    isNew,
    isCopy,
    postEffect,
    patchEffect,
    copyEffect,
  } = options;
  if (isNew) {
    return {
      saveEffect: postEffect,
      buttonText: active ? t('common.creating') : t('common.create'),
    };
  } else if (isCopy) {
    return {
      saveEffect: copyEffect,
      buttonText: active
        ? t('common.creating')
        : t('components.CaseInformation.copy'),
    };
  } else {
    return {
      saveEffect: patchEffect,
      buttonText: active ? t('common.saving') : t('common.save'),
    };
  }
};

export const CASE_POST_ERROR_CODES = [1, 2, 3, 4, 5, 6];

export const resolveCasePostErrorMessage = (t, error) => {
  const { body = {} } = error;
  const { error_code: errorCode } = body;

  if (CASE_POST_ERROR_CODES.includes(parseInt(errorCode))) {
    return t(`components.CaseInformation.postRequestError.${errorCode}`);
  } else {
    return t('components.CaseInformation.requestError');
  }
};

export const performChecksForDuplicateCases = async (options = {}) => {
  const {
    t,
    api,
    prompt,
    clientId,
    clientCode,
    caseInformation,
    postCaseAbortControllerRef,
  } = options;

  const { legalProblemCode } = caseInformation;
  let { reTransferDate } = caseInformation;
  let allowDuplicateLpCode = false;

  if (REAL_ESTATE_LP_CODES.includes(legalProblemCode)) {
    // Check for recent cases with LP code 021 or 022. These are real estate transactions.
    // Only one may be active for each LP code at a time and a new one should only be allowed
    // if the transfer date on the previous case was more than two years in the past.
    // There is an exception made in cases of marriage or divorce, in which case the transfer
    // date is set to 3000/01/01 as a signal that the exception criteria was met.

    rotateAbortControllerRef(postCaseAbortControllerRef);
    const { signal: reActivitySignal } = postCaseAbortControllerRef.current;

    const { json: reActivityJson = {} } = await api.getJson(
      `/Client/${clientId}/cases/legalproblem/activity/${legalProblemCode}`,
      { signal: reActivitySignal },
      {
        success: { bypass: true },
        error: {
          context: {
            message: t('components.CaseInformation.reActivityRequestError'),
          },
        },
      }
    );

    // Prompt will throw if cancelled:

    await prompt(reActivityJson, 'case.realEstateActivity');
    // The user has confirmed the case.realEstateActivity prompt.
    // Allow the duplicate LP code and set the transfer date to 3000/01/01.
    if (reActivityJson.hasRecentCase || reActivityJson.hasActiveCase) {
      allowDuplicateLpCode = true;
      reTransferDate = new Date('3000-01-01');
    }
  } else {
    // For all other (non-real estate) cases, alert the user when there
    // is already an active case for the same client and LP code.

    rotateAbortControllerRef(postCaseAbortControllerRef);
    const { signal: caseExistsSignal } = postCaseAbortControllerRef.current;

    const {
      json: { exists: caseExists },
    } = await api.getJson(
      `/Client/${clientId}/${clientCode}/cases/legalproblem/${legalProblemCode}/exists`,
      { signal: caseExistsSignal },
      {
        success: {
          bypass: true,
        },
        error: {
          context: {
            message: t('components.CaseInformation.caseExistsRequestError'),
          },
        },
      }
    );

    await prompt({ caseExists }, 'case.caseExists');
    // The user has confirmed the case.caseExists prompt.
    // Allow the duplicate LP code if another case exists.
    if (caseExists) {
      allowDuplicateLpCode = true;
    }
  }

  return {
    caseInformation: { ...caseInformation, reTransferDate },
    allowDuplicateLpCode,
  };
};
