import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card } from '../cards';
import ActivityIndicator from './activity-indicator';

const ActivityIndicatorCard = (props) => {
  const { t } = useTranslation();
  const {
    className,
    message = t('components.ActivityIndicatorCard.defaultMessage'),
    activityIndicatorProps = {},
  } = props;
  return (
    <Card className={classnames('activity-indicator-card', className)}>
      <ActivityIndicator {...activityIndicatorProps} />
      {message && <h2>{message}</h2>}
    </Card>
  );
};

ActivityIndicatorCard.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  activityIndicatorProps: PropTypes.shape(ActivityIndicator.propTypes),
};

export default ActivityIndicatorCard;
