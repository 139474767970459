import React from 'react';
import PropTypes from 'prop-types';
import { Route as BaseRoute, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { routePaths } from './route-paths';
import { MSALRenderController } from 'js/components/msal';
import { CurrentUserRenderController } from 'js/components/current-user';
import AuthPlaceholder from 'js/components/auth-placeholder';

const Route = Sentry.withSentryRouting(BaseRoute);

const ProtectedRoute = (props) => {
  const { redirectTo, ...routeProps } = props;
  const { t } = useTranslation();

  const renderPlaceholder = () => (
    <AuthPlaceholder>
      <button className="button button-highlight" disabled>
        {t('components.Login.loggingIn')}
      </button>
    </AuthPlaceholder>
  );

  return (
    <MSALRenderController
      renderWhenAuthenticated={() => (
        <CurrentUserRenderController
          renderOnActive={() => <Route {...routeProps} />}
          renderOnInactive={() => {
            // logout is a protected route so we must render it normally.
            // The comparison is based on window.location rather than the
            // route match because the route information is not available
            // in this context.
            const { pathname } = window.location;
            if (pathname === routePaths.logout) {
              return <Route {...routeProps} />;
            } else {
              return <Redirect to={routePaths.logout} />;
            }
          }}
          renderOnError={() => <Redirect to={routePaths.unknownUser} />}
          renderUntilLoaded={renderPlaceholder}
        />
      )}
      renderWhenUnauthenticated={() => <Redirect to={redirectTo} />}
      renderUntilReady={renderPlaceholder}
    />
  );
};

ProtectedRoute.defaultProps = {
  redirectTo: routePaths.login,
};

ProtectedRoute.propTypes = {
  ...Route.propTypes,
  redirectTo: PropTypes.string,
};

export default ProtectedRoute;
