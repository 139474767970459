import React, { useState } from 'react';
import { faSync, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../components/icons';
import { Card } from '../components/cards';
import { FAB, FABPosition, FABOffset } from '../components/fab';
import { Toggle } from '../components/toggle';

const Buttons = () => {
  const [toggleIsOn, setToggleIsOn] = useState(false);
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Buttons</h1>

        <Card>
          <h2>Button Bar</h2>
          <p>
            Buttons are displayed in a row and sized according to their
            intrinsic width.
          </p>

          <div className="button-bar">
            <button className="button">Default</button>
            <button className="button" disabled>
              Default - Disabled
            </button>
            <button className="button button-highlight">Highlighted</button>
            <button className="button button-highlight" disabled>
              Highlighted - Disabled
            </button>
          </div>
        </Card>

        <Card>
          <h2>Button Stack</h2>
          <p>
            Buttons are displayed in a column and occupy the full width of the
            container.
          </p>

          <div className="button-stack">
            <button className="button">Default</button>
            <button className="button" disabled>
              Default - Disabled
            </button>
            <button className="button button-highlight">Highlighted</button>
            <button className="button button-highlight" disabled>
              Highlighted - Disabled
            </button>
          </div>
        </Card>

        <Card>
          <h2>Link Appearance</h2>
          <button className="button-link-appearance">Text Only</button>
          <button className="button-link-appearance">
            <FontAwesomeIcon icon={faSync} /> Icon & Text
          </button>
          <button className="button-link-appearance" disabled>
            Text Only - Disabled
          </button>
          <button className="button-link-appearance" disabled>
            <FontAwesomeIcon icon={faSync} /> Icon & Text - Disabled
          </button>
        </Card>

        <Card>
          <h2>Toggle</h2>
          <Toggle
            onLabel="Activate"
            onLabelActive="Active"
            offLabel="Deactivate"
            offLabelActive="Inactive"
            name="status"
            isOn={toggleIsOn}
            onChange={(e, name, isOn) => setToggleIsOn(isOn)}
          />
        </Card>

        <Card>
          <h2>Toggle - Disabled</h2>
          <Toggle
            onLabel="Activate"
            onLabelActive="Active"
            offLabel="Deactivate"
            offLabelActive="Inactive"
            name="status"
            isOn={toggleIsOn}
            onChange={(e, name, isOn) => setToggleIsOn(isOn)}
            disabled
          />
        </Card>

        <FABPosition>
          <FAB
            actions={[
              {
                icon: <FontAwesomeIcon icon={faFile} />,
                title: 'Single Action',
                onClick: () => {},
              },
            ]}
          />
          <FAB
            actions={[
              {
                icon: <FontAwesomeIcon icon={faFile} />,
                title: 'Multiple Actions',
              },
              {
                icon: <FontAwesomeIcon icon={faFile} />,
                title: 'Action 1',
                onClick: () => {},
              },
              {
                icon: <FontAwesomeIcon icon={faFile} />,
                title: 'Action 2',
                onClick: () => {},
              },
              {
                icon: <FontAwesomeIcon icon={faFile} />,
                title: 'Action 3',
                onClick: () => {},
              },
            ]}
          />
        </FABPosition>
        <FABOffset />
      </div>
    </div>
  );
};

export default Buttons;
