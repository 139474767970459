import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanLawyerFeedback } from 'js/utilities/lawyers';
import { searchStringFromParams } from 'js/utilities/params';
import { sortLawyerFeedback } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingLawyerFeedback } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingLawyerFeedback(false);
    };
  };
};

export const getLawyerFeedbackEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    query,
    lawyerId,
    setLoadingLawyerFeedback,
    setLawyerFeedback,
    getLawyerFeedbackAbortControllerRef,
    hasAccessToFeedback,
  } = options;

  return async (ignoreCache = false) => {
    if (!lawyerId || !hasAccessToFeedback) {
      return;
    }

    const url = `/Lawyer/${lawyerId}/FeedBack?v=2`;
    const record = cache.get(url);
    if (!record || ignoreCache) {
      setLoadingLawyerFeedback(true);

      rotateAbortControllerRef(getLawyerFeedbackAbortControllerRef);
      const { signal } = getLawyerFeedbackAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          url,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.LawyerFeedback.getRequestError'),
              },
            },
          }
        );

        const feedback = cleanLawyerFeedback(json);

        let sortedFeedback = [...feedback];
        if (query) {
          sortedFeedback = sortLawyerFeedback(feedback, query);
        }

        cache.set(url, feedback);
        setLawyerFeedback(sortedFeedback);
        setLoadingLawyerFeedback(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingLawyerFeedback(false);
        }
      }
    } else {
      const { value: feedback } = record;

      let sortedFeedback = [...feedback];
      if (query) {
        sortedFeedback = sortLawyerFeedback(feedback, query);
      }

      setLawyerFeedback(sortedFeedback);
    }
  };
};

export const getLawyerFeedbackDocumentEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    openDocumentUrl,
    getLawyerFeedbackDocumentAbortControllerRef,
  } = options;

  return async ({ docId }) => {
    if (!lawyerId && !docId) {
      return;
    }

    const url = `/Lawyer/${lawyerId}/FeedBack/Document/${docId}`;

    rotateAbortControllerRef(getLawyerFeedbackDocumentAbortControllerRef);
    const { signal } = getLawyerFeedbackDocumentAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerFeedback.getDocumentRequestError'),
            },
          },
        }
      );

      const { documentUri } = json;
      if (documentUri) {
        openDocumentUrl(documentUri);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        throw error;
      }
    }
  };
};

export const sortLawyerFeedbackEffect = (options = {}) => {
  const { query, setLawyerFeedback } = options;
  return () => {
    setLawyerFeedback((feedback) => sortLawyerFeedback(feedback, query));
  };
};

export const onChangeSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, orderBy: field, order: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const onDismissAddLawyerModalEffect = (options = {}) => {
  const { setAddEditFeedbackModalVisible, getLawyerFeedback } = options;
  return (needsRefresh = false) => {
    setAddEditFeedbackModalVisible(false);
    if (needsRefresh) {
      getLawyerFeedback(true);
    }
  };
};

export const onDismissDeleteLawyerModalEffect = (options = {}) => {
  const { setFeedbackIdPendingDeletion, getLawyerFeedback } = options;
  return (needsRefresh = false) => {
    setFeedbackIdPendingDeletion(0);
    if (needsRefresh) {
      getLawyerFeedback(true);
    }
  };
};

export const onDismissEditLawyerModalEffect = (options = {}) => {
  const {
    getLawyerFeedback,
    setEditingFeedback,
    setIsEditing,
    setAddEditFeedbackModalVisible,
  } = options;
  return () => {
    getLawyerFeedback(true);
    setEditingFeedback({});
    setIsEditing(false);
    setAddEditFeedbackModalVisible(false);
  };
};

export const setEditingFeedbackEffect = (options = {}) => {
  const {
    setEditingFeedback,
    setAddEditFeedbackModalVisible,
    setIsEditing,
  } = options;
  return (feedbackId) => {
    setEditingFeedback(feedbackId);
    setAddEditFeedbackModalVisible(true);
    setIsEditing(true);
  };
};
