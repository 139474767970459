import { isEmpty } from 'js/utilities/validation';
import { searchStringFromParams } from 'js/utilities/params';
import {
  cleanCompanies,
  cleanCompany,
  cleanCompanyFeeSchedule,
} from 'js/utilities/companies';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const onListMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const onDetailsMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setInitialActive, setSaveActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setInitialActive(false);
      setSaveActive(false);
    };
  };
};

export const missingFeeScheduleBannerEffect = (options = {}) => {
  const { t, presentStyledBanner, dismissBanner, feeSchedule } = options;
  return () => {
    let bannerId = '';

    if (!isEmpty(feeSchedule) && isEmpty(feeSchedule.scheduleCode)) {
      bannerId = presentStyledBanner('warning', {
        className: 'fee-schedule-balance-banner',
        timeout: 0,
        progress: false,
        animated: false,
        content: t('components.CompanyDetailsPage.missingFeeScheduleMessage'),
      });
    }

    return () => {
      if (bannerId) {
        dismissBanner(bannerId, false);
      }
    };
  };
};

export const companiesListRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setCompanies,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }
    const endpoint = `/Admin/Companies/${query.search}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.CompaniesListPage.searchError') },
          },
        }
      );
      const { companiesInfo = [], totalCount = 0 } = json;
      setCompanies(cleanCompanies(companiesInfo));
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const companyDetailsRequestEffect = (options = {}) => {
  const {
    t,
    api,
    companyCode,
    setInitialActive,
    setInitialLoadComplete,
    setCompany,
    setFeeSchedule,
    abortControllerRef,
    isNew,
  } = options;
  return async () => {
    if (isNew) {
      setCompany(cleanCompany({}));
      setInitialActive(false);
      return;
    }
    const endpoint = `/Admin/Companies/${companyCode}`;
    setInitialActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CompanyDetailsPage.requestError'),
            },
          },
        }
      );

      const { details = {}, feeSchedule = {} } = json;
      setCompany(cleanCompany(details));
      setFeeSchedule(cleanCompanyFeeSchedule(feeSchedule));
      setInitialActive(false);
      setInitialLoadComplete(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setInitialActive(false);
      }
    }
  };
};

export const previousCompaniesListResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextCompaniesListResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const companiesListResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const companiesListResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, SortBy: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const companiesListSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const companiesListSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

export const updateCompanyEffect = (options = {}) => {
  const { setCompany } = options;
  return (name, value) =>
    setCompany((company) => ({ ...company, [name]: value }));
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onNumberInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      if (!isNaN(value)) {
        onChange(name, value);
      }
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange, touch } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
      touch(name);
    }
  };
};

export const patchCompanyEffect = (options = {}) => {
  const {
    t,
    api,
    company,
    isValid,
    setSaveActive,
    touchAllFields,
    resetInteractionCount,
  } = options;

  return async () => {
    const { companyCode } = company;

    if (!isValid || !companyCode) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setSaveActive(true);
    try {
      await api.patchJson(
        `/Admin/Companies/${companyCode}`,
        { body: company },
        {
          success: {
            context: {
              message: t('components.CompanyDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CompanyDetailsPage.requestPatchError'),
            },
          },
        }
      );
      resetInteractionCount();
      setSaveActive(false);
    } catch (error) {
      setSaveActive(false);
      throw error;
    }
  };
};

export const postCompanyEffect = (options = {}) => {
  const {
    t,
    api,
    company,
    isValid,
    setSaveActive,
    setRedirectPath,
    touchAllFields,
    resetInteractionCount,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setSaveActive(true);

    try {
      const response = await api.postJson(
        `/Admin/Companies`,
        { body: company },
        {
          success: {
            context: {
              message: t('components.CompanyDetailsPage.requestPostSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CompanyDetailsPage.requestPostError'),
            },
          },
        }
      );

      const { companyCode } = response.json;
      const path = routePathReplacingParams(routePaths.company, {
        companyCode,
      });

      resetInteractionCount();
      setSaveActive(false);
      setRedirectPath(path);
    } catch (error) {
      setSaveActive(false);
      throw error;
    }
  };
};

export const saveCompanyEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const patchActiveCompanyEffect = (options = {}) => {
  const {
    t,
    api,
    setSaveActive,
    companyCode,
    companyInactive,
    company,
    setCompany,
    showModal,
  } = options;

  return async () => {
    const payload = { coInact: companyInactive };

    setSaveActive(true);
    try {
      await api.patchJson(
        `/Admin/Companies/${companyCode}`,
        { body: payload },
        {
          success: {
            context: {
              message: t('components.CompanyDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CompanyDetailsPage.requestPatchError'),
            },
          },
        }
      );

      const nextCompany = { ...company, coInact: companyInactive };
      setCompany(nextCompany);
      setSaveActive(false);
      showModal(false);
    } catch (error) {
      setSaveActive(false);
    }
  };
};

export const companiesListPrintRequestEffect = ({ api, query }) => {
  return async () => {
    const { json = {} } = await api.getJson(
      `/Admin/Companies${query.search}`,
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );
    const { companiesInfo = [] } = json;
    const activeCompanies = companiesInfo.filter((item) => !item.coInact);
    return cleanCompanies(activeCompanies);
  };
};
