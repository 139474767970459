import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from 'js/components/menus/sort-menu';
import { ReportsFoldersSortOptions } from './functions';

const ReportsFoldersSortMenu = (props) => (
  <SortMenu
    className="reports-folders-sort-menu"
    sortOptions={ReportsFoldersSortOptions}
    {...props}
  />
);

ReportsFoldersSortMenu.defaultProps = {
  field: 'folderName',
  direction: 'asc',
};

ReportsFoldersSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ReportsFoldersSortMenu;
