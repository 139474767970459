import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ChequeRunFailedChequeItem = (props) => {
  const { t } = useTranslation();
  const { cheque = {}, reason = '' } = props;
  const { chq_count: count } = cheque;
  return (
    <li>
      {t('components.ChequeRunResults.failedCheques.item', { count, reason })}
    </li>
  );
};

ChequeRunFailedChequeItem.propTypes = {
  cheque: PropTypes.object.isRequired,
  reason: PropTypes.string.isRequired,
};

export default ChequeRunFailedChequeItem;
