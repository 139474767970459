import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MSALContext } from 'js/components/msal';
import { StyledBannerContext } from 'js/components/banner-styled';
import { FetchProvider, isAbortError } from 'js/components/fetch';
import { routePaths } from 'js/components/router/route-paths';
import { parseEmailFromErrorDescription } from 'js/utilities/errors';
import { error404RedirectForResponseUrl } from 'js/utilities/errors/response-404';
import {
  captureExceptionSilently,
  captureApiErrorSilently,
} from 'js/utilities/sentry';
import config from 'js/config';

const AppFetchProvider = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { acquireToken } = useContext(MSALContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  return (
    <FetchProvider
      resources={[
        {
          key: 'api',
          baseUrl: config.api.url,
          augmentFetchOptions: async (fetchOptions) => {
            try {
              const token = await acquireToken();
              if (!token) {
                return fetchOptions;
              }

              const { accessToken } = token;
              const { headers = {} } = fetchOptions;

              return {
                ...fetchOptions,
                headers: {
                  ...headers,
                  Authorization: `Bearer ${accessToken}`,
                },
              };
            } catch (error) {
              captureExceptionSilently(error);
              return fetchOptions;
            }
          },
          defaultSuccessCallback: async (result, ctx = {}) => {
            const {
              response: { statusText = '' },
            } = result;

            let content = '';

            // ctx.message may be a function or string.
            // It it's a function, call it with the result object.
            if (typeof ctx.message === 'function') {
              content = ctx.message(result);
            } else {
              content = ctx.message || statusText;
            }

            presentStyledBanner('success', { content });
          },
          defaultErrorCallback: async (error, ctx = {}) => {
            if (isAbortError(error)) {
              return;
            }

            if (error.status >= 500) {
              captureApiErrorSilently(error);
            }

            // Redirect to error pages in response to
            // common errors on GET requests:
            if (error.method === 'GET' && !ctx.preventRedirect) {
              if (error.status === 401) {
                history.replace(routePaths.login);
                return;
              }

              if (error.status === 403) {
                history.replace(routePaths.error403);
                return;
              }

              if (error.status === 404) {
                history.replace(error404RedirectForResponseUrl(error.url));
                return;
              }

              if (error.status >= 500) {
                history.replace(routePaths.error500);
                return;
              }
            }

            let content = '';

            // If the error status is undefined or zero, the request
            // failed due to a network failure. Display a specific
            // error message for that case.
            // If the error was due to the system being locked (423),
            // display a specific error message for that case.
            // Otherwise fall back to the context (ctx).
            // ctx.message may be a function or string.
            // It it's a function, call it with the error object.
            if (!error.status) {
              content = t('common.errors.network');
            } else if (error.status === 423) {
              const lockedByEmail = parseEmailFromErrorDescription(error);
              if (lockedByEmail) {
                content = t('common.errors.locked.identified', {
                  email: lockedByEmail,
                });
              } else {
                content = t('common.errors.locked.unidentified');
              }
            } else if (typeof ctx.message === 'function') {
              content = ctx.message(error);
            } else if (ctx.message) {
              content = `${ctx.message} (${error.status}: ${error.message})`;
            } else {
              content = `${error.status}: ${error.message}`;
            }

            presentStyledBanner('error', { content });
          },
        },
      ]}
    >
      {children}
    </FetchProvider>
  );
};

export default AppFetchProvider;
