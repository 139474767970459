import React, { Fragment, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  InputLabel,
  InputHelp,
  StyledSelect,
  DatePicker,
  FontAwesomeIcon,
} from 'js/components/design-system';
import {
  LawyerFeedbackMetadata,
  LawyerFeedbackMetadataSkeleton,
} from '../lawyer-feedback-metadata';
import { ValidationContext } from 'js/components/validation';
import { fileSizeLimitMB, validateFileSize } from 'js/utilities/files';
import { lawyerFeedbackRequiredFields } from 'js/utilities/lawyers';
import { isEmpty } from 'js/utilities/validation';
import {
  createCaseNumberMaskEffect,
  onChangeCaseNumberEffect,
  onCifKeyDownEffect,
  onEnterCifEffect,
  onConfirmCifEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onSelectFilesEffect,
  onDeselectFileEffect,
  onClickUploadFileEffect,
} from './effects';
import { formatDateFromString } from 'js/utilities/dates';

const Spacer = () => <div />;

const LawyerFeedbackForm = (props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const caseNumberMaskRef = useRef(null);
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const {
    formData = {},
    formOptions = {},
    onChange,
    isCaseMetadataActive,
    caseMetadata,
    getCaseMetadata,
    isFormDisabled,
  } = props;

  const {
    cif = '',
    sourceOfComment = '',
    typeOfComment = '',
    negativeReason = '',
    resolvedHow = '',
    resolvedBy = '',
    dateClosed = null,
    notes = '',
    feedBackDocuments = [],
  } = formData;

  const nextDateClosed =
    typeof dateClosed === 'string'
      ? formatDateFromString(dateClosed)
      : dateClosed;

  const {
    sourceOfCommentOptions = [],
    negativeReasonOptions = [],
    resolvedHowOptions = [],
    resolvedByOptions = [],
  } = formOptions;

  const { invalidFields = [] } = validate(
    formData,
    lawyerFeedbackRequiredFields
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  const renderSelectOptions = (options) =>
    options.map(({ value, title }) => (
      <option key={value} value={value}>
        {title}
      </option>
    ));

  const isPositive = typeOfComment.toString() === 'true';
  const showMetadata = !isEmpty(caseMetadata) && !isCaseMetadataActive;

  useEffect(createCaseNumberMaskEffect({ caseNumberMaskRef }), []);

  return (
    <form className="lawyer-feedback-form">
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.caseNumberValidation'
          )}
          invalid={isTouched('cif') && invalidFields.includes('cif')}
        >
          <InputLabel content={t('components.LawyerFeedbackForm.caseNumber')}>
            <input
              type="text"
              name="cif"
              value={cif}
              disabled={isCaseMetadataActive}
              onKeyDown={onCifKeyDownEffect({
                onEnter: onEnterCifEffect({ validate, getCaseMetadata }),
              })}
              onChange={onChangeCaseNumberEffect({
                caseNumberMaskRef,
                onChange,
              })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <button
          type="button"
          className="button button-highlight"
          disabled={isCaseMetadataActive}
          onClick={onConfirmCifEffect({ validate, cif, getCaseMetadata })}
        >
          {t('components.LawyerFeedbackForm.caseNumberConfirmButton')}
        </button>

        <Spacer />
        <Spacer />
      </div>

      {!showMetadata && (
        <LawyerFeedbackMetadataSkeleton animated={isCaseMetadataActive} />
      )}

      {showMetadata && (
        <LawyerFeedbackMetadata
          metadata={caseMetadata}
          includedFields={['legalProblemCode', 'dateOpened']}
        />
      )}

      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.sourceOfCommentValidation'
          )}
          invalid={
            isTouched('sourceOfComment') &&
            invalidFields.includes('sourceOfComment')
          }
        >
          <InputLabel
            content={t('components.LawyerFeedbackForm.sourceOfComment')}
          >
            <StyledSelect
              name="sourceOfComment"
              value={sourceOfComment}
              disabled={isFormDisabled}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {renderSelectOptions(sourceOfCommentOptions)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.typeOfCommentValidation'
          )}
          invalid={
            isTouched('typeOfComment') &&
            invalidFields.includes('typeOfComment')
          }
        >
          <InputLabel
            content={t('components.LawyerFeedbackForm.typeOfComment')}
          >
            <StyledSelect
              name="typeOfComment"
              value={typeOfComment}
              disabled={isFormDisabled}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              <option value="true">
                {t('components.LawyerFeedbackForm.positive')}
              </option>
              <option value="false">
                {t('components.LawyerFeedbackForm.negative')}
              </option>
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.negativeReasonValidation'
          )}
          invalid={
            isTouched('negativeReason') &&
            invalidFields.includes('negativeReason')
          }
        >
          <InputLabel
            content={t('components.LawyerFeedbackForm.negativeReason')}
          >
            <StyledSelect
              name="negativeReason"
              value={negativeReason}
              disabled={isPositive || isFormDisabled}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {renderSelectOptions(negativeReasonOptions)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.resolvedHowValidation'
          )}
          invalid={
            isTouched('resolvedHow') && invalidFields.includes('resolvedHow')
          }
        >
          <InputLabel content={t('components.LawyerFeedbackForm.resolvedHow')}>
            <StyledSelect
              name="resolvedHow"
              value={resolvedHow}
              disabled={isPositive || isFormDisabled}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {renderSelectOptions(resolvedHowOptions)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.resolvedByValidation'
          )}
          invalid={
            isTouched('resolvedBy') && invalidFields.includes('resolvedBy')
          }
        >
          <InputLabel content={t('components.LawyerFeedbackForm.resolvedBy')}>
            <StyledSelect
              name="resolvedBy"
              value={resolvedBy}
              disabled={isFormDisabled}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {renderSelectOptions(resolvedByOptions)}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerFeedbackForm.dateClosedValidation'
          )}
          invalid={
            isTouched('dateClosed') && invalidFields.includes('dateClosed')
          }
        >
          <InputLabel content={t('components.LawyerFeedbackForm.dateClosed')}>
            <DatePicker
              name="dateClosed"
              selected={nextDateClosed}
              disabled={
                isFormDisabled ||
                (typeof dateClosed === 'string' && dateClosed !== '')
              }
              {...datePickerEventHandlers('dateClosed')}
            />
          </InputLabel>
        </InputHelp>
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t('components.LawyerFeedbackForm.notesValidation')}
          invalid={isTouched('notes') && invalidFields.includes('notes')}
        >
          <InputLabel content={t('components.LawyerFeedbackForm.notes')}>
            <textarea
              name="notes"
              value={notes}
              disabled={isFormDisabled}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>
      </div>
      <div className="lawyer-feedback-attachments">
        <div className="lawyer-feedback-attachments-heading">
          <h2>{t('components.LawyerFeedbackForm.documentsTitle')}</h2>
          <div className="lawyer-feedback-attachments-input">
            <input
              ref={fileInputRef}
              type="file"
              name="feedBackDocuments"
              multiple
              disabled={isFormDisabled}
              onChange={onSelectFilesEffect({
                files: feedBackDocuments,
                onChange,
              })}
            />
            <button
              type="button"
              className="button-link-appearance"
              disabled={isFormDisabled}
              onClick={onClickUploadFileEffect({ fileInputRef })}
            >
              {t('components.LawyerFeedbackForm.documentsUpload')}
            </button>
          </div>
        </div>
        {feedBackDocuments.length === 0 && (
          <p>{t('components.LawyerFeedbackForm.documentsEmptyState')}</p>
        )}
        {feedBackDocuments.length > 0 && (
          <Fragment>
            <ul className="unstyled-list lawyer-feedback-attachments-list">
              {feedBackDocuments.map((file, idx) => {
                if (file.size) {
                  return (
                    <li
                      key={idx}
                      className={classnames({
                        'lawyer-feedback-attachment-invalid': !validateFileSize(
                          file
                        ),
                      })}
                    >
                      <span>{file.name}</span>
                      <button
                        type="button"
                        disabled={isFormDisabled}
                        onClick={onDeselectFileEffect({
                          inputName: 'feedBackDocuments',
                          indexOfFileToDeselect: idx,
                          files: feedBackDocuments,
                          onChange,
                        })}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </li>
                  );
                } else {
                  return (
                    <li key={idx} className={'existing-file'}>
                      <span>{`${file.docName}.${file.docType}`}</span>
                    </li>
                  );
                }
              })}
            </ul>
            {invalidFields.includes('feedBackDocuments') && (
              <p className="lawyer-feedback-attachments-error">
                {t('common.fileSize', { limit: fileSizeLimitMB })}
              </p>
            )}
          </Fragment>
        )}
      </div>
    </form>
  );
};

LawyerFeedbackForm.defaultProps = {
  isFormDisabled: false,
};

LawyerFeedbackForm.propTypes = {
  formData: PropTypes.object.isRequired,
  formOptions: PropTypes.shape({
    sourceOfCommentOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    negativeReasonOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    resolvedHowOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    resolvedByOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isCaseMetadataActive: PropTypes.bool.isRequired,
  caseMetadata: PropTypes.object.isRequired,
  getCaseMetadata: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool,
};

export default LawyerFeedbackForm;
