import { LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';

export const STAFF_OFFICE_LEGAL_PROBLEM_CODES = [
  '011',
  '012',
  '013',
  '014',
  '113',
  '114',
  '021',
  '022',
  '024',
  '025',
];

export const defaultValueForStaffOfficeCases = (
  legalProblemCode,
  defaultValue,
  fallbackValue = ''
) => {
  if (STAFF_OFFICE_LEGAL_PROBLEM_CODES.includes(legalProblemCode)) {
    return defaultValue;
  } else {
    return fallbackValue;
  }
};

// Services Provided

export const defaultServicesProvidedForLegalProblemCode = (legalProblemCode) =>
  defaultValueForStaffOfficeCases(legalProblemCode, '5');

export const resolveDefaultServicesProvidedForLegalProblemCodeAndLawyer = (
  legalProblemCode,
  lawyerType,
  servicesProvidedCode = ''
) => {
  if (
    parseInt(lawyerType) === LAWYER_TYPE_ENUM.STAFF &&
    !servicesProvidedCode
  ) {
    return defaultServicesProvidedForLegalProblemCode(legalProblemCode);
  } else {
    return servicesProvidedCode;
  }
};

export const updateCaseWithDefaultServicesProvidedForLegalProblemCode = (
  caseInfo = {},
  legalProblemCode = ''
) => {
  const { caseLawyer = {} } = caseInfo;
  const { lawyerType } = caseLawyer;
  const servicesProvidedCode = resolveDefaultServicesProvidedForLegalProblemCodeAndLawyer(
    legalProblemCode,
    lawyerType
  );
  const nextCaseLawyer = { ...caseLawyer, servicesProvidedCode };
  const nextCaseInfo = { ...caseInfo, caseLawyer: nextCaseLawyer };
  return nextCaseInfo;
};

// Payee

export const defaultPayeeForLegalProblemCode = (legalProblemCode) =>
  defaultValueForStaffOfficeCases(legalProblemCode, '1'); // payee '1' is lawyer

export const resolveDefaultPayeeForLegalProblemCodeAndLawyer = (
  legalProblemCode,
  lawyerType,
  payee = ''
) => {
  if (parseInt(lawyerType) === LAWYER_TYPE_ENUM.STAFF && !payee) {
    return defaultPayeeForLegalProblemCode(legalProblemCode);
  } else {
    return payee;
  }
};

export const updateCaseWithDefaultPayeeForLegalProblemCode = (
  caseInfo = {},
  legalProblemCode = ''
) => {
  const { caseBilling = {}, caseLawyer = {} } = caseInfo;
  const payee = resolveDefaultPayeeForLegalProblemCodeAndLawyer(
    legalProblemCode,
    caseLawyer.lawyerType
  );
  const nextCaseBilling = { ...caseBilling, payee };
  const nextCaseInfo = { ...caseInfo, caseBilling: nextCaseBilling };
  return nextCaseInfo;
};
