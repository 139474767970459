export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onClickCancelEffect = (options = {}) => {
  const { untouchAll, resetInteractionCount, onClickCancel } = options;
  return () => {
    untouchAll();
    resetInteractionCount();
    onClickCancel();
  };
};

export const onClickSaveEffect = (options = {}) => {
  const {
    t,
    isValid,
    touchAll,
    resetInteractionCount,
    presentStyledBanner,
    onClickSave,
  } = options;
  return async () => {
    if (isValid) {
      await onClickSave();
      resetInteractionCount();
    } else {
      touchAll();
      presentStyledBanner('error', {
        content: t('common.pageValidationError'),
      });
    }
  };
};
