import { searchStringFromParams } from 'js/utilities/params';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const searchRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setLegalProblemCodes,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }

    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Admin/LegalCodes${query.search}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.LegalProblemCodes.searchError') },
          },
        }
      );

      const { legalCodes = [], totalCount = 0 } = json;

      setLegalProblemCodes(legalCodes);
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousSearchResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextSearchResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, Order: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

export const legalProblemCodesListPrintRequestEffect = ({ api, query }) => {
  return async () => {
    const { json = {} } = await api.getJson(
      `/Admin/LegalCodes${query.search}`,
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );
    const { legalCodes = [] } = json;
    const activeCodes = legalCodes.filter((item) => !item.inactive);
    return activeCodes;
  };
};
