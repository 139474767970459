import { cleanDependents } from './functions';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingDependents } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingDependents(false);
    };
  };
};

export const getDependentsEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    setLoadingDependents,
    setDependents,
    getDependentsAbortControllerRef,
  } = options;
  return async () => {
    setLoadingDependents(true);

    rotateAbortControllerRef(getDependentsAbortControllerRef);
    const { signal } = getDependentsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Client/${id}/Dependents`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientDependents.requestError'),
            },
          },
        }
      );

      setDependents(cleanDependents(json));
      setLoadingDependents(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingDependents(false);
      }
    }
  };
};
