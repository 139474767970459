import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DatePicker, InputLabel, InputHelp } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';
import ReleasePrintHeader from './release-print-header';
import ReleasePrintLayout from './release-print-layout';
import { PrintFooter } from 'js/components/print';

const ReleaseHeader = (props) => {
  const { t } = useTranslation();
  const {
    release = {},
    isCopy = false,
    onCreate,
    onSave,
    isArchived = false,
    isNew,
  } = props;

  const { effectiveDate = null, endDate = null, schedule = {} } = release;

  const { id, name } = schedule;

  const createButtons = (
    <Fragment>
      {!isArchived && (
        <Link
          to={routePaths.feeSchedulesActive}
          className="button-link-appearance"
        >
          {t('components.ReleasePage.discard')}
        </Link>
      )}
      {isArchived && (
        <button
          className="button button-highlight page-action-button"
          onClick={() => window.print()}
        >
          {t('common.print')}
        </button>
      )}
      <button
        className="button button-highlight page-action-button"
        onClick={onCreate}
      >
        {isArchived
          ? t('components.FeeSchedulePage.createActiveRelease')
          : t('common.create')}
      </button>
    </Fragment>
  );

  const saveButtons = (
    <Fragment>
      <button
        className="button button-highlight page-action-button"
        onClick={() => window.print()}
      >
        {t('common.print')}
      </button>
      <button
        className="button button-highlight page-action-button"
        onClick={onSave}
        // disabled={isArchived}
      >
        {t('common.save')}
      </button>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="release-heading">
        <div className="release-heading-left">
          <h1>
            {id} {name}
          </h1>
          <div className="date-container">
            <InputHelp
              validationContent={t(
                'components.FeeSchedulePage.startDateValidation'
              )}
            >
              <InputLabel content={t('components.FeeSchedulePage.startDate')}>
                <DatePicker
                  name="startDate"
                  selected={effectiveDate}
                  readOnly
                />
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.FeeSchedulePage.endDateValidation'
              )}
            >
              <InputLabel content={t('components.FeeSchedulePage.endDate')}>
                <DatePicker name="endDate" selected={endDate} readOnly />
              </InputLabel>
            </InputHelp>
          </div>
        </div>
        <div className="release-heading-right">
          {isCopy || isNew || isArchived ? createButtons : saveButtons}
        </div>
      </div>

      <ReleasePrintHeader release={release} />
      <ReleasePrintLayout release={release} />
      <PrintFooter />
    </Fragment>
  );
};

ReleaseHeader.propTypes = {
  release: PropTypes.object,
  isNew: PropTypes.bool,
  onChange: PropTypes.func,
  isCopy: PropTypes.bool,
  saveRelease: PropTypes.func,
  onCreate: PropTypes.func,
  onSave: PropTypes.func,
  isArchived: PropTypes.bool,
};

export default ReleaseHeader;
