import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const onClickDeleteEffect = (options = {}) => {
  const { feedback, setIsDeleting, onClickDelete } = options;
  return async () => {
    if (typeof onClickDelete === 'function') {
      const { feedbackId } = feedback;
      setIsDeleting(true);
      await onClickDelete(feedbackId);
      setIsDeleting(false);
    }
  };
};

export const onClickDocumentEffect = (options = {}) => {
  const {
    feedbackId,
    docId,
    setDocumentIdPendingRetrieval,
    onClickDocument,
  } = options;
  return async () => {
    if (typeof onClickDocument === 'function') {
      setDocumentIdPendingRetrieval(docId);
      await onClickDocument({ feedbackId, docId });
      setDocumentIdPendingRetrieval('');
    }
  };
};

export const deleteFileEffect = (options = {}) => {
  const {
    t,
    api,
    prompt,
    setActive,
    deleteFileAbortControllerRef,
    lawyerId,
    onComplete,
  } = options;
  return async (docId) => {
    try {
      await prompt(docId, 'lawyer.feedbackFileDelete');
    } catch (error) {
      // The prompt was cancelled
      return;
    }
    rotateAbortControllerRef(deleteFileAbortControllerRef);
    const { signal } = deleteFileAbortControllerRef.current;

    setActive(true);
    const url = `/Lawyer/${lawyerId}/Feedback/Document/${docId}`;

    try {
      await api.deleteJson(
        url,
        { signal },
        {
          success: {
            context: {
              message: t('components.LawyerFeedback.deleteFileSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerFeedback.deleteFileError'),
            },
          },
        }
      );
      setActive(false);
      onComplete(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};
