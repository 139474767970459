import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const companiesListSortOptions = [
  {
    key: 'codes-asc',
    field: 'codes',
    direction: 'asc',
    i18nKey: 'components.CompaniesListPage.sortMenu.codesAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'codes-desc',
    field: 'codes',
    direction: 'desc',
    i18nKey: 'components.CompaniesListPage.sortMenu.codesDesc',
    icon: faSortAlphaDownAlt,
  },
  {
    key: 'company-asc',
    field: 'company',
    direction: 'asc',
    i18nKey: 'components.CompaniesListPage.sortMenu.companyAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'company-desc',
    field: 'company',
    direction: 'desc',
    i18nKey: 'components.CompaniesListPage.sortMenu.companyDesc',
    icon: faSortAlphaDownAlt,
  },
];
