import { cleanClientSummary } from 'js/components/client-summary';
import {
  cleanLawyerSummary,
  cleanStaffLawyerSummary,
} from 'js/components/lawyer-summary';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  caseApiKey,
  cleanCase,
  cleanCaseOptions,
  updateCaseWithDefaultServicesProvidedForLegalProblemCode,
  updateCaseWithDefaultPayeeForLegalProblemCode,
  prepareCaseLawyerRequestBody,
  prepareCaseBillingRequestBody,
} from 'js/utilities/cases';
import { cleanClientOptions } from 'js/utilities/clients';
import {
  LAWYER_TYPE_ENUM,
  isNonCooperatingLawyerType,
  lawyerApiKey,
} from 'js/utilities/lawyers';
import { queryForParams } from 'js/utilities/params';
import presentConfirmEstateTrusteePrompt from '../confirm-estate-trustee';
import { focusInputRef } from './functions';

export const windowEventListenerEffect = ({ closeCase }) => {
  return () => {
    const keyupListener = (e) => {
      if (e.key === 'PageDown') {
        const payeeSelect = document.querySelector('select[name="payee"]');
        if (payeeSelect) {
          payeeSelect.focus();
        }
      } else if (e.key === 'F10') {
        closeCase();
      }
    };

    window.addEventListener('keyup', keyupListener);

    return () => {
      window.removeEventListener('keyup', keyupListener);
    };
  };
};

export const focusCaseNumberInputEffect = (options = {}) => {
  const { caseNumberInputRef } = options;
  return () => focusInputRef(caseNumberInputRef);
};

export const onChangeInputCaseNumberEffect = (options = {}) => {
  const { setInputCaseNumber } = options;
  return (name, value) => setInputCaseNumber(value);
};

export const onBlurInputCaseNumberEffect = (options = {}) => {
  const { touchCaseNumberInput } = options;
  return (e) => {
    const { value = '' } = e.target;
    if (value.length > 0) {
      touchCaseNumberInput();
    }
  };
};

export const setConfirmedCaseNumberEffect = (options = {}) => {
  const {
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  } = options;
  return () => {
    if (isInputCaseNumberValid) {
      setConfirmedCaseNumber(inputCaseNumber);
      untouchCaseNumberInput();
      history.push(queryForParams({ casenumber: inputCaseNumber }));
    } else {
      touchCaseNumberInput();
    }
  };
};

export const resetCaseNumberEffect = (options = {}) => {
  const {
    setCaseInfo,
    setClient,
    setLawyer,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    setClientAndLawyerLoaded,
    untouchCaseNumberInput,
    untouchAll,
    caseNumberInputRef,
  } = options;
  return () => {
    setCaseInfo({});
    setClient({}), setLawyer({}), setInputCaseNumber('');
    setConfirmedCaseNumber('');
    setClientAndLawyerLoaded(false);
    untouchCaseNumberInput();
    untouchAll();
    focusInputRef(caseNumberInputRef);
  };
};

export const onCaseClosedEffect = (options = {}) => {
  const {
    cache,
    setCaseInfo,
    setClient,
    setLawyer,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    setClientAndLawyerLoaded,
    untouchCaseNumberInput,
    untouchAll,
    caseNumberInputRef,
  } = options;
  const resetCase = resetCaseNumberEffect({
    setCaseInfo,
    setClient,
    setLawyer,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    setClientAndLawyerLoaded,
    untouchCaseNumberInput,
    untouchAll,
    caseNumberInputRef,
  });
  return (cachedCaseUrl) => {
    cache.delete(cachedCaseUrl);
    resetCase();
  };
};

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setCaseInfoActive,
    setCaseOptionsActive,
    setClientAndLawyerActive,
    setClientAndLawyerLoaded,
    setCaseClosingActive,

    caseNumberParam,
    setConfirmedCaseNumber,
    setInputCaseNumber,
  } = options;
  return () => {
    // Abort requests on unmount:
    if (caseNumberParam) {
      setInputCaseNumber(caseNumberParam);
      setConfirmedCaseNumber(caseNumberParam);
    }
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setCaseInfoActive(false);
      setCaseOptionsActive(false);
      setClientAndLawyerActive(false);
      setClientAndLawyerLoaded(false);
      setCaseClosingActive(false);
    };
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setCaseInfoActive,
    setCaseInfo,
    untouchAll,
    getCaseInfoAbortControllerRef,
    presentStyledBanner,
  } = options;
  return async () => {
    // Clear all validation errors when resetting the form:
    untouchAll();

    if (!caseNumber) {
      setCaseInfo({});
      setCaseInfoActive(false);
      return;
    }

    setCaseInfoActive(true);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = `/Case/${encodeURIComponent(caseNumber)}?isClosing=true`;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseClosing.caseRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      const cleanCaseData = cleanCase(json, true);
      // Filter irrelevant case lawyer data and default the
      // billing closed date to the current date:
      const {
        caseInformation = {},
        caseBilling = {},
        caseLawyer = {},
      } = cleanCaseData;
      const { caseStatus } = caseInformation;
      const { dateClosed } = caseBilling;
      const { lawyerId, lawyerType } = caseLawyer;

      const relevantCaseData = {
        caseInformation,
        caseLawyer,
        caseBilling: {
          ...caseBilling,
          dateClosed: dateClosed || new Date(),
        },
      };

      if (caseStatus === 'Closed') {
        setCaseInfo({});
        presentStyledBanner('error', {
          content: t('components.CaseClosing.alreadyClosedError', {
            caseNumber,
          }),
          timeout: 0,
        });
      } else if (!lawyerId && !isNonCooperatingLawyerType(lawyerType)) {
        setCaseInfo({});
        presentStyledBanner('error', {
          content: t('components.CaseClosing.noLawyerError', {
            caseNumber,
          }),
          timeout: 0,
        });
      } else {
        setCaseInfo(relevantCaseData);
      }

      setCaseInfoActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseInfoActive(false);
      }
    }
  };
};

export const getCaseOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setCaseOptionsActive,
    setCaseOptions,
    getCaseOptionsAbortControllerRef,
  } = options;
  return async () => {
    setCaseOptionsActive(true);

    rotateAbortControllerRef(getCaseOptionsAbortControllerRef);
    const { signal: optionsSignal } = getCaseOptionsAbortControllerRef.current;

    const optionsUrl = '/Common/CaseOptions';
    const optionsRecord = cache.get(optionsUrl);

    if (!optionsRecord) {
      try {
        const { json = {} } = await api.getJson(
          optionsUrl,
          { signal: optionsSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseClosing.optionsRequestError'),
              },
            },
          }
        );

        const caseOptions = cleanCaseOptions(json);
        cache.set(optionsUrl, caseOptions);

        setCaseOptions(caseOptions);
        setCaseOptionsActive(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setCaseOptionsActive(false);
        }
      }
    } else {
      const { value: caseOptions = {} } = optionsRecord;
      setCaseOptions(caseOptions);
      setCaseOptionsActive(false);
    }
  };
};

export const getClientAndLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    lawyerId,
    lawyerType,
    setClientAndLawyerActive,
    setClientAndLawyerLoaded,
    setClient,
    setClientOptions,
    setLawyer,
    getClientAbortControllerRef,
    getLawyerAbortControllerRef,
  } = options;
  return async () => {
    const hasClientOrLawyer = !!(clientId && clientCode) || !!lawyerId;
    if (!hasClientOrLawyer) {
      return;
    }

    const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);
    const isStaffLawyer = Number(lawyerType) === LAWYER_TYPE_ENUM.STAFF;

    setClientAndLawyerActive(true);
    setClientAndLawyerLoaded(false);

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal: clientSignal } = getClientAbortControllerRef.current;

    rotateAbortControllerRef(getLawyerAbortControllerRef);
    const { signal: lawyerSignal } = getLawyerAbortControllerRef.current;

    let clientSummary = {};
    let clientOptions = {};
    let lawyerSummary = {};

    try {
      if (clientId && clientCode) {
        const clientUrl = `/Client/${clientId}/${clientCode}`;
        const { json: clientJson = {} } = await api.getJson(
          clientUrl,
          { signal: clientSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseClosing.clientRequestError'),
              },
            },
          }
        );
        clientSummary = cleanClientSummary(clientJson);
        clientOptions = cleanClientOptions(clientJson);
      }
      //staff lawyer 1994/0300/77208
      if (lawyerId && !isNonCoopLawyer) {
        const lawyerUrl = lawyerApiKey(lawyerId, Number(lawyerType));
        const { json: lawyerJson = {} } = await api.getJson(
          lawyerUrl,
          { signal: lawyerSignal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseClosing.lawyerRequestError'),
              },
            },
          }
        );

        lawyerSummary = isStaffLawyer
          ? cleanStaffLawyerSummary(lawyerJson)
          : cleanLawyerSummary(lawyerJson);
      }

      setClient(clientSummary);
      setClientOptions(clientOptions);
      setLawyer(lawyerSummary);
      setClientAndLawyerActive(false);
      setClientAndLawyerLoaded(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setClientAndLawyerActive(false);
        setClientAndLawyerLoaded(true);
      }
    }
  };
};

export const closeCaseEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    prompt,
    presentStyledBanner,
    caseNumber,
    caseInfo,
    setCaseClosingActive,
    caseClosingAbortControllerRef,
    onCaseClosed,
  } = options;
  return async () => {
    const {
      caseInformation = {},
      caseBilling = {},
      caseLawyer = {},
    } = caseInfo;

    const validationData = {
      ...caseInformation,
      ...caseBilling,
      ...caseLawyer,
    };

    const { isValid } = validateAll(validationData);
    if (!isValid) {
      touchAll();
      presentStyledBanner('error', {
        content: t('common.pageValidationError'),
      });
      return;
    }

    // Copy of the case lawyer data that can be mutated
    // by the estate trustee confirmation prompt:
    const mutableCaseLawyer = { ...caseLawyer };

    try {
      await prompt(caseInfo, 'case.estateTrusteeRule');
      await presentConfirmEstateTrusteePrompt({
        prompt,
        data: caseInfo,
        initialValue: mutableCaseLawyer.stateTrustee,
        onChange: (name, value) => {
          mutableCaseLawyer[name] = value;
        },
      });
    } catch (error) {
      return;
    }

    const url = `/Case/close/${encodeURIComponent(caseNumber)}`;
    const body = {
      caseInformation: { ...caseInformation, caseStatus: 'Closed' },
      caseLawyer: prepareCaseLawyerRequestBody(mutableCaseLawyer),
      caseBilling: {
        ...prepareCaseBillingRequestBody(caseBilling),
        dateClosed: new Date(),
      },
    };

    setCaseClosingActive(true);

    rotateAbortControllerRef(caseClosingAbortControllerRef);
    const { signal } = caseClosingAbortControllerRef.current;

    try {
      await api.postJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseClosing.caseClosingSuccessMessage', {
                caseNumber,
              }),
            },
          },
          error: {
            context: {
              message: t('components.CaseClosing.caseClosingRequestError', {
                caseNumber,
              }),
            },
          },
        }
      );

      setCaseClosingActive(false);

      if (typeof onCaseClosed === 'function') {
        onCaseClosed(caseApiKey(caseNumber));
      }
    } catch (error) {
      setCaseClosingActive(false);
    }
  };
};

export const updateCaseInfoEffect = (options = {}) => {
  const { caseInfo, setCaseInfo, groupName } = options;
  return (name, value) => {
    const group = caseInfo[groupName];
    if (group) {
      let nextCaseInfo = {
        ...caseInfo,
        [groupName]: { ...group, [name]: value },
      };

      // If the legal problem code changes, the services provided code and payee
      // should be set to the default value for the selected legal problem code.
      // (This only applies if the services provided code or payee is empty.)
      if (name === 'legalProblemCode') {
        nextCaseInfo = updateCaseWithDefaultServicesProvidedForLegalProblemCode(
          nextCaseInfo,
          value
        );
        nextCaseInfo = updateCaseWithDefaultPayeeForLegalProblemCode(
          nextCaseInfo,
          value
        );
      }

      setCaseInfo(nextCaseInfo);
    }
  };
};
