import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';

const ProvincesListPrintHeader = (props) => {
  const { t } = useTranslation();
  const { className } = props;

  return (
    <PrintHeader
      className={classnames('provinces-list-print-header', className)}
      title={t('components.ProvincesList.printTitle')}
      heading={t('components.ProvincesList.printHeading')}
    />
  );
};

ProvincesListPrintHeader.propTypes = {
  className: PropTypes.string,
};

export default ProvincesListPrintHeader;
