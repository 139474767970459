import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getRecentItemsEffect = (options = {}) => {
  const { t, api, setActive, setRecentItems, abortControllerRef } = options;
  return async () => {
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = [] } = await api.getJson(
        `/Common/RecentItems`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.RecentItems.fetchError') },
          },
        }
      );

      setRecentItems(json);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};
