import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ClipboardButton from 'js/components/controls/clipboard-button';
import {
  isPendingChequeRunError,
  getErrorCodeAndDescription,
} from './functions';

const ChequeRunError = (props) => {
  const { t } = useTranslation();
  const {
    error = {},
    lockSystemActive,
    onClickUnlock,
    isDeleteRunActive,
    onClickDeleteRun,
  } = props;

  const isActive = lockSystemActive || isDeleteRunActive;

  const { errorCode, errorDescription } = getErrorCodeAndDescription(t, error);
  const blockedByPendingRun = isPendingChequeRunError(errorCode);
  const errorText = blockedByPendingRun
    ? t('components.ChequeRunError.pendingRunError')
    : errorDescription;

  return (
    <div className="cheque-run-error">
      <p className="error-description">{errorText}</p>

      {blockedByPendingRun && (
        <div className="button-bar">
          <button
            className="button"
            disabled={isActive}
            onClick={onClickDeleteRun}
          >
            {isDeleteRunActive
              ? t('components.ChequeRunError.cancelPendingRunButtonTitleActive')
              : t('components.ChequeRunError.cancelPendingRunButtonTitle')}
          </button>
        </div>
      )}

      {!blockedByPendingRun && (
        <Fragment>
          <ClipboardButton
            title={t('components.ChequeRunError.copyError')}
            clipboardText={JSON.stringify(error, null, 2)}
          />
          <div className="button-bar">
            <button
              className="button"
              disabled={isActive}
              onClick={onClickUnlock}
            >
              {lockSystemActive
                ? t('components.ChequeRunError.cancelButtonTitleActive')
                : t('components.ChequeRunError.cancelButtonTitle')}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

ChequeRunError.propTypes = {
  error: PropTypes.object.isRequired,
  lockSystemActive: PropTypes.bool.isRequired,
  onClickUnlock: PropTypes.func.isRequired,
  isDeleteRunActive: PropTypes.bool.isRequired,
  onClickDeleteRun: PropTypes.func.isRequired,
};

export default ChequeRunError;
