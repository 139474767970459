import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { breadcrumbTypes } from 'js/components/design-system';
import { userGroups } from 'js/components/group-permissions';
import { isNewClientPath } from 'js/utilities/clients';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const titleForNewClientOrDependent = (t, isNewDependent) => {
  return isNewDependent
    ? t('components.ClientPage.newDependentTitle')
    : t('components.ClientPage.newClientTitle');
};

export const queryFilteringSearchParamsForClientTabs = (search = '') => {
  const permittedKeys = ['from'];
  const params = paramsFromSearchString(search);
  const filteredParams = permittedKeys.reduce((nextParams, key) => {
    if (params[key]) {
      return { ...nextParams, [key]: params[key] };
    } else {
      return nextParams;
    }
  }, {});
  return queryForParams(filteredParams);
};

export const linkPropsForRoutePath = (path, params = {}, search = '') => {
  const clientPath = routePathReplacingParams(path, params);
  return {
    linkTo: `${clientPath}${search}`,
    isActive: (currentPath) => currentPath === path,
  };
};

export const clientTabItems = (options = {}) => {
  const {
    t,
    path,
    params = {},
    search = '',
    showClientNotesCount,
    clientNotesCount,
    isAdmin,
  } = options;

  const isPrimaryClient = params.clientCode === '00';
  const isNewClient = isNewClientPath(path);

  const infoTab = {
    ...linkPropsForRoutePath(routePaths.clientInformation, params, search),
    content: t('components.ClientInformation.title'),
  };

  const dependentsTab = {
    ...linkPropsForRoutePath(routePaths.clientDependents, params, search),
    content: t('components.ClientDependents.title'),
  };

  const dependentOfTab = {
    ...linkPropsForRoutePath(routePaths.clientDependentOf, params, search),
    content: t('components.ClientDependentOf.title'),
  };

  const caseHistoryTab = {
    ...linkPropsForRoutePath(routePaths.clientCaseHistory, params, search),
    content: t('components.ClientCaseHistory.title'),
  };

  let clientNotesTabContent = t('components.ClientNotes.title');
  if (showClientNotesCount) {
    clientNotesTabContent = `${clientNotesTabContent} (${clientNotesCount})`;
  }

  const clientNotesTab = {
    ...linkPropsForRoutePath(routePaths.clientNotes, params, search),
    content: clientNotesTabContent,
  };

  const clientLogTab = {
    ...linkPropsForRoutePath(routePaths.clientLog, params, search),
    content: t('components.ClientLog.title'),
  };

  const clientDocumentsTab = {
    ...linkPropsForRoutePath(routePaths.clientDocuments, params, search),
    content: t('components.ClientDocuments.title'),
  };

  if (isPrimaryClient || isNewClient) {
    // Primary client tabs:
    const primaryTabs = [
      infoTab,
      dependentsTab,
      caseHistoryTab,
      clientNotesTab,
      clientDocumentsTab,
    ];

    if (isAdmin) {
      primaryTabs.push(clientLogTab);
    }

    return primaryTabs;
  } else {
    // Dependent tabs:
    const dependentTabs = [
      infoTab,
      dependentOfTab,
      caseHistoryTab,
      clientNotesTab,
      clientDocumentsTab,
    ];
    if (isAdmin) {
      dependentTabs.push(clientLogTab);
    }
    return dependentTabs;
  }
};

export const clientBreadcrumbsHistory = (t, location = {}, match = {}) => {
  const { pathname, search } = location;
  const { path, params = {} } = match;
  const { from: fromLocation } = paramsFromSearchString(search);
  const currentPath = `${pathname}${search}`;
  const isPrimaryClient = params.clientCode === '00';
  const isNewClient = isNewClientPath(path);
  const history = [breadcrumbTypes.home];

  if (fromLocation) {
    history.push({
      content: t('components.ClientPage.breadcrumbs.search'),
      path: fromLocation,
    });
  }

  history.push({
    content:
      isPrimaryClient || isNewClient
        ? t('components.ClientPage.breadcrumbs.client')
        : t('components.ClientPage.breadcrumbs.dependent'),
    path: currentPath,
  });

  return history;
};

export const clientActionsPermittedGroups = [
  userGroups.administrator,
  userGroups.dataEntry,
  userGroups.intakeSpecialist,
  userGroups.feedbackSpecialist,
];
