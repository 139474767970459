import { resolvePromptPropsForRule } from './functions';

export const promptEffect = (options = {}) => {
  const { rules = {}, setPromiseCallbacks, setPromptProps } = options;
  return (data, ruleKey, propOverrides = {}) => {
    if (!ruleKey || !rules[ruleKey]) {
      throw new Error(
        `PromptContext::promptEffect: missing or invalid rule for key '${ruleKey}'`
      );
    }

    const { test, ...ruleProps } = rules[ruleKey];

    if (test(data)) {
      const props = resolvePromptPropsForRule(ruleProps, data);
      setPromptProps({ ...props, ...propOverrides });
      return new Promise((resolve, reject) => {
        setPromiseCallbacks({ resolve, reject });
      });
    } else {
      setPromptProps({});
      return Promise.resolve();
    }
  };
};

export const onConfirmPromptEffect = (options = {}) => {
  const { promiseCallbacks, setPromiseCallbacks, setPromptProps } = options;
  return () => {
    const { resolve } = promiseCallbacks;
    if (typeof resolve === 'function') {
      resolve();
    }
    setPromiseCallbacks({});
    setPromptProps({});
  };
};

export const onCancelPromptEffect = (options = {}) => {
  const { promiseCallbacks, setPromiseCallbacks, setPromptProps } = options;
  return () => {
    const { reject } = promiseCallbacks;
    if (typeof reject === 'function') {
      reject();
    }
    setPromiseCallbacks({});
    setPromptProps({});
  };
};
