import { onBeforePrint, onAfterPrint } from './functions';

export const onBeforePrintEffect = (options = {}) => {
  const { containerRef } = options;
  return () => onBeforePrint(containerRef);
};

export const onAfterPrintEffect = (options = {}) => {
  const { containerRef } = options;
  // Important: delay execution by a frame to allow the print dialog to block
  return () => setTimeout(onAfterPrint(containerRef));
};

export const printManagerMountEffect = (options = {}) => {
  const { containerRef } = options;
  return () => {
    const beforePrint = onBeforePrintEffect({ containerRef });
    const afterPrint = onAfterPrintEffect({ containerRef });

    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);

    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  };
};
