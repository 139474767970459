import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BinaryRadioGroup } from 'js/components/design-system';
import {
  initializeEstateTrusteeEffect,
  onChangeEstateTrusteeEffect,
  onKeyPressEffect,
} from './effects';

const ConfirmEstateTrusteeContent = (props) => {
  const { t } = useTranslation();
  const { className, initialValue, onChange } = props;
  const [isEstateTrustee, setEstateTrustee] = useState(false);

  useEffect(initializeEstateTrusteeEffect({ initialValue, setEstateTrustee }), [
    initialValue,
  ]);

  const onChangeEstateTrustee = onChangeEstateTrusteeEffect({
    setEstateTrustee,
    onChange,
  });

  return (
    <div className={classnames('confirm-estate-trustee-content', className)}>
      <BinaryRadioGroup
        onLabel={t('common.yes')}
        offLabel={t('common.no')}
        name="stateTrustee"
        isOn={isEstateTrustee}
        onChange={onChangeEstateTrustee}
        onKeyPress={onKeyPressEffect({ onChangeEstateTrustee })}
      />
    </div>
  );
};

ConfirmEstateTrusteeContent.defaultProps = {
  initialValue: false,
};

ConfirmEstateTrusteeContent.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ConfirmEstateTrusteeContent;
