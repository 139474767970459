export const onChangeTextEffect = (options = {}) => {
  const { setText } = options;
  return (e) => setText(e.target.value);
};

export const onClickDiscardEffect = (options = {}) => {
  const { setText, onClickCancel } = options;
  return (e) => {
    setText('');
    onClickCancel(e);
  };
};

export const onSubmitEffect = (options = {}) => {
  const { text, setText, setIsSubmitting, onSubmit, onComplete } = options;
  return async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit(text);
      setText('');
      setIsSubmitting(false);
      onComplete();
    } catch (error) {
      setIsSubmitting(false);
    }
  };
};

export const onSubmitEditEffect = (options = {}) => {
  const {
    text,
    setText,
    editingNote,
    setIsSubmitting,
    onEdit,
    onComplete,
  } = options;
  return async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onEdit(text, editingNote);
      setText('');
      setIsSubmitting(false);
      onComplete();
    } catch (error) {
      setIsSubmitting(false);
    }
  };
};

export const setEditingNoteEffect = (options = {}) => {
  const { editingNote, setText } = options;
  return () => {
    const { text = '' } = editingNote;
    if (text) {
      setText(text);
    }
  };
};
