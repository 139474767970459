import IMask from 'imask';

export const createMaskEffect = (options = {}) => {
  const { maskRef, mask } = options;
  return () => {
    maskRef.current = IMask.createMask({ mask });
  };
};

export const onChangeMaskedInputEffect = (options = {}) => {
  const { maskRef, onChange } = options;
  return (e) => {
    const { current: mask } = maskRef;
    if (mask) {
      const { name, value } = e.target;
      onChange(name, mask.resolve(value));
    }
  };
};

export const onChangeInputEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
};
