import * as Sentry from '@sentry/react';

export const getCurrentUserEffect = (options = {}) => {
  const { api, isReady, account, setCurrentUser, setHasLoaded } = options;
  return async () => {
    if (!isReady) {
      return;
    }

    // The /Account/whodis endpoint requires an access token,
    // so prevent the request if the user hasn't authenticated.
    if (!account) {
      setHasLoaded(true);
      return;
    }

    try {
      const { json: user } = await api.getJson(
        '/Account/whodis',
        {},
        { success: { bypass: true }, error: { bypass: true } }
      );

      const { code = '', email = '' } = user;
      Sentry.setUser({ id: code, email });

      setCurrentUser(user);
      setHasLoaded(true);
    } catch (error) {
      // the error condition will be handled by
      // the render controller's renderOnError prop.
      setHasLoaded(true);
    }
  };
};
