import IMask from 'imask';
import { dateMaskPattern } from 'js/utilities/masks';

export const createDayEndDateMaskEffect = (options = {}) => {
  const { dayEndDateMaskRef } = options;
  return () => {
    dayEndDateMaskRef.current = IMask.createMask({
      mask: dateMaskPattern,
    });
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { onConfirm } = options;
  return (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onConfirm(e);
    }
  };
};

export const onChangeEffect = (options = {}) => {
  const { dayEndDateMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = dayEndDateMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};
