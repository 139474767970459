export const resolveFieldsArray = (fieldOrFields) => {
  if (fieldOrFields) {
    return Array.isArray(fieldOrFields) ? fieldOrFields : [fieldOrFields];
  } else {
    return [];
  }
};

export const getTouchedStateForFields = (fields = [], value = false) => {
  return fields.reduce((next, field) => ({ ...next, [field]: value }), {});
};

export const DEFAULT_VALIDATION_RULE_NAME = 'default';

export const isSingleValidationRule = (rule) => {
  return rule instanceof RegExp || typeof rule === 'function';
};

export const isMultipleValidationRule = (rule) => {
  const isRuleObject = typeof rule === 'object' && rule !== null;
  if (isRuleObject && !Array.isArray(rule) && !isSingleValidationRule(rule)) {
    return Object.keys(rule).reduce((isRule, reason) => {
      return isRule && isSingleValidationRule(rule[reason]);
    }, true);
  } else {
    return false;
  }
};

export const executeSingleValidationRule = (rule, name, data, field) => {
  let isValid = true;

  if (rule instanceof RegExp) {
    isValid = rule.test(data[field]);
  } else if (typeof rule === 'function') {
    isValid = rule(data, field);
  }

  const failedRuleNames = isValid ? [] : [name];

  return { isValid, failedRuleNames };
};

export const executeMultipleValidationRule = (rule, data, field) => {
  const initialValue = { isValid: true, failedRuleNames: [] };
  return Object.keys(rule).reduce((result, name) => {
    const {
      isValid: currentIsValid,
      failedRuleNames: currentFailedRuleNames,
    } = executeSingleValidationRule(rule[name], name, data, field);
    const nextIsValid = result.isValid && currentIsValid;
    const nextFailedRuleNames = [
      ...result.failedRuleNames,
      ...currentFailedRuleNames,
    ];
    return { isValid: nextIsValid, failedRuleNames: nextFailedRuleNames };
  }, initialValue);
};

export const executeValidationRule = (
  rules = {},
  data = {},
  field,
  debug = false
) => {
  const rule = rules[field];

  if (isSingleValidationRule(rule)) {
    return executeSingleValidationRule(
      rule,
      DEFAULT_VALIDATION_RULE_NAME,
      data,
      field
    );
  } else if (isMultipleValidationRule(rule)) {
    return executeMultipleValidationRule(rule, data, field);
  } else {
    if (debug) {
      // eslint-disable-next-line no-console
      console.warn(
        `ValidationContext: ignoring field [${field}] because it does not have a corresponding validation rule or the rule is invalid.`
      );
    }
    return { isValid: true, failedRuleNames: [] };
  }
};

export const executeValidationRules = (
  rules = {},
  data = {},
  fields = [],
  debug = false
) => {
  const initialValue = {
    isValid: true,
    invalidFields: [],
    failedRuleNames: {},
  };

  const result = fields.reduce((result, field) => {
    const { isValid, failedRuleNames } = executeValidationRule(
      rules,
      data,
      field,
      debug
    );

    const nextIsValid = result.isValid && isValid;

    let nextInvalidFields = result.invalidFields;
    if (!isValid) {
      nextInvalidFields = [...result.invalidFields, field];
    }

    let nextFailedRuleNames = result.failedRuleNames;
    if (!isValid) {
      nextFailedRuleNames = {
        ...result.failedRuleNames,
        [field]: failedRuleNames,
      };
    }

    return {
      isValid: nextIsValid,
      invalidFields: nextInvalidFields,
      failedRuleNames: nextFailedRuleNames,
    };
  }, initialValue);

  const isFieldValid = (field) => !result.invalidFields.includes(field);
  const getFailedRuleNames = (field) => result.failedRuleNames[field] || [];

  return { ...result, isFieldValid, getFailedRuleNames };
};
