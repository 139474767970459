import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { onChangeEffect } from './effects';
import { binaryRadioGroupValues } from './functions';

const BinaryRadioGroup = (props) => {
  const {
    className,
    onLabel,
    offLabel,
    name,
    isOn,
    onChange,
    ...radioProps
  } = props;
  return (
    <div
      className={classnames(
        'binary-radio-group',
        'radio-group',
        'radio-group-horizontal',
        className
      )}
    >
      <label>
        <input
          type="radio"
          name={name}
          value={binaryRadioGroupValues.on}
          checked={isOn === true}
          onChange={onChangeEffect({ onChange })}
          {...radioProps}
        />
        <span>{onLabel}</span>
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value={binaryRadioGroupValues.off}
          checked={isOn === false}
          onChange={onChangeEffect({ onChange })}
          {...radioProps}
        />
        <span>{offLabel}</span>
      </label>
    </div>
  );
};

BinaryRadioGroup.propTypes = {
  className: PropTypes.string,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default BinaryRadioGroup;
