import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { openPrintViewEffect } from '../functions';
import { useHistory } from 'react-router-dom';

const PrintReportCard = (props) => {
  const {
    reportType,
    printUrl,
    active,
    runReport,
    reportDate,
    visible = true,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const casesOpened = reportType === 'casesOpened';
  //keeping previous functionality in here in case we want to open in a new tab
  const onClickAction = casesOpened
    ? runReport
    : // : () => openPrintViewEffect({ destinationUrl: printUrl });
      () => history.push(printUrl);
  if (!visible) {
    return null;
  } else {
    return (
      <div className="print-report-card card">
        <div className="print-type-title-container">
          {t(`components.dayEnd.${reportType}`)}
        </div>
        <div className="print-button-container">
          <button
            className="button button-highlight page-action-button"
            onClick={onClickAction}
            disabled={active || !reportDate}
          >
            {active && casesOpened
              ? t('components.dayEnd.running')
              : t('components.dayEnd.print')}
          </button>
        </div>
      </div>
    );
  }
};

PrintReportCard.propTypes = {
  reportType: PropTypes.string,
  printUrl: PropTypes.string,
  disabled: PropTypes.bool,
  runReport: PropTypes.func,
  active: PropTypes.bool,
  reportDate: PropTypes.string,
  visible: PropTypes.bool,
};

export default PrintReportCard;
