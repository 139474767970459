export const onChangeChequeNumberEffect = (options = {}) => {
  const { setChequeNumber } = options;
  return (e) => {
    const { value } = e.target;
    setChequeNumber(value);
  };
};

export const onBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onSubmitActionsFormEffect = (options = {}) => {
  const {
    isActive,
    cheque,
    chequeRunOptions = {},
    validateAll,
    touchAll,
    onClick,
  } = options;
  return (e) => {
    e.preventDefault();

    if (isActive) {
      return;
    }

    const { isValid } = validateAll(cheque);
    if (isValid) {
      onClick(cheque, chequeRunOptions);
    } else {
      touchAll();
    }
  };
};
