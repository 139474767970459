import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { useHistory } from 'react-router-dom';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { routePaths } from 'js/components/router/route-paths';
import { ActivityIndicatorCard, InputHelp } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import { isEmpty } from 'js/utilities/validation';
import ReportsList from '../reports-list';
import DeleteFolderModal from './delete-folder-modal';
import ReportResultModal from './report-result-modal';
import {
  onMountEffect,
  onChangeFolderEffect,
  getReportsFolderEffect,
  postReportsFolderEffect,
  postReportsFolderFrequencyEffect,
  putReportsFolderEffect,
  deleteReportsFolderEffect,
  onSubmitReportsFolderEffect,
  onChangeFolderNameEffect,
  onBlurFolderNameEffect,
  onFilterReportsEffect,
  putReportItemEffect,
  moveReportItemEffect,
  duplicateReportItemEffect,
  deleteReportItemEffect,
  runReportItemEffect,
  saveAsReportEffect,
} from './effects';
import {
  reportsFolderDetailValidationRules,
  reportsFolderDetailRequiredFields,
} from './functions';

const ReportsFolderDetail = (props) => {
  const {
    query,
    folderId,
    onCreateFolder,
    onUpdateFolder,
    onDeleteFolder,
  } = props;
  const isNewFolder = folderId === 'new';

  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const history = useHistory();

  const getReportsFolderAbortControllerRef = useRef(null);
  const postReportsFolderAbortControllerRef = useRef(null);
  const postReportsFolderFrequencyAbortControllerRef = useRef(null);
  const putReportsFolderAbortControllerRef = useRef(null);
  const deleteReportsFolderAbortControllerRef = useRef(null);
  const putReportItemAbortControllerRef = useRef(null);
  const moveReportItemAbortControllerRef = useRef(null);
  const duplicateReportItemAbortControllerRef = useRef(null);
  const deleteReportItemAbortControllerRef = useRef(null);
  const runReportItemAbortControllerRef = useRef(null);

  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isLoadingFolder, setLoadingFolder] = useState(false);
  const [isSavingFolder, setSavingFolder] = useState(false);
  const [isDeletingFolder, setDeletingFolder] = useState(false);
  const [isModifyingReport, setModifyingReport] = useState(false);
  const [isRunningReport, setRunningReport] = useState(false);
  const [reportResult, setReportResult] = useState({});
  const [folder, setFolder] = useState({});
  const [folderName, setFolderName] = useState('');
  const [reports, setReports] = useState([]);
  const [reportsFilterIndex, setReportsFilterIndex] = useState(0);
  const { reports: allReports = [], ...folderData } = folder;

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const validateAll = () =>
    validate({ folderName }, reportsFolderDetailRequiredFields);
  const touchAll = () => touch(reportsFolderDetailRequiredFields);
  const { isFieldValid } = validateAll();
  const isFolderNameValid = isFieldValid('folderName');
  const isFolderNameChanged = folderName !== folder.name;

  const isFolderVisible = !isEmpty(folder) || isNewFolder;
  const isActive = isLoadingFolder || isSavingFolder || isDeletingFolder;
  const saveButtonTitle = isNewFolder ? t('common.create') : t('common.save');
  const saveButtonTitleActive = isNewFolder
    ? t('common.creating')
    : t('common.saving');
  const reportsPath = query.search
    ? `${routePaths.reports}${query.search}`
    : routePaths.reports;

  const postFolder = postReportsFolderEffect({
    t,
    api,
    cache,
    folderName,
    setSavingFolder,
    onCreateFolder,
    postReportsFolderAbortControllerRef,
  });

  const onSubmit = onSubmitReportsFolderEffect({
    folderId,
    validateAll,
    touchAll,
    postReportsFolder: postFolder,
    putReportsFolder: putReportsFolderEffect({
      t,
      api,
      cache,
      folderId,
      folderName,
      setSavingFolder,
      setFolder,
      setFolderName,
      onUpdateFolder,
      putReportsFolderAbortControllerRef,
    }),
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getReportsFolderAbortControllerRef,
        postReportsFolderAbortControllerRef,
        putReportsFolderAbortControllerRef,
        deleteReportsFolderAbortControllerRef,
        putReportItemAbortControllerRef,
        moveReportItemAbortControllerRef,
        duplicateReportItemAbortControllerRef,
        deleteReportItemAbortControllerRef,
        runReportItemAbortControllerRef,
      ],
      setLoadingFolder,
    }),
    []
  );

  useEffect(
    onChangeFolderEffect({
      folderId,
      setFolder,
      setFolderName,
      setReports,
      setReportsFilterIndex,
      untouch,
      getReportsFolder: getReportsFolderEffect({
        t,
        api,
        cache,
        folderId,
        setLoadingFolder,
        setFolder,
        setFolderName,
        setReports,
        getReportsFolderAbortControllerRef,
      }),
    }),
    [folderId]
  );

  const moveReport = moveReportItemEffect({
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    moveReportItemAbortControllerRef,
  });

  const duplicateReport = duplicateReportItemEffect({
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    duplicateReportItemAbortControllerRef,
  });

  const putReport = putReportItemEffect({
    t,
    api,
    cache,
    setModifyingReport,
    folder,
    setFolder,
    setReports,
    putReportItemAbortControllerRef,
  });

  const saveAsReport = saveAsReportEffect({
    t,
    api,
    history,
    abortControllerRefs: {
      postReportsFolderAbortControllerRef,
      putReportItemAbortControllerRef,
      moveReportItemAbortControllerRef,
      duplicateReportItemAbortControllerRef,
    },
  });

  return (
    <Fragment>
      {isLoadingFolder && <ActivityIndicatorCard />}

      {isFolderVisible && (
        <Fragment>
          {!isLoadingFolder && (
            <form className="reports-folder-detail-form" onSubmit={onSubmit}>
              <InputHelp
                invalid={isTouched('folderName') && !isFolderNameValid}
                validationContent={t(
                  'components.ReportsFolderDetail.folderNameValidation'
                )}
              >
                <input
                  type="text"
                  name="folderName"
                  value={folderName}
                  disabled={isActive}
                  onChange={onChangeFolderNameEffect({ setFolderName })}
                  onBlur={onBlurFolderNameEffect({ touch })}
                />
              </InputHelp>

              <button
                className="button"
                type="submit"
                disabled={isActive || !isFolderNameChanged}
              >
                {isSavingFolder ? saveButtonTitleActive : saveButtonTitle}
              </button>

              {isNewFolder && (
                <Link className="button" to={reportsPath}>
                  {t('common.cancel')}
                </Link>
              )}

              {!isNewFolder && (
                <Fragment>
                  <button
                    className="button"
                    type="button"
                    disabled={isActive}
                    onClick={() => setConfirmingDelete(true)}
                  >
                    {t('components.ReportsFolderDetail.deleteFolder')}
                  </button>
                  <DeleteFolderModal
                    mounted={isConfirmingDelete}
                    onDismiss={() => setConfirmingDelete(false)}
                    onConfirm={deleteReportsFolderEffect({
                      t,
                      api,
                      cache,
                      folderId,
                      setDeletingFolder,
                      setConfirmingDelete,
                      onDeleteFolder,
                      deleteReportsFolderAbortControllerRef,
                    })}
                    isActive={isDeletingFolder}
                  />
                </Fragment>
              )}
            </form>
          )}

          {!isLoadingFolder && allReports.length === 0 && (
            <EmptyState
              title={t('components.ReportsFolderDetail.emptyStateMessage')}
              subtitle={t('components.ReportsFolderDetail.emptyStateSubtitle')}
            />
          )}

          {!isLoadingFolder && allReports.length > 0 && (
            <ReportsList
              reports={reports}
              folderData={folderData}
              reportsFilterIndex={reportsFilterIndex}
              isModifyingReport={isModifyingReport || isSavingFolder}
              isRunningReport={isRunningReport}
              onChangeFolderFrequency={postReportsFolderFrequencyEffect({
                t,
                api,
                cache,
                folderId,
                setSavingFolder,
                setFolder,
                setReports,
                postReportsFolderFrequencyAbortControllerRef,
              })}
              onFilter={onFilterReportsEffect({
                setReportsFilterIndex,
                setReports,
                folder,
              })}
              onSaveReport={putReport}
              onRunReport={runReportItemEffect({
                t,
                api,
                setRunningReport,
                setReportResult,
                runReportItemAbortControllerRef,
              })}
              onMoveReport={moveReport}
              onDuplicateReport={duplicateReport}
              onDeleteReport={deleteReportItemEffect({
                t,
                api,
                cache,
                setModifyingReport,
                folder,
                setFolder,
                setReports,
                deleteReportItemAbortControllerRef,
              })}
              onSaveAsReport={saveAsReport}
            />
          )}
        </Fragment>
      )}

      {!isEmpty(reportResult) && (
        <ReportResultModal
          onDismiss={() => setReportResult({})}
          reportResult={reportResult}
        />
      )}
    </Fragment>
  );
};

ReportsFolderDetail.propTypes = {
  query: PropTypes.shape({
    params: PropTypes.object.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  folderId: PropTypes.string.isRequired,
  onCreateFolder: PropTypes.func,
  onUpdateFolder: PropTypes.func,
  onDeleteFolder: PropTypes.func,
};

const ValidatedReportsFolderDetail = (props) => {
  return (
    <ValidationProvider rules={reportsFolderDetailValidationRules}>
      <ReportsFolderDetail {...props} />
    </ValidationProvider>
  );
};

export default ValidatedReportsFolderDetail;
