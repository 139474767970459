import IMask from 'imask';
import { caseNumberMaskPattern } from 'js/utilities/masks';
import { roundToFixedPointString } from 'js/utilities/numbers';

export const createCaseNumberMaskEffect = (options = {}) => {
  const { caseNumberMaskRef } = options;
  return () => {
    caseNumberMaskRef.current = IMask.createMask({
      mask: caseNumberMaskPattern,
    });
  };
};

export const onChangeCaseNumberEffect = (options = {}) => {
  const { caseNumberMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = caseNumberMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};

export const onCifKeyDownEffect = (options = {}) => {
  const { onEnter } = options;
  return (e) => {
    switch (e.key) {
      case 'Enter':
        onEnter(e);
        break;
      default:
        break;
    }
  };
};

export const onEnterCifEffect = (options = {}) => {
  const { validate, getCaseMetadata } = options;
  return (e) => {
    const { value: cif } = e.target;
    e.target.blur();

    const { isValid } = validate({ cif }, ['cif']);
    if (isValid) {
      getCaseMetadata(cif);
    }
  };
};

export const onConfirmCifEffect = (options = {}) => {
  const { validate, cif, getCaseMetadata } = options;
  return () => {
    const { isValid } = validate({ cif }, ['cif']);
    if (isValid) {
      getCaseMetadata(cif);
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onSelectProvinceEffect = (options = {}) => {
  const { onChangeMulti, provinceOptions } = options;
  return (e) => {
    const { value } = e.target;
    const province = provinceOptions.find((prov) => prov.provId === value);
    if (province) {
      const { provAbbrev, provId } = province;
      onChangeMulti({ province: provAbbrev, provId });
    }
  };
};

export const onSelectCityEffect = (options = {}) => {
  const { onChangeMulti, cityOptions } = options;
  return (e) => {
    const { value } = e.target;
    //Cities do not have unique ids, so we have to
    //check city code and province code together
    const values = value.split('/');
    const cityCode = values[0];
    const provinceCode = values[1];
    const city = cityOptions.find(
      (c) => c.cityCode === cityCode && c.provinceCode === provinceCode
    );
    if (city) {
      const { cityCode, cityName } = city;
      onChangeMulti({ city: cityName, cityId: cityCode });
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onCustomaryFeeInputBlurEffect = (options = {}) => {
  const { touch, onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    const nextValue = roundToFixedPointString(value, 2);
    if (typeof onChange === 'function') {
      onChange(name, nextValue);
    }
    touch(name);
  };
};

export const onCheckboxChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, checked } = e.target;
      onChange(name, checked);
    }
  };
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange, touch } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
      touch(name);
    }
  };
};

export const onSelectFilesEffect = (options = {}) => {
  const { files, onChange } = options;
  return (e) => {
    const { name, files: filesList } = e.target;
    const filesArray = Array.from(filesList);
    const nextFiles = [...files, ...filesArray];
    onChange(name, nextFiles);
    e.target.value = '';
  };
};

export const onDeselectFileEffect = (options = {}) => {
  const { inputName, indexOfFileToDeselect, files, onChange } = options;
  return () => {
    const nextFiles = files.filter((na, idx) => idx !== indexOfFileToDeselect);
    onChange(inputName, nextFiles);
  };
};

export const onClickUploadFileEffect = (options = {}) => {
  const { fileInputRef } = options;
  return () => {
    const { current: fileInput } = fileInputRef;
    if (fileInput) {
      fileInput.click();
    }
  };
};
