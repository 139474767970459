import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';
import DeleteLawyerFeedback from './delete-lawyer-feedback';

const DeleteLawyerFeedbackModal = (props) => {
  const { className, mounted, lawyerId, feedbackId, onDismiss } = props;
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames('delete-lawyer-feedback-modal', className)}
      onClickCancel={() => onDismiss(false)}
      titleText={t('components.DeleteLawyerFeedback.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
      allowCancel={false}
    >
      <DeleteLawyerFeedback
        lawyerId={lawyerId}
        feedbackId={feedbackId}
        onDismiss={onDismiss}
      />
    </Modal>
  );
};

DeleteLawyerFeedbackModal.defaultProps = {
  mounted: false,
};

DeleteLawyerFeedbackModal.propTypes = {
  ...DeleteLawyerFeedback.propTypes,
  mounted: PropTypes.bool,
};

export default DeleteLawyerFeedbackModal;
