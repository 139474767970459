import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import { Card, InputHelp, StyledSelect } from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { isStaffLawyerType } from 'js/utilities/lawyers';
import { onInputChangeEffect, onInputBlurEffect } from './effects';

const LawyerCifForm = (props) => {
  const { t } = useTranslation();
  const { className, formData = {}, onChange, isNonCoopLawyer } = props;
  const { lawyerType, sentToLawyer } = formData;
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { invalidFields = [] } = validate(formData, ['sentToLawyer']);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editCaseLawyer');
  const isStaffLawyer = isStaffLawyerType(lawyerType);

  return (
    <Card className={classnames('lawyer-cif-form', className)}>
      <h2>{t('components.CaseInformation.LawyerCifForm.cifSentTo')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.LawyerCifForm.cifSentToValidation'
          )}
          invalid={
            isTouched('sentToLawyer') && invalidFields.includes('sentToLawyer')
          }
        >
          <StyledSelect
            name="sentToLawyer"
            value={sentToLawyer}
            disabled={!hasEditPermission}
            onChange={onInputChangeEffect({ onChange })}
            onBlur={onInputBlurEffect({ touch })}
          >
            <option value="" disabled={!isStaffLawyer}>
              {isStaffLawyer
                ? t('components.CaseInformation.LawyerCifForm.none')
                : t('common.defaultSelectPlaceholder')}
            </option>
            <option value="false">
              {t('components.CaseInformation.LawyerCifForm.client')}
            </option>
            <option value="true" disabled={isNonCoopLawyer}>
              {t('components.CaseInformation.LawyerCifForm.lawyer')}
            </option>
          </StyledSelect>
          {/*
          <div className="radio-group radio-group-horizontal">
            <label>
              <input
                type="radio"
                name="sentToLawyer"
                value={'false'}
                checked={sentToLawyer === 'false'}
                disabled={!hasEditPermission}
                onChange={onRadioGroupChangeEffect({ onChange, touch })}
              />
              <span>
                {t('components.CaseInformation.LawyerCifForm.client')}
              </span>
            </label>
            <label>
              <input
                type="radio"
                name="sentToLawyer"
                value={'true'}
                checked={sentToLawyer === 'true'}
                //CIF can't be sent to Non-co-operating Lawyers
                disabled={!hasEditPermission || isNonCoopLawyer}
                onChange={onRadioGroupChangeEffect({ onChange, touch })}
              />
              <span>
                {t('components.CaseInformation.LawyerCifForm.lawyer')}
              </span>
            </label>
          </div>
          */}
        </InputHelp>
      </div>
    </Card>
  );
};

LawyerCifForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isNonCoopLawyer: PropTypes.bool,
};

export default LawyerCifForm;
