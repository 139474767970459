import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView, queryForListPrintView } from 'js/components/print';
import { useTranslation } from 'react-i18next';
import { legalProblemCodesListPrintRequestEffect } from './effects';
import { queryForSearchString } from './functions';

const LegalProblemCodesListPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const { search = '' } = useLocation();
  const query = queryForListPrintView(queryForSearchString(search));

  return (
    <ListPrintView
      request={legalProblemCodesListPrintRequestEffect({ api, query })}
      renderTableHeaders={() => (
        <tr>
          <th>{t('components.LegalProblemCodesListPrintView.columns.code')}</th>
          <th>{t('components.LegalProblemCodesListPrintView.columns.name')}</th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.code}>
          <td>{item.code}</td>
          <td>{item.name}</td>
        </tr>
      )}
      emptyStateTitle={t(
        'components.LegalProblemCodesListPrintView.emptyStateTitle'
      )}
      headerProps={{
        title: t('components.LegalProblemCodesListPrintView.title'),
        heading: t('components.LegalProblemCodesListPrintView.heading'),
        printOnly: false,
      }}
      displayFooter={false}
    />
  );
};

export default LegalProblemCodesListPrintView;
