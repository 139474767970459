import { cleanStringField } from 'js/utilities/strings';
import { roundToFixedPointString } from 'js/utilities/numbers';
import { isEmpty, floatPattern, integerPattern } from 'js/utilities/validation';

export const ESTATE_TRUSTEE_LP_CODES = ['011', '012'];
export const REAL_ESTATE_LP_CODES = ['021', '022'];
//see card 16895 for details
export const REFERRAL_LP_CODES = [
  '117',
  '118',
  '119',
  '121',
  '122',
  '123',
  '124',
  '125',
  '129',
  '039',
  '059',
  '061',
  '069',
  '079',
  '089',
];

export const legalProblemCodeValidationRules = {
  code: (data, key) => !isEmpty(data[key]) && data[key].length === 3,
  name: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  serviceFrequency: (data, key) => !isEmpty(data[key]),
  staffCloseOrVoid: (data, key) => !isEmpty(data[key]),
  staffCloseOrVoidIn: integerPattern,
  staffMaxPrepaidHours: (data, key) => {
    const value = data[key];
    const { staffMaxPrepaidHoursEnabled = false } = data;
    return integerPattern.test(value) || !staffMaxPrepaidHoursEnabled;
  },
  staffMaxPrepaidAmount: (data, key) => {
    const value = data[key];
    const { staffMaxPrepaidAmountEnabled = false } = data;
    return floatPattern.test(value) || !staffMaxPrepaidAmountEnabled;
  },
  staffPrepaidRate: floatPattern,
  staffParticipantRate: floatPattern,
  nonStaffCloseOrVoid: (data, key) => !isEmpty(data[key]),
  nonStaffCloseOrVoidIn: integerPattern,
  nonStaffMaxPrepaidHours: (data, key) => {
    const value = data[key];
    const { nonStaffMaxPrepaidHoursEnabled = false } = data;
    return integerPattern.test(value) || !nonStaffMaxPrepaidHoursEnabled;
  },
  nonStaffMaxPrepaidAmount: (data, key) => {
    const value = data[key];
    const { nonStaffMaxPrepaidAmountEnabled = false } = data;
    return floatPattern.test(value) || !nonStaffMaxPrepaidAmountEnabled;
  },
  nonStaffPrepaidRate: floatPattern,
  nonStaffParticipantRate: floatPattern,
  actualRate: floatPattern,
  forecast: floatPattern,
  csqThreshold: floatPattern,
  serviceProvided: (data, key) => !isEmpty(data[key]),
  benefitType: (data, key) => !isEmpty(data[key]),
  lawPanel: (data, key) => {
    const value = data[key];
    return integerPattern.test(value) && parseInt(value) > 0;
  },
  lawSubPanel: (data, key) => {
    const value = data[key];
    return integerPattern.test(value) && parseInt(value) > 0;
  },
};

export const legalProblemCodeRequiredFields = Object.keys(
  legalProblemCodeValidationRules
);

export const cleanLegalProblemCode = (legalProblemCode = {}) => {
  const {
    code,
    name,
    inactive,
    serviceFrequency,
    staffCloseOrVoid,
    staffCloseOrVoidIn,
    nonStaffCloseOrVoid,
    nonStaffCloseOrVoidIn,
    staffPrePopCheck,
    staffMaxPrepaidHoursCheck,
    staffMaxPrepaidHours,
    staffMaxPrepaidAmountCheck,
    staffMaxPrepaidAmount,
    staffPrepaidRate,
    staffParticipantRate,
    nonStaffPrePopCheck,
    nonStaffMaxPrepaidHoursCheck,
    nonStaffMaxPrepaidHours,
    nonStaffMaxPrepaidAmountCheck,
    nonStaffMaxPrepaidAmount,
    nonStaffPrepaidRate,
    nonStaffParticipantRate,
    actualRate,
    forecast,
    csqThreshold,
    serviceProvided,
    benefitType,
    lawPanel,
    lawSubPanel,
  } = legalProblemCode;

  return {
    code: cleanStringField(code),
    name: cleanStringField(name),
    inactive: inactive === true,
    serviceFrequency: roundToFixedPointString(serviceFrequency, 0),
    staffCloseOrVoid: cleanStringField(staffCloseOrVoid),
    staffCloseOrVoidIn: roundToFixedPointString(staffCloseOrVoidIn, 0),
    nonStaffCloseOrVoid: cleanStringField(nonStaffCloseOrVoid),
    nonStaffCloseOrVoidIn: roundToFixedPointString(nonStaffCloseOrVoidIn, 0),
    staffPrePopCheck: staffPrePopCheck === true,
    staffMaxPrepaidHoursCheck: staffMaxPrepaidHoursCheck === true,
    staffMaxPrepaidHours: roundToFixedPointString(staffMaxPrepaidHours, 2),
    staffMaxPrepaidAmountCheck: staffMaxPrepaidAmountCheck === true,
    staffMaxPrepaidAmount: roundToFixedPointString(staffMaxPrepaidAmount, 2),
    staffPrepaidRate: roundToFixedPointString(staffPrepaidRate, 2),
    staffParticipantRate: roundToFixedPointString(staffParticipantRate, 2),
    nonStaffPrePopCheck: nonStaffPrePopCheck === true,
    nonStaffMaxPrepaidHoursCheck: nonStaffMaxPrepaidHoursCheck === true,
    nonStaffMaxPrepaidHours: roundToFixedPointString(
      nonStaffMaxPrepaidHours,
      2
    ),
    nonStaffMaxPrepaidAmountCheck: nonStaffMaxPrepaidAmountCheck === true,
    nonStaffMaxPrepaidAmount: roundToFixedPointString(
      nonStaffMaxPrepaidAmount,
      2
    ),
    nonStaffPrepaidRate: roundToFixedPointString(nonStaffPrepaidRate, 2),
    nonStaffParticipantRate: roundToFixedPointString(
      nonStaffParticipantRate,
      2
    ),
    actualRate: roundToFixedPointString(actualRate, 2),
    forecast: roundToFixedPointString(forecast, 2),
    csqThreshold: roundToFixedPointString(csqThreshold, 2),
    serviceProvided: roundToFixedPointString(serviceProvided, 0),
    benefitType: cleanStringField(benefitType),
    lawPanel: lawPanel ? parseInt(lawPanel) : 0,
    lawSubPanel: lawSubPanel ? parseInt(lawSubPanel) : 0,
  };
};

export const cleanLegalProblemCodeFormOption = (option = {}) => {
  return Object.keys(option).reduce((nextOption, key) => {
    const value = cleanStringField(option[key]);
    return { ...nextOption, [key]: value };
  }, {});
};

export const cleanLegalProblemCodeFormOptionsArray = (options = []) => {
  return options.map(cleanLegalProblemCodeFormOption);
};

export const cleanLegalProblemCodeFormOptions = (json = {}) => {
  const { serviceProvided = [], benefitTypes = [], panelOptions = [] } = json;
  return {
    serviceProvided: cleanLegalProblemCodeFormOptionsArray(serviceProvided),
    benefitTypes: cleanLegalProblemCodeFormOptionsArray(benefitTypes),
    lawyerPanels: panelOptions,
  };
};

export const prepareLegalProblemCodeForApi = (legalProblemCode = {}) => {
  const ignoredFields = ['code'];
  const numericFields = [
    'serviceFrequency',
    'staffCloseOrVoidIn',
    'nonStaffCloseOrVoidIn',
    'staffMaxPrepaidHours',
    'staffMaxPrepaidAmount',
    'staffPrepaidRate',
    'staffParticipantRate',
    'nonStaffMaxPrepaidHours',
    'nonStaffMaxPrepaidAmount',
    'nonStaffPrepaidRate',
    'nonStaffParticipantRate',
    'actualRate',
    'forecast',
    'csqThreshold',
    'serviceProvided',
    'lawPanel',
    'lawSubPanel',
  ];

  return Object.keys(legalProblemCode).reduce((next, key) => {
    if (!ignoredFields.includes(key)) {
      const value = legalProblemCode[key];
      if (numericFields.includes(key)) {
        return { ...next, [key]: Number(value) };
      } else {
        return { ...next, [key]: value };
      }
    } else {
      return next;
    }
  }, {});
};
