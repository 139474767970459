import React from 'react';
import classnames from 'classnames';
import SVG from '../graphics/svg';

const Icon = (props) => {
  const { className, ...svgProps } = props;
  return <SVG className={classnames('icon', className)} {...svgProps} />;
};

Icon.propTypes = {
  ...SVG.propTypes,
};

export default Icon;
