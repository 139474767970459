import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { onChangeEffect, onKeydownEffect } from './effects';
import { toggleValues } from './functions';

const Toggle = (props) => {
  const {
    className,
    onLabel,
    onLabelActive = onLabel,
    offLabel,
    offLabelActive = offLabel,
    name,
    isOn,
    onChange,
    disabled,
  } = props;
  return (
    <div
      tabIndex="0"
      className={classnames(
        'toggle',
        { 'toggle-disabled': disabled },
        className
      )}
      onKeyDown={onKeydownEffect({ onChange, isOn })}
    >
      <label
        className={classnames('toggle-label-on', {
          'toggle-label-active': isOn,
        })}
      >
        <span>{isOn ? onLabelActive : onLabel}</span>
        <input
          type="radio"
          name={name}
          value={toggleValues.on}
          checked={isOn}
          onChange={onChangeEffect({ onChange })}
          disabled={disabled}
        />
      </label>
      <label
        className={classnames('toggle-label-off', {
          'toggle-label-active': !isOn,
        })}
      >
        <span>{!isOn ? offLabelActive : offLabel}</span>
        <input
          type="radio"
          name={name}
          value={toggleValues.off}
          checked={!isOn}
          onChange={onChangeEffect({ onChange })}
          disabled={disabled}
        />
      </label>
    </div>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  onLabel: PropTypes.node.isRequired,
  onLabelActive: PropTypes.node,
  offLabel: PropTypes.node.isRequired,
  offLabelActive: PropTypes.node,
  name: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Toggle;
