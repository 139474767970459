import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from 'js/components/menus/sort-menu';
import { reportsFoldersSortOptions } from './functions';

const ReportSortMenu = (props) => (
  <SortMenu
    className="folder-sort-menu"
    sortOptions={reportsFoldersSortOptions}
    {...props}
  />
);

ReportSortMenu.defaultProps = {
  field: 'folderName',
  direction: 'asc',
};

ReportSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ReportSortMenu;
