import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import config from 'js/config';
import {
  validateEffect,
  touchEffect,
  untouchEffect,
  isTouchedEffect,
} from './effects';

const ValidationContext = createContext({
  validate: () => {},
  touch: () => {},
  untouch: () => {},
  isTouched: () => {},
});

const ValidationProvider = (props) => {
  const { rules = {}, children } = props;
  const [touched, setTouched] = useState({});

  return (
    <ValidationContext.Provider
      value={{
        validate: validateEffect({ rules, debug: config.debug }),
        touch: touchEffect({ setTouched }),
        untouch: untouchEffect({ setTouched }),
        isTouched: isTouchedEffect({ touched }),
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

ValidationProvider.propTypes = {
  rules: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(RegExp),
      PropTypes.func,
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.func])
      ),
    ])
  ),
};

export { ValidationContext, ValidationProvider };
