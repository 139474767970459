import IMask from 'imask';
import { phoneNumberMaskPattern } from 'js/utilities/masks';

export const createPhoneNumberMaskEffect = (options = {}) => {
  const { phoneNumberMaskRef } = options;
  return () => {
    phoneNumberMaskRef.current = IMask.createMask({
      mask: phoneNumberMaskPattern,
    });
  };
};

export const onChangeEffect = (options = {}) => {
  const { phoneNumberMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = phoneNumberMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};
