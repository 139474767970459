import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faPhone,
  faPrint,
  faAt,
  faChevronRight,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  IndentedCard,
  FontAwesomeIcon,
  LabelValuePair,
  InputHelp,
} from 'js/components/design-system';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { ValidationContext } from 'js/components/validation';
import {
  onChangeInputEffect,
  onBlurInputEffect,
  onBeginEditingEffect,
  onCancelEditingEffect,
  onCommitEditingEffect,
} from './effects';

const headingProps = {
  className: 'law-firm-summary-heading',
};

const accessoryProps = {
  className: 'law-firm-summary-accessory',
};

const LawFirmSummary = (props) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const [
    barristerSolicitorEditedName,
    setBarristerSolicitorEditedName,
  ] = useState('');

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const validateAll = () =>
    validate({ firmName: barristerSolicitorEditedName }, ['firmName']);
  const touchAll = () => touch('firmName');
  const untouchAll = () => untouch('firmName');
  const { invalidFields = [] } = validateAll();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const {
    className,
    lawyer = {},
    isBarristerSolicitor,
    accessory,
    linkEnabled,
    isStaffLawyer,
    isEditingDisabled,
    onBeginEditing,
    onCancelEditing,
    onCommitEditing,
  } = props;

  const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;
  const { firmName: barristerSolicitorName = '' } = lawyerInfo;

  const {
    firmId,
    firmName,
    address,
    address2,
    city,
    province,
    postalCode,
    fax,
    primaryPhone,
    email,
  } = lawyerFirm;

  const barristerSolicitorDisplayName =
    barristerSolicitorName || t('components.LawFirmSummary.barristerSolicitor');

  const hasFirm = !!firmId && !!firmName;
  const linkTo = routePathReplacingParams(routePaths.lawFirm, { firmId });
  const canLink = linkEnabled && hasFirm && !isBarristerSolicitor;

  const hasAddress = !!address && !!city && !!province && !!postalCode;
  const hasContent = hasAddress || !!fax || !!primaryPhone || !!email;
  const shouldRenderContent = hasContent && !isBarristerSolicitor;

  const headingContent = (
    <h1>
      {isBarristerSolicitor || !hasFirm
        ? barristerSolicitorDisplayName
        : firmName}
    </h1>
  );

  const staffHeadingContent = (
    <h1>{t('components.LawFirmSummary.staffLawyer')}</h1>
  );

  return (
    <IndentedCard
      className={classnames(
        'law-firm-summary',
        { 'law-firm-summary-no-content': !shouldRenderContent },
        className
      )}
    >
      <div className="law-firm-summary-content">
        {isEditing && (
          <InputHelp
            className="law-firm-summary-editor"
            invalid={
              isTouched('firmName') && invalidFields.includes('firmName')
            }
            validationContent={t(
              'components.LawFirmSummary.barristerSolicitorNameValidation'
            )}
          >
            <input
              ref={inputRef}
              type="text"
              autoComplete="off"
              placeholder={t(
                'components.LawFirmSummary.barristerSolicitorNamePlaceholder'
              )}
              name="firmName"
              value={barristerSolicitorEditedName}
              onChange={onChangeInputEffect({
                setValue: setBarristerSolicitorEditedName,
              })}
              onBlur={onBlurInputEffect({ touch })}
            />
          </InputHelp>
        )}

        {!isEditing && (
          <Fragment>
            {canLink && (
              <Link to={linkTo} {...headingProps}>
                {headingContent}
              </Link>
            )}
            {!canLink && <div {...headingProps}>{headingContent}</div>}
            {isStaffLawyer && (
              <div {...headingProps}>{staffHeadingContent}</div>
            )}
            {shouldRenderContent && (
              <div className="law-firm-summary-body">
                <div>
                  {hasAddress && (
                    <div className="law-firm-summary-column">
                      <p>
                        {address} {address2}{' '}
                        {`${city}, ${province} ${postalCode}`}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  {fax && (
                    <LabelValuePair
                      label={<FontAwesomeIcon icon={faPrint} />}
                      value={fax}
                    />
                  )}
                  {primaryPhone && (
                    <LabelValuePair
                      label={<FontAwesomeIcon icon={faPhone} />}
                      value={primaryPhone}
                    />
                  )}
                  {email && (
                    <LabelValuePair
                      label={<FontAwesomeIcon icon={faAt} />}
                      value={
                        <a className="link" href={`mailto:${email}`}>
                          {email}
                        </a>
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>

      {isBarristerSolicitor && (
        <div className="law-firm-summary-edit-controls">
          {!isEditing && (
            <button
              className="button-link-appearance"
              type="button"
              disabled={isEditingDisabled}
              onClick={onBeginEditingEffect({
                lawyer,
                setBarristerSolicitorEditedName,
                setEditing,
                onBeginEditing,
              })}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
              {t('common.edit')}
            </button>
          )}
          {isEditing && (
            <Fragment>
              <button
                className="button-link-appearance"
                type="button"
                onClick={onCancelEditingEffect({
                  untouchAll,
                  setBarristerSolicitorEditedName,
                  setEditing,
                  onCancelEditing,
                })}
              >
                {t('common.cancel')}
              </button>
              <button
                className="button-link-appearance"
                type="button"
                onClick={onCommitEditingEffect({
                  validateAll,
                  touchAll,
                  untouchAll,
                  lawyer,
                  barristerSolicitorEditedName,
                  setBarristerSolicitorEditedName,
                  setEditing,
                  onCommitEditing,
                })}
              >
                {t('common.save')}
              </button>
            </Fragment>
          )}
        </div>
      )}

      {!isBarristerSolicitor && hasFirm && (
        <Fragment>
          {canLink && (
            <Link to={linkTo} {...accessoryProps}>
              {accessory}
            </Link>
          )}
          {!canLink && <div {...accessoryProps}>{accessory}</div>}
        </Fragment>
      )}
    </IndentedCard>
  );
};

LawFirmSummary.defaultProps = {
  accessory: <FontAwesomeIcon icon={faChevronRight} />,
  isBarristerSolicitor: false,
  linkEnabled: true,
  isStaffLawyer: false,
  isEditingDisabled: false,
};

LawFirmSummary.propTypes = {
  className: PropTypes.string,
  lawyer: PropTypes.shape({
    lawyerInfo: PropTypes.object,
    lawyerFirm: PropTypes.object,
  }).isRequired,
  isBarristerSolicitor: PropTypes.bool,
  linkEnabled: PropTypes.bool,
  accessory: PropTypes.node,
  isStaffLawyer: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
  onBeginEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onCommitEditing: PropTypes.func,
};

export default LawFirmSummary;
