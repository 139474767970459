import React, { Fragment } from 'react';
import { InactiveTag } from 'js/components/deactivate';
import { cleanStringField } from 'js/utilities/strings';

export const cleanLinkedUnion = (union = {}) => {
  return {
    unionNumber: cleanStringField(union.unionNumber),
    unionName: cleanStringField(union.unionName),
    locCity: cleanStringField(union.locCity),
    locInact: union.locInact === true,
  };
};

export const cleanLinkedUnions = (unions = []) => unions.map(cleanLinkedUnion);

export const comparableValueForUnionId = (unionId) => {
  const cleanValue = cleanStringField(unionId);
  const numberValue = Number(cleanValue);
  return !isNaN(numberValue) ? numberValue : cleanValue;
};

export const displayValueForUnionId = (unionId) => {
  return comparableValueForUnionId(unionId).toString();
};

/*
 * Note to future maintainers:
 *
 * unionIdSortPredicateAsc and unionIdSortPredicateDesc are intended to be
 * applied to a mix of numeric and non-numeric strings, because the union id
 * (sometimes referred to as the union code or number) does not have a consistent
 * format. Some unions have a numeric string id (e.g. '1056') while some have
 * a non-numeric id (e.g. 'CAW'). This makes the rules for sorting somewhat
 * complicated. To make things easier, numeric strings are converted to actual
 * numbers by comparableValueForUnionId before the sort is performed. This allows
 * the comparison to be based on type and value, rather than just value. The
 * code assumes that the compared will always be strings or numbers.
 *
 * If both values are strings, the lowercase values of the strings are compared:
 * - If the lowercase values are unequal, the rows are sorted based on the lowercase values.
 * - If the lowercase values are equal, the values are compared in their original case:
 * - - If the values are unequal, the rows are sorted based on these values.
 * - - If the values are equal, the rows are considered to be equal.
 * These rules ensure that strings are sorted in a way that makes sense to humans.
 * 'abc' comes before 'DEF' because 'a' is alphabetically before 'd'. (The machine
 * ordering rules prefer uppercase letters, so 'DEF' comes before 'abc').
 *
 * If both values are numbers, they are compared according to the normal sorting
 * rules for numbers.
 *
 * If one value is a string and the other is a number, the string is ordered above
 * or below the number depending on the sort order. The end result is that all strings
 * are ordered at the bottom of the list in ascending order, or the top of the list in
 * descending order.
 *
 * Given the follwing input:
 *
 * [
 *   { key: '1000' },
 *   { key: 'DEF' },
 *   { key: '0100' },
 *   { key: '2000' },
 *   { key: 'abc' },
 *   { key: '0000' },
 *   { key: 'def' },
 *   { key: 'ABC' },
 * ]
 *
 * The result of applying unionIdSortPredicateAsc will be:
 *
 * [
 *   { key: '0000' },
 *   { key: '0100' },
 *   { key: '1000' },
 *   { key: '2000' },
 *   { key: 'ABC' },
 *   { key: 'abc' },
 *   { key: 'DEF' },
 *   { key: 'def' }
 * ];
 *
 * The result of applying unionIdSortPredicateDesc will be:
 *
 * [
 *   { key: 'def' },
 *   { key: 'DEF' },
 *   { key: 'abc' },
 *   { key: 'ABC' },
 *   { key: '2000' },
 *   { key: '1000' },
 *   { key: '0100' },
 *   { key: '0000' }
 * ];
 */

export const unionIdSortPredicateAsc = (key, row1, row2) => {
  const value1 = comparableValueForUnionId(row1[key]);
  const value2 = comparableValueForUnionId(row2[key]);
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    const lowercase1 = value1.toLowerCase();
    const lowercase2 = value2.toLowerCase();
    if (lowercase1 !== lowercase2) {
      return lowercase1 > lowercase2 ? 1 : -1;
    } else if (value1 !== value2) {
      return value1 > value2 ? 1 : -1;
    } else {
      return 0;
    }
  } else if (typeof value1 === 'string' && typeof value2 !== 'string') {
    return 1;
  } else if (typeof value1 !== 'string' && typeof value2 === 'string') {
    return -1;
  } else if (value1 !== value2) {
    return value1 > value2 ? 1 : -1;
  } else {
    return 0;
  }
};

export const unionIdSortPredicateDesc = (key, row1, row2) => {
  const value1 = comparableValueForUnionId(row1[key]);
  const value2 = comparableValueForUnionId(row2[key]);
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    const lowercase1 = value1.toLowerCase();
    const lowercase2 = value2.toLowerCase();
    if (lowercase1 !== lowercase2) {
      return lowercase1 < lowercase2 ? 1 : -1;
    } else if (value1 !== value2) {
      return value1 < value2 ? 1 : -1;
    } else {
      return 0;
    }
  } else if (typeof value1 === 'string' && typeof value2 !== 'string') {
    return -1;
  } else if (typeof value1 !== 'string' && typeof value2 === 'string') {
    return 1;
  } else if (value1 !== value2) {
    return value1 < value2 ? 1 : -1;
  } else {
    return 0;
  }
};

export const unionIdSortPredicates = {
  asc: unionIdSortPredicateAsc,
  desc: unionIdSortPredicateDesc,
};

export const getLinkedUnionColumns = (t) => {
  const unionNumberColumn = {
    key: 'unionNumber',
    headerContent: t(
      'components.CompanyDetailsPage.LinkedUnionsList.columns.unionNumber'
    ),
    get: (key, row) => displayValueForUnionId(row[key]),
    sort: unionIdSortPredicates,
  };

  const unionNameColumn = {
    key: 'unionName',
    headerContent: t(
      'components.CompanyDetailsPage.LinkedUnionsList.columns.unionName'
    ),
    /* eslint-disable react/display-name */
    get: (key, row) => (
      <Fragment>
        <span>{row[key]}</span>
        <InactiveTag inactive={row.locInact} />
      </Fragment>
    ),
    /* eslint-enable react/display-name */
  };

  const locCityColumn = {
    key: 'locCity',
    headerContent: t(
      'components.CompanyDetailsPage.LinkedUnionsList.columns.locCity'
    ),
  };

  return [unionNumberColumn, unionNameColumn, locCityColumn];
};
