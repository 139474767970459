import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import SaveModal from 'js/components/save-modal';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  DeactivateModal,
  DeactivateToggle,
  InactiveTag,
} from 'js/components/deactivate';
import {
  ExpandingCard,
  ExpandingCardContent,
  InputHelp,
  InputLabel,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import { securityOptions } from 'js/utilities/staff';
import {
  onClickCancelEffect,
  onConfirmCancelEffect,
  onConfirmSaveEffect,
  saveStaffEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onSelectChangeEffect,
  onCheckboxChangeEffect,
  updateStaffInactiveEffect,
  setConfirmDeactivateEffect,
  onClickInviteEffect,
  onClickFormEffect,
} from './effects';
import { staffValidationRules, staffRequiredFields } from './functions';

const StaffCard = (props) => {
  const { t } = useTranslation();
  const {
    staffUser = {},
    officeOptions = {},
    expanded,
    expandEnabled,
    saving,
    onClickExpand,
    onClickInvite,
    onClickCancel,
    onClickSave,
    onChange,
    isNew = false,
  } = props;
  const {
    usrCode = '',
    usrEmail = '',
    usrFname = '',
    usrInact = false,
    usrLname = '',
    usrOfficecode = '',
    usrSecur = '',
    usrStlaw = false,
  } = staffUser;
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const { isValid, invalidFields = [] } = validate(
    staffUser,
    staffRequiredFields
  );
  const touchAll = () => touch(staffRequiredFields);
  const untouchAll = () => untouch(staffRequiredFields);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const secOptions = securityOptions(t);

  const discard = onConfirmCancelEffect({
    untouchAll,
    resetInteractionCount,
    setConfirmCancel,
    onClickCancel,
  });

  const save = saveStaffEffect({
    t,
    presentStyledBanner,
    saveEffect: onConfirmSaveEffect({
      t,
      isValid,
      touchAll,
      resetInteractionCount,
      setConfirmCancel,
      onClickSave,
    }),
  });

  return (
    <Fragment>
      <ExpandingCard
        className="staff-card"
        expanded={expanded}
        data-screen-only
      >
        <div className="staff-card-heading">
          {!expanded && (
            <Fragment>
              <div className="name-container">
                {usrCode && <h2>{usrCode}</h2>}
                <h2 className="inactive-tag-container">
                  {usrFname} {usrLname}
                  <InactiveTag invert inactive={usrInact} />
                </h2>
              </div>
              <div className="staff-card-actions">
                <button
                  className="button-link-appearance"
                  onClick={onClickExpand}
                  disabled={!expandEnabled}
                >
                  {t('components.StaffPage.StaffCard.expand')}
                </button>
              </div>
            </Fragment>
          )}
          {expanded && (
            <Fragment>
              <div className="staff-card-heading-inputs">
                <InputHelp
                  validationContent={t(
                    'components.StaffPage.StaffCard.usrCodeValidation'
                  )}
                  invalid={
                    isTouched('usrCode') && invalidFields.includes('usrCode')
                  }
                >
                  <input
                    type="text"
                    name="usrCode"
                    placeholder={t('components.StaffPage.StaffCard.usrCode')}
                    value={usrCode}
                    disabled={saving}
                    readOnly={!isNew}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                    onClick={incrementInteractionCount}
                  />
                </InputHelp>

                <InputHelp
                  validationContent={t(
                    'components.StaffPage.StaffCard.usrFnameValidation'
                  )}
                  invalid={
                    isTouched('usrFname') && invalidFields.includes('usrFname')
                  }
                >
                  <input
                    type="text"
                    name="usrFname"
                    placeholder={t('components.StaffPage.StaffCard.usrFname')}
                    value={usrFname}
                    disabled={saving}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                    onClick={incrementInteractionCount}
                  />
                </InputHelp>

                <InputHelp
                  validationContent={t(
                    'components.StaffPage.StaffCard.usrLnameValidation'
                  )}
                  invalid={
                    isTouched('usrLname') && invalidFields.includes('usrLname')
                  }
                >
                  <input
                    type="text"
                    name="usrLname"
                    placeholder={t('components.StaffPage.StaffCard.usrLname')}
                    value={usrLname}
                    disabled={saving}
                    onChange={onInputChangeEffect({ onChange })}
                    onBlur={onInputBlurEffect({ touch })}
                    onClick={incrementInteractionCount}
                  />
                </InputHelp>
              </div>

              <DeactivateToggle
                disabled={saving || isEmpty(usrCode) || isNew}
                isActive={!usrInact}
                onActivate={updateStaffInactiveEffect({
                  usrInact: usrInact,
                  onChange,
                  setConfirmDeactivate,
                })}
                onDeactivate={() => setConfirmDeactivate(true)}
              />

              <div className="staff-card-actions">
                <button
                  className="button-link-appearance"
                  disabled={saving}
                  onClick={onClickCancelEffect({
                    interactionCount,
                    setConfirmCancel,
                    onClickCancel,
                    untouchAll,
                  })}
                >
                  {t('common.cancel')}
                </button>
                <button
                  className="button-link-appearance"
                  disabled={saving}
                  onClick={save}
                >
                  {saving ? t('common.saving') : t('common.save')}
                </button>
              </div>
            </Fragment>
          )}
        </div>
        <div className="staff-card-body">
          <ExpandingCardContent className="staff-card-content">
            <form
              onSubmit={(e) => e.preventDefault()}
              onClick={onClickFormEffect({ incrementInteractionCount })}
            >
              <div className="form-row">
                <InputLabel
                  content={t('components.StaffPage.StaffCard.usrOfficecode')}
                >
                  <StyledSelect
                    name="usrOfficecode"
                    value={usrOfficecode}
                    disabled={saving}
                    onChange={onSelectChangeEffect({ onChange, touch })}
                    onBlur={onInputBlurEffect({ touch })}
                  >
                    <option key={''} value={''} disabled>
                      {t('common.defaultSelectPlaceholder')}
                    </option>
                    {officeOptions.map(({ officeCode, officeName }) => (
                      <option
                        key={officeCode}
                        value={officeCode}
                      >{`${officeCode} \u2013 ${officeName}`}</option>
                    ))}
                  </StyledSelect>
                </InputLabel>

                <InputHelp
                  validationContent={t(
                    'components.StaffPage.StaffCard.usrSecurValidation'
                  )}
                  invalid={
                    isTouched('usrSecur') && invalidFields.includes('usrSecur')
                  }
                >
                  <InputLabel
                    content={t('components.StaffPage.StaffCard.usrSecur')}
                  >
                    <StyledSelect
                      name="usrSecur"
                      value={usrSecur}
                      disabled={saving}
                      onChange={onSelectChangeEffect({ onChange, touch })}
                      onBlur={onInputBlurEffect({ touch })}
                    >
                      <option key={''} value={''} disabled>
                        {t('common.defaultSelectPlaceholder')}
                      </option>
                      {secOptions.map(({ value, label }) => (
                        <option
                          key={value}
                          value={value}
                        >{`${value} \u2013 ${label}`}</option>
                      ))}
                    </StyledSelect>
                  </InputLabel>
                </InputHelp>
              </div>
              <div className="form-row">
                <InputHelp>
                  <InputLabel
                    content={t('components.StaffPage.StaffCard.usrEmail')}
                  >
                    <input
                      type="text"
                      name="usrEmail"
                      value={usrEmail}
                      disabled={saving}
                      onChange={onSelectChangeEffect({ onChange, touch })}
                      onBlur={onInputBlurEffect({ touch })}
                    />
                  </InputLabel>
                </InputHelp>

                <div className="staff-card-form-row-subgroup">
                  <Checkbox
                    label={t('components.StaffPage.StaffCard.usrStlaw')}
                    name="usrStlaw"
                    checked={usrStlaw}
                    onChange={onCheckboxChangeEffect({ onChange })}
                  />

                  {usrCode && onClickInvite && (
                    <button
                      className="button button-highlight"
                      disabled={saving}
                      onClick={onClickInviteEffect({ usrCode, onClickInvite })}
                    >
                      {saving
                        ? t('components.StaffPage.StaffCard.inviting')
                        : t('components.StaffPage.StaffCard.invite')}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </ExpandingCardContent>
        </div>
        <DeactivateModal
          title={t('components.StaffPage.DeactivateModal.title')}
          message={t('components.StaffPage.DeactivateModal.message')}
          mounted={confirmDeactivate}
          onClose={setConfirmDeactivateEffect({
            confirm: false,
            setConfirmDeactivate,
          })}
          onSave={updateStaffInactiveEffect({
            usrInact: usrInact,
            onChange,
            setConfirmDeactivate,
          })}
        />
        <SaveModal
          mounted={confirmCancel}
          onClickCancel={() => setConfirmCancel(false)}
          onClickDiscard={discard}
          onClickProceed={save}
        />
        <NavigationSaveModal
          proceedAfter={async () => await save()}
          shouldBlockNavigation={() => interactionCount > 0 && !confirmCancel}
        />
      </ExpandingCard>

      <div data-print-managed data-print-only className="staff-print-card">
        <div className="form-row">
          <p className="bold">{t('components.StaffPage.StaffCard.usrCode')}:</p>
          <p>{usrCode}</p>
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrFname')}:
          </p>
          <p>{usrFname}</p>
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrEmail')}:
          </p>
          <p>{usrEmail}</p>
          <InactiveTag inactive={usrInact} />
        </div>
        <div className="form-row">
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrLname')}:
          </p>
          <p>{usrLname}</p>
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrOfficecode')}:
          </p>
          <p>{usrOfficecode}</p>
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrSecur')}:
          </p>
          <p>{usrSecur}</p>
          <p className="bold">
            {t('components.StaffPage.StaffCard.usrStlaw')}:
          </p>
          <p>{usrStlaw.toString()}</p>
        </div>
      </div>
    </Fragment>
  );
};

StaffCard.defaultProps = {
  expandEnabled: true,
  saving: false,
};

StaffCard.propTypes = {
  ...ExpandingCard.propTypes,
  staffUser: PropTypes.object.isRequired,
  officeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandEnabled: PropTypes.bool,
  saving: PropTypes.bool,
  onClickExpand: PropTypes.func,
  onClickInvite: PropTypes.func,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

const ValidatedStaffCard = (props) => {
  return (
    <ValidationProvider rules={staffValidationRules}>
      <StaffCard {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingStaffCard = (props) => (
  <InteractionTrackingProvider>
    <ValidatedStaffCard {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingStaffCard;
