import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import SearchHeader from 'js/components/headers/search-header';
import SearchSortMenu from 'js/components/menus/search-sort-menu';
import {
  OffsetLimitPagination,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import { FetchContext } from 'js/components/fetch';
import SearchFilter from './filter';
import SearchResults from './results';
import {
  onMountEffect,
  searchRequestEffect,
  previousSearchResultsEffect,
  nextSearchResultsEffect,
  searchResultsPageNumberEffect,
  searchResultsSortEffect,
  onApplyFiltersEffect,
} from './effects';
import { queryForSearchString } from './functions';
import EmptyState from 'js/components/controls/empty-state';

const Search = () => {
  const abortControllerRef = useRef(null);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const { search = '' } = useLocation();
  const {
    params: { model = '' },
  } = useRouteMatch();
  const history = useHistory();
  const query = queryForSearchString(model, search);
  const { firstName = '', lastName = '' } = query.params;
  const term = `${firstName} ${lastName}`.trim();

  const title = t('components.Search.title', {
    term,
    model: t(`components.Search.model.${model}`),
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    searchRequestEffect({
      t,
      api,
      model,
      query,
      setActive,
      setResults,
      setTotalResults,
      abortControllerRef,
    }),
    [model, query.search]
  );

  const pagination = (
    <OffsetLimitPagination
      className="search-pagination"
      offset={query.params.Offset}
      limit={query.params.Limit}
      totalResults={totalResults}
      onClickPrevious={previousSearchResultsEffect({ history, query })}
      onClickNext={nextSearchResultsEffect({ history, query })}
      onClickPageNumber={searchResultsPageNumberEffect({
        history,
        query,
      })}
    />
  );

  return (
    <Page
      className="search"
      title={title}
      header={<SearchHeader initialModel={model} initialQuery={query} />}
    >
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          <SearchFilter
            model={model}
            query={query}
            onApplyFilters={onApplyFiltersEffect({ history })}
          />
        </div>
        <div className="layout-column">
          {!active && (
            <div className="search-meta">
              <h1>
                {t('components.Search.heading', {
                  totalResults,
                  term,
                  model: t(`components.Search.model.${model}`),
                })}
              </h1>
              <SearchSortMenu
                field={query.params.SortBy}
                direction={query.params.Sort}
                onSelect={searchResultsSortEffect({ history, query })}
              />
            </div>
          )}
          {active && (
            <ActivityIndicatorCard message={t('components.Search.searching')} />
          )}
          {!active && results.length === 0 && (
            <EmptyState title={t('components.Search.emptyStateMessage')} />
          )}
          {!active && results.length > 0 && (
            <Fragment>
              {pagination}
              <SearchResults model={model} results={results} />
              {pagination}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Search;
