import React, { useState, useEffect, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import DatePickerInput from '../date-picker-input';
import {
  setStringValueEffect,
  setSafeDateValueEffect,
  showCalendarEffect,
  onKeyDownEffect,
  onChangeEffect,
  onBlurEffect,
} from './effects';
import { DATE_FORMAT } from './functions';

const DatePickerCustomInputRef = forwardRef((props, ref) => (
  <DatePickerInput {...props} inputRef={ref} readOnly />
));

DatePickerCustomInputRef.displayName = 'DatePickerCustomInputRef';

const DatePicker = (props) => {
  const {
    name,
    selected,
    onChange,
    onBlur,
    onKeyDown,
    onSelect,
    onClickOutside,
    disabled,
    readOnly,
  } = props;

  const [showCalendar, setShowCalendar] = useState(false);
  const [stringValue, setStringValue] = useState('');
  const [safeDateValue, setSafeDateValue] = useState(null);

  useEffect(setStringValueEffect({ selected, setStringValue }), [selected]);
  useEffect(setSafeDateValueEffect({ selected, setSafeDateValue }), [selected]);

  if (showCalendar) {
    return (
      <ReactDatePicker
        {...props}
        autoFocus
        strictParsing
        autoComplete={name}
        showPopperArrow={false}
        dateFormat={DATE_FORMAT}
        customInput={<DatePickerCustomInputRef />}
        selected={safeDateValue}
        onSelect={showCalendarEffect({
          setShowCalendar,
          nextShowCalendar: false,
          callback: onSelect,
        })}
        onClickOutside={showCalendarEffect({
          setShowCalendar,
          nextShowCalendar: false,
          callback: onClickOutside,
        })}
        onKeyDown={onKeyDownEffect({ setShowCalendar, onKeyDown })}
      />
    );
  } else {
    return (
      <DatePickerInput
        name={name}
        autoComplete={name}
        value={stringValue}
        disabled={disabled}
        readOnly={readOnly}
        calendarDisabled={disabled || readOnly}
        onChange={onChangeEffect({ setStringValue, onChange })}
        onBlur={onBlurEffect({ setStringValue, onChange, onBlur })}
        onClickCalendar={showCalendarEffect({
          setShowCalendar,
          nextShowCalendar: true,
        })}
      />
    );
  }
};

DatePicker.propTypes = {
  ...ReactDatePicker.propTypes,
};

export default DatePicker;
