import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { cleanProvinces } from 'js/utilities/regions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getProvincesEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setProvinces,
    getProvincesAbortControllerRef,
  } = options;
  return async () => {
    setActive(true);

    rotateAbortControllerRef(getProvincesAbortControllerRef);
    const { signal } = getProvincesAbortControllerRef.current;

    try {
      const { json } = await api.getJson(
        '/Regions/provinces',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ProvincesList.getProvincesRequestError'),
            },
          },
        }
      );

      const provinces = cleanProvinces(json);

      setProvinces(provinces);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};
