import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  OffsetLimitPagination,
  ActivityIndicatorCard,
  TableSortButton,
} from 'js/components/design-system';
import { FetchContext } from 'js/components/fetch';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import {
  onMountEffect,
  chequeRunRequestEffect,
  previousChequeRunResultsEffect,
  nextChequeRunResultsEffect,
  chequeRunResultsPageNumberEffect,
  chequeRunResultsSortEffect,
  chequeRunSearchEffect,
  chequeRunSearchResetEffect,
  chequeRunResetEffect,
  onClickPrintChequeEffect,
} from './effects';
import { queryForSearchString, getChequeRunTableColumns } from './functions';
import EmptyState from 'js/components/controls/empty-state';

const ChequeRunView = (props) => {
  const {
    className,
    timestamp,
    endpoint,
    onLoadCheques,
    onClickPrintCheque,
  } = props;
  const allowChequePrinting = typeof onClickPrintCheque === 'function';
  const abortControllerRef = useRef(null);
  const history = useHistory();
  const { search = '' } = useLocation();
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [cheques, setCheques] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const query = queryForSearchString(search);
  const columns = getChequeRunTableColumns(t);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  const requestChequeRun = chequeRunRequestEffect({
    t,
    api,
    endpoint,
    query,
    setActive,
    setCheques,
    setTotalResults,
    onLoadCheques,
    abortControllerRef,
  });

  useEffect(requestChequeRun, [query.search]);

  useEffect(chequeRunResetEffect({ requestChequeRun, history, query }), [
    timestamp,
  ]);

  return (
    <div className={classnames('cheque-run-view', className)}>
      {active && <ActivityIndicatorCard />}

      {!active && (
        <Fragment>
          <GeneralSearchInput
            placeholder={t('components.ChequeRunView.searchPlaceholder')}
            initialQuery={query}
            onSearch={chequeRunSearchEffect({ history, query })}
            onReset={chequeRunSearchResetEffect({ history, query })}
          />

          {cheques.length === 0 && (
            <EmptyState
              title={t('components.ChequeRunView.emptyStateMessage')}
            />
          )}

          {cheques.length > 0 && (
            <table>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.key}>
                      <TableSortButton
                        column={column}
                        sortColumnKey={query.params.Order}
                        sortDirection={query.params.Sort}
                        onChangeSort={chequeRunResultsSortEffect({
                          history,
                          query,
                        })}
                      />
                    </th>
                  ))}
                  {allowChequePrinting && <th>&nbsp;</th>}
                </tr>
              </thead>
              <tbody>
                {cheques.map((cheque) => {
                  const {
                    chequeCount,
                    amount,
                    clientFirstName,
                    clientLastName,
                    date,
                    payee,
                  } = cheque;
                  return (
                    <tr key={chequeCount}>
                      <td>{payee}</td>
                      <td>{amount}</td>
                      <td>{date}</td>
                      <td>{`${clientLastName}, ${clientFirstName}`}</td>
                      {allowChequePrinting && (
                        <td className="cheque-print-options">
                          <button
                            className="button-link-appearance"
                            onClick={onClickPrintChequeEffect({
                              cheque,
                              onClickPrintCheque,
                            })}
                          >
                            {t('common.print')}
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="cheque-run-pagination-container">
            <OffsetLimitPagination
              className="cheque-run-pagination"
              offset={query.params.Offset}
              limit={query.params.Limit}
              totalResults={totalResults}
              onClickPrevious={previousChequeRunResultsEffect({
                history,
                query,
              })}
              onClickNext={nextChequeRunResultsEffect({ history, query })}
              onClickPageNumber={chequeRunResultsPageNumberEffect({
                history,
                query,
              })}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

ChequeRunView.propTypes = {
  className: PropTypes.string,
  timestamp: PropTypes.number,
  endpoint: PropTypes.string.isRequired,
  onLoadCheques: PropTypes.func,
  onClickPrintCheque: PropTypes.func,
};

export default ChequeRunView;
