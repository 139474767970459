import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const lawFirmSortOptions = [
  {
    key: 'firmName-asc',
    field: 'firmName',
    direction: 'asc',
    i18nKey: 'components.LawFirmListPage.sortMenu.firmAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'firmName-desc',
    field: 'firmName',
    direction: 'desc',
    i18nKey: 'components.LawFirmListPage.sortMenu.firmDesc',
    icon: faSortAlphaDownAlt,
  },
];
