import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const STAFF_LAWYER_ACTIVE_STATUS = {
  ALL: 'All',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const queryFromModalQueryString = (modalQueryString = '') => {
  const decodeQueryString = decodeURIComponent(modalQueryString);

  const {
    Keyword = '',
    Order = 'lastName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
    ActiveStatus = STAFF_LAWYER_ACTIVE_STATUS.ACTIVE,
  } = paramsFromSearchString(decodeQueryString);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
    ActiveStatus,
  };

  return queryForParams(params);
};
