import React, { createContext, useState } from 'react';

const ClientContext = createContext({
  client: {},
  setClient: () => {},
  isLoadingClient: false,
  setLoadingClient: () => {},
  clientNotes: [],
  setClientNotes: () => {},
  isLoadingClientNotes: false,
  setLoadingClientNotes: () => {},
  hasOutstandingBalance: false,
  setHasOutstandingBalance: () => {},
});

const ClientProvider = (props) => {
  const [client, setClient] = useState({});
  const [isLoadingClient, setLoadingClient] = useState(false);
  const [clientNotes, setClientNotes] = useState([]);
  const [isLoadingClientNotes, setLoadingClientNotes] = useState(false);
  const [hasOutstandingBalance, setHasOutstandingBalance] = useState(false);

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
        isLoadingClient,
        setLoadingClient,
        clientNotes,
        setClientNotes,
        isLoadingClientNotes,
        setLoadingClientNotes,
        hasOutstandingBalance,
        setHasOutstandingBalance,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export { ClientContext, ClientProvider };
