import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PrintHeader } from 'js/components/print';

const LegalProblemCodePrintHeader = (props) => {
  const { className, title, heading, ...headerProps } = props;
  return (
    <PrintHeader
      className={classnames('legal-problem-print-header', className)}
      title={title}
      heading={heading}
      {...headerProps}
    />
  );
};

LegalProblemCodePrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default LegalProblemCodePrintHeader;
