import {
  formatDateFromISOString,
  parseDateFromISOString,
} from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { defaultSortPredicate } from 'js/utilities/sort';

export const cleanCaseNotesItem = (item = {}) => {
  const parsedDate = parseDateFromISOString(item.date);
  const timestamp = parsedDate ? parsedDate.getTime() : 0;
  return {
    ...item,
    text: cleanStringField(item.text),
    name: cleanStringField(item.name),
    date: formatDateFromISOString(item.date),
    timestamp,
  };
};

export const cleanCaseNotes = (caseNotes = []) => {
  return caseNotes.map(cleanCaseNotesItem);
};

export const queryForSearchString = (search = '') => {
  const {
    orderBy = 'timestamp',
    order = 'desc',
    ...otherParams
  } = paramsFromSearchString(search);

  const params = {
    ...otherParams,
    orderBy,
    order,
  };

  return queryForParams(params);
};

export const sortCaseNotes = (caseNotes = [], query = {}) => {
  const { params = {} } = query;
  const { orderBy, order } = params;

  if (orderBy && order) {
    return [...caseNotes].sort((item1, item2) =>
      defaultSortPredicate(orderBy, order, item1, item2)
    );
  } else {
    return caseNotes;
  }
};
