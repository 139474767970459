import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import ErrorPage from './error';

const Error500 = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      className="error-500"
      title={t('components.Error500.title')}
      heading={t('components.Error500.heading')}
      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
      content={
        <Fragment>
          <p>{t('components.Error500.messageLine1')}</p>
          <p>{t('components.Error500.messageLine2')}</p>
        </Fragment>
      }
    />
  );
};

export default Error500;
