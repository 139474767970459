import { namePattern, isEmpty } from 'js/utilities/validation';

export const staffValidationRules = {
  usrCode: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 4;
  },
  usrFname: namePattern,
  usrLname: namePattern,
  usrSecur: (data, key) => !isEmpty(data[key]),
};

export const staffRequiredFields = [
  'usrCode',
  'usrFname',
  'usrLname',
  'usrSecur',
];
