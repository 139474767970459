import { toggleValues } from './functions';

export const onChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(e, name, value === toggleValues.on);
  };
};

export const onKeydownEffect = (options = {}) => {
  const { onChange, isOn } = options;
  return (e) => {
    const { name } = e.target;
    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault();
        onChange(e, name, !isOn);
        break;
      default:
        break;
    }
  };
};
