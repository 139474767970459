import { cleanStringField } from 'js/utilities/strings';

export const cleanCaseOptions = (data) => {
  return Object.keys(data).reduce((next, key) => {
    return { ...next, [key]: cleanCaseOptionsList(data[key]) };
  }, {});
};

export const cleanOfficeOptions = (data) => {
  return data.map((office) => {
    return {
      officeName: cleanStringField(office.officeName),
      officeCode: cleanStringField(office.officeCode),
    };
  });
};

export const cleanCaseOptionsList = (data) => {
  return data.map(cleanCaseOptionsItem);
};

export const cleanCaseOptionsItem = (item) => {
  return Object.keys(item).reduce((next, key) => {
    return { ...next, [key]: cleanStringField(item[key]) };
  }, {});
};
