import {
  getEmptyOffice,
  cleanOffice,
  cleanOffices,
} from 'js/utilities/offices';
import { scheduleScrollToLastCardInList } from './functions';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const setEditingOfficeEffect = (options = {}) => {
  const {
    office,
    setEditingOffice,
    setEditingOfficeIndex,
    nextEditingOfficeIndex,
  } = options;
  return () => {
    setEditingOffice({ ...office });
    setEditingOfficeIndex(nextEditingOfficeIndex);
  };
};

export const resetEditingOfficeEffect = (options = {}) => {
  const { setEditingOffice, setEditingOfficeIndex } = options;
  return () => {
    setEditingOffice({});
    setEditingOfficeIndex(-1);
  };
};

export const setAddingOfficeEffect = (options = {}) => {
  const { setAddingOffice, listElementRef } = options;
  return () => {
    const { current: listElement } = listElementRef;
    if (listElement) {
      scheduleScrollToLastCardInList(listElement);
      setAddingOffice(getEmptyOffice());
    }
  };
};

export const resetAddingOfficeEffect = (options = {}) => {
  const { setAddingOffice } = options;
  return () => setAddingOffice({});
};

export const updateOfficeEffect = (options = {}) => {
  const { allowUpdate, setOffice } = options;
  return (name, value) => {
    if (allowUpdate) {
      setOffice((office) => ({ ...office, [name]: value }));
    }
  };
};

export const getOfficesEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setOffices,
    getOfficesAbortControllerRef,
  } = options;
  return async () => {
    setActive(true);

    rotateAbortControllerRef(getOfficesAbortControllerRef);
    const { signal } = getOfficesAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Admin/Offices?v=2',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.OfficesListPage.getRequestError'),
            },
          },
        }
      );

      setOffices(cleanOffices(json));
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const patchOfficeEffect = (options = {}) => {
  const {
    t,
    api,
    editingOffice,
    offices,
    setOffices,
    setSaving,
    onComplete,
  } = options;

  return async () => {
    setSaving(true);

    try {
      const { officeCode } = editingOffice;
      const url = `/Admin/Offices/${officeCode}`;
      const body = editingOffice;

      const { json = {} } = await api.patchJson(
        url,
        { body },
        {
          success: {
            context: {
              message: t('components.OfficesListPage.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.OfficesListPage.patchRequestError'),
            },
          },
        }
      );

      // Replace the edited office in the offices
      // array rather than reloading everything.
      const cleanedOffice = cleanOffice(json);
      const nextOffices = offices.map((office) => {
        const nextOffice =
          office.officeCode === cleanedOffice.officeCode
            ? cleanedOffice
            : office;
        return { ...nextOffice };
      });

      setOffices(nextOffices);
      setSaving(false);
      onComplete();
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };
};

export const postOfficeEffect = (options = {}) => {
  const {
    t,
    api,
    addingOffice,
    offices,
    setOffices,
    setSaving,
    onComplete,
  } = options;

  return async () => {
    setSaving(true);

    const url = `/Admin/Offices`;
    const body = addingOffice;

    try {
      const { json = {} } = await api.postJson(
        url,
        { body },
        {
          success: {
            context: {
              message: t('components.OfficesListPage.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.OfficesListPage.postRequestError'),
            },
          },
        }
      );

      // Append the added office to the offices
      // array rather than reloading everything.
      const cleanedOffice = cleanOffice(json);
      setOffices([...offices, cleanedOffice]);
      setSaving(false);
      onComplete();
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };
};
