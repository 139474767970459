import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';

const DeleteFolderModal = (props) => {
  const { className, mounted, onDismiss, onConfirm, isActive } = props;
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames('delete-folder-modal', className)}
      onClickCancel={onDismiss}
      titleText={t('components.ReportsFolderDetail.deleteFolderModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
    >
      <h2>{t('components.ReportsFolderDetail.deleteFolderModal.title')}</h2>
      <p>{t('components.ReportsFolderDetail.deleteFolderModal.text')}</p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isActive}
          onClick={onDismiss}
        >
          {t('common.cancel')}
        </button>
        <button
          className="button button-highlight"
          disabled={isActive}
          onClick={onConfirm}
        >
          {isActive ? t('common.deleting') : t('common.delete')}
        </button>
      </div>
    </Modal>
  );
};

DeleteFolderModal.defaultProps = {
  mounted: false,
};

DeleteFolderModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  isActive: PropTypes.bool,
};

export default DeleteFolderModal;
