import { debugMessages } from './debug';

export const getRecordEffect = (options = {}) => {
  const { dataRef, debug } = options;
  return (key, maxAge = 0) => {
    const data = dataRef.current;
    const record = data[key];

    if (record) {
      const { time } = record;
      if (maxAge === 0 || Date.now() - maxAge <= time) {
        if (typeof debug === 'function') {
          debug(debugMessages.getRecord, { [key]: record });
        }
        return record;
      } else {
        if (typeof debug === 'function') {
          debug(debugMessages.getRecordMaxAgeExceeded, { [key]: record });
        }
      }
    }

    if (typeof debug === 'function') {
      debug(debugMessages.getRecord, { [key]: undefined });
    }

    return undefined;
  };
};

export const setRecordEffect = (options = {}) => {
  const { dataRef, listenersRef, debug } = options;
  return (key, value) => {
    const data = dataRef.current;
    const hasPreviousRecord = data[key] !== undefined;
    const record = { value, time: Date.now() };
    data[key] = record;

    const action = hasPreviousRecord ? 'set' : 'create';
    const listeners = listenersRef.current[key] || [];
    listeners.forEach((listener) => {
      if (typeof listener === 'function') {
        listener(action, key, record);
      }
    });

    if (typeof debug === 'function') {
      debug(debugMessages.setRecord, { [key]: record });
    }
  };
};

export const deleteRecordEffect = (options = {}) => {
  const { dataRef, listenersRef, debug } = options;
  return (key) => {
    const data = dataRef.current;
    delete data[key];

    const listeners = listenersRef.current[key] || [];
    listeners.forEach((listener) => {
      if (typeof listener === 'function') {
        listener('delete', key);
      }
    });

    if (typeof debug === 'function') {
      debug(debugMessages.deleteRecord, { [key]: undefined });
    }
  };
};

export const deleteRecordsMatchingPatternEffect = (options = {}) => {
  const { dataRef, listenersRef, debug } = options;
  return (pattern) => {
    const data = dataRef.current;
    const allKeys = Object.keys(data).filter((key) => pattern.test(key));

    allKeys.forEach((key) => {
      delete data[key];
      const listeners = listenersRef.current[key] || [];
      listeners.forEach((listener) => {
        if (typeof listener === 'function') {
          listener('delete', key);
        }
      });
    });

    if (typeof debug === 'function') {
      debug(debugMessages.deleteRecords, { keys: allKeys });
    }
  };
};

export const deleteAllRecordsEffect = (options = {}) => {
  const { dataRef, listenersRef, debug } = options;
  return () => {
    const data = dataRef.current;
    const allKeys = Object.keys(data);

    allKeys.forEach((key) => {
      delete data[key];
      const listeners = listenersRef.current[key] || [];
      listeners.forEach((listener) => {
        if (typeof listener === 'function') {
          listener('delete', key);
        }
      });
    });

    if (typeof debug === 'function') {
      debug(debugMessages.deleteRecords, { keys: allKeys });
    }
  };
};

export const addListenerEffect = (options = {}) => {
  const { listenersRef, debug } = options;
  return (key, listener) => {
    const listeners = listenersRef.current[key] || [];
    if (!listeners.includes(listener)) {
      listenersRef.current[key] = [...listeners, listener];
      if (typeof debug === 'function') {
        debug(debugMessages.addListener, { [key]: listener });
      }
    }
  };
};

export const removeListenerEffect = (options = {}) => {
  const { listenersRef, debug } = options;
  return (key, listener) => {
    const listeners = listenersRef.current[key] || [];
    if (listeners.includes(listener)) {
      listenersRef.current[key] = listeners.filter((l) => l !== listener);
      if (typeof debug === 'function') {
        debug(debugMessages.removeListener, { [key]: listener });
      }
    }
  };
};
