import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { cleanRelease, createReleaseEndpoint } from './functions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { isEmpty } from 'js/utilities/validation';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getReleaseEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setRelease,
    scheduleId,
    effectiveDate,
    getAbortControllerRef,
    cache,
    isNew,
    isCopy,
    setEligibleCompanies,
  } = options;
  return async () => {
    let endpoint = createReleaseEndpoint(scheduleId, effectiveDate);
    const record = cache.get(endpoint);
    //we are fetching the release content and setting it in the cache so it can be used to update
    //and overwrite the release as needed. If the release is a copy then we are preventing it from being
    //overwritten during update by checking for that endpoint after getting the saved record
    if (!record) {
      if (isCopy) {
        endpoint = endpoint.concat('/clone');
      }

      setActive(true);

      rotateAbortControllerRef(getAbortControllerRef);
      const { signal } = getAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.FeeScheduleListPage.searchError'),
              },
            },
          }
        );
        const cleanReleaseData = cleanRelease(json);
        cache.set(endpoint, cleanReleaseData);
        setRelease(cleanReleaseData);

        if (isNew && typeof setEligibleCompanies === 'function') {
          //new fee schdules come with their own list of eiligble companies while existing use
          //a seperate endpoint to get theirs. if the fee schedule is new set the eligible companies here
          setEligibleCompanies(cleanReleaseData.eligibleCompanies);
        }
        setActive(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setActive(false);
        }
      }
    } else {
      //cached records are used to save and update the release before being sent to the api
      //while also being a backup for resetting changed content in modals if the user discards changes
      const { value } = record;
      setRelease(value);
      if (isNew && typeof setEligibleCompanies === 'function') {
        setEligibleCompanies(value.eligibleCompanies);
      }
      setActive(false);
    }
  };
};

export const getNewReleaseEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    setRelease,
    scheduleId,
    getNewAbortControllerRef,
    isNew,
    cache,
  } = options;
  return async () => {
    if (!isNew) {
      return;
    }
    const endpoint = `/FeeSchedule/${scheduleId}/release/new`;
    const record = cache.get(endpoint);
    if (!record) {
      setActive(true);
      rotateAbortControllerRef(getNewAbortControllerRef);
      const { signal } = getNewAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.FeeScheduleListPage.searchError'),
              },
            },
          }
        );

        const cleanReleaseData = cleanRelease(json);
        setRelease(cleanReleaseData);
        setActive(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setActive(false);
        }
      }
    } else {
      const { value } = record;
      setRelease(value);
      setActive(false);
    }
  };
};

export const getEligibleCompaniesEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    setEligibleCompanies,
    getEligbleAbortControllerRef,
    isNew,
  } = options;
  return async () => {
    if (isNew) {
      return;
    }
    const endpoint = `/FeeSchedule/${scheduleId}/release/${effectiveDate}/companies/eligible`;

    setActive(true);
    rotateAbortControllerRef(getEligbleAbortControllerRef);
    const { signal } = getEligbleAbortControllerRef.current;

    try {
      const { json = [] } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.FeeScheduleListPage.searchError'),
            },
          },
        }
      );
      setEligibleCompanies(json);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { setRelease } = options;
  return (e) => {
    if (typeof setRelease === 'function') {
      const { name, value } = e.target;
      setRelease(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange, touch } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
      touch(name);
    }
  };
};

export const onChangeLPCodesEffect = (options = {}) => {
  const { release, setRelease } = options;

  return (item) => {
    const releaseCopy = { ...release };
    const { legalProblemCodeSelectOptions = [] } = releaseCopy;
    const index = legalProblemCodeSelectOptions.findIndex(
      (activeCode) => activeCode.code === item.code
    );

    const nextLegalProblemCodeSelectOptions = [
      ...legalProblemCodeSelectOptions,
    ];
    nextLegalProblemCodeSelectOptions[index] = {
      ...nextLegalProblemCodeSelectOptions[index],
      selected: !item.selected,
    };
    setRelease({
      ...releaseCopy,
      legalProblemCodeSelectOptions: nextLegalProblemCodeSelectOptions,
    });
  };
};

export const onChangeCompaniesEffect = (options = {}) => {
  const {
    release,
    setRelease,
    eligibleCompanies = [],
    setEligibleCompanies,
  } = options;

  return (item) => {
    const releaseCopy = { ...release };
    const { associatedCompanies = [] } = releaseCopy;
    const index = associatedCompanies.findIndex(
      (activeCode) => activeCode.companyCode === item.companyCode
    );
    const eligibleIndex = eligibleCompanies.findIndex(
      (activeCode) => activeCode.companyCode === item.companyCode
    );

    const nextAssociatedCompanies = [...associatedCompanies];

    if (index >= 0) {
      nextAssociatedCompanies[index] = {
        ...nextAssociatedCompanies[index],
        selected: !item.selected,
      };
      setRelease({
        ...releaseCopy,
        associatedCompanies: nextAssociatedCompanies,
        eligibleCompanies: nextAssociatedCompanies,
      });
    } else {
      let nextEligible = [...eligibleCompanies];
      if (eligibleIndex >= 0) {
        nextEligible[eligibleIndex] = {
          ...nextEligible[eligibleIndex],
          selected: !item.selected,
        };
      }

      setRelease({
        ...releaseCopy,
        eligibleCompanies: nextEligible,
      });
      setEligibleCompanies(nextEligible);
    }
  };
};

export const closeModalEffect = (options = {}) => {
  const { setMounted, cache, scheduleId, effectiveDate, setRelease } = options;

  return () => {
    //if the changes are discarded, set the cached release and close the modal
    const record = cache.get(createReleaseEndpoint(scheduleId, effectiveDate));
    if (record) {
      setRelease(record.value);
    }
    setMounted(false);
  };
};

export const saveAndCloseModalEffect = (options = {}) => {
  const {
    setMounted,
    cache,
    scheduleId,
    effectiveDate,
    release,
    eligibleCompanies = [],
    setEligibleCompanies,
    setRelease,
  } = options;

  return () => {
    const key = createReleaseEndpoint(scheduleId, effectiveDate);
    if (typeof setRelease === 'function') {
      //once changes are approved they are set in the cache so users can navigate back and forth on the tabs, though in
      //the case of the companies page, we need to set the release as well to handle changes between modal and list
      const { associatedCompanies = [] } = release;
      //when closing the modal, im removing selected companies from the eligible list and moving them to the
      //associated list
      const nextAssociatedCompanies = eligibleCompanies.filter(
        (comp) => comp.selected
      );
      //make sure to remove selected companies from the eligble list so they dont keep
      //getting added on close
      const nextEligibleCompanies = eligibleCompanies.filter(
        (comp) => !comp.selected
      );

      const nextRelease = {
        ...release,
        associatedCompanies: [
          ...nextAssociatedCompanies,
          ...associatedCompanies,
        ],
      };
      setRelease(nextRelease);
      setEligibleCompanies(nextEligibleCompanies);
      cache.set(key, nextRelease);
    } else {
      //once changes are approved they are set in the cache so users can navigate back and forth on the tabs
      cache.set(key, release);
    }
    setMounted(false);
  };
};

export const completeNewReleaseEffect = (options = {}) => {
  //creates a new fee schedule release for a schedule in the system and returns the newly created record
  const {
    t,
    api,
    setActive,
    scheduleId,
    postAbortControllerRef,
    release,
    setRedirectPath,
    isCopy,
    cache,
    presentStyledBanner,
  } = options;
  return async () => {
    let endpoint = `/FeeSchedule/${scheduleId}/release/new`;
    if (isCopy) {
      endpoint = endpoint.concat('?AutoArchiveActiveRelease=true');
    }
    setActive(true);
    rotateAbortControllerRef(postAbortControllerRef);
    const { signal } = postAbortControllerRef.current;
    let body = { ...release };
    try {
      const { json } = await api.postJson(
        endpoint,
        { signal, body },
        {
          success: {
            context: {
              message: t('components.ReleasePage.lpcPostSuccess'),
            },
          },
          error: {
            bypass: true,
          },
        }
      );

      const { effectiveDate, schedule } = json;
      const nextPagePath = routePathReplacingParams(routePaths.releaseLPCodes, {
        scheduleId: schedule.id,
        effectiveDate: effectiveDate,
      });

      cache.delete(endpoint);
      setRedirectPath(nextPagePath);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        const { body } = error;
        presentStyledBanner('error', {
          content: body.error,
        });
        setActive(false);
      }
    }
  };
};

export const noEligibleCompaniesBannerEffect = (options = {}) => {
  const {
    t,
    presentStyledBanner,
    dismissBanner,
    release,
    active,
    isArchived,
  } = options;
  return () => {
    if (active || isArchived) {
      return;
    }
    const { eligibleCompanies = [], associatedCompanies = [] } = release;
    let bannerId = '';
    if (isEmpty(eligibleCompanies) && isEmpty(associatedCompanies)) {
      bannerId = presentStyledBanner('warning', {
        className: 'fee-schedule-balance-banner',
        timeout: 0,
        progress: false,
        animated: false,
        content: t('components.ReleasePage.noEligibleCompaniesBannerMessage'),
      });
    }

    return () => {
      if (bannerId) {
        dismissBanner(bannerId, false);
      }
    };
  };
};

export const saveReleaseEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    scheduleId,
    effectiveDate,
    postAbortControllerRef,
    release,
    onComplete,
    cache,
    presentStyledBanner,
  } = options;
  return async () => {
    const endpoint = createReleaseEndpoint(scheduleId, effectiveDate);
    const { associatedCompanies = [] } = release;
    const filteredCompanies = associatedCompanies.filter(
      (company) => company.selected === true
    );

    setActive(true);
    rotateAbortControllerRef(postAbortControllerRef);
    const { signal } = postAbortControllerRef.current;
    const body = { ...release, associatedCompanies: filteredCompanies };

    try {
      await api.postJson(
        endpoint,
        { signal, body },
        {
          success: {
            context: {
              message: t('components.ReleasePage.postScheduleSuccess'),
            },
          },
          error: {
            bypass: true,
          },
        }
      );

      cache.delete(createReleaseEndpoint(scheduleId, effectiveDate));
      if (typeof onComplete === 'function') {
        onComplete();
      }
      setActive(false);
    } catch (error) {
      const { body } = error;
      if (!isAbortError(error)) {
        presentStyledBanner('error', {
          content: body.error,
        });
        setActive(false);
      }
    }
  };
};
