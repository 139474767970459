import React, { useContext } from 'react';
import { ValidationContext } from 'js/components/validation';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  InputLabel,
  Card,
  InputHelp,
} from 'js/components/design-system';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';
import { onInputChangeEffect, onInputBlurEffect } from './effects';
import { getRequiredFields } from './functions';
import PhoneInput from 'js/components/controls/phone-input';

const LawFirmForm = (props) => {
  const { t } = useTranslation();
  const { formData = {}, onChange } = props;
  const provinces = provinceOptions(t);
  const { validate, touch, isTouched } = useContext(ValidationContext);

  const {
    firmName = '',
    address = '',
    address2 = '',
    city = '',
    email = '',
    fax = '',
    postalCode = '',
    primaryPhone = '',
    province = '',
    secondaryPhone = '',
  } = formData;

  const requiredFields = getRequiredFields();
  const { invalidFields = [] } = validate(formData, requiredFields);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className="law-firm-form">
      <h2>{t('components.LawFirmDetailsPage.formTitle')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.firmNameValidation'
          )}
          invalid={isTouched('firmName') && invalidFields.includes('firmName')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.firmName')}>
            <input
              type="text"
              name="firmName"
              value={firmName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.primaryPhoneValidation'
          )}
          invalid={
            isTouched('primaryPhone') && invalidFields.includes('primaryPhone')
          }
        >
          <InputLabel content={t('components.LawFirmDetailsPage.primaryPhone')}>
            <PhoneInput
              name="primaryPhone"
              value={primaryPhone}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.secondaryPhoneValidation'
          )}
          invalid={
            isTouched('secondaryPhone') &&
            invalidFields.includes('secondaryPhone')
          }
        >
          <InputLabel
            content={t('components.LawFirmDetailsPage.secondaryPhone')}
          >
            <PhoneInput
              name="secondaryPhone"
              value={secondaryPhone}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputLabel content={t('components.LawFirmDetailsPage.fax')}>
          <PhoneInput
            name="fax"
            value={fax}
            {...inputEventHandlers}
            onChange={(name, value) => onChange(name, value)}
          />
        </InputLabel>

        <InputHelp
          validationContent={t('components.LawFirmDetailsPage.emailValidation')}
          invalid={isTouched('email') && invalidFields.includes('email')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.email')}>
            <input
              type="text"
              name="email"
              value={email}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        {/*SECOND ROW*/}
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.addressValidation'
          )}
          invalid={isTouched('address') && invalidFields.includes('address')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.address')}>
            <input
              type="text"
              name="address"
              value={address}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.address2Validation'
          )}
          invalid={isTouched('address2') && invalidFields.includes('address2')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.address2')}>
            <input
              name="address2"
              type="text"
              value={address2}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.LawFirmDetailsPage.cityValidation')}
          invalid={isTouched('city') && invalidFields.includes('city')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.city')}>
            <input
              name="city"
              type="text"
              value={city}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.provinceValidation'
          )}
          invalid={isTouched('province') && invalidFields.includes('province')}
        >
          <InputLabel content={t('components.LawFirmDetailsPage.province')}>
            <StyledSelect
              value={province}
              name="province"
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {provinces.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawFirmDetailsPage.postalCodeValidation'
          )}
          invalid={
            isTouched('postalCode') && invalidFields.includes('postalCode')
          }
        >
          <InputLabel content={t('components.LawFirmDetailsPage.postalCode')}>
            <PostalCodeInput
              name="postalCode"
              value={postalCode}
              onChange={onPostalCodeInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
      </div>
    </Card>
  );
};

LawFirmForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
};

export default LawFirmForm;
