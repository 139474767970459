import { proceduresBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const CaseReassignmentBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;

  return [
    ...proceduresBreadcrumbsHistory(t),
    {
      content: t('components.CaseReassignment.breadcrumbs.caseReassignment'),
      path: currentPath,
    },
  ];
};

export const focusInputRef = (inputRef) => {
  const { current: input } = inputRef;
  if (input) {
    input.focus();
  }
};
