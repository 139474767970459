import React, { useState, useEffect, useContext, useRef } from 'react';
import CSQPrintLayout from './csq-print-layout';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import Page from 'js/components/page';
import { getCSQDataEffect, printViewEffect } from './effects';
import { CSQ_DEFAULT_LANGUAGE } from './language';

const PrintSingleCSQ = () => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [CSQData, setCSQData] = useState({});
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { params = {} } = useRouteMatch();
  const { cif, language = CSQ_DEFAULT_LANGUAGE } = params;
  const getCsqAbortControllerRef = useRef(null);

  const getCSQDataEffectOptions = {
    api,
    cif,
    setCSQData,
    setActive,
    setError,
    getCsqAbortControllerRef,
  };

  useEffect(getCSQDataEffect(getCSQDataEffectOptions), []);
  useEffect(printViewEffect({ CSQData, active }), [
    CSQData?.caseInfo?.caseInformationResponse?.cif,
    active,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.csqData', {
            message: error.message,
          })}
        </p>
      )}
      {!active && !error && (
        <CSQPrintLayout csqData={CSQData} language={language} />
      )}
    </Page>
  );
};

export default PrintSingleCSQ;
