import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { clientApiKey, cleanNestedClient } from 'js/utilities/clients';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { isDependentInfo } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const updateClientInformationEffect = (options = {}) => {
  const { client, setClient, group } = options;
  return (name, value) => {
    const groupData = client[group];
    if (groupData) {
      const nextGroupData = { ...groupData, [name]: value };
      // Reset the local if the company changes:
      if (group === 'clientEmployment' && name === 'company') {
        nextGroupData.local = {};
      }
      const nextClient = { ...client, [group]: nextGroupData };
      setClient(nextClient);
    }
  };
};

export const patchClientInformationEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    prompt,
    id,
    clientCode,
    setActive,
    isValid,
    client,
    setClient,
    hasOutstandingBalance,
    setHasOutstandingBalance,
    touchAllFields,
    resetInteractionCount,
    patchClientAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    if (isDependentInfo(client)) {
      try {
        await prompt(client, 'dependent.age');
      } catch (error) {
        // The prompt was cancelled
        return;
      }
    }

    rotateAbortControllerRef(patchClientAbortControllerRef);
    const { signal } = patchClientAbortControllerRef.current;

    setActive(true);
    const url = clientApiKey(id, clientCode);
    try {
      const { json = {} } = await api.patchJson(
        url,
        { body: client, signal },
        {
          success: {
            context: {
              message: t('components.ClientInformation.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ClientInformation.patchRequestError'),
            },
          },
        }
      );

      const nextClient = cleanNestedClient(json);
      const {
        clientAccount: { outstandingAccount = false },
      } = nextClient;

      if (outstandingAccount !== hasOutstandingBalance) {
        // If the outstanding balance flag has changed value, we must
        // invalidate the cache for all clients with  the same client id:
        const pattern = new RegExp(`^/Client/${id}/\\d+/?$`);
        cache.deleteMatchingPattern(pattern);
      }

      cache.set(url, nextClient);
      setClient(nextClient);
      setHasOutstandingBalance(outstandingAccount);
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const postClientInformationEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    prompt,
    setActive,
    isValid,
    isNewDependent,
    client,
    touchAllFields,
    resetInteractionCount,
    setRedirectPath,
    postClientAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    if (isDependentInfo(client)) {
      try {
        await prompt(client, 'dependent.age');
      } catch (error) {
        // The prompt was cancelled
        return;
      }
    }

    setActive(true);

    const {
      clientAddress = {},
      clientEmployment = {},
      clientPersonal = {},
    } = client;

    try {
      const { clientId, clientStatus } = clientPersonal;
      const spouseStatuses = ['3', '4'];

      // When creating a spouse, first check that the primary client
      // doesn't already have an active spouse. If they do, prompt to
      // continue with deactivation of the existing spouse and creation
      // of the new one.
      if (isNewDependent && spouseStatuses.includes(clientStatus)) {
        rotateAbortControllerRef(postClientAbortControllerRef);
        const {
          signal: spouseExistsSignal,
        } = postClientAbortControllerRef.current;

        const { json: spouseJson = {} } = await api.getJson(
          `/Client/${clientId}/00/dependents/spouse/exists`,
          { signal: spouseExistsSignal },
          {
            success: {
              bypass: true,
            },
            error: {
              context: {
                message: t(
                  'components.ClientInformation.spouseExistsRequestError'
                ),
              },
            },
          }
        );

        const { exists: spouseExists, spouse } = spouseJson;

        if (spouse) {
          // Prompt will throw if cancelled.
          await prompt(
            { spouseExists },
            'dependent.existingSpouseDeactivation'
          );

          // Deactivate the existing spouse:
          const { clientPersonal = {} } = cleanNestedClient(spouse);
          const { clientId, clientCode } = clientPersonal;
          const spouseUrl = clientApiKey(clientId, clientCode);
          const deactivateUrl = `${spouseUrl}/active`;
          const deactivateBody = { clientPersonal: { clientInactive: true } };

          // Rotate the used abort controller before making the PATCH request:
          rotateAbortControllerRef(postClientAbortControllerRef);
          const {
            signal: deactivateSignal,
          } = postClientAbortControllerRef.current;

          await api.postJson(
            deactivateUrl,
            { body: deactivateBody, signal: deactivateSignal },
            {
              success: {
                bypass: true,
              },
              error: {
                context: {
                  message: t(
                    'components.ClientInformation.deactivateSpouseRequestError'
                  ),
                },
              },
            }
          );

          // Delete the deactivated spouse in the local cache:
          cache.delete(spouseUrl);
        } else {
          // Fallback in case the `spouse` object is null or undefined.
          // This case will require that the user deactivates the existing
          // spouse manually.
          // Prompt will throw if cancelled.
          await prompt({ spouseExists }, 'dependent.existingSpouse');
        }
      }
      // End existing spouse check and deactivation.

      let url = '';
      let payload = {};

      if (isNewDependent) {
        const { clientId } = clientPersonal;
        url = `/Client/${clientId}/Dependents`;
        payload = { clientPersonal, clientAddress };
      } else {
        url = '/Client';
        payload = {
          clientPersonal,
          clientAddress,
          clientEmployment,
          clientAccount: {
            outstandingAccount: false,
          },
        };
      }

      // Check for potential duplicate primary clients:
      if (!isNewDependent) {
        // Rotate the used abort controller before making the client duplicate request:
        rotateAbortControllerRef(postClientAbortControllerRef);
        const {
          signal: duplicateClientSignal,
        } = postClientAbortControllerRef.current;

        const { json: duplicateClientJson = {} } = await api.postJson(
          '/Client/check/duplicate',
          { body: payload, signal: duplicateClientSignal },
          {
            success: {
              bypass: true,
            },
            error: {
              context: {
                message: t(
                  'components.ClientInformation.duplicateClientRequestError'
                ),
              },
            },
          }
        );

        const { exists: duplicateExists } = duplicateClientJson;

        // Prompt will throw if cancelled.
        await prompt({ duplicateExists }, 'client.duplicate');
      }
      // End check for potential duplicate clients.
      // Create the new client:

      // Rotate the used abort controller before making the POST request:
      rotateAbortControllerRef(postClientAbortControllerRef);
      const { signal: clientSignal } = postClientAbortControllerRef.current;

      const { json = {} } = await api.postJson(
        url,
        { body: payload, signal: clientSignal },
        {
          success: {
            context: {
              message: t('components.ClientInformation.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ClientInformation.postRequestError'),
            },
          },
        }
      );

      const newClient = cleanNestedClient(json);
      const { clientPersonal: nextClientPersonal } = newClient;
      const {
        clientCode: nextClientCode,
        clientId: nextClientId,
      } = nextClientPersonal;
      const path = routePathReplacingParams(routePaths.clientInformation, {
        id: nextClientId,
        clientCode: nextClientCode,
      });

      setActive(false);
      resetInteractionCount();
      setRedirectPath(path);
    } catch (error) {
      // Swallow undefined errors thrown by prompts
      if (error && !isAbortError(error)) {
        setActive(false);
        throw error;
      } else if (!error) {
        setActive(false);
      }
    }
  };
};

export const saveClientEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error && error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};
