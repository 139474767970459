import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { UnionSortOptions } from './functions';

const UnionSortMenu = (props) => (
  <SortMenu
    className="union-sort-menu"
    sortOptions={UnionSortOptions}
    {...props}
  />
);

UnionSortMenu.defaultProps = {
  field: 'name',
  direction: 'asc',
};

UnionSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default UnionSortMenu;
