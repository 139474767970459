import { cleanStringField } from 'js/utilities/strings';
import {
  officeCodePattern,
  isEmpty,
  validatePostalCode,
  validatePhoneNumber,
  isOneOf,
} from 'js/utilities/validation';

export const OFFICE_TYPES = {
  HEAD: 'H',
  SATELLITE: 'S',
};

export const getEmptyOffice = () => ({
  officeCode: '',
  officeType: '',
  officeName: '',
  officeAddr1: '',
  officeAddr2: '',
  officeCity: '',
  officeProv: '',
  officePcode: '',
  officePhone1: '',
  officePhone2: '',
  officeFax: '',
  officeInact: false,
});

export const cleanOffice = (office = {}) => {
  const {
    officeCode,
    officeType,
    officeName,
    officeAddr1,
    officeAddr2,
    officeCity,
    officeProv,
    officePcode,
    officePhone1,
    officePhone2,
    officeFax,
    officeInact,
  } = office;

  return {
    officeCode: cleanStringField(officeCode),
    officeType: cleanStringField(officeType),
    officeName: cleanStringField(officeName),
    officeAddr1: cleanStringField(officeAddr1),
    officeAddr2: cleanStringField(officeAddr2),
    officeCity: cleanStringField(officeCity),
    officeProv: cleanStringField(officeProv),
    officePcode: cleanStringField(officePcode),
    officePhone1: cleanStringField(officePhone1),
    officePhone2: cleanStringField(officePhone2),
    officeFax: cleanStringField(officeFax),
    officeInact: officeInact === true,
  };
};

export const cleanOffices = (offices = []) => offices.map(cleanOffice);

export const officeValidationRules = {
  officeCode: officeCodePattern,
  officeName: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  officeType: (data, key) => isOneOf(data[key], Object.values(OFFICE_TYPES)),
  officeAddr1: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  officeAddr2: (data, key) => {
    const value = data[key];
    return isEmpty(value) || value.length <= 30;
  },
  officeCity: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 20;
  },
  officeProv: (data, key) => !isEmpty(data[key]),
  officePcode: (data, key) => validatePostalCode(data[key], true),
  officePhone1: (data, key) => validatePhoneNumber(data[key], true),
  officePhone2: (data, key) => validatePhoneNumber(data[key], false),
  officeFax: (data, key) => validatePhoneNumber(data[key], false),
};

export const officeRequiredFields = Object.keys(officeValidationRules);
