import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { legalProblemCodesBreadcrumbsHistory } from './functions';

const LegalProblemCodesBreadcrumbs = () => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="legal-problem-codes-breadcrumbs"
      history={legalProblemCodesBreadcrumbsHistory(t)}
    />
  );
};

export default LegalProblemCodesBreadcrumbs;
