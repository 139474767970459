import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';

const AdminToolsBreadcrumbs = () => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="admin-tools-breadcrumbs"
      history={adminToolsBreadcrumbsHistory(t)}
    />
  );
};

export default AdminToolsBreadcrumbs;
