export const clickFirstLinkInElement = (element) => {
  const a = element.querySelector('a');
  if (a) {
    a.click();
  }
};

export const isLastListItem = (element) => {
  const tagName = element.tagName.toLowerCase();
  const lastItem = element.parentNode.querySelector('li:last-of-type');
  return tagName === 'li' && element === lastItem;
};
