import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { ActivityIndicatorCard } from 'js/components/design-system';
import Modal from 'js/components/modal';
import ButtonInput from 'js/components/controls/button-input';
import { nl2br } from 'js/utilities/strings';
import {
  unmountEffect,
  createDateMaskEffect,
  closeModalEffect,
  onChangeDateEffect,
  onBlurDateEffect,
  onResetDateEffect,
  onConfirmDateEffect,
} from './effects';
import {
  DATE_INPUT_NAME,
  getDefaultDate,
  searchStringContainsAutoVoidParam,
  autoVoidValidationRules,
  autoVoidRequiredFields,
} from './functions';

const AutoVoidModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const [date, setDate] = useState(getDefaultDate());
  const [isActive, setActive] = useState(false);
  const abortControllerRef = useRef(null);
  const dateMaskRef = useRef(null);

  const cache = useContext(DataContext);
  const { api = {} } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const validateAll = (data) => validate(data, autoVoidRequiredFields);
  const touchAll = () => touch(autoVoidRequiredFields);
  const { isFieldValid } = validateAll({ date });

  useEffect(createDateMaskEffect({ dateMaskRef }), []);

  useEffect(
    unmountEffect({ abortControllerRefs: [abortControllerRef], setActive }),
    []
  );

  const onClose = closeModalEffect({
    abortControllerRefs: [abortControllerRef],
    setDate,
    untouch,
    search,
    history,
  });

  return (
    <Modal
      className="auto-void-modal"
      cancelButtonText={t(`common.cancel`)}
      onClickCancel={onClose}
      titleText={t(`components.AutoVoidModal.title`)}
      mounted={searchStringContainsAutoVoidParam(search)}
      allowCancel={!isActive}
      underlayClickExits={false}
      verticallyCenter
    >
      {isActive && (
        <ActivityIndicatorCard
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: nl2br(t('components.AutoVoidModal.activeMessage')),
              }}
            />
          }
        />
      )}
      {!isActive && (
        <Fragment>
          <h2>{t(`components.AutoVoidModal.title`)}</h2>
          <ButtonInput
            value={date}
            inputName={DATE_INPUT_NAME}
            inputLabel={t('components.AutoVoidModal.dateLabel')}
            placeholderText={t('components.AutoVoidModal.datePlaceholder')}
            errorText={t('components.AutoVoidModal.dateValidation')}
            buttonText={t('components.AutoVoidModal.confirmButton')}
            isValid={
              !isTouched(DATE_INPUT_NAME) || isFieldValid(DATE_INPUT_NAME)
            }
            onChange={onChangeDateEffect({ dateMaskRef, setDate })}
            onBlur={onBlurDateEffect({ touch })}
            onReset={onResetDateEffect({ setDate, untouch })}
            onConfirm={onConfirmDateEffect({
              t,
              api,
              cache,
              validateAll,
              touchAll,
              date,
              setActive,
              onClose,
              abortControllerRef,
            })}
          />
        </Fragment>
      )}
    </Modal>
  );
};

const ValidatedAutoVoidModal = (props) => (
  <ValidationProvider rules={autoVoidValidationRules}>
    <AutoVoidModal {...props} />
  </ValidationProvider>
);

export default ValidatedAutoVoidModal;
