import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryForReportsFolders = (search = '') => {
  const {
    Keyword = '',
    Order = 'name',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};
