import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  primaryClientStatusOptions,
  clientStatusOptionsForPrimaryClientStatus,
} from 'js/hardcoded-data/form-option-data';
import { ValidationContext } from 'js/components/validation';
import {
  Card,
  InputLabel,
  InputHelp,
  StyledSelect,
} from 'js/components/design-system';
import { caseCorrectionRequiredFields } from 'js/utilities/cases';
import { isEmpty } from 'js/utilities/validation';
import { onInputChangeEffect, onInputBlurEffect } from './effects';

const Spacer = () => <div />;

const CaseCorrectionForm = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);

  const { className, formData = {}, onChange } = props;

  const { clientStatus = '', primaryClientStatus = '' } = formData;

  const { invalidFields = [] } = validate(
    formData,
    caseCorrectionRequiredFields
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const primaryClientStatuses = primaryClientStatusOptions(t);
  const clientStatuses = clientStatusOptionsForPrimaryClientStatus(
    t,
    primaryClientStatus
  );

  return (
    <Card className={classnames('case-correction-form', className)}>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseCorrection.CaseCorrectionForm.primaryClientStatusValidation'
          )}
          invalid={
            isTouched('primaryClientStatus') &&
            invalidFields.includes('primaryClientStatus')
          }
        >
          <InputLabel
            content={t(
              'components.CaseCorrection.CaseCorrectionForm.primaryClientStatus'
            )}
          >
            <StyledSelect
              name="primaryClientStatus"
              value={primaryClientStatus}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t(
                  'components.CaseCorrection.CaseCorrectionForm.selectPlaceholder'
                )}
              </option>
              {primaryClientStatuses.map((item) => {
                return (
                  <option
                    value={item.value}
                    key={item.value}
                  >{`${item.value} - ${item.title}`}</option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          helpContent={
            isEmpty(primaryClientStatus)
              ? t(
                  'components.CaseCorrection.CaseCorrectionForm.clientStatusHelp'
                )
              : ''
          }
          validationContent={t(
            'components.CaseCorrection.CaseCorrectionForm.clientStatusValidation'
          )}
          invalid={
            isTouched('clientStatus') && invalidFields.includes('clientStatus')
          }
        >
          <InputLabel
            content={t(
              'components.CaseCorrection.CaseCorrectionForm.clientStatus'
            )}
          >
            <StyledSelect
              name="clientStatus"
              value={clientStatus}
              disabled={isEmpty(primaryClientStatus)}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t(
                  'components.CaseCorrection.CaseCorrectionForm.selectPlaceholder'
                )}
              </option>
              {clientStatuses.map((item) => {
                return (
                  <option
                    value={item.value}
                    key={item.value}
                  >{`${item.value} - ${item.title}`}</option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

CaseCorrectionForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CaseCorrectionForm;
