import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Card,
  InputLabel,
  StyledSelect,
  InputHelp,
} from 'js/components/design-system';
import { ValidationContext } from 'js/components/validation';
import { isNonCooperatingLawyerType } from 'js/utilities/lawyers';
import {
  onMountEffect,
  getProvinceOptionsEffect,
  onInputChangeEffect,
  onInputBlurEffect,
} from './effects';

const Spacer = () => <div />;

const LawyerActivityForm = (props) => {
  const { t } = useTranslation();
  const getProvincesAbortControllerRef = useRef(null);
  const {
    className,
    formData = {},
    formOptions = {},
    onChange,
    caseClosing = false,
  } = props;
  const {
    servicesProvidedCode = '',
    reasonCode = '',
    lawyerType = '',
    cityId = '',
    provId = '',
  } = formData;

  const { api = {} } = useContext(FetchContext);
  const [options, setOptions] = useState({});
  const { serviceProvided = [], serviceProvidedReason = [] } = formOptions;

  const { validate, touch, isTouched } = useContext(ValidationContext);
  const requiredFields = caseClosing
    ? ['servicesProvidedCode', 'reasonCode', 'provId', 'cityId']
    : ['provId', 'cityId'];
  const { invalidFields = [] } = validate(formData, requiredFields);
  const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);

  const { citiesByProvince = {}, provinceOptions = [] } = options;
  const cities =
    provId && citiesByProvince[provId] ? citiesByProvince[provId] : [];

  useEffect(
    onMountEffect({
      abortControllerRefs: [getProvincesAbortControllerRef],
    }),
    []
  );

  useEffect(
    getProvinceOptionsEffect({
      t,
      setOptions,
      api,
      isNonCoopLawyer,
      getProvincesAbortControllerRef,
    }),
    [isNonCoopLawyer]
  );

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className={classnames('lawyer-activity-form', className)}>
      <h2>{t('components.CaseInformation.LawyerActivityForm.title')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.LawyerActivityForm.servicesProvidedCodeValidation'
          )}
          invalid={
            isTouched('servicesProvidedCode') &&
            invalidFields.includes('servicesProvidedCode')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.LawyerActivityForm.servicesProvidedCode'
            )}
          >
            <StyledSelect
              name="servicesProvidedCode"
              value={servicesProvidedCode}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            >
              <option key={''} value={''}>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {serviceProvided.map(
                ({
                  servicesProvidedCode: serviceCode,
                  servicesProvidedDesc: serviceDesc,
                }) => (
                  <option
                    key={serviceCode}
                    value={serviceCode}
                  >{`${serviceCode} \u2013 ${serviceDesc}`}</option>
                )
              )}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.LawyerActivityForm.serviceReasonCodeValidation'
          )}
          invalid={
            isTouched('reasonCode') && invalidFields.includes('reasonCode')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.LawyerActivityForm.serviceReasonCode'
            )}
          >
            <StyledSelect
              name="reasonCode"
              value={reasonCode}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
              disabled={servicesProvidedCode !== '0'}
            >
              <option key={''} value={''}>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {serviceProvidedReason.map(
                ({
                  servicesProvidedReasonCode: reasonCode,
                  servicesProvidedReasonDesc: reasonDesc,
                }) => (
                  <option
                    key={reasonCode}
                    value={reasonCode}
                  >{`${reasonCode} \u2013 ${reasonDesc}`}</option>
                )
              )}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        {/*checking for case closing here in case one day they want these options here again*/}
        {isNonCoopLawyer && !caseClosing ? (
          <Fragment>
            <InputHelp
              validationContent={t(
                'components.LawyerInformation.LawyerAddressForm.provinceValidation'
              )}
              invalid={isTouched('provId') && invalidFields.includes('provId')}
            >
              <InputLabel
                content={t(
                  'components.LawyerInformation.LawyerAddressForm.province'
                )}
              >
                <StyledSelect
                  value={provId}
                  name="provId"
                  onBlur={onInputBlurEffect({ touch })}
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t(
                      'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                    )}
                  </option>
                  {provinceOptions.map((prov) => {
                    return (
                      <option value={prov.provId} key={prov.provId}>
                        {prov.provName}
                      </option>
                    );
                  })}
                </StyledSelect>
              </InputLabel>
            </InputHelp>

            <InputHelp
              validationContent={t(
                'components.LawyerInformation.LawyerAddressForm.cityValidation'
              )}
              invalid={isTouched('cityId') && invalidFields.includes('cityId')}
            >
              <InputLabel
                content={t(
                  'components.LawyerInformation.LawyerAddressForm.city'
                )}
              >
                <StyledSelect
                  value={cityId}
                  name="cityId"
                  disabled={!provId}
                  {...inputEventHandlers}
                >
                  <option value="" disabled>
                    {t(
                      'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                    )}
                  </option>
                  {cities.map((city, k) => {
                    return (
                      <option value={city.cityCode} key={k}>
                        {city.cityName}
                      </option>
                    );
                  })}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
          </Fragment>
        ) : (
          <Spacer />
        )}
      </div>
    </Card>
  );
};

LawyerActivityForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  formOptions: PropTypes.shape({
    serviceProvided: PropTypes.arrayOf(
      PropTypes.shape({
        servicesProvidedCode: PropTypes.string.isRequired,
        servicesProvidedDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
    serviceProvidedReason: PropTypes.arrayOf(
      PropTypes.shape({
        servicesProvidedReasonCode: PropTypes.string.isRequired,
        servicesProvidedReasonDesc: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  caseClosing: PropTypes.bool,
};

export default LawyerActivityForm;
