import React, { useState } from 'react';
import {
  StyledSelect,
  DatePicker,
  InputHelp,
  InputLabel,
  StrikethroughInput,
  Checkbox,
} from '../components/forms';
import { Card } from '../components/cards';

const Forms = () => {
  const [selectValue, setSelectValue] = useState('');
  const [date, setDate] = useState(new Date());
  const [checked, setChecked] = useState();
  const [radioValue1, setRadioValue1] = useState('on');
  const [radioValue2, setRadioValue2] = useState('on');
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Forms</h1>

        <form>
          <Card>
            <h2>Inputs - Empty</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text">
                <InputLabel content="Text Input">
                  <input type="text" placeholder="Text" />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Number Input">
                  <input type="number" placeholder="Number" />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Password Input">
                  <input type="password" placeholder="Password" />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Inputs - Filled</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text">
                <InputLabel content="Text Input">
                  <input type="text" value="Text" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Number Input">
                  <input type="number" value="42" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Password Input">
                  <input type="password" value="Password" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Inputs - Invalid</h2>
            <div className="form-row">
              <InputHelp
                helpContent="This is help text"
                validationContent="There is a problem"
                invalid={true}
              >
                <InputLabel content="Text Input">
                  <input type="text" value="Text" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
              <InputHelp
                helpContent="This is help text"
                validationContent="There is a problem"
                invalid={true}
              >
                <InputLabel content="Number Input">
                  <input type="number" value="42" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
              <InputHelp
                helpContent="This is help text"
                validationContent="There is a problem"
                invalid={true}
              >
                <InputLabel content="Password Input">
                  <input type="password" value="Password" onChange={() => {}} />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Inputs - Read Only</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text">
                <InputLabel content="Text Input">
                  <input type="text" placeholder="Read Only" readOnly />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Text Input">
                  <input
                    type="text"
                    value="Read Only - Filled"
                    onChange={() => {}}
                    readOnly
                  />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Inputs - Disabled</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text" disabled>
                <InputLabel content="Text Input" disabled>
                  <input type="text" placeholder="Disabled" disabled />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text" disabled>
                <InputLabel content="Text Input" disabled>
                  <input
                    type="text"
                    value="Disabled - Filled"
                    onChange={() => {}}
                    disabled
                  />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Inputs - Strikethrough</h2>
            <div className="form-row">
              <InputLabel content="Strikethrough Input">
                <StrikethroughInput
                  items={[
                    { text: '123456', struck: false },
                    { text: '234567', struck: true },
                    { text: '345678', struck: true },
                    { text: '456789', struck: false },
                  ]}
                />
              </InputLabel>
            </div>
          </Card>

          <Card>
            <h2>Select</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text">
                <InputLabel content="Select">
                  <StyledSelect
                    value={selectValue}
                    onChange={(e) => setSelectValue(e.target.value)}
                  >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four and more</option>
                  </StyledSelect>
                </InputLabel>
              </InputHelp>
            </div>
            <h2>Select - Disabled</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text" disabled>
                <InputLabel content="Select" disabled>
                  <StyledSelect
                    value={selectValue}
                    onChange={(e) => setSelectValue(e.target.value)}
                    disabled
                  >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four and more</option>
                  </StyledSelect>
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Date</h2>
            <div className="form-row">
              <InputHelp helpContent="This is help text">
                <InputLabel content="Date Input">
                  <DatePicker selected={date} onChange={setDate} />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text">
                <InputLabel content="Date Input">
                  <DatePicker selected={date} onChange={setDate} readOnly />
                </InputLabel>
              </InputHelp>
              <InputHelp helpContent="This is help text" disabled>
                <InputLabel content="Date Input" disabled>
                  <DatePicker selected={date} onChange={setDate} disabled />
                </InputLabel>
              </InputHelp>
            </div>
          </Card>

          <Card>
            <h2>Checkbox</h2>
            <div className="form-row">
              <Checkbox
                name="checkbox"
                onChange={(e) => {
                  const check = e.target.checked;
                  setChecked(check);
                }}
                checked={checked}
                label={'Checked'}
              />
              <Checkbox
                name="checkbox2"
                onChange={(e) => {
                  const check = e.target.checked;
                  setChecked(check);
                }}
                disabled
                checked={true}
                label={'Disabled'}
              />
            </div>
          </Card>

          <Card>
            <h2>Radio Button Group - Horizontal</h2>
            <div className="form-row">
              <div className="radio-group radio-group-horizontal">
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    value="on"
                    checked={radioValue1 === 'on'}
                    onChange={(e) => setRadioValue1(e.target.value)}
                  />
                  <span>On</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    value="off"
                    checked={radioValue1 === 'off'}
                    onChange={(e) => setRadioValue1(e.target.value)}
                  />
                  <span>Off</span>
                </label>
              </div>
            </div>
          </Card>

          <Card>
            <h2>Radio Button Group - Vertical</h2>
            <div className="form-row">
              <div className="radio-group radio-group-vertical">
                <label>
                  <input
                    type="radio"
                    name="radio2"
                    value="on"
                    checked={radioValue2 === 'on'}
                    onChange={(e) => setRadioValue2(e.target.value)}
                  />
                  <span>On</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio2"
                    value="off"
                    checked={radioValue2 === 'off'}
                    onChange={(e) => setRadioValue2(e.target.value)}
                  />
                  <span>Off</span>
                </label>
              </div>
            </div>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default Forms;
