import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '../../icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const DropdownMenuOption = (props) => {
  const { className, selected, children, disabled, ...itemProps } = props;
  const containerClassName = classnames(
    'dropdown-menu-option',
    { 'dropdown-menu-option-selected': selected, disabled },
    className
  );
  const actionClassName = 'dropdown-menu-option-action';

  return (
    <li {...itemProps} className={containerClassName} disabled={disabled}>
      <div className="dropdown-menu-option-content">{children}</div>
      <div className={classnames(actionClassName, `${actionClassName}-open`)}>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      <div className={classnames(actionClassName, `${actionClassName}-close`)}>
        <FontAwesomeIcon icon={faChevronUp} />
      </div>
    </li>
  );
};

DropdownMenuOption.defaultProps = {
  selected: false,
  disabled: false,
};

DropdownMenuOption.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DropdownMenuOption;
