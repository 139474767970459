import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { onMountEffect, deleteLawyerFeedbackEffect } from './effects';

const DeleteLawyerFeedback = (props) => {
  const { className, lawyerId, feedbackId, onDismiss } = props;
  const { t } = useTranslation();
  const deleteLawyerFeedbackAbortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);

  useEffect(
    onMountEffect({
      abortControllerRefs: [deleteLawyerFeedbackAbortControllerRef],
      setActive,
    }),
    []
  );

  const deleteLawyerFeedback = deleteLawyerFeedbackEffect({
    t,
    api,
    lawyerId,
    feedbackId,
    setActive,
    onDismiss,
    deleteLawyerFeedbackAbortControllerRef,
  });

  return (
    <div className={classnames('delete-lawyer-feedback', className)}>
      <h2>{t('components.DeleteLawyerFeedback.title')}</h2>
      <p>{t('components.DeleteLawyerFeedback.message')}</p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={active}
          onClick={() => onDismiss(false)}
        >
          {t('common.cancel')}
        </button>
        <button
          className="button button-highlight"
          disabled={active}
          onClick={deleteLawyerFeedback}
        >
          {active ? t('common.deleting') : t('common.delete')}
        </button>
      </div>
    </div>
  );
};

DeleteLawyerFeedback.propTypes = {
  className: PropTypes.string,
  lawyerId: PropTypes.string.isRequired,
  feedbackId: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default DeleteLawyerFeedback;
