import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const databaseBackupsBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = systemSettingsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.DatabaseBackups.breadcrumbs.databaseBackups'),
      path: currentPath,
    },
  ];

  return history;
};

export const DATABASE_EXPORT_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const DATABASE_BACKUPS_DATE_FORMAT = "MMM d yyyy 'at' h:mm a";

export const classNameForDatabaseStatus = (status) => {
  if (!status) {
    return '';
  }

  switch (status) {
    case 'Online':
      return 'tag tag-success';
    case 'Disabled':
    case 'EmergencyMode':
    case 'Inaccessible':
    case 'Offline':
    case 'Shutdown':
    case 'Stopped':
    case 'Stopping':
    case 'Suspect':
      return 'tag tag-error-bold';
    default:
      return 'tag tag-warning';
  }
};
