import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';
import AddLawyerFeedback from './add-lawyer-feedback';

const AddLawyerFeedbackModal = (props) => {
  const {
    className,
    mounted,
    lawyerId,
    onDismiss,
    editingFeedback = '',
    onCancelEdit,
    isEditing = false,
  } = props;
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames('add-lawyer-feedback-modal', className)}
      onClickCancel={() => onDismiss(false)}
      titleText={t('components.AddLawyerFeedback.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
      allowCancel={false}
    >
      <AddLawyerFeedback
        lawyerId={lawyerId}
        onDismiss={!editingFeedback ? onDismiss : onCancelEdit}
        editingFeedback={editingFeedback}
        isEditing={isEditing}
      />
    </Modal>
  );
};

AddLawyerFeedbackModal.defaultProps = {
  mounted: false,
};

AddLawyerFeedbackModal.propTypes = {
  ...AddLawyerFeedback.propTypes,
  mounted: PropTypes.bool,
};

export default AddLawyerFeedbackModal;
