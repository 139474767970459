import { cleanStringField } from 'js/utilities/strings';
import { isEmpty } from 'js/utilities/validation';

export const getEmptyLawyerType = () => ({
  code: '',
  desc: '',
  glAcct: '',
  gsTcct: '',
  inactive: false,
  requireProvince: false,
  requireCity: false,
  requireId: false,
});

export const cleanLawyerType = (lawyerType = {}) => {
  const { code, desc, glAcct, gsTcct } = lawyerType;

  return {
    ...lawyerType,
    code: cleanStringField(code),
    desc: cleanStringField(desc),
    glAcct: cleanStringField(glAcct),
    gsTcct: cleanStringField(gsTcct),
  };
};

export const cleanLawyerTypes = (lawyerTypes = []) =>
  lawyerTypes.map(cleanLawyerType);

export const lawyerTypeValidationRules = {
  desc: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 30;
  },
  glAcct: (data, key) => !isEmpty(data[key]),
  gsTcct: (data, key) => !isEmpty(data[key]),
};

export const lawyerTypeRequiredFields = ['desc', 'glAcct', 'gsTcct'];
