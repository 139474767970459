import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { clientIdMaskPattern, clientCodeMaskPattern } from 'js/utilities/masks';
import { createMaskEffect, onChangeMaskedInputEffect } from './effects';

const SearchClientIdInput = (props) => {
  const { t } = useTranslation();
  const clientIdMaskRef = useRef(null);
  const clientCodeMaskRef = useRef(null);
  const { clientId = '', clientCode = '', onChange, ...inputProps } = props;

  useEffect(
    createMaskEffect({ maskRef: clientIdMaskRef, mask: clientIdMaskPattern }),
    []
  );
  useEffect(
    createMaskEffect({
      maskRef: clientCodeMaskRef,
      mask: clientCodeMaskPattern,
    }),
    []
  );

  return (
    <div className="search-client-id-input">
      <input
        {...inputProps}
        type="text"
        name="clientId"
        autoComplete="off"
        placeholder={t('components.controls.SearchInput.placeholders.clientId')}
        value={clientId}
        onChange={onChangeMaskedInputEffect({
          maskRef: clientIdMaskRef,
          onChange,
        })}
      />
      <input
        {...inputProps}
        type="text"
        name="clientCode"
        autoComplete="off"
        placeholder={t(
          'components.controls.SearchInput.placeholders.clientCode'
        )}
        value={clientCode}
        onChange={onChangeMaskedInputEffect({
          maskRef: clientCodeMaskRef,
          onChange,
        })}
      />
    </div>
  );
};

SearchClientIdInput.propTypes = {
  clientId: PropTypes.string.isRequired,
  clientCode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchClientIdInput;
