import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
} from '@fortawesome/free-solid-svg-icons';

//api is accepting name but it's actually sorting on code
export const UnionSortOptions = [
  {
    key: 'unionName-asc',
    field: 'name',
    direction: 'asc',
    i18nKey: 'components.UnionListPage.sortMenu.unionNameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'unionName-desc',
    field: 'name',
    direction: 'desc',
    i18nKey: 'components.UnionListPage.sortMenu.unionNameDesc',
    icon: faSortAlphaDownAlt,
  },
  {
    key: 'unionNumber-asc',
    field: 'number',
    direction: 'asc',
    i18nKey: 'components.UnionListPage.sortMenu.unionNumberAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'unionNumber-desc',
    field: 'number',
    direction: 'desc',
    i18nKey: 'components.UnionListPage.sortMenu.unionNumberDesc',
    icon: faSortAlphaDownAlt,
  },
];
