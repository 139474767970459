import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'js/utilities/dates';
import { getCompanyFeeScheduleTitle } from 'js//utilities/companies';

const CompanyPrintDetails = (props) => {
  const { t } = useTranslation();
  const { className, company = {}, feeSchedule = {} } = props;

  const {
    companyCode = '',
    coPhone1 = '',
    coPhone2 = '',
    coFax = '',
    coAddr1 = '',
    coAddr2 = '',
    coCity = '',
    coProv = '',
    coPcode = '',
    coBenstrt = null,
    coBenend = null,
  } = company;

  const {
    scheduleCode = '',
    scheduleName = '',
    versionDate = '',
  } = feeSchedule;

  const streetAddress = `${coAddr1} ${coAddr2}`.trim();

  return (
    <div
      className={classnames('company-print-details', className)}
      data-print-managed
      data-print-only
    >
      <h2>{t('components.CompanyDetailsPage.formTitle')}</h2>
      <div className="company-print-details-row">
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.companyCode')}</span>
          <span>{companyCode}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.primaryPhone')}</span>
          <span>{coPhone1}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.secondaryPhone')}</span>
          <span>{coPhone2}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.fax')}</span>
          <span>{coFax}</span>
        </div>
      </div>
      <div className="company-print-details-row">
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.printAddress')}</span>
          <span>{`${streetAddress}, ${coCity}, ${coProv}, ${coPcode}`}</span>
        </div>
      </div>
      <div className="company-print-details-row">
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.benefitStartDate')}</span>
          <span>{formatDate(coBenstrt)}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.benefitEndDate')}</span>
          <span>{formatDate(coBenend)}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.feeSchedule')}</span>
          <span>{getCompanyFeeScheduleTitle(scheduleCode, scheduleName)}</span>
        </div>
        <div className="company-print-details-item">
          <span>{t('components.CompanyDetailsPage.feeScheduleVersion')}</span>
          <span>{versionDate}</span>
        </div>
      </div>
    </div>
  );
};

CompanyPrintDetails.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object.isRequired,
  feeSchedule: PropTypes.object.isRequired,
};

export default CompanyPrintDetails;
