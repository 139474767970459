export const executeRequestEffect = (options = {}) => {
  const {
    beforeRequest,
    request,
    setWaiting,
    setItems,
    printOnLoad,
    presentStyledBanner,
  } = options;
  return async () => {
    try {
      setWaiting(true);

      if (typeof beforeRequest === 'function') {
        await beforeRequest();
      }

      const items = await request();

      if (Array.isArray(items)) {
        setItems(items);
        setWaiting(false);

        if (printOnLoad && items.length > 0) {
          setTimeout(() => window.print());
        }
      } else {
        throw new Error('ListPrintView: request must return an array.');
      }
    } catch (error) {
      setWaiting(false);
      presentStyledBanner('error', { content: error.message });
    }
  };
};
