import React, { useContext } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'js/components/design-system';
import {
  caseTabItems,
  queryFilteringSearchParamsForCaseTabs,
} from './functions';
import PropTypes from 'prop-types';
import { GroupPermissionsContext } from 'js/components/group-permissions/context';
import { userGroups } from 'js/components/group-permissions';

const CaseTabs = (props) => {
  const { disabled, showCaseNotesCount, caseNotesCount } = props;
  const { hasLoadedGroups, matchAnyGroup } = useContext(
    GroupPermissionsContext
  );
  const {
    path,
    params: { caseNumber },
  } = useRouteMatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = queryFilteringSearchParamsForCaseTabs(search);

  const hasBillingAccess =
    hasLoadedGroups &&
    matchAnyGroup([
      userGroups.administrator,
      userGroups.dataEntry,
      userGroups.intakeSpecialist,
      userGroups.support,
      userGroups.feedbackSpecialist,
      userGroups.viewOnly,
    ]);

  const isAdmin =
    hasLoadedGroups &&
    matchAnyGroup([userGroups.administrator, userGroups.dataEntry]);

  const items = caseTabItems({
    t,
    params: { caseNumber },
    search: query.search,
    hasBillingAccess,
    showCaseNotesCount,
    caseNotesCount,
    isAdmin,
  });

  return (
    <Tabs
      className="case-tabs"
      items={items}
      currentPath={path}
      disabled={disabled}
    />
  );
};

CaseTabs.defaultProps = {
  disabled: false,
  showCaseNotesCount: false,
  caseNotesCount: 0,
};

CaseTabs.propTypes = {
  disabled: PropTypes.bool,
  showCaseNotesCount: PropTypes.bool,
  caseNotesCount: PropTypes.number,
};

export default CaseTabs;
