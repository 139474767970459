import { isStaffLawyerType } from 'js/utilities/lawyers';
import {
  ESTATE_TRUSTEE_LP_CODES,
  REAL_ESTATE_LP_CODES,
} from 'js/utilities/legal-problem-codes';

export const getPromptRules = (t) => ({
  'dependent.age': getPromptRuleForDependentAge(t),
  'dependent.existingSpouse': getPromptRuleForExistingSpouse(t),
  'dependent.existingSpouseDeactivation': getPromptRuleForExistingSpouseDeactivation(
    t
  ),
  'case.dependent.age': getPromptRuleForCaseDependentAge(t),
  'case.estateTrusteeRule': getPromptRuleForEstateTrustee(t),
  'case.estateTrusteeConfirmation': getPromptRuleForEstateTrusteeConfirmation(
    t
  ),
  'case.caseExists': getPromptRuleForExistingCase(t),
  'case.realEstateActivity': getPromptRuleForCaseRealEstateActivity(t),
  'client.duplicate': getPromptRuleForDuplicateClient(t),
  'lawyer.feedbackFileDelete': getPromptRuleForDeleteFiles(t),
  'reports.changeDateRange': getPromptRuleForChangeDateRange(t),
});

export const getPromptRuleForDependentAge = (t) => ({
  test: (data) => {
    const { clientPersonal = {} } = data;
    const birthYear = parseInt(clientPersonal.dateBirth);
    if (!isNaN(birthYear)) {
      const year = new Date().getFullYear();
      const age = Math.max(year - birthYear, 0);
      return age > 26;
    } else {
      return false;
    }
  },
  message: t('common.promptMessages.dependent.age'),
  confirmButtonTitle: t('common.continue'),
});

export const getPromptRuleForExistingSpouse = (t) => ({
  test: (data) => data.spouseExists === true,
  message: t('common.promptMessages.dependent.existingSpouse'),
  allowConfirm: false,
});

export const getPromptRuleForExistingSpouseDeactivation = (t) => ({
  test: (data) => data.spouseExists === true,
  message: t('common.promptMessages.dependent.existingSpouseDeactivation'),
  confirmButtonTitle: t('common.continue'),
});

export const getPromptRuleForCaseDependentAge = (t) => ({
  test: (data) => {
    const birthYear = parseInt(data.dateOfBirth);
    if (!isNaN(birthYear)) {
      const year = new Date().getFullYear();
      const age = Math.max(year - birthYear, 0);
      return age > 26;
    } else {
      return false;
    }
  },
  message: t('common.promptMessages.dependent.age'),
  confirmButtonTitle: t('common.continue'),
});

export const getPromptRuleForEstateTrustee = (t) => ({
  test: (data) => {
    const { caseInformation = {}, caseLawyer = {} } = data;
    const { legalProblemCode } = caseInformation;
    const { lawyerType, stateTrustee } = caseLawyer;
    if (stateTrustee) {
      const isApplicableLpCode = ESTATE_TRUSTEE_LP_CODES.includes(
        legalProblemCode
      );
      const isStaffLawyer = isStaffLawyerType(lawyerType);
      return !(isApplicableLpCode && isStaffLawyer);
    } else {
      return false;
    }
  },
  message: t('common.promptMessages.case.estateTrusteeRule'),
  allowConfirm: false,
});

export const getPromptRuleForEstateTrusteeConfirmation = (t) => ({
  test: (data) => {
    const { caseInformation = {}, caseLawyer = {} } = data;
    const { legalProblemCode } = caseInformation;
    const { lawyerType } = caseLawyer;
    const isApplicableLpCode = ESTATE_TRUSTEE_LP_CODES.includes(
      legalProblemCode
    );
    const isStaffLawyer = isStaffLawyerType(lawyerType);
    return isApplicableLpCode && isStaffLawyer;
  },
  message: t('common.promptMessages.case.estateTrusteeConfirmation'),
});

export const getPromptRuleForExistingCase = (t) => ({
  test: (data) => data.caseExists === true,
  message: t('common.promptMessages.case.caseExists'),
});

export const getPromptRuleForCaseRealEstateActivity = (t) => ({
  test: (data) => {
    const { hasActiveCase = false, hasRecentCase = false } = data;
    // data.legalProblemCode may be null:
    const { legalProblemCode } = data.legalProblemCode || {};
    const isApplicableLpCode = REAL_ESTATE_LP_CODES.includes(legalProblemCode);
    return isApplicableLpCode && (hasActiveCase || hasRecentCase);
  },
  message: (data) => {
    const { hasActiveCase = false, hasRecentCase = false } = data;
    // data.legalProblemCode and data.case may be null:
    const { legalProblemCode } = data.legalProblemCode || {};
    const { caseInformationResponse: caseInformation = {} } = data.case || {};
    const { cif = '' } = caseInformation;
    if (hasActiveCase) {
      return t(`common.promptMessages.case.realEstateActivity.active`, {
        cif,
        legalProblemCode,
      });
    } else if (hasRecentCase) {
      return t(`common.promptMessages.case.realEstateActivity.recent`, {
        cif,
        legalProblemCode,
      });
    } else {
      return '';
    }
  },
  allowConfirm: true,
});

export const getPromptRuleForDuplicateClient = (t) => ({
  test: (data) => data.duplicateExists === true,
  message: t('common.promptMessages.client.duplicate'),
  confirmButtonTitle: t('common.continue'),
});

export const getPromptRuleForDeleteFiles = (t) => ({
  test: (data) => data.docId !== '',
  message: t('common.promptMessages.lawyer.deleteFeedback'),
  confirmButtonTitle: t('common.continue'),
});

export const getPromptRuleForChangeDateRange = (t) => ({
  test: (data) => data.name !== '',
  message: t('common.promptMessages.reports.changeDateRange'),
  confirmButtonTitle: t('common.continue'),
});
