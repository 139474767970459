import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from '../components/cards';

const Home = ({ indexPaths }) => {
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Unifor Design System</h1>
        <Card>
          <ul className="unstyled-list">
            <li>
              <Link className="link" to={indexPaths.text}>
                Text
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.buttons}>
                Buttons
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.forms}>
                Forms
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.cards}>
                Cards
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.navigation}>
                Navigation
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.menus}>
                Menus
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.tables}>
                Tables
              </Link>
            </li>
            <li>
              <Link className="link" to={indexPaths.animations}>
                Animations
              </Link>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
};

Home.propTypes = {
  indexPaths: PropTypes.object,
};

export default Home;
