import React, { useState } from 'react';
import { DropdownMenu } from '../components/menus';

const Menus = () => {
  const [selectedOptionIndex1, setSelectedOptionIndex1] = useState(0);
  const [selectedOptionIndex2, setSelectedOptionIndex2] = useState(0);
  const onSelect1 = (e, idx) => setSelectedOptionIndex1(idx);
  const onSelect2 = (e, idx) => setSelectedOptionIndex2(idx);

  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Menus</h1>

        <h2>Dropdown</h2>
        <DropdownMenu
          options={[
            { content: 'One', onSelect: onSelect1 },
            { content: 'Two', onSelect: onSelect1 },
            { content: 'Three', onSelect: onSelect1 },
            { content: 'Four', onSelect: onSelect1 },
            { content: 'Five', onSelect: onSelect1 },
            { content: 'Six', onSelect: onSelect1 },
          ]}
          selectedOptionIndex={selectedOptionIndex1}
        />
        <DropdownMenu
          options={[
            { content: 'Seven', onSelect: onSelect2 },
            { content: 'Eight', onSelect: onSelect2 },
            { content: 'Nine', onSelect: onSelect2 },
            { content: 'Ten', onSelect: onSelect2 },
            { content: 'Eleven', onSelect: onSelect2 },
            { content: 'Twelve', onSelect: onSelect2 },
          ]}
          selectedOptionIndex={selectedOptionIndex2}
        />
      </div>
    </div>
  );
};

export default Menus;
