import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';

export const setUrlDateEffect = (options = {}) => {
  const { query, setReportDate, setDateConfirmed } = options;
  return () => {
    const { params } = query;
    if (params.date) {
      setReportDate(params.date);
      setDateConfirmed(true);
    }
  };
};

export const onChangeInputEffect = (options = {}) => {
  const { setReportDate } = options;
  return (name, value) => setReportDate(value);
};

export const onBlurReportDateInputEffect = (options = {}) => {
  const { touchReportDateInput } = options;
  return (e) => {
    const { value = '' } = e.target;
    if (value.length > 0) {
      touchReportDateInput();
    }
  };
};

export const onConfirmDateEffect = (options = {}) => {
  const {
    setDateConfirmed,
    touchReportDateInput,
    reportDate,
    isReportDateValid,
    history,
    query,
  } = options;

  return () => {
    touchReportDateInput();
    setDateConfirmed(true);
    if (isReportDateValid) {
      const { params } = query;
      const nextParams = { ...params, date: reportDate };
      history.push(searchStringFromParams(nextParams));
    }
  };
};

export const onResetDateEffect = (options = {}) => {
  const {
    setDateConfirmed,
    setReportDate,
    untouchReportDateInput,
    history,
    query,
  } = options;

  return () => {
    setDateConfirmed(false);
    setReportDate('');
    untouchReportDateInput();
    const { params } = query;
    const nextParams = { ...params, date: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

export const runCaseOpenedReportEffect = (options = {}) => {
  const {
    t,
    api,
    parsedDate,
    setActive,
    runReportAbortControllerRef,
  } = options;
  return async () => {
    setActive(true);
    rotateAbortControllerRef(runReportAbortControllerRef);
    const { signal } = runReportAbortControllerRef.current;
    const endpoint = `/DayEndReport/opencases`;

    const body = {
      date: parsedDate,
    };
    try {
      const { response } = await api.fetch(
        endpoint,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          signal,
          body: JSON.stringify(body),
        },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.dayEnd.runRequestError'),
            },
          },
        }
      );
      const nextResponse = await response.blob();
      const documentUri = URL.createObjectURL(nextResponse);

      if (documentUri) {
        window.open(documentUri, '_blank', 'noopener,noreferrer');
      }
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};
