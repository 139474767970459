import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { onClickHeaderMenuLinkEffect } from './effects';

const HeaderMenuLink = (props) => {
  const { className, linkTo, icon, content, setMenuVisibility } = props;
  const { pathname } = useLocation();
  const isActive = pathname === linkTo;
  return (
    <Link
      className={classnames(
        'header-menu-link',
        { 'header-menu-link-active': isActive },
        className
      )}
      to={linkTo}
      onClick={onClickHeaderMenuLinkEffect({ setMenuVisibility })}
    >
      {icon}
      <span>{content}</span>
    </Link>
  );
};

HeaderMenuLink.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
  setMenuVisibility: PropTypes.func.isRequired,
};

export default HeaderMenuLink;
