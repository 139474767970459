import { isEmpty } from 'js/utilities/validation';

export const feeScheduleValidationRules = {
  name: (data, key) => {
    const value = data[key];
    return !isEmpty(value) && value.length <= 50;
  },
};

export const feeScheduleRequiredFields = ['name'];

export const returnSaveButtonText = (t, isNew, saving) => {
  if (isNew) {
    return saving ? t('common.creating') : t('common.create');
  } else {
    return saving ? t('common.saving') : t('common.save');
  }
};
