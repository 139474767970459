import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';

export const LAWYER_ACTIVE_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const queryFromModalQueryString = (modalQueryString = '') => {
  const decodeQueryString = decodeURIComponent(modalQueryString);

  const {
    Keyword = '',
    Order = 'lastName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
    PanelId = '',
    Province = '',
    City = '',
    SubPanelId = '',
    ActiveStatus = LAWYER_ACTIVE_STATUS.ACTIVE, //defaulted to active lawyers
  } = paramsFromSearchString(decodeQueryString);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
    PanelId,
    Province,
    City,
    SubPanelId,
    ActiveStatus,
  };

  return queryForParams(params);
};

export const isExecutableQuery = (query = {}) => {
  const { params = {} } = query;

  // The value for at least one of the following keys
  // must not be empty for the query to be executable:
  const keys = ['Keyword', 'PanelId', 'SubPanelId', 'Province', 'City'];

  return keys.reduce((next, key) => {
    return next || !isEmpty(params[key]);
  }, false);
};
