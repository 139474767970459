import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ClipboardStatusIcon from './status-icon';
import { clipboardWriteTextEffect } from './effects';
import { CLIPBOARD_WRITE_STATUS } from './functions';

const ClipboardButton = (props) => {
  const { className, title, clipboardText } = props;
  const [writeStatus, setWriteStatus] = useState(
    CLIPBOARD_WRITE_STATUS.UNKNOWN
  );
  return (
    <button
      className={classnames('clipboard-button', className)}
      disabled={writeStatus === CLIPBOARD_WRITE_STATUS.ACTIVE}
      onClick={clipboardWriteTextEffect({ clipboardText, setWriteStatus })}
    >
      <ClipboardStatusIcon writeStatus={writeStatus} />
      <span>{title}</span>
    </button>
  );
};

ClipboardButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  clipboardText: PropTypes.string.isRequired,
};

export default ClipboardButton;
