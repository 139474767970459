import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ListCard } from 'js/components/design-system';
import { formatDateFromISOString } from 'js/utilities/dates';
import { onClickRestoreBackupEffect } from './effects';
import { DATABASE_BACKUPS_DATE_FORMAT } from './functions';

const DatabaseBackupsListCard = (props) => {
  const { t } = useTranslation();
  const { className, backup = {}, onClickRestore, ...cardProps } = props;
  const { startTime, isComplete = false } = backup;

  return (
    <ListCard
      {...cardProps}
      className={classnames(
        'database-backups-list-card',
        { 'in-progress': !isComplete },
        className
      )}
      heading={formatDateFromISOString(startTime, DATABASE_BACKUPS_DATE_FORMAT)}
      subheading={
        isComplete
          ? t('components.DatabaseBackups.listCard.complete')
          : t('components.DatabaseBackups.listCard.incomplete')
      }
      accessory={
        <button
          className="button button-highlight"
          disabled={!isComplete}
          onClick={onClickRestoreBackupEffect({ backup, onClickRestore })}
        >
          {t('components.DatabaseBackups.listCard.restore')}
        </button>
      }
    ></ListCard>
  );
};

DatabaseBackupsListCard.propTypes = {
  ...ListCard.propTypes,
  backup: PropTypes.object.isRequired,
  onClickRestore: PropTypes.func.isRequired,
};

export default DatabaseBackupsListCard;
