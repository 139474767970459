export const resolveSentToLawyerForRequestBody = (sentToLawyer) => {
  switch (sentToLawyer) {
    case 'false':
      return false;
    case 'true':
      return true;
    default:
      return null;
  }
};

export const prepareCaseLawyerRequestBody = (caseLawyer = {}) => {
  return {
    ...caseLawyer,
    sentToLawyer: resolveSentToLawyerForRequestBody(caseLawyer.sentToLawyer),
    lawyerId: caseLawyer.lawyerId || caseLawyer.staffId,
    lawyerType: caseLawyer.lawyerType.toString(),
  };
};

export const prepareCaseBillingRequestBody = (caseBilling = {}) => {
  return {
    ...caseBilling,
    planHours: Number(caseBilling.planHours),
    planFees: Number(caseBilling.planFees),
    planTaxes: Number(caseBilling.planTaxes),
    clientHours: Number(caseBilling.clientHours),
    clientFees: Number(caseBilling.clientFees),
  };
};
