import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import { getLinkedCompanyColumns } from './functions';
import EmptyState from 'js/components/controls/empty-state';
import { Redirect } from 'react-router-dom';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

const LinkedCompanyList = (props) => {
  const { companies = [], isNew, active } = props;
  const { t } = useTranslation();
  const [redirectPath, setRedirectPath] = useState('');

  const emptyTitle = isNew
    ? t('components.UnionDetailsPage.LinkedCompanyList.emptyStateNewMessage')
    : t('components.UnionDetailsPage.LinkedCompanyList.emptyStateMessage');

  if (redirectPath) {
    return <Redirect push to={redirectPath} />;
  }
  return (
    <div data-print-managed>
      <h1 className="linked-unions-heading">
        {t('components.UnionDetailsPage.LinkedCompanyList.title')}
      </h1>
      {active && <ActivityIndicatorCard />}
      {!active && companies.length === 0 && <EmptyState title={emptyTitle} />}
      {!active && companies.length > 0 && (
        <Fragment>
          <SortableTable
            columns={getLinkedCompanyColumns(t)}
            data={companies}
            initialSortColumnKey={'companyCode'}
            initialSortDirection={tableSortDirection.desc}
            onClickRow={(e) => {
              const { companyCode } = e;
              const companyPath = routePathReplacingParams(routePaths.company, {
                companyCode,
              });
              setRedirectPath(companyPath);
            }}
          />
        </Fragment>
      )}
    </div>
  );
};

LinkedCompanyList.propTypes = {
  companies: PropTypes.array,
  isNew: PropTypes.bool,
  active: PropTypes.bool,
};

export default LinkedCompanyList;
