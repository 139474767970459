import React from 'react';
import classnames from 'classnames';
import Card from './card';

const IndentedCard = (props) => {
  const { className, children, ...cardProps } = props;
  return (
    <Card className={classnames('indented-card', className)} {...cardProps}>
      {children}
    </Card>
  );
};

IndentedCard.propTypes = {
  ...Card.propTypes,
};

export default IndentedCard;
