export const onChangeFolderFrequencyCheckboxEffect = (options = {}) => {
  const { prompt, onChangeFolderFrequency } = options;
  return async (e) => {
    const { name, checked } = e.target;
    try {
      await prompt({ name }, 'reports.changeDateRange');
      onChangeFolderFrequency({ [name]: checked });
    } catch (error) {
      return;
    }
  };
};

export const onChangeReportCheckboxEffect = (options = {}) => {
  const { report = {}, onSaveReport } = options;
  return (e) => {
    const { name, checked } = e.target;
    onSaveReport({ ...report, [name]: checked });
  };
};

export const unmountRunEditModalEffect = (options = {}) => {
  const { setRunModalMounted, setEditModalMounted } = options;
  return () => {
    setRunModalMounted(false);
    setEditModalMounted(false);
  };
};

export const onModalRequestEffect = (options = {}) => {
  const { report, requestFunc, setModalMounted } = options;
  return async () => {
    await requestFunc(report);
    setModalMounted(false);
  };
};

export const onMoveReportModalRequestEffect = (options = {}) => {
  const { report, requestFunc, setModalMounted } = options;
  return async (folderId) => {
    await requestFunc(report.id, folderId);
    setModalMounted(false);
  };
};

export const mountSaveAsEffect = (options = {}) => {
  const { unMountRunEdit, setSaveAsModalMounted } = options;
  return () => {
    unMountRunEdit();
    setSaveAsModalMounted(true);
  };
};
