import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

const ClientRedirect = (props) => {
  const {
    match: {
      params: { id, clientCode },
    },
    location: { search = '' },
  } = props;
  const path = routePathReplacingParams(routePaths.clientInformation, {
    id,
    clientCode,
  });
  return <Redirect to={`${path}${search}`} />;
};

export default ClientRedirect;
