import React, { Fragment } from 'react';
import { cleanStringField, nl2br } from 'js/utilities/strings';
import { isEmpty } from 'js/utilities/validation';

// The number of questions excluding the final comments question:
export const numQuestions = 9;

export const renderGeneralAnswers = (strings, includeNeutral = false) => (
  <Fragment>
    <div className="csq-print-tickbox-group">
      <div className="csq-print-tickbox" />
      <span>{strings?.answers?.general?.positive}</span>
    </div>
    {includeNeutral && (
      <div className="csq-print-tickbox-group">
        <div className="csq-print-tickbox" />
        <span>{strings?.answers?.general?.neutral}</span>
      </div>
    )}
    <div className="csq-print-tickbox-group">
      <div className="csq-print-tickbox" />
      <span>{strings?.answers?.general?.negative}</span>
    </div>
  </Fragment>
);

export const renderAnswers = (strings, group = '') => (
  <Fragment>
    <div className="csq-print-tickbox-group">
      <div className="csq-print-tickbox" />
      <span>{strings?.answers?.[group]?.positive}</span>
    </div>
    <div className="csq-print-tickbox-group">
      <div className="csq-print-tickbox" />
      <span>{strings?.answers?.[group]?.neutral}</span>
    </div>
    <div className="csq-print-tickbox-group">
      <div className="csq-print-tickbox" />
      <span>{strings?.answers?.[group]?.negative}</span>
    </div>
  </Fragment>
);

export const renderAnswersForQuestionIndex = (strings, index) => {
  switch (index) {
    case 2:
      return renderAnswers(strings, 'clarity');
    case 3:
      return renderAnswers(strings, 'information');
    case 6:
    case 7:
      return renderAnswers(strings, 'satisfaction');
    case 8:
      return renderGeneralAnswers(strings, true);
    default:
      return renderGeneralAnswers(strings);
  }
};

export const renderQuestions = (strings) => {
  return [...new Array(numQuestions)].map((na, idx) => {
    const questionNumber = idx + 1;
    return (
      <div key={idx} className="csq-print-question">
        <div className="csq-print-question-text">
          <span>{questionNumber}.</span>
          <span
            dangerouslySetInnerHTML={{
              __html: nl2br(strings?.questions?.[questionNumber]),
            }}
          />
        </div>
        <div className="csq-print-question-answers">
          {renderAnswersForQuestionIndex(strings, idx)}
        </div>
      </div>
    );
  });
};

const getCSQLawyerInfo = (lawyerInfo, staffLawyerInfo) => {
  let name = '';
  let id = '';

  if (lawyerInfo) {
    name = `${cleanStringField(lawyerInfo.firstName)} ${cleanStringField(
      lawyerInfo.lastName
    )}`;
    id = lawyerInfo.lawyerId;
  }

  if (!lawyerInfo && staffLawyerInfo) {
    name = `${cleanStringField(staffLawyerInfo.firstName)} ${cleanStringField(
      staffLawyerInfo.lastName
    )}`;
    id = staffLawyerInfo.staffId;
  }

  return { name, id };
};

const getCSQClientInfo = (primaryInfo, dependentInfo) => {
  let name = '';
  let email = '';
  let phone = '';
  let address = {};
  if (primaryInfo) {
    const primary = primaryInfo.clientAddress;

    name = `${cleanStringField(
      primaryInfo.clientPersonal.firstName
    )} ${cleanStringField(primaryInfo.clientPersonal.lastName)}`;
    email = primaryInfo.clientPersonal.emailAddress;
    phone = primaryInfo.clientPersonal.phoneNumber;
    address = {
      address: cleanStringField(primary.address),
      address2: cleanStringField(primary.address2),
      city: cleanStringField(primary.city),
      postalCode: cleanStringField(primary.postalCode),
      province: cleanStringField(primary.province),
    };
  }

  if (!primaryInfo && dependentInfo) {
    const dependent = dependentInfo.clientAddress;
    name = `${cleanStringField(
      dependentInfo.clientPersonal.firstName
    )} ${cleanStringField(dependentInfo.clientPersonal.lastName)}`;
    email = dependentInfo.clientPersonal.emailAddress;
    phone = dependentInfo.clientPersonal.phoneNumber;
    address = {
      address: cleanStringField(dependent.address),
      address2: cleanStringField(dependent.address2),
      city: cleanStringField(dependent.city),
      postalCode: cleanStringField(dependent.postalCode),
      province: cleanStringField(dependent.province),
    };
  }

  return { name, email, phone, address };
};

export const cleanupCSQData = (CSQData) => {
  const {
    caseInfo,
    primaryInfo,
    dependentInfo,
    lawyerInfo,
    staffLawyerInfo,
  } = CSQData;
  const { caseInformationResponse } = caseInfo || {};
  const lawyerData = getCSQLawyerInfo(lawyerInfo, staffLawyerInfo);
  const clientData = getCSQClientInfo(primaryInfo, dependentInfo);
  const formData = {
    //client
    clientName: clientData.name,
    clientPhone: clientData.phone,
    clientEmail: clientData.email,
    clientAddress: clientData.address,
    //lawyer
    lawyerId: lawyerData.id,
    lawyerName: lawyerData.name,
    //case info
    caseLegalProblemCode: !isEmpty(caseInformationResponse)
      ? caseInformationResponse.legalProblemCode
      : '',
    cif: !isEmpty(caseInformationResponse) ? caseInformationResponse.cif : '',
  };

  return formData;
};

export const requiresFrenchCSQ = (csqData = {}) => {
  const { caseInformationResponse = {} } = csqData.caseInfo;
  const { code = '' } = caseInformationResponse.openedInOffice;
  return code === '0700';
};

export const languageForCSQ = (csqData = {}) => {
  return requiresFrenchCSQ(csqData) ? 'fr' : 'en';
};
