import { searchStringFromParams } from 'js/utilities/params';
import { cleanCheques } from 'js/utilities/cheques';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const chequeRunRequestEffect = (options = {}) => {
  const {
    t,
    api,
    endpoint,
    query = {},
    setActive,
    setCheques,
    setTotalResults,
    onLoadCheques,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }

    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `${endpoint}${query.search}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.ChequeRunView.searchError') },
          },
        }
      );

      const cheques = cleanCheques(json.cheques || []);
      // totalResults may be null if there are no cheques:
      const totalResults = json.totalResults || 0;

      setCheques(cheques);
      setTotalResults(totalResults);
      setActive(false);

      if (typeof onLoadCheques === 'function') {
        onLoadCheques(cheques);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousChequeRunResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextChequeRunResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const chequeRunResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const chequeRunResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return ({ key }, direction) => {
    const nextParams = { ...params, Offset: 0, Order: key, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const chequeRunSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const chequeRunSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

export const chequeRunResetEffect = (options = {}) => {
  const {
    requestChequeRun,
    history,
    query: { params = {} },
  } = options;
  return () => {
    if (params.Offset > 0) {
      const nextParams = { ...params, Offset: 0 };
      history.push(searchStringFromParams(nextParams));
    } else {
      requestChequeRun();
    }
  };
};

export const onClickPrintChequeEffect = (options = {}) => {
  const { cheque, onClickPrintCheque } = options;
  return () => onClickPrintCheque(cheque);
};
