import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  FontAwesomeIcon,
  LabelValuePair,
} from 'js/components/design-system';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  textRequiresTruncation,
  truncateTextIfRequired,
} from 'js/utilities/strings';
import { LawyerFeedbackMetadata } from '../lawyer-feedback-metadata';
import LawyerFeedbackPrintProperties from './print-properties';
import LawyerFeedbackScreenProperties from './screen-properties';
import LawyerFeedbackDocuments from './documents';
import { onClickDeleteEffect } from './effects';
import { feedbackPropTypes } from './functions';

const LawyerFeedbackCard = (props) => {
  const { t } = useTranslation();
  const {
    className,
    feedback = {},
    onClickDocument,
    onClickDelete,
    onClickEdit,
    lawyer = {},
    getLawyerFeedback,
  } = props;

  const {
    clientId = '',
    clientCode = '',
    complainDate = '',
    author = '',
    notes = '',
    typeOfComment = false,
    feedBackDocuments = [],
    metadata = {},
    feedbackId = '',
  } = feedback;

  const [expanded, setExpanded] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const requiresTruncation = textRequiresTruncation(notes);
  const content = truncateTextIfRequired(
    notes,
    !expanded && requiresTruncation
  );

  const typeIcon = typeOfComment ? faArrowCircleUp : faArrowCircleDown;
  const typeClassName = typeOfComment
    ? 'lawyer-feedback-positive'
    : 'lawyer-feedback-negative';

  return (
    <Card className={classnames('lawyer-feedback-card', className)}>
      <div className="lawyer-feedback-card-header">
        <div
          className={classnames(
            'lawyer-feedback-card-icon-container',
            typeClassName
          )}
        >
          <FontAwesomeIcon icon={typeIcon} />
        </div>
        <ul className="lawyer-feedback-card-meta">
          <li>
            <LabelValuePair
              label={t('components.LawyerFeedbackCard.refNumber')}
              value={feedbackId}
            />
          </li>
          <li>
            <LabelValuePair
              label={t('components.LawyerFeedbackCard.dateCreated')}
              value={complainDate}
            />
          </li>
          <li>
            <LabelValuePair
              label={t('components.LawyerFeedbackCard.author')}
              value={
                author || t('components.LawyerFeedbackCard.authorEmptyState')
              }
            />
          </li>
        </ul>
      </div>
      {/* Render the full content for print: */}
      <div className="lawyer-feedback-card-body" data-print-only>
        <div className="lawyer-feedback-card-content">
          <LawyerFeedbackMetadata
            includedFields={['cif', 'legalProblemCode']}
            metadata={metadata}
          />
          {notes || t('components.LawyerFeedbackCard.contentEmptyState')}
          <LawyerFeedbackPrintProperties feedback={feedback} />
        </div>
      </div>
      {/* Render the potentially truncated content for screen: */}
      <div className="lawyer-feedback-card-body" data-screen-only>
        <div className="lawyer-feedback-card-icon-container">
          {feedBackDocuments.length > 0 && (
            <FontAwesomeIcon icon={faPaperclip} />
          )}
        </div>
        <div className="lawyer-feedback-card-content">
          <LawyerFeedbackMetadata
            includedFields={[
              'cif',
              'legalProblemCode',
              'dateOpened',
              'dateClosed',
            ]}
            metadata={metadata}
            linkTo={routePathReplacingParams(routePaths.client, {
              id: clientId,
              clientCode,
            })}
          />
          {content || t('components.LawyerFeedbackCard.contentEmptyState')}
          {expanded && <LawyerFeedbackScreenProperties feedback={feedback} />}
          {expanded && (
            <LawyerFeedbackDocuments
              feedback={feedback}
              onClickDocument={onClickDocument}
              lawyer={lawyer}
              getLawyerFeedback={getLawyerFeedback}
            />
          )}
        </div>
      </div>
      <div className="lawyer-feedback-card-footer" data-screen-only>
        {expanded && (
          <button
            className="button-link-appearance"
            disabled={isDeleting}
            onClick={onClickDeleteEffect({
              feedback,
              setIsDeleting,
              onClickDelete,
            })}
          >
            {isDeleting ? t('common.deleting') : t('common.delete')}
          </button>
        )}
        {expanded && (
          <button
            className="button-link-appearance"
            disabled={isDeleting}
            onClick={() => onClickEdit(feedback)}
          >
            {t('common.edit')}
          </button>
        )}
        <button
          className="button-link-appearance"
          disabled={isDeleting}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded
            ? t('common.cancel')
            : t('components.LawyerFeedbackCard.expand')}
        </button>
      </div>
    </Card>
  );
};

LawyerFeedbackCard.propTypes = {
  className: PropTypes.string,
  feedback: PropTypes.shape(feedbackPropTypes).isRequired,
  onClickDocument: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  lawyer: PropTypes.object,
  getLawyerFeedback: PropTypes.func,
};

export default LawyerFeedbackCard;
