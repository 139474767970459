import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prefixOptions } from 'js/hardcoded-data/form-option-data';
import { ValidationContext } from 'js/components/validation';
import {
  Card,
  InputLabel,
  InputHelp,
  StyledSelect,
} from 'js/components/design-system';
import { getLawyerInformationFormRequiredFields } from 'js/utilities/lawyers';
import { onInputChangeEffect, onInputBlurEffect } from './effects';

const StaffLawyerInformationForm = (props) => {
  const { t } = useTranslation();
  const prefOptions = prefixOptions(t);
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { className, formData = {}, onChange, isStaffLawyer } = props;

  const { prefix = '', firstName = '', lastName = '', staffId = '' } = formData;

  const requiredFields = getLawyerInformationFormRequiredFields(isStaffLawyer);
  const { invalidFields = [] } = validate(formData, requiredFields);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className={classnames('staff-lawyer-information-form', className)}>
      <h2>
        {t('components.LawyerInformation.StaffLawyerInformationForm.title')}
      </h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.LawyerInformation.StaffLawyerInformationForm.prefixValidation'
          )}
          invalid={isTouched('prefix') && invalidFields.includes('prefix')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.StaffLawyerInformationForm.prefix'
            )}
          >
            <StyledSelect name="prefix" value={prefix} {...inputEventHandlers}>
              <option value={''}>
                {t(
                  'components.LawyerInformation.StaffLawyerInformationForm.prefixPlaceholder'
                )}
              </option>
              {prefOptions.map((item, key) => {
                return (
                  <option value={item.value} key={key}>
                    {item.title}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.StaffLawyerInformationForm.firstNameValidation'
          )}
          invalid={
            isTouched('firstName') && invalidFields.includes('firstName')
          }
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.StaffLawyerInformationForm.firstName'
            )}
          >
            <input
              type="text"
              name="firstName"
              value={firstName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.LawyerInformation.StaffLawyerInformationForm.lastNameValidation'
          )}
          invalid={isTouched('lastName') && invalidFields.includes('lastName')}
        >
          <InputLabel
            content={t(
              'components.LawyerInformation.StaffLawyerInformationForm.lastName'
            )}
          >
            <input
              type="text"
              name="lastName"
              value={lastName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputLabel
          content={t(
            'components.LawyerInformation.StaffLawyerInformationForm.staffId'
          )}
        >
          <input type="text" name="staffId" value={staffId} readOnly />
        </InputLabel>
      </div>
    </Card>
  );
};

StaffLawyerInformationForm.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object.isRequired,
  options: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isStaffLawyer: PropTypes.bool,
};

export default StaffLawyerInformationForm;
