import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import { ActionCard, FontAwesomeIcon } from 'js/components/design-system';
import {
  faGavel,
  faUserPlus,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { LAWYER_TYPES, LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';

const SelectLawyerModal = (props) => {
  const { t } = useTranslation();
  const { mounted, onClose, onSelect, selectLawyer } = props;

  return (
    <Modal
      className={'select-lawyer-modal'}
      cancelButtonText={t(`common.cancel`)}
      onClickCancel={onClose}
      titleText={t('components.SelectLawyerModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.SelectLawyerModal.title')}</h2>
      <div className="form-row select-lawyer-button-container">
        <ActionCard
          footerAction={
            <button
              onClick={() => onSelect({ modalMounted: LAWYER_TYPES.STAFF })}
              name="staff"
            >
              {t('components.SelectLawyerModal.select')}
            </button>
          }
        >
          <FontAwesomeIcon icon={faGavel} />
          <h2>{t('components.SelectLawyerModal.staff')}</h2>
        </ActionCard>

        <ActionCard
          footerAction={
            <button
              onClick={() => onSelect({ modalMounted: LAWYER_TYPES.COOP })}
              name="coop"
            >
              {t('components.SelectLawyerModal.select')}
            </button>
          }
        >
          <FontAwesomeIcon icon={faUserPlus} />
          <h2>{t('components.SelectLawyerModal.cooperating')}</h2>
        </ActionCard>

        <ActionCard
          footerAction={
            <button
              onClick={() =>
                selectLawyer({
                  lawyer: {},
                  lawyerType: LAWYER_TYPE_ENUM.NON_COOP,
                })
              }
              name="staff"
            >
              {t('components.SelectLawyerModal.select')}
            </button>
          }
        >
          <FontAwesomeIcon icon={faUserTimes} />
          <h2>{t('components.SelectLawyerModal.nonCooperating')}</h2>
        </ActionCard>
      </div>
    </Modal>
  );
};

SelectLawyerModal.propTypes = {
  ...Modal.propTypes,
  onSelect: PropTypes.func.isRequired,
};

export default SelectLawyerModal;
