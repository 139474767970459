import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import { isEmpty } from 'js/utilities/validation';
import { filenameForDocument } from './functions';

const DeleteDocumentModal = (props) => {
  const { t } = useTranslation();
  const { documentPendingDeletion, isDeleting, onClose, onClickDelete } = props;
  const hasDocument = !isEmpty(documentPendingDeletion);
  const filename = hasDocument
    ? filenameForDocument(documentPendingDeletion)
    : '';
  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={onClose}
      titleText={t('components.ClientDocuments.DeleteDocumentModal.title')}
      mounted={hasDocument}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.ClientDocuments.DeleteDocumentModal.title')}</h2>
      <p>
        {t('components.ClientDocuments.DeleteDocumentModal.text', { filename })}
      </p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isDeleting}
          onClick={onClose}
        >
          {t('common.cancel')}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          disabled={isDeleting}
          onClick={onClickDelete}
        >
          {isDeleting ? t('common.deleting') : t('common.delete')}
        </button>
      </div>
    </Modal>
  );
};

DeleteDocumentModal.defaultProps = {
  isDeleting: false,
};

DeleteDocumentModal.propTypes = {
  documentPendingDeletion: PropTypes.shape({
    documentName: PropTypes.string,
    documentType: PropTypes.string,
  }).isRequired,
  isDeleting: PropTypes.bool,
  onClose: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default DeleteDocumentModal;
