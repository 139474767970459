import React, { useEffect, useState, useRef } from 'react';
import CIFPrintLayout from './cif-print-layout';
import { getCIFDataEffect, printViewEffect } from './effects';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ActivityIndicatorCard } from 'js/components/design-system';
import Page from 'js/components/page';

const PrintSingleCIF = () => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [CIFData, setCIFData] = useState({});
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { params = {} } = useRouteMatch();
  const { cif } = params;
  const getCifAbortControllerRef = useRef(null);

  const getCIFDataEffectOptions = {
    api,
    cif,
    setCIFData,
    setActive,
    setError,
    getCifAbortControllerRef,
  };

  useEffect(getCIFDataEffect(getCIFDataEffectOptions), []);
  useEffect(printViewEffect({ CIFData, active }), [
    CIFData?.caseInfo?.caseInformationResponse?.cif,
    active,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.cifData', {
            message: error.message,
          })}
        </p>
      )}
      {!active && !error && <CIFPrintLayout cifData={CIFData} />}
    </Page>
  );
};

export default PrintSingleCIF;
