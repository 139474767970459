import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { companiesListBreadcrumbsHistory } from './functions';

const CompaniesListBreadcrumbs = () => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="companies-list-breadcrumbs"
      history={companiesListBreadcrumbsHistory(t)}
    />
  );
};

export default CompaniesListBreadcrumbs;
