import { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'js/utilities/validation';
import { CurrentUserContext } from './context';

const CurrentUserRenderController = (props) => {
  const {
    renderOnActive,
    renderOnInactive,
    renderOnError,
    renderUntilLoaded,
  } = props;
  const { hasLoaded, currentUser } = useContext(CurrentUserContext);

  if (hasLoaded && !isEmpty(currentUser)) {
    if (currentUser.isActive) {
      return renderOnActive();
    } else {
      return renderOnInactive();
    }
  } else if (hasLoaded && isEmpty(currentUser)) {
    return renderOnError();
  } else {
    return renderUntilLoaded();
  }
};

CurrentUserRenderController.defaultProps = {
  renderUntilLoaded: () => null,
};

CurrentUserRenderController.propTypes = {
  renderOnActive: PropTypes.func.isRequired,
  renderOnInactive: PropTypes.func.isRequired,
  renderOnError: PropTypes.func.isRequired,
  renderUntilLoaded: PropTypes.func,
};

export default CurrentUserRenderController;
