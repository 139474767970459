import React from 'react';
import HeaderMenuItem from './header-menu-item';
import HeaderMenuLink from './header-menu-link';

const HeaderMenuLinkedItem = (props) => {
  const {
    className,
    linkTo,
    icon,
    content,
    isMenuVisible,
    setMenuVisibility,
  } = props;

  return (
    <HeaderMenuItem
      className={className}
      isMenuVisible={isMenuVisible}
      setMenuVisibility={setMenuVisibility}
    >
      <HeaderMenuLink
        className={className}
        linkTo={linkTo}
        icon={icon}
        content={content}
        setMenuVisibility={setMenuVisibility}
      />
    </HeaderMenuItem>
  );
};

HeaderMenuLinkedItem.propTypes = {
  ...HeaderMenuItem.propTypes,
  ...HeaderMenuLink.propTypes,
};

export default HeaderMenuLinkedItem;
