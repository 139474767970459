import { presentBanner, dismissBanner } from './functions';

export const presentBannerEffect = (options = {}) => {
  const { containerRef } = options;
  return (bannerOptions = {}) => {
    if (containerRef && containerRef.current) {
      return presentBanner(containerRef.current, bannerOptions);
    } else {
      return '';
    }
  };
};

export const dismissBannerEffect = (options = {}) => {
  const { containerRef } = options;
  return (bannerId, animated) => {
    if (containerRef && containerRef.current) {
      dismissBanner(containerRef.current, bannerId, animated);
    }
  };
};
