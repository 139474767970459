export const clientStatusOptions = (t, isPrimaryClient = true) => {
  if (isPrimaryClient) {
    return primaryClientStatusOptions(t);
  } else {
    return dependentStatusOptions(t);
  }
};

export const primaryClientStatusOptions = (t) => [
  { value: 1, title: t('common.clientStatusByCode.1') },
  { value: 2, title: t('common.clientStatusByCode.2') },
  { value: 3, title: t('common.clientStatusByCode.3') },
];

export const dependentStatusOptions = (t) => [
  { value: 3, title: t('common.clientStatusByCode.3') },
  { value: 4, title: t('common.clientStatusByCode.4') },
  { value: 5, title: t('common.clientStatusByCode.5') },
];

export const clientStatusOptionsForPrimaryClientStatus = (
  t,
  primaryClientStatus
) => {
  switch (Number(primaryClientStatus)) {
    case 1:
      return [
        { value: 1, title: t('common.clientStatusByCode.1') },
        { value: 4, title: t('common.clientStatusByCode.4') },
        { value: 5, title: t('common.clientStatusByCode.5') },
      ];
    case 2:
      return [
        { value: 2, title: t('common.clientStatusByCode.2') },
        { value: 4, title: t('common.clientStatusByCode.4') },
        { value: 5, title: t('common.clientStatusByCode.5') },
      ];
    case 3:
      return [
        { value: 3, title: t('common.clientStatusByCode.3') },
        { value: 5, title: t('common.clientStatusByCode.5') },
      ];
    default:
      return [];
  }
};

export const prefixOptions = (t) => [
  { value: 'Mr.', title: t('common.prefixes.mr') },
  { value: 'Mrs.', title: t('common.prefixes.mrs') },
  { value: 'Ms.', title: t('common.prefixes.ms') },
  { value: 'M', title: t('common.prefixes.m') },
  { value: 'Me', title: t('common.prefixes.me') },
  { value: 'Mlle', title: t('common.prefixes.mlle') },
  { value: 'Mme', title: t('common.prefixes.mme') },
];

export const provinceOptions = (t) => [
  { value: 'AB', title: t('common.provincesByCode.AB') },
  { value: 'BC', title: t('common.provincesByCode.BC') },
  { value: 'MB', title: t('common.provincesByCode.MB') },
  { value: 'NB', title: t('common.provincesByCode.NB') },
  { value: 'NL', title: t('common.provincesByCode.NL') },
  { value: 'NT', title: t('common.provincesByCode.NT') },
  { value: 'NS', title: t('common.provincesByCode.NS') },
  { value: 'NU', title: t('common.provincesByCode.NU') },
  { value: 'ON', title: t('common.provincesByCode.ON') },
  { value: 'PE', title: t('common.provincesByCode.PE') },
  { value: 'QC', title: t('common.provincesByCode.QC') },
  { value: 'SK', title: t('common.provincesByCode.SK') },
  { value: 'YT', title: t('common.provincesByCode.YT') },
];
