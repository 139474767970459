import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanClientSummary } from 'js/components/client-summary';
import { routePaths } from 'js/components/router/route-paths';
import { caseApiKey, cleanCase } from 'js/utilities/cases';
import { queryForParams } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';
import {
  focusInputRef,
  transformCsqToColumns,
  transformPreviousCSQAnswersToValues,
} from './functions';
import {
  cleanLawyerSummary,
  cleanStaffLawyerSummary,
} from 'js/components/lawyer-summary';
import {
  LAWYER_TYPE_ENUM,
  isNonCooperatingLawyerType,
} from 'js/utilities/lawyers';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setCaseInfoLoading,
    setClientLoading,
    setClientLoaded,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setCaseInfoLoading(false);
      setClientLoading(false);
      setClientLoaded(false);
    };
  };
};

export const focusCaseNumberInputEffect = (options = {}) => {
  const { caseNumberInputRef } = options;
  return () => focusInputRef(caseNumberInputRef);
};

export const onChangeInputCaseNumberEffect = (options = {}) => {
  const { setInputCaseNumber } = options;
  return (name, value) => setInputCaseNumber(value);
};

export const onBlurInputCaseNumberEffect = (options = {}) => {
  const { touchCaseNumberInput } = options;
  return (e) => {
    const { value = '' } = e.target;
    if (value.length > 0) {
      touchCaseNumberInput();
    }
  };
};

export const setConfirmedCaseNumberEffect = (options = {}) => {
  const {
    history,
    isInputCaseNumberValid,
    inputCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
  } = options;
  return () => {
    if (isInputCaseNumberValid) {
      untouchCaseNumberInput();
      const nextQuery = queryForParams({ caseNumber: inputCaseNumber });
      history.replace(nextQuery.search);
    } else {
      touchCaseNumberInput();
    }
  };
};

export const resetCaseNumberEffect = (options = {}) => {
  const {
    history,
    setCsq,
    setCaseInfo,
    setClientSummary,
    setInputCaseNumber,
    setClientLoaded,
    untouchAll,
    untouchCaseNumberInput,
    caseNumberInputRef,
  } = options;
  return () => {
    setCsq({});
    setCaseInfo({});
    setClientSummary({});
    setInputCaseNumber('');
    setClientLoaded(false);
    untouchAll();
    untouchCaseNumberInput();
    focusInputRef(caseNumberInputRef);
    history.replace(routePaths.csqEntry);
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    untouchCaseNumberInput,
    setCaseInfoLoading,
    setCaseInfo,
    setClientLoading,
    setClientLoaded,
    setClientSummary,
    getCaseInfoAbortControllerRef,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (!caseNumber) {
      return;
    }

    untouchCaseNumberInput();
    setCaseInfo({});
    setClientSummary({});
    setClientLoading(false);
    setClientLoaded(false);
    setCaseInfoLoading(true);

    // Abort any pending client requests:
    executeAbortControllerRefs([getClientAbortControllerRef]);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = caseApiKey(caseNumber);
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CSQEntry.caseRequestError'),
              preventRedirect: true,
            },
          },
        }
      );
      setCaseInfo(cleanCase(json));
      setCaseInfoLoading(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseInfoLoading(false);
      }
    }
  };
};

export const getClientEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    setClientLoading,
    setClientLoaded,
    setClientSummary,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (!clientId || !clientCode) {
      return;
    }

    setClientLoading(true);
    setClientLoaded(false);

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal: clientSignal } = getClientAbortControllerRef.current;

    try {
      const clientUrl = `/Client/${clientId}/${clientCode}`;
      const { json = {} } = await api.getJson(
        clientUrl,
        { signal: clientSignal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CSQEntry.clientRequestError'),
            },
          },
        }
      );

      const clientSummary = cleanClientSummary(json);
      setClientSummary(clientSummary);
      setClientLoading(false);
      setClientLoaded(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setClientLoading(false);
        setClientLoaded(true);
      }
    }
  };
};

export const getPreviousCsqEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setLoadingPreviousCsq,
    setHasPreviousCsq,
    getPreviousCsqAbortControllerRef,
    setCsq,
    questions,
  } = options;

  return async () => {
    if (!caseNumber) {
      return;
    }

    setLoadingPreviousCsq(true);
    setHasPreviousCsq(false);

    rotateAbortControllerRef(getPreviousCsqAbortControllerRef);
    const { signal } = getPreviousCsqAbortControllerRef.current;

    const endpoint = `/Case/CSQ/${encodeURIComponent(caseNumber)}`;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: {
            bypass: true,
          },
          error: {
            context: {
              message: t('components.CSQEntry.getPreviousCSQError'),
            },
          },
        }
      );

      const nonNullProperties = Object.keys(json).reduce((next, key) => {
        const value = json[key];
        if (key.toLowerCase() !== 'date' && !!value) {
          return { ...next, [key]: value };
        } else {
          return next;
        }
      }, {});

      const hasCsq = !isEmpty(nonNullProperties);
      const next = transformPreviousCSQAnswersToValues(json, questions);
      setCsq(next);
      setHasPreviousCsq(hasCsq);
      setLoadingPreviousCsq(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingPreviousCsq(false);
        throw error;
      }
    }
  };
};

export const getLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    lawyerType,
    setLawyer,
    getLawyerAbortControllerRef,
  } = options;

  const isNonCoop = isNonCooperatingLawyerType(lawyerType);
  const isStaffLawyer = Number(lawyerType) === LAWYER_TYPE_ENUM.STAFF;

  return async () => {
    if (!lawyerId && !isNonCoop) {
      return;
    }

    if (isNonCoop) {
      setLawyer({ lawyerType: LAWYER_TYPE_ENUM.NON_COOP });
      return;
    }

    rotateAbortControllerRef(getLawyerAbortControllerRef);
    const { signal } = getLawyerAbortControllerRef.current;

    const endpoint = isStaffLawyer
      ? `/Lawyer/Staff/${lawyerId}`
      : `/Lawyer/${lawyerId}`;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseLawyer.lawyerRequestError'),
            },
          },
        }
      );
      const nextLawyer = isStaffLawyer
        ? cleanStaffLawyerSummary(json)
        : cleanLawyerSummary(json);

      setLawyer(nextLawyer);
    } catch (error) {
      return;
    }
  };
};

export const postCsqEffect = (options = {}) => {
  const {
    t,
    api,
    validateAll,
    touchAll,
    caseNumber,
    csq,
    setSaving,
    onComplete,
    postCsqAbortControllerRef,
  } = options;

  return async () => {
    const { isValid } = validateAll(csq);
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    const body = transformCsqToColumns(csq);

    setSaving(true);

    rotateAbortControllerRef(postCsqAbortControllerRef);
    const { signal } = postCsqAbortControllerRef.current;

    const endpoint = `/Case/CSQ/${encodeURIComponent(caseNumber)}`;

    try {
      await api.postJson(
        endpoint,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CSQEntry.postCsqSuccess'),
            },
          },
          error: {
            context: {
              message: (response) => {
                if (response.status === 409) {
                  return t('components.CSQEntry.conflict', { caseNumber });
                } else {
                  return t('components.CSQEntry.postCsqError');
                }
              },
            },
          },
        }
      );

      setSaving(false);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
        throw error;
      }
    }
  };
};

export const onSubmitEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { setCsq } = options;
  return (e) => {
    const { name, value } = e.target;
    setCsq((csq) => ({ ...csq, [name]: value }));
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};
