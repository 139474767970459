import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faPhone,
  faPrint,
  faAt,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  IndentedCard,
  FontAwesomeIcon,
  LabelValuePair,
} from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { resolveProvinceName } from 'js/utilities/strings';
import {
  isStaffLawyerType,
  isStrictCoopLawyerType,
  isStrictNonCoopLawyerType,
} from 'js/utilities/lawyers';
import { linkComponent } from './functions';

const LawyerSummary = (props) => {
  const { t } = useTranslation();
  const {
    className,
    lawyer = {},
    accessory,
    externalNonCoopLawyer = false,
    buttonRoute = '',
    routeButtonText = '',
  } = props;
  const {
    lawyerId,
    staffId,
    firstName,
    lastName,
    city = '',
    province = '',
    fax,
    phoneNumber,
    email,
    lawyerType,
    lawyerInactive = false,
  } = lawyer;

  const provinceName = resolveProvinceName(t, province);
  const lawyerProvided = Object.keys(lawyer).length > 0;
  const isStaffLawyer = isStaffLawyerType(lawyerType);
  const isCoopLawyer = isStrictCoopLawyerType(lawyerType);
  const isNonCoopLawyer =
    isStrictNonCoopLawyerType(lawyerType) || externalNonCoopLawyer;
  const isSelectableLawyer = isStaffLawyer || isCoopLawyer;

  let linkTo = '';

  if (lawyerProvided && isSelectableLawyer) {
    linkTo = routePathReplacingParams(routePaths.lawyer, {
      lawyerId: lawyerId || staffId,
    });
  }

  const headingComponent = (
    <div className="lawyer-summary-heading">
      {isStaffLawyer && (
        <p className="lawyer-summary-staff-id">
          {t('components.LawyerSummary.staffId', {
            staffId,
          })}
        </p>
      )}
      {isCoopLawyer && (
        <p className="lawyer-summary-staff-id">
          {t('components.LawyerSummary.lawyerId', { lawyerId })}
        </p>
      )}
      <h1>
        {lawyerProvided &&
          isSelectableLawyer &&
          `${lastName}, ${firstName}`.trim()}
        {!lawyerProvided &&
          !isNonCoopLawyer &&
          t('components.LawyerSummary.emptyState')}
        {isNonCoopLawyer && t('components.LawyerSummary.nonCoopLawyer')}
        <InactiveTag inactive={lawyerInactive} />
      </h1>
    </div>
  );

  const accessoryComponent = (
    <div className="lawyer-summary-accessory">{accessory}</div>
  );

  return (
    <IndentedCard className={classnames('lawyer-summary', className)}>
      <div className="lawyer-summary-content">
        {linkComponent(headingComponent, linkTo, {
          className: 'lawyer-summary-link',
        })}
        {lawyerProvided && !isNonCoopLawyer && (
          <div className="lawyer-summary-body">
            {city && provinceName && (
              <div className="lawyer-summary-column">
                {`${city}, ${provinceName}`}
              </div>
            )}
            {fax && (
              <div className="lawyer-summary-column">
                <LabelValuePair
                  label={<FontAwesomeIcon icon={faPrint} />}
                  value={fax}
                />
              </div>
            )}
            {phoneNumber && (
              <div className="lawyer-summary-column">
                <LabelValuePair
                  label={<FontAwesomeIcon icon={faPhone} />}
                  value={phoneNumber}
                />
              </div>
            )}
            {email && (
              <div className="lawyer-summary-column">
                <LabelValuePair
                  label={<FontAwesomeIcon icon={faAt} />}
                  value={
                    <a className="link" href={`mailto:${email}`}>
                      {email}
                    </a>
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      {!buttonRoute &&
        lawyerProvided &&
        isSelectableLawyer &&
        linkComponent(accessoryComponent, linkTo)}
      {buttonRoute && (
        <Link
          className="button button-highlight page-action-button"
          to={buttonRoute}
        >
          {routeButtonText}
        </Link>
      )}
    </IndentedCard>
  );
};

LawyerSummary.defaultProps = {
  accessory: <FontAwesomeIcon icon={faChevronRight} />,
};

LawyerSummary.propTypes = {
  className: PropTypes.string,
  lawyer: PropTypes.object.isRequired,
  accessory: PropTypes.node,
  externalNonCoopLawyer: PropTypes.bool,
  buttonRoute: PropTypes.string,
  routeButtonText: PropTypes.string,
};

export default LawyerSummary;
