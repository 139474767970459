import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DropdownMenu, InputHelp } from 'js/components/design-system';
import {
  ValidationContext,
  ValidationProvider,
} from 'js/components/validation';
import SubjectInputs from './subject-inputs';
import {
  restoreSearchSubjectAndCriteriaEffect,
  onSelectSubjectEffect,
  onChangeSearchCriteriaEffect,
  onKeyDownEffect,
  onSubmitSearchEffect,
} from './effects';
import {
  SEARCH_SUBJECTS,
  searchInputValidationRules,
  validateSearchCriteria,
  isSubjectInputTouched,
  persistSearchSubject,
  restoreSearchSubject,
  getSubjectOptions,
  getInitialSearchCriteriaForSubject,
} from './functions';

const SearchInput = (props) => {
  const { className, initialModel, initialQuery } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);

  // Get the initial search subject and criteria.
  // This may be overwritten by restoreSearchSubjectEffect after the search input has mounted,
  // but only if a subject was previously selected.
  const initialSubjectIndex = 0;
  const initialSubject = SEARCH_SUBJECTS[initialSubjectIndex];
  const initialSearchCriteria = getInitialSearchCriteriaForSubject(
    initialSubject
  );

  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(
    initialSubjectIndex
  );
  const [selectedSubject, setSelectedSubject] = useState(initialSubject);
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);

  const isInputValid = validateSearchCriteria({
    subject: selectedSubject,
    searchCriteria,
    validate,
  });
  const isInputTouched = isSubjectInputTouched({
    subject: selectedSubject,
    isTouched,
  });
  const untouchAll = () => untouch(Object.keys(searchInputValidationRules));

  useEffect(
    restoreSearchSubjectAndCriteriaEffect({
      initialModel,
      initialQuery,
      subjects: SEARCH_SUBJECTS,
      restoreSearchSubject,
      setSelectedSubject,
      setSelectedSubjectIndex,
      setSearchCriteria,
    }),
    [initialModel, initialQuery.search]
  );

  const onSubmitSearch = onSubmitSearchEffect({
    subject: selectedSubject,
    searchCriteria,
    initialModel,
    initialQuery,
    validate,
    touch,
    history,
  });

  const onSelectSubject = onSelectSubjectEffect({
    selectedSubject,
    subjects: SEARCH_SUBJECTS,
    untouchAll,
    persistSearchSubject,
    setSelectedSubject,
    setSelectedSubjectIndex,
    setSearchCriteria,
  });

  const subjectOptions = getSubjectOptions({
    t,
    subjects: SEARCH_SUBJECTS,
    onSelectSubject,
  });

  const SubjectInput = SubjectInputs[selectedSubject];

  return (
    <div className={classnames('search-input', className)}>
      <InputHelp
        invalid={isInputTouched && !isInputValid}
        validationContent={t(
          `components.controls.SearchInput.subjects.${selectedSubject}.validationContent`
        )}
      >
        <div className="search-input-content">
          <div className="search-input-wrapper">
            {SubjectInput && (
              <SubjectInput
                {...searchCriteria}
                onChange={onChangeSearchCriteriaEffect({ setSearchCriteria })}
                onKeyDown={onKeyDownEffect({ onEnter: onSubmitSearch })}
              />
            )}
            <DropdownMenu
              options={subjectOptions}
              selectedOptionIndex={selectedSubjectIndex}
            />
          </div>
          <button className="button button-highlight" onClick={onSubmitSearch}>
            {t('components.controls.SearchInput.search')}
          </button>
        </div>
      </InputHelp>
    </div>
  );
};

SearchInput.defaultProps = {
  initialModel: '',
  initialQuery: { params: {}, search: '' },
};

SearchInput.propTypes = {
  className: PropTypes.string,
  initialModel: PropTypes.string,
  initialQuery: PropTypes.shape({
    params: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    search: PropTypes.string,
  }),
};

const ValidatedSearchInput = (props) => (
  <ValidationProvider rules={searchInputValidationRules}>
    <SearchInput {...props} />
  </ValidationProvider>
);

export default ValidatedSearchInput;
