import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import Page from 'js/components/page';
import RecentItems from 'js/components/recent-items';
import FixedHeader from 'js/components/headers/fixed-header';
import { CurrentUserContext } from 'js/components/current-user';
import SearchInput from 'js/components/controls/search-input';
import { routePaths } from 'js/components/router/route-paths';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import HomeResponse404 from './home-response-404';

const Home = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { currentUser = {} } = useContext(CurrentUserContext);
  const isResponse404 = path === routePaths.search404;

  return (
    <Page
      className="home"
      title={t('components.Home.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <div className="home-search-container">
            {isResponse404 && <HomeResponse404 />}
            {!isResponse404 && (
              <h1>
                {t('components.Home.welcome', {
                  name: currentUser.firstname,
                })}
              </h1>
            )}
            <SearchInput />
            <div className="home-action-buttons">
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                  userGroups.intakeSpecialist,
                  userGroups.feedbackSpecialist,
                  userGroups.support,
                ]}
              >
                <Link className="link" to={routePaths.newClient}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span>{t('components.Home.newClient')}</span>
                </Link>
              </GroupPermissionsGate>
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                ]}
              >
                <Link className="link" to={routePaths.newLawyer}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span>{t('components.Home.newLawyer')}</span>
                </Link>
              </GroupPermissionsGate>
            </div>
          </div>
          <div className="home-recent-cases">
            <h1>{t('components.Home.recentlyViewedCases')}</h1>
            <RecentItems />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
