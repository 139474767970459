import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  Card,
  Checkbox,
  InputHelp,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { onCheckEffect } from './effects';

const Spacer = () => <div />;

const Account = (props) => {
  const { t } = useTranslation();
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editClientInformation');
  const { clientId, formData = {}, onChange } = props;
  const {
    feeScheduleName = '',
    outstandingAccount = false,
    version = '',
  } = formData;

  const notesPath = routePathReplacingParams(
    routePaths.clientOutstandingBalanceNotes,
    { id: clientId, clientCode: '00' }
  );

  return (
    <Card className="client-account-form">
      <h2>{t('components.ClientInformation.Account.accountTitle')}</h2>
      <div className="form-row">
        <InputHelp
          helpContent={
            <Link to={notesPath}>
              {t(
                'components.ClientInformation.Account.outstandingAccountNotes'
              )}
            </Link>
          }
        >
          <Checkbox
            name="outstandingAccount"
            onChange={onCheckEffect({ onChange })}
            checked={outstandingAccount}
            label={t('components.ClientInformation.Account.outstandingAccount')}
            disabled={!hasEditPermission}
          />
        </InputHelp>

        <InputLabel
          content={t('components.ClientInformation.Account.feeSchedule')}
        >
          <input type="text" readOnly value={feeScheduleName} />
        </InputLabel>

        <InputLabel content={t('components.ClientInformation.Account.version')}>
          <input type="text" readOnly value={version} />
        </InputLabel>

        <Spacer />
      </div>
    </Card>
  );
};

Account.propTypes = {
  clientId: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Account;
