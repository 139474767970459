import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ListPrintView, queryForListPrintView } from 'js/components/print';
import { useTranslation } from 'react-i18next';
import { unionsListPrintRequestEffect } from './effects';
import { queryForUnions, getUnionPrintAddress } from './functions';

const UnionsListPrintView = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const { search = '' } = useLocation();
  const query = queryForListPrintView(queryForUnions(search));

  return (
    <ListPrintView
      request={unionsListPrintRequestEffect({ api, query })}
      renderTableHeaders={() => (
        <tr>
          <th>{t('components.UnionsListPrintView.columns.number')}</th>
          <th>{t('components.UnionsListPrintView.columns.name')}</th>
          <th>{t('components.UnionsListPrintView.columns.address')}</th>
          <th>{t('components.UnionsListPrintView.columns.phone')}</th>
          <th>{t('components.UnionsListPrintView.columns.fax')}</th>
        </tr>
      )}
      renderTableRow={(item) => (
        <tr key={item.unionNumber}>
          <td>{item.unionNumber}</td>
          <td>{item.unionName}</td>
          <td
            dangerouslySetInnerHTML={{ __html: getUnionPrintAddress(item) }}
          />
          <td>
            <div>{item.locPhone1}</div>
            {item.locPhone2 && <div>{item.locPhone2}</div>}
          </td>
          <td>{item.locFax}</td>
        </tr>
      )}
      emptyStateTitle={t('components.UnionsListPrintView.emptyStateTitle')}
      headerProps={{
        title: t('components.UnionsListPrintView.title'),
        heading: t('components.UnionsListPrintView.heading'),
        printOnly: false,
      }}
      displayFooter={false}
    />
  );
};

export default UnionsListPrintView;
