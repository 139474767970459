import React from 'react';
import { Card } from '../components/cards';

const Fonts = () => {
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Text</h1>

        <Card>
          <h2>Body Text:</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            id feugiat neque. Cras non sem ac libero ullamcorper interdum nec at
            velit. Nulla eu nibh a enim egestas pellentesque a eu nunc. Donec
            vitae mollis ipsum. Nam vestibulum tempus volutpat. Nunc vestibulum
            id purus id vestibulum. Ut dapibus et sapien nec convallis. Nunc
            laoreet erat non neque hendrerit tristique. In in tincidunt nibh.
            Nulla velit elit, fermentum sed nisl viverra, lobortis rhoncus erat.
            Nullam purus erat, vulputate vitae dolor ut, egestas dignissim
            sapien. Etiam in tristique erat. Proin feugiat eu metus eu
            facilisis. Fusce pulvinar erat nec magna accumsan facilisis.
            Phasellus sollicitudin magna a efficitur lobortis.
          </p>
          <p>
            Sed suscipit, risus sed varius pulvinar, arcu dolor porttitor est,
            quis convallis lectus ipsum at elit. Vivamus bibendum ac est et
            maximus. Maecenas eleifend eleifend eros et iaculis. Mauris laoreet
            id nulla non finibus. Phasellus sed suscipit arcu, quis tincidunt
            diam. Sed et ex libero. Maecenas eleifend nunc id mi laoreet
            pharetra. Nulla at mauris blandit nulla molestie auctor eu in sem.
            Pellentesque vitae congue dui. Aliquam id pretium tortor. Integer
            pellentesque neque nec dui posuere ullamcorper. Proin faucibus nisl
            eget sem mattis molestie. Aenean et dignissim felis. Ut tempor
            interdum interdum.
          </p>
          <p>
            This is a{' '}
            <a className="link" href="#">
              Link
            </a>{' '}
            within a line of text.
          </p>
        </Card>

        <Card>
          <h2>Unordered List:</h2>
          <ul>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
          </ul>
        </Card>

        <Card>
          <h2>Ordered List:</h2>
          <ol>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
          </ol>
        </Card>

        <Card>
          <h2>Unstyled List:</h2>
          <ul className="unstyled-list">
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
          </ul>
        </Card>
      </div>
    </div>
  );
};

export default Fonts;
