import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const CompanyPrintDetails = (props) => {
  const { t } = useTranslation();
  const { className, union = {} } = props;

  const {
    unionNumber = '',
    unionName = '',
    locAddr1 = '',
    locAddr2 = '',
    locCity = '',
    locProv = '',
    locPcode = '',
    locPhone1 = '',
    locPhone2 = '',
    locFax = '',
  } = union;

  const streetAddress = `${locAddr1} ${locAddr2}`.trim();

  return (
    <div
      className={classnames('union-print-details', className)}
      data-print-managed
      data-print-only
    >
      <h2>
        {unionName} {t('components.UnionDetailsPage.formTitle')}
      </h2>
      <div className="union-print-details-row">
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.unionNumber')}</span>
          <span>{unionNumber}</span>
        </div>
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.unionName')}</span>
          <span>{unionName}</span>
        </div>
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.locPhone1')}</span>
          <span>{locPhone1}</span>
        </div>
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.locPhone2')}</span>
          <span>{locPhone2}</span>
        </div>
      </div>
      <div className="union-print-details-row">
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.locFax')}</span>
          <span>{locFax}</span>
        </div>
        <div className="union-print-details-item">
          <span>{t('components.UnionDetailsPage.locAddr1')}</span>
          <span>{`${streetAddress}, ${locCity}, ${locProv}, ${locPcode}`}</span>
        </div>
      </div>
    </div>
  );
};

CompanyPrintDetails.propTypes = {
  className: PropTypes.string,
  union: PropTypes.object.isRequired,
};

export default CompanyPrintDetails;
