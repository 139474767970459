import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, DropdownMenu } from 'js/components/design-system';
import ReportsListItem from './list-item';
import {
  reportsDateRanges,
  reportsListFilterOptions,
  allReportsChecked,
} from './functions';
import { onChangeFolderFrequencyCheckboxEffect } from './effects';
import { PromptContext } from 'js/components/prompt';

const ReportsList = (props = {}) => {
  const {
    reports = [],
    folderData = {},
    reportsFilterIndex = 0,
    isModifyingReport,
    isRunningReport,
    onChangeFolderFrequency,
    onFilter,
    onSaveReport,
    onRunReport,
    onMoveReport,
    onDuplicateReport,
    onDeleteReport,
    onSaveAsReport,
  } = props;
  const { t } = useTranslation();
  const { prompt } = useContext(PromptContext);

  return (
    <Fragment>
      <div className="reports-list-header">
        <ul className="unstyled-list reports-list-date-ranges">
          {reportsDateRanges.map((name) => (
            <li key={name}>
              <Checkbox
                label={t(`components.ReportsList.dateRanges.${name}`)}
                name={name}
                checked={allReportsChecked(reports, name)}
                disabled={isModifyingReport}
                onChange={onChangeFolderFrequencyCheckboxEffect({
                  prompt,
                  onChangeFolderFrequency,
                })}
              />
            </li>
          ))}
        </ul>
        <div className="reports-list-filter">
          <DropdownMenu
            options={reportsListFilterOptions(t, onFilter)}
            selectedOptionIndex={reportsFilterIndex}
          />
        </div>
      </div>
      <ul className="unstyled-list reports-list" data-print-managed>
        {reports.map((report) => (
          <ReportsListItem
            key={report.id}
            report={report}
            folderData={folderData}
            isModifyingReport={isModifyingReport}
            isRunningReport={isRunningReport}
            onSaveReport={onSaveReport}
            onRunReport={onRunReport}
            onMoveReport={onMoveReport}
            onDuplicateReport={onDuplicateReport}
            onDeleteReport={onDeleteReport}
            onSaveAsReport={onSaveAsReport}
            reportsFilterIndex={reportsFilterIndex}
          />
        ))}
      </ul>
    </Fragment>
  );
};

ReportsList.defaultProps = {
  isModifyingReport: false,
  isRunningReport: false,
};

ReportsList.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  folderData: PropTypes.object.isRequired,
  reportsFilterIndex: PropTypes.number.isRequired,
  isModifyingReport: PropTypes.bool,
  isRunningReport: PropTypes.bool,
  onChangeFolderFrequency: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onSaveReport: PropTypes.func.isRequired,
  onRunReport: PropTypes.func.isRequired,
  onMoveReport: PropTypes.func.isRequired,
  onDuplicateReport: PropTypes.func.isRequired,
  onDeleteReport: PropTypes.func.isRequired,
  onSaveAsReport: PropTypes.func.isRequired,
};

export default ReportsList;
