import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from 'js/components/design-system';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  onChangeEffect,
  onKeyDownEffect,
  onFilterEffect,
  onResetFilterEffect,
} from './effects';

const FilterInput = (props) => {
  const { className, onFilter, onReset, ...inputProps } = props;
  const [filterQuery, setFilterQuery] = useState('');

  return (
    <div className={classnames('filter-input', className)}>
      <button
        className="filter-input-reset"
        onClick={onResetFilterEffect({
          setFilterQuery,
          onReset,
        })}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <input
        {...inputProps}
        type="text"
        autoComplete="off"
        value={filterQuery}
        onChange={onChangeEffect({
          setFilterQuery,
        })}
        onKeyDown={onKeyDownEffect({
          executeFilter: onFilterEffect({
            filterQuery,
            onFilter,
          }),
        })}
      />
      <div className="filter-input-icon">
        <FontAwesomeIcon
          icon={faSearch}
          onClick={onFilterEffect({
            filterQuery,
            onFilter,
          })}
        />
      </div>
    </div>
  );
};

FilterInput.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onReset: PropTypes.func,
};

export default FilterInput;
