import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChequeRunPage from '../cheque-run-page';
import ChequeRunView from '../cheque-run-view';
import ChequeRunModal from '../cheque-run-modal';
import { setModalMountedEffect, setHasChequesEffect } from './effects';

const ChequeRunCurrent = () => {
  const { t } = useTranslation();
  const [lockSystem, setLockSystem] = useState({});
  const [lockSystemActive, setLockSystemActive] = useState(false);
  const [modalMounted, setModalMounted] = useState(false);
  const [hasCheques, setHasCheques] = useState(false);
  const [timestamp, setTimestamp] = useState(0);

  return (
    <ChequeRunPage
      className="cheque-run-current"
      title={t('components.ChequeRunCurrent.title')}
      onLoadLockSystem={setLockSystem}
      onChangeLockSystemActivity={setLockSystemActive}
      actions={
        <button
          className="button button-highlight cheque-run-action-button"
          disabled={lockSystemActive || !hasCheques}
          onClick={() => setModalMounted(true)}
        >
          {t('components.ChequeRunCurrent.start')}
        </button>
      }
    >
      <ChequeRunModal
        title={t('components.ChequeRunCurrent.modalTitle')}
        mounted={modalMounted}
        setMounted={setModalMountedEffect({ setModalMounted, setTimestamp })}
        lockSystem={lockSystem}
        lockSystemActive={lockSystemActive}
      />
      <ChequeRunView
        className="cheque-run-current-view"
        timestamp={timestamp}
        endpoint="/Cheque/Current"
        onLoadCheques={setHasChequesEffect({ setHasCheques })}
      />
    </ChequeRunPage>
  );
};

export default ChequeRunCurrent;
