import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { searchSortOptions } from './functions';

const SearchSortMenu = (props) => (
  <SortMenu
    className="search-sort-menu"
    sortOptions={searchSortOptions}
    {...props}
  />
);

SearchSortMenu.defaultProps = {
  field: 'firstname',
  direction: 'asc',
};

SearchSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchSortMenu;
