import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  FontAwesomeIcon,
} from 'js/components/design-system';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import EmptyState from 'js/components/controls/empty-state';
import { isEmpty } from 'js/utilities/validation';
import ReportsFoldersSortMenu from './sort-menu';
import { detailUrlForReportsFolder, newReportsFolderUrl } from './functions';

const ReportsFoldersList = (props) => {
  const {
    query,
    folders = [],
    totalFolders,
    isLoadingFolders,
    selectedFolderId,
    allowNewFolder,
    onClickPrevious,
    onClickNext,
    onClickPageNumber,
    onSearch,
    onSearchReset,
    onSort,
  } = props;

  const { t } = useTranslation();

  const pagination = (
    <div className="reports-folders-list-pagination-container">
      <OffsetLimitPagination
        className="reports-folders-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalFolders}
        onClickPrevious={onClickPrevious}
        onClickNext={onClickNext}
        onClickPageNumber={onClickPageNumber}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="reports-folders-list-title-bar">
        <h1>{t('components.ReportsFoldersList.title')}</h1>
        {allowNewFolder && (
          <Link
            className="button button-highlight page-action-button"
            to={newReportsFolderUrl(query)}
          >
            {t('components.ReportsFoldersList.newFolder')}
          </Link>
        )}
      </div>

      <GeneralSearchInput
        placeholder={t('components.ReportsFoldersList.searchPlaceholder')}
        initialQuery={query}
        onSearch={onSearch}
        onReset={onSearchReset}
      />

      <ReportsFoldersSortMenu
        field={query.params.Order}
        direction={query.params.Sort}
        onSelect={onSort}
      />

      {isLoadingFolders && <ActivityIndicatorCard />}

      {!isLoadingFolders && folders.length === 0 && (
        <EmptyState
          title={t(`components.ReportsFoldersList.emptyStateMessage`)}
          subtitle={t(`components.ReportsFoldersList.emptyStateSubtitle`)}
        />
      )}

      {!isLoadingFolders && folders.length > 0 && (
        <Fragment>
          {pagination}
          <ul className="unstyled-list reports-folders-list" data-print-managed>
            {folders.map((folder) => {
              const { id: folderId, name: folderName, owner = {} } = folder;
              const {
                firstname: firstName = '',
                lastname: lastName = '',
              } = owner;
              const hasOwner = !isEmpty(firstName) && !isEmpty(lastName);
              const selected = folderId === parseInt(selectedFolderId);
              return (
                <li key={folderId} className={classnames({ selected })}>
                  <Link to={detailUrlForReportsFolder(folderId, query)}>
                    <div className="reports-folders-list-item-content">
                      <h4>{folderName}</h4>
                      {hasOwner && (
                        <div>
                          {t('components.ReportsFoldersList.owner', {
                            firstName,
                            lastName,
                          })}
                        </div>
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                </li>
              );
            })}
          </ul>
          {pagination}
        </Fragment>
      )}
    </Fragment>
  );
};

ReportsFoldersList.defaultProps = {
  isLoadingFolders: false,
  allowNewFolder: false,
};

ReportsFoldersList.propTypes = {
  query: PropTypes.shape({
    params: PropTypes.object.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalFolders: PropTypes.number.isRequired,
  isLoadingFolders: PropTypes.bool,
  selectedFolderId: PropTypes.string.isRequired,
  allowNewFolder: PropTypes.bool,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPageNumber: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default ReportsFoldersList;
