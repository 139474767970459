import React from 'react';

export const renderAccountOptions = (t, accountOptions = []) => {
  const placeholderOption = (
    <option key={''} value={''} disabled>
      {t('common.defaultSelectPlaceholder')}
    </option>
  );
  const renderedOptions = accountOptions.map((accountOption) => {
    const { acctCode, acctDesc, inactive: accountInactive } = accountOption;
    return (
      <option key={acctCode} value={acctCode} disabled={accountInactive}>
        {acctDesc}
      </option>
    );
  });
  return [placeholderOption, ...renderedOptions];
};
