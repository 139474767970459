import React, { useEffect, useContext, useState, useRef } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { ActivityIndicatorCard, InputHelp } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import { isNewProvincePath } from 'js/utilities/regions';
import { isEmpty } from 'js/utilities/validation';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import ProvinceDetailBreadcrumbs from './breadcrumbs';
import CitiesListCard from './cities-list-card';
import {
  onMountEffect,
  getProvinceEffect,
  postProvinceEffect,
  patchProvinceEffect,
  saveProvinceEffect,
  onChangeProvinceInputEffect,
  onBlurProvinceInputEffect,
  updateProvinceActiveEffect,
  setConfirmDeactivateEffect,
  setAddingCityEffect,
  onEditCityEffect,
  onCancelCityEffect,
  onSaveCityEffect,
} from './effects';
import {
  provinceValidationRules,
  provinceRequiredFields,
  getSaveButtonTitle,
} from './functions';

const ProvinceDetail = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    params: { provinceId },
  } = useRouteMatch();
  const history = useHistory();
  const isNew = isNewProvincePath(pathname);
  const { api = {} } = useContext(FetchContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [isAddingCity, setAddingCity] = useState(false);
  const [editingCityCode, setEditingCityCode] = useState('');
  const isEditingCity = !isEmpty(editingCityCode);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const isActive = isLoading || isSaving;
  const [province, setProvince] = useState({});
  const {
    id = '',
    name = '',
    isActive: isProvinceActive = false,
    cities = [],
  } = province;

  const listContainerRef = useRef(null);
  const shouldRenderList = cities.length > 0 || isAddingCity;

  const getProvinceAbortControllerRef = useRef(null);
  const saveProvinceAbortControllerRef = useRef(null);

  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);

  const { validate, touch, isTouched } = useContext(ValidationContext);
  const validateAll = (data) => validate(data, provinceRequiredFields);
  const touchAll = () => touch(provinceRequiredFields);
  const { isFieldValid } = validateAll(province);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getProvinceAbortControllerRef,
        saveProvinceAbortControllerRef,
      ],
      setLoading,
      setSaving,
    }),
    []
  );

  const getProvince = getProvinceEffect({
    t,
    api,
    isNew,
    provinceId,
    setLoading,
    setProvince,
    getProvinceAbortControllerRef,
  });

  useEffect(getProvince, [isNew, provinceId]);

  const saveEffect = isNew ? postProvinceEffect : patchProvinceEffect;

  const save = saveProvinceEffect({
    t,
    presentStyledBanner,
    saveEffect: saveEffect({
      t,
      api,
      history,
      validateAll,
      touchAll,
      province,
      setSaving,
      saveProvinceAbortControllerRef,
      resetInteractionCount,
    }),
  });

  const onEditCity = onEditCityEffect({ setEditingCityCode });
  const onCancelCity = onCancelCityEffect({
    setAddingCity,
    setEditingCityCode,
  });
  const onSaveCity = onSaveCityEffect({
    setAddingCity,
    setEditingCityCode,
    getProvince,
  });

  return (
    <Page
      className="province-detail-page"
      title={t('components.ProvincesList.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <ProvinceDetailBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          <div className="province-detail-heading">
            <div className="province-detail-heading-left">
              <form
                onSubmit={(e) => e.preventDefault()}
                onClick={incrementInteractionCount}
              >
                <InputHelp
                  validationContent={t(
                    `components.ProvinceDetail.provinceIdValidation`
                  )}
                  invalid={isTouched('id') && !isFieldValid('id')}
                >
                  <input
                    type="text"
                    name="id"
                    placeholder={t(
                      `components.ProvinceDetail.provinceIdPlaceholder`
                    )}
                    value={id}
                    onChange={onChangeProvinceInputEffect({ setProvince })}
                    onBlur={onBlurProvinceInputEffect({ touch })}
                    readOnly={!isNew}
                  />
                </InputHelp>

                <InputHelp
                  validationContent={t(
                    `components.ProvinceDetail.provinceNameValidation`
                  )}
                  invalid={isTouched('name') && !isFieldValid('name')}
                >
                  <input
                    type="text"
                    name="name"
                    placeholder={t(
                      `components.ProvinceDetail.provinceNamePlaceholder`
                    )}
                    value={name}
                    onChange={onChangeProvinceInputEffect({ setProvince })}
                    onBlur={onBlurProvinceInputEffect({ touch })}
                  />
                </InputHelp>
              </form>

              <DeactivateToggle
                disabled={isActive || isNew}
                isActive={isProvinceActive}
                onActivate={updateProvinceActiveEffect({
                  isProvinceActive: true,
                  setProvince,
                  setConfirmDeactivate,
                })}
                onDeactivate={setConfirmDeactivateEffect({
                  confirm: true,
                  setConfirmDeactivate,
                })}
              />
            </div>
            <div className="province-detail-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={save}
                disabled={isActive}
              >
                {getSaveButtonTitle(t, isNew, isSaving)}
              </button>
              <button
                className="button button-highlight page-action-button"
                type="button"
                onClick={setAddingCityEffect({
                  setAddingCity,
                  listContainerRef,
                })}
                disabled={isActive || isNew || isEditingCity || isAddingCity}
              >
                {t(`components.ProvinceDetail.newCity`)}
              </button>
            </div>
          </div>

          {isActive && <ActivityIndicatorCard />}

          <div ref={listContainerRef}>
            {!isActive && !isNew && !shouldRenderList && (
              <EmptyState
                title={t(`components.ProvinceDetail.emptyStateMessage`)}
              />
            )}
            {!isActive && !isNew && shouldRenderList && (
              <ul className="unstyled-list cities-list">
                {cities.map((city) => {
                  return (
                    <li key={city.cityCode}>
                      <CitiesListCard
                        provinceId={provinceId}
                        city={city}
                        isEditing={city.cityCode === editingCityCode}
                        isEditingDisabled={isEditingCity || isAddingCity}
                        onClickEdit={onEditCity}
                        onClickCancel={onCancelCity}
                        onSave={onSaveCity}
                      />
                    </li>
                  );
                })}
                {isAddingCity && (
                  <li>
                    <CitiesListCard
                      provinceId={provinceId}
                      city={{}}
                      isNew
                      isEditing
                      onClickCancel={onCancelCity}
                      onSave={onSaveCity}
                    />
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>

      <DeactivateModal
        title={t('components.ProvinceDetail.DeactivateProvinceModal.title')}
        message={t('components.ProvinceDetail.DeactivateProvinceModal.message')}
        mounted={confirmDeactivate}
        onClose={setConfirmDeactivateEffect({
          confirm: false,
          setConfirmDeactivate,
        })}
        onSave={updateProvinceActiveEffect({
          isProvinceActive: false,
          setProvince,
          setConfirmDeactivate,
        })}
      />

      <NavigationSaveModal
        proceedAfter={async () => await save()}
        shouldBlockNavigation={() => interactionCount > 0}
      />
    </Page>
  );
};

const ValidatedProvinceDetail = (props) => {
  return (
    <ValidationProvider rules={provinceValidationRules}>
      <ProvinceDetail {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingProvinceDetail = (props) => (
  <InteractionTrackingProvider>
    <ValidatedProvinceDetail {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingProvinceDetail;
