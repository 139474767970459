import React from 'react';
import PropTypes from 'prop-types';
import { nl2br } from 'js/utilities/strings';
import { renderQuestions, cleanupCSQData } from './functions';
import { CSQ_SUPPORTED_LANGUAGES, CSQ_STRINGS } from './language';

const CSQPrintLayout = (props) => {
  const { csqData, language } = props;
  const formData = cleanupCSQData(csqData);
  const strings = CSQ_STRINGS[language];
  const { clientAddress = {} } = formData;

  return (
    <div
      className={`print-layout csq-print-layout csq-print-layout-${language}`}
    >
      <div className="print-safe-area csq-print-safe-area">
        {/* Begin Header */}
        <div className="csq-print-header">
          <div className="print-line">
            <span>{strings?.header?.title}</span>
          </div>
          <div className="print-line">
            <h1>{strings?.header?.heading}</h1>
          </div>
        </div>
        {/* End Header */}

        <div className="csq-print-gutter" />

        {/* Begin Case Info */}
        <div className="csq-print-information">
          <div className="csq-print-information-row">
            <div className="csq-print-information-label">
              {strings?.information?.lawyerName}
            </div>
            <div className="csq-print-information-field">
              <span>{formData.lawyerName}</span>
            </div>
          </div>
          <div>
            <p>{formData.clientName}</p>
            <p>
              {clientAddress.address} {clientAddress.address2}
            </p>
            <p>
              {clientAddress.city}, {clientAddress.province}{' '}
            </p>
            <p>{clientAddress.postalCode}</p>
          </div>
          <div className="lawyer-case-info">
            <div className="csq-print-information-row">
              <div className="csq-print-information-label">
                {strings?.information?.lawyerId}
              </div>
              <div className="csq-print-information-field">
                <span>{formData.lawyerId}</span>
              </div>
            </div>

            <div className="csq-print-information-row">
              <div className="csq-print-information-label">
                {strings?.information?.caseNumber}
              </div>
              <div className="csq-print-information-field">
                <span>{formData.cif}</span>
              </div>
            </div>
            <div className="csq-print-information-row">
              <div className="csq-print-information-label">
                {strings?.information?.legalProblemCode}
              </div>
              <div className="csq-print-information-field">
                <span>{formData.caseLegalProblemCode}</span>
              </div>
            </div>

            <div className="csq-print-tickbox-group">
              <div className="csq-print-tickbox" />
              <span>{strings?.information?.contact}</span>
            </div>
          </div>
        </div>
        {/* End Case Info */}

        <div className="csq-print-gutter" />

        {/* Begin Questions */}
        <div className="csq-print-questions">
          {renderQuestions(strings)}

          <div className="csq-print-question">
            <div className="csq-print-question-text">
              <span>10.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: nl2br(strings?.questions?.[10]),
                }}
              />
            </div>
          </div>

          <div className="csq-print-comments">
            <div className="csq-print-comments-line" />
            <div className="csq-print-comments-line" />
            <div className="csq-print-comments-line" />
            <div className="csq-print-comments-line" />
          </div>
        </div>
        {/* End Questions */}

        <div className="csq-print-gutter" />

        {/* Begin Footer */}
        <div className="csq-print-footer">
          <div className="print-line">
            <span>{strings?.footer?.line1}</span>
          </div>
          <div className="print-line">
            <span>{strings?.footer?.line2}</span>
          </div>
        </div>
        {/* End Footer */}
      </div>
    </div>
  );
};

CSQPrintLayout.propTypes = {
  csqData: PropTypes.shape({
    caseInfo: PropTypes.shape({
      caseInformationResponse: PropTypes.shape({
        cif: PropTypes.string,
        legalProblemCode: PropTypes.string,
        legalProblemDesc: PropTypes.string,
        intakeId: PropTypes.string,
        dateOpened: PropTypes.string,
        specialInstructions: PropTypes.string,
      }),
    }),
    primaryInfo: PropTypes.shape({
      clientPersonal: PropTypes.shape({
        clientId: PropTypes.string,
        clientCode: PropTypes.string,
        clientStatusDescription: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        prefix: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      clientEmployment: PropTypes.shape({
        employeeNumber: PropTypes.string,
        seniorityDate: PropTypes.string,
      }),
      clientAddress: PropTypes.shape({
        address: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
      }),
    }),
    dependentInfo: PropTypes.shape({
      clientPersonal: PropTypes.shape({
        clientId: PropTypes.string,
        clientCode: PropTypes.string,
        clientStatusDescription: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        prefix: PropTypes.string,
      }),
      clientEmployment: PropTypes.shape({
        employeeNumber: PropTypes.string,
        seniorityDate: PropTypes.string,
      }),
      clientAddress: PropTypes.shape({
        address: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
      }),
    }),
    lawyerInfo: PropTypes.shape({
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      province: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    staffLawyerInfo: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  language: PropTypes.oneOf(CSQ_SUPPORTED_LANGUAGES).isRequired,
};

export default CSQPrintLayout;
