import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Page from 'js/components/page';
import AuthPlaceholder from 'js/components/auth-placeholder';
import { MSALContext } from 'js/components/msal';

const Login = () => {
  const { t } = useTranslation();
  const { account, login, isAuthenticating } = useContext(MSALContext);

  if (account) {
    return <Redirect to="/" />;
  }

  return (
    <Page className="login" title={t('components.Login.title')}>
      <AuthPlaceholder>
        <button
          className="button button-highlight"
          onClick={() => login()}
          disabled={isAuthenticating}
        >
          {isAuthenticating
            ? t('components.Login.loggingIn')
            : t('components.Login.logIn')}
        </button>
      </AuthPlaceholder>
    </Page>
  );
};

export default Login;
