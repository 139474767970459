import { parse, isValid } from 'date-fns';
import { formatDate } from 'js/utilities/dates';
import { isEmpty } from 'js/utilities/validation';
import { DATE_FORMAT, DATE_PATTERN } from './functions';

/*
 * The string value is used by the regular input
 * for manual entry and basic format validation.
 */
export const setStringValueEffect = (options = {}) => {
  const { selected, setStringValue } = options;
  return () => {
    const stringValue = formatDate(selected, DATE_FORMAT);
    setStringValue(stringValue);
  };
};

/*
 * The safe date value is used by the date picker and
 * is guaranteed to be a valid Date object or null.
 * This is not the same as the `selected` date which may
 * be an invalid Date object. The date picker does not
 * handle invalid dates gracefully.
 */
export const setSafeDateValueEffect = (options = {}) => {
  const { selected, setSafeDateValue } = options;
  return () => {
    const safeDateValue = isValid(selected) ? selected : null;
    setSafeDateValue(safeDateValue);
  };
};

export const onKeyDownEffect = (options = {}) => {
  const { setShowCalendar, onKeyDown } = options;
  return (e) => {
    if (e.key === 'Tab') {
      setShowCalendar(false);
    }

    if (typeof onKeyDown === 'function') {
      onKeyDown(e);
    }
  };
};

export const showCalendarEffect = (options = {}) => {
  const { setShowCalendar, nextShowCalendar, callback } = options;
  return (e) => {
    setShowCalendar(nextShowCalendar);

    if (typeof callback === 'function') {
      callback(e);
    }
  };
};

export const onChangeEffect = (options = {}) => {
  const { setStringValue, onChange } = options;
  return (e) => {
    const { value } = e.target;

    setStringValue(value);

    if (typeof onChange === 'function') {
      const date = parse(value, DATE_FORMAT, new Date());
      if (DATE_PATTERN.test(value) && isValid(date)) {
        onChange(date);
      }
    }
  };
};

export const onBlurEffect = (options = {}) => {
  const { setStringValue, onChange, onBlur } = options;
  return (e) => {
    const { value } = e.target;

    setStringValue(value);

    if (typeof onChange === 'function') {
      const date = parse(value, DATE_FORMAT, new Date());
      if (DATE_PATTERN.test(value) && isValid(date)) {
        onChange(date);
      } else if (!isEmpty(value)) {
        onChange(new Date('Invalid Date'));
      } else {
        onChange(null);
      }
    }

    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };
};
