import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { FetchContext } from 'js/components/fetch';
import {
  InputLabel,
  InputHelp,
  StyledSelect,
  DropdownMenu,
} from 'js/components/design-system';
import Modal from 'js/components/modal';
import { caseStatusesByCode } from 'js/utilities/cases';
import { isEmpty } from 'js/utilities/validation';
import ReportFilterForm from './report-filter-form';
import ReportDateRangeForm from './report-date-range-form';
import {
  cancelRequestsEffect,
  onMountEffect,
  onChangeModalMountedEffect,
  getReportTableFieldsEffect,
  onConfirmSaveEffect,
  onConfirmRunEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onUpdateFilterEffect,
  concatFilterEffect,
  setDefaultsEffect,
  onFilterChangeEffect,
} from './effects';
import {
  storage,
  BOOL_OPERATORS,
  INITIAL_REPORT_FORMAT_INDEX,
  INITIAL_RUN_PARAMS,
  getReportFormatOptions,
  runEditReportValidationRules,
  runReportRequiredFields,
  editReportRequiredFields,
} from './functions';

const RunEditReportModal = (props) => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const getReportTableFieldsAbortControllerRef = useRef(null);

  const [isLoadingTableFields, setLoadingTableFields] = useState(false);
  const [reportTableFields, setReportTableFields] = useState([]);
  const [editableReport, setEditableReport] = useState({});
  const [runParams, setRunParams] = useState(INITIAL_RUN_PARAMS);
  const [selectedFormatIndex, setSelectedFormatIndex] = useState(
    INITIAL_REPORT_FORMAT_INDEX
  );
  const [runButtonId, setRunButtonId] = useState('');

  const {
    className,
    mounted,
    report = {},
    useRunMode,
    isModifyingReport,
    isRunningReport,
    onConfirmSave,
    onConfirmRun,
    onDismiss,
    reportsFilterIndex = 0,
  } = props;

  const { reportName = '', filter = '' } = editableReport;
  const { status = '' } = runParams;

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const requiredFields = useRunMode
    ? runReportRequiredFields
    : editReportRequiredFields;
  const validationSubject = useRunMode ? runParams : editableReport;
  const { isValid, invalidFields = [] } = validate(
    validationSubject,
    requiredFields
  );
  const touchAll = () => touch(requiredFields);
  const untouchAll = () => untouch(requiredFields);

  const filterEndsWithAnd = filter.trim().endsWith(BOOL_OPERATORS.AND);
  const filterEndsWithOr = filter.trim().endsWith(BOOL_OPERATORS.OR);
  const canUpdateFilter =
    isEmpty(filter) || filterEndsWithAnd || filterEndsWithOr;

  const caseStatusOptions = Object.keys(caseStatusesByCode).map((status) => {
    return { value: status, label: caseStatusesByCode[status] };
  });

  const cancelRequests = cancelRequestsEffect({
    abortControllerRefs: [getReportTableFieldsAbortControllerRef],
    setLoadingTableFields,
  });

  useEffect(onMountEffect({ cancelRequests }), []);

  useEffect(
    onChangeModalMountedEffect({
      mounted,
      storage,
      report,
      untouchAll,
      setEditableReport,
      setRunParams,
      setSelectedFormatIndex,
      cancelRequests,
      reportsFilterIndex,
    }),
    [mounted]
  );

  useEffect(
    getReportTableFieldsEffect({
      t,
      api,
      report,
      mounted,
      setLoadingTableFields,
      setReportTableFields,
      getReportTableFieldsAbortControllerRef,
    }),
    [report.id, mounted]
  );

  const onChange = onInputChangeEffect({ setObject: setEditableReport });
  const onFilterChange = onFilterChangeEffect({ setRunParams });

  useEffect(
    setDefaultsEffect({
      report,
      mounted,
      onFilterChange,
    }),
    [mounted]
  );
  const inputEventHandlers = {
    onChange,
    onBlur: onInputBlurEffect({ touch }),
  };

  const concatFilter = concatFilterEffect({ setEditableReport });

  const title = useRunMode
    ? t('components.RunEditReportModal.runTitle', { reportName })
    : t('components.RunEditReportModal.editTitle');

  const isSavingAndRunning = isRunningReport && runButtonId === 'save-run';
  const isOnlyRunning = isRunningReport && runButtonId === 'run';
  return (
    <Modal
      className={classnames('run-edit-report-modal', className)}
      cancelButtonText={t('common.cancel')}
      onClickCancel={onDismiss}
      titleText={title}
      mounted={mounted}
      initialFocus={useRunMode ? '#run' : '#report-name'}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{title}</h2>

      {!useRunMode && (
        <div className="form-row edit-form-row">
          <InputHelp
            validationContent={t(
              'components.RunEditReportModal.reportNameError'
            )}
            invalid={
              isTouched('reportName') && invalidFields.includes('reportName')
            }
          >
            <InputLabel content={t('components.RunEditReportModal.reportName')}>
              <input
                type="text"
                name="reportName"
                id="report-name"
                value={reportName}
                {...inputEventHandlers}
              />
            </InputLabel>
          </InputHelp>
        </div>
      )}

      {useRunMode && (
        <div className="form-row run-form-row">
          <ReportDateRangeForm
            params={runParams}
            setParams={setRunParams}
            invalidFields={invalidFields}
            startDateTouched={isTouched('startDate')}
            endDateTouched={isTouched('endDate')}
          />
          <InputHelp
            validationContent={t(
              'components.RunEditReportModal.statusValidation'
            )}
            invalid={isTouched('status') && invalidFields.includes('status')}
          >
            <InputLabel content={t('components.RunEditReportModal.status')}>
              <StyledSelect
                name="status"
                value={status}
                onChange={onInputChangeEffect({ setObject: setRunParams })}
                onBlur={onInputBlurEffect({ touch })}
              >
                <option value={''} key={''} disabled>
                  {t('common.defaultSelectPlaceholder')}
                </option>
                {caseStatusOptions.map((item, key) => {
                  return (
                    <option value={item.value} key={key}>
                      {item.value} - {item.label}
                    </option>
                  );
                })}
              </StyledSelect>
            </InputLabel>
          </InputHelp>
        </div>
      )}

      <ReportFilterForm
        tableFields={reportTableFields}
        isLoadingTableFields={isLoadingTableFields}
        filter={filter}
        onUpdateFilter={onUpdateFilterEffect({ setEditableReport })}
        canUpdateFilter={canUpdateFilter}
      />

      <div className="button-container and-or-container">
        <button
          className="button"
          onClick={() => concatFilter(BOOL_OPERATORS.AND)}
        >
          {t('components.RunEditReportModal.and')}
        </button>
        <button
          className="button"
          onClick={() => concatFilter(BOOL_OPERATORS.OR)}
        >
          {t('components.RunEditReportModal.or')}
        </button>
      </div>

      <InputHelp
        validationContent={t('components.RunEditReportModal.scriptError')}
        invalid={isTouched('filter') && invalidFields.includes('filter')}
      >
        <InputLabel content={t('components.RunEditReportModal.script')}>
          <textarea name="filter" value={filter} {...inputEventHandlers} />
        </InputLabel>
      </InputHelp>

      <div className="button-bar">
        <button className="button-link-appearance" onClick={onDismiss}>
          {t('common.cancel')}
        </button>

        {useRunMode && (
          <DropdownMenu
            options={getReportFormatOptions(
              t,
              setRunParams,
              setSelectedFormatIndex
            )}
            selectedOptionIndex={selectedFormatIndex}
          />
        )}

        <button
          className="button button-highlight"
          onClick={onConfirmSaveEffect({
            isValid,
            touchAll,
            setRunButtonId,
            editableReport,
            runParams,
            useRunMode,
            onConfirmSave,
            onConfirmRun,
            onDismiss,
          })}
          disabled={isModifyingReport || isRunningReport}
          id="save-run"
        >
          {isModifyingReport && t('common.saving')}
          {!isModifyingReport && !useRunMode && t('common.save')}
          {!isModifyingReport &&
            useRunMode &&
            isSavingAndRunning &&
            t('components.RunEditReportModal.running')}
          {!isModifyingReport &&
            useRunMode &&
            !isSavingAndRunning &&
            t('components.RunEditReportModal.saveAndRun')}
        </button>

        {useRunMode && (
          <button
            className="button button-highlight"
            onClick={onConfirmRunEffect({
              isValid,
              touchAll,
              setRunButtonId,
              editableReport,
              runParams,
              onConfirmRun,
              onDismiss,
            })}
            disabled={isModifyingReport || isRunningReport}
            id="run"
          >
            {isOnlyRunning
              ? t('components.RunEditReportModal.running')
              : t('components.RunEditReportModal.run')}
          </button>
        )}
      </div>
    </Modal>
  );
};

RunEditReportModal.defaultProps = {
  mounted: false,
  useRunMode: false,
  isModifyingReport: false,
  isRunningReport: false,
};

RunEditReportModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  report: PropTypes.object.isRequired,
  useRunMode: PropTypes.bool,
  isModifyingReport: PropTypes.bool,
  isRunningReport: PropTypes.bool,
  onConfirmSave: PropTypes.func.isRequired,
  onConfirmRun: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const ValidatedRunEditReportModal = (props) => (
  <ValidationProvider rules={runEditReportValidationRules(props.useRunMode)}>
    <RunEditReportModal {...props} />
  </ValidationProvider>
);

ValidatedRunEditReportModal.propTypes = { ...RunEditReportModal.propTypes };

export default ValidatedRunEditReportModal;
