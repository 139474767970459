import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { InputLabel, InputHelp } from 'js/components/design-system';
import {
  onChangeChequeNumberEffect,
  onBlurEffect,
  onSubmitActionsFormEffect,
} from './effects';
import {
  chequeRunActionsValidationRules,
  chequeRunActionsRequiredKeys,
} from './functions';

const ChequeRunActions = (props) => {
  const {
    className,
    lockSystemActive,
    onClickUnlock,
    isCreateRunActive,
    onClickCreateRun,
    chequeRunOptions = {},
  } = props;
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const [chequeNumber, setChequeNumber] = useState('');
  const cheque = { chequeNumber };

  const validateAll = (data) => validate(data, chequeRunActionsRequiredKeys);
  const touchAll = () => touch(chequeRunActionsRequiredKeys);
  const { invalidFields = [] } = validateAll(cheque);

  const isActive = lockSystemActive || isCreateRunActive;

  return (
    <form
      className={classnames('cheque-run-actions', className)}
      onSubmit={onSubmitActionsFormEffect({
        isActive,
        cheque,
        chequeRunOptions,
        validateAll,
        touchAll,
        onClick: onClickCreateRun,
      })}
    >
      <div className="cheque-run-action-inputs">
        <InputHelp
          validationContent={t(
            'components.ChequeRunActions.chequeNumberValidation'
          )}
          invalid={
            isTouched('chequeNumber') && invalidFields.includes('chequeNumber')
          }
        >
          <InputLabel content={t('components.ChequeRunActions.chequeNumber')}>
            <input
              type="text"
              name="chequeNumber"
              value={chequeNumber}
              disabled={isActive}
              onChange={onChangeChequeNumberEffect({ setChequeNumber })}
              onBlur={onBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
      </div>
      <div className="cheque-run-action-buttons">
        <button
          className="button"
          type="button"
          disabled={isActive}
          onClick={onClickUnlock}
        >
          {lockSystemActive
            ? t('components.ChequeRunActions.unlockButtonTitleActive')
            : t('components.ChequeRunActions.unlockButtonTitle')}
        </button>
        <button className="button button-highlight" disabled={isActive}>
          {isCreateRunActive
            ? t('components.ChequeRunActions.createRunButtonTitleActive')
            : t('components.ChequeRunActions.createRunButtonTitle')}
        </button>
      </div>
    </form>
  );
};

ChequeRunActions.propTypes = {
  className: PropTypes.string,
  lockSystemActive: PropTypes.bool.isRequired,
  onClickUnlock: PropTypes.func.isRequired,
  isCreateRunActive: PropTypes.bool.isRequired,
  onClickCreateRun: PropTypes.func.isRequired,
  chequeRunOptions: PropTypes.shape({
    reprintLastRun: PropTypes.bool,
    reprintChequeCount: PropTypes.number,
  }),
};

const ValidatedChequeRunActions = (props) => (
  <ValidationProvider rules={chequeRunActionsValidationRules}>
    <ChequeRunActions {...props} />
  </ValidationProvider>
);

export default ValidatedChequeRunActions;
