import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const getCIFDataEffect = (options = {}) => {
  const {
    api,
    cif,
    setCIFData,
    setActive,
    setError,
    getCifAbortControllerRef,
  } = options;

  return async () => {
    rotateAbortControllerRef(getCifAbortControllerRef);
    const { signal } = getCifAbortControllerRef.current;

    setActive(true);
    const url = `/Case/PrintCIF/${cif}`;
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      if (json) {
        setCIFData(json);
        setActive(false);
      }
    } catch (e) {
      if (!isAbortError(e)) {
        setError(e);
        setActive(false);
      }
    }
  };
};

export const getMultipleCIFDataEffect = (options = {}) => {
  const {
    api,
    date,
    setCIFDataArray,
    setActive,
    setError,
    getCifAbortControllerRef,
  } = options;

  return async () => {
    const url = `/dayendreport/cif?reportDate=${date}`;
    setActive(true);

    rotateAbortControllerRef(getCifAbortControllerRef);
    const { signal } = getCifAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      if (json) {
        setCIFDataArray(json);
        setActive(false);
      }
    } catch (e) {
      if (!isAbortError(e)) {
        setError(e);
        setActive(false);
      }
    }
  };
};

export const printViewEffect = (options = {}) => {
  const { CIFData, active } = options;
  return () => {
    if (CIFData?.caseInfo && !active) {
      window.print();
    }
  };
};

export const multiplePrintViewEffect = (options = {}) => {
  const { CIFDataArray, active } = options;
  return () => {
    if (CIFDataArray.length > 0 && !active) {
      window.print();
    }
  };
};
