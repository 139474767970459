export const contentElementClassName = 'print-manager-content';
export const childrenElementClassName = 'print-manager-children';
export const contentElementQuery = `.${contentElementClassName}`;
export const managedElementQuery = `.${childrenElementClassName} [data-print-managed="true"]`;
export const printOrderAttribute = 'data-print-order';

export const getPrintOrder = (element) => {
  return parseInt(element.getAttribute(printOrderAttribute) || 0);
};

export const printManagedElementSortPredicate = (element1, element2) => {
  const order1 = getPrintOrder(element1);
  const order2 = getPrintOrder(element2);
  if (order1 !== order2) {
    return order1 > order2 ? 1 : -1;
  } else {
    return 0;
  }
};

export const onBeforePrint = (containerRef) => {
  const { current: container } = containerRef;

  if (!container) {
    return;
  }

  const contentElement = container.querySelector(contentElementQuery);
  const managedElements = container.querySelectorAll(managedElementQuery);

  if (!contentElement || managedElements.length === 0) {
    return;
  }

  Array.from(managedElements)
    .sort(printManagedElementSortPredicate)
    .forEach((element) => {
      const clone = element.cloneNode(true);
      contentElement.appendChild(clone);
    });
};

export const onAfterPrint = (containerRef) => {
  const { current: container } = containerRef;

  if (!container) {
    return;
  }

  const contentElement = container.querySelector(contentElementQuery);

  if (contentElement) {
    contentElement.innerHTML = null;
  }
};
