import React, { createRef, createContext } from 'react';
import { presentBannerEffect, dismissBannerEffect } from './effects';

const BannerContext = createContext({
  presentBanner: () => {},
  dismissBanner: () => {},
});

const BannerProvider = ({ children }) => {
  const containerRef = createRef();

  return (
    <BannerContext.Provider
      value={{
        presentBanner: presentBannerEffect({ containerRef }),
        dismissBanner: dismissBannerEffect({ containerRef }),
      }}
    >
      {children}
      <div className="banner-container" ref={containerRef} />
    </BannerContext.Provider>
  );
};

export { BannerContext, BannerProvider };
