import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';

const UnionPrintHeader = (props) => {
  const { className, union = {}, ...headerProps } = props;
  const { unionName = '', locInact: unionInactive = false } = union;
  const { t } = useTranslation();
  return (
    <PrintHeader
      className={classnames('union-print-header', className)}
      heading={
        <Fragment>
          <span>{unionName}</span>
          <span>
            {unionInactive
              ? t('common.UnionStatus.inactive')
              : t('common.UnionStatus.active')}
          </span>
        </Fragment>
      }
      {...headerProps}
    />
  );
};

UnionPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  union: PropTypes.shape({
    unionName: PropTypes.string,
    coInact: PropTypes.bool,
  }).isRequired,
};

export default UnionPrintHeader;
