import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  LAWYER_TYPE_ENUM,
  lawyerApiKey,
  cleanLawyer,
  newLawyerData,
} from 'js/utilities/lawyers';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingLawyer,
    setLoadingLawyerFeedback,
    setSavingLawyer,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingLawyer(false);
      setLoadingLawyerFeedback(false);
      setSavingLawyer(false);
    };
  };
};

export const prepNewLawyerEffect = (options = {}) => {
  const { isNew, setLawyer } = options;
  return () => {
    if (!isNew) {
      return;
    }

    const initialLawyerData = newLawyerData();
    setLawyer(initialLawyerData);
  };
};

export const getLawyerRecordEffect = (options = {}) => {
  const {
    isNew,
    t,
    api,
    cache,
    lawyerId,
    lawyerApproxType,
    setLoadingLawyer,
    setLawyer,
    getLawyerAbortControllerRef,
  } = options;
  return async () => {
    if (isNew) {
      return;
    }

    setLoadingLawyer(true);

    const url = lawyerApiKey(lawyerId, lawyerApproxType);
    const record = cache.get(url);

    rotateAbortControllerRef(getLawyerAbortControllerRef);
    const { signal } = getLawyerAbortControllerRef.current;

    if (!record) {
      try {
        const { json = {} } = await api.getJson(
          url,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.LawyerPage.requestError'),
              },
            },
          }
        );
        let nextLawyerData = {};
        if (lawyerApproxType === LAWYER_TYPE_ENUM.STAFF) {
          nextLawyerData = cleanLawyer({ lawyerInfo: { ...json } });
        } else {
          nextLawyerData = cleanLawyer(json);
        }
        // const cleanLawyerData = cleanLawyer(json);
        cache.set(url, nextLawyerData);
        setLawyer(nextLawyerData);
        setLoadingLawyer(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingLawyer(false);
        }
      }
    } else {
      setLawyer(record.value);
      setLoadingLawyer(false);
    }
  };
};

export const patchActiveLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    lawyerId,
    lawyer,
    lawyerApproxType,
    lawyerInactive,
    setSavingLawyer,
    setLawyer,
    setShowModal,
    patchActiveLawyerAbortControllerRef,
  } = options;

  return async () => {
    setSavingLawyer(true);

    const url = lawyerApiKey(lawyerId, lawyerApproxType);
    let body = {};

    if (lawyerApproxType === LAWYER_TYPE_ENUM.STAFF) {
      body = { isActive: !lawyerInactive };
    } else {
      body = { lawyerInfo: { lawyerInactive } };
    }

    rotateAbortControllerRef(patchActiveLawyerAbortControllerRef);
    const { signal } = patchActiveLawyerAbortControllerRef.current;

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.LawyerInformation.requestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );

      const { lawyerInfo = {} } = lawyer;
      const nextLawyer = {
        ...lawyer,
        lawyerInfo: {
          ...lawyerInfo,
          lawyerInactive,
        },
      };

      cache.set(url, nextLawyer);
      setLawyer(nextLawyer);
      setSavingLawyer(false);
      setShowModal(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingLawyer(false);
      }
    }
  };
};

export const getLawyerFeedbackConditionalEffect = (options) => {
  const { isNew, getLawyerFeedback, hasAccessToFeedback } = options;
  return () => {
    if (!isNew && hasAccessToFeedback) {
      getLawyerFeedback();
    }
  };
};
