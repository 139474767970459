import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import FeeScheduleBreadcrumbs from './breadcrumbs';
import FeeScheduleCard from './fee-schedule-card';
import {
  onMountEffect,
  getFeeScheduleEffect,
  deleteFeeScheduleEffect,
} from './effects';
import EmptyState from 'js/components/controls/empty-state';
import FeeScheduleTabs from './tabs';

const FeeScheduleArchived = () => {
  const { t } = useTranslation();
  const { api } = useContext(FetchContext);

  const getAbortControllerRef = useRef(null);
  const deleteAbortControllerRef = useRef(null);

  const [feeSchedules, setFeeSchedules] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [isActive, setActive] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const listElementRef = useRef();

  useEffect(
    onMountEffect({
      abortControllerRefs: [getAbortControllerRef],
      setActive,
    }),
    []
  );

  const getFeeSchedules = getFeeScheduleEffect({
    t,
    api,
    setActive,
    setFeeSchedules,
    getAbortControllerRef,
  });

  useEffect(getFeeSchedules, []);

  return (
    <Page
      className="fee-schedule"
      title={t('components.FeeSchedulePage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <FeeScheduleBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {isActive && <ActivityIndicatorCard />}

          {!isActive && (
            <Fragment>
              <div className="fee-schedule-heading">
                <div className="fee-schedule-heading-left">
                  <h1>{t('components.FeeSchedulePage.title')}</h1>
                </div>
              </div>
              <FeeScheduleTabs />

              {feeSchedules.length === 0 && (
                <EmptyState
                  title={t('components.FeeSchedulePage.emptyTitle')}
                />
              )}

              <ul
                ref={listElementRef}
                className="unstyled-list fee-schedule-list"
              >
                {feeSchedules.length > 0 &&
                  feeSchedules.map((feeSchedule) => {
                    const isOpenFeeSchedule =
                      isOpen && isOpen.id === feeSchedule.id;
                    return (
                      <FeeScheduleCard
                        feeSchedule={feeSchedule}
                        key={feeSchedule.id}
                        expanded={isOpenFeeSchedule}
                        expandEnabled={isEmpty(isOpen)}
                        onClickExpand={() => setIsOpen(feeSchedule)}
                        onClickCancel={() => setIsOpen({})}
                        setEditingFeeSchedule={setIsOpen}
                        onClickDelete={deleteFeeScheduleEffect({
                          t,
                          api,
                          setDeleting,
                          deleteAbortControllerRef,
                          onComplete: getFeeSchedules,
                        })}
                        isDeleting={isDeleting}
                        archived
                      />
                    );
                  })}
              </ul>
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default FeeScheduleArchived;
