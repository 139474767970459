import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const StrikethroughInput = (props) => {
  const { className, items = [], delimiter } = props;
  return (
    <div className={classnames('strikethrough-input', className)}>
      {items.map(({ text = '', struck = false }, key) => (
        <Fragment key={key}>
          <span className={classnames('strikethrough-input-text', { struck })}>
            {text}
          </span>
          <span className={'strikethrough-input-delimiter'}>{delimiter}</span>
        </Fragment>
      ))}
    </div>
  );
};

StrikethroughInput.defaultProps = {
  delimiter: ',',
};

StrikethroughInput.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      struck: PropTypes.bool,
    })
  ).isRequired,
  delimiter: PropTypes.string,
};

export default StrikethroughInput;
