import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanLinkedLawyers } from './functions';
import { searchStringFromParams } from 'js/utilities/params';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { DEFAULT_LIST_PRINT_VIEW_LIMIT } from 'js/components/print';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getLinkedLawyersEffect = (options = {}) => {
  const {
    t,
    api,
    firmId,
    isNew,
    setActive,
    setLinkedLawyers,
    setTotalResults,
    abortControllerRef,
  } = options;

  return async () => {
    setActive(true);
    if (isNew) {
      return;
    }

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Admin/Firms/${firmId}/LinkedLawyers`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientCaseHistory.requestError'),
            },
          },
        }
      );

      const { lawyerInfo, totalCount } = json;
      setLinkedLawyers(cleanLinkedLawyers(lawyerInfo));
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const onSelectLawyerEffect = (options = {}) => {
  const { history } = options;
  return (lawyer) => {
    const casePath = routePathReplacingParams(routePaths.lawyer, {
      lawyerId: lawyer.lawyerId,
    });
    history.push(casePath);
  };
};

export const previousLinkedLawyerResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextLinkedLawyerResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const linkedLawyerResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const lawFirmsLinkedLawyersPrintRequestEffect = ({ api, firmId }) => {
  return async () => {
    const { json = {} } = await api.getJson(
      `/Admin/Firms/${firmId}/LinkedLawyers?Offset=0&Limit=${DEFAULT_LIST_PRINT_VIEW_LIMIT}&Order=lastName&Sort=asc`,
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );
    const { lawyerInfo = [] } = json;
    return cleanLinkedLawyers(lawyerInfo);
  };
};
