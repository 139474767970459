export const caseNumberMaskPattern = '0000/0000/00000';

export const dateMaskPattern = '0000/00/00';

export const phoneNumberMaskPattern = [
  { mask: '000-0000' },
  { mask: '000-000-0000' },
];

export const postalCodeMaskPattern = 'a0a 0a0';

export const clientIdMaskPattern = '000000000';

export const clientCodeMaskPattern = '00';

export const lawyerIdMaskPattern = [{ mask: '0000' }, { mask: '0a0000' }];

export const officeCodeMaskPattern = '0000';

export const lpCodeMaskPattern = '000';
