import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  cleanLawyerSummary,
  cleanStaffLawyerSummary,
} from 'js/components/lawyer-summary';
import { searchStringFromParams } from 'js/utilities/params';
import {
  LAWYER_TYPE_ENUM,
  LAWYER_TYPES,
  isNonCooperatingLawyerType,
} from 'js/utilities/lawyers';
import { caseApiKey, prepareCaseLawyerRequestBody } from 'js/utilities/cases';
import { queryFromModalQuery } from './functions';

export const DEFAULT_MODAL_QUERY_PARAMS = {
  Keyword: '',
  Order: 'lastName',
  Sort: 'asc',
  Limit: '10',
  Offset: '0',
};

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    lawyerId,
    lawyerType,
    setActive,
    setLawyer,
    setCaseInfo,
    getLawyerAbortControllerRef,
  } = options;

  const isNonCoop = isNonCooperatingLawyerType(lawyerType);
  const isStaffLawyer = Number(lawyerType) === LAWYER_TYPE_ENUM.STAFF;

  return async () => {
    if (!lawyerId && !isNonCoop) {
      return;
    }

    if (isNonCoop) {
      setLawyer({ lawyerType: LAWYER_TYPE_ENUM.NON_COOP });
      setCaseInfo((caseInfo) => {
        const { caseLawyer = {} } = caseInfo;
        return {
          ...caseInfo,
          caseLawyer: { ...caseLawyer, sentToLawyer: 'false' },
        };
      });
      return;
    }

    rotateAbortControllerRef(getLawyerAbortControllerRef);
    const { signal } = getLawyerAbortControllerRef.current;

    setActive(true);

    const endpoint = isStaffLawyer
      ? `/Lawyer/Staff/${lawyerId}`
      : `/Lawyer/${lawyerId}`;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseLawyer.lawyerRequestError'),
            },
          },
        }
      );

      const nextLawyer = isStaffLawyer
        ? cleanStaffLawyerSummary(json)
        : cleanLawyerSummary(json);

      setLawyer(nextLawyer);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const updateCaseLawyerInfoEffect = (options = {}) => {
  const { setCaseInfo } = options;
  return (name, value) =>
    setCaseInfo((caseInfo) => {
      const { caseLawyer = {} } = caseInfo;
      const nextLawyer = { ...caseLawyer, [name]: value };

      // Reset the selected city if the province is changed:
      if (name === 'provId') {
        nextLawyer.cityId = '';
      }

      return { ...caseInfo, caseLawyer: nextLawyer };
    });
};

export const patchCaseLawyerEffect = (options = {}) => {
  const {
    t,
    api,
    prompt,
    isValid,
    touchAll,
    caseInfo,
    caseNumber,
    setActive,
    setCaseInfo,
    resetInteractionCount,
    cache,
    patchLawyerAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid || !caseNumber) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    const { caseInformation = {}, caseLawyer = {} } = caseInfo;

    // Copy of the case lawyer data that can be mutated
    // by the estate trustee confirmation prompt:
    const mutableCaseLawyer = { ...caseLawyer };

    try {
      await prompt({ caseInformation, caseLawyer }, 'case.estateTrusteeRule');
    } catch (error) {
      return;
    }

    setActive(true);

    rotateAbortControllerRef(patchLawyerAbortControllerRef);
    const { signal } = patchLawyerAbortControllerRef;

    const url = caseApiKey(caseNumber);
    const body = {
      caseLawyer: prepareCaseLawyerRequestBody(mutableCaseLawyer),
    };
    const nextCaseInfo = { ...caseInfo, caseLawyer: mutableCaseLawyer };

    try {
      await api.patchJson(
        `/Case/${encodeURIComponent(caseNumber)}`,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );

      cache.delete(url);
      setCaseInfo(nextCaseInfo);
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const saveCaseLawyerEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const selectLawyerTypeEffect = (options = {}) => {
  const {
    setLawyerType,
    setSelectLawyerMounted,
    setStaffLawyerMounted,
    setCoopLawyerMounted,
  } = options;
  return (lawyerType) => {
    setLawyerType(lawyerType);
    setSelectLawyerMounted(false);
    if (lawyerType === LAWYER_TYPES.STAFF) {
      setStaffLawyerMounted(true);
    } else if (lawyerType === LAWYER_TYPES.COOP) {
      setCoopLawyerMounted(true);
    }
  };
};

export const selectLawyerEffect = (options = {}) => {
  const {
    setLawyer,
    setCaseInfo,
    setLawyerParams,
    setSelectLawyerMounted,
    incrementInteractionCount,
  } = options;
  return (lawyerObj) => {
    const { lawyer = {}, lawyerType } = lawyerObj;
    //staff lawyers use userId, coop use LawyerId (this is currently being removed TODO)
    const { userId = '', lawyerId = '', isActive } = lawyer;
    const nextLawyer = {
      ...lawyer,
      lawyerId: userId || lawyerId,
      lawyerType: lawyerType,
      lawyerInactive: isActive === false,
    };
    setLawyer(nextLawyer);
    setCaseInfo((caseInfo) => {
      const { caseLawyer = {} } = caseInfo;
      return { ...caseInfo, caseLawyer: { ...caseLawyer, ...nextLawyer } };
    });
    setSelectLawyerMounted(false);
    setLawyerParams({ modalMounted: '' });
    incrementInteractionCount();
  };
};

export const setModalParamsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (changedParams) => {
    const nextParams = { ...params, ...changedParams };
    history.replace(searchStringFromParams(nextParams));
  };
};

export const resetModalParamsEffect = (options = {}) => {
  const { history } = options;
  return () => {
    history.replace(searchStringFromParams({}));
  };
};

export const buildNextLawyerQueryEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { modalQuery, ...otherParams } = params;
  const decodeModalQuery = decodeURIComponent(modalQuery);
  const prevModalQueryParams = queryFromModalQuery(decodeModalQuery);
  const { params: prevParams = {} } = prevModalQueryParams;

  return ({ param = '', value = '', nextQueryParams = {} }) => {
    const nextOptions = {
      ...DEFAULT_MODAL_QUERY_PARAMS,
      ...prevParams,
      ...nextQueryParams,
    };
    let nextModalQuery = {};
    if (param === 'Keyword') {
      nextModalQuery = {
        ...nextOptions,
        [param]: value,
        Offset: 0,
      };
    } else {
      nextModalQuery = {
        ...nextOptions,
        [param]: value,
      };
    }

    const searchString = searchStringFromParams(nextModalQuery);
    const nextQuery = encodeURIComponent(searchString);
    const nextParams = { ...otherParams, modalQuery: nextQuery };

    history.replace(searchStringFromParams(nextParams));
  };
};

export const setDefaultModalQueryEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;

  return () => {
    const defaultModalQueryString = searchStringFromParams(
      DEFAULT_MODAL_QUERY_PARAMS
    );
    const nextParams = {
      ...params,
      modalQuery: encodeURIComponent(defaultModalQueryString),
    };
    history.replace(searchStringFromParams(nextParams));
  };
};

export const cancelModalEffect = (options = {}) => {
  const { setSelectLawyerMounted, resetLawyerParams } = options;
  return () => {
    setSelectLawyerMounted(false);
    resetLawyerParams();
  };
};
