import { cleanOfficeOptions } from 'js/utilities/staff';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';
import { cleanStaffList } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const onPageChangeEffect = (options = {}) => {
  const { editingStaff, setEditingStaff, staff } = options;
  return () => {
    // looking to see if there is an open card and if its still on the page, if not close it
    const containsStaff = staff.find((s) => s.usrCode === editingStaff.usrCode);
    if (editingStaff.usrCode && !containsStaff) {
      setEditingStaff({});
    }
  };
};

export const setEditingStaffEffect = (options = {}) => {
  const { staffUser, setEditingStaff } = options;
  return () => setEditingStaff({ ...staffUser });
};

export const resetEditingStaffEffect = (options = {}) =>
  setEditingStaffEffect({ ...options, staff: {} });

export const resetAddingStaffEffect = (options = {}) => {
  const { setAddingStaff, setIsAdding, defaultNewStaff } = options;
  return () => {
    setAddingStaff(defaultNewStaff), setIsAdding(false);
  };
};

export const updateStaffEffect = (options = {}) => {
  const { allowUpdate, changingStaff, changeStaffFunc } = options;
  return (name, value) => {
    if (!allowUpdate) {
      return;
    }
    const nextStaff = { ...changingStaff, [name]: value };
    changeStaffFunc(nextStaff);
  };
};

export const getStaffEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setStaff,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }
    const endpoint = `/Admin/Staff/${query.search}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.StaffListPage.searchError') },
          },
        }
      );
      const { staffInfo = [], totalCount = 0 } = json;
      const cleanStaff = cleanStaffList(staffInfo);
      setStaff(cleanStaff);
      setTotalResults(totalCount);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const getOfficesEffect = (options = {}) => {
  const { t, api, setActive, setOffices, officeAbortControllerRef } = options;
  return async () => {
    const endpoint = `/Admin/Offices`;
    setActive(true);
    rotateAbortControllerRef(officeAbortControllerRef);
    const { signal } = officeAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.StaffListPage.searchError') },
          },
        }
      );
      setOffices(cleanOfficeOptions(json));
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

//pagination and search
export const previousStaffResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextStaffResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const staffResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const staffResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, SortBy: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const staffSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const staffSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

//add edit
export const patchStaffEffect = (options = {}) => {
  const {
    t,
    api,
    editingStaff,
    staff,
    setStaff,
    setSaving,
    onComplete,
    patchAbortControllerRef,
  } = options;

  return async () => {
    setSaving(true);

    rotateAbortControllerRef(patchAbortControllerRef);
    const { signal } = patchAbortControllerRef.current;
    try {
      const { usrCode } = editingStaff;
      const url = `/Admin/Staff/${usrCode}`;
      const body = editingStaff;

      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            //bypass the success if there is no onComplete, it means something else needs to happen
            bypass: typeof onComplete !== 'function',
            context: {
              message: t('components.StaffPage.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.StaffPage.patchRequestError'),
            },
          },
        }
      );
      const index = staff.findIndex((s) => s.usrCode === editingStaff.usrCode);

      const nextStaffList = [...staff];
      nextStaffList[index] = editingStaff;
      setStaff(nextStaffList);
      setSaving(false);
      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const postStaffEffect = (options = {}) => {
  const {
    t,
    api,
    addingStaff,
    staff,
    setStaff,
    setSaving,
    presentStyledBanner,
    onComplete,
    postAbortControllerRef,
  } = options;

  return async () => {
    setSaving(true);

    const url = `/Admin/Staff`;
    const body = { ...addingStaff };
    const isAdmin = body.usrSecur === '1';

    rotateAbortControllerRef(postAbortControllerRef);
    const { signal } = postAbortControllerRef.current;

    try {
      const { json = {}, response } = await api.postJson(
        url,
        { body, signal },
        {
          success: {
            bypass: isAdmin,
            context: {
              message: t('components.StaffPage.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.StaffPage.postRequestError'),
            },
          },
        }
      );

      if (isAdmin && response.status === 202) {
        presentStyledBanner('success', {
          content: t('components.StaffPage.postRequestProcessing'),
          timeout: 0,
        });
      } else {
        const nextStaff = [json, ...staff];
        setStaff(nextStaff);
      }

      setSaving(false);
      onComplete();
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const reinviteStaffEffect = (options = {}) => {
  const {
    t,
    api,
    setSaving,
    reinviteAbortControllerRef,
    patchStaff,
    onComplete,
  } = options;

  return async (e, usrCode) => {
    try {
      //save the staff member before re-inviting them
      await patchStaff();
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }

    setSaving(true);

    rotateAbortControllerRef(reinviteAbortControllerRef);
    const { signal } = reinviteAbortControllerRef.current;

    const url = `/Admin/Staff/${usrCode}/reinvite`;
    try {
      await api.postJson(
        url,
        { signal },
        {
          success: {
            context: {
              message: t('components.StaffPage.reinviteRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.StaffPage.reinviteRequestError'),
            },
          },
        }
      );

      setSaving(false);
      if (typeof onComplete === 'function') {
        onComplete();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setSaving(false);
      }
    }
  };
};

export const staffListPrintRequestEffect = ({ api, query }) => {
  return async () => {
    const officesRequest = api.getJson(
      '/Admin/Offices',
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );

    const staffRequest = api.getJson(
      `/Admin/Staff${query.search}`,
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );

    const [
      { json: officesJson = [] },
      { json: staffJson = {} },
    ] = await Promise.all([officesRequest, staffRequest]);

    const { staffInfo = [] } = staffJson;
    const staff = cleanStaffList(staffInfo);

    return staff
      .filter((item) => !item.usrInact)
      .map((item) => {
        const office = officesJson.find(
          ({ officeCode }) => officeCode === item.usrOfficecode
        );
        const officeName = office ? office.officeName : '';
        return { ...item, officeName };
      });
  };
};
