import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import { StyledBannerContext } from 'js/components/banner-styled';
import EmptyState from 'js/components/controls/empty-state';
import ClientPage from '../client-page';
import { ClientProvider } from '../client-context';
import { clientDocumentsNamedPermissions } from '../client-named-permissions';

import {
  onMountEffect,
  getDocumentsEffect,
  getDocumentEffect,
  postDocumentEffect,
  deleteDocumentEffect,
  onClickUploadFileEffect,
  onClickDeleteDocumentEffect,
  onConfirmDeleteDocumentEffect,
  onCancelDeleteDocumentEffect,
} from './effects';
import { getDocumentsColumns, openDocumentUrl } from './functions';
import DeleteDocumentModal from './delete-document-modal';

const ClientDocuments = () => {
  const {
    params: { id, clientCode = '00' },
  } = useRouteMatch();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const getDocumentsAbortControllerRef = useRef(null);
  const getDocumentAbortControllerRef = useRef(null);
  const postDocumentAbortControllerRef = useRef(null);
  const deleteDocumentAbortControllerRef = useRef(null);

  const { api = {} } = useContext(FetchContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasUploadPermission = hasNamedPermission('uploadClientDocuments');

  const [isLoadingDocuments, setLoadingDocuments] = useState(false);
  const [isUploadingDocument, setUploadingDocument] = useState(false);
  const [isDeletingDocument, setDeletingDocument] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentPendingDeletion, setDocumentPendingDeletion] = useState({});

  const getDocuments = getDocumentsEffect({
    t,
    api,
    id,
    clientCode,
    setLoadingDocuments,
    setDocuments,
    getDocumentsAbortControllerRef,
  });

  const onChangeDocument = postDocumentEffect({
    t,
    api,
    id,
    clientCode,
    setUploadingDocument,
    presentStyledBanner,
    postDocumentAbortControllerRef,
    onComplete: getDocuments,
  });

  const onClickDownloadDocument = getDocumentEffect({
    t,
    api,
    id,
    clientCode,
    openDocumentUrl,
    getDocumentAbortControllerRef,
  });

  const onClickDeleteDocument = onClickDeleteDocumentEffect({
    setDocumentPendingDeletion,
  });

  const onConfirmDeleteDocument = onConfirmDeleteDocumentEffect({
    setDocumentPendingDeletion,
    deleteDocument: deleteDocumentEffect({
      t,
      api,
      id,
      clientCode,
      documentId: documentPendingDeletion.id,
      setDeletingDocument,
      deleteDocumentAbortControllerRef,
      onComplete: getDocuments,
    }),
  });

  const onCancelDeleteDocument = onCancelDeleteDocumentEffect({
    setDocumentPendingDeletion,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getDocumentsAbortControllerRef,
        getDocumentAbortControllerRef,
        postDocumentAbortControllerRef,
        deleteDocumentAbortControllerRef,
      ],
      setLoadingDocuments,
      setUploadingDocument,
      setDeletingDocument,
    }),
    []
  );

  useEffect(getDocuments, []);

  return (
    <ClientPage
      className="client-documents"
      title={t('components.ClientDocuments.title')}
      actions={
        <div className="client-documents-file-input">
          <input
            ref={fileInputRef}
            type="file"
            multiple
            disabled={!hasUploadPermission || isUploadingDocument}
            onChange={onChangeDocument}
          />
          <button
            className="button button-highlight page-action-button"
            disabled={!hasUploadPermission || isUploadingDocument}
            onClick={onClickUploadFileEffect({ fileInputRef })}
          >
            {isUploadingDocument
              ? t('components.ClientDocuments.uploading')
              : t('components.ClientDocuments.upload')}
          </button>
        </div>
      }
    >
      {isLoadingDocuments && <ActivityIndicatorCard />}

      {!isLoadingDocuments && (
        <Fragment>
          {documents.length === 0 && (
            <EmptyState
              title={t('components.ClientDocuments.emptyStateMessage')}
            />
          )}
          {documents.length > 0 && (
            <SortableTable
              columns={getDocumentsColumns({
                t,
                onClickDownloadDocument,
                onClickDeleteDocument,
                hasDeletePermission: hasNamedPermission(
                  'deleteClientDocuments'
                ),
                hasDownloadPermission: hasNamedPermission(
                  'downloadClientDocuments'
                ),
              })}
              data={documents}
              initialSortColumnKey={'dateUploaded'}
              initialSortDirection={tableSortDirection.desc}
            />
          )}
        </Fragment>
      )}

      <DeleteDocumentModal
        documentPendingDeletion={documentPendingDeletion}
        isDeleting={isDeletingDocument}
        onClose={onCancelDeleteDocument}
        onClickDelete={onConfirmDeleteDocument}
      />
    </ClientPage>
  );
};

const ClientDocumentsWithContext = (props) => (
  <ClientProvider>
    <ClientDocuments {...props} />
  </ClientProvider>
);

const ClientDocumentsWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={clientDocumentsNamedPermissions}>
    <ClientDocumentsWithContext {...props} />
  </NamedPermissionsProvider>
);

export default ClientDocumentsWithNamedPermissions;
