import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, InsetListItem } from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import { bisectArray } from 'js/utilities/array';
import {
  onChangeLawyerPanelEffect,
  onChangeLawyerSubpanelEffect,
} from './effects';

const LawyerPanelsColumns = (props) => {
  const { t } = useTranslation();
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerPanels');

  const { className, panels, setPanels } = props;

  const columnBreakIndex = Math.ceil(panels.length / 2) + 1;
  const columns = bisectArray(panels, columnBreakIndex);

  return (
    <Card className={classnames('lawyer-panels-card', className)}>
      <h3>{t('components.LawyerPanels.heading')}</h3>
      <div className="lawyer-panels-columns">
        {columns.map((column, idx) => (
          <ul key={idx} className="lawyer-panels-column">
            {column.map((panel) => {
              const {
                panelKey,
                checked: panelChecked,
                lawyerSubpanels = [],
              } = panel;
              return (
                <li key={panelKey}>
                  <Checkbox
                    label={t(`common.lawyerPanels.${panelKey}.title`)}
                    name={panelKey}
                    checked={panelChecked}
                    onChange={onChangeLawyerPanelEffect({ setPanels })}
                    disabled={!hasEditPermission}
                  />
                  <ul>
                    {lawyerSubpanels.map((subPanel) => {
                      const {
                        subPanelKey,
                        checked: subPanelChecked,
                      } = subPanel;
                      return (
                        <InsetListItem key={subPanelKey}>
                          <Checkbox
                            label={t(
                              `common.lawyerPanels.${panelKey}.subPanels.${subPanelKey}`
                            )}
                            name={subPanelKey}
                            checked={subPanelChecked}
                            onChange={onChangeLawyerSubpanelEffect({
                              panelKey,
                              setPanels,
                            })}
                            disabled={!hasEditPermission}
                          />
                        </InsetListItem>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        ))}
      </div>
    </Card>
  );
};

LawyerPanelsColumns.propTypes = {
  className: PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPanels: PropTypes.func.isRequired,
};

export default LawyerPanelsColumns;
