import React, { createContext, useState } from 'react';

const CaseContext = createContext({
  caseInfo: {},
  setCaseInfo: () => {},
  isLoadingCaseInfo: false,
  setLoadingCaseInfo: () => {},
  caseOptions: {},
  setCaseOptions: () => {},
  isLoadingCaseOptions: false,
  setLoadingCaseOptions: () => {},
  caseNotes: [],
  setCaseNotes: () => {},
  isLoadingCaseNotes: false,
  setLoadingCaseNotes: () => {},
});

const CaseProvider = (props) => {
  const [caseInfo, setCaseInfo] = useState({});
  const [isLoadingCaseInfo, setLoadingCaseInfo] = useState(false);
  const [caseOptions, setCaseOptions] = useState({});
  const [isLoadingCaseOptions, setLoadingCaseOptions] = useState(false);
  const [caseNotes, setCaseNotes] = useState([]);
  const [isLoadingCaseNotes, setLoadingCaseNotes] = useState(false);

  return (
    <CaseContext.Provider
      value={{
        caseInfo,
        setCaseInfo,
        isLoadingCaseInfo,
        setLoadingCaseInfo,
        caseOptions,
        setCaseOptions,
        isLoadingCaseOptions,
        setLoadingCaseOptions,
        caseNotes,
        setCaseNotes,
        isLoadingCaseNotes,
        setLoadingCaseNotes,
      }}
    >
      {props.children}
    </CaseContext.Provider>
  );
};

export { CaseContext, CaseProvider };
