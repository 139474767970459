import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { lawyerApiKey } from 'js/utilities/lawyers';
import { LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';
import { sortCitiesByProvince } from 'js/utilities/regions';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingOptions,
    setSavingLawyer,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingOptions(false);
      setSavingLawyer(false);
    };
  };
};

export const openLawyerModalEffect = (options = {}) => {
  const { setMounted, resetInteractionCount } = options;
  return () => {
    setMounted(true);
    resetInteractionCount();
  };
};

export const getLawyerOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    setOptions,
    setLoadingOptions,
    getLawyerOptionsAbortControllerRef,
  } = options;
  return async () => {
    setLoadingOptions(true);

    rotateAbortControllerRef(getLawyerOptionsAbortControllerRef);
    const { signal } = getLawyerOptionsAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Lawyer/Options',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );

      const citiesByProvince = sortCitiesByProvince(json);
      setOptions({ ...json, citiesByProvince });
      setLoadingOptions(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingOptions(false);
      }
    }
  };
};

export const updateLawyerInformationEffect = (options = {}) => {
  const { lawyer, setLawyer, group } = options;
  return (name, value) => {
    const groupData = lawyer[group];
    if (groupData) {
      const nextGroupData = { ...groupData, [name]: value };

      // Reset the selected city if the province is changed:
      if (group === 'lawyerInfo' && name === 'provId') {
        nextGroupData.cityId = '';
      }

      const nextLawyer = { ...lawyer, [group]: nextGroupData };
      setLawyer(nextLawyer);
    }
  };
};

export const updateMultipleLawyerInformationEffect = (options = {}) => {
  const { lawyer, setLawyer } = options;
  return (nextItems) => {
    const { lawyerInfo } = lawyer;
    const nextLawyerInfo = { ...lawyerInfo, ...nextItems };
    setLawyer({ ...lawyer, lawyerInfo: nextLawyerInfo });
  };
};

export const patchLawyerInformationEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    lawyerId,
    isValid,
    lawyer,
    setSavingLawyer,
    touchAllFields,
    resetInteractionCount,
    patchLawyerInformationAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setSavingLawyer(true);

    const { lawyerInfo = {}, lawyerFirm } = lawyer;
    const url = lawyerApiKey(lawyerId);
    const body = {
      lawyerInfo: {
        ...lawyerInfo,
        customaryFee: Number(lawyerInfo.customaryFee),
      },
      lawyerFirm,
    };

    rotateAbortControllerRef(patchLawyerInformationAbortControllerRef);
    const { signal } = patchLawyerInformationAbortControllerRef.current;

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.LawyerInformation.requestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );
      cache.set(url, lawyer);
      setSavingLawyer(false);
      resetInteractionCount();
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingLawyer(false);
      }
    }
  };
};

export const postLawyerInformationEffect = (options = {}) => {
  const {
    t,
    api,
    setSavingLawyer,
    isValid,
    lawyer,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
    postLawyerInformationAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setSavingLawyer(true);

    const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;
    const body = {
      lawyerInfo: {
        ...lawyerInfo,
        customaryFee: Number(lawyerInfo.customaryFee),
        dateOpened: new Date(),
      },
      lawyerFirm: { ...lawyerFirm },
    };

    rotateAbortControllerRef(postLawyerInformationAbortControllerRef);
    const { signal } = postLawyerInformationAbortControllerRef.current;
    try {
      const { json = {} } = await api.postJson(
        '/Lawyer',
        { body, signal },
        {
          success: {
            context: {
              message: t('components.LawyerInformation.requestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );
      const { lawyerInfo } = json;
      const { lawyerId } = lawyerInfo;
      const path = routePathReplacingParams(routePaths.lawyerInformation, {
        lawyerId,
      });
      setSavingLawyer(false);
      resetInteractionCount();
      setRedirectPath(path);
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingLawyer(false);
      }
    }
  };
};

export const saveLawyerEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const selectLawFirmEffect = (options = {}) => {
  const { lawyer, setLawyer, setMounted, incrementInteractionCount } = options;
  return async (lawFirm) => {
    const { lawyerInfo } = lawyer;
    const nextLawyer = {
      lawyerInfo: { ...lawyerInfo, barrister: false, firmName: '' },
      lawyerFirm: lawFirm,
    };
    setLawyer(nextLawyer);
    setMounted(false);
    incrementInteractionCount();
  };
};

export const setBarristerEffect = (options = {}) => {
  const { lawyer, setLawyer, setMounted, incrementInteractionCount } = options;
  return async () => {
    const { lawyerInfo } = lawyer;
    const nextLawyer = {
      lawyerInfo: { ...lawyerInfo, barrister: true },
      lawyerFirm: {},
    };
    setLawyer(nextLawyer);
    setMounted(false);
    incrementInteractionCount();
  };
};

export const patchStaffLawyerInformationEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    staffId,
    isValid,
    lawyer,
    setSavingLawyer,
    touchAllFields,
    resetInteractionCount,
    patchStaffLawyerInformationAbortControllerRef,
  } = options;

  return async () => {
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    setSavingLawyer(true);

    const { lawyerInfo = {} } = lawyer;
    const { firstName, lastName, prefix } = lawyerInfo;
    const url = lawyerApiKey(staffId, LAWYER_TYPE_ENUM.STAFF);
    const body = {
      staffId,
      prefix,
      firstName,
      lastName,
    };

    rotateAbortControllerRef(patchStaffLawyerInformationAbortControllerRef);
    const { signal } = patchStaffLawyerInformationAbortControllerRef.current;

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.LawyerInformation.requestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );
      cache.set(url, lawyer);
      setSavingLawyer(false);
      resetInteractionCount();
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingLawyer(false);
      }
    }
  };
};
