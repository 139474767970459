import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import SaveModal from 'js/components/save-modal';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  DeactivateModal,
  DeactivateToggle,
  InactiveTag,
} from 'js/components/deactivate';
import {
  ExpandingCard,
  ExpandingCardContent,
  InputHelp,
  InputLabel,
  StyledSelect,
  Checkbox,
} from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import {
  lawyerTypeValidationRules,
  lawyerTypeRequiredFields,
} from 'js/utilities/lawyer-types';
import {
  onClickCancelEffect,
  onConfirmCancelEffect,
  onConfirmSaveEffect,
  saveLawyerTypeEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  onSelectChangeEffect,
  onCheckboxChangeEffect,
  updateLawyerTypeInactiveEffect,
  setConfirmDeactivateEffect,
} from './effects';
import { renderAccountOptions } from './functions';

const Spacer = () => <div />;

const LawyerTypeCard = (props) => {
  const { t } = useTranslation();
  const {
    lawyerType = {},
    accountOptions = {},
    expanded,
    expandEnabled,
    saving,
    onClickExpand,
    onClickCancel,
    onClickSave,
    onChange,
  } = props;
  const {
    code = '',
    desc = '',
    glAcct = '',
    gsTcct = '',
    inactive = false,
    requireProvince = false,
    requireCity = false,
    requireId = false,
  } = lawyerType;
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const { isValid, invalidFields = [] } = validate(
    lawyerType,
    lawyerTypeRequiredFields
  );
  const touchAll = () => touch(lawyerTypeRequiredFields);
  const untouchAll = () => untouch(lawyerTypeRequiredFields);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);

  const discard = onConfirmCancelEffect({
    untouchAll,
    resetInteractionCount,
    setConfirmCancel,
    onClickCancel,
  });

  const save = saveLawyerTypeEffect({
    t,
    presentStyledBanner,
    saveEffect: onConfirmSaveEffect({
      t,
      isValid,
      touchAll,
      resetInteractionCount,
      setConfirmCancel,
      onClickSave,
    }),
  });

  return (
    <ExpandingCard className="lawyer-type-card" expanded={expanded}>
      <div className="lawyer-type-card-heading">
        {code && <h2>{code}</h2>}
        {!expanded && (
          <Fragment>
            <h2 className="inactive-tag-container">
              <span>{desc}</span>
              <InactiveTag invert inactive={inactive} />
            </h2>
            <div className="lawyer-type-card-actions">
              <button
                className="button-link-appearance"
                onClick={onClickExpand}
                disabled={!expandEnabled}
              >
                {t('components.LawyerTypeCard.expand')}
              </button>
            </div>
          </Fragment>
        )}
        {expanded && (
          <Fragment>
            <InputHelp
              validationContent={t(
                'components.LawyerTypeCard.descriptionValidation'
              )}
              invalid={isTouched('desc') && invalidFields.includes('desc')}
            >
              <input
                type="text"
                name="desc"
                placeholder={t(
                  'components.LawyerTypeCard.descriptionPlaceholder'
                )}
                value={desc}
                disabled={saving}
                onChange={onInputChangeEffect({ onChange })}
                onBlur={onInputBlurEffect({ touch })}
                onClick={incrementInteractionCount}
              />
            </InputHelp>
            <div className="lawyer-type-card-actions">
              <button
                className="button-link-appearance"
                disabled={saving}
                onClick={onClickCancelEffect({
                  interactionCount,
                  setConfirmCancel,
                  onClickCancel,
                  untouchAll,
                })}
              >
                {t('components.LawyerTypeCard.cancel')}
              </button>
              <button
                className="button-link-appearance"
                disabled={saving}
                onClick={save}
              >
                {saving ? t('common.saving') : t('common.save')}
              </button>
            </div>
          </Fragment>
        )}
      </div>
      <div className="lawyer-type-card-body">
        <ExpandingCardContent className="lawyer-type-card-content">
          <form
            onSubmit={(e) => e.preventDefault()}
            onClick={incrementInteractionCount}
          >
            <div className="form-row">
              <InputHelp
                validationContent={t(
                  'components.LawyerTypeCard.glAccountValidation'
                )}
                invalid={
                  isTouched('glAcct') && invalidFields.includes('glAcct')
                }
              >
                <InputLabel content={t('components.LawyerTypeCard.glAccount')}>
                  <StyledSelect
                    name="glAcct"
                    value={glAcct}
                    disabled={saving}
                    onChange={onSelectChangeEffect({ onChange, touch })}
                    onBlur={onInputBlurEffect({ touch })}
                  >
                    {renderAccountOptions(t, accountOptions)}
                  </StyledSelect>
                </InputLabel>
              </InputHelp>

              <InputHelp
                validationContent={t(
                  'components.LawyerTypeCard.gstAccountValidation'
                )}
                invalid={
                  isTouched('gsTcct') && invalidFields.includes('gsTcct')
                }
              >
                <InputLabel content={t('components.LawyerTypeCard.gstAccount')}>
                  <StyledSelect
                    name="gsTcct"
                    value={gsTcct}
                    disabled={saving}
                    onChange={onSelectChangeEffect({ onChange, touch })}
                    onBlur={onInputBlurEffect({ touch })}
                  >
                    {renderAccountOptions(t, accountOptions)}
                  </StyledSelect>
                </InputLabel>
              </InputHelp>

              <Spacer />
            </div>
            <div className="form-row">
              <InputLabel
                element="div"
                content={t('components.LawyerTypeCard.requiredFields')}
              >
                <div className="lawyer-type-card-required-fields">
                  <Checkbox
                    label={t('components.LawyerTypeCard.provinceRequired')}
                    name="requireProvince"
                    checked={requireProvince}
                    disabled={saving}
                    onChange={onCheckboxChangeEffect({ onChange })}
                  />

                  <Checkbox
                    label={t('components.LawyerTypeCard.cityRequired')}
                    name="requireCity"
                    checked={requireCity}
                    disabled={saving}
                    onChange={onCheckboxChangeEffect({ onChange })}
                  />

                  <Checkbox
                    label={t('components.LawyerTypeCard.idRequired')}
                    name="requireId"
                    checked={requireId}
                    disabled={saving}
                    onChange={onCheckboxChangeEffect({ onChange })}
                  />
                </div>
              </InputLabel>

              <DeactivateToggle
                disabled={saving || isEmpty(code)}
                isActive={!inactive}
                onActivate={updateLawyerTypeInactiveEffect({
                  inactive: false,
                  onChange,
                  setConfirmDeactivate,
                })}
                onDeactivate={setConfirmDeactivateEffect({
                  confirm: true,
                  setConfirmDeactivate,
                })}
              />
            </div>
          </form>
        </ExpandingCardContent>
      </div>
      <DeactivateModal
        title={t('components.LawyerTypeCard.DeactivateModal.title')}
        message={t('components.LawyerTypeCard.DeactivateModal.message')}
        mounted={confirmDeactivate}
        onClose={setConfirmDeactivateEffect({
          confirm: false,
          setConfirmDeactivate,
        })}
        onSave={updateLawyerTypeInactiveEffect({
          inactive: true,
          onChange,
          setConfirmDeactivate,
        })}
      />
      <SaveModal
        mounted={confirmCancel}
        onClickCancel={() => setConfirmCancel(false)}
        onClickDiscard={discard}
        onClickProceed={save}
      />
      <NavigationSaveModal
        proceedAfter={async () => await save()}
        shouldBlockNavigation={() => interactionCount > 0 && !confirmCancel}
      />
    </ExpandingCard>
  );
};

LawyerTypeCard.defaultProps = {
  expandEnabled: true,
  saving: false,
};

LawyerTypeCard.propTypes = {
  ...ExpandingCard.propTypes,
  lawyerType: PropTypes.object.isRequired,
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandEnabled: PropTypes.bool,
  saving: PropTypes.bool,
  onClickExpand: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ValidatedLawyerTypeCard = (props) => {
  return (
    <ValidationProvider rules={lawyerTypeValidationRules}>
      <LawyerTypeCard {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingLawyerTypeCard = (props) => (
  <InteractionTrackingProvider>
    <ValidatedLawyerTypeCard {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingLawyerTypeCard;
