import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const legalProblemCodesSortOptions = [
  {
    key: 'codes-asc',
    field: 'codes',
    direction: 'asc',
    i18nKey: 'components.LegalProblemCodesSortMenu.codesAsc',
    icon: faSortAmountDownAlt,
  },
  {
    key: 'codes-desc',
    field: 'codes',
    direction: 'desc',
    i18nKey: 'components.LegalProblemCodesSortMenu.codesDesc',
    icon: faSortAmountDown,
  },
  {
    key: 'name-asc',
    field: 'name',
    direction: 'asc',
    i18nKey: 'components.LegalProblemCodesSortMenu.nameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'name-desc',
    field: 'name',
    direction: 'desc',
    i18nKey: 'components.LegalProblemCodesSortMenu.nameDesc',
    icon: faSortAlphaDownAlt,
  },
];
