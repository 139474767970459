import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import {
  StyledSelect,
  InputLabel,
  ActivityIndicatorCard,
  InputHelp,
} from 'js/components/design-system';
import {
  onEmploymentMount,
  fetchLocalOptionsEffect,
} from '../../client/client-forms/effects';
import {
  onSelectChangeEffect,
  onInputBlurEffect,
  closeModalEffect,
} from './effects';
import { ValidationContext } from 'js/components/validation';

const UpdateCompanyCodeModal = (props) => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { untouch, touch, isTouched } = useContext(ValidationContext);
  const untouchAll = () => untouch(['caseCompany', 'localCode']);
  const {
    mounted,
    onClose,
    caseCompany = {},
    clientInfo = {},
    isActive = false,
    onChange,
    onEdit,
    active,
  } = props;
  const [companyCodes, setCompanyCodes] = useState([]);
  const [locals, setLocals] = useState([]);
  const employmentAbortControllerRef = useRef(null);
  const localAbortControllerRef = useRef(null);

  const { companyCode } = caseCompany;

  const { localCode = '' } = clientInfo;

  useEffect(
    onEmploymentMount({
      t,
      api,
      setCompanyCodes,
      setLocals,
      employmentAbortControllerRef,
    }),
    []
  );

  useEffect(
    fetchLocalOptionsEffect({
      t,
      api,
      companyCode,
      setLocals,
      onChange,
      localAbortControllerRef,
    }),
    [companyCode]
  );

  const loading = companyCodes.length === 0 && locals.length === 0;

  return (
    <Modal
      className="update-company-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={closeModalEffect({ onClose, untouchAll })}
      titleText={t('components.CaseInformation.UpdateCompanyModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <div className="client-summary-heading">
        <h1>{t('components.CaseInformation.UpdateCompanyModal.title')}</h1>
      </div>
      <p>{t('components.CaseInformation.UpdateCompanyModal.subText')}</p>
      {loading && <ActivityIndicatorCard />}
      {!loading && (
        <div className="client-summary-body">
          <div className="client-summary-column">
            <InputLabel
              content={t('components.ClientInformation.Employment.company')}
            >
              <StyledSelect
                value={companyCode}
                onChange={onSelectChangeEffect({
                  onChange,
                  touch,
                  selectOptions: companyCodes,
                  searchKey: 'companyCode',
                })}
                onBlur={onInputBlurEffect({ touch })}
                name="caseCompany"
              >
                <option value="" disabled>
                  {t(
                    'components.ClientInformation.Employment.companyPlaceholder'
                  )}
                </option>
                {companyCodes.map((comp) => {
                  return (
                    <option value={comp.companyCode} key={comp.companyCode}>
                      {`${comp.companyCode} - ${comp.companyName}`}
                    </option>
                  );
                })}
              </StyledSelect>
            </InputLabel>
          </div>

          <div className="client-summary-column">
            <InputHelp
              validationContent={t(
                'components.ClientInformation.Employment.localValidation'
              )}
              invalid={localCode === ''}
            >
              <InputLabel
                content={t('components.ClientInformation.Employment.local')}
              >
                <StyledSelect
                  value={localCode}
                  onChange={onSelectChangeEffect({
                    onChange,
                    touch,
                    selectOptions: locals,
                    searchKey: 'localCode',
                  })}
                  onBlur={onInputBlurEffect({ touch })}
                  name="localInfo"
                  disabled={active || !isTouched('caseCompany')}
                >
                  <option value="" disabled>
                    {t(
                      'components.ClientInformation.Employment.localPlaceholder'
                    )}
                  </option>
                  {locals.map((loc, key) => {
                    return (
                      <option
                        key={key}
                        value={loc.localCode}
                      >{`${loc.localCode} - ${loc.localName}`}</option>
                    );
                  })}
                </StyledSelect>
              </InputLabel>
            </InputHelp>
          </div>
        </div>
      )}
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isActive}
          onClick={closeModalEffect({ onClose, untouchAll })}
        >
          {t('common.cancel')}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          disabled={isActive || loading || localCode === ''}
          onClick={onEdit}
        >
          {isActive
            ? t('common.editing')
            : t('components.CaseInformation.UpdateCompanyModal.editButton')}
        </button>
      </div>
    </Modal>
  );
};

UpdateCompanyCodeModal.defaultProps = {
  mounted: false,
  active: false,
};

UpdateCompanyCodeModal.propTypes = {
  mounted: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onClickDelete: PropTypes.func,
  caseCompany: PropTypes.object,
  clientInfo: PropTypes.object,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
};

export default UpdateCompanyCodeModal;
