import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanLinkedUnions } from './functions';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const getLinkedUnionsEffect = (options = {}) => {
  const {
    t,
    api,
    companyCode,
    setActive,
    setLinkedUnions,
    abortControllerRef,
  } = options;

  return async () => {
    if (!companyCode) {
      return;
    }

    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Admin/Companies/${companyCode}/Unions`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.CompanyDetailsPage.LinkedUnionsList.requestError'
              ),
            },
          },
        }
      );

      setLinkedUnions(cleanLinkedUnions(json));
      setActive(false);
    } catch (error) {
      if (!isAbortError(isAbortError)) {
        setActive(false);
      }
    }
  };
};

export const onSelectUnionEffect = (options = {}) => {
  const { history } = options;
  return (union) => {
    const unionPath = routePathReplacingParams(routePaths.unionDetails, {
      unionNumber: union.unionNumber,
    });
    history.push(unionPath);
  };
};

export const setManageUnionsModalMountedEffect = (options = {}) => {
  const { setManageUnionsModalMounted, getLinkedUnions } = options;
  return (mounted) => {
    setManageUnionsModalMounted(mounted);
    if (!mounted) {
      getLinkedUnions();
    }
  };
};
