import { searchStringFromParams } from 'js/utilities/params';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanUnion } from './functions';
import { formatPhoneNumber } from 'js/utilities/strings';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const unionRequestEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setActive,
    setUnions,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }
    const endpoint = `/Admin/Unions/${query.search}`;
    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: { message: t('components.UnionListPage.searchError') },
          },
        }
      );
      const { items = [], totalItems = 0 } = json;
      setUnions(items);
      setTotalResults(totalItems);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousUnionResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextUnionResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const unionResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const unionResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, SortBy: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const unionSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    history.push(searchStringFromParams(nextParams));
  };
};

export const unionSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    history.push(searchStringFromParams(nextParams));
  };
};

//form effects
export const updateUnionInfoEffect = (options = {}) => {
  const { union, setUnion } = options;
  return (name, value) => setUnion({ ...union, [name]: value });
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

//DETAILS PAGE////////////////////////////////////////////////////

export const unionDetailsRequestEffect = (options = {}) => {
  const {
    t,
    api,
    unionNumber,
    setInitialActive,
    setUnion,
    getUnionAbortControllerRef,
    isNew,
  } = options;
  return async () => {
    if (isNew) {
      setUnion(cleanUnion({}));
      setInitialActive(false);
      return;
    }
    const endpoint = `/Admin/Unions/${unionNumber}`;
    setInitialActive(true);

    rotateAbortControllerRef(getUnionAbortControllerRef);
    const { signal } = getUnionAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.UnionDetailsPage.requestError'),
            },
          },
        }
      );
      setUnion(cleanUnion(json));
      setInitialActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setInitialActive(false);
      }
    }
  };
};

export const unionCompaniesRequestEffect = (options = {}) => {
  const {
    t,
    api,
    unionNumber,
    setInitialActive,
    setCompanies,
    abortCompanyControllerRef,
    isNew,
  } = options;
  return async () => {
    if (isNew) {
      setCompanies([]);
      setInitialActive(false);
      return;
    }
    const endpoint = `/Admin/Unions/${unionNumber}/Companies`;
    setInitialActive(true);

    rotateAbortControllerRef(abortCompanyControllerRef);
    const { signal } = abortCompanyControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.UnionDetailsPage.requestError'),
            },
          },
        }
      );
      setCompanies(json);
      setInitialActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setInitialActive(false);
      }
    }
  };
};

export const patchUnionEffect = (options = {}) => {
  const {
    t,
    api,
    union,
    isValid,
    setActive,
    touchAllFields,
    resetInteractionCount,
    setDidSubmit,
    setUnion,
    patchUnionAbortControllerRef,
  } = options;

  return async () => {
    const { unionNumber } = union;
    setDidSubmit(true);
    if (!isValid || !unionNumber) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }
    setActive(true);
    const body = {
      ...union,
      locPhone1: formatPhoneNumber(union.locPhone1),
      locPhone2: formatPhoneNumber(union.locPhone2),
      locFax: formatPhoneNumber(union.locFax),
    };

    rotateAbortControllerRef(patchUnionAbortControllerRef);
    const { signal } = patchUnionAbortControllerRef.current;

    try {
      const { json } = await api.patchJson(
        `/Admin/Unions/${unionNumber}`,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.UnionDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.UnionDetailsPage.requestPatchError'),
            },
          },
        }
      );
      setUnion(cleanUnion(json));
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const postUnionActiveEffect = (options = {}) => {
  const {
    t,
    api,
    setActive,
    unionNumber,
    union,
    setUnion,
    showModal,
    postUnionActiveAbortControllerRef,
  } = options;

  return async () => {
    try {
      setActive(true);

      rotateAbortControllerRef(postUnionActiveAbortControllerRef);
      const { signal } = postUnionActiveAbortControllerRef.current;

      const locInact = !union.locInact;

      await api.postJson(
        `/Admin/Unions/${unionNumber}/active`,
        { body: { locInact }, signal },
        {
          success: {
            context: {
              message: t('components.UnionDetailsPage.requestPatchSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.UnionDetailsPage.requestPatchError'),
            },
          },
        }
      );

      const nextUnion = { ...union, locInact };

      setUnion(nextUnion);
      setActive(false);
      showModal(false);
    } catch (error) {
      setActive(false);
    }
  };
};

export const postUnionEffect = (options = {}) => {
  const {
    t,
    api,
    union,
    isValid,
    setActive,
    touchAllFields,
    resetInteractionCount,
    setDidSubmit,
    setRedirectPath,
    postUnionAbortControllerRef,
  } = options;

  return async () => {
    setDidSubmit(true);
    if (!isValid) {
      touchAllFields();
      throw new Error(t('common.pageValidationError'));
    }

    rotateAbortControllerRef(postUnionAbortControllerRef);
    const { signal } = postUnionAbortControllerRef.current;

    setActive(true);
    try {
      const { json } = await api.postJson(
        `/Admin/Unions`,
        { body: union, signal },
        {
          success: {
            context: {
              message: t('components.UnionDetailsPage.requestPostSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.UnionDetailsPage.requestPostError'),
            },
          },
        }
      );
      const { unionNumber } = json;
      resetInteractionCount();
      setActive(false);
      setRedirectPath(
        routePathReplacingParams(routePaths.unionDetails, { unionNumber })
      );
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const saveUnionEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

export const touchAllOnSubmitEffect = (options = {}) => {
  const { touchAllFields, didSubmit } = options;
  return async () => {
    if (didSubmit) {
      touchAllFields();
    }
  };
};

export const unionsListPrintRequestEffect = ({ api, query }) => {
  return async () => {
    const { json = {} } = await api.getJson(
      `/Admin/Unions${query.search}`,
      {},
      {
        success: { bypass: true },
        error: { bypass: true },
      }
    );
    const { items = [] } = json;
    const activeUnions = items.filter((item) => !item.locInact);
    return activeUnions.map(cleanUnion);
  };
};
