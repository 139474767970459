import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import ErrorPage from './error';

const Error404 = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      className="error-404"
      title={t('components.Error404.title')}
      heading={t('components.Error404.heading')}
      icon={<FontAwesomeIcon icon={faQuestionCircle} />}
      content={
        <Fragment>
          <p>{t('components.Error404.messageLine1')}</p>
          <p>{t('components.Error404.messageLine2')}</p>
        </Fragment>
      }
    />
  );
};

export default Error404;
