import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { phoneNumberMaskPattern } from 'js/utilities/masks';
import { createMaskEffect, onChangeMaskedInputEffect } from './effects';

const SearchPhoneNumberInput = (props) => {
  const { t } = useTranslation();
  const phoneNumberMaskRef = useRef(null);
  const { phoneNumber = '', onChange, ...inputProps } = props;

  useEffect(
    createMaskEffect({
      maskRef: phoneNumberMaskRef,
      mask: phoneNumberMaskPattern,
    }),
    []
  );

  return (
    <div className="search-phone-number-input">
      <input
        {...inputProps}
        type="text"
        name="phoneNumber"
        autoComplete="off"
        placeholder={t(
          'components.controls.SearchInput.placeholders.phoneNumber'
        )}
        value={phoneNumber}
        onChange={onChangeMaskedInputEffect({
          maskRef: phoneNumberMaskRef,
          onChange,
        })}
      />
    </div>
  );
};

SearchPhoneNumberInput.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchPhoneNumberInput;
