import config from 'js/config';

export const addBeforeUnloadListenerEffect = (options = {}) => {
  if (config.disableBeforeUnloadEvents === true) {
    return () => {};
  }

  const { onBeforeUnload } = options;
  return () => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  };
};

export const navigationBlockEffect = (options = {}) => {
  const {
    history,
    locationRef,
    actionRef,
    unblockRef,
    setIsConfirming,
    shouldBlockNavigation,
  } = options;
  return () => {
    // Note: This will throw 'A history supports only one prompt at a time'
    // warnings in the dev console because we need to regenerate the block
    // callback frequently. It does not appear to have any negative effects.
    unblockRef.current = history.block((location, action) => {
      const shouldBlock = shouldBlockNavigation({ location, action });
      if (shouldBlock) {
        locationRef.current = location;
        actionRef.current = action;
        setIsConfirming(true);
        return false;
      } else {
        unblockRef.current();
        return true;
      }
    });
  };
};

export const navigationBlockUnmountEffect = (options = {}) => {
  const { locationRef, actionRef, unblockRef, setIsConfirming } = options;
  return () => {
    return () => {
      unblockRef.current();
      unblockRef.current = () => {};
      locationRef.current = null;
      actionRef.current = '';
      setIsConfirming(false);
    };
  };
};

export const onClickCancelEffect = (options = {}) => {
  const { setIsConfirming } = options;
  return () => setIsConfirming(false);
};

export const onClickDiscardEffect = (options = {}) => {
  const {
    history,
    locationRef,
    actionRef,
    unblockRef,
    setIsConfirming,
    executeNavigationAction,
  } = options;
  return () => {
    setIsConfirming(false);
    unblockRef.current();
    executeNavigationAction(history, locationRef.current, actionRef.current);
  };
};

export const onClickProceedEffect = (options = {}) => {
  const {
    proceedAfter,
    history,
    locationRef,
    actionRef,
    unblockRef,
    setIsConfirming,
    executeNavigationAction,
  } = options;
  return async () => {
    setIsConfirming(false);
    try {
      await proceedAfter();
      unblockRef.current();
      executeNavigationAction(history, locationRef.current, actionRef.current);
    } catch (error) {
      if (config.debug) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };
};
