import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InputLabel } from 'js/components/design-system';
import { feedbackPropTypes } from './functions';

const LawyerFeedbackProperties = (props) => {
  const { t } = useTranslation();
  const { className, feedback = {} } = props;
  const {
    typeOfComment = false,
    sourceOfCommentDesc = '',
    negativeReasonDesc = '',
    resolvedHowDesc = '',
    resolvedByDesc = '',
  } = feedback;
  const typeOfCommentDesc = typeOfComment
    ? t('components.LawyerFeedbackProperties.positive')
    : t('components.LawyerFeedbackProperties.negative');

  return (
    <form
      className={classnames('lawyer-feedback-screen-properties', className)}
    >
      <div className="form-row">
        <InputLabel
          content={t('components.LawyerFeedbackProperties.sourceOfComment')}
        >
          <input type="text" value={sourceOfCommentDesc} readOnly />
        </InputLabel>

        <InputLabel
          content={t('components.LawyerFeedbackProperties.typeOfComment')}
        >
          <input type="text" value={typeOfCommentDesc} readOnly />
        </InputLabel>

        <InputLabel
          content={t('components.LawyerFeedbackProperties.negativeReason')}
        >
          <input type="text" value={negativeReasonDesc} readOnly />
        </InputLabel>

        <InputLabel
          content={t('components.LawyerFeedbackProperties.resolvedHow')}
        >
          <input type="text" value={resolvedHowDesc} readOnly />
        </InputLabel>

        <InputLabel
          content={t('components.LawyerFeedbackProperties.resolvedBy')}
        >
          <input type="text" value={resolvedByDesc} readOnly />
        </InputLabel>
      </div>
    </form>
  );
};

LawyerFeedbackProperties.propTypes = {
  className: PropTypes.string,
  feedback: PropTypes.shape(feedbackPropTypes).isRequired,
};

export default LawyerFeedbackProperties;
