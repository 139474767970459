import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PaginationButton from './pagination-button';

const PaginationNumberButton = (props) => {
  const { className, pageNumber, currentPage, onClick, ...buttonProps } = props;
  const dynamicClassNames = {
    'pagination-current-page': pageNumber === currentPage,
  };
  return (
    <PaginationButton
      className={classnames(
        'pagination-number-button',
        dynamicClassNames,
        className
      )}
      onClick={(e) => onClick(e, { pageNumber })}
      {...buttonProps}
    >
      {pageNumber}
    </PaginationButton>
  );
};

PaginationNumberButton.propTypes = {
  ...PaginationButton.propTypes,
  pageNumber: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PaginationNumberButton;
