import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  InputLabel,
  Card,
  InputHelp,
} from 'js/components/design-system';
import { NamedPermissionsContext } from 'js/components/group-permissions';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';
import PropTypes from 'prop-types';
import { ValidationContext } from 'js/components/validation';
import { onInputChangeEffect, onInputBlurEffect } from './effects';

const Spacer = () => <div />;

const Address = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched } = useContext(ValidationContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editClientInformation');
  const { formData = {}, onChange } = props;
  const {
    address = '',
    address2 = '',
    city = '',
    postalCode = '',
    province = '',
  } = formData;

  const provinces = provinceOptions(t);

  const { invalidFields = [] } = validate(formData, [
    'address',
    'address2',
    'city',
    'postalCode',
  ]);

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  return (
    <Card className="client-address-form">
      <h2>{t('components.ClientInformation.Address.title')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.ClientInformation.Address.addressValidation'
          )}
          invalid={isTouched('address') && invalidFields.includes('address')}
        >
          <InputLabel
            content={t('components.ClientInformation.Address.address')}
          >
            <input
              type="text"
              name="address"
              autoComplete="off"
              value={address}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Address.address2Validation'
          )}
          invalid={isTouched('address2') && invalidFields.includes('address2')}
        >
          <InputLabel
            content={t('components.ClientInformation.Address.address2')}
          >
            <input
              name="address2"
              autoComplete="address2"
              type="text"
              value={address2}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Address.cityValidation'
          )}
          invalid={isTouched('city') && invalidFields.includes('city')}
        >
          <InputLabel content={t('components.ClientInformation.Address.city')}>
            <input
              name="city"
              type="text"
              autoComplete="city"
              value={city}
              readOnly={!hasEditPermission}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Address.provinceValidation'
          )}
          invalid={isTouched('province') && invalidFields.includes('province')}
        >
          <InputLabel
            content={t('components.ClientInformation.Address.province')}
          >
            <StyledSelect
              value={province}
              name="province"
              disabled={!hasEditPermission}
              {...inputEventHandlers}
            >
              <option value="">
                {t('components.ClientInformation.Address.provincePlaceholder')}
              </option>
              {provinces.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.ClientInformation.Address.postalCodeValidation'
          )}
          invalid={
            isTouched('postalCode') && invalidFields.includes('postalCode')
          }
        >
          <InputLabel
            content={t('components.ClientInformation.Address.postalCode')}
          >
            <PostalCodeInput
              name="postalCode"
              autoComplete="postalCode"
              placeholder="L6L 6L6"
              value={postalCode}
              readOnly={!hasEditPermission}
              onChange={onPostalCodeInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <Spacer />
      </div>
    </Card>
  );
};

Address.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
};

export default Address;
