import { caseApiKey, cleanCase } from 'js/utilities/cases';
import { cleanClientSummary } from 'js/components/client-summary';
import { cleanClientOptions } from 'js/utilities/clients';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import {
  resolveCasePostErrorMessage,
  performChecksForDuplicateCases,
} from './functions';
import { isEmpty } from 'js/utilities/validation';
import { caseStatuses } from 'js/utilities/cases';
import { isStaffLawyerType } from 'js/utilities/lawyers';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive, setSaveActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
      setSaveActive(false);
    };
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const getClientEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    setActive,
    setClient,
    setClientOptions,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (!clientId || !clientCode) {
      return;
    }

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal } = getClientAbortControllerRef.current;

    setActive(true);

    try {
      const { json = {} } = await api.getJson(
        `/Client/${clientId}/${clientCode}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseInformation.clientRequestError'),
            },
          },
        }
      );

      setClient(cleanClientSummary(json));
      setClientOptions(cleanClientOptions(json));
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const updateCaseInformationEffect = (options = {}) => {
  const { setCaseInfo } = options;
  return (name, value) =>
    setCaseInfo((caseInfo) => {
      const { caseInformation = {} } = caseInfo;
      return {
        ...caseInfo,
        caseInformation: { ...caseInformation, [name]: value },
      };
    });
};

export const updateClientCompanyCodeEffect = (options = {}) => {
  const { caseCompany, client, setClient } = options;
  return () => {
    const { companyCode, companyName } = caseCompany;
    const { companyCode: clientCompanyCode } = client;
    if (isEmpty(caseCompany) || isEmpty(client)) {
      return;
    } else if (companyCode === clientCompanyCode) {
      return;
    }
    const nextClient = {
      ...client,
      companyName,
      companyCode,
    };
    setClient(nextClient);
  };
};

export const updateClientLocalCodeEffect = (options = {}) => {
  const { localInfo, client, setClient } = options;
  return () => {
    const { localCode, localName } = localInfo;
    const { localCode: clientLocalCode } = client;
    if (isEmpty(client) || isEmpty(localInfo)) {
      return;
    } else if (localCode === clientLocalCode) {
      return;
    }
    const nextClient = {
      ...client,
      localCode,
      localName,
    };
    setClient(nextClient);
  };
};

export const saveAndCloseModalEffect = (options = {}) => {
  //used to close the admin only change company code and union modal
  const { patchEffect, setMounted } = options;
  return async () => {
    patchEffect();
    setMounted(false);
  };
};

export const patchCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    // prompt,
    caseInfo,
    isValid,
    touchAll,
    setActive,
    setCaseInfo,
    resetInteractionCount,
    patchCaseAbortControllerRef,
  } = options;

  return async () => {
    const { caseInformation = {}, caseLawyer = {} } = caseInfo;
    const { cif } = caseInformation;
    if (!isValid || !cif) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    // Copy of the case lawyer data that can be mutated
    // by the estate trustee confirmation prompt:
    const mutableCaseLawyer = { ...caseLawyer };

    // try {
    //   await prompt({ caseInformation, caseLawyer }, 'case.estateTrusteeRule');
    // } catch (error) {
    //   return;
    // }

    rotateAbortControllerRef(patchCaseAbortControllerRef);
    const { signal } = patchCaseAbortControllerRef.current;

    setActive(true);

    const url = caseApiKey(cif);

    // Remove the legacy `officeOpened` property and assign it to openedInOffice.code:
    const { officeOpened, ...otherCaseInformation } = caseInformation;
    const nextCaseInformation = {
      ...otherCaseInformation,
      openedInOffice: { code: officeOpened },
    };
    const nextCaseInfo = {
      ...caseInfo,
      caseInformation: nextCaseInformation,
      caseLawyer: mutableCaseLawyer,
    };

    try {
      const { json = {} } = await api.patchJson(
        url,
        { body: nextCaseInfo, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );

      const updatedCaseInfo = cleanCase(json);
      setCaseInfo(updatedCaseInfo);
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const patchComapnyLocalEffect = (options = {}) => {
  const {
    t,
    api,
    prompt,
    caseInfo,
    isValid,
    touchAll,
    setActive,
    resetInteractionCount,
    patchCaseAbortControllerRef,
    presentCompanyBanner,
  } = options;

  return async () => {
    //This endpoint will allow you to change the company code and the local.
    //We do some validation to make sure other parts of system will still work
    //because of all the interdependencies including fee schedules.

    const { caseInformation = {}, caseLawyer = {} } = caseInfo;
    const { cif, caseCompany, localInfo } = caseInformation;
    if (!isValid || !cif) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    try {
      await prompt({ caseInformation, caseLawyer }, 'case.estateTrusteeRule');
    } catch (error) {
      return;
    }

    rotateAbortControllerRef(patchCaseAbortControllerRef);
    const { signal } = patchCaseAbortControllerRef.current;

    setActive(true);

    const url = `/Case/${cif}/localcompany`;
    const body = {
      companyCode: caseCompany.companyCode,
      localCode: localInfo.localCode,
    };

    try {
      await api.patchJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.patchRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CaseInformation.requestError'),
            },
          },
        }
      );

      presentCompanyBanner();
      resetInteractionCount();
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
        throw error;
      }
    }
  };
};

export const companyChangedBannerEffect = (options = {}) => {
  const { t, presentStyledBanner, dismissBanner, caseInfo } = options;
  return () => {
    const { caseLawyer = {}, caseInformation = {} } = caseInfo;
    const isClosed = caseInformation.caseStatus === caseStatuses.closed;
    const isStaffLawyer = isStaffLawyerType(caseLawyer.lawyerType);
    if (!isStaffLawyer || isClosed) {
      //This message should appear when this criteria is met:
      //case is closed
      //case is a non-staff case
      //prompt appears only when the case is assigned to co-operating and non-co-operating lawyers

      let bannerId = '';

      bannerId = presentStyledBanner('warning', {
        className: 'client-company-changed-banner',
        timeout: 0,
        progress: false,
        animated: false,
        content: t('components.ClientPage.companyChangedMessage'),
        dismissButtonContent: t('common.done'),
        onDismiss: () => {
          dismissBanner(bannerId);
        },
      });

      return () => {
        if (bannerId) {
          dismissBanner(bannerId, false);
        }
      };
    } else {
      return;
    }
  };
};

export const postCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    prompt,
    caseInfo,
    client,
    isValid,
    setActive,
    setRedirect,
    touchAll,
    resetInteractionCount,
    postCaseAbortControllerRef,
  } = options;

  const { clientId, clientCode, clientStatus } = client;

  return async () => {
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    if (clientStatus === '5') {
      try {
        await prompt(client, 'case.dependent.age');
      } catch (error) {
        return;
      }
    }

    setActive(true);

    try {
      // Remove the legacy `officeOpened` property and assign it to openedInOffice.code:
      const { caseInformation: inputCaseInformation = {} } = caseInfo;
      const { officeOpened, ...otherCaseInformation } = inputCaseInformation;
      const caseInformation = {
        ...otherCaseInformation,
        openedInOffice: { code: officeOpened },
      };

      const {
        caseInformation: nextCaseInformation,
        allowDuplicateLpCode,
      } = await performChecksForDuplicateCases({
        t,
        api,
        prompt,
        clientId,
        clientCode,
        caseInformation,
        postCaseAbortControllerRef,
      });

      const body = {
        caseInformationResponse: {
          ...nextCaseInformation,
          clientId,
          clientCode,
          lastEdit: new Date(),
          openDate: new Date(),
        },
      };

      // Rotate the used abort controller before making the POST request:
      rotateAbortControllerRef(postCaseAbortControllerRef);
      const { signal } = postCaseAbortControllerRef.current;

      const response = await api.postJson(
        `/Case?allowDuplicateLpCode=${allowDuplicateLpCode.toString()}`,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: resolveCasePostErrorMessageEffect({ t }),
            },
          },
        }
      );

      const { caseInformationResponse } = response.json;
      const caseNumber = encodeURIComponent(caseInformationResponse.cif);
      const path = routePathReplacingParams(routePaths.caseLawyer, {
        caseNumber,
      });

      resetInteractionCount();
      setActive(false);
      setRedirect(path);
    } catch (error) {
      if (error && !isAbortError(error)) {
        setActive(false);
        throw error;
      } else if (!error) {
        // Swallow undefined thrown by prompts
        setActive(false);
      }
    }
  };
};

export const postCaseCopyEffect = (options = {}) => {
  const {
    t,
    api,
    prompt,
    isValid,
    caseNumber,
    caseInfo,
    client,
    setActive,
    setRedirect,
    touchAll,
    resetInteractionCount,
    postCaseAbortControllerRef,
  } = options;

  const { clientId, clientCode, clientStatus } = client;

  return async () => {
    if (!isValid) {
      touchAll();
      throw new Error(t('common.pageValidationError'));
    }

    if (clientStatus === '5') {
      try {
        await prompt(client, 'case.dependent.age');
      } catch (error) {
        return;
      }
    }

    setActive(true);

    try {
      const { caseInformation = {}, caseLawyer = {} } = caseInfo;
      const {
        caseInformation: nextCaseInformation,
        allowDuplicateLpCode,
      } = await performChecksForDuplicateCases({
        t,
        api,
        prompt,
        clientId,
        clientCode,
        caseInformation,
        postCaseAbortControllerRef,
      });

      const body = {
        caseInformationResponse: {
          ...nextCaseInformation,
          clientId,
          clientCode,
          cif: caseNumber,
          lastEdit: new Date(),
          openDate: new Date(),
        },
        caseLawyerResponse: {
          ...caseLawyer,
          servicesProvidedCode: '',
          servicesProvidedDesc: null,
          referralReasonCode: '',
          referralReasonDesc: null,
          reasonCode: '',
          reasonDesc: null,
          sentToLawyer: null,
        },
        isCopy: true,
      };

      // Rotate the used abort controller before making the POST request:
      rotateAbortControllerRef(postCaseAbortControllerRef);
      const { signal } = postCaseAbortControllerRef.current;

      const { json = {} } = await api.postJson(
        `/Case?allowDuplicateLpCode=${allowDuplicateLpCode.toString()}`,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.CaseInformation.postRequestSuccess'),
            },
          },
          error: {
            context: {
              message: resolveCasePostErrorMessageEffect({ t }),
            },
          },
        }
      );

      const { caseInformationResponse = {} } = json;
      const newCaseNumber = encodeURIComponent(caseInformationResponse.cif);
      const path = routePathReplacingParams(routePaths.case, {
        caseNumber: newCaseNumber,
      });
      resetInteractionCount();
      setActive(false);
      setRedirect(path);
    } catch (error) {
      if (error && !isAbortError(error)) {
        setActive(false);
        throw error;
      } else if (!error) {
        // Swallow undefined thrown by prompts
        setActive(false);
      }
    }
  };
};

export const resolveCasePostErrorMessageEffect = (options = {}) => {
  const { t } = options;
  return (result) => resolveCasePostErrorMessage(t, result);
};

export const saveCaseInformationEffect = (options = {}) => {
  const { t, saveEffect, presentStyledBanner } = options;
  return async () => {
    try {
      await saveEffect();
    } catch (error) {
      // Display validation errors and rethrow the error
      // to prevent the NavigationSaveModal from  proceeding:
      if (error && error.message === t('common.pageValidationError')) {
        presentStyledBanner('error', {
          content: error.message,
        });
      }
      throw error;
    }
  };
};

//this select effect returns an object
export const onSelectChangeEffect = (options = {}) => {
  const { onChange, touch, selectOptions, searchKey } = options;
  return async (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      const valueItem = selectOptions.find((op) => op[searchKey] === value);
      touch(e.target.name);
      onChange(name, valueItem);
      if (name === 'caseCompany') {
        //force users to select the local
        onChange('localInfo', { localName: '', localCode: '' });
      }
    }
  };
};

export const closeModalEffect = (options = {}) => {
  const { onClose, untouchAll } = options;
  return () => {
    untouchAll();
    onClose();
  };
};
