import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Checkbox,
  DropdownMenu,
  FontAwesomeIcon,
  ExpandingCard,
  ExpandingCardContent,
} from 'js/components/design-system';
import {
  RunEditReportModal,
  MoveReportModal,
  DuplicateReportModal,
  DeleteReportModal,
  SaveAsModal,
} from '../reports-modals';
import {
  onChangeReportCheckboxEffect,
  unmountRunEditModalEffect,
  onModalRequestEffect,
  onMoveReportModalRequestEffect,
} from './effects';
import { reportsDateRanges, reportModifyOptions } from './functions';

const ReportsListItem = (props = {}) => {
  const {
    report = {},
    folderData = {},
    isModifyingReport,
    isRunningReport,
    onSaveReport,
    onRunReport,
    onMoveReport,
    onDuplicateReport,
    onDeleteReport,
    onSaveAsReport,
    reportsFilterIndex = 0,
  } = props;
  const { reportName, filter: reportFilter = '' } = report;
  const { t } = useTranslation();

  const [isExpanded, setExpanded] = useState(false);
  const [runModalMounted, setRunModalMounted] = useState(false);
  const [editModalMounted, setEditModalMounted] = useState(false);
  const [saveAsModalMounted, setSaveAsModalMounted] = useState(false);
  const [moveModalMounted, setMoveModalMounted] = useState(false);
  const [duplicateModalMounted, setDuplicateModalMounted] = useState(false);
  const [deleteModalMounted, setDeleteModalMounted] = useState(false);

  const modifyOptions = reportModifyOptions({
    t,
    setRunModalMounted,
    setEditModalMounted,
    setMoveModalMounted,
    setDuplicateModalMounted,
    setDeleteModalMounted,
    setSaveAsModalMounted,
  });

  const unMountRunEdit = unmountRunEditModalEffect({
    setRunModalMounted,
    setEditModalMounted,
  });

  return (
    <li className="reports-list-item">
      <ExpandingCard expanded={isExpanded}>
        <div className="reports-list-item-main-content">
          <h4 onClick={() => setExpanded(!isExpanded)}>{reportName}</h4>
          <div className="reports-list-item-date-ranges">
            {reportsDateRanges.map((name) => (
              <Checkbox
                label=""
                key={name}
                name={name}
                checked={report[name] === true}
                disabled={isModifyingReport}
                onChange={onChangeReportCheckboxEffect({
                  report,
                  onSaveReport,
                })}
              />
            ))}
          </div>
          <div className="reports-list-item-controls">
            <DropdownMenu options={modifyOptions} selectedOptionIndex={0} />
            <button className="button" onClick={() => setRunModalMounted(true)}>
              {t('components.ReportsList.run')}
            </button>
            <button
              className="reports-list-item-expand-button"
              onClick={() => setExpanded(!isExpanded)}
            >
              <FontAwesomeIcon
                icon={isExpanded ? faMinusCircle : faPlusCircle}
              />
            </button>
          </div>
        </div>
        <ExpandingCardContent>
          <div className="reports-list-item-expanded-content">
            <textarea value={reportFilter} readOnly />
          </div>
        </ExpandingCardContent>
      </ExpandingCard>

      <RunEditReportModal
        mounted={runModalMounted || editModalMounted}
        report={report}
        useRunMode={runModalMounted}
        isModifyingReport={isModifyingReport}
        isRunningReport={isRunningReport}
        onConfirmSave={onSaveReport}
        onConfirmRun={onRunReport}
        onDismiss={unMountRunEdit}
        reportsFilterIndex={reportsFilterIndex}
      />

      <SaveAsModal
        mounted={saveAsModalMounted}
        report={report}
        folderData={folderData}
        onSaveAsReport={onSaveAsReport}
        onDismiss={() => setSaveAsModalMounted(false)}
      />

      <MoveReportModal
        mounted={moveModalMounted}
        onDismiss={() => setMoveModalMounted(false)}
        onConfirm={onMoveReportModalRequestEffect({
          report,
          requestFunc: onMoveReport,
          setModalMounted: setMoveModalMounted,
        })}
        isActive={isModifyingReport}
      />

      <DuplicateReportModal
        mounted={duplicateModalMounted}
        onDismiss={() => setDuplicateModalMounted(false)}
        onConfirm={onModalRequestEffect({
          report,
          requestFunc: onDuplicateReport,
          setModalMounted: setDuplicateModalMounted,
        })}
        isActive={isModifyingReport}
      />

      <DeleteReportModal
        mounted={deleteModalMounted}
        onDismiss={() => setDeleteModalMounted(false)}
        onConfirm={onModalRequestEffect({
          report,
          requestFunc: onDeleteReport,
          setModalMounted: setDeleteModalMounted,
        })}
        isActive={isModifyingReport}
      />
    </li>
  );
};

ReportsListItem.defaultProps = {
  dateRangesDisabled: false,
  isModifyingReport: false,
  isRunningReport: false,
};

ReportsListItem.propTypes = {
  report: PropTypes.object.isRequired,
  folderData: PropTypes.object.isRequired,
  isModifyingReport: PropTypes.bool,
  isRunningReport: PropTypes.bool,
  onSaveReport: PropTypes.func.isRequired,
  onRunReport: PropTypes.func.isRequired,
  onMoveReport: PropTypes.func.isRequired,
  onDuplicateReport: PropTypes.func.isRequired,
  onDeleteReport: PropTypes.func.isRequired,
  onSaveAsReport: PropTypes.func.isRequired,
  reportsFilterIndex: PropTypes.number,
};

export default ReportsListItem;
