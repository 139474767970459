import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import { StyledBannerContext } from 'js/components/banner-styled';
import { DeactivateModal, DeactivateToggle } from 'js/components/deactivate';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import {
  legalProblemCodeValidationRules,
  legalProblemCodeRequiredFields,
} from 'js/utilities/legal-problem-codes';
import LegalProblemCodeBreadcrumbs from './breadcrumbs';
import LegalProblemCodeForm from './form';
import {
  onMountEffect,
  getFormOptionsEffect,
  getLegalProblemCodeEffect,
  patchLegalProblemCodeEffect,
  postLegalProblemCodeActiveEffect,
  postLegalProblemCodeEffect,
  saveLegalProblemCodeEffect,
  updateLegalProblemCodeEffect,
} from './effects';
import { isNewPath } from './functions';
import { PrintFooter } from 'js/components/print';
import LegalProblemCodePrintHeader from './legal-problem-print-header';

const LegalProblemCodeDetail = () => {
  const {
    params: { code = '' },
  } = useRouteMatch();
  const { pathname } = useLocation();
  const isNew = isNewPath(pathname);
  const getFormOptionsAbortControllerRef = useRef(null);
  const getLegalProblemCodeAbortControllerRef = useRef(null);
  const patchLegalProblemCodeAbortControllerRef = useRef(null);
  const postLegalProblemCodeAbortControllerRef = useRef(null);
  const postLegalProblemCodeActiveAbortControllerRef = useRef(null);
  const { t } = useTranslation();
  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    incrementInteractionCount,
    interactionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);
  const [displayName, setDisplayName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [legalProblemCode, setLegalProblemCode] = useState({});
  const [legalProblemCodeActive, setLegalProblemCodeActive] = useState(false);
  const [formOptionsActive, setFormOptionsActive] = useState(false);
  const [formOptions, setFormOptions] = useState({});
  const [redirectPath, setRedirectPath] = useState('');
  const { api = {} } = useContext(FetchContext);
  const active = legalProblemCodeActive || formOptionsActive;
  const { inactive = false } = legalProblemCode;
  const {
    serviceProvided = [],
    benefitTypes = [],
    lawyerPanels = [],
  } = formOptions;

  const pageTitle = isNew
    ? t('components.LegalProblemCodeDetail.newTitle')
    : t('components.LegalProblemCodeDetail.title', {
        code,
        name: displayName,
      });

  const pageHeading = isNew
    ? t('components.LegalProblemCodeDetail.newHeading')
    : `${code} \u2013 ${displayName}`;

  const validateAll = (data) => validate(data, legalProblemCodeRequiredFields);
  const touchAll = () => touch(legalProblemCodeRequiredFields);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getFormOptionsAbortControllerRef,
        getLegalProblemCodeAbortControllerRef,
        patchLegalProblemCodeAbortControllerRef,
        postLegalProblemCodeAbortControllerRef,
        postLegalProblemCodeActiveAbortControllerRef,
      ],
      setLegalProblemCodeActive,
      setFormOptionsActive,
      setSaving,
      setRedirectPath,
    }),
    [code]
  );

  useEffect(
    getFormOptionsEffect({
      t,
      api,
      setFormOptionsActive,
      setFormOptions,
      getFormOptionsAbortControllerRef,
    }),
    []
  );

  useEffect(
    getLegalProblemCodeEffect({
      t,
      api,
      isNew,
      code,
      setLegalProblemCodeActive,
      setLegalProblemCode,
      setDisplayName,
      getLegalProblemCodeAbortControllerRef,
    }),
    [code]
  );

  const patchLegalProblemCode = patchLegalProblemCodeEffect({
    t,
    api,
    validateAll,
    touchAll,
    code,
    legalProblemCode,
    setSaving,
    setLegalProblemCode,
    setDisplayName,
    resetInteractionCount,
    patchLegalProblemCodeAbortControllerRef,
  });

  const postLegalProblemCodeActive = postLegalProblemCodeActiveEffect({
    t,
    api,
    code,
    legalProblemCode,
    setSaving,
    setLegalProblemCode,
    setShowModal,
    postLegalProblemCodeActiveAbortControllerRef,
  });

  const postLegalProblemCode = postLegalProblemCodeEffect({
    t,
    api,
    validateAll,
    touchAll,
    legalProblemCode,
    setSaving,
    resetInteractionCount,
    setRedirectPath,
    postLegalProblemCodeAbortControllerRef,
  });

  const saveLegalProblemCode = saveLegalProblemCodeEffect({
    t,
    saveEffect: isNew ? postLegalProblemCode : patchLegalProblemCode,
    presentStyledBanner,
  });

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Page
      className="legal-problem-code-detail"
      title={pageTitle}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <LegalProblemCodeBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          <LegalProblemCodePrintHeader
            title={t('components.LegalProblemCodes.title')}
            heading={`${code} \u2013 ${displayName}`}
          />
          {active && <ActivityIndicatorCard />}

          {!active && (
            <Fragment>
              <div className="legal-problem-code-detail-heading">
                <div className="legal-problem-code-detail-heading-left">
                  <h1>{pageHeading}</h1>
                  <DeactivateToggle
                    disabled={isNew || saving}
                    isActive={!inactive}
                    onActivate={postLegalProblemCodeActive}
                    onDeactivate={() => setShowModal(true)}
                  />
                  <DeactivateModal
                    title={t(
                      'components.LegalProblemCodeDetail.DeactivateModal.title'
                    )}
                    message={t(
                      'components.LegalProblemCodeDetail.DeactivateModal.message'
                    )}
                    mounted={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={postLegalProblemCodeActive}
                    active={saving}
                  />
                </div>
                <div className="legal-problem-code-detail-heading-right">
                  <button
                    className="button button-highlight page-action-button"
                    onClick={() => window.print()}
                  >
                    {t('common.print')}
                  </button>
                  <button
                    className="button button-highlight page-action-button"
                    onClick={saveLegalProblemCode}
                    disabled={saving}
                  >
                    {saving ? t('common.saving') : t('common.save')}
                  </button>
                </div>
              </div>
              <NavigationSaveModal
                proceedAfter={async () => await saveLegalProblemCode()}
                shouldBlockNavigation={() => interactionCount > 0}
              />
              <form
                onClick={incrementInteractionCount}
                onSubmit={(e) => e.preventDefault()}
                data-print-managed
              >
                <LegalProblemCodeForm
                  formData={legalProblemCode}
                  formOptions={{ serviceProvided, benefitTypes, lawyerPanels }}
                  onChange={updateLegalProblemCodeEffect({
                    legalProblemCode,
                    setLegalProblemCode,
                  })}
                />
              </form>
            </Fragment>
          )}
          <PrintFooter />
        </div>
      </div>
    </Page>
  );
};

const ValidatedLegalProblemCodeDetail = () => {
  return (
    <ValidationProvider rules={{ ...legalProblemCodeValidationRules }}>
      <LegalProblemCodeDetail />
    </ValidationProvider>
  );
};

const InteractionTrackingLegalProblemCodeDetail = () => (
  <InteractionTrackingProvider>
    <ValidatedLegalProblemCodeDetail />
  </InteractionTrackingProvider>
);

export default InteractionTrackingLegalProblemCodeDetail;
