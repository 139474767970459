import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import { MSALContext } from 'js/components/msal';
import { routePaths } from 'js/components/router/route-paths';

const Logout = () => {
  const cache = useContext(DataContext);
  const { account, logout } = useContext(MSALContext);

  const history = useHistory();

  useEffect(() => {
    cache.deleteAll();
    if (account) {
      logout(account);
    } else {
      history.replace(routePaths.login);
    }
  }, []);

  return null;
};

export default Logout;
