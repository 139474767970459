import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import DayEndDateInput from 'js/components/controls/day-end-date-input';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { validateDate } from 'js/utilities/validation';
import { formatDateFromString } from 'js/utilities/dates';
import PrintReportCard from './print-report-card';
import {
  onChangeInputEffect,
  onBlurReportDateInputEffect,
  onConfirmDateEffect,
  onResetDateEffect,
  runCaseOpenedReportEffect,
  setUrlDateEffect,
} from './effects';
import {
  getDayEndReportConfiguration,
  queryForSearchString,
} from './functions';
import DayEndBreadcrumbs from './breadcrumbs';
import { CurrentUserContext } from 'js/components/current-user';

const DayEnd = (props) => {
  const { className, ...pageProps } = props;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const history = useHistory();
  const { search = '' } = useLocation();
  const query = queryForSearchString(search);
  const runReportAbortControllerRef = useRef(null);
  const { currentUser = {} } = useContext(CurrentUserContext);
  const isHeadOfficeUser = currentUser.office?.code === '0100'; //head office

  const [reportDate, setReportDate] = useState('');
  const [active, setActive] = useState(false);
  const [dateConfirmed, setDateConfirmed] = useState(false);
  const dayEndDateInputRef = useRef(null);
  const { touch, untouch, isTouched } = useContext(ValidationContext);

  const touchReportDateInput = () => touch('reportDateInput');
  const untouchReportDateInput = () => untouch('reportDateInput');
  const parsedDate = formatDateFromString(reportDate);
  const isReportDateValid =
    !isTouched('reportDateInput') || validateDate(parsedDate);
  const reportEnabled = isReportDateValid && dateConfirmed;
  const reportsConfig = getDayEndReportConfiguration(
    reportDate,
    isHeadOfficeUser
  );

  const runCaseReport = runCaseOpenedReportEffect({
    t,
    api,
    parsedDate,
    setActive,
    runReportAbortControllerRef,
  });

  useEffect(setUrlDateEffect({ query, setReportDate, setDateConfirmed }), []);

  return (
    <Page
      className={classnames('day-end-page', className)}
      title={t('components.dayEnd.title')}
      header={<FixedHeader />}
      {...pageProps}
    >
      <div className="layout-container inset-col-1">
        <div className="layout-column">
          <DayEndBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          <h1>{t('components.dayEnd.title')}</h1>
          <DayEndDateInput
            inputRef={dayEndDateInputRef}
            inputName="reportDate"
            value={reportDate}
            isValid={isReportDateValid}
            onChange={onChangeInputEffect({
              setReportDate,
            })}
            onBlur={onBlurReportDateInputEffect({
              touchReportDateInput,
            })}
            onConfirm={onConfirmDateEffect({
              setDateConfirmed,
              touchReportDateInput,
              reportDate,
              isReportDateValid,
              history,
              query,
            })}
            onReset={onResetDateEffect({
              setDateConfirmed,
              setReportDate,
              untouchReportDateInput,
              history,
              query,
            })}
            buttonDisabled={!reportDate || !validateDate(parsedDate)}
          />
        </div>
      </div>
      <div
        className={`day-end-print-section ${!reportEnabled ? 'disabled' : ''}`}
      >
        <div className="layout-container inset-col-2">
          <div className="layout-column">
            {Object.keys(reportsConfig).map((key, i) => (
              <PrintReportCard
                reportType={key}
                printUrl={reportsConfig[key].url}
                runReport={runCaseReport}
                reportDate={reportDate}
                active={active}
                key={i}
                visible={reportsConfig[key].visible}
              />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

DayEnd.propTypes = {
  className: PropTypes.string,
};

const ValidatedDayEnd = () => (
  <ValidationProvider>
    <DayEnd />
  </ValidationProvider>
);
export default ValidatedDayEnd;
