import React from 'react';
import { Link } from 'react-router-dom';
import { cleanStringField } from 'js/utilities/strings';
import { LAWYER_TYPE_ENUM, returnlawyerTypeFromId } from 'js/utilities/lawyers';

export const cleanLawyerSummary = (lawyer = {}) => {
  const { lawyerInfo = {} } = lawyer;
  const {
    lawyerId,
    staffId,
    firstName,
    lastName,
    city,
    province,
    fax,
    phoneNumber,
    email,
    lawyerInactive, // Coop lawyers
    isActive, // Staff lawyers
  } = lawyerInfo;

  return {
    lawyerId: cleanStringField(lawyerId),
    staffId: cleanStringField(staffId),
    firstName: cleanStringField(firstName),
    lastName: cleanStringField(lastName),
    city: cleanStringField(city),
    province: cleanStringField(province),
    fax: cleanStringField(fax),
    phoneNumber: cleanStringField(phoneNumber),
    email: cleanStringField(email),
    //this is more of an estimate as lawyers do not have type
    //saved on their object, only on case
    lawyerType: returnlawyerTypeFromId(lawyerId),
    lawyerInactive: lawyerInactive === true || isActive === false,
  };
};

export const cleanStaffLawyerSummary = (lawyerInfo = {}) => {
  const lawyerSummary = cleanLawyerSummary({ lawyerInfo });
  return { ...lawyerSummary, lawyerType: LAWYER_TYPE_ENUM.STAFF };
};

export const linkComponent = (component, linkTo, linkProps = {}) => {
  if (linkTo) {
    return (
      <Link to={linkTo} {...linkProps}>
        {component}
      </Link>
    );
  } else {
    return component;
  }
};
