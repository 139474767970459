export default {
  header: {
    title: 'Régime De Services Juridiques UNIFOR',
    heading: "Sondage d'opinion des usagers",
    //heading: 'Sondage d\'opinion des usagers: Avocat(e) ou Notaire Coopérant(e)',
  },
  footer: {
    line1: "Merci d'avoir bien voulu compléter ce questionnaire.",
    line2:
      "Veuilles maintenant nous le retourner dans l'enveloppe-réponse affranchie en annexe dès que possible.",
  },
  information: {
    lawyerId: 'I.D.:',
    lawyerName: "Nom de l'Avocat(e):",
    clientName: 'Nom du Client:',
    caseNumber: 'Dossier #:',
    email: 'Votre Courriel:',
    phone: 'Votre # de Telephone:',
    legalProblemCode: 'LP Code:',
    contact: "S'il vous plaît contactez-moi au sujet de mon dossier",
  },
  questions: {
    1: 'Avez-vous été traité avec courtoisie par votre avocat(e) ou notaire?',
    2: 'Avez-vous été traité avec courtoisie par les autres personnes du bureau?',
    3: 'Votre avocat(e) ou notaire vous a-t-il clairement expliqué votre problème juridique\net les étapes pour le résoudre?',
    4: 'Votre avocat(e) ou notaire vous a-t-il tenu au courant de ce qui se déroulait dans\nvotre dossier?',
    5: 'Les réponses de votre avocat(e) ou notaire à vos questions étaient-elles satisfaisantes?',
    6: "Avez-vous l'impression que votre dossier a été terminé dans un délai raisonnable?",
    7: 'Etiez-vous satisfait du travail de votre avocat(e) ou notaire?',
    8: "Etiez-vous satisfait de l'issue de votre dossier?",
    9: 'Consulteriez-vous le même avocat(e) ou notaire la prochaine fois que vous aurez\nbesoin de services juridiques?',
    10: 'Veuillez noter tout autre commentaire. Nous vous saurions gré de toute critique,\npositive ou négative, dont vous voudriez bien nous faire part.',
  },
  answers: {
    general: {
      positive: 'Oui',
      neutral: 'Peut-être',
      negative: 'Non',
    },
    clarity: {
      positive: 'Très clairement',
      neutral: 'Assez vaguement',
      negative: 'Pas clairement du tout',
    },
    information: {
      positive: 'Oui',
      neutral: 'Plus ou moins',
      negative: 'Non',
    },
    satisfaction: {
      positive: 'Très satisfait',
      neutral: 'Plus ou moins satisfait',
      negative: 'Pas satisfait',
    },
  },
};
