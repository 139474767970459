import PropTypes from 'prop-types';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

export const actionPropTypes = PropTypes.shape({
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  activityIcon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
});

export const actionLinkPropTypes = PropTypes.shape({
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  linkTo: PropTypes.string,
  linkHref: PropTypes.string,
  linkProps: PropTypes.object,
  disabled: PropTypes.bool,
});

export const fabActionHeight = 60; // must also be set in fab/styles/properties.css

export const getActionsHeight = (expanded = false, numActions = 0) => {
  return expanded ? numActions * fabActionHeight : fabActionHeight;
};

export const getActionsBottomPosition = (expanded = false, numActions = 0) => {
  return expanded ? 0 : Math.max(numActions - 1, 0) * fabActionHeight;
};

export const getAccessoryIcon = (expanded = false) => {
  return expanded ? faTimes : faPlus;
};

export const isActionLink = (action) => !!action.linkTo || !!action.linkHref;
