import React from 'react';
import { ActivityIndicatorCard } from '../components/activity-indicator';

const Animations = () => {
  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Animations</h1>
        <ActivityIndicatorCard />
      </div>
    </div>
  );
};

export default Animations;
