import React, { useContext, useEffect } from 'react';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'js/components/modal';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onDatePickerChangeEffect,
  onSelectChangeEffect,
  onClickRunEffect,
  onDismountEffect,
} from './effects';
import {
  runReportValidationRules,
  cboOptions,
  getRequiredFields,
} from './functions';
import {
  InputLabel,
  InputHelp,
  StyledSelect,
  DatePicker,
} from 'js/components/design-system';

const RunFeedbackReportModal = (props) => {
  const { t } = useTranslation();
  const { validate, touch, isTouched, untouch } = useContext(ValidationContext);

  const {
    mounted,
    report,
    reportBody,
    onChange,
    onDismiss,
    active,
    onRun,
    legalProblemCodes = [],
  } = props;

  const {
    title = '',
    requiresLawyerId = false,
    requiresLegalProbCode = false,
    requiresCBO = false,
  } = report;

  const {
    startDate,
    endDate,
    lawyerId,
    legalProblemCode,
    yearEndFeedbackReportType,
  } = reportBody;

  const requiredFields = getRequiredFields(report);

  const { invalidFields = [], isValid } = validate(reportBody, requiredFields);
  const touchAll = () => touch(requiredFields);
  const untouchAll = () => untouch(requiredFields);

  useEffect(
    onDismountEffect({
      untouchAll,
    }),
    [mounted]
  );

  const onClickRun = onClickRunEffect({
    onRun,
    isValid,
    touchAll,
  });

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const datePickerEventHandlers = (name) => ({
    onChange: onDatePickerChangeEffect({ name, onChange, touch }),
    onBlur: onInputBlurEffect({ touch }),
  });

  const cboItems = cboOptions(t);

  const lawyerIdError =
    lawyerId.length === 0
      ? t('components.FeedbackReportPage.modal.lawyerIdValidation')
      : t('components.FeedbackReportPage.modal.lawyerIdLengthValidation');

  return (
    <Modal
      className={classnames('run-feedback-report-modal')}
      cancelButtonText={t('common.cancel')}
      onClickCancel={onDismiss}
      titleText={title}
      mounted={mounted}
      initialFocus={'#run'}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{title}</h2>
      <div className="form-container form-row">
        {requiresLawyerId && (
          <InputHelp
            validationContent={lawyerIdError}
            invalid={
              isTouched('lawyerId') && invalidFields.includes('lawyerId')
            }
          >
            <InputLabel
              content={t('components.FeedbackReportPage.modal.lawyerId')}
            >
              <input
                type="text"
                name="lawyerId"
                id="lawyerId"
                value={lawyerId}
                {...inputEventHandlers}
              />
            </InputLabel>
          </InputHelp>
        )}

        {requiresLegalProbCode && (
          <InputHelp
            validationContent={t(
              'components.FeedbackReportPage.modal.legalProblemCodeValidation'
            )}
            invalid={
              isTouched('legalProblemCode') &&
              invalidFields.includes('legalProblemCode')
            }
          >
            <InputLabel
              content={t(
                'components.FeedbackReportPage.modal.legalProblemCode'
              )}
            >
              <StyledSelect
                name="legalProblemCode"
                value={
                  (legalProblemCode && legalProblemCode.displayValue) || ''
                }
                onChange={onSelectChangeEffect({ onChange, legalProblemCodes })}
                onBlur={onInputBlurEffect({ touch })}
              >
                <option value={''} disabled>
                  {t('common.defaultSelectPlaceholder')}
                </option>
                {legalProblemCodes.map((code, key) => (
                  <option key={key} value={code.displayValue}>
                    {code.displayValue}
                  </option>
                ))}
              </StyledSelect>
            </InputLabel>
          </InputHelp>
        )}

        {requiresCBO && (
          <InputHelp
            validationContent={t(
              'components.FeedbackReportPage.modal.yearEndFeedbackReportTypeValidation'
            )}
            invalid={
              isTouched('yearEndFeedbackReportType') &&
              invalidFields.includes('yearEndFeedbackReportType')
            }
          >
            <InputLabel
              content={t(
                'components.FeedbackReportPage.modal.yearEndFeedbackReportType'
              )}
            >
              <StyledSelect
                name="yearEndFeedbackReportType"
                value={yearEndFeedbackReportType}
                {...inputEventHandlers}
              >
                <option key={''} value={''} disabled>
                  {t('common.defaultSelectPlaceholder')}
                </option>
                {cboItems.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </StyledSelect>
            </InputLabel>
          </InputHelp>
        )}

        <InputHelp
          validationContent={t(
            'components.FeedbackReportPage.modal.startDateValidation'
          )}
          invalid={
            isTouched('startDate') && invalidFields.includes('startDate')
          }
        >
          <InputLabel
            content={t('components.FeedbackReportPage.modal.startDate')}
          >
            <DatePicker
              name="startDate"
              selected={startDate}
              {...datePickerEventHandlers('startDate')}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.FeedbackReportPage.modal.endDateValidation'
          )}
          invalid={isTouched('endDate') && invalidFields.includes('endDate')}
        >
          <InputLabel
            content={t('components.FeedbackReportPage.modal.endDate')}
          >
            <DatePicker
              name="endDate"
              selected={endDate}
              {...datePickerEventHandlers('endDate')}
            />
          </InputLabel>
        </InputHelp>
      </div>

      <div className="button-container">
        <button
          className="button button-highlight"
          onClick={onClickRun}
          disabled={active}
          id="run"
        >
          {active
            ? t('components.FeedbackReportPage.modal.running')
            : t('components.FeedbackReportPage.modal.runButton')}
        </button>
      </div>
    </Modal>
  );
};

RunFeedbackReportModal.propTypes = {
  mounted: PropTypes.bool,
  report: PropTypes.object,
  reportBody: PropTypes.object,
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  active: PropTypes.bool,
  legalProblemCodes: PropTypes.array,
  onRun: PropTypes.func,
};

const ValidatedRunFeedbackReportModal = (props) => (
  <ValidationProvider rules={runReportValidationRules}>
    <RunFeedbackReportModal {...props} />
  </ValidationProvider>
);

export default ValidatedRunFeedbackReportModal;
