import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryForSearchString = (search = '') => {
  const {
    Keyword = '',
    Order = 'Payee',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};

export const getChequeRunTableColumns = (t) => {
  return ['Payee', 'Amount', 'Date', 'Client'].map((key) => ({
    key,
    headerContent: t(`components.ChequeRunView.columns.${key}`),
    sortable: true,
  }));
};
