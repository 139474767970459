import { clickFirstLinkInElement, isLastListItem } from './functions';

export const onClickHeaderMenuLinkEffect = (options = {}) => {
  const { setMenuVisibility } = options;
  return () => setMenuVisibility(false);
};

export const onKeyDownHeaderMenuItemEffect = (options = {}) => {
  const { setMenuVisibility } = options;
  return (e) => {
    const { key, target } = e;
    switch (key) {
      case ' ':
        e.preventDefault();
        clickFirstLinkInElement(target);
        break;
      case 'Tab':
        if (isLastListItem(target)) {
          setMenuVisibility(false);
        }
        break;
      default:
        break;
    }
  };
};
