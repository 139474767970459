import React, { Fragment, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  ExpandingCard,
  ExpandingCardContent,
  InputHelp,
  FontAwesomeIcon,
} from 'js/components/design-system';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  onClickCancelEffect,
  onClickSaveEffect,
} from './effects';
import {
  feeScheduleValidationRules,
  feeScheduleRequiredFields,
  returnSaveButtonText,
} from './functions';
import { formatDateFromISOString } from 'js/utilities/dates';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import ArchiveModal from './archive-modal';
import DeleteModal from './delete-modal';

const FeeScheduleCard = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isArchiveModalMounted, setArchiveModalMounted] = useState(false);
  const [isDeleteModalMounted, setDeleteModalMounted] = useState(false);

  const {
    feeSchedule = {},
    expanded,
    expandEnabled,
    onClickExpand,
    onChange,
    onClickArchive,
    onClickCancel,
    onClickSave,
    onClickDelete,
    isNew = false,
    archived = false,
    isSaving = false,
    isDeleting = false,
    isArchiving = false,
  } = props;

  const { name = '', id = '', releases = [], activeRelease = {} } = feeSchedule;
  const incompleteSchedule = activeRelease === null && releases.length === 0;

  const { presentStyledBanner } = useContext(StyledBannerContext);

  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const { isValid, invalidFields = [] } = validate(
    feeSchedule,
    feeScheduleRequiredFields
  );
  const touchAll = () => touch(feeScheduleRequiredFields);
  const untouchAll = () => untouch(feeScheduleRequiredFields);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);

  const nextReleases = releases.filter(
    (release) => release.isActive !== archived
  );

  const save = onClickSaveEffect({
    t,
    isValid,
    touchAll,
    resetInteractionCount,
    presentStyledBanner,
    onClickSave,
  });

  const cancel = onClickCancelEffect({
    untouchAll,
    resetInteractionCount,
    onClickCancel,
  });

  const disableButton = isArchiving || isSaving;

  const activeForm = (
    <div className="form-row">
      {!isNew && (
        <InputHelp
          validationContent={t(
            'components.FeeSchedulePage.FeeScheduleCard.idValidation'
          )}
          invalid={isTouched('id') && invalidFields.includes('id')}
        >
          <input
            type="text"
            name="id"
            placeholder={t('components.FeeSchedulePage.FeeScheduleCard.id')}
            value={id}
            readOnly
          />
        </InputHelp>
      )}

      <InputHelp
        validationContent={t(
          'components.FeeSchedulePage.FeeScheduleCard.nameValidation'
        )}
        invalid={isTouched('name') && invalidFields.includes('name')}
      >
        <input
          type="text"
          name="name"
          placeholder={t('components.FeeSchedulePage.FeeScheduleCard.name')}
          value={name}
          disabled={isSaving}
          onChange={onInputChangeEffect({ onChange })}
          onBlur={onInputBlurEffect({ touch })}
          onClick={incrementInteractionCount}
        />
      </InputHelp>
    </div>
  );

  return (
    <Fragment>
      {(nextReleases.length > 0 || isNew || incompleteSchedule) && (
        <ExpandingCard className="fee-schedule-card" expanded={expanded}>
          <div className="fee-schedule-card-heading">
            {!expanded && (
              <Fragment>
                <div className="name-container">
                  <h2>
                    {id} {name}
                  </h2>
                  {incompleteSchedule && (
                    <span className="tag tag-error">
                      {t(
                        'components.FeeSchedulePage.FeeScheduleCard.incomplete'
                      )}
                    </span>
                  )}
                </div>
                <div className="fee-schedule-card-actions">
                  {!incompleteSchedule && (
                    <button
                      className="button-link-appearance"
                      onClick={onClickExpand}
                      disabled={!expandEnabled}
                    >
                      {t('components.FeeSchedulePage.FeeScheduleCard.expand')}
                    </button>
                  )}
                  {incompleteSchedule && (
                    <Fragment>
                      <DeleteModal
                        mounted={isDeleteModalMounted}
                        onConfirm={() => onClickDelete(feeSchedule)}
                        onDismiss={() => setDeleteModalMounted(false)}
                        isActive={isDeleting}
                      />
                      <button
                        className="button-link-appearance"
                        onClick={() => setDeleteModalMounted(true)}
                        disabled={!expandEnabled}
                      >
                        {t(
                          'components.FeeSchedulePage.FeeScheduleCard.deleteSchedule'
                        )}
                      </button>
                      <button
                        className="button-link-appearance"
                        onClick={() =>
                          history.push(
                            routePathReplacingParams(
                              routePaths.releaseLPCodes,
                              {
                                scheduleId: id,
                                effectiveDate: 'new',
                              }
                            )
                          )
                        }
                        disabled={!expandEnabled}
                      >
                        {t(
                          'components.FeeSchedulePage.FeeScheduleCard.completeSchedule'
                        )}
                      </button>
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
            {expanded && (
              <Fragment>
                {!archived && activeForm}
                {archived && (
                  <div className="name-container">
                    <h2>
                      {id} {name}
                    </h2>
                  </div>
                )}

                {!archived && (
                  <div className="release-actions">
                    <ArchiveModal
                      mounted={isArchiveModalMounted}
                      onConfirm={() => onClickArchive(feeSchedule)}
                      onDismiss={() => setArchiveModalMounted(false)}
                      isActive={isArchiving}
                    />
                    <button
                      className="button-link-appearance"
                      disabled={disableButton || isNew}
                      onClick={() => setArchiveModalMounted(true)}
                    >
                      {t('components.FeeSchedulePage.FeeScheduleCard.archive')}
                    </button>

                    {disableButton || isNew ? (
                      <span className="button-link-appearance">
                        {t(
                          'components.FeeSchedulePage.FeeScheduleCard.newRelease'
                        )}
                      </span>
                    ) : (
                      <Link
                        className="button-link-appearance"
                        disabled={disableButton || isNew}
                        to={routePathReplacingParams(
                          routePaths.releaseLPCodesCopy,
                          {
                            scheduleId: id,
                            effectiveDate: activeRelease.effectiveDate || '',
                          }
                        )}
                      >
                        {t(
                          'components.FeeSchedulePage.FeeScheduleCard.newRelease'
                        )}
                      </Link>
                    )}
                  </div>
                )}

                {!archived && (
                  <div className="fee-schedule-card-actions">
                    <button
                      className="button-link-appearance"
                      disabled={disableButton}
                      onClick={cancel}
                    >
                      {t('common.cancel')}
                    </button>
                    <button
                      className="button-link-appearance"
                      disabled={disableButton}
                      onClick={save}
                    >
                      {returnSaveButtonText(t, isNew, isSaving)}
                    </button>
                  </div>
                )}

                {archived && (
                  <div className="fee-schedule-card-actions">
                    <button
                      className="button-link-appearance"
                      disabled={isSaving}
                      onClick={cancel}
                    >
                      {t('common.close')}
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div className="fee-schedule-card-body">
            <ExpandingCardContent className="fee-schedule-card-content">
              <div>
                {nextReleases &&
                  nextReleases.map((release, key) => {
                    const { effectiveDate, endDate } = release;
                    return (
                      <Link
                        to={routePathReplacingParams(
                          routePaths.releaseLPCodes,
                          { scheduleId: id, effectiveDate }
                        )}
                        key={key}
                        className="release-containers"
                      >
                        <div>
                          <p className="bold">
                            {formatDateFromISOString(effectiveDate)}
                          </p>
                          <p>{endDate ? t('common.to') : t('common.on')}</p>
                          {endDate && (
                            <p className="bold">
                              {formatDateFromISOString(endDate)}
                            </p>
                          )}
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Link>
                    );
                  })}
                {nextReleases.length === 0 && (
                  <p className="empty-state">
                    {t(
                      'components.FeeSchedulePage.FeeScheduleCard.releaseEmptyState'
                    )}
                  </p>
                )}
              </div>
            </ExpandingCardContent>
          </div>

          <NavigationSaveModal
            proceedAfter={async () => await save()}
            shouldBlockNavigation={() => interactionCount > 0}
          />
        </ExpandingCard>
      )}
    </Fragment>
  );
};

FeeScheduleCard.defaultProps = {
  expandEnabled: true,
  saving: false,
};

FeeScheduleCard.propTypes = {
  ...ExpandingCard.propTypes,
  feeSchedule: PropTypes.object.isRequired,
  expandEnabled: PropTypes.bool,
  onClickExpand: PropTypes.func,
  saving: PropTypes.bool,
  onClickArchive: PropTypes.func,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func,
  onClickDelete: PropTypes.func,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
  isSaving: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isArchiving: PropTypes.bool,
  archived: PropTypes.bool,
};

const ValidatedFeeScheduleCard = (props) => {
  return (
    <ValidationProvider rules={feeScheduleValidationRules}>
      <FeeScheduleCard {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingFeeScheduleCard = (props) => (
  <InteractionTrackingProvider>
    <ValidatedFeeScheduleCard {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingFeeScheduleCard;
