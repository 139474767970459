import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { initializeExpandedEffect, onChangeExpandedEffect } from './effects';

const ExpandingCard = (props) => {
  const { className, expanded, children } = props;
  const previousExpandedRef = useRef(expanded);
  const cardElementRef = useRef(null);

  useEffect(
    initializeExpandedEffect({
      expanded,
      previousExpandedRef,
      cardElementRef,
    }),
    []
  );

  useEffect(
    onChangeExpandedEffect({
      expanded,
      previousExpandedRef,
      cardElementRef,
    }),
    [expanded]
  );

  return (
    <div
      className={classnames('expanding-card', className)}
      ref={cardElementRef}
    >
      {children}
    </div>
  );
};

ExpandingCard.defaultProps = {
  expanded: false,
};

ExpandingCard.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

export default ExpandingCard;
