import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import Modal from 'js/components/modal';
import ChequeRunModalContentLocked from './content-locked';
import ChequeRunModalContentUnlocked from './content-unlocked';
import {
  mountModalOnLockEffect,
  onModalMountChangeEffect,
  onClickCancelEffect,
  onClickLockEffect,
  onClickUnlockEffect,
  createRunEffect,
  resolveOnClickConfirmRunEffect,
  resolveOnClickDeleteRunEffect,
} from './effects';

const ChequeRunModal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    title,
    reprintLastRun,
    reprintChequeCount,
    mounted,
    setMounted,
    lockSystem,
    lockSystemActive,
  } = props;
  const { lock = false, toggleLockSystem } = lockSystem;
  const lockRef = useRef(lock);
  const { api = {} } = useContext(FetchContext);

  const [isCreateRunActive, setCreateRunActive] = useState(false);
  const [isConfirmRunActive, setConfirmRunActive] = useState(false);
  const [isDeleteRunActive, setDeleteRunActive] = useState(false);
  const [chequeRunResults, setChequeRunResults] = useState({});
  const [chequeRunError, setChequeRunError] = useState({});

  const onClickCancel = onClickCancelEffect({ setMounted });

  const createRun = createRunEffect({
    api,
    setCreateRunActive,
    setChequeRunResults,
    setChequeRunError,
  });

  useEffect(
    mountModalOnLockEffect({
      lockRef,
      lock,
      mounted,
      setMounted,
    }),
    [lock]
  );

  useEffect(
    onModalMountChangeEffect({
      mounted,
      setChequeRunResults,
      setChequeRunError,
    }),
    [mounted]
  );

  const onClickConfirmEffect = resolveOnClickConfirmRunEffect(
    reprintLastRun,
    reprintChequeCount
  );
  const onClickDeleteEffect = resolveOnClickDeleteRunEffect(
    reprintLastRun,
    reprintChequeCount
  );

  return (
    <Modal
      className={classnames('cheque-run-modal', className)}
      titleText={title}
      mounted={mounted}
      cancelButtonText={t('components.ChequeRunModal.dismiss')}
      onClickCancel={onClickCancel}
      allowCancel={!lock}
      underlayClickExits={false}
      verticallyCenter
      focusDialog
    >
      {lock && (
        <ChequeRunModalContentLocked
          title={title}
          reprintLastRun={reprintLastRun}
          reprintChequeCount={reprintChequeCount}
          lockSystemActive={lockSystemActive}
          onClickUnlock={onClickUnlockEffect({ toggleLockSystem, setMounted })}
          isCreateRunActive={isCreateRunActive}
          onClickCreateRun={createRun}
          isConfirmRunActive={isConfirmRunActive}
          onClickConfirmRun={onClickConfirmEffect({
            api,
            setConfirmRunActive,
            setChequeRunResults,
            setChequeRunError,
            toggleLockSystem,
            setMounted,
          })}
          isDeleteRunActive={isDeleteRunActive}
          onClickDeleteRun={onClickDeleteEffect({
            api,
            setDeleteRunActive,
            setChequeRunResults,
            setChequeRunError,
            toggleLockSystem,
            setMounted,
          })}
          chequeRunResults={chequeRunResults}
          chequeRunError={chequeRunError}
        />
      )}
      {!lock && (
        <ChequeRunModalContentUnlocked
          lockSystemActive={lockSystemActive}
          onClickCancel={onClickCancel}
          onClickLock={onClickLockEffect({ toggleLockSystem })}
        />
      )}
    </Modal>
  );
};

ChequeRunModal.defaultProps = {
  reprintLastRun: false,
};

ChequeRunModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  reprintLastRun: PropTypes.bool,
  reprintChequeCount: PropTypes.number,
  mounted: PropTypes.bool.isRequired,
  setMounted: PropTypes.func.isRequired,
  lockSystem: PropTypes.shape({
    lock: PropTypes.bool,
    toggleLockSystem: PropTypes.func,
  }).isRequired,
  lockSystemActive: PropTypes.bool.isRequired,
};

export default ChequeRunModal;
