import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DropdownMenuOption from './dropdown-menu-option';
import {
  onMountEffect,
  toggleOpenEffect,
  onSelectOptionEffect,
  onSelectionKeyDownEffect,
  onOptionKeyDownEffect,
} from './effects';

const DropdownMenu = (props) => {
  const { className, options = [], selectedOptionIndex = 0, disabled } = props;
  const { content: selectedContent } = options[selectedOptionIndex] || {};
  const optionsListRef = useRef(null);
  const [open, setOpen] = useState(false);
  const containerClassName = classnames(
    'dropdown-menu',
    { 'dropdown-menu-open': open },
    className
  );

  useEffect(onMountEffect({ optionsListRef, setOpen }), []);

  useEffect(() => {
    if (open) {
      optionsListRef.current.querySelector('li').focus();
    }
  }, [open]);

  return (
    <div className={containerClassName}>
      <ul className={classnames('dropdown-menu-selection', { disabled })}>
        <DropdownMenuOption
          tabIndex={open ? -1 : 0}
          onClick={toggleOpenEffect({ open, setOpen, disabled })}
          onKeyDown={onSelectionKeyDownEffect({ open })}
          disabled={disabled}
        >
          {selectedContent}
        </DropdownMenuOption>
      </ul>
      <ul className="dropdown-menu-options" role="listbox" ref={optionsListRef}>
        {options.map(({ content, onSelect }, optionIndex) => (
          <DropdownMenuOption
            key={optionIndex}
            role="option"
            tabIndex={open ? 0 : -1}
            selected={optionIndex === selectedOptionIndex}
            onClick={onSelectOptionEffect({ optionIndex, onSelect, setOpen })}
            onKeyDown={onOptionKeyDownEffect({
              onSelect: onSelectOptionEffect({
                optionIndex,
                onSelect,
                setOpen,
              }),
              setOpen,
            })}
            disabled={disabled}
          >
            {content}
          </DropdownMenuOption>
        ))}
      </ul>
    </div>
  );
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      onSelect: PropTypes.func.isRequired,
    })
  ),
  selectedOptionIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default DropdownMenu;
