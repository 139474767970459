import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../icons';
import { onKeyDownEffect } from './effects';

const Checkbox = (props) => {
  const {
    className,
    name,
    label,
    checked = false,
    disabled,
    ...inputProps
  } = props;

  return (
    <div
      className={classnames('checkbox-container', className, {
        disabled: disabled,
      })}
    >
      <label
        role="checkbox"
        aria-checked={checked}
        className="styled-checkbox"
        checked={checked}
        tabIndex={0}
        onKeyDown={onKeyDownEffect()}
      >
        <input
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          aria-label={label}
          {...inputProps}
        />
        <FontAwesomeIcon className="checked" icon={faCheckSquare} focusable />
        <FontAwesomeIcon className="unchecked" icon={faSquare} focusable />
        <span>{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Checkbox;
