import { resolvePageTitle } from './functions';

export const setPageTitleEffect = (options = {}) => {
  const { t, title, environment } = options;
  return () => {
    const titleElement = document.querySelector('title');
    const reportingEnv = environment.includes('reporting');
    if (reportingEnv) {
      titleElement.innerHTML = t('components.Page.title.reporting');
    } else if (titleElement) {
      titleElement.innerHTML = resolvePageTitle(t, title);
    }
  };
};
