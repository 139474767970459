export const executeAbortControllers = (abortControllers = []) => {
  abortControllers.forEach((controller) => controller.abort());
};

export const executeAbortControllerRefs = (abortControllerRefs = []) => {
  const abortControllers = abortControllerRefs
    .filter((ref) => ref.current)
    .map((ref) => ref.current);
  executeAbortControllers(abortControllers);
};

export const rotateAbortControllerRef = (abortControllerRef) => {
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }
  abortControllerRef.current = new AbortController();
};

export const isAbortError = (error) => {
  return error instanceof DOMException && /aborted/i.test(error.message);
};
