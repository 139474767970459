import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';

const DeleteNoteModal = (props) => {
  const { t } = useTranslation();
  const { mounted, isDeleting, onClose, onClickDelete } = props;

  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={onClose}
      titleText={t(
        'components.ClientOutstandingBalanceNotes.DeleteNoteModal.title'
      )}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      allowCancel={false}
    >
      <h2>
        {t('components.ClientOutstandingBalanceNotes.DeleteNoteModal.title')}
      </h2>
      <p>
        {t('components.ClientOutstandingBalanceNotes.DeleteNoteModal.text')}
      </p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isDeleting}
          onClick={onClose}
        >
          {t('components.ClientOutstandingBalanceNotes.DeleteNoteModal.cancel')}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          disabled={isDeleting}
          onClick={onClickDelete}
        >
          {isDeleting
            ? t(
                'components.ClientOutstandingBalanceNotes.DeleteNoteModal.deleting'
              )
            : t(
                'components.ClientOutstandingBalanceNotes.DeleteNoteModal.delete'
              )}
        </button>
      </div>
    </Modal>
  );
};

DeleteNoteModal.defaultProps = {
  mounted: false,
  isDeleting: false,
};

DeleteNoteModal.propTypes = {
  mounted: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  onClose: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default DeleteNoteModal;
