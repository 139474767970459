import { breadcrumbTypes } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';

export const reportsBreadcrumbsHistory = (t) => {
  return [
    breadcrumbTypes.home,
    {
      content: t('components.ReportsPage.title'),
      path: routePaths.reports,
    },
  ];
};
