import { rotateAbortControllerRef, isAbortError } from 'js/components/fetch';

export const getCSQDataEffect = (options = {}) => {
  const {
    api,
    cif,
    setCSQData,
    setActive,
    setError,
    getCsqAbortControllerRef,
  } = options;

  return async () => {
    const url = `/Case/PrintCSQ/${cif}`;
    setActive(true);

    rotateAbortControllerRef(getCsqAbortControllerRef);
    const { signal } = getCsqAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      if (json) {
        setCSQData(json);
        setActive(false);
      }
    } catch (e) {
      if (!isAbortError(e)) {
        setError(e);
        setActive(false);
      }
    }
  };
};

export const getMultipleCSQDataEffect = (options = {}) => {
  const {
    api,
    date,
    setCSQDataArray,
    setActive,
    setError,
    getCsqAbortControllerRef,
  } = options;

  return async () => {
    const url = `/dayendreport/csq?reportDate=${date}`;
    setActive(true);

    rotateAbortControllerRef(getCsqAbortControllerRef);
    const { signal } = getCsqAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { bypass: true },
        }
      );

      setCSQDataArray(json);
      setActive(false);
    } catch (e) {
      if (!isAbortError(e)) {
        setError(e);
        setActive(false);
      }
    }
  };
};

export const printViewEffect = (options = {}) => {
  const { CSQData, active } = options;
  return () => {
    if (CSQData?.caseInfo && !active) {
      window.print();
    }
  };
};

export const multiplePrintViewEffect = (options = {}) => {
  const { CSQDataArray, active } = options;
  return () => {
    if (CSQDataArray.length > 0 && !active) {
      window.print();
    }
  };
};
