export const onBeforeUnload = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

export const executeNavigationAction = (history, location, action) => {
  if (location && action) {
    switch (action) {
      case 'PUSH':
        history.push(location);
        break;
      case 'POP':
        history.goBack();
        break;
      default:
        break;
    }
  }
};
