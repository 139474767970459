import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { searchStringFromParams } from 'js/utilities/params';
import {
  caseNumberPattern,
  clientIdPattern,
  clientCodePattern,
  lawyerIdPattern,
} from 'js/utilities/validation';

export const RESPONSE_404_TYPES = {
  CASE: 'case',
  CLIENT: 'client',
  LAWYER: 'lawyer',
};

export const error404CriteriaForLawyerParams = (params = []) => {
  let lawyerId = '';

  if (/^staff/i.test(params[0]) && params[1]) {
    lawyerId = decodeURIComponent(params[1]);
  } else {
    lawyerId = decodeURIComponent(params[0]);
  }

  return { lawyerId };
};

export const error404CriteriaForTypeAndParams = (type = '', params = []) => {
  switch (type) {
    case RESPONSE_404_TYPES.CASE:
      return { caseNumber: decodeURIComponent(params[0]) };
    case RESPONSE_404_TYPES.CLIENT:
      return {
        clientId: decodeURIComponent(params[0]),
        clientCode: decodeURIComponent(params[1]),
      };
    case RESPONSE_404_TYPES.LAWYER:
      return error404CriteriaForLawyerParams(params);
    default:
      return {};
  }
};

export const error404RedirectForResponsePathname = (pathname = '') => {
  const [type = '', ...params] = pathname.split('/');
  const lowercaseType = type.toLowerCase();
  if (Object.values(RESPONSE_404_TYPES).includes(lowercaseType)) {
    const path = routePathReplacingParams(routePaths.search404, {
      type: lowercaseType,
    });
    const criteria = error404CriteriaForTypeAndParams(lowercaseType, params);
    const search = searchStringFromParams(criteria);
    return path + search;
  } else {
    return routePaths.error404;
  }
};

export const error404RedirectForResponseUrl = (responseUrl = '') => {
  try {
    const url = new URL(responseUrl);
    const pathname = url.pathname.replace(/^\/\w+\//i, '');
    return error404RedirectForResponsePathname(pathname);
  } catch (error) {
    return routePaths.error404;
  }
};

export const validateResponse404TypeAndParams = (type = '', params = {}) => {
  switch (type) {
    case RESPONSE_404_TYPES.CASE:
      return caseNumberPattern.test(params.caseNumber);
    case RESPONSE_404_TYPES.CLIENT:
      return (
        clientIdPattern.test(params.clientId) &&
        clientCodePattern.test(params.clientCode)
      );
    case RESPONSE_404_TYPES.LAWYER:
      return lawyerIdPattern.test(params.lawyerId);
    default:
      return false;
  }
};
