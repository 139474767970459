import { cleanClientSummary } from 'js/components/client-summary';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanCase } from 'js/utilities/cases';
import { cleanClientOptions } from 'js/utilities/clients';
import { queryForParams } from 'js/utilities/params';
import { focusInputRef } from './functions';

export const focusInputEffect = (options = {}) => {
  const { inputRef } = options;
  return () => focusInputRef(inputRef);
};

export const onChangeCaseNumberInputEffect = (options = {}) => {
  const { setInputCaseNumber } = options;
  return (name, value) => setInputCaseNumber(value);
};

export const onChangeInputEffect = (options = {}) => {
  const { setValue } = options;
  return (e) => {
    const { value } = e.target;
    setValue(value);
  };
};

export const onBlurInputEffect = (options = {}) => {
  const { touch } = options;
  return (e) => {
    const { name, value = '' } = e.target;
    if (value.length > 0) {
      touch(name);
    }
  };
};

export const onKeyPressEffect = (options = {}) => {
  const { onEnter } = options;
  return (e) => {
    if (e.key === 'Enter' && typeof onEnter === 'function') {
      onEnter();
    }
  };
};

export const setConfirmedCaseNumberEffect = (options = {}) => {
  const {
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  } = options;
  return () => {
    if (isInputCaseNumberValid) {
      setConfirmedCaseNumber(inputCaseNumber);
      untouchCaseNumberInput();
      history.push(queryForParams({ casenumber: inputCaseNumber }));
    } else {
      touchCaseNumberInput();
    }
  };
};

export const resetCaseEffect = (options = {}) => {
  const {
    setCaseInfo,
    setCaseCorrectionFields,
    setClient,
    setClientOptions,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    untouchCaseNumberInput,
    untouchCaseCorrectionInputs,
    caseNumberInputRef,
  } = options;
  return () => {
    setCaseInfo({});
    setCaseCorrectionFields({});
    setClient({});
    setClientOptions({});
    setInputCaseNumber('');
    setConfirmedCaseNumber('');
    untouchCaseNumberInput();
    untouchCaseCorrectionInputs();
    focusInputRef(caseNumberInputRef);
  };
};

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setCaseInfoActive,
    setClientActive,
    setCaseCorrectionActive,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setCaseInfoActive(false);
      setClientActive(false);
      setCaseCorrectionActive(false);
    };
  };
};

export const onUpdateCaseNumberEffect = (options = {}) => {
  const {
    caseNumberParam,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    resetCase,
  } = options;

  return () => {
    if (caseNumberParam) {
      setInputCaseNumber(caseNumberParam);
      setConfirmedCaseNumber(caseNumberParam);
    } else {
      resetCase();
    }
  };
};

export const onChangeCaseCorrectionFieldEffect = (options = {}) => {
  const { setCaseCorrectionFields, untouch } = options;
  return (name, value) => {
    setCaseCorrectionFields((fields) => {
      const nextFields = { ...fields, [name]: value };

      // Reset the client status selection when
      // the primary client status is changed:
      if (name === 'primaryClientStatus') {
        nextFields.clientStatus = '';
      }

      return nextFields;
    });

    // Untouch the client status selection when
    // the primary client status is changed:
    if (name === 'primaryClientStatus') {
      untouch('clientStatus');
    }
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setCaseInfoActive,
    setCaseInfo,
    setCaseCorrectionFields,
    getCaseInfoAbortControllerRef,
  } = options;
  return async () => {
    if (!caseNumber) {
      setCaseInfo({});
      setCaseInfoActive(false);
      return;
    }

    setCaseInfoActive(true);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = `/Case/${encodeURIComponent(caseNumber)}?isClosing=true`;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseCorrection.caseRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      const caseInfo = cleanCase(json);
      const { caseInformation = {} } = caseInfo;
      const { primaryClientStatus, clientStatus } = caseInformation;
      const caseCorrectionFields = {
        primaryClientStatus,
        clientStatus,
      };

      setCaseInfo(caseInfo);
      setCaseCorrectionFields(caseCorrectionFields);
      setCaseInfoActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseInfoActive(false);
      }
    }
  };
};

export const getClientEffect = (options = {}) => {
  const {
    t,
    api,
    clientId,
    clientCode,
    setClientActive,
    setClient,
    setClientOptions,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (!clientId || !clientCode) {
      return;
    }

    setClientActive(true);

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal } = getClientAbortControllerRef.current;

    try {
      const url = `/Client/${clientId}/${clientCode}`;
      const { json: clientJson = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CaseCorrection.clientRequestError'),
              preventRedirect: true,
            },
          },
        }
      );

      setClient(cleanClientSummary(clientJson));
      setClientOptions(cleanClientOptions(clientJson));
      setClientActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setClientActive(false);
      }
    }
  };
};

export const updateCaseCorrectionFieldsEffect = (options = {}) => {
  const {
    t,
    api,
    areCaseCorrectionInputsValid,
    touchCaseCorrectionInputs,
    caseNumber,
    caseCorrectionFields,
    setCaseCorrectionActive,
    patchCaseCorrectionAbortControllerRef,
    resetCase,
  } = options;
  return async () => {
    if (!areCaseCorrectionInputsValid) {
      touchCaseCorrectionInputs();
      return;
    }

    setCaseCorrectionActive(true);

    rotateAbortControllerRef(patchCaseCorrectionAbortControllerRef);
    const { signal } = patchCaseCorrectionAbortControllerRef.current;

    try {
      const [year, officeId, caseId] = caseNumber.split('/');
      const url = `/Case/${year}/${officeId}/${caseId}/edit`;
      const body = { ...caseCorrectionFields };

      await api.postJson(
        url,
        { signal, body },
        {
          success: {
            context: {
              message: t(
                'components.CaseCorrection.caseCorrectionSuccessMessage',
                { caseNumber }
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.CaseCorrection.caseCorrectionRequestError',
                { caseNumber }
              ),
              preventRedirect: true,
            },
          },
        }
      );

      setCaseCorrectionActive(false);
      resetCase();
    } catch (error) {
      if (!isAbortError(error)) {
        setCaseCorrectionActive(false);
      }
    }
  };
};
