import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { defaultSortPredicate } from 'js/utilities/sort';

export const queryForSearchString = (search = '') => {
  const {
    orderBy = 'timestamp',
    order = 'desc',
    ...otherParams
  } = paramsFromSearchString(search);

  const params = {
    ...otherParams,
    orderBy,
    order,
  };

  return queryForParams(params);
};

export const sortLawyerFeedback = (feedback = [], query = {}) => {
  const { params = {} } = query;
  const { orderBy, order } = params;

  if (orderBy && order) {
    return [...feedback].sort((item1, item2) =>
      defaultSortPredicate(orderBy, order, item1, item2)
    );
  } else {
    return feedback;
  }
};

export const openDocumentUrl = (url) =>
  window.open(url, '_blank', 'noopener,noreferrer');

export const returnFilteredFeedback = (lawyerFeedback, searchString) => {
  return lawyerFeedback.filter((item) => {
    return Object.values(item).reduce((next, value = '') => {
      if (value) {
        const match = value
          .toString()
          .toLowerCase()
          .includes(searchString.toLowerCase());
        return next || match;
      } else {
        return next;
      }
    }, false);
  });
};
