import React, { useEffect, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  Checkbox,
  ActivityIndicatorCard,
  SortableTable,
  tableSortDirection,
} from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import FilterInput from 'js/components/controls/filter-input';
import Modal from 'js/components/modal';
import {
  resetModalEffect,
  unmountModalEffect,
  onMountEffect,
  onFilterUnionsEffect,
  onResetUnionsEffect,
  getManagedUnionsEffect,
  postManagedUnionsEffect,
  onChangeManagedUnionCheckboxEffect,
} from './effects';
import { getManagedUnionColumns } from './functions';

const ManageUnionsModal = (props) => {
  const { className, mounted, setMounted, companyCode } = props;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const getAbortControllerRef = useRef(null);
  const postAbortControllerRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [unions, setUnions] = useState([]);
  const [filteredUnions, setFilteredUnions] = useState([]);
  const [managedUnionIds, setManagedUnionIds] = useState([]);
  const isActive = isLoading || isSaving;

  const reset = resetModalEffect({
    abortControllerRefs: [getAbortControllerRef, postAbortControllerRef],
    setLoading,
    setSaving,
    setUnions,
    setFilteredUnions,
    setManagedUnionIds,
  });

  const unmount = unmountModalEffect({ setMounted, reset });

  useEffect(onMountEffect({ reset }), []);

  useEffect(
    getManagedUnionsEffect({
      t,
      api,
      mounted,
      companyCode,
      setLoading,
      setUnions,
      setFilteredUnions,
      setManagedUnionIds,
      getAbortControllerRef,
    }),
    [mounted, companyCode]
  );

  const renderCheckboxForRow = (key, { unionId, checked }) => (
    <Checkbox
      checked={checked}
      onChange={onChangeManagedUnionCheckboxEffect({
        unionId,
        managedUnionIds,
        setManagedUnionIds,
        setFilteredUnions,
      })}
    />
  );

  return (
    <Modal
      className={classnames('manage-unions-modal', className)}
      titleText={t('components.CompanyDetailsPage.ManageUnionsModal.title')}
      mounted={mounted}
      cancelButtonText={t(
        'components.CompanyDetailsPage.ManageUnionsModal.dismiss'
      )}
      onClickCancel={unmount}
      underlayClickExits={false}
      verticallyCenter
      focusDialog
    >
      <div className="manage-unions-modal-heading">
        <h1>{t('components.CompanyDetailsPage.ManageUnionsModal.title')}</h1>
        <FilterInput
          placeholder={t(
            'components.CompanyDetailsPage.ManageUnionsModal.filterInputPlaceholder'
          )}
          disabled={isActive || unions.length === 0}
          onFilter={onFilterUnionsEffect({
            unions,
            managedUnionIds,
            setFilteredUnions,
          })}
          onReset={onResetUnionsEffect({
            unions,
            managedUnionIds,
            setFilteredUnions,
          })}
        />
      </div>
      {isLoading && <ActivityIndicatorCard />}
      {!isLoading && filteredUnions.length > 0 && (
        <div className="manage-unions-modal-table-wrapper">
          <SortableTable
            columns={getManagedUnionColumns(t, renderCheckboxForRow)}
            data={filteredUnions}
            initialSortColumnKey={'unionId'}
            initialSortDirection={tableSortDirection.asc}
          />
        </div>
      )}
      {!isLoading && unions.length === 0 && (
        <EmptyState
          title={t(
            'components.CompanyDetailsPage.ManageUnionsModal.emptyStateMessage'
          )}
        />
      )}
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isSaving}
          onClick={unmount}
        >
          {t('components.CompanyDetailsPage.ManageUnionsModal.cancelButton')}
        </button>
        <button
          className="button button-highlight"
          disabled={isActive}
          onClick={postManagedUnionsEffect({
            t,
            api,
            companyCode,
            setSaving,
            unions,
            managedUnionIds,
            postAbortControllerRef,
            unmount,
          })}
        >
          {isSaving
            ? t(
                'components.CompanyDetailsPage.ManageUnionsModal.saveButtonActive'
              )
            : t('components.CompanyDetailsPage.ManageUnionsModal.saveButton')}
        </button>
      </div>
    </Modal>
  );
};

ManageUnionsModal.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool.isRequired,
  setMounted: PropTypes.func.isRequired,
  companyCode: PropTypes.string.isRequired,
};

export default ManageUnionsModal;
