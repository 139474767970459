import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { provincesListBreadcrumbsHistory } from './functions';

const ProvincesListBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="provinces-list-breadcrumbs"
      history={provincesListBreadcrumbsHistory(t, location)}
    />
  );
};

export default ProvincesListBreadcrumbs;
