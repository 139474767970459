import { booleanFromBinaryRadioGroupValue } from './functions';

export const onChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, booleanFromBinaryRadioGroupValue(value));
    }
  };
};
