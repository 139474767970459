import React, {
  Fragment,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';

import {
  SortableTable,
  tableSortDirection,
  ActivityIndicatorCard,
  FontAwesomeIcon,
} from 'js/components/design-system';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import EmptyState from 'js/components/controls/empty-state';
import CasePage from '../case-page';
import { CaseProvider, CaseContext } from '../case-context';
import { onMountEffect, getCaseLogEffect } from './effects';
import { getCaseLogColumns } from './functions';
import CaseLogPrintHeader from './case-log-print-header';
import { PrintFooter } from 'js/components/print';

const CaseLog = () => {
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { caseInfo } = useContext(CaseContext);
  const { caseInformation = {} } = caseInfo;
  const { clientCode, clientId } = caseInformation;

  const getCaseLogAbortControllerRef = useRef(null);
  const [loadingLogData, setLoadingLogData] = useState(false);
  const [logData, setLogData] = useState([]);

  const { params = {} } = useRouteMatch();
  const caseNumber = decodeURIComponent(params.caseNumber);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getCaseLogAbortControllerRef],
      setLoadingLogData,
    }),
    []
  );

  useEffect(
    getCaseLogEffect({
      t,
      api,
      caseNumber,
      setLoadingLogData,
      setLogData,
      getCaseLogAbortControllerRef,
    }),
    []
  );

  return (
    <CasePage
      className="case-log"
      title={t('components.CaseLog.title')}
      actions={
        <button
          className="button button-highlight page-action-button"
          disabled={loadingLogData}
          onClick={() => window.print()}
        >
          {t('common.print')}
        </button>
      }
    >
      <CaseLogPrintHeader
        title={t('components.CaseLog.printHeaderTitle', { caseNumber })}
        heading={caseNumber}
      />

      {loadingLogData && <ActivityIndicatorCard />}
      {clientId && clientCode && (
        <div className="case-log-actions">
          <Link
            className="button client-log-link"
            to={routePathReplacingParams(routePaths.clientLog, {
              id: clientId,
              clientCode,
            })}
          >
            {t('components.CaseLog.clientLogLink')}
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      )}
      {!loadingLogData && (
        <Fragment>
          {logData.length === 0 && (
            <EmptyState title={t('components.CaseLog.emptyStateMessage')} />
          )}
          {logData.length > 0 && (
            <SortableTable
              columns={getCaseLogColumns(t)}
              data={logData}
              initialSortColumnKey={'date'}
              initialSortDirection={tableSortDirection.desc}
              printManaged={true}
            />
          )}
        </Fragment>
      )}
      <PrintFooter />
    </CasePage>
  );
};

const CaseLogWithContext = (props) => (
  <CaseProvider>
    <CaseLog {...props} />
  </CaseProvider>
);

export default CaseLogWithContext;
