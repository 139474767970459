export const fileSizeLimitMB = 5;
export const fileSizeLimit = fileSizeLimitMB * 1024 * 1024;

export const getMaxFileSize = (files = []) => {
  const sizes = files.map((file) => file.size || 0);
  if (sizes.length > 0) {
    return Math.max(...sizes);
  } else {
    return 0;
  }
};

export const validateFileSizes = (files = []) => {
  const maxFileSize = getMaxFileSize(files);
  return maxFileSize <= fileSizeLimit;
};

export const validateFileSize = (file) => {
  return file.size <= fileSizeLimit;
};
