import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const staffSortOptions = [
  {
    key: 'id-asc',
    field: 'id',
    direction: 'asc',
    i18nKey: 'components.StaffPage.SortMenu.usrCodeAsc',
    icon: faSortAmountDownAlt,
  },
  {
    key: 'id-desc',
    field: 'id',
    direction: 'desc',
    i18nKey: 'components.StaffPage.SortMenu.usrCodeDesc',
    icon: faSortAmountDown,
  },

  {
    key: 'firstname-asc',
    field: 'firstname',
    direction: 'asc',
    i18nKey: 'components.StaffPage.SortMenu.usrFnameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'firstname-desc',
    field: 'firstname',
    direction: 'desc',
    i18nKey: 'components.StaffPage.SortMenu.usrFnameDesc',
    icon: faSortAlphaDownAlt,
  },

  {
    key: 'lastname-asc',
    field: 'lastname',
    direction: 'asc',
    i18nKey: 'components.StaffPage.SortMenu.usrLnameAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'lastname-desc',
    field: 'lastname',
    direction: 'desc',
    i18nKey: 'components.StaffPage.SortMenu.usrLnameDesc',
    icon: faSortAlphaDownAlt,
  },
];
