import { searchStringFromParams } from 'js/utilities/params';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { searchUrlForModel, cleanSearchResults } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setActive } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setActive(false);
    };
  };
};

export const searchRequestEffect = (options = {}) => {
  const {
    t,
    api,
    model,
    query = {},
    setActive,
    setResults,
    setTotalResults,
    abortControllerRef,
  } = options;
  return async () => {
    const { search = '' } = query;
    const url = searchUrlForModel(model, search);

    if (!url) {
      return;
    }

    setActive(true);

    rotateAbortControllerRef(abortControllerRef);
    const { signal } = abortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: { context: { message: t('components.Search.searchError') } },
        }
      );

      const { items = [], totalItems = 0 } = json;
      const results = cleanSearchResults(items, model);

      setResults(results);
      setTotalResults(totalItems);
      setActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setActive(false);
      }
    }
  };
};

export const previousSearchResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextSearchResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const searchResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, SortBy: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const onApplyFiltersEffect = (options = {}) => {
  const { history } = options;
  return (query = {}) => {
    const { search = '' } = query;
    history.push(search);
  };
};
