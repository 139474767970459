import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import ChequeRunBreadcrumbs from './breadcrumbs';
import ChequeRunTabs from './tabs';
import { getLockSystemEffect } from './effects';

const ChequeRunPage = (props) => {
  const {
    className,
    title,
    actions,
    onChangeLockSystemActivity,
    onLoadLockSystem,
    children,
    ...pageProps
  } = props;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  useEffect(
    getLockSystemEffect({
      t,
      api,
      onChangeLockSystemActivity,
      onLoadLockSystem,
    }),
    []
  );

  return (
    <Page
      className={classnames('cheque-run-page', className)}
      title={t('components.ChequeRunPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <div className="layout-container">
        <div className="layout-column">
          <ChequeRunBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <div className="cheque-run-page-heading">
            <div className="cheque-run-page-heading-left">
              <h1>{t('components.ChequeRunPage.heading')}</h1>
            </div>
            <div className="cheque-run-page-heading-right">{actions}</div>
          </div>
          <ChequeRunTabs />
          {children}
        </div>
      </div>
    </Page>
  );
};

ChequeRunPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

export default ChequeRunPage;
