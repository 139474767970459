import React, { Fragment } from 'react';
import { routePaths } from 'js/components/router/route-paths';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { clientApiKey, cleanNestedClient } from 'js/utilities/clients';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingClient,
    setSavingClient,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingClient(false);
      setSavingClient(false);
    };
  };
};

export const outstandingBalanceBannerEffect = (options = {}) => {
  const {
    t,
    history,
    presentStyledBanner,
    dismissBanner,
    hasPermission,
    hasOutstandingBalance,
    currentRoutePath,
    redirectPath,
    search,
  } = options;
  return () => {
    let bannerId = '';

    const showOnCurrentRoute =
      currentRoutePath !== routePaths.clientCaseHistory;

    if (hasPermission && hasOutstandingBalance && showOnCurrentRoute) {
      bannerId = presentStyledBanner('info', {
        className: 'client-outstanding-balance-banner',
        timeout: 0,
        progress: false,
        animated: false,
        content: (
          <Fragment>
            <span>{t('components.ClientPage.outstandingBalanceMessage')}</span>
            <button
              className="button-link-appearance"
              onClick={() => history.push(`${redirectPath}${search}`)}
            >
              {t('components.ClientPage.outstandingBalanceLinkText')}
            </button>
          </Fragment>
        ),
        dismissButtonContent: null,
      });
    }

    return () => {
      if (bannerId) {
        dismissBanner(bannerId, false);
      }
    };
  };
};

export const prepNewClientEffect = (options = {}) => {
  const {
    isNew,
    t,
    api,
    cache,
    id,
    setLoadingClient,
    setClient,
    prepClientAbortControllerRef,
  } = options;
  return async () => {
    if (!isNew) {
      return;
    }

    rotateAbortControllerRef(prepClientAbortControllerRef);
    const { signal } = prepClientAbortControllerRef.current;

    let initialClientData = cleanNestedClient({
      clientPersonal: { clientId: id },
    });

    if (id) {
      const url = clientApiKey(id, '00');
      const record = cache.get(url);
      if (!record) {
        setLoadingClient(true);
        try {
          const { json = {} } = await api.getJson(
            url,
            { signal },
            {
              success: { bypass: true },
              error: {
                context: {
                  message: t('components.ClientPage.getClientError'),
                },
              },
            }
          );

          const { clientAddress = {} } = cleanNestedClient(json);
          initialClientData = { ...initialClientData, clientAddress };
          setLoadingClient(false);
        } catch (error) {
          if (!isAbortError(error)) {
            setLoadingClient(false);
            // Fall through to apply the default initial client data
          }
        }
      } else {
        const { clientAddress = {} } = record.value;
        initialClientData = { ...initialClientData, clientAddress };
      }
    }

    setClient(initialClientData);
  };
};

export const getClientEffect = (options = {}) => {
  const {
    isNew,
    t,
    api,
    cache,
    id,
    clientCode,
    setLoadingClient,
    setClient,
    setHasOutstandingBalance,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    if (isNew) {
      return;
    }

    setLoadingClient(true);

    rotateAbortControllerRef(getClientAbortControllerRef);
    const { signal } = getClientAbortControllerRef.current;

    const url = clientApiKey(id, clientCode);
    const record = cache.get(url);

    if (!record) {
      try {
        const { json = {} } = await api.getJson(
          url,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.ClientPage.getClientError'),
              },
            },
          }
        );

        const client = cleanNestedClient(json);
        const {
          clientAccount: { outstandingAccount = false },
        } = client;
        cache.set(url, client);
        setClient(client);
        setHasOutstandingBalance(outstandingAccount);
        setLoadingClient(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingClient(false);
        }
      }
    } else {
      const { value: client } = record;
      const {
        clientAccount: { outstandingAccount = false },
      } = client;
      setClient(record.value);
      setHasOutstandingBalance(outstandingAccount);
      setLoadingClient(false);
    }
  };
};

export const postClientActiveEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    id,
    clientCode,
    setSavingClient,
    client,
    clientInactive,
    setClient,
    showModal,
    postClientActiveAbortControllerRef,
  } = options;

  const { clientPersonal = {} } = client;

  const nextClient = {
    ...client,
    clientPersonal: {
      ...clientPersonal,
      clientInactive,
    },
  };

  return async () => {
    try {
      const body = {
        clientPersonal: {
          clientInactive,
        },
      };

      setSavingClient(true);

      rotateAbortControllerRef(postClientActiveAbortControllerRef);
      const { signal } = postClientActiveAbortControllerRef.current;

      const clientUrl = clientApiKey(id, clientCode);
      const url = `${clientUrl}/active`;

      await api.postJson(
        url,
        { body, signal },
        {
          success: {
            context: {
              message: t('components.ClientPage.postClientActiveSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.ClientPage.postClientActiveError'),
            },
          },
        }
      );

      cache.set(clientUrl, nextClient);
      setClient(nextClient);
      setSavingClient(false);
      showModal(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingClient(false);
      }
    }
  };
};

export const getClientNotesConditionalEffect = (options) => {
  const { isNew, getClientNotes } = options;
  return () => {
    if (!isNew) {
      getClientNotes();
    }
  };
};

export const unsetCompanyChangedEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    id,
    clientCode,
    client,
    setSavingClient,
    setClient,
    unsetCompanyChangedAbortControllerRef,
  } = options;
  return async () => {
    setSavingClient(true);

    rotateAbortControllerRef(unsetCompanyChangedAbortControllerRef);
    const { signal } = unsetCompanyChangedAbortControllerRef.current;

    try {
      const url = `/Client/${id}/${clientCode}/company/changed?v=false`;
      await api.postJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientPage.unsetCompanyChangedError'),
            },
          },
        }
      );

      const { clientEmployment = {} } = client;
      const nextClient = {
        ...client,
        clientEmployment: { ...clientEmployment, companyChanged: false },
      };

      cache.set(clientApiKey(id, clientCode), nextClient);
      setClient(nextClient);
      setSavingClient(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setSavingClient(false);
      }
    }
  };
};
