import { tableSortDirection } from 'js/components/design-system';
import { formatDateFromISOString, formatDate } from 'js/utilities/dates';
import { roundToFixedPointString } from 'js/utilities/numbers';
import { cleanStringField } from 'js/utilities/strings';

// Case history columns are sorted by the column value in ascending
// or descending order, with the case open date in descending order
// as a secondary sort (tie breaker):
export const caseOpenDateSortPredicateDesc = (row1, row2) => {
  if (row1.openDate !== row2.openDate) {
    return row1.openDate < row2.openDate ? 1 : -1;
  } else {
    return 0;
  }
};

export const caseHistorySortPredicateAsc = (key, row1, row2) => {
  if (row1[key] !== row2[key]) {
    return row1[key] > row2[key] ? 1 : -1;
  } else {
    return caseOpenDateSortPredicateDesc(row1, row2);
  }
};

export const caseHistorySortPredicateDesc = (key, row1, row2) => {
  if (row1[key] !== row2[key]) {
    return row1[key] < row2[key] ? 1 : -1;
  } else {
    return caseOpenDateSortPredicateDesc(row1, row2);
  }
};

export const caseHistorySortOptions = {
  [tableSortDirection.asc]: caseHistorySortPredicateAsc,
  [tableSortDirection.desc]: caseHistorySortPredicateDesc,
};

export const caseHistoryPaymentSortPredicateAsc = (key, row1, row2) => {
  const value1 = parseFloat(row1[key]);
  const value2 = parseFloat(row2[key]);
  if (value1 !== value2) {
    return value1 > value2 ? 1 : -1;
  } else {
    return caseOpenDateSortPredicateDesc(row1, row2);
  }
};

export const caseHistoryPaymentSortPredicateDesc = (key, row1, row2) => {
  const value1 = parseFloat(row1[key]);
  const value2 = parseFloat(row2[key]);
  if (value1 !== value2) {
    return value1 < value2 ? 1 : -1;
  } else {
    return caseOpenDateSortPredicateDesc(row1, row2);
  }
};

export const caseHistoryPaymentSortOptions = {
  [tableSortDirection.asc]: caseHistoryPaymentSortPredicateAsc,
  [tableSortDirection.desc]: caseHistoryPaymentSortPredicateDesc,
};

export const getCaseHistoryColumns = (t) => {
  const paymentColumn = {
    key: 'payment',
    headerContent: t('components.ClientCaseHistory.columns.payment'),
    get: (key, row) => t('common.currency', { value: row[key] }),
    sort: caseHistoryPaymentSortOptions,
  };

  const otherColumns = [
    'cif',
    'status',
    'legalProblem',
    'lawyerType',
    'lawyerId',
    'openDate',
    'closedDate',
    'transferDate',
    'box',
    'file',
  ].map((key) => ({
    key,
    headerContent: t(`components.ClientCaseHistory.columns.${key}`),
    sort: caseHistorySortOptions,
  }));

  // The box and file columns may need to be added in the future:
  /*
  const boxColumn = {
    key: 'box',
    headerContent: t('components.ClientCaseHistory.columns.box'),
    sort: numericTableSortOptions,
  };

  const fileColumn = {
    key: 'file',
    headerContent: t('components.ClientCaseHistory.columns.file'),
    sort: numericTableSortOptions,
  };
  */

  return [paymentColumn, ...otherColumns /*, boxColumn, fileColumn*/];
};

export const cleanCaseHistoryItem = (item = {}) => {
  return {
    payment: roundToFixedPointString(item.payment, 2),
    cif: cleanStringField(item.cif),
    status: cleanStringField(item.status),
    legalProblem: cleanStringField(item.legalProblem),
    openDate: formatDateFromISOString(item.openDate),
    closedDate: formatDateFromISOString(item.closedDate),
    transferDate: formatDateFromISOString(item.transferDate),
    lawyerType: cleanStringField(item.lawyerType),
    lawyerId: cleanStringField(item.lawyerId),
    box: cleanStringField(item.box),
    file: cleanStringField(item.file),
  };
};

export const cleanCaseHistory = (caseHistory = []) => {
  return caseHistory.map(cleanCaseHistoryItem);
};

export const filenameForCaseHistoryReport = (clientId, clientCode) => {
  const clientString = `${clientId}-${clientCode}`;
  const dateString = formatDate(new Date(), 'yyyy-MM-dd');
  return `client_${clientString}_case_history_${dateString}.pdf`;
};
