import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { caseClosingBreadcrumbsHistory } from './functions';

const CaseClosingBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="case-closing-breadcrumbs"
      history={caseClosingBreadcrumbsHistory(t, location)}
    />
  );
};

export default CaseClosingBreadcrumbs;
