import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanReportFolders } from 'js/utilities/reports';
import { searchStringFromParams } from 'js/utilities/params';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingFolders } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingFolders(false);
    };
  };
};

export const onChangeFolderEffect = (options = {}) => {
  const { folderId, folderIdRef, getReportsFolders } = options;
  return () => {
    const { current: previousFolderId } = folderIdRef;
    const hasPreviousFolderId = !!previousFolderId;
    const hasCurrentFolderId = !!folderId;

    // The folder has been deseleted, which usually means a redirect
    // was performed from a specific folder to the folders list,
    // e.g. due to the selected folder having been deleted.
    const hasDeselectedFolder =
      !hasCurrentFolderId && hasPreviousFolderId && previousFolderId !== 'new';

    // A redirect was performed from the new folder route to a specific folder,
    // e.g. due to a new folder having been created.
    const hasNewFolder = hasCurrentFolderId && previousFolderId === 'new';

    if (hasDeselectedFolder || hasNewFolder) {
      getReportsFolders();
    }

    folderIdRef.current = folderId;
  };
};

export const getReportsFoldersEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    setLoadingFolders,
    setFolders,
    setTotalFolders,
    getReportsFoldersAbortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }

    setLoadingFolders(true);

    rotateAbortControllerRef(getReportsFoldersAbortControllerRef);
    const { signal } = getReportsFoldersAbortControllerRef.current;

    try {
      const endpoint = `/Reports/folders${query.search}`;
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ReportsPage.getFoldersRequestError'),
            },
          },
        }
      );

      const { items = [], totalItems = 0 } = json;
      const folders = cleanReportFolders(items);

      setFolders(folders);
      setTotalFolders(totalItems);
      setLoadingFolders(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingFolders(false);
      }
    }
  };
};

export const previousReportsFoldersEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const nextReportsFoldersEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const reportsFoldersPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const reportsFoldersSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, Order: field, Sort: direction };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const reportsFoldersSearchEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (searchTerm) => {
    const nextParams = { ...params, Offset: 0, Keyword: searchTerm };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const reportsFoldersSearchResetEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    const nextParams = { ...params, Offset: 0, Keyword: '' };
    const nextSearchString = searchStringFromParams(nextParams);
    history.push(nextSearchString);
  };
};

export const onCreateFolderEffect = (options = {}) => {
  const {
    history,
    query: { search = '' },
  } = options;
  return (createdFolder = {}) => {
    // Redirect to the new folder path with the current folder list search params.
    const { id: folderId } = createdFolder;
    const path = routePathReplacingParams(routePaths.reportsFolder, {
      folderId,
    });
    const redirect = search ? `${path}${search}` : path;
    history.replace(redirect);
  };
};

export const onUpdateFolderEffect = (options = {}) => {
  const { setFolders } = options;
  return (updatedFolder = {}) => {
    // Replace the updated item if it is found in the current folders list.
    setFolders((folders) => {
      const index = folders.findIndex(
        (folder) => folder.id === updatedFolder.id
      );
      if (index >= 0) {
        const nextFolders = [...folders];
        nextFolders[index] = updatedFolder;
        return nextFolders;
      } else {
        return folders;
      }
    });
  };
};

export const onDeleteFolderEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return () => {
    // Redirect to the folders list with the current search params,
    // but reset the offset because we don't know whether the pagination
    // params will be valid after removing the deleted folder.
    const nextParams = { ...params, Offset: 0 };
    const nextSearchString = searchStringFromParams(nextParams);
    const redirect = `${routePaths.reports}${nextSearchString}`;
    history.replace(redirect);
  };
};
