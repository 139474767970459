import {
  LAWYER_TYPE_ENUM,
  COOP_LAWYER_SUB_TYPES,
  NONCOOP_LAWYER_SUB_TYPES,
} from 'js/utilities/lawyers';

export const returnLawyerTypeData = (lawyerType, t) => {
  const type = Number(lawyerType);
  const isStaff = type === LAWYER_TYPE_ENUM.STAFF;
  const isCoop =
    COOP_LAWYER_SUB_TYPES(t).findIndex((subType) => subType.typeId === type) >=
    0;
  const isNonCoop =
    NONCOOP_LAWYER_SUB_TYPES(t).findIndex(
      (subType) => subType.typeId === type
    ) >= 0;
  if (isStaff) {
    return [
      {
        typeId: LAWYER_TYPE_ENUM.STAFF,
        label: t(`common.LawyerTypesByEnum.${LAWYER_TYPE_ENUM.STAFF}`),
      },
    ];
  } else if (isCoop) {
    return COOP_LAWYER_SUB_TYPES(t);
  } else if (isNonCoop) {
    return NONCOOP_LAWYER_SUB_TYPES(t);
  } else {
    return [];
  }
};

export const getPayeeOptions = (payeeOptions, lawyerType, t) => {
  const type = Number(lawyerType);

  const isLawyerDisabled =
    NONCOOP_LAWYER_SUB_TYPES(t).findIndex(
      (subType) => subType.typeId === type
    ) >= 0;

  return payeeOptions.map(({ payeeCode, payeeDesc }) => ({
    payeeCode,
    payeeDesc,
    //payee code 1 = Lawyer. non coop lawyers can not be paid through this process
    isDisabled: payeeCode === '1' && isLawyerDisabled,
  }));
};

export const canEditBilling = (options = {}) => {
  const { isAdmin, isActive, hasEditPermission, caseClosing } = options;
  if (isAdmin || (hasEditPermission && caseClosing)) {
    //isAdmin is admin and data entry, they can always edit
    //if they have permission to close cases they can also update when closing
    return true;
  } else {
    if (isActive) {
      //if you are allowed to edit, its only when active
      return hasEditPermission ? true : false;
    } else {
      return false;
    }
  }
};
