import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';
import { cleanCaseHistory } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingCaseHistory } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingCaseHistory(false);
    };
  };
};

export const getCaseHistoryEffect = (options = {}) => {
  const {
    t,
    api,
    query = {},
    lawyerId,
    setLoadingCaseHistory,
    setCaseHistory,
    setTotalResults,
    getCaseHistoryAbortControllerRef,
  } = options;
  return async () => {
    if (!query.search) {
      return;
    }

    setLoadingCaseHistory(true);

    rotateAbortControllerRef(getCaseHistoryAbortControllerRef);
    const { signal } = getCaseHistoryAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Lawyer/${lawyerId}/cases?${query.search}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerCaseHistory.requestError'),
            },
          },
        }
      );
      const { items = [], totalItems = 0 } = json;
      setCaseHistory(cleanCaseHistory(items));
      setTotalResults(totalItems);
      setLoadingCaseHistory(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingCaseHistory(false);
      }
    }
  };
};

export const onSelectCaseEffect = (options = {}) => {
  const { history } = options;
  return (caseInfo) => {
    const caseNumber = encodeURIComponent(caseInfo.cif);
    const casePath = routePathReplacingParams(routePaths.case, { caseNumber });
    history.push(casePath);
  };
};

export const previousCaseHistoryResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Math.max(Offset - Limit, 0) };
    history.push(searchStringFromParams(nextParams));
  };
};

export const nextCaseHistoryResultsEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  const { Offset, Limit } = params;
  return () => {
    const nextParams = { ...params, Offset: Offset + Limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const caseHistoryResultsPageNumberEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { offset, limit }) => {
    const nextParams = { ...params, Offset: offset, Limit: limit };
    history.push(searchStringFromParams(nextParams));
  };
};

export const caseHistoryResultsSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, Offset: 0, Order: field, Sort: direction };
    history.push(searchStringFromParams(nextParams));
  };
};
