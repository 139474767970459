import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '../../icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { createDateMaskEffect, onChangeEffect } from './effects';

const DatePickerInput = (props) => {
  const { t } = useTranslation();
  const {
    className,
    inputRef,
    onChange,
    onClickCalendar,
    calendarDisabled,
    placeholder = t('common.defaultDatePlaceholder'),
    ...inputProps
  } = props;
  const dateMaskRef = useRef(null);

  useEffect(createDateMaskEffect({ dateMaskRef }), []);

  return (
    <div className="date-picker-input-container">
      <input
        type="text"
        className={classnames('date-picker-input', className)}
        ref={inputRef}
        placeholder={placeholder}
        onChange={onChangeEffect({ dateMaskRef, onChange })}
        {...inputProps}
      />
      <button
        className="date-picker-input-icon"
        tabIndex="-1"
        disabled={calendarDisabled}
        onClick={onClickCalendar}
      >
        <FontAwesomeIcon icon={faCalendar} />
      </button>
    </div>
  );
};

DatePickerInput.defaultProps = {
  calendarDisabled: false,
};

DatePickerInput.propTypes = {
  ...ReactDatePicker.propTypes,
  onClickCalendar: PropTypes.func,
  calendarDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default DatePickerInput;
