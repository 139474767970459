import React from 'react';
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import { formatDateFromISOString } from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';

export const filenameForDocument = (doc) => {
  const { documentName, documentType } = doc;
  return `${documentName}.${documentType}`;
};

export const getDocumentsColumns = ({
  t,
  onClickDownloadDocument,
  onClickDeleteDocument,
  hasDeletePermission = false,
  hasDownloadPermission = false,
}) => {
  const nameColumn = {
    key: 'documentName',
    headerContent: t('components.ClientDocuments.columns.documentName'),
    // eslint-disable-next-line react/display-name
    get: (key, row) => filenameForDocument(row),
    sort: {
      asc: (key, row1, row2) => {
        const { documentName: name1, documentType: type1 } = row1;
        const { documentName: name2, documentType: type2 } = row2;
        if (name1 !== name2) {
          return name1 > name2 ? 1 : -1;
        } else if (type1 !== type2) {
          return type1 > type2 ? 1 : -1;
        } else {
          return 0;
        }
      },
      desc: (key, row1, row2) => {
        const { documentName: name1, documentType: type1 } = row1;
        const { documentName: name2, documentType: type2 } = row2;
        if (name1 !== name2) {
          return name1 < name2 ? 1 : -1;
        } else if (type1 !== type2) {
          return type1 < type2 ? 1 : -1;
        } else {
          return 0;
        }
      },
    },
  };

  const actionsColumn = {
    key: 'documentActions',
    headerContent: '',
    sortable: false,
    // eslint-disable-next-line react/display-name
    get: (key, row) => (
      <div className="client-document-actions">
        {hasDeletePermission && (
          <button
            className="button-link-appearance"
            onClick={() => onClickDeleteDocument(row)}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> {t('common.delete')}
          </button>
        )}
        {hasDownloadPermission && (
          <button
            className="button-link-appearance"
            onClick={() => onClickDownloadDocument(row)}
          >
            <FontAwesomeIcon icon={faDownload} /> {t('common.download')}
          </button>
        )}
      </div>
    ),
  };

  const otherColumns = ['dateUploaded'].map((key) => ({
    key,
    headerContent: t(`components.ClientDocuments.columns.${key}`),
  }));

  return [nameColumn, ...otherColumns, actionsColumn];
};

export const cleanDocumentsItem = (item = {}) => {
  return {
    ...item,
    documentType: cleanStringField(item.documentType),
    documentName: cleanStringField(item.documentName),
    dateUploaded: formatDateFromISOString(item.dateUploaded),
  };
};

export const cleanDocuments = (documents = []) => {
  return documents.map(cleanDocumentsItem);
};

export const openDocumentUrl = (url) =>
  window.open(url, '_blank', 'noopener,noreferrer');
