import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { PrintHeader } from 'js/components/print';
import { LAWYER_TYPE_ENUM, returnlawyerTypeFromId } from 'js/utilities/lawyers';
import { formatDate } from 'js/utilities/dates';
import { bisectArray } from 'js/utilities/array';
import { onMountEffect, getLawyerPrintDataEffect } from './effects';
import { getLawyerLanguages, getLawyerAddress, getAddress } from './functions';

const LawyerPrintView = () => {
  const { t } = useTranslation();
  const {
    params: { lawyerId },
  } = useRouteMatch();
  const lawyerApproxType = returnlawyerTypeFromId(lawyerId);
  const isStaffLawyer = lawyerApproxType === LAWYER_TYPE_ENUM.STAFF;

  const { api = {} } = useContext(FetchContext);

  const getLawyerAbortControllerRef = useRef(null);
  const getPanelsAbortControllerRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [lawyer, setLawyer] = useState({});
  const [panels, setPanels] = useState([]);

  const columns = bisectArray(panels);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerAbortControllerRef,
        getPanelsAbortControllerRef,
      ],
      setLoading,
    }),
    []
  );

  useEffect(
    getLawyerPrintDataEffect({
      t,
      api,
      lawyerId,
      lawyerApproxType,
      setLoading,
      setLawyer,
      setPanels,
      getLawyerAbortControllerRef,
      getPanelsAbortControllerRef,
    }),
    []
  );

  const { lawyerInfo = {}, lawyerFirm = {} } = lawyer;

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    city,
    province,
    dateOpened,
    calledToBar,
    customaryFee,
    payFirm,
    lawyerNotes,
  } = lawyerInfo;

  const {
    firmId,
    firmName,
    primaryPhone: firmPhone,
    fax: firmFax,
  } = lawyerFirm;

  const title = isStaffLawyer
    ? t('components.LawyerPrintView.staffTitle', { lawyerId })
    : t('components.LawyerPrintView.title', { lawyerId });

  const lawyerActive = isStaffLawyer
    ? lawyerInfo.isActive
    : !lawyerInfo.lawyerInactive;

  return (
    <div className="lawyer-print-view">
      {isLoading && <ActivityIndicatorCard />}
      {!isLoading && (
        <Fragment>
          <PrintHeader
            className="lawyer-print-view-header"
            title={title}
            heading={
              <Fragment>
                <span>{`${firstName} ${lastName}`}</span>
                <span>
                  {lawyerActive
                    ? t('components.LawyerPrintView.status.active')
                    : t('components.LawyerPrintView.status.inactive')}
                </span>
              </Fragment>
            }
            printOnly={false}
          />

          {!isStaffLawyer && (
            <div className="lawyer-print-view-details" data-print-managed>
              <h2>{t('components.LawyerPrintView.details.title')}</h2>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.details.phone')}</span>
                  <span>{phoneNumber}</span>
                </div>
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.details.email')}</span>
                  <span>{email}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.details.city')}</span>
                  <span>{city}</span>
                </div>
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.province')}
                  </span>
                  <span>{province}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.languages')}
                  </span>
                  <span>{getLawyerLanguages(t, lawyerInfo)}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.dateOpened')}
                  </span>
                  <span>{formatDate(dateOpened)}</span>
                </div>
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.calledToBar')}
                  </span>
                  <span>{calledToBar}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.customaryFee')}
                  </span>
                  <span>{customaryFee}</span>
                </div>
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.details.payFirm')}</span>
                  <span>{payFirm ? t('common.yes') : t('common.no')}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>
                    {t('components.LawyerPrintView.details.mailingAddress')}
                  </span>
                  <span>{getLawyerAddress(t, lawyerInfo)}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.details.notes')}</span>
                  <span>{lawyerNotes}</span>
                </div>
              </div>
            </div>
          )}

          {!isStaffLawyer && !!firmId && (
            <div className="lawyer-print-view-details" data-print-managed>
              <h2>{t('components.LawyerPrintView.firm.title')}</h2>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.firm.name')}</span>
                  <span>{firmName}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.firm.phone')}</span>
                  <span>{firmPhone}</span>
                </div>
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.firm.fax')}</span>
                  <span>{firmFax}</span>
                </div>
              </div>
              <div className="lawyer-print-view-details-row">
                <div className="lawyer-print-view-details-item">
                  <span>{t('components.LawyerPrintView.firm.address')}</span>
                  <span>{getAddress(lawyerFirm)}</span>
                </div>
              </div>
            </div>
          )}

          {!isStaffLawyer && (
            <div className="lawyer-print-view-panels" data-print-managed>
              <h2>{t('components.LawyerPrintView.panels.title')}</h2>
              <div className="lawyer-print-view-panels-columns">
                {columns.map((panels, idx) => (
                  <ul key={idx}>
                    {panels.map((panel) => {
                      const { panelKey, lawyerSubpanels = [] } = panel;
                      return (
                        <li key={panelKey}>
                          <span>
                            {t(`common.lawyerPanels.${panelKey}.title`)}
                          </span>
                          <ul>
                            {lawyerSubpanels.map(({ subPanelKey }) => (
                              <li key={subPanelKey}>
                                {t(
                                  `common.lawyerPanels.${panelKey}.subPanels.${subPanelKey}`
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                ))}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default LawyerPrintView;
