import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { expandingCardContentClassName } from './functions';

const ExpandingCardContent = ({ className, children }) => (
  <div className={classnames(expandingCardContentClassName, className)}>
    {children}
  </div>
);

ExpandingCardContent.propTypes = {
  className: PropTypes.string,
};

export default ExpandingCardContent;
