import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { DataContext } from 'js/components/data';
import { ActivityIndicatorCard } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import FeedbackSortMenu from 'js/components/menus/feedback-sort-menu';
import { PrintFooter } from 'js/components/print';
import LawyerPrintHeader from '../lawyer-print-header';
import LawyerPage from '../lawyer-page';
import { LawyerContext, LawyerProvider } from '../lawyer-context';
import { LawyerFeedbackCard } from '../lawyer-feedback-card';
import {
  onMountEffect,
  getLawyerFeedbackEffect,
  getLawyerFeedbackDocumentEffect,
  sortLawyerFeedbackEffect,
  onChangeSortEffect,
  onDismissAddLawyerModalEffect,
  onDismissDeleteLawyerModalEffect,
  setEditingFeedbackEffect,
  onDismissEditLawyerModalEffect,
} from './effects';
import {
  queryForSearchString,
  openDocumentUrl,
  returnFilteredFeedback,
} from './functions';
import {
  AddLawyerFeedbackModal,
  DeleteLawyerFeedbackModal,
} from './add-delete-feedback';
import { GroupPermissionsContext } from 'js/components/group-permissions/context';
import { userGroups } from 'js/components/group-permissions';
import GeneralSearchInput from 'js/components/controls/general-search-input';

const LawyerFeedback = (props) => {
  const getLawyerFeedbackAbortControllerRef = useRef(null);
  const getLawyerFeedbackDocumentAbortControllerRef = useRef(null);
  const { match = {}, location = {}, history } = props;
  const {
    params: { lawyerId },
  } = match;
  const query = queryForSearchString(location.search);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { matchAnyGroup } = useContext(GroupPermissionsContext);

  const {
    lawyer,
    lawyerFeedback,
    setLawyerFeedback,
    isLoadingLawyerFeedback,
    setLoadingLawyerFeedback,
  } = useContext(LawyerContext);

  const [
    AddEditFeedbackModalVisible,
    setAddEditFeedbackModalVisible,
  ] = useState(false);
  const [editingFeedback, setEditingFeedback] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [feedbackIdPendingDeletion, setFeedbackIdPendingDeletion] = useState(0);
  const [searchString, setSearchString] = useState('');

  const filteredFeedback = returnFilteredFeedback(lawyerFeedback, searchString);

  const hasAccessToFeedback = matchAnyGroup([
    userGroups.administrator,
    userGroups.feedbackSpecialist,
    userGroups.dataEntry,
  ]);

  const getLawyerFeedback = getLawyerFeedbackEffect({
    t,
    api,
    cache,
    query,
    lawyerId,
    setLoadingLawyerFeedback,
    setLawyerFeedback,
    getLawyerFeedbackAbortControllerRef,
    hasAccessToFeedback,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerFeedbackAbortControllerRef,
        getLawyerFeedbackDocumentAbortControllerRef,
      ],
      setLoadingLawyerFeedback,
    }),
    []
  );

  useEffect(
    sortLawyerFeedbackEffect({
      query,
      setLawyerFeedback,
    }),
    [query.search]
  );

  const getLawyerFeedbackDocument = getLawyerFeedbackDocumentEffect({
    t,
    api,
    lawyerId,
    openDocumentUrl,
    getLawyerFeedbackDocumentAbortControllerRef,
  });

  return (
    <LawyerPage
      className="lawyer-feedback"
      title={t('components.LawyerFeedback.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() => window.print()}
            disabled={isLoadingLawyerFeedback}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={() => setAddEditFeedbackModalVisible(true)}
            disabled={isLoadingLawyerFeedback}
          >
            {t('components.LawyerFeedback.addFeedback')}
          </button>
        </Fragment>
      }
    >
      <AddLawyerFeedbackModal
        mounted={AddEditFeedbackModalVisible}
        lawyerId={lawyerId}
        onDismiss={onDismissAddLawyerModalEffect({
          setAddEditFeedbackModalVisible,
          getLawyerFeedback,
        })}
        editingFeedback={editingFeedback}
        onCancelEdit={onDismissEditLawyerModalEffect({
          setAddEditFeedbackModalVisible,
          getLawyerFeedback,
          setEditingFeedback,
          setIsEditing,
        })}
        isEditing={isEditing}
      />

      <DeleteLawyerFeedbackModal
        mounted={feedbackIdPendingDeletion > 0}
        lawyerId={lawyerId}
        feedbackId={feedbackIdPendingDeletion}
        onDismiss={onDismissDeleteLawyerModalEffect({
          setFeedbackIdPendingDeletion,
          getLawyerFeedback,
        })}
      />

      <LawyerPrintHeader lawyer={lawyer} />

      {isLoadingLawyerFeedback && <ActivityIndicatorCard />}

      {!isLoadingLawyerFeedback && (
        <Fragment>
          {lawyerFeedback.length > 0 && (
            <Fragment>
              <div className="lawyer-feedback-actions">
                {lawyerFeedback.length > 0 && (
                  <FeedbackSortMenu
                    field={query.params.orderBy}
                    direction={query.params.order}
                    onSelect={onChangeSortEffect({ history, query })}
                  />
                )}
                <GeneralSearchInput
                  placeholder={t('components.LawyerFeedback.searchPlaceholder')}
                  onSearch={setSearchString}
                  onReset={() => setSearchString('')}
                />
              </div>
              <div className="lawyer-feedback-cards-list" data-print-managed>
                {filteredFeedback.map((record) => (
                  <LawyerFeedbackCard
                    key={record.feedbackId}
                    feedback={record}
                    onClickDocument={getLawyerFeedbackDocument}
                    onClickDelete={setFeedbackIdPendingDeletion}
                    onClickEdit={setEditingFeedbackEffect({
                      setEditingFeedback,
                      setAddEditFeedbackModalVisible,
                      setIsEditing,
                    })}
                    lawyer={lawyer.lawyerInfo}
                    getLawyerFeedback={getLawyerFeedback}
                  />
                ))}
              </div>
            </Fragment>
          )}
          {filteredFeedback.length === 0 && (
            <EmptyState
              title={t('components.LawyerFeedback.emptyStateMessage')}
            />
          )}
        </Fragment>
      )}

      <PrintFooter />
    </LawyerPage>
  );
};

const LawyerFeedbackWithContext = (props) => (
  <LawyerProvider>
    <LawyerFeedback {...props} />
  </LawyerProvider>
);

export default LawyerFeedbackWithContext;
