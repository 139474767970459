import React from 'react';
import { numericTableSortOptions } from 'js/components/design-system';
import { proceduresBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { formatDateFromISOString } from 'js/utilities/dates';
import { cleanStringField } from 'js/utilities/strings';

export const dataIntegrityBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;

  return [
    ...proceduresBreadcrumbsHistory(t),
    {
      content: t(
        'components.PrimaryClientIntegrity.breadcrumbs.primaryClientIntegrity'
      ),
      path: currentPath,
    },
  ];
};

export const getDataIntegrityReportColumns = (t, onClickUpdateClientStatus) => {
  const yearColumn = {
    key: 'cas_yearid',
    headerContent: 'cas_yearid',
    sort: numericTableSortOptions,
  };

  const officeIdColumn = {
    key: 'cas_offid',
    headerContent: 'cas_offid',
    sort: numericTableSortOptions,
  };

  const caseIdColumn = {
    key: 'cas_caseid',
    headerContent: 'cas_caseid',
    sort: numericTableSortOptions,
  };

  const closeDateColumn = {
    key: 'cas_clodt',
    headerContent: 'cas_clodt',
  };

  const clientIdColumn = {
    key: 'cas_sin',
    headerContent: 'cas_sin',
    sort: numericTableSortOptions,
  };

  const clientCodeColumn = {
    key: 'cas_ccode',
    headerContent: 'cas_ccode',
    sort: numericTableSortOptions,
  };

  const caseStatusColumn = {
    key: 'cas_status',
    headerContent: 'cas_status',
  };

  const caseClientStatusColumn = {
    key: 'cas_cstat',
    headerContent: 'cas_cstat',
  };

  const primaryClientStatusColumn = {
    key: 'cas_priclistat',
    headerContent: 'cas_priclistat',
    sort: numericTableSortOptions,
  };

  const actionsColumn = {
    key: 'actions',
    headerContent: '',
    sortable: false,
    /* eslint-disable react/display-name */
    get: (key, row) => (
      <button
        className="button-link-appearance"
        onClick={() => onClickUpdateClientStatus(row)}
        data-screen-only
      >
        {row.updated
          ? t('components.PrimaryClientIntegrity.updated')
          : t('components.PrimaryClientIntegrity.update')}
      </button>
    ),
    /* eslint-enable react/display-name */
  };

  return [
    yearColumn,
    officeIdColumn,
    caseIdColumn,
    closeDateColumn,
    clientIdColumn,
    clientCodeColumn,
    caseStatusColumn,
    caseClientStatusColumn,
    primaryClientStatusColumn,
    actionsColumn,
  ];
};

export const clientStatusValidationRules = {
  primaryClientStatus: (data, key) => {
    const value = parseInt(data[key]);
    const acceptableValues = [1, 2, 3, 6];
    if (isNaN(value)) {
      return false;
    } else {
      return acceptableValues.includes(value);
    }
  },
};

export const clientStatusRequiredFields = Object.keys(
  clientStatusValidationRules
);

export const cleanDataIntegrityReportItems = (items = []) => {
  return items.map(cleanDataIntegrityReportItem);
};

export const cleanDataIntegrityReportItem = (item = {}) => {
  const { cas_clodt, ...rest } = item;
  const cif = cifForDataIntegrityItem(item);

  const nextItem = Object.keys(rest).reduce((acc, key) => {
    return { ...acc, [key]: cleanStringField(rest[key]) };
  }, {});

  return {
    ...nextItem,
    cas_clodt: formatDateFromISOString(cas_clodt),
    cif,
    updated: false,
  };
};

export const cifForDataIntegrityItem = (item = {}) => {
  const yearId = item.cas_yearid || '';
  const officeId = item.cas_offid || '';
  const caseId = item.cas_caseid || '';
  return `${yearId}/${officeId}/${caseId}`;
};
