import React, {
  useRef,
  useContext,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListButtonCard,
  StyledSelect,
} from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import {
  onMountEffect,
  staffLawyerRequestEffect,
  previousStaffLawyerResultsEffect,
  nextStaffLawyerResultsEffect,
  staffLawyerResultsPageNumberEffect,
  onSearchChangeEffect,
  onInputChangeEffect,
} from './effects';
import {
  queryFromModalQueryString,
  STAFF_LAWYER_ACTIVE_STATUS,
} from './functions';
import { LAWYER_TYPE_ENUM } from 'js/utilities/lawyers';
import EmptyState from 'js/components/controls/empty-state';

const StaffLawyerModal = (props) => {
  const { t } = useTranslation();
  const {
    mounted,
    onSelect,
    onBack,
    onChange,
    onClose,
    modalQueryString,
    officeOpened = '',
  } = props;

  const abortControllerRef = useRef(null);
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [staffLawyers, setStaffLawyers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const nextQuery = queryFromModalQueryString(modalQueryString);

  const {
    params: {
      ActiveStatus = STAFF_LAWYER_ACTIVE_STATUS.ACTIVE, //defaulted to active lawyers
    },
  } = nextQuery;

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    staffLawyerRequestEffect({
      t,
      api,
      query: nextQuery,
      setActive,
      setStaffLawyers,
      setTotalResults,
      abortControllerRef,
      officeOpened,
    }),
    [modalQueryString]
  );

  const pagination = (
    <div className="pagination-container">
      <OffsetLimitPagination
        className="law-firm-pagination"
        offset={nextQuery.params.Offset}
        limit={nextQuery.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousStaffLawyerResultsEffect({
          query: nextQuery,
          onChange,
        })}
        onClickNext={nextStaffLawyerResultsEffect({
          query: nextQuery,
          onChange,
        })}
        onClickPageNumber={staffLawyerResultsPageNumberEffect({
          query: nextQuery,
          onChange,
        })}
      />
    </div>
  );

  return (
    <Modal
      className={'select-lawyer-modal search-lawyer-modal'}
      backButtonText={t(`common.back`)}
      onClickBack={onBack}
      onClickCancel={onClose}
      titleText={t('components.StaffLawyerModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t('components.StaffLawyerModal.title')}</h2>
      <div className="layout-container inset-col-1 search-container staff-lawyer-search-bar">
        <div className="layout-column">
          <GeneralSearchInput
            placeholder={t('components.StaffLawyerModal.searchPlaceholder')}
            initialQuery={nextQuery}
            onSearch={onSearchChangeEffect({ onChange })}
            onReset={onSearchChangeEffect({ onChange })}
          />
        </div>
        <div className="layout-column ">
          <StyledSelect
            value={ActiveStatus}
            name="ActiveStatus"
            onChange={onInputChangeEffect({ onChange })}
          >
            <option value={STAFF_LAWYER_ACTIVE_STATUS.ALL}>
              {t('components.CoopLawyerModal.allLawyers')}
            </option>
            <option value={STAFF_LAWYER_ACTIVE_STATUS.ACTIVE}>
              {t('components.CoopLawyerModal.activeLawyers')}
            </option>
            <option value={STAFF_LAWYER_ACTIVE_STATUS.INACTIVE}>
              {t('components.CoopLawyerModal.inactiveLawyers')}
            </option>
          </StyledSelect>
        </div>
        <div className="layout-column"></div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {!active && staffLawyers.length > 0 && pagination}
          {active && <ActivityIndicatorCard />}
          {!active &&
            staffLawyers.map((staffLawyer) => {
              return (
                <ListButtonCard
                  key={staffLawyer.staffId}
                  onClick={() =>
                    onSelect({
                      lawyer: staffLawyer,
                      lawyerType: LAWYER_TYPE_ENUM.STAFF,
                    })
                  }
                  heading={
                    <Fragment>
                      <span>{`${staffLawyer.lastName}, ${staffLawyer.firstName}`}</span>
                      <InactiveTag inactive={!staffLawyer.isActive} />
                    </Fragment>
                  }
                  subheading={`${t(
                    'components.StaffLawyerModal.staffLawyerId'
                  )}: ${staffLawyer.staffId}`}
                />
              );
            })}
          {!active && staffLawyers.length === 0 && (
            <EmptyState
              title={t(`components.StaffLawyerModal.emptyStateMessage`)}
            />
          )}
          {!active && staffLawyers.length > 0 && pagination}
        </div>
      </div>
    </Modal>
  );
};

StaffLawyerModal.propTypes = {
  ...Modal.propTypes,
  onSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  officeOpened: PropTypes.string,
};

export default StaffLawyerModal;
