import React, { useState } from 'react';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  ActionCard,
  IndentedCard,
  ListCard,
  CaseListCard,
  IndentedListCard,
  NoteCard,
} from '../components/cards';
import { FontAwesomeIcon } from '../components/icons';
import { Disclosure } from '../components/disclosure';

const Cards = () => {
  const [disclosureContentVisible, setDisclosureContentVisible] = useState(
    false
  );

  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Cards</h1>

        <h2>Card</h2>
        <Card>This is a plain card</Card>
        <Card linkTo="/">This is a linked card</Card>
        <IndentedCard>This is an indented card</IndentedCard>
        <IndentedCard linkTo="/">This is a linked indented card</IndentedCard>

        <h2>ActionCard</h2>
        <ActionCard
          heading="Heading"
          subheading="This is an action card"
          headerAction={
            <button className="button-link-appearance">
              <FontAwesomeIcon icon={faSync} /> Action
            </button>
          }
          footerAction={<button>Action</button>}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            id feugiat neque. Cras non sem ac libero ullamcorper interdum nec at
            velit.
          </p>
          <Disclosure
            heading="Disclosure"
            showContentAction={
              <button
                className="button-link-appearance"
                onClick={() => setDisclosureContentVisible(true)}
              >
                Show
              </button>
            }
            hideContentAction={
              <button
                className="button-link-appearance"
                onClick={() => setDisclosureContentVisible(false)}
              >
                Hide
              </button>
            }
            contentVisible={disclosureContentVisible}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            id feugiat neque. Cras non sem ac libero ullamcorper interdum nec at
            velit.
          </Disclosure>
        </ActionCard>

        <h2>ListCard</h2>
        <ListCard
          linkTo="/"
          heading="Heading"
          subheading="This is a plain list card"
        />
        <IndentedListCard
          linkTo="/"
          heading="Heading"
          subheading="This is an indented list card"
        />
        <CaseListCard
          linkTo="/"
          heading="Case Number"
          subheading="This is a case list card"
        />

        <h2>NoteCard</h2>
        <div className="note-cards-list">
          <NoteCard
            id="1"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              id feugiat neque. Cras non sem ac libero ullamcorper interdum nec at
              velit. Nulla eu nibh a enim egestas pellentesque a eu nunc. Donec
              vitae mollis ipsum. Nam vestibulum tempus volutpat. Nunc vestibulum id
              purus id vestibulum. Ut dapibus et sapien nec convallis. Nunc laoreet
              erat non neque hendrerit tristique. In in tincidunt nibh. Nulla velit
              elit, fermentum sed nisl viverra, lobortis rhoncus erat. Nullam purus
              erat, vulputate vitae dolor ut, egestas dignissim sapien. Etiam in
              tristique erat. Proin feugiat eu metus eu facilisis. Fusce pulvinar
              erat nec magna accumsan facilisis. Phasellus sollicitudin magna a
              efficitur lobortis."
            name="Alan Thompson"
            date="2021/02/04"
          />
          <NoteCard
            id="2"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              id feugiat neque. Cras non sem ac libero ullamcorper interdum nec at
              velit. Nulla eu nibh a enim egestas pellentesque a eu nunc. Donec
              vitae mollis ipsum. Nam vestibulum tempus volutpat. Nunc vestibulum id
              purus id vestibulum. Ut dapibus et sapien nec convallis. Nunc laoreet
              erat non neque hendrerit tristique. In in tincidunt nibh. Nulla velit
              elit, fermentum sed nisl viverra, lobortis rhoncus erat. Nullam purus
              erat, vulputate vitae dolor ut, egestas dignissim sapien. Etiam in
              tristique erat. Proin feugiat eu metus eu facilisis. Fusce pulvinar
              erat nec magna accumsan facilisis. Phasellus sollicitudin magna a
              efficitur lobortis."
            name="Alan Thompson"
            date="2021/02/04"
          />
        </div>
      </div>
    </div>
  );
};

export default Cards;
