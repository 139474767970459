import React, { Fragment, useContext } from 'react';
import { CurrentUserContext } from 'js/components/current-user';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { routePaths } from 'js/components/router/route-paths';
import { AdminListCard } from 'js/components/design-system';
import AutoVoidModal from '../auto-void-modal';
import ProceduresBreadcrumbs from './breadcrumbs';
import { onClickAutoVoidEffect } from './effects';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import { isEmpty } from 'js/utilities/validation';

const ProceduresPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { hasLoaded: hasLoadedUser, currentUser = {} } = useContext(
    CurrentUserContext
  );
  const hasUser = hasLoadedUser && !isEmpty(currentUser);

  return (
    <Page
      className="procedures-page"
      title={t('components.ProceduresPage.title')}
      header={<FixedHeader />}
    >
      <AutoVoidModal />

      <div className="layout-container">
        <div className="layout-column">
          <ProceduresBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="admin-page-heading">
            <h1>{t('components.ProceduresPage.title')}</h1>
          </div>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {hasUser && (
            <Fragment>
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.feedbackSpecialist,
                  userGroups.intakeSpecialist,
                  userGroups.dataEntry,
                  userGroups.support,
                ]}
              >
                <AdminListCard
                  linkTo={routePaths.caseClosing}
                  heading={t('components.ProceduresPage.caseClosing')}
                />
              </GroupPermissionsGate>
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                ]}
              >
                <AdminListCard
                  linkTo={routePaths.issueCheque}
                  heading={t('components.ProceduresPage.issueCheque')}
                />
                <AdminListCard
                  onClick={onClickAutoVoidEffect({ search, history })}
                  heading={t('components.ProceduresPage.autoVoid')}
                  accessory={null}
                />
              </GroupPermissionsGate>
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                  userGroups.intakeSpecialist,
                  userGroups.feedbackSpecialist,
                ]}
              >
                <AdminListCard
                  linkTo={routePaths.caseReassignment}
                  heading={t('components.ProceduresPage.caseReassignment')}
                />
              </GroupPermissionsGate>
            </Fragment>
          )}
        </div>
        <div className="layout-column">
          {hasUser && (
            <Fragment>
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                ]}
              >
                <AdminListCard
                  linkTo={routePaths.chequeRun}
                  heading={t('components.ProceduresPage.chequeRun')}
                />
                <AdminListCard
                  linkTo={routePaths.csqEntry}
                  heading={t('components.ProceduresPage.csqEntry')}
                />
                <AdminListCard
                  linkTo={routePaths.primaryClientIntegrity}
                  heading={t(
                    'components.ProceduresPage.primaryClientIntegrity'
                  )}
                />
                <AdminListCard
                  linkTo={routePaths.caseCorrection}
                  heading={t('components.ProceduresPage.caseCorrection')}
                />
              </GroupPermissionsGate>
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ProceduresPage;
