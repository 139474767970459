import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanCaseHistory, filenameForCaseHistoryReport } from './functions';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingCaseHistory } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingCaseHistory(false);
    };
  };
};

export const getCaseHistoryEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    setLoadingCaseHistory,
    setCaseHistory,
    getCaseHistoryAbortControllerRef,
  } = options;
  return async () => {
    setLoadingCaseHistory(true);

    rotateAbortControllerRef(getCaseHistoryAbortControllerRef);
    const { signal } = getCaseHistoryAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Client/${id}/${clientCode}/CaseHistory`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientCaseHistory.requestError'),
            },
          },
        }
      );
      setCaseHistory(cleanCaseHistory(json));
      setLoadingCaseHistory(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingCaseHistory(false);
      }
    }
  };
};

export const getCaseHistoryReportEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    setLoadingReport,
    setReportResult,
    getReportAbortControllerRef,
  } = options;
  return async () => {
    setLoadingReport(true);

    rotateAbortControllerRef(getReportAbortControllerRef);
    const { signal } = getReportAbortControllerRef.current;

    const headers = {
      'Content-Type': 'application/json',
    };

    const body = JSON.stringify({ clientId: id, clientCode });

    try {
      const { response } = await api.fetch(
        `/Reports/case/history`,
        { method: 'POST', headers, body, signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ClientCaseHistory.reportRequestError'),
            },
          },
        }
      );

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      if (objectUrl) {
        const filename = filenameForCaseHistoryReport(id, clientCode);
        setReportResult({ objectUrl, filename });
        setLoadingReport(false);
      } else {
        throw new Error('Invalid report file data.');
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingReport(false);
      }
    }
  };
};

export const onSelectCaseEffect = (options = {}) => {
  const { history } = options;
  return (caseInfo) => {
    const caseNumber = encodeURIComponent(caseInfo.cif);
    const casePath = routePathReplacingParams(routePaths.case, { caseNumber });
    history.push(casePath);
  };
};
