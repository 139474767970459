import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';

const VoidDenyModal = (props) => {
  const { t } = useTranslation();
  const { mounted, onClose, onSave, nextCaseStatus, active } = props;
  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t(
        `components.CasePage.VoidDenyModal.${nextCaseStatus}.closeButton`
      )}
      onClickCancel={onClose}
      titleText={t(`components.CasePage.VoidDenyModal.${nextCaseStatus}.title`)}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
    >
      <h2>{t(`components.CasePage.VoidDenyModal.${nextCaseStatus}.title`)}</h2>
      <p>{t(`components.CasePage.VoidDenyModal.${nextCaseStatus}.text`)}</p>
      <div className="button-bar">
        <button className="button-link-appearance" onClick={onClose}>
          {t(
            `components.CasePage.VoidDenyModal.${nextCaseStatus}.cancelButton`
          )}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          onClick={onSave}
        >
          {active
            ? t('common.updating')
            : t(
                `components.CasePage.VoidDenyModal.${nextCaseStatus}.saveButton`
              )}
        </button>
      </div>
    </Modal>
  );
};

VoidDenyModal.propTypes = {
  mounted: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  nextCaseStatus: PropTypes.string,
  active: PropTypes.bool,
};

export default VoidDenyModal;
