import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const Card = (props) => {
  const { className, linkTo, children, onClick } = props;
  const commonProps = {
    className: classnames('card', className),
  };

  if (linkTo) {
    return (
      <Link to={linkTo} {...commonProps}>
        {children}
      </Link>
    );
  } else if (onClick) {
    return (
      <button onClick={onClick} {...commonProps}>
        {children}
      </button>
    );
  } else {
    return <div {...commonProps}>{children}</div>;
  }
};

Card.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

export default Card;
