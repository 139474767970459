import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from 'js/config';

export const initializeSentry = ({ t, history }) => {
  if (!config.sentry.enabled) {
    return;
  }

  Sentry.init({
    // The Sentry `dsn` and `tracesSampleRate` options are provided by the config:
    ...config.sentry.options,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', config.api.url, /^\//],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    beforeSend(event, context) {
      // Exclude page validation errors:
      if (isPageValidationErrorEvent(t, event)) {
        return null;
      }

      const isException = !!event.exception;
      const isCapturableError = isCapturableErrorEvent(event);

      const { captureContext = {} } = context;
      const { allowUserFeedback = true } = captureContext;

      // Show the report dialog only for unhandled exceptions
      // and uncaught 5xx-series API errors:
      if (isException && isCapturableError && allowUserFeedback) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return isCapturableError ? event : null;
    },
  });
};

export const captureExceptionSilently = (error, context = {}) => {
  Sentry.captureException(error, { ...context, allowUserFeedback: false });
};

export const captureApiErrorSilently = (error, context = {}) => {
  const { message, body = {} } = error;
  const { extra = {} } = context;
  captureExceptionSilently(new Error(message), {
    ...context,
    extra: { ...extra, ...error, body: JSON.stringify(body, null, 2) },
  });
};

export const getErrorDataFromEvent = (event) => {
  const { extra } = event;

  if (extra && typeof extra === 'object') {
    const { __serialized__: data } = extra;
    if (data && typeof data === 'object') {
      return data;
    }
  }

  return {};
};

export const isApiErrorEvent = (event) => {
  const { status } = getErrorDataFromEvent(event);
  return typeof status === 'number';
};

export const is5xxErrorEvent = (event) => {
  const { status } = getErrorDataFromEvent(event);
  return typeof status === 'number' && status >= 500;
};

export const isCapturableErrorEvent = (event) => {
  return !isApiErrorEvent(event) || is5xxErrorEvent(event);
};

export const isPageValidationErrorEvent = (t, event) => {
  if (event.exception) {
    const { values } = event.exception;
    if (Array.isArray(values)) {
      return !!values.find(
        ({ type, value }) =>
          type === 'Error' && value === t('common.pageValidationError')
      );
    }
  }
  return false;
};
