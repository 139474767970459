import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from 'js/components/design-system';
import ClipboardButton from 'js/components/controls/clipboard-button';

const ChequeRunReportItem = (props) => {
  const { t } = useTranslation();
  const { report = {} } = props;
  const {
    name = '',
    reportUri = '',
    HasError: hasError = false,
    ErrorResponse: errorResponse = {},
  } = report;

  const status = hasError
    ? t('components.ChequeRunResults.reports.status.failed')
    : t('components.ChequeRunResults.reports.status.complete');

  const commonElements = (
    <Fragment>
      <span>{name}</span>
      <span>{status}</span>
    </Fragment>
  );

  if (hasError) {
    return (
      <li>
        {commonElements}
        <ClipboardButton
          title={t('components.ChequeRunResults.copyError')}
          clipboardText={JSON.stringify(errorResponse, null, 2)}
        />
      </li>
    );
  } else {
    return (
      <li>
        {commonElements}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={reportUri}
          download={name}
        >
          <FontAwesomeIcon icon={faCloudDownloadAlt} />
          {t('components.ChequeRunResults.reports.download')}
        </a>
      </li>
    );
  }
};

ChequeRunReportItem.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ChequeRunReportItem;
