import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidationContext } from 'js/components/validation';
import {
  InputHelp,
  InputLabel,
  StyledSelect,
} from 'js/components/design-system';
import { onInputChangeEffect, onInputBlurEffect } from './effects';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';

const PayeeAddressForm = (props) => {
  const { t } = useTranslation();
  const { touch, isTouched } = useContext(ValidationContext);
  const { formData = {}, onChange, invalidFields } = props;
  const {
    payeeOtherPayeeName = '',
    payeeOtherAddress = '',
    payeeOtherAddress2 = '',
    payeeOtherCity = '',
    payeeOtherProv = '',
    payeeOtherPostalCode = '',
  } = formData;

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const provinces = provinceOptions(t);

  return (
    <div className="payee-form">
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeNameValidation'
          )}
          invalid={
            isTouched('payeeOtherPayeeName') &&
            invalidFields.includes('payeeOtherPayeeName')
          }
        >
          <InputLabel
            content={t('components.CaseInformation.PayeeAddressForm.payeeName')}
          >
            <input
              type="text"
              name="payeeOtherPayeeName"
              value={payeeOtherPayeeName}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeOtherAddressValidation'
          )}
          invalid={
            isTouched('payeeOtherAddress') &&
            invalidFields.includes('payeeOtherAddress')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.PayeeAddressForm.payeeOtherAddress'
            )}
          >
            <input
              type="text"
              name="payeeOtherAddress"
              value={payeeOtherAddress}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeOtherAddress2Validation'
          )}
          invalid={
            isTouched('payeeOtherAddress2') &&
            invalidFields.includes('payeeOtherAddress2')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.PayeeAddressForm.payeeOtherAddress2'
            )}
          >
            <input
              type="text"
              name="payeeOtherAddress2"
              value={payeeOtherAddress2}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeOtherCityValidation'
          )}
          invalid={
            isTouched('payeeOtherCity') &&
            invalidFields.includes('payeeOtherCity')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.PayeeAddressForm.payeeOtherCity'
            )}
          >
            <input
              type="text"
              name="payeeOtherCity"
              value={payeeOtherCity}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeOtherProvValidation'
          )}
          invalid={
            isTouched('payeeOtherProv') &&
            invalidFields.includes('payeeOtherProv')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.PayeeAddressForm.payeeOtherProv'
            )}
          >
            <StyledSelect
              value={payeeOtherProv}
              name="payeeOtherProv"
              onBlur={onInputBlurEffect({ touch })}
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t(
                  'components.LawyerInformation.LawyerAddressForm.provincePlaceholder'
                )}
              </option>
              {provinces.map((prov) => {
                return (
                  <option value={prov.value} key={prov.value}>
                    {prov.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.CaseInformation.PayeeAddressForm.payeeOtherPostalCodeValidation'
          )}
          invalid={
            isTouched('payeeOtherPostalCode') &&
            invalidFields.includes('payeeOtherPostalCode')
          }
        >
          <InputLabel
            content={t(
              'components.CaseInformation.PayeeAddressForm.payeeOtherPostalCode'
            )}
          >
            <input
              type="text"
              name="payeeOtherPostalCode"
              value={payeeOtherPostalCode}
              onChange={onInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
      </div>
    </div>
  );
};

PayeeAddressForm.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  invalidFields: PropTypes.array,
};

export default PayeeAddressForm;
