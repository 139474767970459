import classnames from 'classnames';
import {
  faCopy,
  faSync,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

export const CLIPBOARD_WRITE_STATUS = {
  UNKNOWN: 'unknown',
  ACTIVE: 'active',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const CLIPBOARD_WRITE_STATUS_RESET_TIME_MS = 1000;

export const clipboardStatusIconClassNames = (writeStatus) => {
  const dynamicClassNames = {
    'clipboard-status-icon-active':
      writeStatus === CLIPBOARD_WRITE_STATUS.ACTIVE,
    'clipboard-status-icon-success':
      writeStatus === CLIPBOARD_WRITE_STATUS.SUCCESS,
    'clipboard-status-icon-error': writeStatus === CLIPBOARD_WRITE_STATUS.ERROR,
    'fa-spin': writeStatus === CLIPBOARD_WRITE_STATUS.ACTIVE,
  };

  const className = classnames('clipboard-status-icon', dynamicClassNames);

  return className;
};

export const clipboardStatusIcon = (writeStatus) => {
  switch (writeStatus) {
    case CLIPBOARD_WRITE_STATUS.ACTIVE:
      return faSync;
    case CLIPBOARD_WRITE_STATUS.SUCCESS:
      return faCheck;
    case CLIPBOARD_WRITE_STATUS.ERROR:
      return faTimes;
    default:
      return faCopy;
  }
};
