import React from 'react';
import PropTypes from 'prop-types';
import {
  defaultDotRadius,
  defaultAnimationDurationMS,
  deg2rad,
  pointFromCentreOfCircle,
} from './functions';

const ActivityIndicatorDot = (props) => {
  const {
    index,
    numberOfDots,
    containerRadius,
    dotRadius,
    animationDurationMS,
  } = props;
  const animationDelayMS = (animationDurationMS / numberOfDots) * index;
  const circleRadius = containerRadius - dotRadius;
  const degrees = (360 / numberOfDots) * index;
  const { x, y } = pointFromCentreOfCircle(circleRadius, deg2rad(degrees));

  return (
    <div
      className="activity-indicator-dot"
      style={{
        top: y,
        left: x,
        width: dotRadius * 2,
        height: dotRadius * 2,
        borderRadius: '50%',
        animationDuration: `${animationDurationMS}ms`,
        animationDelay: `${animationDelayMS}ms`,
      }}
    />
  );
};

ActivityIndicatorDot.defaultProps = {
  dotRadius: defaultDotRadius,
  animationDurationMS: defaultAnimationDurationMS,
};

ActivityIndicatorDot.propTypes = {
  index: PropTypes.number.isRequired,
  numberOfDots: PropTypes.number.isRequired,
  containerRadius: PropTypes.number.isRequired,
  dotRadius: PropTypes.number,
  animationDurationMS: PropTypes.number,
};

export default ActivityIndicatorDot;
