import {
  CSQ_STRINGS,
  CSQ_DEFAULT_LANGUAGE,
} from 'js/components/pages/print/csq/language';
import { numQuestions } from 'js/components/pages/print/csq/functions';
import { stripNewlines } from 'js/utilities/strings';
import { caseNumberPattern, isEmpty } from 'js/utilities/validation';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { proceduresBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const queryForSearchString = (search = '') => {
  const { caseNumber = '' } = paramsFromSearchString(search);
  return queryForParams({ caseNumber });
};

export const csqEntryBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;

  return [
    ...proceduresBreadcrumbsHistory(t),
    {
      content: t('components.CSQEntry.breadcrumbs.csqEntry'),
      path: currentPath,
    },
  ];
};

export const focusInputRef = (inputRef) => {
  const { current: input } = inputRef;
  if (input) {
    input.focus();
  }
};

/* Questions/Answers */

export const CSQ_ENTRY_ANSWER_OPTIONS = {
  POSITIVE: 'positive',
  NEUTRAL: 'neutral',
  NEGATIVE: 'negative',
};

export const getCsqGeneralAnswers = (answers, includeNeutral = false) => ({
  [CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE]: answers.general.positive,
  [CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL]: answers.general.neutral,
  [CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE]: answers.general.negative,
  includeNeutral,
});

export const getCsqAnswers = (answers, group = '') => ({
  [CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE]: answers[group]?.positive || '',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL]: answers[group]?.neutral || '',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE]: answers[group]?.negative || '',
  includeNeutral: true,
});

export const getCsqAnswersForQuestionIndex = (answers, index) => {
  switch (index) {
    case 2:
      return getCsqAnswers(answers, 'clarity');
    case 3:
      return getCsqAnswers(answers, 'information');
    case 6:
    case 7:
      return getCsqAnswers(answers, 'satisfaction');
    case 8:
      return getCsqGeneralAnswers(answers, true);
    default:
      return getCsqGeneralAnswers(answers);
  }
};

export const getCsqQuestionsAndAnswers = (language = CSQ_DEFAULT_LANGUAGE) => {
  // Note: if the app is ever translated, the appropriate
  // CSQ strings will have to be selected for the current
  // UI language.
  // The strings are stored outside of the i18n dictionary
  // in order to support printing French and/or English
  // CSQs independent of the UI language. This function
  // uses the CSQ strings from the print layout to avoid
  // duplication.
  const strings = CSQ_STRINGS[language];

  if (!strings) {
    throw new Error(
      `getCsqQuestionsAndAnswers: There are no strings for language '${language}'`
    );
  }

  const { questions = {}, answers = {} } = strings;

  return [...new Array(numQuestions)].map((na, index) => {
    const questionNumber = index + 1;
    return {
      questionNumber: questionNumber.toString(),
      questionText: stripNewlines(questions[questionNumber]),
      answers: getCsqAnswersForQuestionIndex(answers, index),
    };
  });
};

/* Validation */

export const getCsqRadioValidationRules = () => {
  return [...new Array(numQuestions)].reduce((rules, na, idx) => {
    const questionNumber = (idx + 1).toString();
    const rule = (data, key) => !isEmpty(data[key]);
    return { ...rules, [questionNumber]: rule };
  }, {});
};

export const csqEntryValidationRules = {
  inputCaseNumber: caseNumberPattern,
  ...getCsqRadioValidationRules(),
  notes: (data, key) => {
    const value = data[key] || '';
    return value.length <= 1000;
  },
};

export const csqEntryRequiredFields = [
  ...Object.keys(getCsqRadioValidationRules()),
  'notes',
];

/* Transforms */

export const CSQ_ENTRY_ANSWER_MAP_YMN = {
  [CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE]: 'Y',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL]: 'M',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE]: 'N',
};

export const CSQ_ENTRY_ANSWER_MAP_VSN = {
  [CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE]: 'V',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL]: 'S',
  [CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE]: 'N',
};

export const CSQ_ENTRY_COLUMNS = {
  1: {
    column: 'lawyerCourtesy',
    answerMap: CSQ_ENTRY_ANSWER_MAP_YMN,
  },
  2: {
    column: 'otherCourtesy',
    answerMap: CSQ_ENTRY_ANSWER_MAP_YMN,
  },
  3: {
    column: 'explained',
    answerMap: CSQ_ENTRY_ANSWER_MAP_VSN,
  },
  4: {
    column: 'informed',
    answerMap: CSQ_ENTRY_ANSWER_MAP_VSN,
  },
  5: {
    column: 'questionsAnswered',
    answerMap: CSQ_ENTRY_ANSWER_MAP_YMN,
  },
  6: {
    column: 'time',
    answerMap: CSQ_ENTRY_ANSWER_MAP_YMN,
  },
  7: {
    column: 'lawyerSatisfaction',
    answerMap: CSQ_ENTRY_ANSWER_MAP_VSN,
  },
  8: {
    column: 'caseSatisfaction',
    answerMap: CSQ_ENTRY_ANSWER_MAP_VSN,
  },
  9: {
    column: 'nextCase',
    answerMap: CSQ_ENTRY_ANSWER_MAP_YMN,
  },
};

export const transformCsqAnswerToColumn = (questionNumber, answerOption) => {
  const { column, answerMap = {} } = CSQ_ENTRY_COLUMNS[questionNumber] || {};
  const value = answerMap[answerOption] || '';
  return { [column]: value };
};

export const transformCsqColumnToAnswer = (question, answer) => {
  const key = Object.keys(CSQ_ENTRY_COLUMNS).find(
    (key) => CSQ_ENTRY_COLUMNS[key].column === question
  );

  const { answerMap = {} } = CSQ_ENTRY_COLUMNS[key];
  const answerKey = Object.keys(answerMap).find(
    (key) => answerMap[key] === answer
  );

  let value = '';

  if (answerKey) {
    const uppercaseKey = answerKey.toUpperCase();
    if (CSQ_ENTRY_ANSWER_OPTIONS[uppercaseKey]) {
      value = CSQ_ENTRY_ANSWER_OPTIONS[uppercaseKey];
    }
  }

  return { [key]: value };
};

export const transformCsqToColumns = (csq = {}) => {
  const questionNumbers = Object.keys(csq).filter((key) => key !== 'notes');

  const questionColumns = questionNumbers.reduce((columns, num) => {
    const column = transformCsqAnswerToColumn(num, csq[num]);
    return { ...columns, ...column };
  }, {});

  return { ...questionColumns, notes: csq.notes || '' };
};

export const transformPreviousCSQAnswersToValues = (data = {}) => {
  //used to convert answers from the api into something the form can read to display
  const nextAnswerKeys = Object.keys(data).filter(
    (key) => key !== 'notes' && key !== 'date' && key !== 'caseId'
  );

  const nextAnswers = nextAnswerKeys.reduce((allAnswers, question) => {
    const answer = transformCsqColumnToAnswer(question, data[question]);
    return { ...allAnswers, ...answer };
  }, {});

  return { ...data, ...nextAnswers };
};
