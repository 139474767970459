export const paramsFromSearchString = (search = '') => {
  const pairs = search.replace(/^\?/, '').split('&');
  return pairs.reduce((next, pair) => {
    const [key, value = ''] = pair.split('=');
    return { ...next, [key]: decodeURIComponent(value) };
  }, {});
};

export const searchStringFromParams = (params = {}) => {
  const search = Object.keys(params)
    .filter((key) => params[key] !== '')
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
  return `?${search}`;
};

export const queryForParams = (params = {}) => {
  return { params, search: searchStringFromParams(params) };
};
