import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const login = async (client, request, useRedirectFlow = false) => {
  if (useRedirectFlow) {
    return client.loginRedirect(request);
  } else {
    return client.loginPopup(request);
  }
};

export const acquireToken = async (
  client,
  request,
  useRedirectFlow = false
) => {
  return client.acquireTokenSilent(request).catch((error) => {
    if (error instanceof InteractionRequiredAuthError) {
      if (useRedirectFlow) {
        return client.acquireTokenRedirect(request);
      } else {
        return client.acquireTokenPopup(request);
      }
    }
  });
};
