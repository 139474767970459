import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IndentedCard, FontAwesomeIcon } from 'js/components/design-system';
import { formatDate } from 'js/utilities/dates';

const LawyerFeedbackMetadata = (props) => {
  const { t } = useTranslation();
  const { className, includedFields = [], metadata = {}, linkTo } = props;
  const {
    prefix = '',
    firstName = '',
    lastName = '',
    cif = '',
    legalProblemCode = '',
    dateOpened,
    dateClosed,
  } = metadata;

  const name = `${prefix} ${firstName} ${lastName}`.trim();

  return (
    <IndentedCard
      className={classnames('lawyer-feedback-metadata', className)}
      linkTo={linkTo}
    >
      <div className="lawyer-feedback-metadata-content">
        <h1>{name}</h1>
        <div className="lawyer-feedback-metadata-row">
          {includedFields.includes('cif') && (
            <div className="lawyer-feedback-metadata-column">
              {t('components.LawyerFeedbackMetadata.cif')} {cif}
            </div>
          )}
          {includedFields.includes('legalProblemCode') && (
            <div className="lawyer-feedback-metadata-column">
              {t('components.LawyerFeedbackMetadata.legalProblemCode')}{' '}
              {legalProblemCode}
            </div>
          )}
          {includedFields.includes('dateOpened') && (
            <div className="lawyer-feedback-metadata-column">
              {t('components.LawyerFeedbackMetadata.dateOpened')}{' '}
              {formatDate(dateOpened)}
            </div>
          )}
          {includedFields.includes('dateClosed') && (
            <div className="lawyer-feedback-metadata-column">
              {t('components.LawyerFeedbackMetadata.dateClosed')}{' '}
              {formatDate(dateClosed)}
            </div>
          )}
        </div>
      </div>
      {linkTo && (
        <div className="lawyer-feedback-metadata-accessory">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </IndentedCard>
  );
};

LawyerFeedbackMetadata.propTypes = {
  className: PropTypes.string,
  includedFields: PropTypes.arrayOf(
    PropTypes.oneOf(['cif', 'legalProblemCode', 'dateOpened', 'dateClosed'])
  ),
  metadata: PropTypes.shape({
    prefix: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    cif: PropTypes.string,
    legalProblemCode: PropTypes.string,
    dateOpened: PropTypes.instanceOf(Date),
    dateClosed: PropTypes.instanceOf(Date),
  }).isRequired,
  linkTo: PropTypes.string,
};

export default LawyerFeedbackMetadata;
