import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { VersionInfoContext } from 'js/components/version-info';
import { formatDateFromISOString } from 'js/utilities/dates';
import { DATABASE_BACKUPS_DATE_FORMAT } from 'js/components/pages/database-backups/functions';

const Footer = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  const { versionInfo = {} } = useContext(VersionInfoContext);
  //consts formatted like this because endpoint may return nulls
  const environment = versionInfo.environment || '';
  const lastRestoreOperation = versionInfo.lastRestoreOperation || {};
  const sessionDetails = lastRestoreOperation.sessionDetails || {};
  const exportDetails = lastRestoreOperation.exportDetails || {};

  //date the reporting environment was updated
  const dateUpdated = formatDateFromISOString(
    sessionDetails.endTime,
    DATABASE_BACKUPS_DATE_FORMAT
  );
  //Date data was backed up
  const backupDate = formatDateFromISOString(
    exportDetails.endTime,
    DATABASE_BACKUPS_DATE_FORMAT
  );

  const STRONG_TAGS = {
    openStrong: '<strong>',
    closeStrong: '</strong>',
  };

  //footer currently appears only in the reporting environment
  const showFooter = environment.includes('reporting');
  if (showFooter) {
    return (
      <footer className={classnames('footer', className)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('components.Footer.environment', {
              environment,
              ...STRONG_TAGS,
            }),
          }}
        />
        {backupDate && (
          <div
            dangerouslySetInnerHTML={{
              __html: t('components.Footer.backupDate', {
                backupDate,
                ...STRONG_TAGS,
              }),
            }}
          />
        )}
        {dateUpdated && (
          <div
            dangerouslySetInnerHTML={{
              __html: t('components.Footer.dateUpdated', {
                dateUpdated,
                ...STRONG_TAGS,
              }),
            }}
          />
        )}
      </footer>
    );
  } else {
    return <div />;
  }
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
