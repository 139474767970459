import {
  resolveFieldsArray,
  getTouchedStateForFields,
  executeValidationRules,
} from './functions';

export const validateEffect = (options = {}) => {
  const { rules = {}, debug = false } = options;
  return (data = {}, fieldOrFields) => {
    const fields = resolveFieldsArray(fieldOrFields);
    if (fields.length > 0) {
      return executeValidationRules(rules, data, fields, debug);
    } else {
      return false;
    }
  };
};

export const touchEffect = (options = {}) => {
  const { setTouched } = options;
  return (fieldOrFields) => {
    const fields = resolveFieldsArray(fieldOrFields);
    if (fields.length > 0) {
      setTouched((touched) => ({
        ...touched,
        ...getTouchedStateForFields(fields, true),
      }));
    }
  };
};

export const untouchEffect = (options = {}) => {
  const { setTouched } = options;
  return (fieldOrFields) => {
    const fields = resolveFieldsArray(fieldOrFields);
    if (fields.length > 0) {
      setTouched((touched) => ({
        ...touched,
        ...getTouchedStateForFields(fields, false),
      }));
    }
  };
};

export const isTouchedEffect = (options = {}) => {
  const { touched } = options;
  return (fieldOrFields) => {
    const fields = resolveFieldsArray(fieldOrFields);
    if (fields.length > 0) {
      return fields.reduce((next, field) => {
        return next && touched[field] === true;
      }, true);
    }
  };
};
