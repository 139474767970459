import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import NavigationModal from './modal';

const NavigationSaveModal = (props) => {
  const { t } = useTranslation();
  const { className, ...modalProps } = props;
  return (
    <NavigationModal
      className={classnames('navigation-save-modal', className)}
      titleText={t('components.NavigationSaveModal.titleText')}
      messageText={t('components.NavigationSaveModal.messageText')}
      proceedButtonText={t('components.NavigationSaveModal.proceedButtonText')}
      {...modalProps}
    />
  );
};

NavigationSaveModal.propTypes = {
  ...NavigationModal.propTypes,
};

export default NavigationSaveModal;
