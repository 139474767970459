import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faPhone,
  faAt,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  IndentedCard,
  FontAwesomeIcon,
  LabelValuePair,
} from 'js/components/design-system';
import { routePathForClientOrDependent } from 'js/utilities/clients';
import { clientSummaryMode } from './functions';

const ClientSummary = (props) => {
  const { t } = useTranslation();
  const {
    className,
    client = {},
    accessory,
    mode,
    headerAccessory,
    disableNavigation = false,
  } = props;
  const {
    clientId,
    clientCode,
    clientStatus,
    prefix,
    firstName,
    lastName,
    dateOfBirth,
    employeeNumber,
    companyName,
    localCode,
    localName,
    feeScheduleId,
    feeScheduleName,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    phoneNumber,
    emailAddress,
    seniorityDate,
    version,
  } = client;

  const linkTo = routePathForClientOrDependent(clientId, clientCode);

  return (
    <IndentedCard className={classnames('client-summary', className)}>
      <div className="client-summary-content">
        <div className="client-summary-heading">
          {disableNavigation ? (
            <h1>{`${prefix} ${firstName} ${lastName}`.trim()}</h1>
          ) : (
            <Link to={linkTo}>
              <h1>{`${prefix} ${firstName} ${lastName}`.trim()}</h1>
            </Link>
          )}
          {headerAccessory && headerAccessory}
        </div>
        <div className="client-summary-body">
          <div className="client-summary-column">
            <LabelValuePair
              label={t('components.ClientSummary.clientId')}
              value={`${clientId}-${clientCode}`}
            />
            {mode === clientSummaryMode.full && (
              <Fragment>
                <LabelValuePair
                  label={t('components.ClientSummary.dateOfBirth')}
                  value={dateOfBirth}
                />
                <div>&nbsp;</div>
                {addressLine1 && <div>{addressLine1}</div>}
                {addressLine2 && <div>{addressLine2}</div>}
                <div>{`${city}, ${province} ${postalCode}`}</div>
              </Fragment>
            )}
          </div>

          <div className="client-summary-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                {companyName && <div>{companyName}</div>}
                <LabelValuePair
                  label={t('components.ClientSummary.local')}
                  value={`${localCode} ${localName}`}
                />
                <LabelValuePair
                  label={t('components.ClientSummary.seniorityDate')}
                  value={seniorityDate}
                />
                <LabelValuePair
                  label={t('components.ClientSummary.employeeNumber')}
                  value={employeeNumber}
                />
              </Fragment>
            )}
          </div>

          <div className="client-summary-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                <LabelValuePair
                  label={t('components.ClientSummary.clientStatus')}
                  value={`${clientStatus} \u2013 ${t(
                    `common.clientStatusByCode.${clientStatus}`
                  )}`}
                />
                <LabelValuePair
                  label={t('components.ClientSummary.feeSchedule')}
                  value={`${feeScheduleId} \u2013 ${feeScheduleName}`}
                />
                <LabelValuePair
                  label={t('components.ClientSummary.version')}
                  value={version}
                />
              </Fragment>
            )}
          </div>
          <div className="client-summary-column">
            {mode === clientSummaryMode.full && (
              <Fragment>
                {phoneNumber && (
                  <LabelValuePair
                    label={<FontAwesomeIcon icon={faPhone} />}
                    value={phoneNumber}
                  />
                )}
                {emailAddress && (
                  <LabelValuePair
                    label={<FontAwesomeIcon icon={faAt} />}
                    value={
                      <a className="link" href={`mailto:${emailAddress}`}>
                        {emailAddress}
                      </a>
                    }
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {!disableNavigation && (
        <Link to={linkTo} className="client-summary-accessory">
          {accessory}
        </Link>
      )}
    </IndentedCard>
  );
};

ClientSummary.defaultProps = {
  accessory: <FontAwesomeIcon icon={faChevronRight} />,
  mode: clientSummaryMode.full,
  disableNavigation: false,
};

ClientSummary.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  accessory: PropTypes.node,
  headerAccessory: PropTypes.node,
  mode: PropTypes.oneOf(Object.values(clientSummaryMode)),
  disableNavigation: PropTypes.bool,
};

export default ClientSummary;
