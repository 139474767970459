import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { routePaths } from 'js/components/router/route-paths';
import { AdminListCard } from 'js/components/design-system';
import AdminToolsBreadcrumbs from './breadcrumbs';

const AdminToolsPage = () => {
  const { t } = useTranslation();

  return (
    <Page
      className="admin-page"
      title={t('components.AdminPage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <AdminToolsBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="admin-page-heading">
            <h1>{t('components.AdminPage.title')}</h1>
          </div>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <AdminListCard
            linkTo={routePaths.staff}
            heading={t('components.AdminPage.staff')}
          />
          <AdminListCard
            linkTo={routePaths.companies}
            heading={t('components.AdminPage.companies')}
          />
          <AdminListCard
            linkTo={routePaths.systemSettings}
            heading={t('components.AdminPage.systemSettings')}
          />
        </div>
        <div className="layout-column">
          <AdminListCard
            linkTo={routePaths.unions}
            heading={t('components.AdminPage.unions')}
          />
          <AdminListCard
            linkTo={routePaths.lawFirms}
            heading={t('components.AdminPage.lawFirms')}
          />
          <AdminListCard
            linkTo={routePaths.feeSchedulesActive}
            heading={t('components.AdminPage.feeSchedules')}
          />
        </div>
      </div>
    </Page>
  );
};

export default AdminToolsPage;
