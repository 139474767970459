export const onKeyDownEffect = (options = {}) => {
  const { onConfirm } = options;
  return (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onConfirm(e);
    }
  };
};

export const onChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
};
