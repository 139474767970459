import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { systemSettingsBreadcrumbsHistory } from 'js/utilities/admin-tools';

const SystemSettingsBreadcrumbs = () => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="system-settings-breadcrumbs"
      history={systemSettingsBreadcrumbsHistory(t)}
    />
  );
};

export default SystemSettingsBreadcrumbs;
