import React, { useEffect, useState, useContext, useRef } from 'react';
import CSQPrintLayout from './csq-print-layout';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import Page from 'js/components/page';
import { resolveMessageForErrorCode } from 'js/utilities/errors';
import { getMultipleCSQDataEffect, multiplePrintViewEffect } from './effects';
import { languageForCSQ } from './functions';

const PrintMultipleCSQ = () => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [CSQDataArray, setCSQDataArray] = useState([]);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { params = {} } = useRouteMatch();
  const { date } = params;
  const getCsqAbortControllerRef = useRef(null);

  const getCSQDataEffectOptions = {
    t,
    api,
    date,
    setCSQDataArray,
    setActive,
    setError,
    getCsqAbortControllerRef,
  };

  useEffect(getMultipleCSQDataEffect(getCSQDataEffectOptions), []);
  useEffect(multiplePrintViewEffect({ CSQDataArray, active }), [
    CSQDataArray.length,
    active,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.csqData', {
            message: resolveMessageForErrorCode(error, error.message, {
              1: t('components.Print.errors.userNotFound'),
              2: t('components.Print.errors.userNoOffice'),
              3: t('components.Print.errors.userNotHeadOffice'),
            }),
          })}
        </p>
      )}
      {!active &&
        !error &&
        CSQDataArray.map((csqData, key) => (
          <CSQPrintLayout
            key={key}
            csqData={csqData}
            language={languageForCSQ(csqData)}
          />
        ))}
      {!active && !error && CSQDataArray.length === 0 && (
        <EmptyState
          title={t(`components.dayEnd.emptyStateTitle`)}
          subtitle={t(`components.dayEnd.emptyStateSubtitle`, {
            reportType: 'CSQ',
            date: decodeURIComponent(date),
          })}
        />
      )}
    </Page>
  );
};

export default PrintMultipleCSQ;
