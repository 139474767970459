import React, { useContext, useEffect } from 'react';
import { ValidationContext } from 'js/components/validation';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  InputLabel,
  Card,
  InputHelp,
} from 'js/components/design-system';
import PostalCodeInput, {
  onPostalCodeInputChangeEffect,
} from 'js/components/controls/postal-code-input';
import { provinceOptions } from 'js/hardcoded-data/form-option-data';
import {
  onInputChangeEffect,
  onInputBlurEffect,
  touchAllOnSubmitEffect,
} from './effects';
import PhoneInput from 'js/components/controls/phone-input';

const Spacer = () => <div />;

const UnionForm = (props) => {
  const { t } = useTranslation();
  const {
    formData = {},
    onChange,
    invalidFields,
    didSubmit = false,
    requiredFields,
    isNew = false,
  } = props;
  const provinces = provinceOptions(t);
  const { touch, isTouched } = useContext(ValidationContext);
  const {
    unionNumber = '',
    unionName = '',
    locAddr1 = '',
    locAddr2 = '',
    locCity = '',
    locProv = '',
    locPcode = '',
    locPhone1 = '',
    locPhone2 = '',
    locFax = '',
  } = formData;

  const inputEventHandlers = {
    onChange: onInputChangeEffect({ onChange }),
    onBlur: onInputBlurEffect({ touch }),
  };

  const touchAllFields = () => touch(requiredFields);

  useEffect(
    touchAllOnSubmitEffect({
      didSubmit,
      touchAllFields,
    }),
    [didSubmit]
  );

  return (
    <Card className="union-details-form">
      <h2>{t('components.UnionDetailsPage.formTitle')}</h2>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.unionNumberValidation'
          )}
          invalid={
            isTouched('unionNumber') && invalidFields.includes('unionNumber')
          }
        >
          <InputLabel content={t('components.UnionDetailsPage.unionNumber')}>
            <input
              type="text"
              name="unionNumber"
              value={unionNumber}
              readOnly={!isNew}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.unionNameValidation'
          )}
          invalid={
            isTouched('unionName') && invalidFields.includes('unionName')
          }
        >
          <InputLabel content={t('components.UnionDetailsPage.unionName')}>
            <input
              type="text"
              name="unionName"
              value={unionName}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.locPhoneValidation'
          )}
          invalid={
            isTouched('locPhone1') && invalidFields.includes('locPhone1')
          }
        >
          <InputLabel content={t('components.UnionDetailsPage.locPhone1')}>
            <PhoneInput
              name="locPhone1"
              value={locPhone1}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.locPhoneValidation'
          )}
          invalid={
            isTouched('locPhone2') && invalidFields.includes('locPhone2')
          }
        >
          <InputLabel content={t('components.UnionDetailsPage.locPhone2')}>
            <PhoneInput
              name="locPhone2"
              value={locPhone2}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.UnionDetailsPage.locFaxValidation')}
          invalid={isTouched('locFax') && invalidFields.includes('locFax')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locFax')}>
            <PhoneInput
              name="locFax"
              value={locFax}
              {...inputEventHandlers}
              onChange={(name, value) => onChange(name, value)}
            />
          </InputLabel>
        </InputHelp>

        {/*SECOND ROW*/}
      </div>
      <div className="form-row">
        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.locAddr1Validation'
          )}
          invalid={isTouched('locAddr1') && invalidFields.includes('locAddr1')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locAddr1')}>
            <input
              type="text"
              name="locAddr1"
              value={locAddr1}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.locAddr2Validation'
          )}
          invalid={isTouched('locAddr2') && invalidFields.includes('locAddr2')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locAddr2')}>
            <input
              name="locAddr2"
              type="text"
              value={locAddr2}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.UnionDetailsPage.locCityValidation')}
          invalid={isTouched('locCity') && invalidFields.includes('locCity')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locCity')}>
            <input
              name="locCity"
              type="text"
              value={locCity}
              {...inputEventHandlers}
            />
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t('components.UnionDetailsPage.locProvValidation')}
          invalid={isTouched('locProv') && invalidFields.includes('locProv')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locProv')}>
            <StyledSelect
              value={locProv}
              name="locProv"
              {...inputEventHandlers}
            >
              <option value="" disabled>
                {t('common.defaultSelectPlaceholder')}
              </option>
              {provinces.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </StyledSelect>
          </InputLabel>
        </InputHelp>

        <InputHelp
          validationContent={t(
            'components.UnionDetailsPage.locPcodeValidation'
          )}
          invalid={isTouched('locPcode') && invalidFields.includes('locPcode')}
        >
          <InputLabel content={t('components.UnionDetailsPage.locPcode')}>
            <PostalCodeInput
              name="locPcode"
              value={locPcode}
              onChange={onPostalCodeInputChangeEffect({ onChange })}
              onBlur={onInputBlurEffect({ touch })}
            />
          </InputLabel>
        </InputHelp>
        <Spacer />
      </div>
    </Card>
  );
};

UnionForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  invalidFields: PropTypes.array,
  didSubmit: PropTypes.bool,
  requiredFields: PropTypes.array,
  isNew: PropTypes.bool,
};

export default UnionForm;
