import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { feedbackSortOptions } from './functions';

const FeedbackSortMenu = (props) => (
  <SortMenu
    className="feedback-sort-menu"
    sortOptions={feedbackSortOptions}
    {...props}
  />
);

FeedbackSortMenu.defaultProps = {
  field: 'timestamp',
  direction: 'desc',
};

FeedbackSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FeedbackSortMenu;
