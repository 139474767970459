import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/free-solid-svg-icons';

export const feedbackSortOptions = [
  {
    key: 'date-asc',
    field: 'timestamp',
    direction: 'asc',
    i18nKey: 'components.FeedbackSortMenu.dateAsc',
    icon: faSortAmountDown,
  },
  {
    key: 'date-desc',
    field: 'timestamp',
    direction: 'desc',
    i18nKey: 'components.FeedbackSortMenu.dateDesc',
    icon: faSortAmountDownAlt,
  },
  {
    key: 'author-asc',
    field: 'author',
    direction: 'asc',
    i18nKey: 'components.FeedbackSortMenu.authorAsc',
    icon: faSortAlphaDown,
  },
  {
    key: 'author-desc',
    field: 'author',
    direction: 'desc',
    i18nKey: 'components.FeedbackSortMenu.authorDesc',
    icon: faSortAlphaDownAlt,
  },
];
