import React, { Fragment, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import builtByRedBit from 'assets/built-black.png';
import config from 'js/config';
import Modal from 'js/components/modal';
import { VersionInfoContext } from './context';
import { setMountedEffect } from './effects';

const VersionInfoModal = (props) => {
  const { t } = useTranslation();

  const [mounted, setMounted] = useState(false);
  const { hasLoaded, versionInfo = {} } = useContext(VersionInfoContext);
  const { setMenuVisibility, isMenuVisible = false } = props;

  const {
    apiVersion = '',
    reportingRestoreEnvironment = {},
    databaseDetails = {},
    environment = '',
  } = versionInfo;

  const { apiServer = '', webServer = '' } = reportingRestoreEnvironment;
  const {
    sourceDatabase = '',
    restoreDestinationDatabase = '',
    enabled: databaseEnabled = false,
  } = databaseDetails;

  if (hasLoaded && apiVersion) {
    return (
      <Fragment>
        <li
          role="menuitem"
          className={'header-menu-link'}
          tabIndex={isMenuVisible ? 0 : -1}
          onClick={setMountedEffect({ setMounted, setMenuVisibility })}
        >
          {t('components.VersionInfoModal.buttonText')}
        </li>
        <Modal
          className="version-info-modal"
          onClickCancel={() => setMounted(false)}
          titleText={t('components.VersionInfoModal.title')}
          cancelButtonText={t('common.close')}
          mounted={mounted}
          underlayClickExits={false}
          verticallyCenter
          focusTrapPaused
        >
          <h2>{t('components.VersionInfoModal.title')}</h2>
          <div className="version-info-modal-content">
            <h3>{t('components.VersionInfoModal.version.title')}</h3>
            <ul>
              <li>
                <strong>
                  {t('components.VersionInfoModal.version.enviroment')}
                </strong>{' '}
                <span>{environment}</span>
              </li>
              <li>
                <strong>{t('components.VersionInfoModal.version.api')}</strong>{' '}
                <span>{apiVersion}</span>
              </li>
              <li>
                <strong>{t('components.VersionInfoModal.version.web')}</strong>{' '}
                <span>{config.build}</span>
              </li>
            </ul>
            <h3>{t('components.VersionInfoModal.reporting.title')}</h3>
            <ul>
              <li>
                <strong>
                  {t('components.VersionInfoModal.reporting.apiServer')}
                </strong>{' '}
                <span>{apiServer}</span>
              </li>
              <li>
                <strong>
                  {t('components.VersionInfoModal.reporting.webServer')}
                </strong>{' '}
                <span>{webServer}</span>
              </li>
            </ul>
            <h3>{t('components.VersionInfoModal.database.title')}</h3>
            <ul>
              <li>
                <strong>
                  {t('components.VersionInfoModal.database.source')}
                </strong>{' '}
                <span>{sourceDatabase || t('common.na')}</span>
              </li>
              <li>
                <strong>
                  {t('components.VersionInfoModal.database.destination')}
                </strong>{' '}
                <span>{restoreDestinationDatabase || t('common.na')}</span>
              </li>
              <li>
                <strong>
                  {t('components.VersionInfoModal.database.enabled')}
                </strong>{' '}
                <span>{databaseEnabled.toString()}</span>
              </li>
            </ul>
            <div className="version-info-modal-credit">
              <a href="http://www.redbitdev.com/?utm_source=uniforlsp_case">
                <img
                  src={builtByRedBit}
                  alt={t('components.VersionInfoModal.credit')}
                />
              </a>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default VersionInfoModal;
