import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { issueChequeBreadcrumbsHistory } from './functions';

const IssueChequeBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      className="issue-cheque-breadcrumbs"
      history={issueChequeBreadcrumbsHistory(t, location)}
    />
  );
};

export default IssueChequeBreadcrumbs;
