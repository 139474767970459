export const debugMessages = {
  getRecord: 'DataContext: get record',
  getRecordMaxAgeExceeded: 'DataContext: get record - max age exceeded',
  setRecord: 'DataContext: set record',
  deleteRecord: 'DataContext: delete record',
  deleteRecords: 'DataContext: delete multiple records',
  addListener: 'DataContext: add listener',
  removeListener: 'DataContext: remove listener',
};

export const defaultDataDebugger = (enabled) => {
  // eslint-disable-next-line no-console
  return enabled ? console.log : undefined;
};
