import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from 'js/components/data';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import { NavigationSaveModal } from 'js/components/navigation-modal';
import {
  InteractionTrackingProvider,
  InteractionTrackingContext,
} from 'js/components/interaction-tracking';
import { StyledBannerContext } from 'js/components/banner-styled';
import { LawFirmSummary } from 'js/components/law-firm-summary';
import {
  FAB,
  FABPosition,
  FABOffset,
  FontAwesomeIcon,
} from 'js/components/design-system';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import {
  returnlawyerTypeFromId,
  LAWYER_TYPE_ENUM,
  isNewLawyerPath,
  getLawyerInformationValidationRules,
  getLawyerInformationRequiredFields,
} from 'js/utilities/lawyers';
import LawyerPage from '../lawyer-page';
import SelectLawfirmModal from '../select-lawfirm-modal';
import { LawyerContext, LawyerProvider } from '../lawyer-context';
import { lawyerInformationNamedPermissions } from '../lawyer-named-permissions';
import {
  LawyerAddressForm,
  LawyerInformationForm,
  LawyerBillingForm,
  StaffLawyerInformationForm,
} from '../lawyer-forms';
import {
  onMountEffect,
  getLawyerOptionsEffect,
  updateLawyerInformationEffect,
  patchLawyerInformationEffect,
  postLawyerInformationEffect,
  saveLawyerEffect,
  selectLawFirmEffect,
  setBarristerEffect,
  patchStaffLawyerInformationEffect,
  openLawyerModalEffect,
} from './effects';
import { getSaveButtonTitle } from './functions';

const LawyerInformation = () => {
  const getLawyerOptionsAbortControllerRef = useRef(null);
  const patchLawyerInformationAbortControllerRef = useRef(null);
  const postLawyerInformationAbortControllerRef = useRef(null);
  const patchStaffLawyerInformationAbortControllerRef = useRef(null);

  const history = useHistory();
  const { path, params } = useRouteMatch();
  const { lawyerId } = params;
  const { t } = useTranslation();

  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { validate, touch } = useContext(ValidationContext);
  const {
    interactionCount,
    incrementInteractionCount,
    resetInteractionCount,
  } = useContext(InteractionTrackingContext);

  const { lawyer, setLawyer } = useContext(LawyerContext);

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasEditPermission = hasNamedPermission('editLawyerInformation');

  const [isLoadingOptions, setLoadingOptions] = useState(false);
  const [isSavingLawyer, setSavingLawyer] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [isLawFirmSummaryEditing, setLawFirmSummaryEditing] = useState(false);
  const [options, setOptions] = useState({});
  const [redirectPath, setRedirectPath] = useState('');
  const { lawyerInfo = {} } = lawyer;
  const { staffId = '' } = lawyerInfo;
  const isNew = isNewLawyerPath(path);
  const lawyerApproxType = returnlawyerTypeFromId(lawyerId);
  const isStaffLawyer = lawyerApproxType === LAWYER_TYPE_ENUM.STAFF;
  const isActive = isLoadingOptions || isSavingLawyer;
  const requiredFields = getLawyerInformationRequiredFields(
    isNew,
    isStaffLawyer
  );
  const { isValid } = validate(lawyerInfo, requiredFields);
  const touchAllFields = () => touch(requiredFields);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getLawyerOptionsAbortControllerRef,
        patchLawyerInformationAbortControllerRef,
        postLawyerInformationAbortControllerRef,
        patchStaffLawyerInformationAbortControllerRef,
      ],
      setLoadingOptions,
      setSavingLawyer,
    }),
    []
  );

  useEffect(
    getLawyerOptionsEffect({
      t,
      api,
      setOptions,
      setLoadingOptions,
      getLawyerOptionsAbortControllerRef,
    }),
    []
  );

  const patchLawyer = patchLawyerInformationEffect({
    t,
    api,
    cache,
    lawyerId,
    isValid,
    lawyer,
    setSavingLawyer,
    touchAllFields,
    resetInteractionCount,
    patchLawyerInformationAbortControllerRef,
  });

  const patchStaffLawyer = patchStaffLawyerInformationEffect({
    t,
    api,
    cache,
    staffId,
    isValid,
    lawyer,
    setSavingLawyer,
    touchAllFields,
    resetInteractionCount,
    patchStaffLawyerInformationAbortControllerRef,
  });

  const postLawyer = postLawyerInformationEffect({
    t,
    api,
    isValid,
    lawyer,
    setSavingLawyer,
    touchAllFields,
    setRedirectPath,
    resetInteractionCount,
    postLawyerInformationAbortControllerRef,
  });

  const patchAction = isStaffLawyer ? patchStaffLawyer : patchLawyer;

  const saveLawyer = saveLawyerEffect({
    t,
    saveEffect: isNew ? postLawyer : patchAction,
    presentStyledBanner,
  });

  const updateLawyer = updateLawyerInformationEffect({
    lawyer,
    setLawyer,
    group: 'lawyerInfo',
  });

  const openModal = openLawyerModalEffect({
    setMounted,
    resetInteractionCount,
  });

  const actionsDisabled =
    isActive || !hasEditPermission || isLawFirmSummaryEditing;

  return (
    <LawyerPage
      className="lawyer-information"
      title={t('components.LawyerInformation.title')}
      actions={
        <Fragment>
          <button
            className="button button-highlight page-action-button"
            onClick={() =>
              history.push(
                routePathReplacingParams(routePaths.lawyerPrint, { lawyerId })
              )
            }
            disabled={isActive}
          >
            {t('common.print')}
          </button>
          <button
            className="button button-highlight page-action-button"
            onClick={saveLawyer}
            disabled={actionsDisabled}
          >
            {getSaveButtonTitle(t, isNew, isSavingLawyer)}
          </button>
        </Fragment>
      }
    >
      {redirectPath && <Redirect to={redirectPath} />}
      {hasEditPermission && (
        <SelectLawfirmModal
          mounted={mounted}
          onClose={() => setMounted(false)}
          onSelectFirm={selectLawFirmEffect({
            lawyer,
            setLawyer,
            setMounted,
            incrementInteractionCount,
          })}
          onSelectBarrister={setBarristerEffect({
            lawyer,
            setLawyer,
            setMounted,
            incrementInteractionCount,
          })}
        />
      )}
      <NavigationSaveModal
        proceedAfter={async () => await saveLawyer()}
        shouldBlockNavigation={() => hasEditPermission && interactionCount > 0}
      />
      <form
        onSubmit={(e) => e.preventDefault()}
        onClick={incrementInteractionCount}
      >
        {isStaffLawyer && (
          <StaffLawyerInformationForm
            formData={lawyerInfo}
            onChange={updateLawyer}
            isStaffLawyer={isStaffLawyer}
          />
        )}
        {!isStaffLawyer && (
          <Fragment>
            <LawyerInformationForm
              formData={lawyerInfo}
              onChange={updateLawyer}
              isNew={isNew}
              options={options}
              isStaffLawyer={isStaffLawyer}
            />
            <LawFirmSummary
              lawyer={lawyer}
              isBarristerSolicitor={lawyerInfo.barrister}
              isStaffLawyer={isStaffLawyer}
              isEditingDisabled={isActive || !hasEditPermission}
              onBeginEditing={() => setLawFirmSummaryEditing(true)}
              onCancelEditing={() => setLawFirmSummaryEditing(false)}
              onCommitEditing={(nextLawyer) => {
                setLawyer(nextLawyer);
                setLawFirmSummaryEditing(false);
              }}
            />
            <LawyerAddressForm
              formData={lawyerInfo}
              onChange={updateLawyer}
              isStaffLawyer={isStaffLawyer}
              isBarristerSolicitor={lawyerInfo.barrister}
            />
            <LawyerBillingForm
              formData={lawyerInfo}
              onChange={updateLawyer}
              isNew={isNew}
              isStaffLawyer={isStaffLawyer}
              isBarristerSolicitor={lawyerInfo.barrister}
            />
          </Fragment>
        )}
      </form>
      {!isStaffLawyer && (
        <Fragment>
          <FABPosition>
            <FAB
              actions={[
                {
                  icon: <FontAwesomeIcon icon={faBuilding} />,
                  title: t('components.LawyerPage.lawFirmModal.chooseButton'),
                  onClick: openModal,
                  disabled: actionsDisabled,
                },
              ]}
            />
          </FABPosition>
          <FABOffset />
        </Fragment>
      )}
    </LawyerPage>
  );
};

const LawyerInformationWithContext = (props) => (
  <LawyerProvider>
    <LawyerInformation {...props} />
  </LawyerProvider>
);

const LawyerInformationWithNamedPermissions = (props) => (
  <NamedPermissionsProvider
    namedPermissions={lawyerInformationNamedPermissions}
  >
    <LawyerInformationWithContext {...props} />
  </NamedPermissionsProvider>
);

const ValidatedLawyerInformation = (props) => {
  const { path, params } = useRouteMatch();
  const isNew = isNewLawyerPath(path);
  const { lawyerId } = params;
  const lawyerApproxType = returnlawyerTypeFromId(lawyerId);
  const isStaffLawyer = lawyerApproxType === LAWYER_TYPE_ENUM.STAFF;

  const rules = getLawyerInformationValidationRules(isNew, isStaffLawyer);
  return (
    <ValidationProvider rules={rules}>
      <LawyerInformationWithNamedPermissions {...props} />
    </ValidationProvider>
  );
};

const InteractionTrackingLawyerInformation = (props) => (
  <InteractionTrackingProvider>
    <ValidatedLawyerInformation {...props} />
  </InteractionTrackingProvider>
);

export default InteractionTrackingLawyerInformation;
