export const isPendingChequeRunError = (errorCode) =>
  errorCode === '0x00000001' || errorCode === 1;

export const getErrorCodeAndDescription = (t, error) => {
  const { body = {} } = error;
  const { meta_data: metaData = {} } = body;
  const {
    error_code: errorCode = '',
    error_description: errorDescription = t('common.errors.unknown'),
  } = metaData;
  return { errorCode, errorDescription };
};
