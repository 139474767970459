import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'js/components/modal';

const Prompt = (props) => {
  const { t } = useTranslation();
  const {
    className,
    mounted,
    modalProps,
    message,
    content,
    allowConfirm,
    confirmButtonTitle = t('common.ok'),
    confirmButtonClassName,
    onConfirm,
    allowCancel,
    cancelButtonTitle = t('common.cancel'),
    cancelButtonClassName,
    onCancel,
  } = props;

  return (
    <Modal
      className={classnames('prompt-modal', className)}
      titleText={t('common.promptTitle')}
      mounted={mounted}
      allowCancel={allowCancel}
      onClickCancel={onCancel}
      underlayClickExits={false}
      verticallyCenter
      focusTrapPaused
      {...modalProps}
    >
      <div className={classnames('prompt', className)}>
        {message && <p>{message}</p>}
        {content && <div className="prompt-content">{content}</div>}
        <div className="button-bar">
          {allowCancel && (
            <button className={cancelButtonClassName} onClick={onCancel}>
              {cancelButtonTitle}
            </button>
          )}
          {allowConfirm && (
            <button className={confirmButtonClassName} onClick={onConfirm}>
              {confirmButtonTitle}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

Prompt.defaultProps = {
  mounted: false,
  modalProps: {},
  message: '',
  allowConfirm: true,
  confirmButtonClassName: 'button button-highlight',
  allowCancel: true,
  cancelButtonClassName: 'button-link-appearance',
};

Prompt.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  modalProps: PropTypes.object,
  message: PropTypes.string,
  content: PropTypes.node,
  allowConfirm: PropTypes.bool,
  confirmButtonTitle: PropTypes.string,
  confirmButtonClassName: PropTypes.string,
  onConfirm: PropTypes.func,
  allowCancel: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  cancelButtonClassName: PropTypes.string,
  onCancel: PropTypes.func,
};

export default Prompt;
