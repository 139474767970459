import React, { useState, useEffect, useRef } from 'react';
import { SortableTable } from '../components/tables';

const contentUpdateInterval = 10000;

const initialColumns = [
  { key: 'one', headerContent: 'One' },
  { key: 'two', headerContent: 'Two' },
  { key: 'three', headerContent: 'Three' },
  { key: 'four', headerContent: 'Four' },
];

const finalColumns = [
  { key: 'one', headerContent: 'One' },
  { key: 'two', headerContent: 'Two' },
  { key: 'three', headerContent: 'Three' },
  { key: 'four', headerContent: 'Four' },
  { key: 'five', headerContent: 'Five', sortable: false },
];

const initialData = [
  { one: 'A', two: 'B', three: 'C', four: 'D' },
  { one: 'B', two: 'C', three: 'D', four: 'A' },
  { one: 'C', two: 'D', three: 'A', four: 'B' },
  { one: 'D', two: 'A', three: 'B', four: 'C' },
];

const finalData = [
  { one: 'A', two: 'B', three: 'C', four: 'D', five: 'E' },
  { one: 'B', two: 'C', three: 'D', four: 'E', five: 'A' },
  { one: 'C', two: 'D', three: 'E', four: 'A', five: 'B' },
  { one: 'D', two: 'E', three: 'A', four: 'B', five: 'C' },
  { one: 'E', two: 'A', three: 'B', four: 'C', five: 'D' },
];

const Tables = () => {
  const timeoutRef = useRef(0);
  const [showFinal, setShowFinal] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () => setShowFinal(!showFinal),
      contentUpdateInterval
    );
    return () => clearTimeout(timeoutRef.current);
  }, [showFinal]);

  const columns = showFinal ? finalColumns : initialColumns;
  const data = showFinal ? finalData : initialData;

  return (
    <div className="layout-container layout-container-no-offset">
      <div className="layout-column">
        <h1>Tables</h1>

        <h2>Sortable</h2>
        <p>Content updates every {contentUpdateInterval / 1000} seconds.</p>
        <SortableTable
          columns={columns}
          data={data}
          initialSortColumnKey={'one'}
          onClickRow={setClickedRow}
        />
        {clickedRow && (
          <p>Latest clicked row data: {JSON.stringify(clickedRow)}</p>
        )}
      </div>
    </div>
  );
};

export default Tables;
