import { breadcrumbTypes } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';

export const reportBreadcrumbsHistory = (t) => {
  return [
    breadcrumbTypes.home,
    {
      content: t('components.FeedbackReportPage.title'),
      path: routePaths.feedbackReports,
    },
  ];
};

export const feedbackReportOptions = (t) => [
  {
    title: t('components.FeedbackReportPage.feedbackLawyer'),
    requiresLawyerId: true,
    requiresLegalProbCode: false,
    requiresCBO: false,
    endpoint: '/Reports/feedback/lawyer',
  },
  {
    title: t('components.FeedbackReportPage.closedCases'),
    requiresLawyerId: false,
    requiresLegalProbCode: false,
    requiresCBO: false,
    endpoint: '/Reports/feedback/closedcases',
  },
  {
    title: t('components.FeedbackReportPage.openedCases'),
    requiresLawyerId: false,
    requiresLegalProbCode: false,
    requiresCBO: false,
    endpoint: '/Reports/feedback/opencases',
  },
  {
    title: t('components.FeedbackReportPage.positiveFeedbackLawyer'),
    requiresLawyerId: false,
    requiresLegalProbCode: false,
    requiresCBO: false,
    endpoint: '/Reports/feedback/positivecomments',
  },
  {
    title: t('components.FeedbackReportPage.legalProblemCode'),
    requiresLawyerId: false,
    requiresLegalProbCode: true,
    requiresCBO: false,
    endpoint: '/Reports/feedback/legalproblemcode',
  },
  {
    title: t('components.FeedbackReportPage.yearEnd'),
    requiresLawyerId: false,
    requiresLegalProbCode: false,
    requiresCBO: true,
    endpoint: '/Reports/feedback/yearend',
  },
];
