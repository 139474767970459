import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import {
  cleanManagedUnions,
  getManagedUnionIds,
  updateUnionsWithManagedUnionIds,
  filterUnionsMatchingQuery,
} from './functions';

export const resetModalEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoading,
    setSaving,
    setUnions,
    setFilteredUnions,
    setManagedUnionIds,
  } = options;
  return () => {
    // Abort requests and clear union data on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoading(false);
      setSaving(false);
      setUnions([]);
      setFilteredUnions([]);
      setManagedUnionIds([]);
    };
  };
};

export const unmountModalEffect = (options = {}) => {
  const { setMounted, reset } = options;
  return () => {
    setMounted(false);
    reset();
  };
};

export const onMountEffect = (options = {}) => {
  const { reset } = options;
  return () => () => reset();
};

export const onFilterUnionsEffect = (options = {}) => {
  const { unions, managedUnionIds, setFilteredUnions } = options;
  return (filterQuery) => {
    const filteredUnions = filterUnionsMatchingQuery(unions, filterQuery);
    const nextUnions = updateUnionsWithManagedUnionIds(
      filteredUnions,
      managedUnionIds
    );
    setFilteredUnions(nextUnions);
  };
};

export const onResetUnionsEffect = (options = {}) => {
  const { unions, managedUnionIds, setFilteredUnions } = options;
  return () => {
    const nextUnions = updateUnionsWithManagedUnionIds(unions, managedUnionIds);
    setFilteredUnions(nextUnions);
  };
};

export const onChangeManagedUnionCheckboxEffect = (options = {}) => {
  const {
    unionId,
    managedUnionIds,
    setManagedUnionIds,
    setFilteredUnions,
  } = options;
  return () => {
    let nextManagedUnionIds = [];

    if (managedUnionIds.includes(unionId)) {
      nextManagedUnionIds = managedUnionIds.filter((id) => id !== unionId);
    } else {
      nextManagedUnionIds = [...managedUnionIds, unionId];
    }

    setManagedUnionIds(nextManagedUnionIds);

    setFilteredUnions((filteredUnions) =>
      filteredUnions.map((union) => ({
        ...union,
        checked: nextManagedUnionIds.includes(union.unionId),
      }))
    );
  };
};

export const getManagedUnionsEffect = (options = {}) => {
  const {
    t,
    api,
    mounted,
    companyCode,
    setLoading,
    setUnions,
    setFilteredUnions,
    setManagedUnionIds,
    getAbortControllerRef,
  } = options;

  return async () => {
    if (!mounted || !companyCode) {
      return;
    }

    setLoading(true);

    rotateAbortControllerRef(getAbortControllerRef);
    const { signal } = getAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Admin/Companies/${companyCode}/Unions/Manage`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.CompanyDetailsPage.ManageUnionsModal.getRequestError'
              ),
            },
          },
        }
      );

      const { unionInfo = [] } = json;
      const unions = cleanManagedUnions(unionInfo);
      const managedUnionIds = getManagedUnionIds(unions);

      setUnions(unions);
      setFilteredUnions(unions);
      setManagedUnionIds(managedUnionIds);
      setLoading(false);
    } catch (error) {
      if (!isAbortError(isAbortError)) {
        setLoading(false);
      }
    }
  };
};

export const postManagedUnionsEffect = (options = {}) => {
  const {
    t,
    api,
    companyCode,
    setSaving,
    unions,
    managedUnionIds,
    postAbortControllerRef,
    unmount,
  } = options;

  return async () => {
    if (!companyCode) {
      return;
    }

    setSaving(true);

    rotateAbortControllerRef(postAbortControllerRef);
    const { signal } = postAbortControllerRef.current;

    const body = {
      unionInfo: updateUnionsWithManagedUnionIds(unions, managedUnionIds),
    };

    try {
      await api.postJson(
        `/Admin/Companies/${companyCode}/Unions/Manage`,
        { signal, body },
        {
          success: {
            context: {
              message: t(
                'components.CompanyDetailsPage.ManageUnionsModal.postRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.CompanyDetailsPage.ManageUnionsModal.postRequestError'
              ),
            },
          },
        }
      );

      setSaving(false);

      if (typeof unmount === 'function') {
        unmount();
      }
    } catch (error) {
      if (!isAbortError(isAbortError)) {
        setSaving(false);
      }
    }
  };
};
