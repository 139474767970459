import React, { createContext, useContext } from 'react';
import { BannerProvider, BannerContext } from 'js/components/banner';
import { presentStyledBannerEffect } from './effects';

const StyledBannerContext = createContext({
  presentStyledBanner: () => {},
  presentBanner: () => {},
  dismissBanner: () => {},
});

const StyledBannerProviderWrapper = ({ children }) => {
  const { presentBanner, dismissBanner } = useContext(BannerContext);
  return (
    <StyledBannerContext.Provider
      value={{
        presentStyledBanner: presentStyledBannerEffect({ presentBanner }),
        presentBanner,
        dismissBanner,
      }}
    >
      {children}
    </StyledBannerContext.Provider>
  );
};

const StyledBannerProvider = ({ children }) => {
  return (
    <BannerProvider>
      <StyledBannerProviderWrapper>{children}</StyledBannerProviderWrapper>
    </BannerProvider>
  );
};

export { StyledBannerContext, StyledBannerProvider };
