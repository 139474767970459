export const getLockSystemEffect = (options = {}) => {
  const { t, api, onChangeLockSystemActivity, onLoadLockSystem } = options;
  return async () => {
    try {
      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(true);
      }

      const { json = {} } = await api.getJson(
        '/System/lock',
        {},
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ChequeRunPage.getLockRequestError'),
            },
          },
        }
      );

      const { isLocked = false } = json;
      const lockSystem = {
        lock: isLocked,
        toggleLockSystem: putLockSystemEffect({
          t,
          api,
          lock: !isLocked,
          onChangeLockSystemActivity,
          onLoadLockSystem,
        }),
      };

      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(false);
      }

      if (typeof onLoadLockSystem === 'function') {
        onLoadLockSystem(lockSystem);
      }
    } catch (error) {
      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(false);
      }
    }
  };
};

export const putLockSystemEffect = (options = {}) => {
  const {
    t,
    api,
    lock,
    onChangeLockSystemActivity,
    onLoadLockSystem,
  } = options;
  return async () => {
    try {
      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(true);
      }

      const endpoint = '/System/lock';

      if (lock) {
        await api.postJson(
          endpoint,
          {},
          {
            success: {
              bypass: true, // bypass the message when locking
              context: {
                message: t('components.ChequeRunPage.lockRequestSuccess'),
              },
            },
            error: {
              context: {
                message: t('components.ChequeRunPage.lockRequestError'),
              },
            },
          }
        );
      } else {
        await api.deleteJson(
          endpoint,
          {},
          {
            success: {
              context: {
                message: t('components.ChequeRunPage.unlockRequestSuccess'),
              },
            },
            error: {
              context: {
                message: t('components.ChequeRunPage.unlockRequestError'),
              },
            },
          }
        );
      }

      const lockSystem = {
        lock,
        toggleLockSystem: putLockSystemEffect({
          t,
          api,
          lock: !lock,
          onChangeLockSystemActivity,
          onLoadLockSystem,
        }),
      };

      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(false);
      }

      if (typeof onLoadLockSystem === 'function') {
        onLoadLockSystem(lockSystem);
      }
    } catch (error) {
      if (typeof onChangeLockSystemActivity === 'function') {
        onChangeLockSystemActivity(false);
      }
    }
  };
};
