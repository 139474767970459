import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const resolveContent = (props) => {
  const { helpContent, validationContent, invalid, disabled } = props;
  if ((!invalid || disabled) && helpContent) {
    return helpContent;
  } else if (invalid && validationContent) {
    return validationContent;
  } else {
    return null;
  }
};

const InputHelp = (props) => {
  const { className, invalid, disabled, children } = props;
  const content = resolveContent(props);
  const dynamicClassNames = {
    'input-help-invalid': invalid && !disabled,
    'input-help-disabled': disabled,
  };

  return (
    <div
      className={classnames('input-help', { ...dynamicClassNames }, className)}
    >
      {children}
      {content && <div className="input-help-content">{content}</div>}
    </div>
  );
};

InputHelp.defaultProps = {
  helpContent: null,
  validationContent: null,
  invalid: false,
  disabled: false,
};

InputHelp.propTypes = {
  className: PropTypes.string,
  helpContent: PropTypes.node,
  validationContent: PropTypes.node,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputHelp;
