import { cleanStringField } from 'js/utilities/strings';

export const sortCitiesByProvince = (options = {}) => {
  const { provinceOptions = [], cityOptions = [] } = options;
  return provinceOptions.reduce((nextCitiesByProvince, province) => {
    const citiesForProvince = cityOptions.filter(
      (city) => city.provinceCode === province.provId
    );
    return {
      ...nextCitiesByProvince,
      [province.provId]: citiesForProvince,
    };
  }, {});
};

export const isNewProvincePath = (path) => {
  return path.split('/').pop() === 'new';
};

export const cleanProvince = (province = {}) => {
  const { id, name, isActive, cities = [] } = province;

  return {
    id: cleanStringField(id),
    name: cleanStringField(name),
    isActive: isActive === true,
    cities: cleanCities(cities),
  };
};

export const cleanProvinces = (provinces = []) => provinces.map(cleanProvince);

export const cleanCity = (city = {}) => {
  const { cityCode, name, isActive } = city;

  return {
    cityCode: cleanStringField(cityCode),
    name: cleanStringField(name),
    isActive: isActive === true,
  };
};

export const cleanCities = (cities = []) => cities.map(cleanCity);
