import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'js/components/design-system';
import { unionDetailsBreadcrumbsHistory } from './functions';

const UnionDetailsBreadcrumbs = () => {
  const { t } = useTranslation();
  const {
    params: { unionNumber },
  } = useRouteMatch();
  return (
    <Breadcrumbs
      className="Union-details-breadcrumbs"
      history={unionDetailsBreadcrumbsHistory(t, unionNumber)}
    />
  );
};

export default UnionDetailsBreadcrumbs;
