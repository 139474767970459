import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { cleanReportFolders } from 'js/utilities/reports';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [] } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
    };
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { setObject } = options;
  return (e) => {
    const { name, value } = e.target;
    setObject((obj) => ({
      ...obj,
      [name]: value,
    }));
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const getAllFoldersEffect = (options = {}) => {
  const {
    t,
    api,
    mounted,
    setLoadingFolders,
    setFolders,
    getReportsFoldersAbortControllerRef,
  } = options;
  return async () => {
    if (!mounted) {
      return;
    }

    setLoadingFolders(true);

    rotateAbortControllerRef(getReportsFoldersAbortControllerRef);
    const { signal } = getReportsFoldersAbortControllerRef.current;

    try {
      const endpoint = `/Reports/folders?Order=name&Sort=asc&Limit=1000&Offset=0`;
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.ReportsPage.getFoldersRequestError'),
            },
          },
        }
      );
      const { items = [] } = json;

      setFolders(cleanReportFolders(items));
      setLoadingFolders(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingFolders(false);
      }
    }
  };
};

export const onConfirmSaveAsEffect = (options = {}) => {
  const {
    isValid,
    touchAll,
    report,
    nextReport,
    saveType,
    onSaveAsReport,
    onDismiss,
    untouchAll,
    setActive,
  } = options;
  return async () => {
    setActive(true);
    if (!isValid) {
      touchAll();
      setActive(false);
      return;
    }
    try {
      await onSaveAsReport({
        report,
        nextReport,
        saveType,
      });
      untouchAll();
      onDismiss();
      setActive(false);
    } catch (error) {
      // do nothing with the error
      setActive(false);
    }
  };
};
