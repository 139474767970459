import React, { useContext, useState, useRef, useEffect } from 'react';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import FeedbackReportBreadcrumbs from './breadcrumbs';
import { feedbackReportOptions } from './functions';
import {
  getLegalProblemCodesEffect,
  runFeedbackReportEffect,
  onChangeEffect,
  onCloseModalEffect,
} from './effects';
import RunFeedbackReportModal from './run-feedback-report-modal';
import { isEmpty } from 'js/utilities/validation';

const FeedbackReportPage = (props) => {
  const { api = {} } = useContext(FetchContext);
  const { className, title, ...pageProps } = props;
  const { t } = useTranslation();
  const legalProblemCodesAbortControllerRef = useRef(null);
  const postReportAbortControllerRef = useRef(null);
  const Reports = feedbackReportOptions(t);

  const defaultReportBody = {
    startDate: '',
    endDate: '',
    lawyerId: '',
    legalProblemCode: {},
    yearEndFeedbackReportType: '',
  };
  const [runningReport, setRunningReport] = useState({});
  const [reportBody, setReportBody] = useState(defaultReportBody);
  const [legalProblemCodes, setLegalProblemCodes] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(
    getLegalProblemCodesEffect({
      t,
      api,
      setActive,
      setLegalProblemCodes,
      legalProblemCodesAbortControllerRef,
    }),
    []
  );

  const onClose = onCloseModalEffect({
    setRunningReport,
    setReportBody,
    defaultReportBody,
  });

  return (
    <Page
      className={classnames('feedback-reports-page', className)}
      title={t('components.FeedbackReportPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <div className="layout-container ">
        <div className="layout-column">
          <FeedbackReportBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <div className="feedback-reports-page-heading">
            <div className="feedback-reports-page-heading-left">
              <h1>{t('components.FeedbackReportPage.title')}</h1>
            </div>
          </div>

          {Reports.map((report, key) => (
            <div className="card feedback-report-item" key={key}>
              <h2>{report.title}</h2>
              <div className="print-button-container">
                <button
                  className="button button-highlight page-action-button"
                  onClick={() => setRunningReport(report)}
                >
                  {t('components.FeedbackReportPage.run')}
                </button>
              </div>
            </div>
          ))}
          <RunFeedbackReportModal
            mounted={!isEmpty(runningReport)}
            report={runningReport}
            reportBody={reportBody}
            onChange={onChangeEffect({ reportBody, setReportBody })}
            onDismiss={onClose}
            active={active}
            legalProblemCodes={legalProblemCodes}
            onRun={runFeedbackReportEffect({
              t,
              api,
              runningReport,
              reportBody,
              setActive,
              postReportAbortControllerRef,
            })}
          />
        </div>
      </div>
    </Page>
  );
};

FeedbackReportPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

export default FeedbackReportPage;
