import { breadcrumbTypes } from 'js/components/design-system';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryForSearchString = (search = '') => {
  const { date = '' } = paramsFromSearchString(search);

  const params = {
    date,
  };

  return queryForParams(params);
};

export const openPrintViewEffect = (options) => {
  const { destinationUrl } = options;

  window.open(destinationUrl);
};

export const getDayEndReportConfiguration = (date, isHeadOfficeUser) => ({
  casesOpened: {
    url: '', //not needed, this runs it's own report
    visible: true,
  },
  CIF: {
    url: routePathReplacingParams(routePaths.printMultipleCIF, {
      date: encodeURIComponent(date),
    }),
    visible: true,
  },
  NTP: {
    url: routePathReplacingParams(routePaths.printMultipleNTP, {
      date: encodeURIComponent(date),
    }),
    visible: true,
  },
  CSQ: {
    url: routePathReplacingParams(routePaths.printMultipleCSQ, {
      date: encodeURIComponent(date),
    }),
    visible: isHeadOfficeUser,
  },
});

export const dayEndBreadcrumbsHistory = (t) => {
  return [
    breadcrumbTypes.home,
    {
      content: t('components.dayEnd.title'),
      path: routePaths.dayEnd,
    },
  ];
};
