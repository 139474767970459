import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from 'js/components/menus/sort-menu';
import { legalProblemCodesSortOptions } from './functions';

const LegalProblemCodesSortMenu = (props) => (
  <SortMenu
    className="legal-problem-codes-sort-menu"
    sortOptions={legalProblemCodesSortOptions}
    {...props}
  />
);

LegalProblemCodesSortMenu.defaultProps = {
  field: 'codes',
  direction: 'asc',
};

LegalProblemCodesSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default LegalProblemCodesSortMenu;
