import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { routePaths } from 'js/components/router/route-paths';
import { AdminListCard } from 'js/components/design-system';
import SystemSettingsBreadcrumbs from './breadcrumbs';

const SystemSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <Page
      className="system-settings-page"
      title={t('components.SystemSettingsPage.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <SystemSettingsBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <div className="admin-page-heading">
            <h1>{t('components.SystemSettingsPage.title')}</h1>
          </div>
        </div>
      </div>

      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <AdminListCard
            linkTo={routePaths.lawyerTypes}
            heading={t('components.SystemSettingsPage.lawyerTypes')}
          />
          <AdminListCard
            linkTo={routePaths.offices}
            heading={t('components.SystemSettingsPage.offices')}
          />
          <AdminListCard
            linkTo={routePaths.databaseBackups}
            heading={t('components.SystemSettingsPage.databaseBackups')}
          />
        </div>
        <div className="layout-column">
          <AdminListCard
            linkTo={routePaths.legalProblemCodes}
            heading={t('components.SystemSettingsPage.legalProblemCodes')}
          />
          <AdminListCard
            linkTo={routePaths.provinces}
            heading={t('components.SystemSettingsPage.provinces')}
          />
        </div>
      </div>
    </Page>
  );
};

export default SystemSettingsPage;
