import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { isEmpty } from 'js/utilities/validation';
import { formatDate } from 'js/utilities/dates';
import { resolveSelectedDateRangeParamsForReport } from './date-functions';
import {
  INITIAL_RUN_PARAMS,
  INITIAL_REPORT_FORMAT_INDEX,
  returnFunctionString,
  resolveRunParamsForReport,
  findCaseStatus,
} from './functions';

export const cancelRequestsEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingTableFields } = options;
  return () => {
    executeAbortControllerRefs(abortControllerRefs);
    setLoadingTableFields(false);
  };
};

export const onMountEffect = (options = {}) => {
  const { cancelRequests } = options;
  return () => {
    // Abort requests on unmount:
    return () => cancelRequests();
  };
};

export const setDefaultsEffect = (options = {}) => {
  const { report = {}, mounted = false, onFilterChange } = options;
  return () => {
    if (!mounted) {
      return;
    }
    const { filter = '' } = report;
    const status = findCaseStatus(filter);
    if (status) {
      onFilterChange('status', status);
    }
  };
};

export const onChangeModalMountedEffect = (options = {}) => {
  const {
    mounted,
    storage,
    report,
    untouchAll,
    setEditableReport,
    setRunParams,
    setSelectedFormatIndex,
    cancelRequests,
    reportsFilterIndex,
  } = options;
  return () => {
    if (mounted) {
      if (report.id) {
        setEditableReport(report);
        const dateRangeParams = resolveSelectedDateRangeParamsForReport(
          storage,
          report,
          reportsFilterIndex
        );
        setRunParams((runParams) => ({ ...runParams, ...dateRangeParams }));
      }
    } else {
      untouchAll();
      cancelRequests();
      // Delay resetting the editable report and run
      // params so the validation error doesn't flash
      // before the untouch is set:
      setTimeout(() => {
        setEditableReport({});
        setRunParams(INITIAL_RUN_PARAMS);
        setSelectedFormatIndex(INITIAL_REPORT_FORMAT_INDEX);
      });
    }
  };
};

export const getReportTableFieldsEffect = (options = {}) => {
  const {
    t,
    api,
    report,
    mounted,
    setLoadingTableFields,
    setReportTableFields,
    getReportTableFieldsAbortControllerRef,
  } = options;
  return async () => {
    if (!report.id || !mounted) {
      return;
    }

    setLoadingTableFields(true);

    rotateAbortControllerRef(getReportTableFieldsAbortControllerRef);
    const { signal } = getReportTableFieldsAbortControllerRef.current;

    try {
      const endpoint = `/Common/TableField?reportId=${report.id}`;
      const { json = {} } = await api.getJson(
        endpoint,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.RunEditReportModal.getReportTableFieldsError'
              ),
            },
          },
        }
      );
      setReportTableFields(json);
      setLoadingTableFields(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingTableFields(false);
      }
    }
  };
};

export const onConfirmSaveEffect = (options = {}) => {
  const {
    isValid,
    touchAll,
    setRunButtonId,
    editableReport,
    runParams,
    useRunMode,
    onConfirmSave,
    onConfirmRun,
    onDismiss,
  } = options;
  return async (e) => {
    if (!isValid) {
      touchAll();
      return;
    }

    setRunButtonId(e.target.id);

    try {
      await onConfirmSave(editableReport);

      if (useRunMode) {
        const params = resolveRunParamsForReport(editableReport, runParams);
        await onConfirmRun(editableReport, params);
      }

      onDismiss();
    } catch (error) {
      // do nothing with the error
    }
  };
};

export const onConfirmRunEffect = (options = {}) => {
  const {
    isValid,
    touchAll,
    setRunButtonId,
    editableReport,
    runParams,
    onConfirmRun,
    onDismiss,
  } = options;
  return async (e) => {
    if (!isValid) {
      touchAll();
      return;
    }

    setRunButtonId(e.target.id);

    try {
      const params = resolveRunParamsForReport(editableReport, runParams);
      await onConfirmRun(editableReport, params);
      onDismiss();
    } catch (error) {
      // do nothing with the error
    }
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { setObject } = options;
  return (e) => {
    const { name, value } = e.target;
    setObject((obj) => ({
      ...obj,
      [name]: value,
    }));
  };
};

export const onFilterChangeEffect = (options = {}) => {
  const { setRunParams } = options;
  return (name, value) => {
    setRunParams((runParams) => ({ ...runParams, [name]: value }));
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onDateRangeChangeEffect = (options = {}) => {
  const { setObject, resolveDatesForDateRange, storage } = options;
  return (e) => {
    const { name, value } = e.target;
    setObject((obj) => {
      const { startDate, endDate } = resolveDatesForDateRange(storage, value);
      return { ...obj, [name]: value, startDate, endDate };
    });
  };
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, setObject, saveDateRangeParam, storage } = options;
  return (date) => {
    setObject((obj) => ({ ...obj, [name]: date }));
    saveDateRangeParam(storage, name, formatDate(date));
  };
};

export const onInputFilterChangeEffect = (options = {}) => {
  const { filterValues, setFilterValues } = options;
  return (e) => {
    if (typeof setFilterValues === 'function') {
      const { name, value } = e.target;
      const nextFilterValues = { ...filterValues, [name]: value };
      setFilterValues(nextFilterValues);
    }
  };
};

export const onUpdateFilterEffect = (options = {}) => {
  const { setEditableReport } = options;
  return (filter) => {
    setEditableReport((report) => ({
      ...report,
      filter,
    }));
  };
};

export const insertFilterEffect = (options = {}) => {
  const { t, filter, filterValues, onUpdateFilter, setValueError } = options;
  return async () => {
    const { operator, value } = filterValues;
    const requiresValue = operator !== 'isEmpty' && operator !== 'notEmpty';

    if (requiresValue && !isEmpty(value)) {
      const nextFilter = filter.concat(returnFunctionString(filterValues));
      onUpdateFilter(nextFilter);
      setValueError('');
    } else if (requiresValue && isEmpty(value)) {
      setValueError(t('components.ReportDetailsPage.editModal.valueError'));
    } else {
      //does not require a value
      const nextFilter = filter.concat(returnFunctionString(filterValues));
      onUpdateFilter(nextFilter);
    }
  };
};

export const concatFilterEffect = (options = {}) => {
  const { setEditableReport } = options;
  return async (value) => {
    setEditableReport((report) => {
      const { filter = '' } = report;
      const nextFilter = filter.concat(` ${value} `);
      return { ...report, filter: nextFilter };
    });
  };
};
