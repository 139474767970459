import { roundToFixedPointString } from 'js/utilities/numbers';
import IMask from 'imask';
import { lpCodeMaskPattern } from 'js/utilities/masks';

export const createLpCodeMaskEffect = (options = {}) => {
  const { lpCodeMaskRef } = options;
  return () => {
    lpCodeMaskRef.current = IMask.createMask({
      mask: lpCodeMaskPattern,
    });
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onLpCodeInputChangeEffect = (options = {}) => {
  const { lpCodeMaskRef, onChange } = options;
  return (e) => {
    const { current: mask } = lpCodeMaskRef;
    const { name, value } = e.target;
    if (mask) {
      onChange(name, mask.resolve(value));
    }
  };
};

export const onNumericInputBlurEffect = (options = {}) => {
  const { touch, onChange, toFixed = false, precision = 1 } = options;
  return (e) => {
    const { name, value } = e.target;
    let nextValue = '';

    if (toFixed) {
      nextValue = roundToFixedPointString(value, precision);
    } else {
      nextValue = !isNaN(Number(value)) ? value : '';
    }

    if (typeof onChange === 'function') {
      onChange(name, nextValue);
    }
    touch(name);
  };
};

export const onCheckboxChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, checked } = e.target;
      onChange(name, checked);
    }
  };
};
