import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
  isAbortError,
} from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';
import { clientApiKey, cleanNestedClient } from 'js/utilities/clients';
import {
  cleanOutstandingBalanceNotes,
  sortOutstandingBalanceNotes,
} from './functions';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setClientActive,
    setNotesActive,
    setDeleting,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setClientActive(false);
      setNotesActive(false);
      setDeleting(false);
    };
  };
};

export const getClientEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    id,
    clientCode,
    setClientActive,
    setClient,
    getClientAbortControllerRef,
  } = options;
  return async () => {
    const url = clientApiKey(id, clientCode);
    const record = cache.get(url);

    if (!record) {
      setClientActive(true);

      rotateAbortControllerRef(getClientAbortControllerRef);
      const { signal } = getClientAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          url,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.ClientPage.getClientRequestError'),
              },
            },
          }
        );
        const cleanClientData = cleanNestedClient(json);
        cache.set(url, cleanClientData);
        setClient(cleanClientData);
        setClientActive(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setClientActive(false);
        }
      }
    } else {
      setClientActive(false);
      setClient(record.value);
    }
  };
};

export const getNotesEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    query,
    setNotesActive,
    setNotes,
    getNotesAbortControllerRef,
  } = options;
  return async () => {
    setNotesActive(true);

    rotateAbortControllerRef(getNotesAbortControllerRef);
    const { signal } = getNotesAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        `/Client/${id}/${clientCode}/notes/outstanding`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.ClientOutstandingBalanceNotes.getNotesRequestError'
              ),
            },
          },
        }
      );

      const notes = cleanOutstandingBalanceNotes(json);
      const sortedNotes = sortOutstandingBalanceNotes(notes, query);

      setNotes(sortedNotes);
      setNotesActive(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setNotesActive(false);
      }
    }
  };
};

export const sortNotesEffect = (options = {}) => {
  const { query, setNotes } = options;
  return () => {
    setNotes((notes) => sortOutstandingBalanceNotes(notes, query));
  };
};

export const onChangeSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, orderBy: field, order: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const presentNoteModalEffect = (options = {}) => {
  const { setPresentNoteModal, willPresent = false } = options;
  return () => setPresentNoteModal(willPresent);
};

export const postNoteEffect = (options = {}) => {
  const { t, api, id, clientCode, postNoteAbortControllerRef } = options;
  return async (text = '') => {
    rotateAbortControllerRef(postNoteAbortControllerRef);
    const { signal } = postNoteAbortControllerRef.current;

    try {
      await api.postJson(
        `/Client/${id}/${clientCode}/notes/outstanding`,
        {
          body: { text },
          signal,
        },
        {
          success: {
            context: {
              message: t(
                'components.ClientOutstandingBalanceNotes.postRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ClientOutstandingBalanceNotes.postRequestError'
              ),
            },
          },
        }
      );
    } catch (error) {
      // Errors will be caught by the Add Note modal:
      if (!isAbortError(error)) {
        throw error;
      }
    }
  };
};

export const onCompleteNoteEffect = (options = {}) => {
  const { dismissNoteModal, getNotes } = options;
  return async () => {
    dismissNoteModal();
    await getNotes();
  };
};

export const deleteNoteEffect = (options = {}) => {
  const {
    t,
    api,
    id,
    clientCode,
    noteIdPendingDeletion,
    setNoteIdPendingDeletion,
    setDeleting,
    getNotes,
    deleteNoteAbortControllerRef,
  } = options;
  return async () => {
    setDeleting(true);

    rotateAbortControllerRef(deleteNoteAbortControllerRef);
    const { signal } = deleteNoteAbortControllerRef.current;

    try {
      await api.deleteJson(
        `/Client/${id}/${clientCode}/notes/outstanding/${encodeURIComponent(
          noteIdPendingDeletion
        )}`,
        { signal },
        {
          success: {
            context: {
              message: t(
                'components.ClientOutstandingBalanceNotes.deleteRequestSuccess'
              ),
            },
          },
          error: {
            context: {
              message: t(
                'components.ClientOutstandingBalanceNotes.deleteRequestError'
              ),
            },
          },
        }
      );
      setDeleting(false);
      setNoteIdPendingDeletion('');
      getNotes();
    } catch (error) {
      if (!isAbortError(error)) {
        setDeleting(false);
      }
    }
  };
};
