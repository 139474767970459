export const initializeEstateTrusteeEffect = (options = {}) => {
  const { initialValue, setEstateTrustee } = options;
  return () => setEstateTrustee(initialValue);
};

export const onChangeEstateTrusteeEffect = (options = {}) => {
  const { setEstateTrustee, onChange } = options;
  return (name, value) => {
    setEstateTrustee(value);
    onChange(name, value);
  };
};

export const onKeyPressEffect = (options = {}) => {
  const { onChangeEstateTrustee } = options;
  return (e) => {
    const {
      key,
      target: { name },
    } = e;
    switch (key) {
      case 'y':
        onChangeEstateTrustee(name, true);
        break;
      case 'n':
        onChangeEstateTrustee(name, false);
        break;
      default:
        break;
    }
  };
};
