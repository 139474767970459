export default {
  translation: {
    common: {
      errors: {
        network:
          'Your request could not be completed due to a network failure. Please check that your internet connection is working and configured correctly.',
        unknown: 'Something went wrong.',
        notFound: 'The resource you requested does not exist.',
        locked: {
          identified:
            'System locked by {{email}}. Updates will not be permitted until the system is unlocked. Please try again later.',
          unidentified:
            'System locked. Updates will not be permitted until the system is unlocked. Please try again later.',
        },
      },
      yes: 'Yes',
      no: 'No',
      submit: 'Submit',
      submitting: 'Submitting...',
      ok: 'OK',
      continue: 'Continue',
      cancel: 'Cancel',
      close: 'Close',
      add: 'Add',
      new: 'New',
      on: 'on',
      to: 'to',
      preview: 'Preview',
      print: 'Print',
      edit: 'Edit',
      save: 'Save',
      saving: 'Saving...',
      update: 'Update',
      updating: 'Updating...',
      create: 'Create',
      creating: 'Creating...',
      delete: 'Delete',
      deleting: 'Deleting...',
      download: 'Download',
      done: 'Done',
      back: 'Back',
      or: 'Or',
      na: 'N/A',
      defaultSelectPlaceholder: 'Select',
      defaultDatePlaceholder: 'YYYY/MM/DD',
      comingSoon: 'Coming soon',
      currency: '${{value}}',
      fileSize: 'Files must be less than {{limit}}MB in size.',
      nameFormatLastFirst: '{{lastName}}, {{firstName}}',
      clientStatus: {
        active: 'Active',
        retiree: 'Retiree',
        survivingSpouse: 'Surviving Spouse',
        spouse: 'Spouse',
        dependent: 'Dependent',
        deceased: 'Deceased',
      },
      clientStatusByCode: {
        1: 'Active',
        2: 'Retiree',
        3: 'Surviving Spouse',
        4: 'Spouse',
        5: 'Dependent',
        6: 'Deceased',
      },
      caseStatus: {
        active: 'Active',
        closed: 'Closed',
        denied: 'Denied',
        void: 'Voided',
      },
      provincesByCode: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MN: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NT: 'Northwest Territories',
        NS: 'Nova Scotia',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      },
      sourceOfCommentOptionsByCode: {
        LE: 'Letter',
        TC: 'TC',
        CS: 'CSQ',
        ME: 'MEMO',
      },
      negativeReasonOptionsByCode: {
        CO: 'Coverage',
        SE: 'Service',
        LW: 'Legal Work',
        BL: 'Billing',
      },
      resolvedHowOptionsByCode: {
        NA: 'No Action Required',
        LE: 'Letter',
        TC: 'TC',
      },
      resolvedByOptionsByCode: {
        PE: 'Premature Ending',
        EX: 'Explanation',
        IR: 'Informational Resolution',
        DD: 'ExecDirector Decision',
        CM: 'Claims Manager Decision',
        CD: 'Commitee Decision',
        NA: 'No Action Required',
      },
      prefixes: {
        mr: 'Mr.',
        mrs: 'Mrs.',
        ms: 'Ms.',
        m: 'M',
        me: 'Me',
        mlle: 'Mlle',
        mme: 'Mme',
      },
      lawyerStatus: {
        active: 'Active',
        inactive: 'Inactive',
      },
      companyStatus: {
        active: 'Active',
        inactive: 'Inactive',
      },
      UnionStatus: {
        active: 'Active',
        inactive: 'Inactive',
      },
      CoopLawyerTypes: {
        coop: 'Co-Operating Lawyer',
        clientCoop: 'Client Selected Co-op Lawyer',
        coopNotary: 'Co-Operating Notary (CN)',
        clientCoopNotary: 'Client Select Co-Op. Notary',
      },
      NonCoopLawyerTypes: {
        nonCoop: 'Non-Co-operating Lawyer',
        nonCoopNotary: 'Non-Co-operating Notary',
        outsideCanadaNotary: 'Outside Canada Lawyer',
      },
      LawyerTypesByEnum: {
        0: 'Lawyer', // Fallback value when lawyer type determination fails
        1: 'Staff Lawyer',
        2: 'Co-operating Lawyer',
        3: 'Client Selected Co-op Lawyer',
        4: 'Non-Co-operating Lawyer',
        5: 'Non-Co-operating Notary',
        6: 'Co-Operating Notary (CN)',
        7: 'Client Select Co-Op. Notary',
        8: 'Outside Canada Lawyer',
      },
      lawyerPanels: {
        WillsandEstates: {
          title: 'Wills and Estates',
          subPanels: {
            WillsPowersofAttorney: 'Wills & Powers of Attorney',
            EstateAdministration: 'Estate Administration',
            EstateLitigation: 'Estate Litigation',
          },
        },
        RealEstate: {
          title: 'Real Estate',
          subPanels: {
            RealEstateTransactions: 'Real Estate Transactions',
            RealEstateLitigation: 'Real Estate Litigation',
          },
        },
        TenantsRights: {
          title: "Tenants' Rights",
          subPanels: {
            Leases: 'Leases',
            Litigation: 'Litigation',
          },
        },
        Family: {
          title: 'Family (Including contested litigation)',
          subPanels: {
            GuardianshipCommitteeship: 'Guardianship/Committeeship',
            Adoption: 'Adoption',
            ChangeofName: 'Change of Name',
            AllOtherContestedUncontested:
              'All Other Contested and Uncontested Family Law Matters',
            ChildProtection: 'Child Protection',
          },
        },
        CivilLitigation: {
          title: 'Civil Litigation',
          subPanels: {
            PersonalInjury: 'Personal Injury',
            PropertyDamage: 'Property Damage',
            LibelSlander: 'Libel and Slander',
            ProfessionalMalpractice: 'Professional Malpractice',
            WrongfulDismissal: 'Wrongful Dismissal',
            Appeals: 'Appeals',
            LibelandSlander: 'Libel and Slander',
          },
        },
        CriminalandMotorVehicle: {
          title: 'Criminal and Motor Vehicle',
          subPanels: {
            Criminal: 'Criminal',
            MotorVehicleViolations: 'Motor Vehicle Violations',
            Appeals: 'Appeals',
          },
        },
        ConsumerDebtor: {
          title: 'Consumer/Debtor',
          subPanels: {
            CollectionActions: 'Collection Actions',
            ConsumerTransactions: 'Consumer Transactions',
            Bankruptcy: 'Bankruptcy',
            'No-faultInsuranceClaims': 'No-fault Insurance Claims',
            OtherInsDisputes: 'Other Insurance Disputes',
            Appeals: 'Appeals',
          },
        },
        AdministrativeLaw: {
          title: 'Administrative Law',
          subPanels: {
            VeteransBenefits: "Veterans' Benefits",
            UnemploymentInsurance: 'Unemployment Insurance',
            WorkersCompensation: "Workers' Compensation",
            CitizenshipImmigrationDeportation:
              'Citizenship/Inmmigration/Deportation',
            IncomeTaxAssessmentAppeals: 'Income Tax Assessment Appeals',
            PropertyTaxAssessmentAppeals: 'Property Tax Assessment Appeals',
            CPPQPPinclDisabilityclaims:
              'CPP/QPP Claims (incl. Disability claims)',
            AllAdministrativeLaw: 'All Administrative Law',
          },
        },
      },
      logoAlt: 'Unifor Logo',
      pageValidationError:
        'Page could not be saved as there is invalid data. Please complete data or discard changes.',
      promptTitle: 'Prompt',
      promptMessages: {
        dependent: {
          age:
            'Dependent is more than 26 years of age. Do you wish to continue?',
          existingSpouse:
            'The primary client already has an active spouse. You must deactivate the existing spouse manually first.',
          existingSpouseDeactivation:
            'The primary client already has an active spouse. Do you want to deactivate the existing spouse and create a new one?',
        },
        case: {
          estateTrusteeRule:
            "When the lawyer is an estate trustee, the lawyer must be a staff lawyer and the legal problem code must be '011' or '012'.",
          estateTrusteeConfirmation: 'Is the lawyer an Estate Trustee?',
          caseExists:
            'An active case exists for this client ID and LP code. Do you wish to continue?',
          realEstateActivity: {
            active:
              'An active case {{cif}} already exists for LP code {{legalProblemCode}}. Do you want to continue? Doing so will set the transfer date to January 1, 3000.',
            recent:
              'Another case {{cif}} was created for LP code {{legalProblemCode}} within the last two years. Do you want to continue? Doing so will set the transfer date to January 1, 3000.',
          },
        },
        client: {
          duplicate:
            'One or more similar clients were found that may be duplicates. Do you wish to continue?',
        },
        lawyer: {
          deleteFeedback: 'Are you sure you want to delete this file?',
        },
        reports: {
          changeDateRange:
            'Are you sure you want to update all reports in this folder?',
        },
      },
    },
    components: {
      DesignSystem: {
        NoteCard: {
          author: 'Note from: {{name}}',
          readMore: 'Read More',
          readLess: 'Read Less',
        },
      },
      controls: {
        SearchInput: {
          search: 'Search',
          subjects: {
            clientName: {
              name: 'Client Name',
              validationContent:
                'Enter a first name, last name, or both (minimum 2 characters each).',
            },
            clientId: {
              name: 'Client ID',
              validationContent:
                'The client ID must be 9 digits. The code must be 2 digits.',
            },
            clientPhoneNumber: {
              name: 'Client Phone',
              validationContent: 'Enter a valid phone number.',
            },
            caseNumber: {
              name: 'Case Number',
              validationContent:
                'The case number must match the format ####/####/#####.',
            },
            lawyerName: {
              name: 'Lawyer Name',
              validationContent:
                'Enter a first name, last name, or both (minimum 2 characters each).',
            },
            lawyerId: {
              name: 'Lawyer ID',
              validationContent:
                'The lawyer ID must be 4 digits or match the format #A####.',
            },
            lawyerPhoneNumber: {
              name: 'Lawyer Phone',
              validationContent: 'Enter a valid phone number.',
            },
          },
          placeholders: {
            firstName: 'First Name',
            lastName: 'Last Name',
            clientId: '#########',
            clientCode: '##',
            lawyerId: '####',
            caseNumber: '####/####/#####',
            phoneNumber: '###-###-####',
          },
        },
        CaseNumberInput: {
          label: 'Enter Case Number',
          confirm: 'Go',
          validation: 'Invalid case number.',
        },
        GeneralSearchInput: {
          placeholder: 'Search',
          termLengthValidation:
            'Please enter at least {{minimumTermLength}} characters.',
        },
        DayEndDateInput: {
          defaultValue: 'YYYY/MM/DD',
          confirm: 'Go',
          label: 'The selected Day End tasks will be performed for:',
          validation: 'Invalid date',
        },
      },
      Page: {
        title: {
          generic: 'UniforLSP',
          specific: 'UniforLSP | {{ title }}',
          reporting: 'UniforLSP | Reporting Environment',
        },
      },
      Soon: {
        title: 'Coming Soon',
      },
      VersionInfoModal: {
        buttonText: 'About',
        title: 'About UniforLSP CASE',
        environment: 'Environment: {{environment}}',
        credit: 'Built by RedBit',
        version: {
          title: 'Version',
          enviroment: 'Enviroment:',
          api: 'API Version:',
          web: 'Web App Build:',
        },
        reporting: {
          title: 'Reporting',
          apiServer: 'API Server:',
          webServer: 'Web Server:',
        },
        database: {
          title: 'Database',
          source: 'Source:',
          destination: 'Restore Destination:',
          enabled: 'Enabled:',
        },
      },
      Home: {
        title: 'Home',
        welcome: 'Welcome Back, {{name}}!',
        newClient: 'New Client',
        newLawyer: 'New Lawyer/Notary',
        recentlyViewedCases: 'Recently Viewed Cases',
      },
      HomeResponse404: {
        heading: 'No results were found.',
        messages: {
          case:
            '{{-openStrong}}{{caseNumber}}{{-closeStrong}} was not found in {{-openStrong}}Cases.{{-closeStrong}}',
          client:
            '{{-openStrong}}{{clientId}} {{clientCode}}{{-closeStrong}} was not found in {{-openStrong}}Clients.{{-closeStrong}}',
          lawyer:
            '{{-openStrong}}{{lawyerId}}{{-closeStrong}} was not found in {{-openStrong}}Lawyers.{{-closeStrong}}',
        },
        action: 'Check your input or adjust the filter.',
      },
      RecentItems: {
        fetchError: 'We could not load your recently viewed cases.',
        emptyStateMessage: 'You have not viewed any cases.',
        clientEmployeeNumber: 'Employee Number: {{clientEmployeeNumber}}',
      },
      Login: {
        title: 'Login',
        logIn: 'Log In',
        loggingIn: 'Logging In...',
      },
      UnknownUser: {
        title: 'Unknown User',
        accountMessage:
          'There seems to be something wrong with your account. Please log out and back in.',
        accountInfo: 'You are currently logged in as {{username}}.',
        logout: 'Log Out',
      },
      Error403: {
        title: 'Access Denied',
        heading: 'Access Denied',
        messageLine1: 'Your account is not allowed to view this page.',
        messageLine2: 'Contact the Administrator to adjust your permissions.',
      },
      Error404: {
        title: 'Not Found',
        heading: 'Page not found.',
        messageLine1: 'The page you are attempting to access does not exist.',
        messageLine2: 'Please check the URL or return home to try again.',
      },
      Error500: {
        title: 'System Error',
        heading: 'Something went wrong...',
        messageLine1: 'Please check the page you are trying to access.',
        messageLine2:
          'If the problem persists, please contact the Administrator.',
      },
      DeactivateModal: {
        saveButton: 'Set as Inactive',
        closeButton: 'Close',
        cancelButton: 'Discard Changes',
      },
      DeactivateToggle: {
        activate: 'Activate',
        active: 'Active',
        deactivate: 'Deactivate',
        inactive: 'Inactive',
      },
      InactiveTag: {
        inactive: 'Inactive',
      },
      FixedHeader: {
        menu: 'Menu',
        reports: 'Reports',
        feedbackReports: 'Feedback Reports',
        adminTools: 'Admin Tools',
        logout: 'Log Out',
        dayEnd: 'Day End',
        procedures: 'Procedures',
      },
      Footer: {
        environment: `{{-openStrong}}Environment:{{-closeStrong}} {{environment}}`,
        dateUpdated: `{{-openStrong}}Restored On:{{-closeStrong}} {{dateUpdated}}`,
        backupDate: `{{-openStrong}}Backup Date:{{-closeStrong}} {{backupDate}}`,
      },
      SearchHeader: {
        newClient: 'New Client',
        newLawyer: 'New Lawyer',
      },
      Search: {
        title: "Search results for '{{term}}' in {{model}}",
        heading: "{{totalResults}} results for '{{term}}' in {{model}}",
        searching: 'Searching...',
        model: {
          client: 'Clients',
          lawyer: 'Lawyers',
        },
        searchError: 'We could not complete your search',
        emptyStateMessage: 'We could not find any results for your search.',
      },
      SearchSortMenu: {
        firstNameAsc: 'First Name (A-Z)',
        firstNameDesc: 'First Name (Z-A)',
        lastNameAsc: 'Last Name (A-Z)',
        lastNameDesc: 'Last Name (Z-A)',
      },
      SearchResultsClientCard: {
        heading: '{{lastName}}, {{firstName}}',
        subheadingWithCompany: '{{status}} \u2013 {{companyName}}',
        subheadingWithCompanyAndUnion:
          '{{status}} \u2013 {{companyName}}, {{unionName}}',
        employeeNumber: 'Employee Number: {{employeeNumber}}',
      },
      SearchResultsLawyerCard: {
        heading: '{{lastName}}, {{firstName}}',
        subheading: 'Lawyer / Notary - {{city}}, {{province}}',
      },
      SearchFilter: {
        apply: 'Apply',
        reset: 'Reset',
        clients: 'Clients',
        lawyers: 'Lawyers',
        status: 'Status',
        city: 'City',
        company: 'Company Name',
        firm: 'Firm Name',
        clientTypeFilter: {
          all: 'All',
          active: 'Active',
          spouse: 'Spouse',
          survivingSpouse: 'Surviving Spouse',
          dependent: 'Dependent',
          retiree: 'Retiree',
        },
        lawyerTypeFilter: {
          all: 'All',
          staff: 'Staff',
          cooperating: 'Co-operating',
        },
      },
      LawyerTypes: {
        title: 'Lawyer Types',
        heading: 'Lawyer Types',
        breadcrumbs: {
          lawyerTypes: 'Lawyer Types',
        },
        getLawyerTypesRequestError:
          'We could not load the list of lawyer types.',
        getLawyerTypeOptionsRequestError:
          'We could not load the lawyer type options.',
        patchRequestSuccess: 'The lawyer type was updated.',
        patchRequestError: 'We could not update the lawyer type.',
        postRequestSuccess: 'The lawyer type was created.',
        postRequestError: 'We could not create the lawyer type.',
      },
      LawyerTypeCard: {
        expand: 'Expand',
        cancel: 'Cancel',
        descriptionPlaceholder: 'New lawyer type',
        descriptionValidation: 'Required, 30 or fewer characters',
        glAccount: 'G/L Account',
        glAccountValidation: 'Required',
        gstAccount: 'GST Account',
        gstAccountValidation: 'Required',
        requiredFields: 'Required Fields',
        provinceRequired: 'Province',
        cityRequired: 'City',
        idRequired: 'ID',
        DeactivateModal: {
          title: 'Set Lawyer Type as Inactive?',
          message:
            'This will flag the lawyer type as inactive throughout the system. This action can be undone.',
        },
      },
      AdminPage: {
        title: 'Admin Tools',
        breadcrumbs: {
          adminTools: 'Admin Tools',
          systemSettings: 'System Settings',
          procedures: 'Procedures',
        },
        staff: 'Staff',
        companies: 'Companies',
        systemSettings: 'System Settings',
        unions: 'Unions',
        lawFirms: 'Law Firms',
        feeSchedules: 'Fee Schedules',
      },
      FeeSchedulePage: {
        title: 'Fee Schedules',
        printTitle: 'Fee Schedule',
        emptyTitle: 'No fee schedules found',
        patchSuccess: 'Fee Schedule updated',
        patchError: 'Fee Schedule could not be updated',
        postSuccess: 'Fee Schedule created',
        postError: 'Fee Schedule could not be created',
        deleteSuccess: 'Fee Schedule deleted',
        deleteError: 'Fee Schedule could not be deleted',
        startDate: 'Start Date',
        endDate: 'End Date',
        startDateValidation: 'Start Date Required',
        archiveSuccess: 'Fee Schedule successfully archived',
        archiveError: 'Fee Schedule could not be archived',
        createActiveRelease: 'Create Active Release',

        FeeScheduleCard: {
          expand: 'Expand',
          archive: 'Archive Schedule',
          newRelease: 'New Release',
          id: 'ID',
          name: 'Benefit Name',
          idValidation: 'Required',
          nameValidation: 'Required, 50 or fewer characters',
          releaseEmptyState: 'No releases have been archived yet',
          incomplete: 'Incomplete',
          completeSchedule: 'Complete Schedule',
          deleteSchedule: 'Delete',
        },
        ArchiveModal: {
          title: 'Are you sure you want to archive this schedule?',
          text:
            'This will end the active release. To re-active a schedule in the future, create an active release from the archived details.',
          archive: 'Archive Schedule',
          archiving: 'Archiving... ',
        },
        DeleteModal: {
          title: 'Are you sure you want to delete this schedule?',
          text: 'This cannot be undone.',
          delete: 'Delete Schedule',
          deleting: 'Deleting... ',
        },
        Active: {
          title: 'Active',
          emptyTitle: 'No active fee schedules found',
        },
        Archived: {
          title: 'Archived',
          emptyTitle: 'No archived fee schedules found',
        },
      },
      ReleasePage: {
        title: 'Release',
        discard: 'Discard Release',
        legalProblemCodes: 'Legal Problem Codes',
        companies: 'Companies',
        searchPlaceholder: 'Search Legal Problem Codes',
        manageCodes: 'Manage Codes',
        companiesEmpty: 'No Companies are linked with this Fee Schedule',
        legalProblemCodesEmpty:
          'No Legal Problem Codes linked with this Fee Schedule',
        legalProblemCodesEmptyFilter: 'No Legal Problem Codes Found',
        lpcPostSuccess:
          'Legal problem codes have been updated and a new release has been made',
        lpcPostError: 'Legal problem codes could not be updated',
        noEligibleCompaniesBannerMessage:
          'No eligible companies available. To be able to save this fee schedule, please add a new company',
        postScheduleSuccess: 'Release was updated successfully',
        postScheduleError: 'Release could not be updated',
        updateWarning: '* Values may update after release has been saved',
        lpcTable: {
          actualRate: 'Actual Rate',
          name: 'Description',
          code: 'LP',
          csqThreshold: 'CSQ Threshold',
          forecast: 'Forecast',
        },
        manageLpCode: {
          title: 'Manage Legal Problem Codes',
          code: 'LP',
          name: 'Description',
          saveButton: 'Update and Close',
          cancelButton: 'Discard Changes',
          copyButton: 'Save New Release',
          lpcEmpty: 'No Legal Problem Codes found',
          checkMessage: 'Check the boxes to add codes to the release.',
        },
        manageCompanies: {
          buttonTitle: 'Manage Companies',
          title: 'Manage Eligible Companies',
          searchPlaceholder: 'Search Companies',
          companyCode: 'Code',
          companyName: 'Company Name',
          saveButton: 'Update and Close',
          cancelButton: 'Discard Changes',
          copyButton: 'Save New Release',
          companiesEmpty: 'No Companies found',
          checkMessage: 'Check the boxes to add companies to the release.',
          releaseMessage:
            '{{-openStrong}}Note:{{-closeStrong}} Removing a company will trigger a new release to be made.',
        },
        companiesTable: {
          companyCode: 'Code',
          companyName: 'Name',
          coCity: 'Location',
        },
      },
      ReleasePrintLayout: {
        title: 'Fee Schedule',
        startDate: 'Start Date:',
        endDate: 'End Date:',
        legalProblemCode: 'Legal Problem Code:',
        legalProblemDesc: 'Description:',
        serviceFrequency: 'Service Frequency:',
        transferLimit: 'Transfer Limit:',
        benefitType: 'Benefit Type:',
        serviceProvided: 'Service Provided:',
        csqThreshold: 'CSQ Threshold:',
        staffCloseOrVoid: 'Staff Close or Void:',
        nonStaffCloseOrVoid: 'Non-Staff Close or Void:',
        closeOrVoid: '{{action}} in {{days}}',
        actualRate: 'Actual Rate:',
        forecastAmount: 'Forecast Amount:',
        warn: 'Give warning if 0 billed to client:',
        block: 'Block:',
        lawPanel: 'Law Panel:',
        lawSubPanel: 'Law Sub-Panel:',
        closingStatus: 'Closing Status:',
        staff: 'Staff',
        nonStaff: 'Non-Staff',
        maxPrepaidHours: 'Max Prepaid Hours:',
        maxPrepaidAmount: 'Max Prepaid Amount:',
        prepaidRate: 'Prepaid Rate:',
        participantRate: 'Participant Rate:',
        companyCode: 'Company Code',
        companyName: 'Company Name',
      },
      SystemSettingsPage: {
        title: 'System Settings',
        lawyerTypes: 'Lawyer Types',
        offices: 'Offices',
        legalProblemCodes: 'Legal Problem Codes',
        provinces: 'Province & City Codes',
        databaseBackups: 'Database Backups',
      },
      DatabaseBackups: {
        title: 'Database Backups',
        breadcrumbs: {
          databaseBackups: 'Database Backups',
        },
        activityIndicator: {
          loading: 'Loading Database Backups\u2026',
          restoring: 'Restoring Reporting Database\u2026',
        },
        heading: {
          page: 'Database Backups',
          database: 'Database Details',
          daily: 'Daily',
          weekly: 'Weekly',
          monthly: 'Monthly',
          yearly: 'Yearly',
        },
        table: {
          database: 'Current Database',
          reportingDatabase: 'Reporting Database',
          name: 'Name',
          location: 'Location',
          status: 'Status',
        },
        emptyStateMessage: {
          daily: 'There are no daily backups.',
          weekly: 'There are no weekly backups.',
          monthly: 'There are no monthly backups.',
          yearly: 'There are no yearly backups.',
        },
        listCard: {
          complete: 'Ready to Restore',
          incomplete: 'In Progress',
          restore: 'Restore',
        },
        modal: {
          title: 'Restore Backup',
          date: 'Restore from date:',
          message:
            'Are you sure you want to restore the reporting server to this backup?',
          confirmDelete:
            'I understand that the existing reporting database will be deleted.',
          confirmDeleteValidation: 'Confirmation is required',
          confirmButtonText: 'Restore',
        },
        reportingEnvLink: 'Open Reporting Environment',
        getDatabaseBackupsError: 'We could not load the database backups.',
        restoreDatabaseSuccess: 'Restore process has begun',
        restoreDatabaseError: 'We could not restore the database',
        getDatabaseRestoreStatusError:
          'We could not check the database restore process status',
        getDatabaseSessionStatusError:
          'We could not check the database restore session status',
      },
      ProceduresPage: {
        title: 'Procedures',
        caseClosing: 'Close Cases',
        issueCheque: 'Issue Cheque',
        chequeRun: 'Cheque Run',
        csqEntry: 'CSQ Entry',
        autoVoid: 'Auto Void/Close',
        primaryClientIntegrity: 'Primary Client Integrity Report',
        caseReassignment: 'Reassign Case',
        caseCorrection: 'Correct Hidden Case Fields',
      },
      AutoVoidModal: {
        title: 'Auto Void/Close',
        confirmButton: 'Go',
        dateLabel: 'The Auto-Void/Close function will perform as of:',
        datePlaceholder: 'YYYY/MM/DD',
        dateValidation: 'Enter a valid date',
        activeMessage: 'Auto-Void/Close\nin progress...',
        postRequestSuccessSingluar: '{{count}} case was {{action}}.',
        postRequestSuccessPlural: '{{count}} cases were {{action}}.',
        voided: 'voided',
        closed: 'closed',
        postRequestError: 'Auto-Void/Close Failed',
      },
      LegalProblemCodes: {
        title: 'Legal Problem Codes',
        heading: 'Legal Problem Codes',
        breadcrumbs: {
          legalProblemCodes: 'Legal Problem Codes',
        },
        emptyStateMessage: 'There are no legal problem codes.',
        searchPlaceholder: 'Search by Problem Code Name',
      },
      LegalProblemCodeDetail: {
        title: 'Legal Problem Code {{code}} - {{name}}',
        newTitle: 'New Legal Problem Code',
        newHeading: 'New Legal Problem Code',
        breadcrumbs: {
          details: 'Details',
          new: 'New',
        },
        DeactivateModal: {
          title: 'Set Legal Problem Code as Inactive?',
          message:
            'This will flag the legal problem code as inactive throughout the system. This action can be undone.',
        },
        getFormOptionsRequestError: 'We could not load the form options.',
        getRequestError: 'We could not load the legal problem code details.',
        patchRequestSuccess: 'The legal problem code has been updated.',
        patchRequestError: 'We could not update the legal problem code.',
        postRequestSuccess: 'The legal problem code has been created.',
        postRequestError: 'We could not create the legal problem code.',
      },
      LegalProblemCodesListPrintView: {
        title: 'UNIFOR LSP',
        heading: 'Legal Problem Codes',
        emptyStateTitle: 'There are no legal problem codes to print.',
        columns: {
          code: 'Code',
          name: 'Name',
        },
      },
      LegalProblemCodeForm: {
        code: 'Code',
        name: 'Description',
        nameValidation: 'Required, 30 or fewer characters',
        serviceFrequency: 'Service Frequency',
        serviceFrequencyValidation: 'Required',
        serviceFrequencyOption: '{{years}} years',
        staff: 'Staff',
        nonStaff: 'Non-Staff',
        staffCloseOrVoid: 'Staff Close or Void',
        codeValidation: '3 chars Required',
        staffCloseOrVoidValidation: 'Required',
        staffCloseOrVoidInValidation: 'Required',
        nonStaffCloseOrVoid: 'Non-Staff Close or Void',
        nonStaffCloseOrVoidValidation: 'Required',
        nonStaffCloseOrVoidInValidation: 'Required',
        in: 'in',
        days: 'Days',
        void: 'Void',
        close: 'Close',
        actualRate: 'Actual Rate',
        actualRateValidation: 'Required',
        forecast: 'Forecast',
        forecastValidation: 'Required',
        csqThreshold: 'CSQ Threshold',
        csqThresholdValidation: 'Required',
        serviceProvided: 'Service Provided',
        serviceProvidedValidation: 'Required',
        benefitType: 'Benefit Type',
        benefitTypeValidation: 'Required',
        prepopulateOnCase: 'Prepopulate On Case',
        maxPrepaidHours: 'Max Prepaid Hours:',
        maxPrepaidHoursValidation: 'Required',
        maxPrepaidAmount: 'Max Prepaid Amount ($):',
        maxPrepaidAmountValidation: 'Required',
        prepaidRate: 'Prepaid Rate ($):',
        prepaidRateValidation: 'Required',
        participantRate: 'Participant Rate ($):',
        participantRateValidation: 'Required',
        lawPanel: 'Law Panel',
        lawPanelValidation: 'Required',
        lawSubPanel: 'Law Sub-Panel',
        lawSubPanelValidation: 'Required',
      },
      LegalProblemCodesSortMenu: {
        codesAsc: 'Codes Ascending',
        codesDesc: 'Codes Descending',
        nameAsc: 'Name Ascending',
        nameDesc: 'Name Descending',
      },
      Case: {
        title: 'Case {{caseNumber}}',
      },
      ClientPage: {
        clientTitle: 'Client - {{title}}',
        dependentTitle: 'Dependent - {{title}}',
        newClientTitle: 'New Client',
        newDependentTitle: 'New Dependent',
        getClientError: 'We could not load the client data.',
        postClientActiveSuccess: 'Client updated',
        postClientActiveError: 'We could not update the client.',
        unsetCompanyChangedError: 'We could not update the client.',
        clientSubheading: 'Client',
        dependentSubheading: 'Dependent',
        subheading:
          '{{clientSubheadingText}} - ID: {{id}}-{{clientCode}}, Status: {{clientStatus}} - {{clientStatusText}}',
        breadcrumbs: {
          search: 'Search Results',
          client: 'Client',
          dependent: 'Dependent',
          outstandingBalanceNotes: 'Outstanding Balance Notes',
        },
        outstandingBalanceMessage:
          'One of the clients with the same Client ID has an outstanding balance.',
        outstandingBalanceLinkText: 'See Notes',
        companyChangedMessage:
          'The Company Code has changed. Please inform accounting immediately.',
      },
      ClientInformation: {
        title: 'Information',
        postRequestSuccess: 'Client has been created',
        postRequestError: 'We could not create the client.',
        patchRequestSuccess: 'Client has been updated',
        patchRequestError: 'We could not update the client.',
        spouseExistsRequestError: 'We could not check for an existing spouse.',
        deactivateSpouseRequestError:
          'We could not deactivate the existing spouse.',
        duplicateClientRequestError:
          'We could not check for a duplicate client.',
        DeactivateModal: {
          title: 'Set Client as Inactive?',
          message:
            'This will flag the client as inactive throughout the system. This action can be undone.',
        },
        Personal: {
          title: 'Personal',
          clientId: 'Client ID',
          clientIdValidation: 'Must be 9 digits',
          clientStatus: 'Status',
          dateBirth: 'Year of Birth',
          dateBirthPlaceholder: 'eg. 1980',
          dateBirthValidation: 'Required',
          dateRetirement: 'Date of Retirement',
          dateRetirementValidation: 'Required',
          emailAddress: 'Email Address',
          emailAddressValidation: 'Required',
          firstName: 'First Name',
          firstNameValidation: 'Required, 2 to 30 characters',
          lastName: 'Last Name',
          lastNameValidation: 'Required, 2 to 30 characters',
          phoneNumber: 'Phone Number',
          phoneNumberValidation: 'Required',
          prefix: 'Prefix',
          prefixPlaceholder: 'None',
          prefixValidation: 'Required',
          clientStatusValidation: 'Required',
          clientStatusPlaceholder: 'Select',
        },
        Account: {
          accountTitle: 'Account',
          outstandingAccount: 'Outstanding Account',
          outstandingAccountNotes: 'See Notes',
          feeSchedule: 'Fee Schedule',
          version: 'Version',
        },
        Address: {
          title: 'Address',
          address: 'Street Address',
          address2: 'Street Address 2',
          city: 'City',
          postalCode: 'Postal Code',
          province: 'Province',
          provincePlaceholder: 'Select',
          addressValidation: 'Required, 30 or fewer characters',
          address2Validation: '30 or fewer characters',
          cityValidation: 'Required, 20 or fewer characters',
          postalCodeValidation: 'Required',
          provinceValidation: 'Required',
        },
        Employment: {
          title: 'Employment',
          company: 'Company Code',
          companyPlaceholder: 'Select a Company',
          employeeNumber: 'Employee Number',
          local: 'Local',
          localPlaceholder: 'Select a local',
          localInactiveOption: '{{optionText}} (Inactive)',
          seniorityDate: 'Seniority Date',
          requestError: 'We could not load the employment data.',
          employeeNumberValidation: 'Required, 10 or fewer characters',
          seniorityDateValidation: 'Required',
          companyValidation: 'Required',
          localValidation: 'Required',
        },
      },
      ClientDependents: {
        title: 'Dependents',
        requestError: "We could not load the client's dependents.",
        emptyStateMessage: 'This client does not have any dependents.',
        addToCoverage: 'Add to Coverage',
      },
      ClientDependentOf: {
        title: 'Dependent Of',
        requestError: 'We could not load the primary client information.',
      },
      ClientCaseHistory: {
        title: 'Case History',
        printHeaderTitle: 'Case History',
        generateReport: 'Report',
        generatingReport: 'Loading...',
        requestError: 'We could not load the case history.',
        reportRequestError: 'We could not load the case history report.',
        emptyStateMessage: 'This client does not have a case history.',
        columns: {
          payment: 'Payment',
          cif: 'CIF',
          status: 'Status',
          legalProblem: 'LP',
          openDate: 'Open Date',
          closedDate: 'Close Date',
          transferDate: 'Transfer Date',
          box: 'Box',
          file: 'File',
          lawyerType: 'Lawyer Type',
          lawyerId: 'Lawyer Id',
        },
      },
      ClientNotes: {
        title: 'Client Notes',
        printHeaderTitle: 'Client Notes',
        requestError: 'We could not load the client notes.',
        postRequestSuccess: 'Note saved',
        postRequestError: 'We could not save your note.',
        deleteRequestSuccess: 'Note deleted',
        deleteRequestError: 'We could not delete your note.',
        emptyStateMessage: 'There are no notes for this client.',
        addNote: 'Add a Note',
        DeleteNoteModal: {
          title: 'Delete this Note?',
          text:
            'This note will be permanently deleted. This action cannot be undone.',
          cancel: 'Cancel',
          delete: 'Delete Note',
          deleting: 'Deleting Note...',
        },
      },
      ClientLog: {
        title: 'Client Log',
        printHeaderHeader: 'Client Log',
        printHeaderTitle: 'Client Id: {{id}}, {{clientCode}}',
        requestError: 'We could not load the client log.',
        emptyStateMessage: 'There are no notes for this client.',
        caseLogLink: 'Jump to: Case Log',
        columns: {
          date: 'Date',
          field: 'Field',
          newValue: 'New Value',
          oldValue: 'Old Value',
          updatedById: 'Updated By',
        },
      },
      ClientDocuments: {
        title: 'Documents',
        requestError: "We could not load the client's documents.",
        postRequestError: 'We could not upload the document you selected.',
        postRequestErrorMultiple:
          'We could not upload some or all of the documents you selected.',
        postRequestSuccess: 'Document uploaded',
        postRequestSuccessMultiple: 'Documents uploaded',
        deleteDocumentRequestSuccess: 'Document deleted',
        deleteDocumentRequestError: 'We were unable to delete the document.',
        emptyStateMessage: 'There are no documents for this client.',
        upload: 'Upload a File',
        uploading: 'Uploading...',
        columns: {
          documentName: 'Name',
          dateUploaded: 'Date',
        },
        DeleteDocumentModal: {
          title: 'Delete Document?',
          text:
            'Are you sure you want to delete {{filename}}? This action cannot be undone.',
        },
      },
      ClientOutstandingBalanceNotes: {
        title: 'Outstanding Balance Notes for {{firstName}} {{lastName}}',
        subtitle: 'Outstanding Balance Notes',
        printHeaderTitle: 'Outstanding Balance Notes',
        emptyStateMessage:
          'There are no outstanding balance notes for this client.',
        getClientRequestError: 'We could not load the client information.',
        getNotesRequestError:
          'We could not load the outstanding balance notes for this client.',
        postRequestSuccess: 'Note saved',
        postRequestError: 'We could not save your note.',
        deleteRequestSuccess: 'Note deleted',
        deleteRequestError: 'We could not delete the note.',
        addNote: 'Add a Note',
        DeleteNoteModal: {
          title: 'Delete this Note?',
          text:
            'This note will be permanently deleted. This action cannot be undone.',
          cancel: 'Cancel',
          delete: 'Delete Note',
          deleting: 'Deleting Note...',
        },
      },
      ClientPrintHeader: {
        clientId:
          'Client ID: {{clientId}}-{{clientCode}}, Status: {{clientStatus}} - {{clientStatusText}}',
      },
      CasePage: {
        title: 'Case - {{title}}',
        lpCode: 'LP Code:',
        caseRequestError: 'We could not load the case data.',
        casePatchStatusRequestSuccess: 'Case status updated.',
        casePatchStatusRequestError: 'We could not update the case status.',
        caseReopenRequestSuccess: 'Case reopened.',
        caseReopenRequestError: 'We could not reopen the case.',
        optionsRequestError: 'We could not load the case options.',
        officesRequestError: 'We could not load the office list.',
        subheading: 'Case',
        newCase: 'New Case',
        breadcrumbs: {
          case: 'Case',
        },
        fabs: {
          actions: 'Actions',
          copy: 'Copy',
          void: 'Void',
          deny: 'Deny',
          cif: 'CIF',
          ntp: 'NTP',
          undoVoid: 'Undo Void',
          undoDeny: 'Undo Deny',
          reopen: 'Reopen',
          reassign: 'Reassign',
          correct: 'Correct',
          closeCase: 'Close Case',
        },
        VoidDenyModal: {
          Void: {
            title: 'Void this Case?',
            text:
              'This will set the Case as Void throughout the system. This action can be undone.',
            cancelButton: 'Take me back',
            saveButton: 'Void Case',
            closeButton: 'Cancel',
          },
          Denied: {
            title: 'Deny this Case?',
            text:
              'This will set the Case as Denied throughout the system. This action can be undone.',
            cancelButton: 'Take me back',
            saveButton: 'Deny Case',
            closeButton: 'Cancel',
          },
        },
      },
      CaseInformation: {
        title: 'Information',
        clientRequestError: 'We could not load the client information.',
        caseExistsRequestError: 'We could not check for an existing case.',
        postRequestSuccess: 'Opening New Case',
        postRequestError: {
          1: 'The user is not associated with an office. This must be fixed before creating a case.',
          2: 'Cannot find primary client to set the company and local as dependents do not have that information.',
          3: 'Unable to make a copy of the case. Please try again.',
          4: 'A conflict occurred when creating the case. Please try again.',
          5: 'Only Head Office may create cases for other offices.',
          6: 'Unable to generate a new case id. Please contact the system administrator.',
        },
        patchRequestSuccess: 'Case has been updated',
        requestError: 'Something went wrong',
        copy: 'Create Copy',
        createCase: 'Create Case',
        UpdateCompanyModal: {
          title: 'Edit Company and Union',
          subText: 'This will not affect the client, only the case.',
          editButton: 'Edit Case',
        },
        CaseBillingForm: {
          dateClosed: 'Date Closed',
          dateClosedValidation: 'Invalid date',
          payee: 'Payee',
          payeeValidation: 'Payee is required.',
          lawyerInvoiceDate: "Lawyer's Invoice",
          lawyerInvoiceDateValidation: 'Invalid date',
          printCSQ: 'Print CSQ',
          chequeNumbers: 'Cheque Numbers',
          chargedToPlan: 'Charged to Plan',
          planHours: 'Hours',
          planFees: 'Fees',
          planTaxes: 'GST/HST',
          planTotal: 'Total Fees & GST/HST',
          chargedToClient: 'Charged to Client',
          clientHours: 'Hours',
          clientFees: 'Fees',
          chargeValidation: 'Enter a valid number.',
        },
        PayeeAddressForm: {
          payeeName: 'Payee Name',
          payeeOtherAddress: 'Street Address',
          payeeOtherAddress2: 'Street Address 2',
          payeeOtherCity: 'City',
          payeeOtherProv: 'Province',
          payeeOtherPostalCode: 'Postal Code',

          payeeNameValidation: 'Required, max 30 chars.',
          payeeOtherAddressValidation: 'Required, max 30 chars.',
          payeeOtherAddress2Validation: 'Max 30 chars.',
          payeeOtherCityValidation: 'Required, max 20 chars.',
          payeeOtherProvValidation: 'Required, max 2 chars.',
          payeeOtherPostalCodeValidation: 'Required, max 7 chars.',
        },
        CaseInformationForm: {
          archiveBox: 'Archive Box',
          archiveBoxValidation: 'Max. 16 characters',
          archiveFile: 'Archive File',
          archiveFileValidation: 'Max. 16 characters',
          cif: 'Case Number',
          cifValidation: 'Invalid case number.',
          intakeId: 'Intake ID',
          intakeIdValidation: 'Invalid intake ID.',
          legalProblemCode: 'Legal Problem',
          legalProblemCodePlaceholder: 'Select',
          legalProblemCodeValidation: 'Legal problem code is required',
          officeOpened: 'Opened In',
          officeOpenedPlaceholder: 'Select',
          officeOpenedValidation: 'Office opened is required',
          openDate: 'Date Open',
          openDateValidation: 'Invalid date',
          shreddedDate: 'Date Shredded',
          shreddedDateValidation: 'Invalid date',
          lastEdit: 'Last Edit Date',
          lastEditValidation: 'Invalid date',
          reTransferDate: 'R/E Transfer Date',
          reTransferDateValidation: 'Required',
          otherParty: 'Other Party a Plan Member?',
          yes: 'Yes',
          no: 'No',
          specialInstructions: 'Special Instructions',
        },
        LawyerInformationForm: {
          title: 'Lawyer Information',
          referralReasonCode: 'Referral Reason',
          referralReasonCodeValidation: 'Referral Reason is required',
          stateTrustee: 'Lawyer is Estate Trustee',
          firstMeeting: 'First Meeting',
          firstMeetingValidation:
            'Must be before the case open date (see Case Information)',
          contactPerson: 'Contact Person',
          lawyerType: 'Lawyer Type',
        },
        LawyerCifForm: {
          cifSentTo: 'CIF Sent to:',
          cifSentToValidation: 'Choose client or lawyer.',
          client: 'Client',
          lawyer: 'Lawyer',
          none: 'N/A',
        },
        LawyerActivityForm: {
          title: 'Lawyer Activity Form',
          servicesProvidedCode: 'Service Provided',
          servicesProvidedCodeValidation: 'Required',
          serviceReasonCode: 'Reason',
          serviceReasonCodeValidation: 'Required',
        },
      },
      CaseLawyer: {
        title: 'Lawyer',
        lawyerRequestError: 'We could not load the lawyer information.',
        selectLawyer: 'Select Lawyer',
      },
      CaseBilling: {
        title: 'Billing',
      },
      CaseLog: {
        title: 'Case Log',
        printHeaderTitle: 'Change Log for Case {{caseNumber}}',
        emptyStateMessage: 'No changes could be found for this case',
        clientLogLink: 'Jump to: Client Log',
        columns: {
          caseId: 'Case ID',
          date: 'Date',
          field: 'Field',
          id: 'ID',
          newValue: 'New Value',
          oldValue: 'Old Value',
          updatedById: 'Updated By',
        },
      },
      CaseNotes: {
        title: 'Notes',
        printHeaderTitle: 'Case Notes',
        requestError: 'We could not load the case notes.',
        postRequestSuccess: 'Note saved',
        postRequestError: 'We could not save your note.',
        patchRequestSuccess: 'Note updated',
        patchRequestError: 'We could not update your note.',
        deleteRequestSuccess: 'Note deleted',
        deleteRequestError: 'We could not delete your note.',
        emptyStateMessage: 'There are no notes for this case.',
        addNote: 'Add a Note',
        clientNotesLink: 'Jump to: Client Notes',
        clientNotesLinkWithCount: 'Jump to: Client Notes ({{count}})',
        DeleteNoteModal: {
          title: 'Delete this Note?',
          text:
            'This note will be permanently deleted. This action cannot be undone.',
          cancel: 'Cancel',
          delete: 'Delete Note',
          deleting: 'Deleting Note...',
        },
      },
      CaseClosing: {
        title: 'Case Closing',
        alreadyClosedError: 'Case {{caseNumber}} has already been closed.',
        caseRequestError: 'We could not load the case data.',
        clientRequestError: 'We could not load the client information.',
        lawyerRequestError: 'We could not load the lawyer information.',
        optionsRequestError: 'We could not load the case options.',
        officesRequestError: 'We could not load the office list.',
        caseClosingRequestError: 'We could not close case {{caseNumber}}.',
        caseClosingSuccessMessage: 'Case {{caseNumber}} closed',
        changeLawyer: 'Change Lawyer',
        noLawyerError:
          'Case {{caseNumber}} does not have a lawyer and can not be closed.',
        noCifSentError:
          'This case is missing who the CIF was sent to. Please ensure that data was set before closing this case.',
        closeCase: 'Close Case',
        breadcrumbs: {
          caseClosing: 'Close Cases',
        },
      },
      CaseReassignment: {
        title: 'Reassign Case',
        caseRequestError: 'We could not load the case data.',
        clientRequestError: 'We could not load the client information.',
        caseReassignmentRequestError:
          'We could not reassign case {{caseNumber}} to client {{clientId}}-{{clientCode}}.',
        caseReassignmentSuccessMessage:
          'Case {{caseNumber}} reassigned to client {{clientId}}-{{clientCode}}',
        reassignTo: 'Reassign to...',
        clientIdPlaceholder: 'Search by Client ID',
        clientCodePlaceholder: 'Client Code',
        clientIdValidation:
          'The client ID must be 9 digits. The code must be 2 digits.',
        selectClient: 'Select Client',
        reassignCase: 'Reassign Case',
        breadcrumbs: {
          caseReassignment: 'Reassign Case',
        },
        ConfirmModal: {
          title: 'Reassign Case',
          text: 'Are you sure you want to reassign this case?',
          cancel: 'Take me back',
          reassign: 'Reassign',
          reassigning: 'Reassigning...',
        },
      },
      CaseCorrection: {
        title: 'Correct Hidden Case Fields',
        caseRequestError: 'We could not load the case data.',
        clientRequestError: 'We could not load the client information.',
        caseCorrectionRequestError: 'We could not update case {{caseNumber}}.',
        caseCorrectionSuccessMessage: 'Case {{caseNumber}} has been updated',
        save: 'Correct Case',
        breadcrumbs: {
          caseCorrection: 'Correct Hidden Case Fields',
        },
        CaseCorrectionForm: {
          clientCode: 'Client Code',
          clientCodeValidation: '2 digits',
          clientStatus: 'Client Status',
          clientStatusHelp: 'Select the primary client status first',
          clientStatusValidation: 'Required',
          primaryClientStatus: 'Primary Client Status',
          primaryClientStatusValidation: 'Required',
          selectPlaceholder: 'Select...',
        },
      },
      SelectLawyerModal: {
        title: 'Select the Lawyer Type',
        staff: 'Staff',
        cooperating: 'Co-operating',
        nonCooperating: 'Non-Co-operating',
        select: 'Select',
      },
      StaffLawyerModal: {
        title: 'Select Staff Lawyers',
        searchPlaceholder: 'Search staff lawyers',
        emptyStateMessage: 'No staff lawyers match your search criteria',
        searchError: 'Your search could not be completed',
        staffLawyerId: 'Staff Lawyer ID',
      },
      CoopLawyerModal: {
        title: 'Select a Co-operating Lawyer',
        searchById: 'Do you know the Lawyer ID?',
        select: 'Select',
        searchPlaceholder: 'Search Lawyers',
        searchId: 'Enter Lawyer Id',
        emptyStateMessage: 'No co-operating lawyers match your search criteria',
        searchError: 'Your search could not be completed',
        lawyerId: 'Lawyer ID',
        province: 'Province',
        provincePlaceholder: 'Select a Province',
        panel: 'Panel',
        panelPlaceholder: 'Select a Panel',
        subPanel: 'Sub Panel',
        subPanelPlaceholder: 'All Panels',
        city: 'City',
        cityPlaceholder: 'Cities & Districts',
        active: 'Active',
        allLawyers: 'All Lawyers',
        activeLawyers: 'Active Lawyers',
        inactiveLawyers: 'Inactive Lawyers',
        go: 'Go',
      },
      LawyerPage: {
        title: 'Lawyer - {{title}}',
        newLawyerTitle: 'New Lawyer',
        requestError: 'We could not load the lawyer data.',
        subheading: 'Lawyer',
        staffSubheading: 'Staff Lawyer Id: {{lawyerId}}',
        lawyerSubheading: 'Lawyer Id: {{lawyerId}}',
        breadcrumbs: {
          lawyer: 'Lawyer',
        },
        DeactivateModal: {
          title: 'Set Lawyer as Inactive?',
          message:
            'This will flag the lawyer as inactive throughout the system. This action can be undone.',
        },
        lawFirmModal: {
          chooseButton: 'Choose a Law Firm',
          title: 'Choose Law Firm',
          soleProprietor: 'Sole proprietor?',
          barristerButton: 'Set as Barrister & Solicitor',
        },
      },
      LawyerInformation: {
        title: 'Information',
        requestSuccess: 'Lawyer has been updated',
        requestError: 'Lawyer could not be updated',
        LawyerInformationForm: {
          title: 'General',
          prefix: 'Prefix',
          prefixPlaceholder: 'None',
          prefixValidation: 'Required',
          firstName: 'First Name',
          firstNameValidation: 'Required, 2 to 30 characters',
          lastName: 'Last Name',
          lastNameValidation: 'Required, 2 to 30 characters',
          phoneNumber: 'Phone Number',
          phoneNumberValidation: 'Required',
          fax: 'Fax Number',
          faxValidation: 'Invalid number format',
          email: 'Email',
          emailValidation: 'Invalid email',
          customaryFee: 'Customary Fee',
          customaryFeeValidation: 'Required',
          payFirm: 'Pay the Firm',
          dateOpened: 'Date Opened',
          calledToBar: 'Called to Bar',
          calledToBarValidation: '4 digit year',
          lawyerNotes: 'Lawyer Notes',
          language: 'Language',
          languageValidation: 'Select one or more languages',
          langEnglish: 'English',
          langFrench: 'French',
          langOther: 'Other',
          lawyerId: 'Lawyer ID',
        },
        StaffLawyerInformationForm: {
          title: 'General',
          prefix: 'Prefix',
          prefixPlaceholder: 'None',
          prefixValidation: 'Required',
          firstName: 'First Name',
          firstNameValidation: 'Minimum of 2 characters',
          lastName: 'Last Name',
          lastNameValidation: 'Minimum of 2 characters',
          staffId: 'Staff ID',
        },
        LawyerBillingForm: {
          title: 'System and Billing',
          customaryFee: 'Customary Fee',
          customaryFeeValidation: 'Required',
          payFirm: 'Pay the Firm',
          dateOpened: 'Date Opened',
          calledToBar: 'Called to Bar',
          calledToBarValidation: '4 digit year',
          lawyerNotes: 'Lawyer Notes',
        },
        LawyerAddressForm: {
          title: 'Mailing Address',
          addressSameAsFirm: 'Same as Firm',
          address: 'Street Address',
          address2: 'Street Address 2',
          city: 'City',
          postalCode: 'Postal Code',
          province: 'Province',
          provincePlaceholder: 'Select',
          addressValidation: 'Required, 30 or fewer characters',
          address2Validation: '30 or fewer characters',
          cityValidation: 'Required, 20 or fewer characters',
          cityIdValidation: 'Required',
          postalCodeValidation: 'Required',
          provinceValidation: 'Required',
        },
      },
      LawyerPanels: {
        title: 'Panels',
        heading: 'This lawyer is currently practicing:',
        getRequestError: 'We could not load the lawyer panels',
        patchRequestSuccess: 'Lawyer panels have been updated',
        patchRequestError: 'Lawyer panels could not be updated',
      },
      LawyerCaseHistory: {
        title: 'Case History',
        printHeaderTitle: 'Case History',
        requestError: 'We could not load the case history.',
        emptyStateMessage: 'This lawyer does not have a case history.',
        columns: {
          cif: 'CIF',
          caseStatus: 'Status',
          legalProblemCode: 'LP',
          openDate: 'Open Date',
          shreddedDate: 'Shredded Date',
          reTransferDate: 'Transfer Date',
          clientId: 'Client Id',
          clientCode: 'Client Code',
          box: 'Box',
          file: 'File',
        },
      },
      LawyerFeedback: {
        title: 'Feedback',
        addFeedback: 'Add Feedback',
        emptyStateMessage: 'No feedback has been received for this lawyer.',
        getRequestError: 'We could not load feedback for this lawyer',
        searchPlaceholder: 'Seach Lawyer Feedback',
        deleteFileSuccess: 'File was deleted',
        deleteFileError: 'File could not be deleted, please try again later',
      },
      AddLawyerFeedback: {
        title: 'Add Feedback',
        editTitle: 'Edit Feedback',
        discard: 'Cancel',
        save: 'Add Feedback',
        edit: 'Save Feedback',
        requestError: 'We could not add new feedback',
        requestSuccess: 'Feedback has been added',
        caseRequestError: 'We could not load the case data.',
        requestErrorNoCase:
          'The case linked with this feedback is not associated with this lawyer',
        clientRequestError: 'We could not load the client data.',
      },
      DeleteLawyerFeedback: {
        title: 'Delete Feedback',
        message:
          'Are you sure you want to delete this feedback? This cannot be undone.',
        requestError: 'We could not delete the feedback',
        requestSuccess: 'Feedback has been deleted',
      },
      LawyerFeedbackForm: {
        caseNumber: 'Case Number',
        caseNumberValidation: 'Please enter a valid case number.',
        caseNumberConfirmButton: 'Go',
        sourceOfComment: 'Source of Comment',
        sourceOfCommentValidation: 'Required',
        typeOfComment: 'Type of Comment',
        typeOfCommentValidation: 'Required',
        positive: 'Positive',
        negative: 'Negative',
        negativeReason: 'Negative Reason',
        negativeReasonValidation: 'Required',
        resolvedHow: 'Resolution Form',
        resolvedHowValidation: 'Required',
        resolvedBy: 'Resolution Method',
        resolvedByValidation: 'Required',
        dateClosed: 'Date Closed',
        dateClosedValidation: 'Invalid date',
        notes: 'Notes',
        notesValidation: 'Required',
        documentsTitle: 'Attachments',
        documentsEmptyState: 'There are no files attached',
        documentsUpload: 'Upload File',
      },
      LawyerFeedbackMetadata: {
        cif: 'Case:',
        legalProblemCode: 'LP Code:',
        dateOpened: 'Open Date:',
        dateClosed: 'Close Date:',
      },
      LawyerFeedbackCard: {
        author: 'Note from:',
        authorEmptyState: 'Unknown',
        expand: 'Expand',
        contentEmptyState:
          'The author did not provide a note for this feedback.',
        refNumber: `Reference Number:`,
        dateCreated: 'Feedback Created:',
      },

      LawyerFeedbackProperties: {
        sourceOfComment: 'Source of Comment',
        typeOfComment: 'Type of Comment',
        negativeReason: 'Negative Reason',
        resolvedHow: 'Resolved How',
        resolvedBy: 'Resolved By',
        dateOpened: 'Date Opened',
        dateClosed: 'Date Closed',
        positive: 'Positive',
        negative: 'Negative',
      },
      LawyerPrintView: {
        title: 'Lawyer ID: {{lawyerId}}',
        staffTitle: 'Staff Lawyer ID: {{lawyerId}}',
        details: {
          title: 'Lawyer Information',
          phone: 'Phone Number',
          email: 'Email',
          city: 'City',
          province: 'Province',
          languages: 'Language(s)',
          dateOpened: 'Date Opened',
          calledToBar: 'Called to Bar',
          customaryFee: 'Customary Fee',
          payFirm: 'Pay Firm',
          mailingAddress: 'Mailing Address',
          addressSameAsFirm: 'Same as firm',
          notes: 'Notes',
        },
        firm: {
          title: 'Law Firm',
          name: 'Name',
          phone: 'Phone Number',
          fax: 'Fax Number',
          address: 'Address',
        },
        panels: {
          title: 'Panels',
        },
        languages: {
          english: 'English',
          french: 'French',
          other: 'Other',
        },
        status: {
          active: 'Active',
          inactive: 'Inactive',
        },
      },
      ChequeRunPage: {
        title: 'Cheque Run',
        heading: 'Cheque Run',
        breadcrumbs: {
          chequeRun: 'Cheque Run',
        },
        getLockRequestError: 'Failed to get the system lock status',
        lockRequestSuccess: 'System locked',
        unlockRequestSuccess: 'System unlocked',
        lockRequestError: 'We could not lock the system',
        unlockRequestError: 'We could not unlock the system',
      },
      ChequeRunCurrent: {
        title: 'Current',
        start: 'Start Current Run',
        modalTitle: 'Current Cheque Run',
      },
      ChequeRunLast: {
        title: 'Last Run',
        start: 'Start Last Run',
        modalTitle: 'Last Cheque Run',
        reprintModalTitle: 'Reprint Cheque',
      },
      ChequeRunHistory: {
        title: 'History',
      },
      ChequeRunView: {
        columns: {
          Payee: 'Payee',
          Amount: 'Amount',
          Date: 'Date',
          Client: 'Client',
        },
        emptyStateMessage: 'There are no cheques available to print.',
        searchPlaceholder: 'Search Payee',
      },
      ChequeRunModal: {
        dismiss: 'Dismiss',
        lockAlert:
          "Changes cannot be saved while\nthe Cheque Run is active.\n\nUsers can still access information, but changes\nwon't be saved until the system is unlocked.",
        lockCancelButtonTitle: 'Take me back',
        lockConfirmButtonTitle: 'Lock & Continue',
        lockConfirmButtonTitleActive: 'Locking...',
        unlockButtonTitle: 'Unlock & Exit',
        unlockButtonTitleActive: 'Unlocking...',
        confirmDeleteRunTitle: 'Are you sure?',
        confirmDeleteRunMessage:
          'The current Cheque Run will be cancelled\nand no cheques will be generated.',
        confirmDeleteRunButtonTitle: 'Cancel Run',
        confirmDeleteRunButtonTitleActive: 'Cancelling Run...',
        cancelDeleteRunButtonTitle: 'Take Me Back',
      },
      ChequeRunActions: {
        chequeNumber: 'First Cheque #',
        chequeNumberValidation: 'Required',
        unlockButtonTitle: 'Unlock & Exit',
        unlockButtonTitleActive: 'Unlocking...',
        createRunButtonTitle: 'Create PDFs',
        createRunButtonTitleActive: 'Creating PDFs...',
      },
      ChequeRunResults: {
        failedCheques: {
          message: '{{count}} cheques failed to print.',
          item: '{{count}}: {{reason}}',
        },
        reports: {
          emptyState: 'No reports were created.',
          download: 'Download',
          status: {
            complete: 'Complete',
            failed: 'Failed',
          },
        },
        showDetails: 'Show Details',
        hideDetails: 'Hide Details',
        cancelButtonTitle: 'Cancel',
        downloadAll: 'Download All',
        confirmButtonTitle: 'Complete & Unlock',
        confirmButtonTitleActive: 'Completing...',
        copyError: 'Copy Error',
      },
      ChequeRunError: {
        cancelButtonTitle: 'Cancel',
        cancelButtonTitleActive: 'Cancelling...',
        cancelPendingRunButtonTitle: 'Cancel Pending Cheque Run',
        cancelPendingRunButtonTitleActive: 'Cancelling...',
        pendingRunError:
          'There is already a pending cheque run. Please cancel it before starting a new cheque run.',
        copyError: 'Copy Error',
      },
      IssueCheque: {
        title: 'Issue Cheque',
        emptyState: 'No cheques have been issued for case {{caseNumber}}.',
        caseOptionsRequestError: 'We could not load the case options.',
        caseRequestError: 'We could not load the case data.',
        clientRequestError: 'We could not load the client information.',
        lawyerRequestError: 'We could not load the lawyer information.',
        patchClientBillingSuccess: 'Updated the client billing information',
        patchClientBillingError:
          'We could not update the client billing information.',
        chargedToClient: 'Charged to Client:',
        chargeValidation: 'Enter a valid number',
        clientHours: 'Hours',
        clientFees: 'Fees',
        newCheque: 'New Cheque',
        reverseCheque: 'Reverse Cheque',
        breadcrumbs: {
          issueCheque: 'Issue Cheque',
        },
        columns: {
          chequeNumber: 'Number',
          payee: 'Payee',
          date: 'Date',
          total: 'Amount',
          status: 'Status',
        },
        NewChequeModal: {
          title: 'New Cheque',
          saveButton: 'Issue Cheque',
          saveButtonActive: 'Issuing Cheque...',
          closeButton: 'Close',
          cancelButton: 'Take me back',
          payee: 'Payee',
          payeePlaceholder: 'Select Payee',
          payeeValidation: 'Select a payee',
          date: 'Date',
          dateValidation: 'Select a date',
          chequeNumber: 'Cheque Number',
          chequeNumberValidation: 'Must be 6 digits',
          amountValidation: 'Must be a number',
          hours: 'Hours',
          fee: 'Fee',
          gst: 'GST',
          pst: 'PST',
          total: 'Total',
          recipientInformation: 'Recipient Information',
          prefix: 'Prefix',
          prefixPlaceholder: 'None',
          firstName: 'First Name',
          firstNameValidation: 'Required, 2 to 150 characters',
          lastName: 'Last Name',
          lastNameValidation: 'Required, 2 to 150 characters',
          firmName: 'Law Firm Name',
          firmNameValidation: 'Required, 150 or fewer characters',
          address: 'Street Address',
          addressValidation: 'Required, 150 or fewer characters',
          address2: 'Street Address 2',
          address2Validation: '150 or fewer characters',
          city: 'City',
          cityValidation: 'Required, 20 or fewer characters',
          postalCode: 'Postal Code',
          postalCodeValidation: 'Required',
          province: 'Province',
          provinceValidation: 'Required',
          provincePlaceholder: 'Select',
          chequesRequestError: 'We could not load previously issued cheques',
          postChequeSuccess: 'Cheque issued',
          postChequeError: 'We could not issue the cheque.',
          chequeReversalSuccess: 'Cheque {{chequeNumber}} reversed',
          chequeReversalError: 'We could not reverse cheque {{chequeNumber}}.',
          newChequeReversalSuccess: 'Cheque reversed',
          newChequeReversalError: 'We could not reverse the cheque.',
        },
        ReverseChequeModal: {
          title: 'Reverse Cheque',
          text:
            'Cheque number {{chequeNumber}} paid to {{firstName}} {{lastName}} will be reversed. This action cannot be undone.',
          reverse: 'Reverse Cheque',
          reversing: 'Reversing Cheque...',
        },
      },
      PrimaryClientIntegrity: {
        title: 'Primary Client Integrity Report',
        printTitle: 'UniforLSP CASE',
        printHeading: 'Primary Client Integrity Report',
        emptyState: 'No primary client integrity issues were found.',
        getDataIntegrityReportRequestError:
          'We could not load the primary client integrity report.',
        postClientStatusRequestSuccess: 'Updated primary client status',
        postClientStatusRequestError:
          'We could not update the primary client status',
        update: 'Update',
        updated: 'Updated',
        breadcrumbs: {
          primaryClientIntegrity: 'Primary Client Integrity Report',
        },
        ClientStatusModal: {
          title: 'Update Primary Client Status',
          cif: 'CIF:',
          caseClientStatus: 'Case Client Status:',
          primaryClientStatus: 'Primary Client Status:',
          primaryClientStatusValidation: 'Status should be 1, 2, 3 or 6',
          cancelButton: 'Cancel',
          saveButton: 'Save',
          saveButtonActive: 'Saving...',
        },
      },
      CSQEntry: {
        title: 'CSQ Entry',
        breadcrumbs: {
          csqEntry: 'CSQ Entry',
        },
        clientNameAddress: 'Client Name & Address:',
        caseRequestError: 'We could not load the case information.',
        clientRequestError: 'We could not load the client information.',
        getPreviousCSQError:
          'We were unable to check whether a previous CSQ was entered for this case.',
        postCsqSuccess: 'CSQ saved',
        postCsqError: 'We were unable to save the CSQ.',
        conflict: 'A CSQ has already been entered for case {{caseNumber}}.',
        notes: 'Comments:',
        radioValidation: 'Required',
        csqSubmittedMessage:
          'CSQ has already been submitted and cannot be submitted again',
        caseCard: {
          clientId: 'ID:',
          submitDate: 'Entry Date:',
          lpCode: 'LP Code:',
          lpCodeDesc: 'LP Code Description:',
          lawyerId: 'Lawyer ID:',
          lawyerName: 'Lawyer Name:',
        },
      },
      LawFirmListPage: {
        title: 'Law Firms',
        searchPlaceholder: 'Search Firms',
        searchError: 'We could not complete your search',
        emptyStateMessage: 'We could not find any results for your search.',
        breadcrumbs: {
          firms: 'Law Firms',
        },
        sortMenu: {
          firmAsc: 'Firms Ascending ',
          firmDesc: 'Firms Descending ',
        },
        activeDropdown: {
          allFirms: 'All Firms',
          activeFirms: 'Active Firms',
          inactiveFirms: 'Inactive Firms',
        },
      },
      LawFirmDetailsPage: {
        title: 'Law Firm Details',
        subtitle: 'Law Firm',
        newTitle: 'New Law Firm',
        formTitle: 'Information',
        firmName: 'Firm Name',
        address: 'Street Address',
        address2: 'Street Address 2',
        city: 'City',
        email: 'Email',
        fax: 'Fax',
        postalCode: 'Postal Code',
        primaryPhone: 'Primary Phone',
        province: 'Province',
        secondaryPhone: 'Secondary Phone',
        firmNameValidation: 'Required, 150 or fewer characters',
        addressValidation: 'Required, 50 or fewer characters',
        address2Validation: '30 or fewer characters',
        cityValidation: 'Required, 50 or fewer characters',
        emailValidation: 'Required',
        postalCodeValidation: 'Required',
        primaryPhoneValidation: 'Required',
        provinceValidation: 'Required',
        requestPatchSuccess: 'Law firm has been updated',
        requestPostSuccess: 'Law firm has been created',
        requestPatchError: 'Law firm could not be updated',
        requestPostError: 'Law firm could not be created',
        requestError: 'Something went wrong',
        DeactivateModal: {
          title: 'Set Law Firm as Inactive?',
          message:
            'This will flag the law firm as inactive throughout the system. This action can be undone.',
        },
        LinkedLawyerList: {
          title: 'Linked Lawyers',
          emptyStateMessage: 'This law firm has no associated lawyers',
          columns: {
            lawyerId: 'Lawyer ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            city: 'City',
            province: 'Province',
          },
        },
      },
      LawFirmsListPrintView: {
        title: 'UNIFOR LSP',
        heading: 'Law Firms',
        emptyStateTitle: 'There are no law firm records to print.',
        columns: {
          name: 'Name',
          address: 'Address',
          email: 'Email',
          phone: 'Phone',
          fax: 'Fax',
        },
      },
      LawFirmDetailsPrintView: {
        title: 'Law Firm',
        status: {
          active: 'Active',
          inactive: 'Inactive',
        },
        contact: {
          title: 'Contact Info',
          primaryPhone: 'Primary Phone',
          secondaryPhone: 'Secondary Phone',
          fax: 'Fax',
          email: 'Email',
          address: 'Address',
        },
        LinkedLawyers: {
          heading: 'Linked Lawyers',
          emptyStateTitle: 'There are no lawyers linked to {{firmName}}.',
          columns: {
            lawyerId: 'Lawyer ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            city: 'City',
            province: 'Province',
          },
        },
      },
      CompaniesListPage: {
        title: 'Companies',
        searchPlaceholder: 'Search by ID or Company Name',
        searchError: 'We could not complete your search',
        emptyStateMessage: 'We could not find any results for your search.',
        breadcrumbs: {
          companies: 'Companies',
        },
        sortMenu: {
          codesAsc: 'Codes Ascending ',
          codesDesc: 'Codes Descending ',
          companyAsc: 'Company Ascending ',
          companyDesc: 'Company Descending ',
        },
      },
      CompanyDetailsPage: {
        title: 'Company Details',
        subtitle: 'Company',
        newTitle: 'New Company',
        formTitle: 'Information',
        companyCode: 'Number',
        companyCodeValidation: 'Required, 2 to 3 characters',
        companyName: 'Company Name',
        companyNameValidation: 'Required, 30 or fewer characters',
        primaryPhone: 'Primary Phone',
        primaryPhoneValidation: 'Required',
        secondaryPhone: 'Secondary Phone',
        secondaryPhoneValidation: 'Invalid phone',
        fax: 'Fax',
        faxValidation: 'Invalid fax',
        address: 'Street Address',
        addressValidation: 'Required, 30 or fewer characters',
        address2: 'Street Address 2',
        address2Validation: '30 or fewer characters',
        printAddress: 'Address',
        city: 'City',
        cityValidation: 'Required, 20 or fewer characters',
        province: 'Province',
        provinceValidation: 'Required',
        postalCode: 'Postal Code',
        postalCodeValidation: 'Required',
        benefitStartDate: 'Benefit Start Date',
        benefitStartDateValidation: 'Required',
        benefitEndDate: 'Benefit End Date',
        benefitEndDateValidation: 'Invalid date',
        feeSchedule: 'Fee Schedule',
        feeScheduleVersion: 'Version',
        missingFeeScheduleMessage:
          'This company is not associated with a fee schedule.',
        requestPatchSuccess: 'Company has been updated',
        requestPostSuccess: 'Company has been created',
        requestPatchError: 'Company could not be updated',
        requestPostError: 'Company could not be created',
        requestError: 'Something went wrong',
        breadcrumbs: {
          new: 'New',
          details: 'Details',
        },
        DeactivateModal: {
          title: 'Set Company as Inactive?',
          message:
            'This will flag the company as inactive throughout the system. This action can be undone.',
        },
        LinkedUnionsList: {
          title: 'Linked Unions',
          manageUnionsButton: 'Manage Unions',
          emptyStateMessage: 'This company has no associated unions.',
          requestError: 'We could not load the linked unions',
          columns: {
            unionNumber: 'Union',
            unionName: 'Name',
            locCity: 'Location',
          },
        },
        ManageUnionsModal: {
          dismiss: 'Dismiss',
          title: 'Unions',
          getRequestError: 'We could not load the unions for this company.',
          postRequestSuccess: 'The unions for this company have been updated.',
          postRequestError: 'We could not update the unions for this company.',
          emptyStateMessage: 'This company has no associated unions.',
          filterInputPlaceholder: 'Search by Code, Union Name, or Location',
          cancelButton: 'Discard Changes',
          saveButton: 'Save and Close',
          saveButtonActive: 'Saving...',
          columns: {
            unionId: 'Code',
            unionName: 'Name',
            unionCity: 'Location',
          },
        },
      },
      CompaniesListPrintView: {
        title: 'UNIFOR LSP',
        heading: 'Companies',
        emptyStateTitle: 'There are no company records to print.',
        columns: {
          code: 'Number',
          name: 'Name',
          address: 'Address',
          phone: 'Phone',
          fax: 'Fax',
          benefitStart: 'Benefit Start Date',
          benefitEnd: 'Benefit End Date',
        },
      },
      OfficesListPage: {
        title: 'Offices',
        heading: 'Offices',
        breadcrumbs: {
          offices: 'Offices',
        },
        getRequestError: 'We could not load the list of offices.',
        patchRequestSuccess: 'The office was updated.',
        patchRequestError: 'We could not update the office.',
        postRequestSuccess: 'The office was created.',
        postRequestError: 'We could not create the office.',
      },
      OfficeCard: {
        expand: 'Expand',
        cancel: 'Cancel',
        codePlaceholder: '0000',
        codeValidation: '4 digits',
        namePlaceholder: 'New office',
        nameValidation: 'Required, 30 or fewer characters',
        primaryPhone: 'Primary Phone',
        primaryPhoneValidation: 'Required',
        secondaryPhone: 'Secondary Phone',
        secondaryPhoneValidation: 'Invalid phone',
        fax: 'Fax',
        faxValidation: 'Invalid fax',
        officeType: 'Head Office',
        officeTypeValidation: 'Required',
        yes: 'Yes',
        no: 'No',
        addressLine1: 'Street Address',
        addressLine1Validation: 'Required, 30 or fewer characters',
        addressLine2: 'Street Address 2',
        addressLine2Validation: '30 or fewer characters',
        city: 'City',
        cityValidation: 'Required, 20 or fewer characters',
        province: 'Province',
        provincePlaceholder: 'Select ',
        provinceValidation: 'Required',
        postalCode: 'Postal Code',
        postalCodeValidation: 'Required',
        DeactivateModal: {
          title: 'Set Office as Inactive?',
          message:
            'This will flag the office as inactive throughout the system. This action can be undone.',
        },
      },
      ActivityIndicatorCard: {
        defaultMessage: 'Loading\u2026',
      },
      NotesSortMenu: {
        dateAsc: 'Date Ascending',
        dateDesc: 'Date Descending',
        nameAsc: 'Author Ascending',
        nameDesc: 'Author Descending',
      },
      LawyerCaseHistorySortMenu: {
        dateAsc: 'Date Ascending',
        dateDesc: 'Date Descending',
      },
      FeedbackSortMenu: {
        dateAsc: 'Date Ascending',
        dateDesc: 'Date Descending',
        authorAsc: 'Author Ascending',
        authorDesc: 'Author Descending',
      },
      ClientSummary: {
        clientId: 'ID:',
        clientStatus: 'Status:',
        dateOfBirth: 'Date of Birth:',
        employeeNumber: 'Employee #:',
        feeSchedule: 'Fee Schedule:',
        local: 'Local:',
        seniorityDate: 'Seniority Date:',
        version: 'Version',
      },
      LawyerSummary: {
        staffId: 'Staff Lawyer ID: {{staffId}}',
        lawyerId: 'Lawyer ID: {{lawyerId}}',
        emptyState: 'No Lawyer Selected',
        nonCoopLawyer: 'Non-Co-operating Lawyer',
      },
      LawFirmSummary: {
        barristerSolicitor: 'Barrister & Solicitor',
        barristerSolicitorNamePlaceholder: 'Barrister & Solicitor',
        barristerSolicitorNameValidation:
          'Name must contain 60 or fewer characters.',
        staffLawyer: 'Staff Lawyer',
      },
      Modal: {
        cancelButtonText: 'Cancel',
      },
      SaveModal: {
        titleText: 'Save Changes?',
        cancelButtonText: 'Cancel',
        discardButtonText: 'Discard Changes',
        proceedButtonText: 'Save',
      },
      NavigationModal: {
        titleText: 'Leave Page?',
        cancelButtonText: 'Cancel',
        discardButtonText: 'Discard Changes',
        proceedButtonText: 'Proceed',
      },
      NavigationSaveModal: {
        titleText: 'Save Changes?',
        messageText: 'You may lose unsaved changes if you leave this page.',
        proceedButtonText: 'Save & Continue',
      },
      NoteModal: {
        title: 'Add Note',
        heading: 'Enter the Note Below:',
        discardButtonText: 'Cancel',
        saveButtonText: 'Add',
      },
      PrintFooter: {
        line1: 'UNIFOR LSP',
        line2: 'Private and Confidential',
      },
      ClientLocal: {
        requestError: 'We could not load the local data',
      },
      FeedbackReportPage: {
        title: 'Feedback Reports',
        emptyStateMessage: 'No Reports Found',
        run: 'Run',
        feedbackLawyer: 'Feedback by Lawyer',
        closedCases: 'Closed Cases',
        openedCases: 'Opened Cases',
        positiveFeedbackLawyer: 'Positive Feedback',
        legalProblemCode: 'Legal Problem Code Report',
        yearEnd: 'Year End',
        requestSuccess: 'Feedback report ran successfully ',
        requestError: 'Feedback report could not be run',
        modal: {
          lawyerId: 'Lawyer Id',
          legalProblemCode: 'Legal Problem',
          startDate: 'From',
          endDate: 'To',
          lawyerIdValidation: 'Lawyer Id is required',
          lawyerIdLengthValidation: 'Lawyer Id is 4 or 6 characters',
          legalProblemCodeValidation: 'Legal Problem is required',
          startDateValidation: 'Required',
          endDateValidation: 'Required',
          yearEndFeedbackReportType: 'Select One',
          yearEndFeedbackReportTypeValidation: 'CBO Required',
          cancel: 'Cancel',
          runButton: 'Run Report',
          running: 'Running...',
        },
        cboOptions: {
          LawyerType: 'Lawyer Type',
          ResolvedBy: 'Resolved By',
          ResolvedHow: 'Resolved How',
          TypeOfComplaints: 'Type of Complaints',
        },
      },
      ReportsPage: {
        title: 'Reports',
        emptyStateTitle: 'Select a folder to get started.',
        emptyStateSubtitle: 'Find and select folders in the left-hand sidebar.',
        getFoldersRequestError: 'We were unable to load the folders.',
      },
      ReportsFoldersList: {
        title: 'Folders',
        newFolder: 'New Folder',
        getRequestError: 'We were unable to load the report folders.',
        searchPlaceholder: 'Search Report Folders',
        emptyStateMessage: 'No Folders Found',
        emptyStateSubtitle: 'Add a new folder to get started.',
        sortMenu: {
          folderAsc: 'Folders Ascending ',
          folderDesc: 'Folders Descending ',
        },
        owner: 'Owner: {{firstName}} {{lastName}}',
      },
      ReportsFolderDetail: {
        deleteFolder: 'Delete Folder',
        deleteFolderModal: {
          title: 'Delete Folder',
          text:
            'Are you sure you want to delete this folder? This cannot be undone.',
          requestSuccess: 'Folder was deleted',
          requestError: 'Folder could not be deleted',
        },
        reportResultModal: {
          title: 'Your report is ready',
          text: 'Download your report or open it in a new browser tab.',
          download: 'Download Report',
          open: 'Open Report',
          done: 'Done',
        },
        folderNameValidation:
          'Folder name must be between 1 and 16 characters in length.',
        getFolderRequestError: 'We were unable to load the folder.',
        postFolderRequestSuccess: 'Folder created successfully',
        postFolderRequestError: 'We were unable to create the folder.',
        postFolderFrequencyRequestSuccess:
          'Report frequency updated successfully',
        postFolderFrequencyRequestError:
          'We were unable to update the frequency for the reports in this folder.',
        putFolderRequestSuccess: 'Folder updated successfully',
        putFolderRequestError: 'We were unable to update the folder.',
        deleteFolderRequestSuccess: 'Folder deleted successfully',
        deleteFolderRequestError: 'We were unable to delete the folder.',
        putReportItemRequestSuccess: 'Report updated successfully',
        putReportItemRequestError: 'We were unable to update the report.',
        moveReportItemRequestSuccess: 'Report moved successfully',
        moveReportItemRequestError: 'We were unable to move the report.',
        duplicateReportItemRequestSuccess: 'Report duplicated successfully',
        duplicateReportItemRequestError:
          'We were unable to duplicate the report.',
        deleteReportItemRequestSuccess: 'Report deleted successfully',
        deleteReportItemRequestError: 'We were unable to delete the report.',
        saveAsRequestSuccess: 'Report was saved successfully.',
        saveAsRequestError: 'Report could not be saved.',
        runReportItemRequestError: 'We were unable to run the report.',
        emptyStateMessage: 'No Reports Found',
        emptyStateSubtitle: 'Add a new report to get started.',
      },
      ReportsList: {
        run: 'Run',
        filters: {
          all: 'All Reports',
          generateMonthly: 'Monthly',
          generateFirstQuarterly: '1st Quarter',
          generateHalfYearly: 'Half Year',
          generateThirdQuarterly: '3rd Quarter',
          generateYearly: 'Yearly',
        },
        dateRanges: {
          generateMonthly: 'M',
          generateFirstQuarterly: '1',
          generateHalfYearly: 'H',
          generateThirdQuarterly: '3',
          generateYearly: 'Y',
        },
        modifyOptions: {
          modify: 'Modify',
          edit: 'Edit',
          saveAs: 'Save As',
          move: 'Move',
          duplicate: 'Duplicate',
          delete: 'Delete',
        },
      },
      RunEditReportModal: {
        getReportTableFieldsError:
          'We were unable to load the fields for this report.',
        editTitle: 'Edit Report',
        runTitle: 'Run {{reportName}} Report',
        reportName: 'Report Name',
        saveAs: 'Save As',
        fieldName: 'Field Name',
        operator: 'Operator',
        value: 'Value',
        insert: 'Insert',
        and: 'And',
        or: 'Or',
        script: 'Script:',

        fieldNameError: 'Field Name is required',
        operatorError: 'Operator is required',
        valueError: 'This operation requires a value',
        scriptError: 'Script can not be empty',
        reportNameError: 'Report Name must be between 1 and 30 characters',
        runRequestError: 'Report could not be run',

        firstQuart: 'First Quarter',
        halfYear: 'Half Year',
        thirdQuart: 'Third Quarter',
        month: 'Month',
        yearly: 'Yearly',
        custom: 'Custom',
        endDate: 'To',
        startDate: 'From',
        dateRange: 'Date Range:',
        status: 'Status',
        saveAndRun: 'Save and Run',
        run: 'Run',
        running: 'Running',

        endDateValidation: 'End date is required',
        startDateValidation: 'Start Date is required',
        statusValidation: 'Status is required',

        operators: {
          equal: 'Equal to',
          notEqual: 'Not Equal to',
          greaterThan: 'Greater than',
          greaterOrEqual: 'Greater or Equal',
          lessOrEqual: 'Less or Equal',
          lessThan: 'Less than',
          begins: 'Begins with',
          contains: 'Contains',
          notContains: 'Does Not Contain',
          like: 'Like',
          isEmpty: 'Is Empty',
          notEmpty: 'Is Not Empty',
          oneOf: 'Is One Of',
        },

        formats: {
          PDF: 'PDF',
          Xml: 'XML',
          Word: 'MS Word',
          Excel: 'MS Excel',
          RichText: 'Rich Text',
          EditableRichText: 'Editable Rich Text',
        },
      },
      SaveAsModal: {
        getReportTableFieldsError:
          'We were unable to load the fields for this report.',
        title: 'Save As Report',
        origin: 'Origin',
        destination: 'Destination',
        reportName: 'Report Name',
        owner: 'Owner',
        folderName: 'Folder Name',
        nextReportName: 'New Report Name',
        nextFolderName: 'Folder Name',
        nextFolderPlaceholder: 'Select existing folder',
        newFolderButton: 'Save to New Folder',
        existingFolderButton: 'Save to Existing Folder',
        newFolderName: 'New Folder Name',

        nextReportNameError: 'New report name required.',
        nextFolderIdError: 'Please select a folder.',
        newFolderNameError: 'Please enter the name for your new folder.',
      },
      MoveReportModal: {
        title: 'Move Report',
        text: 'Choose a folder below to move your report to',
        moveText: `Move the report to the folder {{folderName}}?`,
        moveButton: 'Move Report',
        getFoldersRequestError: 'We were unable to load the folders.',
        searchPlaceholder: 'Search Report Folders',
        emptyStateMessage: 'No Folders Found',
        owner: 'Owner: {{firstName}} {{lastName}}',
        sortMenu: {
          folderAsc: 'Folders Ascending ',
          folderDesc: 'Folders Descending ',
        },
      },
      DuplicateReportModal: {
        title: 'Duplicate Report',
        text:
          'Are you sure you want to duplicate this report? The new report will appear in the list below.',
        duplicateButton: 'Duplicate',
      },
      DeleteReportModal: {
        title: 'Delete Report',
        text:
          'Are you sure you want to delete this report? This cannot be undone.',
      },
      ProvincesList: {
        title: 'Province Codes',
        heading: 'Province Codes',
        printTitle: 'System Settings',
        printHeading: 'Province Codes',
        emptyStateMessage: 'There are no provinces to show.',
        breadcrumbs: {
          provinces: 'Province Codes',
        },
        getProvincesRequestError: 'We could not load the list of provinces.',
      },
      ProvinceDetail: {
        title: 'Province',
        newCity: 'New City',
        emptyStateMessage: 'There are no cities to show.',
        breadcrumbs: {
          province: 'Province',
        },
        getProvinceRequestError: 'We could not load the province.',
        postProvinceRequestSuccess: 'The province was created.',
        postProvinceRequestError: 'We could not create the province.',
        patchProvinceRequestSuccess: 'The province was updated.',
        patchProvinceRequestError: 'We could not update the province.',
        provinceIdPlaceholder: 'ID',
        provinceIdValidation: '1 char',
        provinceNamePlaceholder: 'Province',
        provinceNameValidation: 'Required, 30 or fewer characters',
        DeactivateProvinceModal: {
          title: 'Set Province as Inactive?',
          message:
            'This will flag the province as inactive throughout the system. This action can be undone.',
        },
        postCityRequestSuccess: 'The city was created.',
        postCityRequestError: 'We could not create the city.',
        patchCityRequestSuccess: 'The city was updated.',
        patchCityRequestError: 'We could not update the city.',
        cityCodePlaceholder: 'ID',
        cityCodeValidation: '1 char',
        cityNamePlaceholder: 'City',
        cityNameValidation: 'Required, 30 or fewer characters',
        DeactivateCityModal: {
          title: 'Set City as Inactive?',
          message:
            'This will flag the city as inactive throughout the system. This action can be undone.',
        },
      },
      Print: {
        errors: {
          cifData: 'Unable to load the CIF data: {{message}}',
          csqData: 'Unable to load the CSQ data: {{message}}',
          ntpData: 'Unable to load the NTP data: {{message}}',
          userNotFound: 'User not found.',
          userNoOffice: 'User is not assigned to an office.',
          userNotHeadOffice: 'User is not assigned to the head office.',
        },
      },
      UnionListPage: {
        title: 'Unions',
        searchPlaceholder: 'Search Unions',
        searchError: 'We could not complete your search',
        emptyStateMessage: 'We could not find any results for your search.',
        breadcrumbs: {
          unions: 'Unions',
        },
        sortMenu: {
          unionNameAsc: 'Unions Ascending ',
          unionNameDesc: 'Unions Descending ',
          unionNumberAsc: 'Codes Ascending ',
          unionNumberDesc: 'Codes Descending ',
        },
      },
      UnionDetailsPage: {
        title: 'Union Details',
        subtitle: 'Union',
        newTitle: 'New Union',
        formTitle: 'Information',
        unionNumber: 'Number',
        unionName: 'Union Name',
        locAddr1: 'Street Address',
        locAddr2: 'Unit #',
        locCity: 'City',
        locProv: 'Province',
        locPcode: 'Postal Code',
        locPhone1: 'Primary Phone',
        locPhone2: 'Secondary Phone',
        locFax: 'Fax',
        breadcrumbs: {
          new: 'New',
          details: 'Details',
        },
        unionNumberValidation: 'Required, 4 or fewer characters',
        unionNameValidation: 'Required, 30 or fewer characters',
        locAddr1Validation: 'Required, 30 or fewer characters',
        locAddr2Validation: '30 or fewer characters',
        locCityValidation: 'Required, 20 or fewer characters',
        locProvValidation: 'Required',
        locPcodeValidation: 'Invalid postal code',
        locPhoneValidation: 'Invalid phone',
        locFaxValidation: 'Invalid fax number',
        requestPatchSuccess: 'Union has been updated',
        requestPostSuccess: 'Union has been created',
        requestPatchError: 'Union could not be updated',
        requestPostError: 'Union could not be created',
        requestError: 'Something went wrong',
        DeactivateModal: {
          title: 'Set Union as Inactive?',
          message:
            'This will flag the union as inactive throughout the system. This action can be undone.',
        },
        LinkedCompanyList: {
          title: 'Linked Companies',
          emptyStateMessage: 'This union has no associated companies.',
          emptyStateNewMessage: 'Create new union to view linked companies.',
          columns: {
            companyCode: 'Code',
            companyName: 'Name',
            coCity: 'City',
            coProv: 'Province',
          },
        },
      },
      UnionsListPrintView: {
        title: 'UNIFOR LSP',
        heading: 'Unions',
        emptyStateTitle: 'There are no union records to print.',
        columns: {
          number: 'Number',
          name: 'Name',
          address: 'Address',
          phone: 'Phone',
          fax: 'Fax',
        },
      },
      PrintLayouts: {
        // CSQ strings should not be localized and are stored in
        // js/components/print/csq/language
        CIF: {
          header: {
            title: 'UNIFOR Legal Services Plan',
            heading: 'Case Information Form - Case No. {{cif}}',
            legalProblemCode: 'LP Code',
            intakeId: 'Intake ID:',
            dateOpened: 'Opened:',
          },
          partA: {
            title: 'Part A - Plan Participant Data',
            status: 'Status:',
            clientId: 'ID:',
            email: 'Email:',
            phone: 'Phone:',
            company: 'Company:',
            local: 'Local:',
            seniorityDate: 'Seniority Date:',
            employeeNumber: 'Employee #:',
            specialInstructions: 'Special Instructions:',
          },
          partB: {
            title: 'Part B - Lawyer Activity Report',
            firm: 'Firm:',
            lawyerId: 'Lawyer ID:',
            phone: 'Phone:',
            staffLawyer: 'Staff Lawyer ID: {{staffLawyerId}}',
            nonCoopLawyer: 'Non-Co-Operating Lawyer',
            nonCoopNotary: 'Non-Co-Operating Notary',
            outsideCanada: 'Lawyer Outside Canada',
            noLawyer: 'No Lawyer Assigned',
            servicesProvided: {
              heading: 'Client Contact/Services Provided (Select One):',
              noServicesProvided: '1. No Service Provided',
              notarizationOnly: '2. Notarization only, no advice',
              adviceOnly: 'Advice only',
              telephone: '3.a) Telephone',
              inPerson: '3.b) In Person',
              moreThanAdvice: 'More than advice:',
              officeWorkOnly: '4.a) Office Work Only',
              lawyerOnRecord: '4.b) Lawyer on Record (Court or Tribunal)',
              legalProblemOutcome:
                '5. Nature of the Legal Problem and Outcome:',
            },
          },
          partC: {
            title: 'Part C - Summary Billing Report',
            hours: 'Hours',
            hourlyRate: 'Hourly Rate',
            amount: 'Amount',
            referralBenefit: 'Referral Benefit Bill Client',
            tax: 'HST/GST',
            total: 'Total',
            chargeToPlan: {
              heading: 'Charge to Plan for Covered Services*:',
              instructions: '*Attach detailed account',
            },
            chargeToClient: {
              heading: 'Charge to Client for Non-covered Services**:',
              instructions: '**Attach summary of billing to client',
            },
          },
          partD: {
            title: 'For UNIFOR-LSP OFFICE USE ONLY',
            transferDate: 'Transfer Date:',
            invoiceDate: 'Invoice Date:',
            planTotal: 'Plan Total:',
            csqSent: 'CSQ Sent:',
            referralReason: 'R.R.',
            declarationSent: 'Declaration Sent',
            cifSentTo: 'CIF Sent To:',
            payee: 'Payee:',
            client: 'Client',
            lawyer: 'Lawyer',
            lawFirm: 'Law Firm',
            certification: {
              heading: 'Certification',
              message:
                'I certify that the information provided above is accurate and complete and that the services were actually rendered, and that no other billing has been or will be presented to the client for the services indicated on this form.',
              signature: 'Signature of Lawyer',
              date: 'Date',
            },
          },
        },
        NTP: {
          header: {
            title: 'UNIFOR Legal Services Plan',
            heading: 'Notice To Participant',
            warningHeading: 'Important',
            warningLine1: 'Contact your lawyer immediately',
            warningLine2:
              'Some cases may be jeopardized if time limits are not met',
            metadata: {
              legalProblem: 'Legal Problem:',
              participantName:
                "Participant's Name: {{lastName}}, {{firstName}}",
              caseNumber: 'Case No:',
            },
          },
          cooperatingLawyer: {
            heading: 'If you are using a Co-operating Lawyer:',
            instructions: {
              // Markdown/html supported:
              1: 'Give the enclosed documents to him or her as soon as possible. The Co-operating Lawyer cannot proceed without them. If this is a **real estate matter**, please have your lawyer **send us a copy of the Agreement of Purchase and Sale and your new mailing address.**<br />If this is a **litigation matter**, please have your Lawyer send us a copy of the Claim or Defence.',
              2: '**Plan coverage is only for the Legal Problem stated above.** See the enclosed Plan Benefit Schedule. It tells you what legal fees the **Plan pays for** and what legal fees are **your responsibility.**',
              3: 'All taxes, disbursements, and title search fees are **your responsibility.**',
              4: '**Please note that** Co-operating Lawyers are not "Unifor Lawyers".  They are not employed by the Plan.  They are lawyers in private practice in your community who have agreed to bill according to our fee schedule.  The Plan is not responsible for any act of omission by any Co-operating Lawyer.',
              5: '**The Co-operating Lawyer to whom you were referred by the Plan is:**',
            },
            metadata: {
              lawyerName: 'Name:',
              lawyerId: 'Lawyer ID:',
              lawFirm: 'Firm:',
              phone: 'Phone Number:',
              address: 'Address:',
            },
          },
          nonCooperatingLawyer: {
            heading: 'If you are using a NON-Co-operating Lawyer:',
            instructions: {
              // Markdown/html supported:
              '1a':
                "**You pay** the Lawyer directly. When you receive his or her account, submit a copy to the Plan's Head Office in Toronto for reimbursement at the following address:",
              '1b':
                "Please be sure that the Lawyer's account sets out the type of legal services provided, the time spent, and the disbursements incurred.",
              2: 'If this is a **real estate matter**, please send us a copy of the Agreement of Purchase and Sale and your new mailing address.',
              3: 'If this is a **litigation matter**, please send us a copy of the Claim or Defence.',
              4: 'You will be reimbursed in accordance with the **<span style="text-decoration: underline;">enclosed</span> Plan Reimbursement Schedule.**',
            },
            addressLines: {
              1: 'UNIFOR LEGAL SERVICES PLAN',
              2: 'Suite 600 - 1 St. Clair Ave. West',
              3: 'Toronto, Ontario M4V 3C3',
            },
            phoneLines: {
              1: '(416) 960-2410',
              2: '1 (800) 268 - 7573',
            },
            faxLine: 'Fax: (416) 960-8047',
          },
        },
      },
      dayEnd: {
        title: 'Day End',
        print: 'Print',
        running: 'Running...',
        casesOpened: `Print "Cases Opened" Report`,
        CIF: 'Print CIFs for all Opened Cases',
        NTP: 'Print Notice to Participants',
        CSQ: 'Print Client Satisfaction Questionnaires',
        emptyStateTitle: 'No Data Found',
        emptyStateSubtitle:
          'There are no {{reportType}} to present for {{date}}',
        runRequestError: 'Report could not be run',
      },
      StaffPage: {
        title: 'Staff',
        emptyTitle: 'No Staff Found',
        emptySubtitle: 'Please add staff members to view them here.',
        searchPlaceholder: 'Search ID or Staff Name',
        patchRequestSuccess: 'Staff member was updated',
        patchRequestError: 'Staff member could not be updated',
        postRequestSuccess: 'Staff member created',
        postRequestProcessing:
          'Contact RedBit to complete the enrolment process',
        postRequestError: 'Staff member could not be created',
        reinviteRequestSuccess:
          'An invitation has been sent to the staff member',
        reinviteRequestError:
          'Failed to send a new invitation to the staff member',
        SortMenu: {
          usrCodeAsc: 'ID Ascending',
          usrCodeDesc: 'ID Descending',
          usrFnameAsc: 'First Name Ascending',
          usrFnameDesc: 'First Name Descending',
          usrLnameAsc: 'Last Name Ascending',
          usrLnameDesc: 'Last Name Descending',
        },
        StaffCard: {
          expand: 'Expand',
          usrCode: 'ID',
          usrEmail: 'Email Address',
          usrFname: 'First Name',
          usrLname: 'Last Name',
          usrOfficecode: 'Office',
          usrSecur: 'Access Level',
          usrStlaw: 'Staff Lawyer',
          usrFnameValidation: 'Required, 2 to 30 characters',
          usrLnameValidation: 'Required, 30 or fewer characters',
          usrOfficecodeValidation: 'Required',
          usrSecurValidation: 'Required',
          usrCodeValidation: 'Required, 4 or fewer characters',
          invite: 'Re-invite',
          inviting: 'Sending\u2026',
        },
        security: {
          admin: 'Administrator',
          dataEntry: 'Data Entry',
          intake: 'Intake Specialist',
          viewOnly: 'View Only',
          feedback: 'Feedback Specialist',
          support: 'Support',
        },
        DeactivateModal: {
          title: 'Set Staff Member as Inactive?',
          message:
            'This will flag the staff member as inactive throughout the system. This action can be undone.',
        },
      },
      StaffListPrintView: {
        title: 'UNIFOR LSP',
        heading: 'Staff',
        emptyStateTitle: 'There are no staff records to print.',
        columns: {
          code: 'ID',
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email Address',
          office: 'Office',
          accessLevel: 'Access Level',
          staffLawyer: 'Staff Lawyer',
        },
        groups: {
          administrator: 'Administrator',
          dataEntry: 'Data Entry',
          intakeSpecialist: 'Intake Specialist',
          viewOnly: 'View Only',
          feedbackSpecialist: 'Feedback Specialist',
          support: 'Support',
        },
      },
    },
  },
};
