import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ListCard, ActivityIndicatorCard } from 'js/components/design-system';
import { FetchContext } from 'js/components/fetch';
import EmptyState from 'js/components/controls/empty-state';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { onMountEffect, getRecentItemsEffect } from './effects';

const RecentItems = () => {
  const abortControllerRef = useRef(null);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [recentItems, setRecentItems] = useState([]);

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    getRecentItemsEffect({
      t,
      api,
      setActive,
      setRecentItems,
      abortControllerRef,
    }),
    []
  );

  return (
    <div className="recent-items">
      {active && <ActivityIndicatorCard />}
      {!active && recentItems.length === 0 && (
        <EmptyState title={t('components.RecentItems.emptyStateMessage')} />
      )}
      {!active &&
        recentItems.length > 0 &&
        recentItems.map((item, idx) => {
          const {
            cif = '',
            clientFirstName = '',
            clientLastName = '',
            legalCode = '',
            legalDesc = '',
            clientEmployeeNumber = '',
          } = item;
          return (
            <ListCard
              key={idx}
              linkTo={routePathReplacingParams(routePaths.case, {
                caseNumber: encodeURIComponent(cif),
              })}
              heading={`${clientLastName}, ${clientFirstName}`}
              subheading={
                <Fragment>
                  <span>{cif}</span>
                  {clientEmployeeNumber && <br />}
                  {clientEmployeeNumber && (
                    <span>
                      {t('components.RecentItems.clientEmployeeNumber', {
                        clientEmployeeNumber,
                      })}
                    </span>
                  )}
                  <br />
                  <span>{`${legalCode} \u2013 ${legalDesc}`}</span>
                </Fragment>
              }
            />
          );
        })}
    </div>
  );
};

export default RecentItems;
