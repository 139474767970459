import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';
import { searchStringFromParams } from 'js/utilities/params';
import { cleanClientNotes } from 'js/utilities/clients';
import { cleanCaseNotes, sortCaseNotes } from './functions';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [], setLoadingCaseNotes } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingCaseNotes(false);
    };
  };
};

export const getCaseNotesEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    caseNumber,
    query,
    setLoadingCaseNotes,
    setCaseNotes,
    getCaseNotesAbortControllerRef,
  } = options;
  return async (ignoreCache = false) => {
    setLoadingCaseNotes(true);

    const endpoint = `/Case/CaseNotes/${encodeURIComponent(caseNumber)}`;
    const record = cache.get(endpoint);

    if (!record || ignoreCache) {
      rotateAbortControllerRef(getCaseNotesAbortControllerRef);
      const { signal } = getCaseNotesAbortControllerRef.current;

      try {
        const { json = {} } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CaseNotes.requestError'),
              },
            },
          }
        );

        const caseNotes = cleanCaseNotes(json);

        let sortedNotes = [...caseNotes];
        if (query) {
          sortedNotes = sortCaseNotes(caseNotes, query);
        }

        cache.set(endpoint, caseNotes);
        setCaseNotes(sortedNotes);
        setLoadingCaseNotes(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingCaseNotes(false);
        }
      }
    } else {
      const { value: caseNotes } = record;

      let sortedNotes = [...caseNotes];
      if (query) {
        sortedNotes = sortCaseNotes(caseNotes, query);
      }

      setCaseNotes(sortedNotes);
      setLoadingCaseNotes(false);
    }
  };
};

export const getClientNotesCountEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    clientId,
    clientCode,
    setLoadingClientNotesCount,
    setClientNotesCount,
    getClientNotesCountAbortControllerRef,
  } = options;
  return async () => {
    if (!clientId || !clientCode) {
      return;
    }

    const endpoint = `/Client/${clientId}/${clientCode}/notes`;
    const record = cache.get(endpoint);

    if (!record) {
      setLoadingClientNotesCount(true);

      rotateAbortControllerRef(getClientNotesCountAbortControllerRef);
      const { signal } = getClientNotesCountAbortControllerRef.current;

      try {
        const { json = [] } = await api.getJson(
          endpoint,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.ClientNotes.requestError'),
              },
            },
          }
        );

        const clientNotes = cleanClientNotes(json);

        // Cache the client notes to improve loading performance
        // when navigating from case notes to client notes:
        cache.set(endpoint, clientNotes);
        setClientNotesCount(clientNotes.length);
        setLoadingClientNotesCount(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingClientNotesCount(false);
        }
      }
    } else {
      const { value: clientNotes } = record;
      setClientNotesCount(clientNotes.length);
    }
  };
};

export const sortCaseNotesEffect = (options = {}) => {
  const { query, setCaseNotes } = options;
  return () => setCaseNotes((caseNotes) => sortCaseNotes(caseNotes, query));
};

export const onChangeSortEffect = (options = {}) => {
  const {
    history,
    query: { params = {} },
  } = options;
  return (e, { field, direction }) => {
    const nextParams = { ...params, orderBy: field, order: direction };
    history.push(searchStringFromParams(nextParams));
  };
};

export const presentNoteModalEffect = (options = {}) => {
  const { setPresentNoteModal, willPresent = false, setEditingNote } = options;
  return () => {
    setPresentNoteModal(willPresent);
    if (!willPresent) {
      setEditingNote({});
    }
  };
};

export const saveNoteEffect = (options = {}) => {
  const { t, api, caseNumber, postNoteAbortControllerRef } = options;
  return async (text = '') => {
    // Errors will be caught by the note modal:
    rotateAbortControllerRef(postNoteAbortControllerRef);
    const { signal } = postNoteAbortControllerRef.current;

    await api.postJson(
      `/Case/CaseNotes/${encodeURIComponent(caseNumber)}`,
      {
        body: { text },
        signal,
      },
      {
        success: {
          context: {
            message: t('components.CaseNotes.postRequestSuccess'),
          },
        },
        error: {
          context: {
            message: t('components.CaseNotes.postRequestError'),
          },
        },
      }
    );
  };
};

export const onCompleteNoteEffect = (options = {}) => {
  const { dismissNoteModal, getCaseNotes } = options;
  return async () => {
    dismissNoteModal();
    await getCaseNotes(true);
  };
};

export const setEditNoteEffect = (options = {}) => {
  const { setEditingNote, setPresentNoteModal } = options;
  return async (note = {}) => {
    if (typeof setEditingNote === 'function') {
      setEditingNote(note);
      setPresentNoteModal(true);
    }
  };
};

export const onEditNoteEffect = (options = {}) => {
  const { t, api, patchNoteAbortControllerRef } = options;
  return async (text, editingNote) => {
    const { id } = editingNote;
    // Errors will be caught by the note modal:
    rotateAbortControllerRef(patchNoteAbortControllerRef);
    const { signal } = patchNoteAbortControllerRef.current;

    await api.patchJson(
      `/Case/CaseNotes/${encodeURIComponent(id)}`,
      {
        body: { ...editingNote, text },
        signal,
      },
      {
        success: {
          context: {
            message: t('components.CaseNotes.patchRequestSuccess'),
          },
        },
        error: {
          context: {
            message: t('components.CaseNotes.patchRequestError'),
          },
        },
      }
    );
  };
};

export const checkPermissionsEffect = (options = {}) => {
  const {
    hasLoadedGroups,
    matchAnyGroup,
    userGroups,
    setHasEditPermission,
  } = options;

  return () => {
    if (hasLoadedGroups) {
      const isMatch = matchAnyGroup([
        userGroups.administrator,
        userGroups.dataEntry,
        userGroups.feedbackSpecialist,
        userGroups.intakeSpecialist,
        userGroups.support,
      ]);

      if (isMatch) setHasEditPermission(true);
    }
  };
};

export const deleteNoteEffect = (options = {}) => {
  const {
    t,
    api,
    noteIdPendingDeletion,
    setNoteIdPendingDeletion,
    setDeleting,
    getCaseNotes,
    deleteNoteAbortControllerRef,
  } = options;
  return async () => {
    setDeleting(true);

    rotateAbortControllerRef(deleteNoteAbortControllerRef);
    const { signal } = deleteNoteAbortControllerRef.current;
    try {
      await api.deleteJson(
        `/Case/CaseNotes/${encodeURIComponent(noteIdPendingDeletion)}`,
        { signal },
        {
          success: {
            context: {
              message: t(t('components.CaseNotes.deleteRequestSuccess')),
            },
          },
          error: {
            context: {
              message: t('components.CaseNotes.deleteRequestSuccess'),
            },
          },
        }
      );
      setDeleting(false);
      setNoteIdPendingDeletion('');
      getCaseNotes(true);
    } catch (error) {
      if (!isAbortError(error)) {
        setDeleting(false);
      }
    }
  };
};
