import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { objectRemovingKeys } from 'js/utilities/object';

export const queryFromSearchString = (search = '') => {
  const params = paramsFromSearchString(search);
  const nextParams = updateReprintChequeCount(
    params.reprintChequeCount,
    params
  );
  return queryForParams(nextParams);
};

export const updateReprintChequeCount = (reprintChequeCount, params = {}) => {
  const intValue = parseInt(reprintChequeCount);
  if (isNaN(intValue)) {
    return objectRemovingKeys(params, ['reprintChequeCount']);
  } else {
    return { ...params, reprintChequeCount: intValue };
  }
};
