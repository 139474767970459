export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange, touch } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
      touch(name);
    }
  };
};

export const onSelectChangeEffect = (options = {}) => {
  const { onChange, legalProblemCodes } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      const nextValue = legalProblemCodes.find(
        (code) => code.displayValue === value
      );
      onChange(name, nextValue);
    }
  };
};

export const onClickRunEffect = (options = {}) => {
  const { onRun, isValid, touchAll } = options;
  return () => {
    if (!isValid) {
      touchAll();
    } else {
      onRun();
    }
  };
};

export const onDismountEffect = (options = {}) => {
  const { untouchAll } = options;
  return () => {
    untouchAll();
  };
};
