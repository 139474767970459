import React from 'react';
import ConfirmEstateTrusteeContent from './content';

const presentConfirmEstateTrusteePrompt = async (options = {}) => {
  const { prompt, data, initialValue, onChange } = options;
  await prompt(data, 'case.estateTrusteeConfirmation', {
    modalProps: {
      initialFocus: 'input[type="radio"]:checked',
    },
    content: (
      <ConfirmEstateTrusteeContent
        initialValue={initialValue}
        onChange={onChange}
      />
    ),
  });
};

export default presentConfirmEstateTrusteePrompt;
