import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { DataContext } from 'js/components/data';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import Page from 'js/components/page';
import SearchHeader from 'js/components/headers/search-header';
import {
  ActivityIndicatorCard,
  FAB,
  FABPosition,
  FABOffset,
  LabelValuePair,
} from 'js/components/design-system';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import { routePaths } from 'js/components/router/route-paths';
import { isEmpty } from 'js/utilities/validation';
import { isStaffLawyerType } from 'js/utilities/lawyers';
import { CaseContext } from '../case-context';
import { getCaseNotesEffect } from '../case-notes/effects';
import { queryForSearchString as caseNotesQueryForSearchString } from '../case-notes/functions';
import CaseBreadcrumbs from './breadcrumbs';
import CaseTabs from './tabs';
import VoidDenyModal from './void-deny-modal';
import { getPrintActions, getCaseActions } from './fab-actions';
import {
  onMountEffect,
  getCaseInfoEffect,
  getCaseOptionsEffect,
  prepNewCaseEffect,
  patchCaseStatusEffect,
  getCaseNotesConditionalEffect,
} from './effects';
import { caseActionsPermittedGroups } from './functions';

const CasePage = (props) => {
  const { className, title, actions, children, ...pageProps } = props;
  const { t } = useTranslation();

  const { path, params = {} } = useRouteMatch();
  const { id, clientCode = '00' } = params;

  let caseNotesQuery;
  if (path === routePaths.caseNotes) {
    const { search } = useLocation();
    caseNotesQuery = caseNotesQueryForSearchString(search);
  }

  const {
    caseInfo,
    setCaseInfo,
    isLoadingCaseInfo,
    setLoadingCaseInfo,
    setCaseOptions,
    isLoadingCaseOptions,
    setLoadingCaseOptions,
    caseNotes,
    setCaseNotes,
    isLoadingCaseNotes,
    setLoadingCaseNotes,
  } = useContext(CaseContext);
  const { caseInformation = {} } = caseInfo;
  const { caseStatus = '', legalProblemCode = '' } = caseInformation;

  const { api = {} } = useContext(FetchContext);
  const cache = useContext(DataContext);

  const active = isLoadingCaseInfo || isLoadingCaseOptions;
  const caseNumber = decodeURIComponent(params.caseNumber);
  const [nextCaseStatus, setNextCaseStatus] = useState('');
  const [isPatchingCase, setIsPatchingCase] = useState(false);

  const getCaseInfoAbortControllerRef = useRef(null);
  const getCaseOptionsAbortControllerRef = useRef(null);
  const getCaseNotesAbortControllerRef = useRef(null);

  const isNew = path.includes('new');
  const isCopy = path.includes('copy');
  const isStaffLawyer = isStaffLawyerType(caseInfo?.caseLawyer?.lawyerType);
  const hasLoadedCaseInfo = !isEmpty(caseInfo);

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseInfoAbortControllerRef,
        getCaseOptionsAbortControllerRef,
        getCaseNotesAbortControllerRef,
      ],
      setLoadingCaseInfo,
      setLoadingCaseOptions,
      setLoadingCaseNotes,
    }),
    []
  );

  const getCaseInfoOptions = {
    t,
    api,
    caseNumber,
    cache,
    setLoadingCaseInfo,
    setCaseInfo,
    isNew,
    isCopy,
    id,
    clientCode,
    getCaseInfoAbortControllerRef,
  };

  const patchCaseStatusOptions = {
    t,
    api,
    cache,
    caseNumber,
    caseInfo,
    setIsPatchingCase,
    getCaseInfo: getCaseInfoEffect({
      // Fetch the case info but don't update the active status
      // since the FAB active icon already indicates request activity:
      ...getCaseInfoOptions,
      setLoadingCaseInfo: () => {},
    }),
  };

  useEffect(getCaseInfoEffect(getCaseInfoOptions), [isCopy, caseNumber]);

  useEffect(
    getCaseNotesConditionalEffect({
      hasLoadedCaseInfo,
      isNew,
      isCopy,
      getCaseNotes: getCaseNotesEffect({
        t,
        api,
        cache,
        caseNumber,
        query: caseNotesQuery,
        setLoadingCaseNotes,
        setCaseNotes,
        getCaseNotesAbortControllerRef,
      }),
    }),
    [hasLoadedCaseInfo, isNew, isCopy]
  );

  useEffect(
    prepNewCaseEffect({
      isNew,
      setCaseInfo,
      setLoadingCaseInfo,
    }),
    [isNew]
  );

  useEffect(
    getCaseOptionsEffect({
      t,
      api,
      cache,
      setLoadingCaseOptions,
      setCaseOptions,
      getCaseOptionsAbortControllerRef,
    }),
    []
  );

  return (
    <Page
      className={classnames('case-page', className)}
      title={t('components.CasePage.title', { title })}
      header={<SearchHeader />}
      {...pageProps}
    >
      <VoidDenyModal
        mounted={nextCaseStatus !== ''}
        onClose={() => setNextCaseStatus('')}
        onSave={patchCaseStatusEffect({
          ...patchCaseStatusOptions,
          caseStatus: nextCaseStatus,
          setNextCaseStatus,
        })}
        active={isPatchingCase}
        nextCaseStatus={nextCaseStatus}
      />
      <div className="layout-container">
        <div className="layout-column">
          <CaseBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          {active && <ActivityIndicatorCard />}
          {!active && (
            <React.Fragment>
              <div className="case-page-heading">
                <div className="case-page-heading-left">
                  <p>{t('components.CasePage.subheading')}</p>
                  <div className="case-page-metadata">
                    <h1>
                      {isNew || isCopy
                        ? t('components.CasePage.newCase')
                        : caseNumber}
                    </h1>
                    {caseStatus && (
                      <div
                        className={classnames(
                          `tag ${caseStatus.toLowerCase()}`
                        )}
                      >
                        {t(`common.caseStatus.${caseStatus.toLowerCase()}`)}
                      </div>
                    )}
                    <LabelValuePair
                      label={t(`components.CasePage.lpCode`)}
                      value={legalProblemCode}
                    />
                  </div>
                </div>
                <div className="case-page-heading-right">{actions}</div>
              </div>

              <CaseTabs
                disabled={isNew || isCopy}
                showCaseNotesCount={!isLoadingCaseNotes}
                caseNotesCount={caseNotes.length}
              />
              <div className="case-page-content">{children}</div>
            </React.Fragment>
          )}
        </div>
      </div>
      {!isEmpty(caseStatus) && !isCopy && (
        <Fragment>
          <FABPosition>
            <GroupPermissionsGate permittedGroups={caseActionsPermittedGroups}>
              {({ hasLoadedGroups, hasMatchingGroup }) => (
                <FAB
                  actions={getPrintActions({
                    t,
                    caseNumber,
                    disabled: isPatchingCase,
                    ntpPermitted:
                      hasLoadedGroups && hasMatchingGroup && !isStaffLawyer,
                    cifPermitted: true,
                    caseStatus,
                  })}
                />
              )}
            </GroupPermissionsGate>
            <GroupPermissionsGate permittedGroups={caseActionsPermittedGroups}>
              {({ hasLoadedGroups, hasMatchingGroup, matchAnyGroup }) => {
                const isAdmin = matchAnyGroup([userGroups.administrator]);
                const isSupportOnly =
                  matchAnyGroup([userGroups.support]) &&
                  !matchAnyGroup([userGroups.administrator]);

                const canReassign = matchAnyGroup([
                  userGroups.administrator,
                  userGroups.dataEntry,
                  userGroups.intakeSpecialist,
                  userGroups.feedbackSpecialist,
                ]);

                const canCorrect = matchAnyGroup([
                  userGroups.administrator,
                  userGroups.dataEntry,
                ]);

                const caseActions = getCaseActions({
                  t,
                  caseNumber,
                  caseStatus,
                  disabled: isPatchingCase,
                  copyPermitted: !isSupportOnly,
                  reassignPermitted: canReassign,
                  correctionPermitted: canCorrect,
                  reopenPermitted: isAdmin,
                  denyPermitted: !isSupportOnly,
                  voidPermitted: true,
                  patchCaseStatusOptions,
                  setNextCaseStatus,
                });

                if (
                  hasLoadedGroups &&
                  hasMatchingGroup &&
                  caseActions.length > 1
                ) {
                  return <FAB actions={caseActions} />;
                } else {
                  return null;
                }
              }}
            </GroupPermissionsGate>
          </FABPosition>
          <FABOffset />
        </Fragment>
      )}
    </Page>
  );
};

CasePage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
};

export default CasePage;
