import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import { InactiveTag } from 'js/components/deactivate';
import {
  onMountEffect,
  lawFirmRequestEffect,
  previousLawFirmResultsEffect,
  nextLawFirmResultsEffect,
  lawFirmResultsPageNumberEffect,
  lawFirmResultsSortEffect,
  lawFirmSearchEffect,
  lawFirmSearchResetEffect,
  lawFirmResultsActiveEffect,
} from './effects';
import { queryForLawFirms, FIRM_ACTIVE_STATUS } from './functions';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListCard,
  StyledSelect,
} from 'js/components/design-system';
import LawFirmSortMenu from 'js/components/menus/law-firm-sort-menu';
import LawFirmBreadcrumbs from './law-firm-breadcrumbs';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { Link } from 'react-router-dom';
import EmptyState from 'js/components/controls/empty-state';

const LawFirmListPage = (props) => {
  const { className, title, ...pageProps } = props;
  const { t } = useTranslation();

  const abortControllerRef = useRef(null);
  const history = useHistory();
  const { search = '' } = useLocation();
  const { api = {} } = useContext(FetchContext);
  const [active, setActive] = useState(false);
  const [lawFirms, setLawFirms] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const query = queryForLawFirms(search);
  const { ActiveStatus } = query.params;

  useEffect(
    onMountEffect({
      abortControllerRefs: [abortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    lawFirmRequestEffect({
      t,
      api,
      query,
      setActive,
      setLawFirms,
      setTotalResults,
      abortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="law-firms-list-pagination-container">
      <OffsetLimitPagination
        className="law-firm-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalResults}
        onClickPrevious={previousLawFirmResultsEffect({
          history,
          query,
        })}
        onClickNext={nextLawFirmResultsEffect({ history, query })}
        onClickPageNumber={lawFirmResultsPageNumberEffect({
          history,
          query,
        })}
      />
    </div>
  );

  return (
    <Page
      className={classnames('law-firms-list-page', className)}
      title={t('components.LawFirmListPage.title', { title })}
      header={<FixedHeader />}
      {...pageProps}
    >
      <div className="layout-container ">
        <div className="layout-column">
          <LawFirmBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <div className="law-firms-list-page-heading">
            <div className="law-firms-list-page-heading-left">
              <h1>{t('components.LawFirmListPage.title')}</h1>
            </div>
            <div className="law-firms-list-page-heading-right">
              <button
                className="button button-highlight page-action-button"
                onClick={() =>
                  history.push(routePaths.lawFirmsPrint + query.search)
                }
              >
                {t('common.print')}
              </button>
              <Link
                className="button button-highlight page-action-button"
                to={routePaths.newLawFirm}
              >
                {t('common.new')}
              </Link>
            </div>
          </div>

          <div className="law-firms-list-page-search-bar">
            <GeneralSearchInput
              placeholder={t('components.LawFirmListPage.searchPlaceholder')}
              initialQuery={query}
              onSearch={lawFirmSearchEffect({ history, query })}
              onReset={lawFirmSearchResetEffect({ history, query })}
            />
            <StyledSelect
              value={ActiveStatus}
              name="ActiveStatus"
              onChange={lawFirmResultsActiveEffect({ history, query })}
            >
              <option value={FIRM_ACTIVE_STATUS.ALL}>
                {t('components.LawFirmListPage.activeDropdown.allFirms')}
              </option>
              <option value={FIRM_ACTIVE_STATUS.ACTIVE}>
                {t('components.LawFirmListPage.activeDropdown.activeFirms')}
              </option>
              <option value={FIRM_ACTIVE_STATUS.INACTIVE}>
                {t('components.LawFirmListPage.activeDropdown.inactiveFirms')}
              </option>
            </StyledSelect>
            <LawFirmSortMenu
              field={query.params.Order}
              direction={query.params.Sort}
              onSelect={lawFirmResultsSortEffect({ history, query })}
            />
          </div>

          {active && <ActivityIndicatorCard />}

          {!active && lawFirms.length === 0 && (
            <EmptyState
              title={t(`components.LawFirmListPage.emptyStateMessage`)}
            />
          )}

          {!active && lawFirms.length > 0 && (
            <Fragment>
              {pagination}
              <ul className="unstyled-list law-firms-list">
                {lawFirms
                  .filter(({ firmName }) => firmName.length > 0)
                  .map((lawFirm) => {
                    const { firmId, firmName, inactive } = lawFirm;
                    return (
                      <li key={firmId}>
                        <ListCard
                          key={firmId}
                          heading={
                            <div className="inactive-tag-container">
                              <span>{firmName}</span>
                              <InactiveTag inactive={inactive} />
                            </div>
                          }
                          linkTo={routePathReplacingParams(routePaths.lawFirm, {
                            firmId,
                          })}
                        />
                      </li>
                    );
                  })}
              </ul>
              {pagination}
            </Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

LawFirmListPage.propTypes = {
  ...Page.propTypes,
  actions: PropTypes.node,
  onChangeLockSystemActivity: PropTypes.func,
  onLoadLockSystem: PropTypes.func,
};

export default LawFirmListPage;
