import {
  caseStatuses,
  caseApiKey,
  cleanCase,
  cleanCaseOptions,
  createCaseCopy,
} from 'js/utilities/cases';
import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const onMountEffect = (options = {}) => {
  const {
    abortControllerRefs = [],
    setLoadingCaseInfo,
    setLoadingCaseOptions,
    setLoadingCaseNotes,
  } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
      setLoadingCaseInfo(false);
      setLoadingCaseOptions(false);
      setLoadingCaseNotes(false);
    };
  };
};

export const prepNewCaseEffect = (options = {}) => {
  const { isNew, setCaseInfo, setLoadingCaseInfo } = options;
  return async () => {
    if (!isNew) {
      return;
    }
    setLoadingCaseInfo(true);
    setCaseInfo(cleanCase());
    setLoadingCaseInfo(false);
  };
};

export const getCaseInfoEffect = (options = {}) => {
  const {
    t,
    api,
    caseNumber,
    setLoadingCaseInfo,
    setCaseInfo,
    isNew,
    isCopy,
    getCaseInfoAbortControllerRef,
  } = options;
  return async () => {
    if (isNew) {
      return;
    }

    setLoadingCaseInfo(true);

    rotateAbortControllerRef(getCaseInfoAbortControllerRef);
    const { signal } = getCaseInfoAbortControllerRef.current;

    const url = caseApiKey(caseNumber);

    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.CasePage.caseRequestError'),
            },
          },
        }
      );

      const cleanCaseData = cleanCase(json);
      let caseData = cleanCaseData;
      if (isCopy) {
        caseData = createCaseCopy(caseData);
      }

      setCaseInfo(caseData);
      setLoadingCaseInfo(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoadingCaseInfo(false);
      }
    }
  };
};

export const getCaseOptionsEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    setLoadingCaseOptions,
    setCaseOptions,
    getCaseOptionsAbortControllerRef,
  } = options;
  return async () => {
    setLoadingCaseOptions(true);

    rotateAbortControllerRef(getCaseOptionsAbortControllerRef);
    const { signal } = getCaseOptionsAbortControllerRef.current;

    const optionsUrl = '/Common/CaseOptions';
    const optionsRecord = cache.get(optionsUrl);

    if (!optionsRecord) {
      try {
        const { json = {} } = await api.getJson(
          optionsUrl,
          { signal },
          {
            success: { bypass: true },
            error: {
              context: {
                message: t('components.CasePage.optionsRequestError'),
              },
            },
          }
        );

        const caseOptions = cleanCaseOptions(json);
        cache.set(optionsUrl, caseOptions);

        setCaseOptions(caseOptions);
        setLoadingCaseOptions(false);
      } catch (error) {
        if (!isAbortError(error)) {
          setLoadingCaseOptions(false);
        }
      }
    } else {
      const { value: caseOptions = {} } = optionsRecord;
      setCaseOptions(caseOptions);
      setLoadingCaseOptions(false);
    }
  };
};

export const patchCaseStatusEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    caseNumber,
    caseInfo,
    caseStatus,
    setIsPatchingCase,
    getCaseInfo,
    setNextCaseStatus,
  } = options;
  return async () => {
    setIsPatchingCase(true);
    const url = caseApiKey(caseNumber);

    const { caseInformation = {} } = caseInfo;
    const nextCaseInformation = { ...caseInformation, caseStatus };
    const body = { ...caseInfo, caseInformation: nextCaseInformation };

    try {
      await api.patchJson(
        url,
        { body },
        {
          success: {
            context: {
              message: t('components.CasePage.casePatchStatusRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CasePage.casePatchStatusRequestError'),
            },
          },
        }
      );

      cache.delete(url);
      await getCaseInfo();
      setIsPatchingCase(false);
      if (typeof setNextCaseStatus === 'function') {
        setNextCaseStatus('');
      }
    } catch (error) {
      setIsPatchingCase(false);
      if (typeof setNextCaseStatus === 'function') {
        setNextCaseStatus('');
      }
    }
  };
};

export const reopenCaseEffect = (options = {}) => {
  const {
    t,
    api,
    cache,
    caseNumber,
    caseInfo,
    setIsPatchingCase,
    getCaseInfo,
  } = options;
  return async () => {
    setIsPatchingCase(true);

    const { caseInformation = {}, caseBilling = {} } = caseInfo;
    const nextCaseInformation = {
      ...caseInformation,
      caseStatus: caseStatuses.active,
    };
    const nextCaseBilling = {
      ...caseBilling,
      dateClosed: null,
      lawyerInvoiceDate: null,
      planHours: 0,
      planFees: 0,
      planTaxes: 0,
      clientHours: 0,
      clientFees: 0,
    };
    const body = {
      ...caseInfo,
      caseInformation: nextCaseInformation,
      caseBilling: nextCaseBilling,
    };

    try {
      await api.postJson(
        `/Case/reopen/${encodeURIComponent(caseNumber)}`,
        { body },
        {
          success: {
            context: {
              message: t('components.CasePage.caseReopenRequestSuccess'),
            },
          },
          error: {
            context: {
              message: t('components.CasePage.caseReopenRequestError'),
            },
          },
        }
      );

      cache.delete(caseApiKey(caseNumber));
      await getCaseInfo();
      setIsPatchingCase(false);
    } catch (error) {
      setIsPatchingCase(false);
    }
  };
};

export const getCaseNotesConditionalEffect = (options) => {
  const { hasLoadedCaseInfo, isNew, isCopy, getCaseNotes } = options;
  return () => {
    if (hasLoadedCaseInfo && !isNew && !isCopy) {
      getCaseNotes();
    }
  };
};
