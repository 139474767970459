import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { GroupPermissionsContext } from './context';
import { hasNamedPermissionEffect } from './effects';
import { userGroups } from './groups';

const NamedPermissionsContext = createContext({
  hasNamedPermission: () => false,
});

const NamedPermissionsProvider = (props) => {
  const { namedPermissions = {}, children } = props;
  const { hasLoadedGroups, matchAnyGroup } = useContext(
    GroupPermissionsContext
  );

  return (
    <NamedPermissionsContext.Provider
      value={{
        hasNamedPermission: hasNamedPermissionEffect({
          namedPermissions,
          hasLoadedGroups,
          matchAnyGroup,
        }),
      }}
    >
      {children}
    </NamedPermissionsContext.Provider>
  );
};

NamedPermissionsProvider.propTypes = {
  namedPermissions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(userGroups)))
  ),
};

export { NamedPermissionsContext, NamedPermissionsProvider };
