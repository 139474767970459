import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from '../sort-menu';
import { lawyerCaseHistorySortOptions } from './functions';

const LawyerCaseHistorySortMenu = (props) => (
  <SortMenu
    className="lawyer-case-history-sort-menu"
    sortOptions={lawyerCaseHistorySortOptions}
    {...props}
  />
);

LawyerCaseHistorySortMenu.defaultProps = {
  field: 'date',
  direction: 'desc',
};

LawyerCaseHistorySortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default LawyerCaseHistorySortMenu;
