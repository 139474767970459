export const tableSortDirection = {
  asc: 'asc',
  desc: 'desc',
};

export const stringifyColumns = (columns = []) =>
  JSON.stringify(columns.map((column) => column.key));

export const stringifyData = (data = []) => JSON.stringify(data);

export const defaultTableCellDataGetter = (key, row) => row[key];

export const defaultTableSortPredicateAsc = (key, row1, row2) => {
  if (row1[key] !== row2[key]) {
    return row1[key] > row2[key] ? 1 : -1;
  } else {
    return 0;
  }
};

export const defaultTableSortPredicateDesc = (key, row1, row2) => {
  if (row1[key] !== row2[key]) {
    return row1[key] < row2[key] ? 1 : -1;
  } else {
    return 0;
  }
};

export const defaultTableSortOptions = {
  [tableSortDirection.asc]: defaultTableSortPredicateAsc,
  [tableSortDirection.desc]: defaultTableSortPredicateDesc,
};

export const numericTableSortPredicateAsc = (key, row1, row2) => {
  const value1 = Number(row1[key]);
  const value2 = Number(row2[key]);
  if (value1 !== value2) {
    return value1 > value2 ? 1 : -1;
  } else {
    return 0;
  }
};

export const numericTableSortPredicateDesc = (key, row1, row2) => {
  const value1 = Number(row1[key]);
  const value2 = Number(row2[key]);
  if (value1 !== value2) {
    return value1 < value2 ? 1 : -1;
  } else {
    return 0;
  }
};

export const numericTableSortOptions = {
  [tableSortDirection.asc]: numericTableSortPredicateAsc,
  [tableSortDirection.desc]: numericTableSortPredicateDesc,
};

export const columnDefaults = {
  headerContent: '',
  key: '',
  get: defaultTableCellDataGetter,
  sort: defaultTableSortOptions,
  sortable: true,
};

export const setColumnDefaults = (columns = []) => {
  return columns.map((column) => ({ ...columnDefaults, ...column }));
};

export const prepareTableData = (columns = [], data = []) => {
  return data.map((row) => {
    const values = columns.map((col) => {
      const { key, get } = col;
      return get(key, row);
    });
    return { row, values };
  });
};

export const sortTableData = (data, column, direction) => {
  const copy = [...data];
  const { key, sort = {} } = column;
  const sortFunc = sort[direction];
  if (typeof sortFunc === 'function') {
    return copy.sort((row1, row2) => sortFunc(key, row1, row2));
  } else {
    return copy;
  }
};
