export const cleanStringField = (value) => {
  return (value || '').trim();
};

export const resolveProvinceName = (t, province) => {
  if (/^[A-Z]{2}$/.test(province)) {
    return t(`common.provincesByCode.${province}`);
  } else {
    return province;
  }
};

export const nl2br = (text) => text.replace(/\n/g, '<br />');

export const stripNewlines = (text) => text.replace(/\n/g, ' ');

export const defaultTextTruncationLength = 200;

export const textRequiresTruncation = (
  text = '',
  toLength = defaultTextTruncationLength
) => {
  return text.length > toLength;
};

export const truncateText = (
  text = '',
  toLength = defaultTextTruncationLength
) => {
  // Truncate and trim the text. Append an ellipsis only if
  // the last character of the truncated text is alphanumeric.
  const truncatedText = text.slice(0, toLength).trim();
  const trailingChar = truncatedText[truncatedText.length - 1];
  const appendEllipsis = /^[a-zA-Z0-9]$/.test(trailingChar);
  return appendEllipsis ? `${truncatedText}\u2026` : truncatedText;
};

export const truncateTextIfRequired = (
  text = '',
  requiresTruncation = false,
  toLength = defaultTextTruncationLength
) => {
  return requiresTruncation ? truncateText(text, toLength) : text;
};

export const formatPhoneNumber = (num) => {
  const digits = num.replaceAll('-', '').replace(/\s+/g, '');
  if (digits.length === 7) {
    return `${digits.substring(0, 3)}-${digits.substring(3, 7)}`;
  } else if (digits.length > 7) {
    return `${digits.substring(0, 3)}-${digits.substring(
      3,
      6
    )}-${digits.substring(6)}`;
  } else if (digits.length === 0) {
    return '';
  }
  return num;
};

export const preprendHttpScheme = (url, https = true) => {
  const hasScheme = /^https?:\/\//i.test(url);
  if (!hasScheme) {
    const scheme = https ? 'https' : 'http';
    return `${scheme}://${url}`;
  } else {
    return url;
  }
};
