export const onCheckboxChangeEffect = (options = {}) => {
  const { setChecked } = options;
  return (e) => setChecked(e.target.checked);
};

export const onSubmitDatabaseRestoreFormEffect = (options = {}) => {
  const { validateAll, touchAll, backup, onConfirm } = options;
  return (e) => {
    e.preventDefault();
    const { isValid } = validateAll();
    if (isValid) {
      onConfirm(backup);
    } else {
      touchAll();
    }
  };
};
