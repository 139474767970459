import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'js/components/modal';

const ConfirmCaseReassignmentModal = (props) => {
  const { t } = useTranslation();
  const { mounted, isActive, onClose, onConfirm } = props;

  return (
    <Modal
      className="deactivate-modal"
      cancelButtonText={t('common.close')}
      onClickCancel={onClose}
      titleText={t('components.CaseReassignment.ConfirmModal.title')}
      mounted={mounted}
      underlayClickExits={false}
      verticallyCenter
      allowCancel={false}
    >
      <h2>{t('components.CaseReassignment.ConfirmModal.title')}</h2>
      <p>{t('components.CaseReassignment.ConfirmModal.text')}</p>
      <div className="button-bar">
        <button
          className="button-link-appearance"
          disabled={isActive}
          onClick={onClose}
        >
          {t('components.CaseReassignment.ConfirmModal.cancel')}
        </button>
        <button
          id="navigation-modal-proceed"
          className="button button-highlight"
          disabled={isActive}
          onClick={onConfirm}
        >
          {isActive
            ? t('components.CaseReassignment.ConfirmModal.reassigning')
            : t('components.CaseReassignment.ConfirmModal.reassign')}
        </button>
      </div>
    </Modal>
  );
};

ConfirmCaseReassignmentModal.defaultProps = {
  mounted: false,
  isActive: false,
};

ConfirmCaseReassignmentModal.propTypes = {
  mounted: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmCaseReassignmentModal;
