import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { FetchContext } from 'js/components/fetch';
import {
  IndentedListCard,
  ActivityIndicatorCard,
} from 'js/components/design-system';
import { InactiveTag } from 'js/components/deactivate';
import {
  NamedPermissionsContext,
  NamedPermissionsProvider,
} from 'js/components/group-permissions';
import EmptyState from 'js/components/controls/empty-state';
import {
  clientStatusKeyForValue,
  routePathForClientOrDependent,
  routePathForNewDependent,
} from 'js/utilities/clients';
import ClientPage from '../client-page';
import { ClientProvider } from '../client-context';
import { clientDependentsNamedPermissions } from '../client-named-permissions';
import { onMountEffect, getDependentsEffect } from './effects';

const ClientDependents = () => {
  const {
    params: { id },
  } = useRouteMatch();
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { hasNamedPermission } = useContext(NamedPermissionsContext);
  const hasAddPermission = hasNamedPermission('addDependentToCoverage');

  const [isLoadingDependents, setLoadingDependents] = useState(false);
  const [dependents, setDependents] = useState([]);
  const getDependentsAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getDependentsAbortControllerRef],
      setLoadingDependents,
    }),
    []
  );

  useEffect(
    getDependentsEffect({
      t,
      api,
      id,
      setLoadingDependents,
      setDependents,
      getDependentsAbortControllerRef,
    }),
    []
  );

  return (
    <ClientPage
      className="client-dependents"
      title={t('components.ClientDependents.title')}
      actions={
        hasAddPermission && (
          <Link
            className="button button-highlight page-action-button"
            to={routePathForNewDependent(id)}
          >
            {t('components.ClientDependents.addToCoverage')}
          </Link>
        )
      }
    >
      {isLoadingDependents && <ActivityIndicatorCard />}
      {!isLoadingDependents && (
        <Fragment>
          {dependents.length === 0 && (
            <EmptyState
              title={t('components.ClientDependents.emptyStateMessage')}
            />
          )}
          {dependents.length > 0 &&
            dependents.map((dependent, index) => {
              const { firstName, lastName, status, code, isActive } = dependent;
              const statusKey = clientStatusKeyForValue(status);
              return (
                <IndentedListCard
                  key={index}
                  heading={
                    <div className="inactive-tag-container">
                      <span>
                        {t('common.nameFormatLastFirst', {
                          firstName,
                          lastName,
                        })}
                      </span>
                      <InactiveTag inactive={!isActive} />
                    </div>
                  }
                  subheading={t(`common.clientStatus.${statusKey}`)}
                  linkTo={routePathForClientOrDependent(id, code)}
                />
              );
            })}
        </Fragment>
      )}
    </ClientPage>
  );
};

const ClientDependentsWithContext = (props) => (
  <ClientProvider>
    <ClientDependents {...props} />
  </ClientProvider>
);

const ClientDependentsWithNamedPermissions = (props) => (
  <NamedPermissionsProvider namedPermissions={clientDependentsNamedPermissions}>
    <ClientDependentsWithContext {...props} />
  </NamedPermissionsProvider>
);

export default ClientDependentsWithNamedPermissions;
