import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const Tabs = (props) => {
  const { className, items = [], currentPath, disabled } = props;
  return (
    <ul className={classnames('tabs', className, { disabled })}>
      {items.map((item) => {
        const {
          className: itemClassName,
          linkTo,
          content,
          isActive = () => false,
        } = item;
        return (
          <li
            key={linkTo}
            className={classnames(
              'tabs-item',
              { 'tabs-item-active': isActive(currentPath) },
              itemClassName
            )}
          >
            {disabled ? (
              <span>{content}</span>
            ) : (
              <Link to={linkTo}>{content}</Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      linkTo: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      isActive: PropTypes.func,
    })
  ),
  currentPath: PropTypes.string.isRequired,
};

export default Tabs;
