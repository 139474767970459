export const resolveCreateChequeRunPath = (
  reprintLastRun = false,
  reprintChequeCount
) => {
  if (reprintLastRun) {
    return '/Cheque/LastRun/reprint';
  } else if (!isNaN(reprintChequeCount)) {
    return `/Cheque/reprint/${reprintChequeCount}`;
  } else {
    return '/Cheque/run';
  }
};

export const resolveDeleteChequeRunPath = (
  reprintLastRun = false,
  reprintSingleCheque = false,
  shouldConfirmRun = false
) => {
  // NOTE: Use DELETE /Cheque/run endpoint for to delete a pending
  // cheque regardless of which POST endpoint created it.
  // (Both endpoints should do the same thing if deleteOnly=true,
  // but /Cheque/run is used in accordance with decisions made in
  // planning and to avoid potential issues that might be caused
  // by using DELETE /Cheque/reprint to remove a pending run created
  // by POST /Cheque/run.)
  if (reprintLastRun && shouldConfirmRun) {
    return '/Cheque/LastRun/reprint';
  } else if (reprintSingleCheque && shouldConfirmRun) {
    return '/Cheque/reprint';
  } else {
    return '/Cheque/run';
  }
};
