import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { StyledBannerContext } from 'js/components/banner-styled';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import CaseNumberInput from 'js/components/controls/case-number-input';
import {
  InputHelp,
  Overlay,
  IndentedCard,
  LabelValuePair,
  DatePicker,
  InputLabel,
  FontAwesomeIcon,
} from 'js/components/design-system';
import {
  ClientSummarySkeleton,
  clientSummaryMode,
} from 'js/components/client-summary';
import { isEmpty } from 'js/utilities/validation';
import CSQEntryBreadcrumbs from './breadcrumbs';
import {
  onMountEffect,
  focusCaseNumberInputEffect,
  onChangeInputCaseNumberEffect,
  onBlurInputCaseNumberEffect,
  setConfirmedCaseNumberEffect,
  resetCaseNumberEffect,
  getCaseInfoEffect,
  getClientEffect,
  getPreviousCsqEffect,
  postCsqEffect,
  onSubmitEffect,
  onInputChangeEffect,
  onInputBlurEffect,
  getLawyerEffect,
} from './effects';
import {
  queryForSearchString,
  getCsqQuestionsAndAnswers,
  csqEntryValidationRules,
  csqEntryRequiredFields,
  CSQ_ENTRY_ANSWER_OPTIONS,
} from './functions';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CSQEntry = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const {
    params: { caseNumber: confirmedCaseNumber },
  } = queryForSearchString(search);
  const getCaseInfoAbortControllerRef = useRef(null);
  const getClientAbortControllerRef = useRef(null);
  const getPreviousCsqAbortControllerRef = useRef(null);
  const postCsqAbortControllerRef = useRef(null);
  const caseNumberInputRef = useRef(null);
  const getLawyerAbortControllerRef = useRef(null);

  const [inputCaseNumber, setInputCaseNumber] = useState(confirmedCaseNumber);
  const [isCaseInfoLoading, setCaseInfoLoading] = useState(false);
  const [caseInfo, setCaseInfo] = useState({});
  const [isClientLoading, setClientLoading] = useState(false);
  const [isClientLoaded, setClientLoaded] = useState(false);
  const [clientSummary, setClientSummary] = useState({});
  const [csq, setCsq] = useState({});
  const [lawyer, setLawyer] = useState({});
  const [isLoadingPreviousCsq, setLoadingPreviousCsq] = useState(false);
  const [hasPreviousCsq, setHasPreviousCsq] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const { presentStyledBanner } = useContext(StyledBannerContext);
  const { api = {} } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);
  const isLoading = isCaseInfoLoading || isClientLoading;

  const hasCase = !isEmpty(caseInfo);
  const hasClient = !isEmpty(clientSummary);

  const { caseInformation = {}, caseLawyer = {} } = caseInfo;
  const {
    clientId,
    clientCode,
    legalProblemCode,
    legalProblemDesc,
  } = caseInformation;
  const { lawyerId, lawyerType } = caseLawyer;
  const { firstName = '', lastName = '' } = lawyer;

  const { isValid: isInputCaseNumberValid } = validate(
    { inputCaseNumber },
    'inputCaseNumber'
  );
  const touchCaseNumberInput = () => touch('inputCaseNumber');
  const untouchCaseNumberInput = () => untouch('inputCaseNumber');

  const validateAll = (data) => validate(data, csqEntryRequiredFields);
  const { isFieldValid } = validateAll(csq);
  const touchAll = () => touch(csqEntryRequiredFields);
  const untouchAll = () => untouch(csqEntryRequiredFields);

  const questions = getCsqQuestionsAndAnswers();
  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseInfoAbortControllerRef,
        getClientAbortControllerRef,
      ],
      setCaseInfoLoading,
      setClientLoading,
      setClientLoaded,
    }),
    []
  );

  useEffect(
    focusCaseNumberInputEffect({
      caseNumberInputRef,
    }),
    []
  );

  useEffect(
    getCaseInfoEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      untouchCaseNumberInput,
      setCaseInfoLoading,
      setCaseInfo,
      setClientLoading,
      setClientLoaded,
      setClientSummary,
      getCaseInfoAbortControllerRef,
      getClientAbortControllerRef,
    }),
    [confirmedCaseNumber]
  );

  useEffect(
    getLawyerEffect({
      t,
      api,
      lawyerId,
      lawyerType,
      setLawyer,
      getLawyerAbortControllerRef,
    }),
    [lawyerId]
  );

  useEffect(
    getPreviousCsqEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      setLoadingPreviousCsq,
      setHasPreviousCsq,
      getPreviousCsqAbortControllerRef,
      setCsq,
      questions,
    }),
    [confirmedCaseNumber]
  );

  useEffect(
    getClientEffect({
      t,
      api,
      clientId,
      clientCode,
      setClientLoading,
      setClientLoaded,
      setClientSummary,
      getClientAbortControllerRef,
    }),
    [clientId, clientCode]
  );

  const resetCaseNumber = resetCaseNumberEffect({
    history,
    setCsq,
    setCaseInfo,
    setClientSummary,
    setInputCaseNumber,
    setClientLoaded,
    untouchAll,
    untouchCaseNumberInput,
    caseNumberInputRef,
  });

  const onSubmit = onSubmitEffect({
    t,
    saveEffect: postCsqEffect({
      t,
      api,
      validateAll,
      touchAll,
      caseNumber: confirmedCaseNumber,
      csq,
      setSaving,
      onComplete: resetCaseNumber,
      postCsqAbortControllerRef,
    }),
    presentStyledBanner,
  });

  const radioInputEventHandlers = {
    onChange: onInputChangeEffect({ setCsq }),
  };

  const {
    firstName: clientFirstName,
    lastName: clientLastName,
    prefix,
  } = clientSummary;

  return (
    <Page
      className="csq-entry"
      title={t('components.CSQEntry.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <CSQEntryBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-2">
        <div className="layout-column">
          <CaseNumberInput
            inputRef={caseNumberInputRef}
            inputName="inputCaseNumber"
            value={inputCaseNumber}
            isValid={!isTouched('inputCaseNumber') || isInputCaseNumberValid}
            onChange={onChangeInputCaseNumberEffect({
              setInputCaseNumber,
            })}
            onBlur={onBlurInputCaseNumberEffect({
              touchCaseNumberInput,
            })}
            onConfirm={setConfirmedCaseNumberEffect({
              history,
              isInputCaseNumberValid,
              inputCaseNumber,
              touchCaseNumberInput,
              untouchCaseNumberInput,
            })}
            onReset={resetCaseNumber}
          />

          {!hasClient && !isClientLoaded && (
            <ClientSummarySkeleton
              className="csq-entry-client-summary"
              animated={isLoading}
              mode={clientSummaryMode.minimal}
            />
          )}

          {hasClient && (
            <IndentedCard
              className="csq-summary"
              linkTo={routePathReplacingParams(routePaths.case, {
                caseNumber: encodeURIComponent(confirmedCaseNumber),
              })}
            >
              <div className="form-column">
                <div className="form-row">
                  <div>
                    <h1>{`${prefix} ${clientFirstName} ${clientLastName}`}</h1>
                    <LabelValuePair
                      label={t('components.CSQEntry.caseCard.clientId')}
                      value={clientId}
                    />
                  </div>
                  <InputLabel
                    content={t('components.CSQEntry.caseCard.submitDate')}
                  >
                    <DatePicker
                      selected={new Date(csq.date)}
                      name="date"
                      readOnly
                    />
                  </InputLabel>
                </div>
                <div className="form-row">
                  <LabelValuePair
                    label={t('components.CSQEntry.caseCard.lpCode')}
                    value={legalProblemCode}
                  />
                  <LabelValuePair
                    label={t('components.CSQEntry.caseCard.lpCodeDesc')}
                    value={legalProblemDesc}
                  />
                  <LabelValuePair
                    label={t('components.CSQEntry.caseCard.lawyerId')}
                    value={!lawyerId ? t('common.na') : lawyerId}
                  />
                  <LabelValuePair
                    label={t('components.CSQEntry.caseCard.lawyerName')}
                    value={
                      !firstName ? t('common.na') : `${lastName}, ${firstName}`
                    }
                  />
                </div>
              </div>
              <div className="form-column">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </IndentedCard>
          )}

          <Overlay visible={!hasCase || isLoadingPreviousCsq || isSaving}>
            <form onSubmit={onSubmit}>
              <div className="csq-questions">
                {questions.map(({ questionNumber, questionText, answers }) => (
                  <div
                    key={questionNumber}
                    className="csq-entry-question-group"
                  >
                    <h4>
                      <span>{questionNumber}.</span>
                      <span>{questionText}</span>
                    </h4>
                    <InputHelp
                      validationContent={t(
                        'components.CSQEntry.radioValidation'
                      )}
                      invalid={
                        isTouched(questionNumber) &&
                        !isFieldValid(questionNumber)
                      }
                    >
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={questionNumber}
                            value={CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE}
                            checked={
                              csq[questionNumber] ===
                              CSQ_ENTRY_ANSWER_OPTIONS.POSITIVE
                            }
                            {...radioInputEventHandlers}
                          />
                          <span>{answers.positive}</span>
                        </label>
                        {answers.includeNeutral && (
                          <label>
                            <input
                              type="radio"
                              name={questionNumber}
                              value={CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL}
                              checked={
                                csq[questionNumber] ===
                                CSQ_ENTRY_ANSWER_OPTIONS.NEUTRAL
                              }
                              {...radioInputEventHandlers}
                            />
                            <span>{answers.neutral}</span>
                          </label>
                        )}
                        <label>
                          <input
                            type="radio"
                            name={questionNumber}
                            value={CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE}
                            checked={
                              csq[questionNumber] ===
                              CSQ_ENTRY_ANSWER_OPTIONS.NEGATIVE
                            }
                            {...radioInputEventHandlers}
                          />
                          <span>{answers.negative}</span>
                        </label>
                      </div>
                    </InputHelp>
                  </div>
                ))}

                <div className="csq-entry-question-group">
                  <h4>
                    <span>{questions.length + 1}.</span>
                    <span>{t('components.CSQEntry.notes')}</span>
                  </h4>
                  <textarea
                    name="notes"
                    value={csq.notes || ''}
                    onChange={onInputChangeEffect({ setCsq })}
                    onBlur={onInputBlurEffect({ touch })}
                  />
                </div>
              </div>

              <div className="button-bar">
                {hasPreviousCsq && (
                  <p>{t('components.CSQEntry.csqSubmittedMessage')}</p>
                )}
                <button
                  className="button button-highlight"
                  type="submit"
                  disabled={hasPreviousCsq}
                >
                  {isSaving ? t('common.submitting') : t('common.submit')}
                </button>
              </div>
            </form>
          </Overlay>
        </div>
      </div>
    </Page>
  );
};

const ValidatedCSQEntry = (props) => (
  <ValidationProvider rules={csqEntryValidationRules}>
    <CSQEntry {...props} />
  </ValidationProvider>
);

export default ValidatedCSQEntry;
