import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'js/components/design-system';
import {
  CLIPBOARD_WRITE_STATUS,
  clipboardStatusIconClassNames,
  clipboardStatusIcon,
} from './functions';

const ClipboardStatusIcon = ({ writeStatus }) => {
  return (
    <div className={clipboardStatusIconClassNames(writeStatus)}>
      <FontAwesomeIcon icon={clipboardStatusIcon(writeStatus)} />
    </div>
  );
};

ClipboardStatusIcon.propTypes = {
  writeStatus: PropTypes.oneOf(Object.values(CLIPBOARD_WRITE_STATUS))
    .isRequired,
};

export default ClipboardStatusIcon;
