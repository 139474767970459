import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faTimesCircle, faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  Overlay,
  FontAwesomeIcon,
  FAB,
  FABPosition,
  FABOffset,
  InputHelp,
} from 'js/components/design-system';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import CaseNumberInput from 'js/components/controls/case-number-input';
import {
  ClientSummary,
  ClientSummarySkeleton,
} from 'js/components/client-summary';
import { caseReassignmentValidationRules } from 'js/utilities/cases';
import { paramsFromSearchString } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';
import { CaseInformationForm } from '../case-forms';
import CaseReassignmentBreadcrumbs from './breadcrumbs';
import ConfirmCaseReassignmentModal from './confirm-modal';
import {
  focusInputEffect,
  onChangeCaseNumberInputEffect,
  onChangeInputEffect,
  onBlurInputEffect,
  onKeyPressEffect,
  resetCaseEffect,
  resetNewClientIdEffect,
  setConfirmedCaseNumberEffect,
  setConfirmedNextClientIdEffect,
  onMountEffect,
  onUpdateCaseNumberEffect,
  getCaseInfoEffect,
  getClientEffect,
  confirmReassignCaseEffect,
  cancelReassignCaseEffect,
  reassignCaseEffect,
} from './effects';

const CaseReassignment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const { casenumber: caseNumberParam = '' } = paramsFromSearchString(search);

  //refs
  const caseNumberInputRef = useRef(null);
  const nextClientIdInputRef = useRef(null);

  const getCaseInfoAbortControllerRef = useRef(null);
  const getClientAbortControllerRef = useRef(null);
  const getNextClientAbortControllerRef = useRef(null);
  const caseReassignmentAbortControllerRef = useRef(null);

  //states
  const [inputCaseNumber, setInputCaseNumber] = useState('');
  const [confirmedCaseNumber, setConfirmedCaseNumber] = useState('');
  const [caseInfoActive, setCaseInfoActive] = useState(false);
  const [caseInfo, setCaseInfo] = useState({});

  const [clientActive, setClientActive] = useState(false);
  const [client, setClient] = useState({});
  const [clientOptions, setClientOptions] = useState({});

  const [nextClientActive, setNextClientActive] = useState(false);
  const [inputNextClientId, setInputNextClientId] = useState('');
  const [confirmedNextClientId, setConfirmedNextClientId] = useState('');
  const [inputNextClientCode, setInputNextClientCode] = useState('');
  const [confirmedNextClientCode, setConfirmedNextClientCode] = useState('');
  const [nextClient, setNextClient] = useState({});

  const [confirmingCaseReassignment, setConfirmingCaseReassignment] = useState(
    false
  );
  const [caseReassignmentActive, setCaseReassignmentActive] = useState(false);

  //contexts
  const { api = {} } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);

  const hasCase = !isEmpty(caseInfo);
  const hasClient = !isEmpty(client);
  const hasNextClient = !isEmpty(nextClient);

  const { caseInformation = {} } = caseInfo;
  const { clientId, clientCode, legalProblems = [] } = caseInformation;

  const { offices = [] } = clientOptions;

  const { isValid: isInputCaseNumberValid } = validate({ inputCaseNumber }, [
    'inputCaseNumber',
  ]);

  const { isValid: isInputNextClientValid } = validate(
    { inputNextClientId, inputNextClientCode },
    ['inputNextClientId', 'inputNextClientCode']
  );

  const touchCaseNumberInput = () => touch('inputCaseNumber');
  const untouchCaseNumberInput = () => untouch('inputCaseNumber');

  const touchNextClientInputs = () =>
    touch(['inputNextClientId', 'inputNextClientCode']);
  const untouchNextClientInputs = () =>
    untouch(['inputNextClientId', 'inputNextClientCode']);

  const confirmCaseNumber = setConfirmedCaseNumberEffect({
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  });

  const confirmNextClient = setConfirmedNextClientIdEffect({
    isInputNextClientValid,
    inputNextClientId,
    inputNextClientCode,
    setConfirmedNextClientId,
    setConfirmedNextClientCode,
    touchNextClientInputs,
    untouchNextClientInputs,
  });

  const resetCase = resetCaseEffect({
    setCaseInfo,
    setClient,
    setNextClient,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    setInputNextClientId,
    setConfirmedNextClientId,
    setInputNextClientCode,
    setConfirmedNextClientCode,
    untouchCaseNumberInput,
    untouchNextClientInputs,
    caseNumberInputRef,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseInfoAbortControllerRef,
        getClientAbortControllerRef,
        caseReassignmentAbortControllerRef,
      ],
      setCaseInfoActive,
      setClientActive,
      setNextClientActive,
      setCaseReassignmentActive,
    }),
    []
  );

  useEffect(
    onUpdateCaseNumberEffect({
      caseNumberParam,
      setInputCaseNumber,
      setConfirmedCaseNumber,
      resetCase,
    }),
    [caseNumberParam]
  );

  useEffect(
    focusInputEffect({
      inputRef: caseNumberInputRef,
    }),
    []
  );

  useEffect(
    getCaseInfoEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      setCaseInfoActive,
      setCaseInfo,
      getCaseInfoAbortControllerRef,
    }),
    [confirmedCaseNumber]
  );

  useEffect(
    getClientEffect({
      t,
      api,
      clientId,
      clientCode,
      setClientActive,
      setClient,
      setClientOptions,
      getClientAbortControllerRef,
    }),
    [clientId, clientCode]
  );

  useEffect(
    getClientEffect({
      t,
      api,
      clientId: confirmedNextClientId,
      clientCode: confirmedNextClientCode,
      setClientActive: setNextClientActive,
      setClient: setNextClient,
      getClientAbortControllerRef: getNextClientAbortControllerRef,
    }),
    [confirmedNextClientId, confirmedNextClientCode]
  );

  return (
    <Page
      className="case-reassign"
      title={t('components.CaseReassignment.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <CaseReassignmentBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <CaseNumberInput
            inputRef={caseNumberInputRef}
            inputName="inputCaseNumber"
            value={inputCaseNumber}
            isValid={!isTouched('inputCaseNumber') || isInputCaseNumberValid}
            onChange={onChangeCaseNumberInputEffect({
              setInputCaseNumber,
            })}
            onBlur={onBlurInputEffect({
              touch,
            })}
            onConfirm={confirmCaseNumber}
            onReset={resetCase}
          />

          {(!hasClient || clientActive) && (
            <ClientSummarySkeleton animated={caseInfoActive || clientActive} />
          )}

          {hasClient && !clientActive && <ClientSummary client={client} />}

          <Overlay visible={!hasCase}>
            <form>
              <CaseInformationForm
                isNew={false}
                isCopy={false}
                formData={caseInformation}
                formOptions={{ legalProblems, offices }}
                onChange={() => {}}
              />
            </form>
          </Overlay>

          <div className="case-reassign-next-client">
            <h3>{t('components.CaseReassignment.reassignTo')}</h3>

            <InputHelp
              validationContent={t(
                'components.CaseReassignment.clientIdValidation'
              )}
              invalid={
                isTouched(['inputNextClientId', 'inputNextClientCode']) &&
                !isInputNextClientValid
              }
            >
              <div className="case-number-input-group">
                <button
                  className="case-number-input-reset"
                  onClick={resetNewClientIdEffect({
                    setNextClient,
                    setInputNextClientId,
                    setInputNextClientCode,
                    setConfirmedNextClientId,
                    setConfirmedNextClientCode,
                    untouchNextClientInputs,
                    nextClientIdInputRef,
                  })}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <input
                  ref={nextClientIdInputRef}
                  type="text"
                  id="next-client-id-input"
                  className="case-number-input case-reassign-client-id-input"
                  autoComplete="off"
                  name="inputNextClientId"
                  value={inputNextClientId}
                  placeholder={t(
                    'components.CaseReassignment.clientIdPlaceholder'
                  )}
                  onKeyPress={onKeyPressEffect({
                    onEnter: confirmNextClient,
                  })}
                  onChange={onChangeInputEffect({
                    setValue: setInputNextClientId,
                  })}
                  onBlur={onBlurInputEffect({
                    touch,
                  })}
                />
                <input
                  type="text"
                  id="next-client-code-input"
                  className="case-number-input case-reassign-client-code-input"
                  autoComplete="off"
                  name="inputNextClientCode"
                  value={inputNextClientCode}
                  placeholder={t(
                    'components.CaseReassignment.clientCodePlaceholder'
                  )}
                  onKeyPress={onKeyPressEffect({
                    onEnter: confirmNextClient,
                  })}
                  onChange={onChangeInputEffect({
                    setValue: setInputNextClientCode,
                  })}
                  onBlur={onBlurInputEffect({
                    touch,
                  })}
                />
                <button
                  className="button button-highlight case-number-input-confirm"
                  onClick={confirmNextClient}
                >
                  {t('components.CaseReassignment.selectClient')}
                </button>
              </div>
            </InputHelp>

            {(!hasNextClient || nextClientActive) && (
              <ClientSummarySkeleton animated={nextClientActive} />
            )}

            {hasNextClient && !nextClientActive && (
              <ClientSummary client={nextClient} disableNavigation />
            )}
          </div>
        </div>
      </div>

      <ConfirmCaseReassignmentModal
        mounted={confirmingCaseReassignment}
        isActive={caseReassignmentActive}
        onClose={cancelReassignCaseEffect({ setConfirmingCaseReassignment })}
        onConfirm={reassignCaseEffect({
          t,
          api,
          caseNumber: confirmedCaseNumber,
          clientId: confirmedNextClientId,
          clientCode: confirmedNextClientCode,
          setCaseReassignmentActive,
          caseReassignmentAbortControllerRef,
          setConfirmingCaseReassignment,
          history,
        })}
      />

      {hasCase && hasNextClient && (
        <Fragment>
          <FABPosition>
            <FAB
              actions={[
                {
                  title: t('components.CaseReassignment.reassignCase'),
                  icon: <FontAwesomeIcon icon={faShuffle} />,
                  active: caseReassignmentActive,
                  disabled: caseReassignmentActive,
                  onClick: confirmReassignCaseEffect({
                    setConfirmingCaseReassignment,
                  }),
                },
              ]}
            />
          </FABPosition>
          <FABOffset />
        </Fragment>
      )}
    </Page>
  );
};

const ValidatedCaseReassignment = () => (
  <ValidationProvider rules={caseReassignmentValidationRules}>
    <CaseReassignment />
  </ValidationProvider>
);

export default ValidatedCaseReassignment;
