export const roundToPrecision = (value, precision = 1) => {
  const multiplier = Math.pow(10, parseFloat(precision));
  return Math.round(value * multiplier) / multiplier;
};

export const roundToFixedPointString = (value, precision = 1) => {
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return '';
  }

  return roundToPrecision(numericValue, precision).toFixed(precision);
};

export const sumOf = (values = []) => {
  const numericValues = values.map((value) => {
    const number = Number(value);
    return !isNaN(number) ? number : 0;
  });
  return numericValues.reduce((sum, value) => sum + value, 0);
};
