import React, { useEffect, useState, useRef } from 'react';
import CIFPrintLayout from './cif-print-layout';
import { getMultipleCIFDataEffect, multiplePrintViewEffect } from './effects';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ActivityIndicatorCard } from 'js/components/design-system';
import EmptyState from 'js/components/controls/empty-state';
import Page from 'js/components/page';

const PrintMultipleCIF = () => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [CIFDataArray, setCIFDataArray] = useState([]);
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);
  const { params = {} } = useRouteMatch();
  const { date } = params;
  const getCifAbortControllerRef = useRef(null);

  const getCIFDataEffectOptions = {
    api,
    date,
    setCIFDataArray,
    setActive,
    setError,
    getCifAbortControllerRef,
  };

  useEffect(getMultipleCIFDataEffect(getCIFDataEffectOptions), []);
  useEffect(multiplePrintViewEffect({ CIFDataArray, active }), [
    CIFDataArray.length,
    active,
  ]);

  return (
    <Page title={t('common.print')} className="print-page">
      {active && <ActivityIndicatorCard />}
      {!active && error && (
        <p className="print-page-error">
          {t('components.Print.errors.cifData', {
            message: error.message,
          })}
        </p>
      )}
      {!active &&
        !error &&
        CIFDataArray.map((data, key) => (
          <CIFPrintLayout cifData={data} key={key} />
        ))}
      {!active && !error && CIFDataArray.length === 0 && (
        <EmptyState
          title={t(`components.dayEnd.emptyStateTitle`)}
          subtitle={t(`components.dayEnd.emptyStateSubtitle`, {
            reportType: 'CIF',
            date: decodeURIComponent(date),
          })}
        />
      )}
    </Page>
  );
};

export default PrintMultipleCIF;
