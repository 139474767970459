import React, { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import { FetchContext } from 'js/components/fetch';
import { ActivityIndicatorCard } from 'js/components/design-system';
import { isEmpty } from 'js/utilities/validation';
import LawyerTypeBreadcrumbs from './breadcrumbs';
import LawyerTypeCard from './lawyer-type-card';
import {
  onMountEffect,
  setEditingLawyerTypeEffect,
  resetEditingLawyerTypeEffect,
  setAddingLawyerTypeEffect,
  resetAddingLawyerTypeEffect,
  updateLawyerTypeEffect,
  getLawyerTypesAndOptionsEffect,
  patchLawyerTypeEffect,
  postLawyerTypeEffect,
} from './effects';

const LawyerTypes = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingLawyerType, setEditingLawyerType] = useState({});
  const [addingLawyerType, setAddingLawyerType] = useState({});
  const [lawyerTypes, setLawyerTypes] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const { api = {} } = useContext(FetchContext);
  const listElementRef = useRef();
  const isEditing = !isEmpty(editingLawyerType);
  const isAdding = !isEmpty(addingLawyerType);
  const isLocked = isEditing || isAdding;

  const getLawyerTypesAbortControllerRef = useRef(null);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getLawyerTypesAbortControllerRef],
      setActive,
    }),
    []
  );

  useEffect(
    getLawyerTypesAndOptionsEffect({
      t,
      api,
      setActive,
      setLawyerTypes,
      setAccountOptions,
      getLawyerTypesAbortControllerRef,
    }),
    []
  );

  return (
    <Page
      className="lawyer-types"
      title={t('components.LawyerTypes.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <LawyerTypeBreadcrumbs />
        </div>
      </div>
      <div className="layout-container inset-col-2">
        <div className="layout-column">
          {active && <ActivityIndicatorCard />}
          {!active && (
            <React.Fragment>
              <div className="lawyer-types-heading">
                <div className="lawyer-types-heading-left">
                  <h1>{t('components.LawyerTypes.heading')}</h1>
                </div>
                <div className="lawyer-types-heading-right">
                  <button
                    className="button button-highlight page-action-button"
                    onClick={setAddingLawyerTypeEffect({
                      setAddingLawyerType,
                      listElementRef,
                    })}
                    disabled={isLocked}
                  >
                    {t('common.new')}
                  </button>
                </div>
              </div>
              <ul
                ref={listElementRef}
                className="unstyled-list lawyer-types-list"
              >
                {lawyerTypes.map((lawyerType) => {
                  const isEditingLawyerType =
                    isEditing && editingLawyerType.code === lawyerType.code;
                  const referenceLawyerType = isEditingLawyerType
                    ? editingLawyerType
                    : lawyerType;
                  return (
                    <li key={lawyerType.code}>
                      <LawyerTypeCard
                        lawyerType={referenceLawyerType}
                        accountOptions={accountOptions}
                        expanded={isEditingLawyerType}
                        expandEnabled={!isLocked}
                        saving={saving}
                        onClickExpand={setEditingLawyerTypeEffect({
                          lawyerType,
                          setEditingLawyerType,
                        })}
                        onClickCancel={resetEditingLawyerTypeEffect({
                          setEditingLawyerType,
                        })}
                        onClickSave={patchLawyerTypeEffect({
                          t,
                          api,
                          editingLawyerType,
                          lawyerTypes,
                          setLawyerTypes,
                          setSaving,
                          onComplete: resetEditingLawyerTypeEffect({
                            setEditingLawyerType,
                          }),
                        })}
                        onChange={updateLawyerTypeEffect({
                          allowUpdate: isEditingLawyerType,
                          lawyerType: editingLawyerType,
                          setLawyerType: setEditingLawyerType,
                        })}
                      />
                    </li>
                  );
                })}

                {isAdding && (
                  <li>
                    <LawyerTypeCard
                      lawyerType={addingLawyerType}
                      accountOptions={accountOptions}
                      expanded={true}
                      saving={saving}
                      onClickExpand={() => {}}
                      onClickCancel={resetAddingLawyerTypeEffect({
                        setAddingLawyerType,
                      })}
                      onClickSave={postLawyerTypeEffect({
                        t,
                        api,
                        addingLawyerType,
                        lawyerTypes,
                        setLawyerTypes,
                        setSaving,
                        onComplete: resetAddingLawyerTypeEffect({
                          setAddingLawyerType,
                        }),
                      })}
                      onChange={updateLawyerTypeEffect({
                        allowUpdate: true,
                        lawyerType: addingLawyerType,
                        setLawyerType: setAddingLawyerType,
                      })}
                    />
                  </li>
                )}
              </ul>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};

export default LawyerTypes;
