export const resolveOptionalFunctionProp = (prop, data = {}) => {
  return typeof prop === 'function' ? prop(data) : prop;
};

export const resolvePromptPropsForRule = (rule, data) => {
  const {
    message,
    allowConfirm,
    confirmButtonTitle,
    confirmButtonClassName,
    allowCancel,
    cancelButtonTitle,
    cancelButtonClassName,
  } = rule;

  return {
    message: resolveOptionalFunctionProp(message, data),
    allowConfirm: resolveOptionalFunctionProp(allowConfirm, data),
    confirmButtonTitle: resolveOptionalFunctionProp(confirmButtonTitle, data),
    confirmButtonClassName: resolveOptionalFunctionProp(
      confirmButtonClassName,
      data
    ),
    allowCancel: resolveOptionalFunctionProp(allowCancel, data),
    cancelButtonTitle: resolveOptionalFunctionProp(cancelButtonTitle, data),
    cancelButtonClassName: resolveOptionalFunctionProp(
      cancelButtonClassName,
      data
    ),
  };
};
