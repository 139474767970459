import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const FIRM_ACTIVE_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const queryForLawFirms = (search = '') => {
  const {
    Keyword = '',
    Order = 'firmName',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
    ActiveStatus = FIRM_ACTIVE_STATUS.ACTIVE,
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    Order,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
    ActiveStatus,
  };

  return queryForParams(params);
};
