import { paramsFromSearchString, queryForParams } from 'js/utilities/params';

export const queryForSearchString = (search = '') => {
  const {
    orderBy = 'timestamp',
    order = 'desc',
    ...otherParams
  } = paramsFromSearchString(search);

  const params = {
    ...otherParams,
    orderBy,
    order,
  };

  return queryForParams(params);
};
