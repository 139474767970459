import React, { createContext, useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from 'js/components/current-user';
import { loadGroupsEffect, matchAnyGroupEffect } from './effects';

const GroupPermissionsContext = createContext({
  hasLoadedGroups: false,
  matchAnyGroup: () => false,
});

const GroupPermissionsProvider = ({ children }) => {
  const { hasLoaded: hasLoadedUser, currentUser } = useContext(
    CurrentUserContext
  );
  const [hasLoadedGroups, setHasLoadedGroups] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(
    loadGroupsEffect({
      hasLoadedUser,
      currentUser,
      setGroups,
      setHasLoadedGroups,
    }),
    [hasLoadedUser]
  );

  return (
    <GroupPermissionsContext.Provider
      value={{
        hasLoadedGroups,
        matchAnyGroup: matchAnyGroupEffect({ groups }),
      }}
    >
      {children}
    </GroupPermissionsContext.Provider>
  );
};

export { GroupPermissionsContext, GroupPermissionsProvider };
