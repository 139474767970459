import {
  rotateAbortControllerRef,
  isAbortError,
  executeAbortControllerRefs,
} from 'js/components/fetch';

export const DATABASE_RESTORE_POLLING_INTERVAL = 10000;

export const onMountEffect = (options = {}) => {
  const { pollingIntervalRef, abortControllerRefs = [], setLoading } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      clearInterval(pollingIntervalRef.current);
      executeAbortControllerRefs(abortControllerRefs);
      setLoading(false);
    };
  };
};

export const getDatabaseRestoreStatusEffect = (options = {}) => {
  const {
    t,
    api,
    setLoading,
    setRestoring,
    setSessionId,
    getDatabaseRestoreStatusAbortControllerRef,
    getDatabaseBackups,
  } = options;
  return async () => {
    setLoading(true);

    rotateAbortControllerRef(getDatabaseRestoreStatusAbortControllerRef);
    const { signal } = getDatabaseRestoreStatusAbortControllerRef.current;

    try {
      const { json = {} } = await api.getJson(
        '/Admin/database/restore/status',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.DatabaseBackups.getDatabaseRestoreStatusError'
              ),
            },
          },
        }
      );

      const { inProgress = false, sessionId = '' } = json;

      if (inProgress) {
        setRestoring(true);
        setSessionId(sessionId);
        setLoading(false);
      } else {
        getDatabaseBackups();
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false);
      }
    }
  };
};

export const getDatabaseSessionStatusEffect = (options = {}) => {
  const {
    t,
    api,
    setRestoring,
    sessionId,
    setLogs,
    setSessionId,
    getDatabaseSessionStatusAbortControllerRef,
    getDatabaseBackups,
  } = options;
  return async () => {
    if (!sessionId) {
      setRestoring(false);
      return;
    }

    rotateAbortControllerRef(getDatabaseSessionStatusAbortControllerRef);
    const { signal } = getDatabaseSessionStatusAbortControllerRef.current;

    try {
      const { json } = await api.getJson(
        `/Admin/database/restore/status/${sessionId}`,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t(
                'components.DatabaseBackups.getDatabaseSessionStatusError'
              ),
            },
          },
        }
      );

      const { session = {}, logs = [] } = json;
      const { isComplete = false } = session;

      if (isComplete) {
        setLogs([]);
        setSessionId('');
        setRestoring(false);
        getDatabaseBackups();
      } else {
        setLogs(logs);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setRestoring(false);
      }
    }
  };
};

export const pollDatabaseSessionStatusEffect = (options = {}) => {
  const { pollingIntervalRef, sessionId, getDatabaseSessionStatus } = options;
  return () => {
    if (sessionId) {
      pollingIntervalRef.current = setInterval(
        async () => await getDatabaseSessionStatus(),
        DATABASE_RESTORE_POLLING_INTERVAL
      );
    } else {
      clearInterval(pollingIntervalRef.current);
    }
  };
};

export const getDatabaseBackupsEffect = (options = {}) => {
  const {
    t,
    api,
    setLoading,
    setDatabase,
    setReportingDatabase,
    setDailyExports,
    setWeeklyExports,
    setMonthlyExports,
    setYearlyExports,
    getDatabaseBackupsAbortControllerRef,
  } = options;
  return async () => {
    setLoading(true);

    rotateAbortControllerRef(getDatabaseBackupsAbortControllerRef);
    const { signal } = getDatabaseBackupsAbortControllerRef.current;

    try {
      const { json } = await api.getJson(
        '/Admin/database/backups',
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.DatabaseBackups.getDatabaseBackupsError'),
            },
          },
        }
      );

      const { database, reportingDatabase, exportsByType = {} } = json;

      setDatabase(database || {});
      setReportingDatabase(reportingDatabase || {});
      setDailyExports(exportsByType.daily || []);
      setWeeklyExports(exportsByType.weekly || []);
      setMonthlyExports(exportsByType.monthly || []);
      setYearlyExports(exportsByType.yearly || []);
      setLoading(false);
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false);
      }
    }
  };
};

export const restoreDatabaseEffect = (options = {}) => {
  const {
    t,
    api,
    setRestoring,
    getDatabaseBackups,
    restoreDatabaseAbortControllerRef,
    setLogs,
    setSessionId,
  } = options;
  return async (backup) => {
    setRestoring(true);

    rotateAbortControllerRef(restoreDatabaseAbortControllerRef);
    const { signal } = restoreDatabaseAbortControllerRef.current;

    const url = `/Admin/database/backups/${backup.sessionId}/restore`;
    const body = { autoDeleteExistingDatabase: true };

    // UNCOMMENT WHILE TESTING:
    // body.dryRun = true;

    try {
      const { json = {} } = await api.postJson(
        url,
        { signal, body },
        {
          success: {
            context: {
              message: t('components.DatabaseBackups.restoreDatabaseSuccess'),
            },
          },
          error: { bypass: true },
        }
      );

      const { isComplete = false, sessionId = '' } = json;

      if (isComplete) {
        setSessionId('');
        setLogs([]);
        setRestoring(false);
        getDatabaseBackups();
      } else {
        setSessionId(sessionId);
        setLogs([]);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        setRestoring(false);
      }
    }
  };
};

export const onConfirmDatabaseRestoreEffect = (options = {}) => {
  const { setExportToRestore, restoreDatabase } = options;
  return (backup) => {
    setExportToRestore({});
    restoreDatabase(backup);
  };
};

export const onCancelDatabaseRestoreEffect = (options = {}) => {
  const { setExportToRestore } = options;
  return () => setExportToRestore({});
};

export const onClickRestoreBackupEffect = (options = {}) => {
  const { backup = {}, onClickRestore } = options;
  return () => onClickRestore(backup);
};
