import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '../card';
import { FontAwesomeIcon } from '../../icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ListCard = (props) => {
  const { className, heading, subheading, accessory, ...cardProps } = props;
  return (
    <Card className={classnames('list-card', className)} {...cardProps}>
      <div className="list-card-content">
        <h2>{heading}</h2>
        <p>{subheading}</p>
      </div>
      <div className="list-card-accessory">{accessory}</div>
    </Card>
  );
};

ListCard.defaultProps = {
  accessory: <FontAwesomeIcon icon={faChevronRight} />,
};

ListCard.propTypes = {
  ...Card.propTypes,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  accessory: PropTypes.node,
};

export default ListCard;
