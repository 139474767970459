import { routePaths } from 'js/components/router/route-paths';
import { proceduresBreadcrumbsHistory } from 'js/utilities/admin-tools';

export const linkPropsForRoutePath = (path) => {
  return {
    linkTo: path,
    isActive: (currentPath) => currentPath === path,
  };
};

export const chequeRunTabItems = (t) => {
  const chequeRunCurrentTab = {
    ...linkPropsForRoutePath(routePaths.chequeRunCurrent),
    content: t('components.ChequeRunCurrent.title'),
  };

  const chequeRunLastTab = {
    ...linkPropsForRoutePath(routePaths.chequeRunLast),
    content: t('components.ChequeRunLast.title'),
  };

  /*
  const chequeRunHistoryTab = {
    ...linkPropsForRoutePath(routePaths.chequeRunHistory),
    content: t('components.ChequeRunHistory.title'),
  };
  */

  return [chequeRunCurrentTab, chequeRunLastTab];
};

export const chequeRunBreadcrumbsHistory = (t, location = {}) => {
  const { pathname } = location;
  return [
    ...proceduresBreadcrumbsHistory(t),
    {
      content: t('components.ChequeRunPage.breadcrumbs.chequeRun'),
      path: pathname,
    },
  ];
};
