import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from 'js/components/fetch';
import {
  ValidationProvider,
  ValidationContext,
} from 'js/components/validation';
import {
  Overlay,
  FontAwesomeIcon,
  FAB,
  FABPosition,
  FABOffset,
} from 'js/components/design-system';
import Page from 'js/components/page';
import FixedHeader from 'js/components/headers/fixed-header';
import CaseNumberInput from 'js/components/controls/case-number-input';
import {
  ClientSummary,
  ClientSummarySkeleton,
} from 'js/components/client-summary';
import {
  caseCorrectionValidationRules,
  caseCorrectionRequiredFields,
} from 'js/utilities/cases';
import { paramsFromSearchString } from 'js/utilities/params';
import { isEmpty } from 'js/utilities/validation';
import { CaseInformationForm, CaseCorrectionForm } from '../case-forms';
import CaseCorrectionBreadcrumbs from './breadcrumbs';
import {
  focusInputEffect,
  onChangeCaseNumberInputEffect,
  onBlurInputEffect,
  onChangeCaseCorrectionFieldEffect,
  resetCaseEffect,
  setConfirmedCaseNumberEffect,
  onMountEffect,
  onUpdateCaseNumberEffect,
  getCaseInfoEffect,
  getClientEffect,
  updateCaseCorrectionFieldsEffect,
} from './effects';

const CaseCorrection = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search = '' } = useLocation();
  const { casenumber: caseNumberParam = '' } = paramsFromSearchString(search);

  //refs
  const caseNumberInputRef = useRef(null);
  const getCaseInfoAbortControllerRef = useRef(null);
  const getClientAbortControllerRef = useRef(null);
  const patchCaseCorrectionAbortControllerRef = useRef(null);

  //states
  const [inputCaseNumber, setInputCaseNumber] = useState('');
  const [confirmedCaseNumber, setConfirmedCaseNumber] = useState('');
  const [caseInfoActive, setCaseInfoActive] = useState(false);
  const [caseInfo, setCaseInfo] = useState({});

  const [caseCorrectionActive, setCaseCorrectionActive] = useState(false);
  const [caseCorrectionFields, setCaseCorrectionFields] = useState({});

  const [clientActive, setClientActive] = useState(false);
  const [client, setClient] = useState({});
  const [clientOptions, setClientOptions] = useState({});
  const { offices = [] } = clientOptions;

  //contexts
  const { api = {} } = useContext(FetchContext);
  const { validate, touch, untouch, isTouched } = useContext(ValidationContext);

  const hasCase = !isEmpty(caseInfo);
  const hasClient = !isEmpty(client);

  const { caseInformation = {} } = caseInfo;
  const { clientId, clientCode, legalProblems = [] } = caseInformation;

  const { isValid: isInputCaseNumberValid } = validate({ inputCaseNumber }, [
    'inputCaseNumber',
  ]);

  const touchCaseNumberInput = () => touch('inputCaseNumber');
  const untouchCaseNumberInput = () => untouch('inputCaseNumber');

  const { isValid: areCaseCorrectionInputsValid } = validate(
    caseCorrectionFields,
    caseCorrectionRequiredFields
  );

  const touchCaseCorrectionInputs = () => touch(caseCorrectionRequiredFields);
  const untouchCaseCorrectionInputs = () =>
    untouch(caseCorrectionRequiredFields);

  const confirmCaseNumber = setConfirmedCaseNumberEffect({
    isInputCaseNumberValid,
    inputCaseNumber,
    setConfirmedCaseNumber,
    touchCaseNumberInput,
    untouchCaseNumberInput,
    history,
  });

  const resetCase = resetCaseEffect({
    setCaseInfo,
    setCaseCorrectionFields,
    setClient,
    setClientOptions,
    setInputCaseNumber,
    setConfirmedCaseNumber,
    untouchCaseNumberInput,
    untouchCaseCorrectionInputs,
    caseNumberInputRef,
  });

  useEffect(
    onMountEffect({
      abortControllerRefs: [
        getCaseInfoAbortControllerRef,
        getClientAbortControllerRef,
        patchCaseCorrectionAbortControllerRef,
      ],
      setCaseInfoActive,
      setClientActive,
      setCaseCorrectionActive,
    }),
    []
  );

  useEffect(
    onUpdateCaseNumberEffect({
      caseNumberParam,
      setInputCaseNumber,
      setConfirmedCaseNumber,
      resetCase,
    }),
    [caseNumberParam]
  );

  useEffect(
    focusInputEffect({
      inputRef: caseNumberInputRef,
    }),
    []
  );

  useEffect(
    getCaseInfoEffect({
      t,
      api,
      caseNumber: confirmedCaseNumber,
      setCaseInfoActive,
      setCaseInfo,
      setCaseCorrectionFields,
      getCaseInfoAbortControllerRef,
    }),
    [confirmedCaseNumber]
  );

  useEffect(
    getClientEffect({
      t,
      api,
      clientId,
      clientCode,
      setClientActive,
      setClient,
      setClientOptions,
      getClientAbortControllerRef,
    }),
    [clientId, clientCode]
  );

  return (
    <Page
      className="case-reassign"
      title={t('components.CaseCorrection.title')}
      header={<FixedHeader />}
    >
      <div className="layout-container">
        <div className="layout-column">
          <CaseCorrectionBreadcrumbs />
        </div>
      </div>
      <div className="layout-container  inset-col-1">
        <div className="layout-column">
          <CaseNumberInput
            inputRef={caseNumberInputRef}
            inputName="inputCaseNumber"
            value={inputCaseNumber}
            isValid={!isTouched('inputCaseNumber') || isInputCaseNumberValid}
            onChange={onChangeCaseNumberInputEffect({
              setInputCaseNumber,
            })}
            onBlur={onBlurInputEffect({
              touch,
            })}
            onConfirm={confirmCaseNumber}
            onReset={resetCase}
          />

          {(!hasClient || clientActive) && (
            <ClientSummarySkeleton animated={caseInfoActive || clientActive} />
          )}

          {hasClient && !clientActive && <ClientSummary client={client} />}

          <Overlay visible={!hasCase || !hasClient}>
            <form>
              <CaseInformationForm
                isNew={false}
                isCopy={false}
                formData={caseInformation}
                formOptions={{ legalProblems, offices }}
                onChange={() => {}}
              />
            </form>
            <CaseCorrectionForm
              formData={caseCorrectionFields}
              onChange={onChangeCaseCorrectionFieldEffect({
                setCaseCorrectionFields,
                untouch,
              })}
            />
          </Overlay>
        </div>
      </div>

      {hasCase && (
        <Fragment>
          <FABPosition>
            <FAB
              actions={[
                {
                  title: t('components.CaseCorrection.save'),
                  icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
                  active: caseCorrectionActive,
                  disabled: caseCorrectionActive,
                  onClick: updateCaseCorrectionFieldsEffect({
                    t,
                    api,
                    areCaseCorrectionInputsValid,
                    touchCaseCorrectionInputs,
                    caseNumber: confirmedCaseNumber,
                    caseCorrectionFields,
                    setCaseCorrectionActive,
                    patchCaseCorrectionAbortControllerRef,
                    resetCase,
                  }),
                },
              ]}
            />
          </FABPosition>
          <FABOffset />
        </Fragment>
      )}
    </Page>
  );
};

const ValidatedCaseCaseCorrection = () => (
  <ValidationProvider rules={caseCorrectionValidationRules}>
    <CaseCorrection />
  </ValidationProvider>
);

export default ValidatedCaseCaseCorrection;
