import { cleanStringField } from 'js/utilities/strings';

export const cleanDependentsItem = (item = {}) => {
  return {
    firstName: cleanStringField(item.firstName),
    lastName: cleanStringField(item.lastName),
    status: cleanStringField(item.status),
    code: cleanStringField(item.code),
    isActive: item.isActive === true,
  };
};

export const cleanDependents = (dependents = []) => {
  return dependents.map(cleanDependentsItem);
};
