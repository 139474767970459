import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'js/utilities/dates';
import { prepareLegalProblemsForPrintMemo } from './functions';
import ReleasePrintLegalProblemItem from './release-print-legal-problem-item';

const ReleasePrintLayout = (props) => {
  const { t } = useTranslation();
  const { className, release = {} } = props;
  const {
    effectiveDate,
    endDate,
    legalProblemCodeSelectOptions = [],
    associatedCompanies = [],
  } = release;

  const legalProblems = useMemo(
    prepareLegalProblemsForPrintMemo({ legalProblemCodeSelectOptions }),
    [legalProblemCodeSelectOptions]
  );

  return (
    <div
      className={classnames('release-print-layout', className)}
      data-print-managed
      data-print-only
    >
      <ul className="release-print-metadata">
        <li>
          {t('components.ReleasePrintLayout.startDate')}{' '}
          {effectiveDate && formatDate(effectiveDate)}
        </li>
        <li>
          {t('components.ReleasePrintLayout.endDate')}{' '}
          {endDate && formatDate(endDate)}
        </li>
      </ul>

      {legalProblems.length > 0 && (
        <ol className="release-print-legal-problems">
          {legalProblems.map((item) => (
            <ReleasePrintLegalProblemItem
              key={item.legalProblem?.legalProblemCode}
              legalProblem={item}
            />
          ))}
        </ol>
      )}

      {associatedCompanies.length > 0 && (
        <table className="release-print-companies">
          <thead>
            <tr>
              <th>{t('components.ReleasePrintLayout.companyCode')}</th>
              <th>{t('components.ReleasePrintLayout.companyName')}</th>
            </tr>
          </thead>
          <tbody>
            {associatedCompanies.map((company) => {
              const { companyCode = '', companyName = '' } = company;
              return (
                <tr key={companyCode}>
                  <td>{companyCode}</td>
                  <td>{companyName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

ReleasePrintLayout.propTypes = {
  className: PropTypes.string,
  release: PropTypes.shape({
    effectiveDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
};

export default ReleasePrintLayout;
