import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InputHelp, FontAwesomeIcon } from 'js/components/design-system';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  initializeSearchTermEffect,
  onChangeEffect,
  onKeyDownEffect,
  onExecuteSearchEffect,
  onResetSearchEffect,
  onSearchEffect,
} from './effects';

const GeneralSearchInput = (props) => {
  const { t } = useTranslation();
  const {
    className,
    placeholder = t('components.controls.GeneralSearchInput.placeholder'),
    initialQuery,
    minimumTermLength,
    onSearch,
    onReset,
    disabled = false,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [termLengthInvalid, setTermLengthInvalid] = useState(false);

  useEffect(initializeSearchTermEffect({ initialQuery, setSearchTerm }), [
    initialQuery.search,
  ]);

  const onSearchIcon = onSearchEffect({
    minimumTermLength,
    setTermLengthInvalid,
    executeSearch: onExecuteSearchEffect({
      searchTerm,
      onSearch,
    }),
  });

  return (
    <div className={classnames('general-search-input', className)}>
      <InputHelp
        invalid={termLengthInvalid}
        validationContent={t(
          'components.controls.GeneralSearchInput.termLengthValidation',
          { minimumTermLength }
        )}
      >
        <div className="search-input-wrapper">
          <button
            className="search-input-reset"
            onClick={onResetSearchEffect({
              setSearchTerm,
              setTermLengthInvalid,
              onReset,
            })}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <input
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            value={searchTerm}
            onChange={onChangeEffect({
              minimumTermLength,
              termLengthInvalid,
              setTermLengthInvalid,
              setSearchTerm,
            })}
            onKeyDown={onKeyDownEffect({
              minimumTermLength,
              setTermLengthInvalid,
              executeSearch: onExecuteSearchEffect({
                searchTerm,
                onSearch,
              }),
            })}
            disabled={disabled}
          />
          <div className={classnames('search-input-icon', { disabled })}>
            <FontAwesomeIcon icon={faSearch} onClick={onSearchIcon} />
          </div>
        </div>
      </InputHelp>
    </div>
  );
};

GeneralSearchInput.defaultProps = {
  initialQuery: { params: { Keyword: '' }, search: '' },
  minimumTermLength: 2,
};

GeneralSearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  initialQuery: PropTypes.shape({
    params: PropTypes.shape({
      Keyword: PropTypes.string,
    }),
    search: PropTypes.string,
  }),
  minimumTermLength: PropTypes.number,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
};

export default GeneralSearchInput;
