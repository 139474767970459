import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { cleanStringField, formatPhoneNumber } from 'js/utilities/strings';
import {
  formatDateFromISOString,
  parseDateFromISOString,
} from 'js/utilities/dates';
import { defaultSortPredicate } from 'js/utilities/sort';

//used for api calls and as key for data context
export const clientApiKey = (id, clientCode = '00') =>
  `/Client/${id}/${clientCode}`;

export const clientStatus = {
  primaryClient: 'Primary Client',
  spouse: 'Spouse',
  survivingSpouse: 'Surviving Spouse',
  dependent: 'Dependent',
  retiree: 'Retiree',
};

export const clientStatusKeyForValue = (value) => {
  const match = Object.keys(clientStatus).find(
    (key) => clientStatus[key] === value
  );
  return match || '';
};

export const cleanClient = (client = {}) => {
  return {
    ...client,
    firstName: cleanStringField(client.firstName),
    lastName: cleanStringField(client.lastName),
    memberStatus: cleanStringField(client.memberStatus),
    memberCompanyName: cleanStringField(client.memberCompanyName),
    memberLocalCode: cleanStringField(client.memberLocalCode),
    lawyerCity: cleanStringField(client.lawyerCity),
    lawyerProv: cleanStringField(client.lawyerProv),
  };
};

export const cleanClients = (clients = []) => clients.map(cleanClient);

export const cleanNestedClient = (data = {}) => {
  const {
    clientAccount,
    clientAddress,
    clientEmployment,
    clientPersonal,
  } = data;
  return {
    clientAccount: cleanAccount(clientAccount),
    clientAddress: cleanAddress(clientAddress),
    clientEmployment: cleanEmployment(clientEmployment),
    clientPersonal: cleanClientPersonal(clientPersonal),
  };
};

export const cleanClientPersonal = (formData = {}) => {
  return {
    clientCode: cleanStringField(formData.clientCode),
    clientId: cleanStringField(formData.clientId),
    clientStatus: cleanStringField(formData.clientStatus),
    dateBirth: cleanStringField(formData.dateBirth),
    dateRetirement: parseDateFromISOString(formData.dateRetirement),
    emailAddress: cleanStringField(formData.emailAddress),
    firstName: cleanStringField(formData.firstName),
    lastName: cleanStringField(formData.lastName),
    phoneNumber: formatPhoneNumber(cleanStringField(formData.phoneNumber)),
    prefix: cleanStringField(formData.prefix),
    clientInactive: formData.clientInactive,
  };
};

export const cleanAddress = (formData = {}) => {
  return {
    address: cleanStringField(formData.address),
    address2: cleanStringField(formData.address2),
    city: cleanStringField(formData.city),
    postalCode: cleanStringField(formData.postalCode),
    province: cleanStringField(formData.province),
  };
};

export const cleanAccount = (formData = {}) => {
  const versionDate = cleanStringField(formData.version);
  return {
    feeScheduleId: cleanStringField(formData.feeScheduleId),
    feeScheduleName: cleanStringField(formData.feeScheduleName),
    outstandingAccount: formData.outstandingAccount,
    version: formatDateFromISOString(versionDate),
  };
};

export const cleanEmployment = (formData = {}) => {
  const { company = {}, local = {} } = formData;
  const comp = {
    companyCode: cleanStringField(company.companyCode),
    companyName: cleanStringField(company.companyName),
  };
  const loc = {
    localName: cleanStringField(local.localName),
    localCode: cleanStringField(local.localCode),
  };
  const date = cleanStringField(formData.seniorityDate);
  return {
    company: comp,
    local: loc,
    employeeNumber: cleanStringField(formData.employeeNumber),
    seniorityDate: date ? new Date(formatDateFromISOString(date)) : null,
    companyChanged: formData.companyChanged === true,
  };
};

export const cleanClientLegalProblems = (data = []) => {
  return data.map((legalProblem) => {
    return {
      legalProblemCode: cleanStringField(legalProblem.legalProblemCode),
      legalProblemDesc: cleanStringField(legalProblem.legalProblemDesc),
    };
  });
};

export const cleanClientOfficeOptions = (data = []) => {
  return data.map((office) => {
    return {
      officeName: cleanStringField(office.officeName),
      officeCode: cleanStringField(office.officeCode),
      officeType: cleanStringField(office.officeType),
    };
  });
};

export const cleanClientOptions = (data = {}) => {
  const { legalProblemCodes: legalProblems = [], officeOptions = [] } = data;
  return {
    legalProblems: cleanClientLegalProblems(legalProblems),
    offices: cleanClientOfficeOptions(officeOptions),
  };
};

export const cleanClientNotesItem = (item = {}) => {
  const parsedDate = parseDateFromISOString(item.date);
  const timestamp = parsedDate ? parsedDate.getTime() : 0;
  return {
    ...item,
    text: cleanStringField(item.text),
    name: cleanStringField(item.name),
    date: formatDateFromISOString(item.date),
    timestamp,
  };
};

export const cleanClientNotes = (clientNotes = []) => {
  return clientNotes.map(cleanClientNotesItem);
};

export const sortClientNotes = (clientNotes = [], query = {}) => {
  const { params = {} } = query;
  const { orderBy, order } = params;

  if (orderBy && order) {
    return [...clientNotes].sort((item1, item2) =>
      defaultSortPredicate(orderBy, order, item1, item2)
    );
  } else {
    return clientNotes;
  }
};

export const appendFromQueryParam = (path, currentLocation) => {
  const { pathname = '', search = '' } = currentLocation;
  const fromLocation = encodeURIComponent(`${pathname}${search}`);
  return path + `?from=${fromLocation}`;
};

export const routePathForClient = (client = {}, currentLocation) => {
  const { id = '', clientCode = '00' } = client;
  let path = routePathReplacingParams(routePaths.client, { id, clientCode });

  if (currentLocation) {
    path = appendFromQueryParam(path, currentLocation);
  }

  return path;
};

export const routePathForLawyer = (lawyer = {}, currentLocation) => {
  const { id: lawyerId = '' } = lawyer;
  let path = routePathReplacingParams(routePaths.lawyer, { lawyerId });

  if (currentLocation) {
    path = appendFromQueryParam(path, currentLocation);
  }

  return path;
};

export const routePathForClientOrDependent = (
  clientId,
  dependentCode = '00'
) => {
  return routePathReplacingParams(routePaths.client, {
    id: clientId,
    clientCode: dependentCode,
  });
};

export const routePathForNewDependent = (clientId) => {
  return routePathReplacingParams(routePaths.newDependent, {
    id: clientId,
  });
};

export const isNewClientPath = (path) => {
  return path.split('/').pop() === 'new';
};

export const isNewDependentPath = (path) => {
  return path.split('/').pop() === 'new-dependent';
};
