import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { setPageTitleEffect } from './effects';
import Footer from 'js/components/footer';
import { VersionInfoContext } from 'js/components/version-info';

const Page = (props) => {
  const { t } = useTranslation();
  const { className, title, header, footer, children } = props;
  const { versionInfo = {} } = useContext(VersionInfoContext);
  const environment = versionInfo.environment || '';

  useEffect(setPageTitleEffect({ t, title, environment }), [title]);

  return (
    <div className={classnames('page', className)}>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

Page.defaultProps = {
  title: '',
  header: <header />,
  footer: <Footer />,
};

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export default Page;
