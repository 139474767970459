import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PrintHeader } from 'js/components/print';

const ClientLogPrintHeader = (props) => {
  const { className, ...headerProps } = props;

  return (
    <PrintHeader
      className={classnames('Client-print-header', className)}
      {...headerProps}
    />
  );
};

ClientLogPrintHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string,
};

export default ClientLogPrintHeader;
