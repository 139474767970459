import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrintHeader } from 'js/components/print';

const ReleasePrintHeader = (props) => {
  const { t } = useTranslation();
  const { className, release = {} } = props;
  const { schedule = {} } = release;
  const { id = '', name = '' } = schedule;

  return (
    <PrintHeader
      className={classnames('release-print-header', className)}
      title={t('components.ReleasePrintLayout.title')}
      heading={
        <span>
          {id} {name}
        </span>
      }
    />
  );
};

ReleasePrintHeader.propTypes = {
  className: PropTypes.string,
  release: PropTypes.shape({
    schedule: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default ReleasePrintHeader;
