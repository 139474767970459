import { userGroups } from 'js/components/group-permissions';

export const sharedClientNamedPermissions = {
  viewClientOsNotes: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const clientInformationNamedPermissions = {
  ...sharedClientNamedPermissions,
  editClientInformation: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const clientDependentsNamedPermissions = {
  ...sharedClientNamedPermissions,
  addDependentToCoverage: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};

export const clientDocumentsNamedPermissions = {
  ...sharedClientNamedPermissions,
  uploadClientDocuments: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.support,
  ],
  downloadClientDocuments: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.support,
  ],
  deleteClientDocuments: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.support,
  ],
};

export const clientNotesNamedPermissions = {
  ...sharedClientNamedPermissions,
  addClientNotes: [
    userGroups.administrator,
    userGroups.dataEntry,
    userGroups.intakeSpecialist,
    userGroups.feedbackSpecialist,
    userGroups.support,
  ],
};
