import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'js/components/design-system';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import {
  lawyerTabItems,
  queryFilteringSearchParamsForLawyerTabs,
} from './functions';

const LawyerTabs = (props) => {
  const {
    disabled,
    isStaffLawyer,
    showLawyerFeedbackCount,
    lawyerFeedbackCount,
  } = props;
  const {
    path,
    params: { lawyerId },
  } = useRouteMatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = queryFilteringSearchParamsForLawyerTabs(search);
  return (
    <GroupPermissionsGate permittedGroups={Object.values(userGroups)}>
      {({ matchAnyGroup }) => {
        const canViewFeedback = matchAnyGroup([
          userGroups.administrator,
          userGroups.feedbackSpecialist,
          userGroups.dataEntry,
        ]);
        const tabItems = lawyerTabItems({
          t,
          params: { lawyerId },
          search: query.search,
          canViewFeedback,
          isStaffLawyer,
          showLawyerFeedbackCount,
          lawyerFeedbackCount,
        });
        return (
          <Tabs
            className="lawyer-tabs"
            items={tabItems}
            currentPath={path}
            disabled={disabled}
          />
        );
      }}
    </GroupPermissionsGate>
  );
};

LawyerTabs.defaultProps = {
  disabled: false,
  isStaffLawyer: false,
  showLawyerFeedbackCount: false,
  lawyerFeedbackCount: 0,
};

LawyerTabs.propTypes = {
  disabled: PropTypes.bool,
  isStaffLawyer: PropTypes.bool,
  showLawyerFeedbackCount: PropTypes.bool,
  lawyerFeedbackCount: PropTypes.number,
};

export default LawyerTabs;
