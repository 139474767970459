import React from 'react';
import PropTypes from 'prop-types';
import { cardComponentForModel } from './functions';

const SearchResultsCard = (props) => {
  const { className, model, data = {} } = props;
  const CardComponent = cardComponentForModel(model);
  if (CardComponent) {
    return <CardComponent className={className} data={data} />;
  } else {
    return null;
  }
};

SearchResultsCard.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default SearchResultsCard;
