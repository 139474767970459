import { roundToFixedPointString } from 'js/utilities/numbers';
import { sortCitiesByProvince } from 'js/utilities/regions';
import {
  executeAbortControllerRefs,
  rotateAbortControllerRef,
} from 'js/components/fetch';
import { isEmpty } from 'js/utilities/validation';
import { isNonCooperatingLawyerType } from 'js/utilities/lawyers';

export const onMountEffect = (options = {}) => {
  const { abortControllerRefs = [] } = options;
  return () => {
    // Abort requests on unmount:
    return () => {
      executeAbortControllerRefs(abortControllerRefs);
    };
  };
};

export const onInputChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, value } = e.target;
      onChange(name, value);
    }
  };
};

export const onInputBlurEffect = (options = {}) => {
  const { touch } = options;
  return (e) => touch(e.target.name);
};

export const onNumericInputBlurEffect = (options = {}) => {
  const { touch, onChange, toFixed = false, precision = 1 } = options;
  return (e) => {
    const { name, value } = e.target;

    let nextValue = '';

    if (toFixed) {
      nextValue = roundToFixedPointString(value, precision);
    } else {
      nextValue = !isNaN(Number(value)) ? value : '';
    }

    if (typeof onChange === 'function') {
      onChange(name, nextValue);
    }
    touch(name);
  };
};

export const onFocusTypeoverInput = () => {
  return (e) => {
    const { target } = e;
    const { value = '' } = target;
    target.setSelectionRange(0, value.length);
  };
};

export const onDatePickerChangeEffect = (options = {}) => {
  const { name, onChange, touch } = options;
  return (date) => {
    if (typeof onChange === 'function') {
      onChange(name, date);
      touch(name);
    }
  };
};

export const onBinaryRadioGroupChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (name, value) => {
    if (typeof onChange === 'function') {
      onChange(name, value);
    }
  };
};

export const onCheckboxChangeEffect = (options = {}) => {
  const { onChange } = options;
  return (e) => {
    if (typeof onChange === 'function') {
      const { name, checked } = e.target;
      onChange(name, checked);
    }
  };
};

export const onRadioGroupChangeEffect = (options = {}) => {
  const { onChange, touch } = options;
  return (e) => {
    const { name, value } = e.target;
    if (typeof onChange === 'function') {
      onChange(name, value);
    }
    touch(name);
  };
};

export const getProvinceOptionsEffect = (options = {}) => {
  const {
    setOptions,
    api,
    t,
    isNonCoopLawyer,
    getProvincesAbortControllerRef,
  } = options;
  return async () => {
    if (!isNonCoopLawyer) {
      return;
    }
    rotateAbortControllerRef(getProvincesAbortControllerRef);
    const { signal } = getProvincesAbortControllerRef.current;

    const url = '/Lawyer/Options';
    try {
      const { json = {} } = await api.getJson(
        url,
        { signal },
        {
          success: { bypass: true },
          error: {
            context: {
              message: t('components.LawyerInformation.requestError'),
            },
          },
        }
      );
      const citiesByProvince = sortCitiesByProvince(json);
      setOptions({ ...json, citiesByProvince });
    } catch (error) {
      return;
    }
  };
};

export const checkPermissionsEffect = (options = {}) => {
  const {
    hasLoadedGroups,
    matchAnyGroup,
    userGroups,
    setHasEditPermission,
  } = options;

  return () => {
    if (hasLoadedGroups) {
      const isMatch = matchAnyGroup([
        userGroups.administrator,
        userGroups.dataEntry,
        userGroups.intakeSpecialist,
        userGroups.support,
      ]);

      if (isMatch) setHasEditPermission(true);
    }
  };
};

export const updateDefaultCSQFlagEffect = (options = {}) => {
  // Updated Rules (see card 17233)
  const {
    onChange,
    planFees,
    planTaxes,
    csqThreshold,
    calculatePlanTotal,
    formData,
    currentUserOffice,
    lawyerType,
  } = options;
  return () => {
    if (isEmpty(formData)) {
      return;
    }
    const HEADOFFICE = '0100';
    const headOfficeUser = currentUserOffice === HEADOFFICE;
    const planTotal = calculatePlanTotal(planFees, planTaxes);
    const isNonCoopLawyer = isNonCooperatingLawyerType(lawyerType);
    const hasThreshhold = csqThreshold > 0;
    const surpassThreshold = planTotal >= csqThreshold;
    //updated rules Oct 27 2022
    //the case is being closed by a head office user (Case can be opened in any office)
    // the case has either a cooperating or staff lawyer (these rules do NOT apply to non-cooperating lawyers)
    // the case's total fees are greater than the csq threshold
    //staff offices can not print csqs, so if this is being closed by a staff office user the checkbox does not set
    if (
      headOfficeUser &&
      !isNonCoopLawyer &&
      hasThreshhold &&
      surpassThreshold
    ) {
      onChange('printCSQ', true);
    } else {
      return;
    }
  };
};

export const setAvailableOfficesEffect = (options = {}) => {
  const {
    isNewOrCopy = false,
    currentUser = {},
    offices = [],
    openedInOffice = {},
    setAvailableOffices,
    setOfficeEditable,
    onChange,
  } = options;

  return () => {
    let availableOffices = [];
    let officeEditable = false;
    let officeOpened = '';

    if (isNewOrCopy) {
      const { userSecurity = {}, office: currentOffice = {} } = currentUser;
      const { accessLevel } = userSecurity;

      if (currentOffice.code) {
        // In order to be able to select any office that is
        // included in the case options, the user must be from
        // head office (0100), or must have access level 3 in
        // either the Metro (0900) or Brampton (0810) office.
        const isHeadOffice = currentOffice.code === '0100';
        const isMetroOffice = currentOffice.code === '0800';
        const isBramptonOffice = currentOffice.code === '0810';
        const canSelectOffice =
          (isMetroOffice || isBramptonOffice) && accessLevel === 3;

        if (isHeadOffice || canSelectOffice) {
          // The user is eligible to select any office
          // that is included in the case options.
          availableOffices = [...offices];
          officeEditable = true;
        } else {
          // If the user is not eligible to select any office,
          // they must open the case in their own office.
          // That office will be preselected and the input
          // will be disabled.
          availableOffices = [
            { officeCode: currentOffice.code, officeName: currentOffice.name },
          ];
          officeOpened = currentOffice.code;
        }
      }

      // Fall through if the current user's office code is
      // not yet available. This is a temporary condition.
    } else if (openedInOffice.code) {
      // If the office is neither new nor a copy,
      // the only option will be the office in which
      // the case was opened.
      availableOffices = [
        { officeCode: openedInOffice.code, officeName: openedInOffice.name },
      ];
    }

    setAvailableOffices(availableOffices);
    setOfficeEditable(officeEditable);

    // Update the office code if it should be preselected
    // because the user is not eligible to select any office:
    if (officeOpened && typeof onChange === 'function') {
      onChange('officeOpened', officeOpened);
    }
  };
};
