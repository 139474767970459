import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FetchContext } from 'js/components/fetch';
import {
  ActivityIndicatorCard,
  OffsetLimitPagination,
  ListButtonCard,
} from 'js/components/design-system';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';
import GeneralSearchInput from 'js/components/controls/general-search-input';
import EmptyState from 'js/components/controls/empty-state';
import ReportsSortMenu from './sort-menu';
import {
  onMountEffect,
  getReportsFoldersEffect,
  previousReportsFoldersEffect,
  nextReportsFoldersEffect,
  reportsFoldersPageNumberEffect,
  reportsFoldersSortEffect,
  reportsFoldersSearchEffect,
  reportsFoldersSearchResetEffect,
} from './effects.js';
import { queryForReportsFoldersSearch } from './functions';

const FolderList = (props) => {
  const { onSelectFolder } = props;
  const { t } = useTranslation();
  const { api = {} } = useContext(FetchContext);

  const getReportsFoldersAbortControllerRef = useRef(null);

  const [isLoadingFolders, setLoadingFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [totalFolders, setTotalFolders] = useState(0);
  const [search, setSearch] = useState('');

  const query = queryForReportsFoldersSearch(search);

  useEffect(
    onMountEffect({
      abortControllerRefs: [getReportsFoldersAbortControllerRef],
      setLoadingFolders,
    }),
    []
  );

  useEffect(
    getReportsFoldersEffect({
      t,
      api,
      query,
      setLoadingFolders,
      setFolders,
      setTotalFolders,
      getReportsFoldersAbortControllerRef,
    }),
    [query.search]
  );

  const pagination = (
    <div className="move-report-modal-pagination-container">
      <OffsetLimitPagination
        className="move-report-modal-pagination"
        offset={query.params.Offset}
        limit={query.params.Limit}
        totalResults={totalFolders}
        onClickPrevious={previousReportsFoldersEffect({
          query,
          setSearch,
        })}
        onClickNext={nextReportsFoldersEffect({
          query,
          setSearch,
        })}
        onClickPageNumber={reportsFoldersPageNumberEffect({
          query,
          setSearch,
        })}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="move-report-modal-search-bar">
        <GeneralSearchInput
          placeholder={t('components.MoveReportModal.searchPlaceholder')}
          initialQuery={query}
          onSearch={reportsFoldersSearchEffect({ query, setSearch })}
          onReset={reportsFoldersSearchResetEffect({ query, setSearch })}
        />
        <ReportsSortMenu
          field={query.params.Order}
          direction={query.params.Sort}
          onSelect={reportsFoldersSortEffect({ query, setSearch })}
        />
      </div>

      {isLoadingFolders && <ActivityIndicatorCard />}

      {!isLoadingFolders && folders.length === 0 && (
        <EmptyState title={t(`components.MoveReportModal.emptyStateMessage`)} />
      )}

      {!isLoadingFolders && folders.length > 0 && (
        <GroupPermissionsGate>
          {({ matchAnyGroup }) => {
            const isAdmin = matchAnyGroup([userGroups.administrator]);
            return (
              <Fragment>
                {pagination}
                <ul className="unstyled-list" data-print-managed>
                  {folders.map((folder) => {
                    const {
                      id: folderId,
                      name: folderName,
                      owner = {},
                    } = folder;
                    const {
                      firstname: firstName = '',
                      lastname: lastName = '',
                    } = owner;
                    const subheading = t('components.MoveReportModal.owner', {
                      firstName,
                      lastName,
                    });
                    return (
                      <ListButtonCard
                        key={folderId}
                        heading={folderName}
                        subheading={isAdmin ? subheading : ''}
                        onClick={() => onSelectFolder(folder)}
                      />
                    );
                  })}
                </ul>
                {pagination}
              </Fragment>
            );
          }}
        </GroupPermissionsGate>
      )}
    </Fragment>
  );
};

FolderList.propTypes = {
  onSelectFolder: PropTypes.func.isRequired,
};

export default FolderList;
