import {
  isEmpty,
  validateDate,
  lawyerIdPattern,
} from 'js/utilities/validation';

export const runReportValidationRules = {
  startDate: (data, key) => validateDate(data[key]),
  endDate: (data, key) => validateDate(data[key]),
  lawyerId: (data, key) => lawyerIdPattern.test(data[key]),
  legalProblemCode: (data, key) => !isEmpty(data[key]),
  yearEndFeedbackReportType: (data, key) => !isEmpty(data[key]),
};

export const cboOptions = (t) => [
  {
    title: t('components.FeedbackReportPage.cboOptions.LawyerType'),
    value: 'LawyerType',
  },
  {
    title: t('components.FeedbackReportPage.cboOptions.ResolvedBy'),
    value: 'ResolvedBy',
  },
  {
    title: t('components.FeedbackReportPage.cboOptions.ResolvedHow'),
    value: 'ResolvedHow',
  },
  {
    title: t('components.FeedbackReportPage.cboOptions.TypeOfComplaints'),
    value: 'TypeOfComplaints',
  },
];

export const getRequiredFields = (report) => {
  const {
    requiresLawyerId = false,
    requiresLegalProbCode = false,
    requiresCBO = false,
  } = report;

  let requiredFields = [];
  const baseRequiredFields = ['startDate', 'endDate'];

  if (requiresLawyerId) {
    requiredFields = [...baseRequiredFields, 'lawyerId'];
  } else if (requiresLegalProbCode) {
    requiredFields = [...baseRequiredFields, 'legalProblemCode'];
  } else if (requiresCBO) {
    requiredFields = [...baseRequiredFields, 'yearEndFeedbackReportType'];
  } else {
    requiredFields = [...baseRequiredFields];
  }
  return requiredFields;
};
