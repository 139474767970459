import { cleanStringField } from 'js/utilities/strings';
import { formatDateFromISOString } from 'js/utilities/dates';

export const cleanClientLogRow = (data = {}) => {
  return {
    date: formatDateFromISOString(data.date),
    field: cleanStringField(data.field),
    newValue: cleanStringField(data.newValue),
    oldValue: cleanStringField(data.oldValue),
    updatedById: cleanStringField(data.updatedById),
  };
};

export const cleanClientLog = (data = []) => {
  return data.map(cleanClientLogRow);
};

export const getClientLogColumns = (t) => {
  const columns = ['date', 'field', 'newValue', 'oldValue', 'updatedById'].map(
    (key) => ({
      key,
      headerContent: t(`components.ClientLog.columns.${key}`),
    })
  );

  return columns;
};
