import {
  routePaths,
  routePathReplacingParams,
} from 'js/components/router/route-paths';
import { searchStringFromParams } from 'js/utilities/params';
import {
  clientIdPattern,
  clientCodePattern,
  lawyerIdPattern,
  caseNumberPattern,
  validateOptionalName,
  validatePhoneNumber,
  isEmpty,
} from 'js/utilities/validation';

export const SEARCH_SUBJECT_LOCAL_STORAGE_KEY =
  'com.redbitdev.uniforlsp.search.subject';

export const SEARCH_SUBJECTS = [
  'clientName',
  'clientId',
  'clientPhoneNumber',
  'caseNumber',
  'lawyerName',
  'lawyerId',
  'lawyerPhoneNumber',
];

export const SEARCH_SUBJECTS_INITIAL_SELECTION_INDEX = 0;

export const subjectChangeRequiresNewCriteria = (
  currentSubject,
  nextSubject
) => {
  const clientToLawyer =
    (currentSubject === 'clientName' && nextSubject === 'lawyerName') ||
    (currentSubject === 'clientPhoneNumber' &&
      nextSubject === 'lawyerPhoneNumber');
  const lawyerToClient =
    (currentSubject === 'lawyerName' && nextSubject === 'clientName') ||
    (currentSubject === 'lawyerPhoneNumber' &&
      nextSubject === 'clientPhoneNumber');
  return !clientToLawyer && !lawyerToClient;
};

export const searchInputValidationRules = {
  firstName: (data, key) => validateOptionalName(data[key]),
  lastName: (data, key) => validateOptionalName(data[key]),
  phoneNumber: (data, key) => validatePhoneNumber(data[key], true),
  clientId: clientIdPattern,
  clientCode: clientCodePattern,
  lawyerId: lawyerIdPattern,
  caseNumber: caseNumberPattern,
};

export const validateSearchCriteria = (options = {}) => {
  const { subject, ...validationOptions } = options;
  switch (subject) {
    case 'clientName':
    case 'lawyerName':
      return validateCriteriaForNameSearch(validationOptions);
    default:
      return validateCriteriaForDirectSearch(validationOptions);
  }
};

export const validateCriteriaForNameSearch = (options = {}) => {
  const { searchCriteria = {}, validate } = options;
  const { invalidFields = [] } = validate(
    searchCriteria,
    Object.keys(searchCriteria)
  );

  // Both names must pass validation and at least one must not be empty:
  const { firstName, lastName } = searchCriteria;
  const isFirstNameValid = !invalidFields.includes('firstName');
  const isLastNameValid = !invalidFields.includes('lastName');
  const hasEitherName = !isEmpty(firstName) || !isEmpty(lastName);
  return hasEitherName && isFirstNameValid && isLastNameValid;
};

export const validateCriteriaForDirectSearch = (options = {}) => {
  const { searchCriteria = {}, validate } = options;
  const { isValid = [] } = validate(
    searchCriteria,
    Object.keys(searchCriteria)
  );
  return isValid;
};

export const isSubjectInputTouched = (options = {}) => {
  const { subject, isTouched } = options;
  switch (subject) {
    case 'clientName':
    case 'lawyerName':
      return isTouched('firstName') || isTouched('lastName');
    case 'clientPhoneNumber':
    case 'lawyerPhoneNumber':
      return isTouched('phoneNumber');
    default:
      return isTouched(subject);
  }
};

export const persistSearchSubject = (subject) => {
  localStorage.setItem(SEARCH_SUBJECT_LOCAL_STORAGE_KEY, subject);
};

export const restoreSearchSubject = () => {
  return localStorage.getItem(SEARCH_SUBJECT_LOCAL_STORAGE_KEY);
};

export const getIndexOfSearchSubject = (subjects = [], subject) => {
  if (subjects.length > 0 && subject) {
    return subjects.findIndex((sub) => sub === subject);
  } else {
    return -1;
  }
};

export const getSubjectOptions = (options = {}) => {
  const { t, subjects, onSelectSubject } = options;
  return subjects.map((subject) => ({
    content: t(`components.controls.SearchInput.subjects.${subject}.name`),
    onSelect: () => onSelectSubject(subject),
  }));
};

export const getInitialSearchCriteriaForSubject = (subject) => {
  switch (subject) {
    case 'clientName':
    case 'lawyerName':
      return { firstName: '', lastName: '' };
    case 'clientId':
      return { clientId: '', clientCode: '00' };
    case 'lawyerId':
      return { lawyerId: '' };
    case 'clientPhoneNumber':
      return { phoneNumber: '' };
    case 'lawyerPhoneNumber':
      return { phoneNumber: '' };
    case 'caseNumber':
      return { caseNumber: '' };
    default:
      return {};
  }
};

export const searchSubjectForModel = (model) => {
  switch (model) {
    case 'client':
      return 'clientName';
    case 'lawyer':
      return 'lawyerName';
    default:
      return '';
  }
};

export const searchModelForSubject = (subject) => {
  switch (subject) {
    case 'clientName':
    case 'clientPhoneNumber':
      return 'client';
    case 'lawyerName':
    case 'lawyerPhoneNumber':
      return 'lawyer';
    default:
      return '';
  }
};

export const paramsForNameSearch = (
  model,
  searchCriteria = {},
  initialModel = '',
  initialQuery = {}
) => {
  // Retain the current search filters if the search model has not changed:
  if (model === initialModel) {
    const { params = {} } = initialQuery;
    return { ...params, ...searchCriteria };
  } else {
    return { ...searchCriteria };
  }
};

export const routePathForNameSearch = (
  subject,
  searchCriteria = {},
  initialModel = '',
  initialQuery = {}
) => {
  const model = searchModelForSubject(subject);
  if (!model) {
    return '';
  }

  const params = paramsForNameSearch(
    model,
    searchCriteria,
    initialModel,
    initialQuery
  );
  const search = searchStringFromParams(params);
  const path = routePathReplacingParams(routePaths.search, { model });

  return path + search;
};

export const routePathForClientId = ({ clientId, clientCode }) => {
  return routePathReplacingParams(routePaths.client, {
    id: encodeURIComponent(clientId),
    clientCode: encodeURIComponent(clientCode),
  });
};

export const routePathForLawyerId = ({ lawyerId }) => {
  return routePathReplacingParams(routePaths.lawyer, {
    lawyerId: encodeURIComponent(lawyerId),
  });
};

export const routePathForCaseNumber = ({ caseNumber }) => {
  return routePathReplacingParams(routePaths.case, {
    caseNumber: encodeURIComponent(caseNumber),
  });
};

export const routePathForSearch = (
  subject,
  searchCriteria = {},
  initialModel = '',
  initialQuery = {}
) => {
  switch (subject) {
    case 'clientName':
    case 'clientPhoneNumber':
    case 'lawyerName':
    case 'lawyerPhoneNumber':
      return routePathForNameSearch(
        subject,
        searchCriteria,
        initialModel,
        initialQuery
      );
    case 'clientId':
      return routePathForClientId(searchCriteria);
    case 'lawyerId':
      return routePathForLawyerId(searchCriteria);
    case 'caseNumber':
      return routePathForCaseNumber(searchCriteria);
    default:
      return '';
  }
};
