import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  initializeClientEffect,
  initializeCurrentAccountEffect,
  loginEffect,
  logoutEffect,
  acquireTokenEffect,
} from './lib/effects';

const placeholderPromise = async () =>
  Promise.reject(new Error('MSALContext not installed'));

const MSALContext = createContext({
  client: null,
  account: null,
  login: placeholderPromise,
  logout: placeholderPromise,
  acquireToken: placeholderPromise,
  isAuthenticating: false,
  isReady: false,
  config: {},
  audience: '',
});

const MSALProvider = (props) => {
  const { config = {}, audience, useRedirectFlow, children } = props;
  const [client, setClient] = useState(null);
  const [account, setAccount] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isReady, setIsReady] = useState(null);

  useEffect(initializeClientEffect({ config, setClient }), []);
  useEffect(
    initializeCurrentAccountEffect({
      client,
      setAccount,
      setIsAuthenticating,
      setIsReady,
      useRedirectFlow,
    }),
    [client]
  );

  return (
    <MSALContext.Provider
      value={{
        client,
        account,
        login: loginEffect({
          client,
          audience,
          setAccount,
          setIsAuthenticating,
          useRedirectFlow,
        }),
        logout: logoutEffect({
          client,
          setAccount,
          setIsAuthenticating,
        }),
        acquireToken: acquireTokenEffect({
          client,
          account,
          audience,
          setIsAuthenticating,
          useRedirectFlow,
        }),
        isAuthenticating,
        isReady,
        config,
        audience,
      }}
    >
      {children}
    </MSALContext.Provider>
  );
};

MSALProvider.defaultProps = {
  useRedirectFlow: false,
};

MSALProvider.propTypes = {
  config: PropTypes.shape({
    auth: PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      authority: PropTypes.string.isRequired,
      redirectUri: PropTypes.string.isRequired,
    }).isRequired,
    cache: PropTypes.object,
    system: PropTypes.object,
  }).isRequired,
  audience: PropTypes.string.isRequired,
  useRedirectFlow: PropTypes.bool,
};

export { MSALContext, MSALProvider };
