export const expandingCardContentClassName = 'expanding-card-content';
export const expandingCardContentSelector = `.${expandingCardContentClassName}`;
export const expandedClassName = 'expanded';
export const animatingClassName = 'animating';

export const topx = (value) => `${value}px`;

export const performExpansion = (options = {}) => {
  const { expanded, cardElement, onHeightTransitionEndEffect } = options;

  const contentElement = cardElement.querySelector(
    expandingCardContentSelector
  );

  if (!contentElement) {
    throw new Error(
      'ExpandingCardContent must be provided as a descendent of ExpandingCard'
    );
  }

  const onTransitionEnd = onHeightTransitionEndEffect({
    expanded,
    cardElement,
    contentElement,
  });

  // The .expanded class is managed here rather than
  // in the component render function to ensure that
  // it is applied on the same frame as the .animating
  // class. This reduces jitter.
  if (expanded) {
    cardElement.classList.add(expandedClassName);
  } else {
    cardElement.classList.remove(expandedClassName);
  }

  cardElement.classList.add(animatingClassName);

  contentElement.addEventListener('transitionend', onTransitionEnd);
  contentElement.style.height = 'auto';

  window.requestAnimationFrame(() => {
    const { offsetHeight } = contentElement;
    const initialHeight = expanded ? 0 : offsetHeight;
    const finalHeight = expanded ? offsetHeight : 0;
    contentElement.style.height = topx(initialHeight);
    window.requestAnimationFrame(() => {
      contentElement.style.height = topx(finalHeight);
    });
  });
};
