import { queryForParams } from 'js/utilities/params';

export const DEFAULT_LIST_PRINT_VIEW_LIMIT = 10000;

export const queryForListPrintView = (
  query = {},
  limit = DEFAULT_LIST_PRINT_VIEW_LIMIT
) => {
  const { params = {} } = query;

  const nextParams = {
    ...params,
    Limit: limit,
    Offset: 0,
  };

  return queryForParams(nextParams);
};
