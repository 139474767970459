import React from 'react';
import PropTypes from 'prop-types';
import SortMenu from 'js/components/menus/sort-menu';
import { staffSortOptions } from './functions';

const StaffSortMenu = (props) => (
  <SortMenu
    className="staff-sort-menu"
    sortOptions={staffSortOptions}
    {...props}
  />
);

StaffSortMenu.defaultProps = {
  field: 'id',
  direction: 'asc',
};

StaffSortMenu.propTypes = {
  field: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default StaffSortMenu;
