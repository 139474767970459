import { userGroups } from 'js/components/group-permissions';
import { adminToolsBreadcrumbsHistory } from 'js/utilities/admin-tools';
import { paramsFromSearchString, queryForParams } from 'js/utilities/params';
import { cleanStringField } from 'js/utilities/strings';

export const staffBreadcrumbsHistory = (t, location = {}) => {
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`;
  const prevHstory = adminToolsBreadcrumbsHistory(t);

  const history = [
    ...prevHstory,
    {
      content: t('components.StaffPage.title'),
      path: currentPath,
    },
  ];

  return history;
};

export const queryForSearchString = (search = '') => {
  const {
    Keyword = '',
    SortBy = 'id',
    Sort = 'asc',
    Limit = '10',
    Offset = '0',
  } = paramsFromSearchString(search);

  const params = {
    Keyword,
    SortBy,
    Sort,
    Limit: parseInt(Limit),
    Offset: parseInt(Offset),
  };

  return queryForParams(params);
};

export const cleanStaffList = (staffInfo) => {
  return staffInfo.map(cleanStaffMember);
};

export const cleanStaffMember = (staff = {}) => {
  const {
    usrCode,
    usrEmail,
    usrFname,
    usrLname,
    usrOfficecode,
    usrPrefix,
    usrSecur,
  } = staff;
  return {
    ...staff,
    usrCode: cleanStringField(usrCode),
    usrEmail: usrEmail ? cleanStringField(usrEmail) : '',
    usrFname: cleanStringField(usrFname),
    usrLname: cleanStringField(usrLname),
    usrOfficecode: cleanStringField(usrOfficecode),
    usrPrefix: cleanStringField(usrPrefix),
    usrSecur: cleanStringField(usrSecur),
  };
};

export const getStaffPrintOffice = (officeCode, officeName) => {
  return [officeCode, officeName].filter((line) => !!line).join(' - ');
};

export const getStaffPrintAccessLevel = (t, accessLevel) => {
  const groupKey = Object.keys(userGroups).find(
    (key) => userGroups[key] === parseInt(accessLevel)
  );
  const groupName = groupKey
    ? t(`components.StaffListPrintView.groups.${groupKey}`)
    : '';
  return [accessLevel.toString(), groupName]
    .filter((line) => !!line)
    .join(' - ');
};
