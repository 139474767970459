import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPostalCodeMaskEffect, onChangeEffect } from './effects';

const PostalCodeInput = (props) => {
  const postalCodeMaskRef = useRef(null);
  const { onChange, ...inputProps } = props;

  useEffect(createPostalCodeMaskEffect({ postalCodeMaskRef }), []);

  return (
    <input
      type="text"
      autoComplete="off"
      onChange={onChangeEffect({ postalCodeMaskRef, onChange })}
      {...inputProps}
    />
  );
};

PostalCodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PostalCodeInput;
