import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'js/components/design-system';
import { releaseTabItems } from './functions';
import PropTypes from 'prop-types';

const ReleaseTabs = (props) => {
  const { path } = useRouteMatch();
  const { disabled = false, params = {}, isCopy = false } = props;
  const { t } = useTranslation();
  const { scheduleId = '', effectiveDate } = params;
  return (
    <Tabs
      className="fee-schedule-tabs"
      items={releaseTabItems(t, path, { scheduleId, effectiveDate, isCopy })}
      currentPath={path}
      disabled={disabled}
    />
  );
};

ReleaseTabs.propTypes = {
  disabled: PropTypes.bool,
  params: PropTypes.object,
  isCopy: PropTypes.bool,
};

export default ReleaseTabs;
