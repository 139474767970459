import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../../icons';
import { onClickSortButtonEffect } from './effects';
import { tableSortDirection } from './functions';
import { columnPropTypes, sortDirectionPropTypes } from './prop-types';

const TableSortButton = (props = {}) => {
  const { column = {}, sortColumnKey, sortDirection, onChangeSort } = props;
  const { key, headerContent, sortable } = column;
  const isActiveSortColumn = key === sortColumnKey;
  const isAscending = sortDirection === tableSortDirection.asc;
  const direction =
    isActiveSortColumn && isAscending
      ? tableSortDirection.desc
      : tableSortDirection.asc;
  const icon = isActiveSortColumn && !isAscending ? faCaretDown : faCaretUp;

  return (
    <button
      className={classnames('table-sort-button', {
        'table-sort-button-active': isActiveSortColumn,
      })}
      onClick={onClickSortButtonEffect({ column, direction, onChangeSort })}
      disabled={!sortable}
    >
      {headerContent}
      {sortable && <FontAwesomeIcon icon={icon} data-screen-only />}
    </button>
  );
};

TableSortButton.propTypes = {
  column: columnPropTypes.isRequired,
  sortColumnKey: PropTypes.string.isRequired,
  sortDirection: sortDirectionPropTypes.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};

export default TableSortButton;
