import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import SearchInput from 'js/components/controls/search-input';
import { FontAwesomeIcon } from 'js/components/design-system';
import { routePaths } from 'js/components/router/route-paths';
import PopoverController from 'js/components/popover-controller';
import FixedHeader from './fixed-header';
import HeaderMenuLinkedItem from './header-menu-linked-item';
import {
  GroupPermissionsGate,
  userGroups,
} from 'js/components/group-permissions';

const SearchHeader = (props) => {
  const { t } = useTranslation();
  const { className, initialModel, initialQuery, ...headerProps } = props;
  return (
    <FixedHeader
      className={classnames('search-header', className)}
      {...headerProps}
    >
      <SearchInput initialModel={initialModel} initialQuery={initialQuery} />
      <PopoverController popperOptions={{ placement: 'bottom-start' }}>
        {({
          referenceAttributes,
          contentAttributes,
          isVisible,
          setVisibility,
        }) => (
          <Fragment>
            {
              <GroupPermissionsGate
                permittedGroups={[
                  userGroups.administrator,
                  userGroups.dataEntry,
                  userGroups.intakeSpecialist,
                  userGroups.feedbackSpecialist,
                  userGroups.support,
                ]}
              >
                <button
                  className={classnames(
                    'header-menu-button',
                    'header-new-button',
                    {
                      'header-menu-button-active': isVisible,
                    }
                  )}
                  {...referenceAttributes}
                >
                  <span>{t('common.new')}</span>
                  <FontAwesomeIcon
                    icon={isVisible ? faMinusCircle : faPlusCircle}
                  />
                </button>
              </GroupPermissionsGate>
            }

            <ul
              {...contentAttributes}
              className={classnames(
                contentAttributes.className,
                'header-menu',
                'header-new-menu'
              )}
              role="menu"
            >
              <HeaderMenuLinkedItem
                linkTo={routePaths.newClient}
                content={t('components.SearchHeader.newClient')}
                isMenuVisible={isVisible}
                setMenuVisibility={setVisibility}
              />
              <HeaderMenuLinkedItem
                linkTo={routePaths.newLawyer}
                content={t('components.SearchHeader.newLawyer')}
                isMenuVisible={isVisible}
                setMenuVisibility={setVisibility}
              />
            </ul>
          </Fragment>
        )}
      </PopoverController>
    </FixedHeader>
  );
};

SearchHeader.propTypes = {
  ...FixedHeader.propTypes,
  initialModel: PropTypes.string,
  initialQuery: PropTypes.object,
};

export default SearchHeader;
