import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '../icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export const breadcrumbTypes = {
  home: {
    className: 'breadcrumbs-icon',
    path: '/',
    content: <FontAwesomeIcon icon={faHome} />,
  },
};

const Breadcrumbs = (props) => {
  const { className, history = [] } = props;
  return (
    <ul className={classnames('breadcrumbs', className)}>
      {history.map((item, idx) => {
        const { className: itemClassName, path, content } = item;
        return (
          <li
            key={idx}
            className={itemClassName}
            style={{ zIndex: history.length - idx }}
          >
            <Link to={path}>{content}</Link>
          </li>
        );
      })}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      path: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
};

export default Breadcrumbs;
