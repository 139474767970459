export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const injectFetchHeaders = (options = {}, headers = {}) => {
  const { headers: optionHeaders = {} } = options;
  const nextHeaders = { ...headers, ...optionHeaders };
  return { ...options, headers: nextHeaders };
};

export const fetchRequest = async (url, options = {}) => {
  const response = await fetch(url, options);

  if (response.ok) {
    return { response };
  } else {
    const error = {
      method: options.method || 'GET',
      url: response.url,
      status: response.status,
      message: response.statusText,
      body: {},
    };
    throw error;
  }
};

export const fetchJson = async (url, options = {}) => {
  const { body, ...otherOptions } = options;
  const nextBody = body !== undefined ? JSON.stringify(body) : undefined;
  const nextOptions = injectFetchHeaders(
    { ...otherOptions, body: nextBody },
    JSON_HEADERS
  );
  const method = (nextOptions.method || 'GET').toUpperCase();
  const response = await fetch(url, nextOptions);

  if (response.ok) {
    let json = {};
    if (['GET', 'POST', 'PUT', 'PATCH'].includes(method)) {
      try {
        json = await response.json();
      } catch (na) {
        // Ignore parse errors because the
        // response may not have a body.
      }
    }
    return { response, json };
  } else {
    let errorBody = {};
    try {
      errorBody = await response.json();
    } catch (na) {
      // Ignore parse errors because the failed
      // response may not have a json body.
    }

    let errorMessage = response.statusText;

    if (errorBody.error && typeof errorBody.error === 'string') {
      errorMessage = errorBody.error;
    }

    const error = {
      method,
      url: response.url,
      status: response.status,
      message: errorMessage,
      body: errorBody,
    };
    throw error;
  }
};

export const getJson = async (url, options = {}) => {
  return fetchJson(url, {
    ...options,
    method: 'GET',
  });
};

export const postJson = async (url, options = {}) => {
  return fetchJson(url, {
    ...options,
    method: 'POST',
  });
};

export const putJson = async (url, options = {}) => {
  return fetchJson(url, {
    ...options,
    method: 'PUT',
  });
};

export const patchJson = async (url, options = {}) => {
  return fetchJson(url, {
    ...options,
    method: 'PATCH',
  });
};

export const deleteJson = async (url, options = {}) => {
  return fetchJson(url, {
    ...options,
    method: 'DELETE',
  });
};
